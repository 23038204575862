import {
    INVALIDATE_DETALLESPEDIDOMON,
    REQUEST_DETALLESPEDIDOMON,
    RECEIVE_DETALLESPEDIDOMON,
    ERROR_DETALLESPEDIDOMON,
    RESET_DETALLESPEDIDOMON,
    REQUEST_CREATE_DETALLEPEDIDOMON,
    SUCCESS_CREATE_DETALLEPEDIDOMON,
    ERROR_CREATE_DETALLEPEDIDOMON,
    RESET_CREATE_DETALLEPEDIDOMON,
    CREATE_DETALLEPEDIDOMON,
    DELETE_DETALLEPEDIDOMON,
    UPDATE_DETALLEPEDIDOMON
} from '../actions/DetallePedidoMonActions';
import {combineReducers} from 'redux';
import normalizeDatos from "../normalizers/normalizeDetallesPedidoMon";
import merge from "lodash/merge";
import union from "lodash/union";
import difference from "lodash/difference";
import pickBy from "lodash/pickBy";
import remove from "lodash/remove";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import {RECEIVE_PEDIDOS} from "../actions/PedidoActions";
import {
    ERROR_UPDATE_CLIENTE,
    REQUEST_UPDATE_CLIENTE, RESET_UPDATE_CLIENTE,
    SUCCESS_UPDATE_CLIENTE,
    UPDATE_CLIENTE
} from "../actions/ClienteActions";

function detallesPedidoMonById(state = {
    isFetching: false,
    didInvalidate: true,
    detallesPedidoMon: {}
}, action) {
    switch (action.type) {
        // Fetch
        case INVALIDATE_DETALLESPEDIDOMON:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_DETALLESPEDIDOMON:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_DETALLESPEDIDOMON:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                detallesPedidoMon: action.detallesPedidoMon ? action.detallesPedidoMon : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_DETALLESPEDIDOMON:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_DETALLESPEDIDOMON:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                detallesPedidoMon: []
            });
        // Create
        case CREATE_DETALLEPEDIDOMON:
            return merge({}, state, {
                isCreating: false,
                detallesPedidoMon: action.detallePedidoMon,
                error: null,
            });
        case REQUEST_CREATE_DETALLEPEDIDOMON:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_DETALLEPEDIDOMON:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case ERROR_CREATE_DETALLEPEDIDOMON:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_DETALLEPEDIDOMON:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                detallesPedidoMon: {}
            });
        // Delete
        case DELETE_DETALLEPEDIDOMON:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                detallesPedidoMon: pickBy(state.detallesPedidoMon, function (bulto, key) {
                    return key !== Object.keys(action.detallePedidoMon)[0];
                })
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                detallesPedidoMon: {}
            });
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                detallesPedidoMon: merge({}, state.detallesPedidoMon, action.pedidos.entities.detallesPedidoMon),
                lastUpdated: action.receivedAt
            });
        case UPDATE_DETALLEPEDIDOMON:
            var detallePedidoMon = {};
            detallePedidoMon[action.idDetallePedidoMon] = action.detallePedidoMon;
            return Object.assign({}, state, {
                isFetching: false,
                detallesPedidoMon: merge({}, state.detallesPedidoMon, detallePedidoMon),
            });
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    detallesPedidoMon: {},
    error: ""
}, action) {
    switch (action.type) {
        case UPDATE_DETALLEPEDIDOMON:
            return Object.assign({}, state, {
                detallesPedidoMon: union(state.detallesPedidoMon, [action.idDetallePedidoMon]),
            });
        default:
            return state
    }
}


function allDetallesPedidoMon(state = [], action) {
    switch (action.type) {
        case RECEIVE_DETALLESPEDIDOMON:
            return normalizeDatos(action.detallesPedidoMon).result ? normalizeDatos(action.detallesPedidoMon).result : [];
        case RESET_DETALLESPEDIDOMON:
            return [];
        case CREATE_DETALLEPEDIDOMON:
            return union(state, [parseInt(Object.keys(action.detallePedidoMon)[0])]);
        case DELETE_DETALLEPEDIDOMON:
            return difference(state, [parseInt(Object.keys(action.detallePedidoMon)[0])]);
        case RECEIVE_PEDIDOS:
            return action.pedidos.entities.detallesPedidoMon ? union(state, Object.keys(action.pedidos.entities.detallesPedidoMon)) : state;
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

const detallesPedidoMon = combineReducers({
    byId: detallesPedidoMonById,
    allIds: allDetallesPedidoMon,
    update: update,
});

export default detallesPedidoMon;