import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import * as moment from 'moment';
import fechasFormat from "../../api/fechas";

import Footer2 from '../../components/layout/Footer2';

import "../../assets/css/Encuesta.css";

//Actions
import {createContacto, saveCreateContacto} from '../../actions/ContactoActions';
//Constants

import Loading from "../../assets/img/loader.gif"
import Cleave from 'cleave.js/react';

require('cleave.js/dist/addons/cleave-phone.ar');


class Contacto extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.createContacto(cambio);
    }

    submitForm(e) {
        e.preventDefault();
        this.props.saveCreateContacto();
    }

    render() {
        var contacto = this.props.contacto.create.nuevo;
        return (
            <div className="formularioContacto">
                <section className="login-ingreso topBorder">
                    <Row>
                        <Col md={10} mdOffset={1} className="recuadro">
                            <p>
                                Bienvenido/a <br/>
                                Usá este formulario para hacernos llegar tu consulta.
                                Te responderemos a la brevedad.
                            </p>
                            <form id="form" onSubmit={(e) => {
                                this.submitForm(e)
                            }}>
                                <Row>
                                    <input id="nombre" type="text" required placeholder="Nombre"
                                           onChange={(e) => this.onChangeUsuario(e)}
                                           value={contacto.nombre ? contacto.nombre : ""}/>
                                </Row>
                                <Row>
                                    <input id="apellido" type="text" required placeholder="Apellido"
                                           onChange={(e) => this.onChangeUsuario(e)}
                                           value={contacto.apellido ? contacto.apellido : ""}/>
                                </Row>
                                <Row>
                                    <input id="empresa" type="text" required placeholder="Empresa"
                                           onChange={(e) => this.onChangeUsuario(e)}
                                           value={contacto.empresa ? contacto.empresa : ""}
                                    />
                                </Row>
                                {/*<Row>*/}
                                    {/*<Cleave*/}
                                        {/*id="telefono"*/}
                                        {/*type="text"*/}
                                        {/*label="Teléfono"*/}
                                        {/*required={false}*/}
                                        {/*placeholder="(Cod. Area - Num. Tel.)"*/}
                                        {/*disabled={false}*/}
                                        {/*options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}*/}
                                        {/*onChange={(e) => this.onChangeUsuario(e)}*/}
                                    {/*/>*/}
                                {/*</Row>*/}
                                <Row>
                                    <input id="telefono" type="number" required placeholder="(Cod. Area - Num. Tel.)"
                                           onChange={(e) => {this.onChangeUsuario(e)}}
                                           value={contacto.telefono ? contacto.telefono : ""}
                                           min={0}/>
                                </Row>
                                <Row>
                                    <input id="email" type="email" required placeholder="Email"
                                           onChange={(e) => this.onChangeUsuario(e)}
                                           value={contacto.email ? contacto.email : ""}
                                           style={{color:"white"}}
                                    />
                                </Row>

                                <Row>
                                    <select id="tipoConsulta" onChange={(e) => this.onChangeUsuario(e)}
                                            value={contacto.tipoConsulta ? contacto.tipoConsulta : ""} required>
                                        <option value="" disabled selected>Seleccione el tipo de consulta</option>
                                        <option value="Técnica">Técnica</option>
                                        <option value="Comercial">Comercial</option>
                                        <option value="Administrativa">Administrativa</option>
                                        <option value="Otras">Otras</option>
                                    </select>
                                </Row>

                                <Row>
                                    <textarea id="consulta" rows="5"
                                              onChange={(e) => this.onChangeUsuario(e)}
                                              placeholder="Consulta"
                                              value={contacto.consulta ? contacto.consulta : ""}/>
                                </Row>

                                <div className="separador-celeste"/>
                                <Row>
                                    <Col md={12} className="botoneraIngreso">
                                        {
                                            this.props.contacto.create.isCreating ?
                                                <img src={Loading} style={{
                                                    width: "25px",
                                                    marginTop: "21px"
                                                }}/> :
                                                <Button type="submit" className="registrate">Enviar</Button>
                                        }
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </section>

                <Footer2/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        contacto: state.contacto,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createContacto: (cambio) => {
            dispatch(createContacto(cambio))
        },
        saveCreateContacto: () => {
            dispatch(saveCreateContacto())
        }
    };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contacto));