import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter, Route} from "react-router-dom";
import {Switch} from 'react-router';

//Images
import imgSierra from "../../../assets/img/evento/eventoRoad/sierraLearningCenter.png";

import '../../../assets/css/Sorteo.css';
import TablaSorteo from "./TablaSorteo";
import OkSorteo from "./OkSorteo";
import StartSorteo from "./StartSorteo";
import Ganadores from "./Ganadores";


class Sorteo extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="sorteo">
                <div className="container-limit">
                    <Row className="headerRoad">
                        <img src={imgSierra}/>
                        <div className="textoTitulo">
                            <h2>¡MUCHA SUERTE!</h2>
                        </div>
                    </Row>
                    <Row>
                        <Col md={12} className="center-block">
                            <Switch>
                                <Route path="/sorteo/:evento?" exact component={StartSorteo}/>
                                <Route path="/sorteo/startSorteo/:evento" exact component={TablaSorteo}/>
                                <Route path="/sorteo/ganadores/:evento" exact component={Ganadores}/>
                                <Route path="/sorteo/ok/saludos"  component={OkSorteo}/>

                            </Switch>

                        </Col>
                    </Row>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sorteo));
