import c from '../constants/constants';
require('es6-promise').polyfill();
require('isomorphic-fetch');

var detallesPedidoMon = {

    getByIdPedido(idPedido) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/detallesPedidoMon/'+idPedido, defaultOptions);
    },
    update(detallesPedidoMon) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(detallesPedidoMon)
        };

        return fetch(c.BASE_URL + '/detallesPedidoMon/1', defaultOptions);
    },
};

export default detallesPedidoMon;