import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import history from "../../../../history";

import {Row} from "react-bootstrap";

//Components
import DatosUsuario from "../../../elementos/basicos/DatosUsuario";

//Images
import iconoKit from "../../../../assets/img/cuentas/logoKit.png";
import inicio from "../../../../assets/img/monitorPedidos/inicio.png";
import iconoMonitor from "../../../../assets/img/monitorPedidos/iconoMonitor.png";

//CSS
import '../../../../assets/css/HeaderPlataforma.css';

class HeaderPlataforma extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="headerPlataforma">
                <Row>
                    <div className="inicioKit">
                        <img src={iconoKit} alt="iconoKit" onClick={() => history.push('/')} style={{cursor:"pointer"}}/>
                        <img className="inicio" src={inicio} alt="inicio" onClick={() => history.push('/panel/cuentas')} style={{cursor:"pointer"}}/>
                    </div>
                    <DatosUsuario mostrarLogo={false}/>
                </Row>
                <Row className="contenedorTitulo">
                    <img src={iconoMonitor} alt="iconoMonitor"/>
                    <h1>{this.props.titulo ? this.props.titulo : ""}</h1>
                </Row>
            </section>
        )

    }
}

function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderPlataforma));
