//api
import entidadesFinancierasApi from "../api/entidadesFinancierasApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "./AuthenticationActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeEntidadesFinancieras";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../api/authentication";

//ENTIDADESFINANCIERAS
export const REQUEST_ENTIDADESFINANCIERAS = 'REQUEST_ENTIDADESFINANCIERAS';
export const RECEIVE_ENTIDADESFINANCIERAS = 'RECEIVE_ENTIDADESFINANCIERAS';
export const INVALIDATE_ENTIDADESFINANCIERAS = 'INVALIDATE_ENTIDADESFINANCIERAS';
export const ERROR_ENTIDADESFINANCIERAS = "ERROR_ENTIDADESFINANCIERAS";
export const RESET_ENTIDADESFINANCIERAS = "RESET_ENTIDADESFINANCIERAS";


export function invalidateEntidadesFinancieras() {
    return {
        type: INVALIDATE_ENTIDADESFINANCIERAS
    }
}

function requestEntidadesFinancieras() {
    return {
        type: REQUEST_ENTIDADESFINANCIERAS,
    }
}

function receiveEntidadesFinancieras(json) {
    return {
        type: RECEIVE_ENTIDADESFINANCIERAS,
    entidadesFinancieras: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorEntidadesFinancieras(error) {
    return {
        type: ERROR_ENTIDADESFINANCIERAS,
        error: error,
    }
}

export function resetEntidadesFinancieras() {
    return {
        type: RESET_ENTIDADESFINANCIERAS
    }
}

export function fetchEntidadesFinancieras(filtros) {
    return dispatch => {
        dispatch(requestEntidadesFinancieras());
        return entidadesFinancierasApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveEntidadesFinancieras(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorEntidadesFinancieras(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEntidadesFinancieras(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchEntidadesFinancieras(state) {
    const entidadesFinancieras = state.entidadesFinancieras.byId;
    if (!entidadesFinancieras) {
        return true
    } else if (entidadesFinancieras.isFetching) {
        return false
    } else {
        return entidadesFinancieras.didInvalidate;
    }
}

export function fetchEntidadesFinancierasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchEntidadesFinancieras(getState())) {
            return dispatch(fetchEntidadesFinancieras(filtros))
        }
    }
}


//MODEL
export const REQUEST_ENTIDADFINANCIERA = 'REQUEST_ENTIDADFINANCIERA';
export const RECEIVE_ENTIDADFINANCIERA = 'RECEIVE_ENTIDADFINANCIERA';
export const INVALIDATE_ENTIDADFINANCIERA = 'INVALIDATE_ENTIDADFINANCIERA';
export const ERROR_ENTIDADFINANCIERA = "ERROR_ENTIDADFINANCIERA";
export const RESET_ENTIDADFINANCIERA = "RESET_ENTIDADFINANCIERA";


export function invalidateEntidadFinanciera() {
    return {
        type: INVALIDATE_ENTIDADFINANCIERA
    }
}

function requestEntidadFinanciera() {
    return {
        type: REQUEST_ENTIDADFINANCIERA,
    }
}

export function receiveEntidadFinanciera(json) {
    return {
        type: RECEIVE_ENTIDADFINANCIERA,
    entidadFinanciera: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorEntidadFinanciera(error) {
    return {
        type: ERROR_ENTIDADFINANCIERA,
        error: error,
    }
}

export function fetchEntidadFinanciera(idEntidadFinanciera) {
    return dispatch => {
        dispatch(requestEntidadFinanciera());
        return entidadesFinancierasApi.getOne(idEntidadFinanciera)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveEntidadFinanciera(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorEntidadFinanciera(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEntidadFinanciera(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_ENTIDADFINANCIERA = 'UPDATE_ENTIDADFINANCIERA';
export const REQUEST_UPDATE_ENTIDADFINANCIERA = "REQUEST_UPDATE_ENTIDADFINANCIERA";
export const SUCCESS_UPDATE_ENTIDADFINANCIERA = "SUCCESS_UPDATE_ENTIDADFINANCIERA";
export const ERROR_UPDATE_ENTIDADFINANCIERA = "ERROR_UPDATE_ENTIDADFINANCIERA";
export const RESET_UPDATE_ENTIDADFINANCIERA = "RESET_UPDATE_ENTIDADFINANCIERA";
export const DELETE_UPDATE_ENTIDADFINANCIERA = "DELETE_UPDATE_ENTIDADFINANCIERA";

function requestUpdateEntidadFinanciera() {
    return {
        type: REQUEST_UPDATE_ENTIDADFINANCIERA,
    }
}

function receiveUpdateEntidadFinanciera(entidadFinanciera) {
    return {
        type: SUCCESS_UPDATE_ENTIDADFINANCIERA,
        receivedAt: Date.now(),
        entidadFinanciera: normalizeDato(entidadFinanciera)
    }
}

function errorUpdateEntidadFinanciera(error) {
    return {
        type: ERROR_UPDATE_ENTIDADFINANCIERA,
        error: error,
    }
}

export function resetUpdateEntidadFinanciera() {
    return {
        type: RESET_UPDATE_ENTIDADFINANCIERA,
    }
}

export function updateEntidadFinanciera(entidadFinanciera) {
    return {
        type: UPDATE_ENTIDADFINANCIERA,
        entidadFinanciera
    }
}

export function saveUpdateEntidadFinanciera() {
    return (dispatch, getState) => {
        dispatch(requestUpdateEntidadFinanciera());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let entidadFinanciera = denormalizeDato(getState().entidadesFinancieras.update.activo, store);

        return entidadesFinancierasApi.saveUpdate(entidadFinanciera)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateEntidadFinanciera(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateEntidadFinanciera(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorUpdateEntidadesFinancieras(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorUpdateEntidadesFinancieras(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorUpdateEntidadFinanciera(JSON.parse(error.message)));
                                                if (error.data && error.data.length > 0)
                                                   dispatch(receiveUpdateEntidadFinanciera(error.data));
                                            }).catch(() => {
                                            dispatch(errorUpdateEntidadFinanciera(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

export function deleteUpdateEntidadFinanciera(entidadFinanciera) {
    return {
        type: DELETE_UPDATE_ENTIDADFINANCIERA,
        entidadFinanciera
    }
}

//UPDATE ENTIDADESFINANCIERAS
export const REQUEST_UPDATE_ENTIDADESFINANCIERAS = "REQUEST_UPDATE_ENTIDADESFINANCIERAS";
export const SUCCESS_UPDATE_ENTIDADESFINANCIERAS = "SUCCESS_UPDATE_ENTIDADESFINANCIERAS";
export const ERROR_UPDATE_ENTIDADESFINANCIERAS = "ERROR_UPDATE_ENTIDADESFINANCIERAS";
export const RESET_UPDATE_ENTIDADESFINANCIERAS = "RESET_UPDATE_ENTIDADESFINANCIERAS";

function requestUpdateEntidadesFinancieras() {
    return {
        type: REQUEST_UPDATE_ENTIDADESFINANCIERAS,
}
}

function receiveUpdateEntidadesFinancieras(entidadesFinancieras) {
    return {
        type: SUCCESS_UPDATE_ENTIDADESFINANCIERAS,
    receivedAt: Date.now(),
        entidadesFinancieras: normalizeDatos(entidadesFinancieras)
}
}

function errorUpdateEntidadesFinancieras(error) {
    return {
        type: ERROR_UPDATE_ENTIDADESFINANCIERAS,
    error: error,
}
}

export function resetUpdateEntidadesFinancieras() {
    return {
        type: RESET_UPDATE_ENTIDADESFINANCIERAS,
}
}

export function saveUpdateEntidadesFinancieras() {
    return (dispatch, getState) => {
        dispatch(requestUpdateEntidadesFinancieras());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let entidadesFinancieras = getState().entidadesFinancieras.update.activos.map((idEntidadFinanciera) => {
            return denormalizeDato(getState().entidadesFinancieras.update.activo[idEntidadFinanciera], store);
        });

        return entidadesFinancierasApi.saveUpdateEntidadesFinancieras(entidadesFinancieras)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateEntidadesFinancieras(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateEntidadesFinancieras(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                    if (error.responseJSON && error.responseJSON.message !== "")
                        try
                        {
                            dispatch(errorUpdateEntidadesFinancieras(JSON.parse(error.responseJSON.message)));
                        } catch(e) {
                            dispatch(errorUpdateEntidadesFinancieras(error.responseJSON.message));
                        }
                    else
                            error.json().then((error) => {
                                dispatch(errorUpdateEntidadesFinancieras(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorUpdateEntidadesFinancieras(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//ALTA ENTIDADFINANCIERA
export const CREATE_ENTIDADFINANCIERA = 'CREATE_ENTIDADFINANCIERA';
export const REQUEST_CREATE_ENTIDADFINANCIERA = "REQUEST_CREATE_ENTIDADFINANCIERA";
export const SUCCESS_CREATE_ENTIDADFINANCIERA = "SUCCESS_CREATE_ENTIDADFINANCIERA";
export const ERROR_CREATE_ENTIDADFINANCIERA = "ERROR_CREATE_ENTIDADFINANCIERA";
export const RESET_CREATE_ENTIDADFINANCIERA = "RESET_CREATE_ENTIDADFINANCIERA";
export const DELETE_CREATE_ENTIDADFINANCIERA = "DELETE_CREATE_ENTIDADFINANCIERA";


//ALTA ENTIDADFINANCIERA
function requestCreateEntidadFinanciera() {
    return {
        type: REQUEST_CREATE_ENTIDADFINANCIERA,
    }
}

function receiveCreateEntidadFinanciera(entidadFinanciera) {
    return {
        type: SUCCESS_CREATE_ENTIDADFINANCIERA,
        receivedAt: Date.now(),
        entidadFinanciera: normalizeDato(entidadFinanciera)
    }
}

export function errorCreateEntidadFinanciera(error) {
    return {
        type: ERROR_CREATE_ENTIDADFINANCIERA,
        error: error,
    }
}

export function resetCreateEntidadFinanciera() {
    return {
        type: RESET_CREATE_ENTIDADFINANCIERA
    }
}

export function createEntidadFinanciera(entidadFinanciera) {
    return {
        type: CREATE_ENTIDADFINANCIERA,
        entidadFinanciera
    }
}

export function deleteCreateEntidadFinanciera(entidadFinanciera) {
    return {
        type: DELETE_CREATE_ENTIDADFINANCIERA,
        entidadFinanciera
    }
}

export function saveCreateEntidadFinanciera() {
    return (dispatch, getState) => {
        dispatch(requestCreateEntidadFinanciera());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let entidadFinanciera = denormalizeDato(getState().entidadesFinancieras.create.nuevo, store);

        return entidadesFinancierasApi.saveCreate(entidadFinanciera)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateEntidadFinanciera(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateEntidadFinanciera(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        try
                                        {
                                            dispatch(errorCreateEntidadesFinancieras(JSON.parse(error.responseJSON.message)));
                                        } catch(e) {
                                            dispatch(errorCreateEntidadesFinancieras(error.responseJSON.message));
                                        }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorCreateEntidadFinanciera(JSON.parse(error.message)));
                                                if (error.data)
                                                    dispatch(receiveCreateEntidadFinanciera(error.data));
                                            }).catch(() => {
                                            dispatch(errorCreateEntidadFinanciera(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//CREATE ENTIDADESFINANCIERAS
export const REQUEST_CREATE_ENTIDADESFINANCIERAS = "REQUEST_CREATE_ENTIDADESFINANCIERAS";
export const SUCCESS_CREATE_ENTIDADESFINANCIERAS = "SUCCESS_CREATE_ENTIDADESFINANCIERAS";
export const ERROR_CREATE_ENTIDADESFINANCIERAS = "ERROR_CREATE_ENTIDADESFINANCIERAS";
export const RESET_CREATE_ENTIDADESFINANCIERAS = "RESET_CREATE_ENTIDADESFINANCIERAS";

function requestCreateEntidadesFinancieras() {
    return {
        type: REQUEST_CREATE_ENTIDADESFINANCIERAS,
}
}

function receiveCreateEntidadesFinancieras(entidadesFinancieras) {
    return {
        type: SUCCESS_CREATE_ENTIDADESFINANCIERAS,
    receivedAt: Date.now(),
        entidadesFinancieras: normalizeDatos(entidadesFinancieras)
}
}

function errorCreateEntidadesFinancieras(error) {
    return {
        type: ERROR_CREATE_ENTIDADESFINANCIERAS,
    error: error,
}
}

export function resetCreateEntidadesFinancieras() {
    return {
        type: RESET_CREATE_ENTIDADESFINANCIERAS,
}
}

export function saveCreateEntidadesFinancieras() {
    return (dispatch, getState) => {
        dispatch(requestCreateEntidadesFinancieras());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key],getState()[key].update.activo,getState()[key].create.nuevo);
            });

        let entidadesFinancieras = getState().entidadesFinancieras.create.nuevos.map((idEntidadFinanciera) => {
            return denormalizeDato(getState().entidadesFinancieras.create.nuevo[idEntidadFinanciera], store);
        });

        return entidadesFinancierasApi.saveCreateEntidadesFinancieras(entidadesFinancieras)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateEntidadesFinancieras(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateEntidadesFinancieras(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try
                                {
                                    dispatch(errorCreateEntidadesFinancieras(JSON.parse(error.responseJSON.message)));
                                } catch(e) {
                                    dispatch(errorCreateEntidadesFinancieras(error.responseJSON.message));
                                }
                    else
                            error.json().then((error) => {
                                dispatch(errorCreateEntidadesFinancieras(JSON.parse(error.message)));
                            }).catch(() => {
                            dispatch(errorCreateEntidadesFinancieras(errorMessages.GENERAL_ERROR));
                        });


                        return;
                }
            });
    }
}

//DELETE ENTIDADFINANCIERA
export const DELETE_ENTIDADFINANCIERA = 'DELETE_ENTIDADFINANCIERA';
export const REQUEST_DELETE_ENTIDADFINANCIERA = "REQUEST_DELETE_ENTIDADFINANCIERA";
export const SUCCESS_DELETE_ENTIDADFINANCIERA = "SUCCESS_DELETE_ENTIDADFINANCIERA";
export const ERROR_DELETE_ENTIDADFINANCIERA = "ERROR_DELETE_ENTIDADFINANCIERA";
export const RESET_DELETE_ENTIDADFINANCIERA = "RESET_DELETE_ENTIDADFINANCIERA";

function requestDeleteEntidadFinanciera() {
    return {
        type: REQUEST_DELETE_ENTIDADFINANCIERA,
    }
}

function receiveDeleteEntidadFinanciera(entidadFinanciera) {
    return {
        type: SUCCESS_DELETE_ENTIDADFINANCIERA,
        receivedAt: Date.now(),
        entidadFinanciera: normalizeDato(entidadFinanciera)
    }
}

function errorDeleteEntidadFinanciera(error) {
    return {
        type: ERROR_DELETE_ENTIDADFINANCIERA,
        error: error,
    }
}

export function resetDeleteEntidadFinanciera(error) {
    return {
        type: RESET_DELETE_ENTIDADFINANCIERA,
        error: error,
    }
}

export function deleteEntidadFinanciera(entidadFinanciera) {
    return {
        type: DELETE_ENTIDADFINANCIERA,
        entidadFinanciera
    }
}

export function saveDeleteEntidadFinanciera(entidadFinanciera) {
    return dispatch => {
        dispatch(requestDeleteEntidadFinanciera());
        return entidadesFinancierasApi.saveDelete(entidadFinanciera)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteEntidadFinanciera());
                            dispatch(receiveDeleteEntidadFinanciera(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteEntidadFinanciera(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                if (error.responseJSON && error.responseJSON.message !== "")
                                    try
                                    {
                                        dispatch(errorDeleteEntidadFinanciera(JSON.parse(error.responseJSON.message)));
                                    } catch(e) {
                                        dispatch(errorDeleteEntidadFinanciera(error.responseJSON.message));
                                    }
                                    else
                                            error.json().then((error) => {
                                                dispatch(errorDeleteEntidadFinanciera(JSON.parse(error.message)));
                                            }).catch(()=> {
                                            dispatch(errorDeleteEntidadFinanciera(errorMessages.GENERAL_ERROR));
                                        });
                                    return;
                            }
                        });
    }
}

//PRINT ENTIDADFINANCIERA
export const REQUEST_PRINT_ENTIDADFINANCIERA = "REQUEST_PRINT_ENTIDADFINANCIERA";
export const SUCCESS_PRINT_ENTIDADFINANCIERA = "SUCCESS_PRINT_ENTIDADFINANCIERA";
export const ERROR_PRINT_ENTIDADFINANCIERA = "ERROR_PRINT_ENTIDADFINANCIERA";
export const RESET_PRINT_ENTIDADFINANCIERA = "RESET_PRINT_ENTIDADFINANCIERA";

function requestPrintEntidadFinanciera() {
    return {
        type: REQUEST_PRINT_ENTIDADFINANCIERA,
    }
}

function receivePrintEntidadFinanciera(turnos) {
    return {
        type: SUCCESS_PRINT_ENTIDADFINANCIERA,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintEntidadFinanciera(error) {
    return {
        type: ERROR_PRINT_ENTIDADFINANCIERA,
        error: error,
    }
}

export function resetPrintEntidadFinanciera() {
    return {
        type: RESET_PRINT_ENTIDADFINANCIERA,
    }
}

export function printEntidadFinanciera(idEntidadFinanciera) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintEntidadFinanciera());
        return entidadesFinancierasApi.printEntidadFinanciera(idEntidadFinanciera)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintEntidadFinanciera(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintEntidadFinanciera(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintEntidadFinanciera(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintEntidadFinanciera(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintEntidadFinanciera(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}