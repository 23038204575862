import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux';

//Images
import kitLogo from '../../../assets/img/kitLogo.png';

class PromocionDos extends Component {
    render() {
        const {kit, history} = this.props;
        var path = kitLogo;
        if (kit && kit.foto)
            path = process.env.PUBLIC_URL + "/img/kits/" +  kit.foto;

        //Precio
        var precioVigente = null;
        var precioKit = 0;
        kit.producto_kit.some((idProductoKit)=>{
            let productoKit = this.props.productosKits.byId.productosKits[idProductoKit];
            let producto = this.props.productos.byId.productos[productoKit.codigo_vd];
            precioKit = precioKit + (productoKit.cantidad * producto.precio);
        });

        if (kit.precio != 0) {
            var precioViejo = kit.precio;
            precioVigente = precioKit;
        }
        else {
            precioVigente = precioKit;
        }
        return (
            <Col md={6}>
                <div className="container-producto" onClick={() => {
                    history.push('/kitDetalle/' + kit.id)
                }}>
                    <Row className="tit-producto">
                        <h3>{kit ? kit.nombreKit : ""}</h3>
                        {/*<p>dos lineas</p>*/}
                    </Row>
                    <Row>
                        <Col md={8}>
                            <img src={path} alt=""/>

                        </Col>
                        <Col className="container-precio" md={4}>
                            <div className="dscPrecio">
                                <p>Precio sugerido consumidor final</p>
                                <p className="precioTachado">{precioViejo ? "USD " + precioViejo : ""}</p>
                                <p className="precio">{precioVigente ? "USD " + precioVigente : ""}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        );
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        productosKits: state.productosKits,
        kits: state.kits
    };
}

export default withRouter(connect(mapStateToProps)(PromocionDos));
