import * as errorMessages from '../../../constants/MessageConstants';

//Actions
import {logout} from "../../AuthenticationActions";

//api
import pullsdk from "../../../api/sdk/pullsdk";

//normalizer

//PULLSDK CREATE USR
export const CREATE_PULLSDK_USR = 'CREATE_PULLSDK_USR';
export const REQUEST_CREATE_PULLSDK_USR = "REQUEST_CREATE_PULLSDK_USR";
export const SUCCESS_CREATE_PULLSDK_USR = "SUCCESS_CREATE_PULLSDK_USR";
export const ERROR_CREATE_PULLSDK_USR = "ERROR_CREATE_PULLSDK_USR";
export const RESET_CREATE_PULLSDK_USR = "RESET_CREATE_PULLSDK_USR";


//CREATE
function requestCreatePullSDKUsr() {
    return {
        type: REQUEST_CREATE_PULLSDK_USR,
    }
}

function receiveCreatePullSDKUsr() {
    return {
        type: SUCCESS_CREATE_PULLSDK_USR,
        receivedAt: Date.now()
    }
}

export function errorCreatePullSDKUsr(error) {
    return {
        type: ERROR_CREATE_PULLSDK_USR,
        error: error,
    }
}

export function resetCreatePullSDKUsr(error) {
    return {
        type: RESET_CREATE_PULLSDK_USR
    }
}

export function createPullSDKUsr(pullsdk) {
    return {
        type: CREATE_PULLSDK_USR,
        pullsdk
    }
}

export function saveCreatePullSDKUsr(idHabilitacion, codAcceso) {
    return (dispatch, getState) => {
        dispatch(requestCreatePullSDKUsr());
        return pullsdk.saveCreate(getState().usuarios.update.activo, idHabilitacion, codAcceso)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveCreatePullSDKUsr());
                }
            })
            .then((pullsdk) => {
                // dispatch(resetCreatePullSDKUsr());
                // history.push("login");
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePullSDKUsr(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorCreatePullSDKUsr(error.message));
                                else
                                    dispatch(errorCreatePullSDKUsr(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorCreatePullSDKUsr(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

