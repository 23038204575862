import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

//Lodash
import pickBy from "lodash/pickBy";
import sampleSize from "lodash/sampleSize";

//Components
import Producto from '../producto/Producto';

//Images
import img1 from '../../../assets/img/img1.png';
import loadImg from '../../../assets/img/loader.gif';

//CSS
import '../../../assets/css/ProductosHome.css';

//Actions
import {fetchCategoriasIfNeeded} from "../../../actions/CategoriaActions";

class ProductosHome extends Component {

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
    }

    render() {
        var seleccionDestacados = pickBy(this.props.productos.byId.productos, function (producto, key) {
            return producto.destacado == 1;
        });
        var seleccionRandom = sampleSize(seleccionDestacados, 4);

        var Productos = seleccionRandom.map((producto) => {
            return <Producto producto={producto}/>
        });

        var Loader = () => {
            return (
                <div style={{textAlign: "center", float: "none", margin: "0 auto"}}>
                    <img style={{width: "32px"}} src={loadImg} alt=""/>
                </div>
            );
        }

        return (
            <section className="container-limit productos-home">
                <div className="separador hidden-sm hidden-xs"></div>
                <Row>
                    <Col md={4} className="visible-sm visible-xs hidden-md hidden-lg">
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <div className="know">
                                        <h4>CONOCÉ TODA LA VARIEDAD
                                            DE PRODUCTOS EN
                                            NUESTRA TIENDA</h4>
                                    </div>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                    <Col md={8} sm={12} className="linea-derecha">
                        {!this.props.categorias.byId.isFetching ? <div className="grid">{Productos}</div> : Loader()}
                    </Col>
                    <Col md={4} className="hidden-sm hidden-xs">
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <div className="compra-profesional">
                                        <img src={img1} alt=""/>
                                        <Button className="sombra"
                                                onClick={() => this.props.history.push("/compraProfesional")}>CONOCE
                                            MAS</Button>
                                    </div>
                                    <div className="know">
                                        <h4>CONOCÉ TODA LA VARIEDAD
                                            DE PRODUCTOS EN
                                            NUESTRA TIENDA</h4>
                                    </div>
                                </Row>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/*DIV PARA LA MINI LINEA, HAY QUE DESAPARECERLA*/}
                <Col md={4} className="pull-right hidden-sm hidden-xs">
                    <Row>
                        <Col md={12}>
                            <Row>
                                <div className="linea-corta">
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <div className="separador"></div>
                <Row>
                    <Col md={6} sm={6} xs={12}>
                        <div className="bigButton cam sombra"
                             onClick={() => this.props.history.push("categorias/videovigilancia")}>
                            <h4>
                                Cámaras IP y DVR
                            </h4>
                        </div>
                    </Col>
                    <Col md={6} sm={6} xs={12}>
                        <div className="bigButton casa sombra"
                             onClick={() => this.props.history.push("categorias/alarmas")}>
                            <h4>
                                Alarmas para negocios y Hogares
                            </h4>
                        </div>
                    </Col>
                </Row>
                <div className="separador"></div>
            </section>
        )
            ;
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductosHome));
