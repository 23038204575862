import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var ganadores = {

    getGanadores(idEvento) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/ganadores/' + idEvento, defaultOptions);
    }
}

export default ganadores;