import {combineReducers} from 'redux';
import {RECEIVE_ACCESOCUENTA_USUARIO, RECEIVE_ACCESOSCUENTA} from "../actions/AccesosCuentaActions";
import merge from "lodash/merge";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import union from "lodash/union";

function herramientaById(
    state = {
        isFetching: false,
        didInvalidate: true,
        herramientas: {},
    }, action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                herramientas: merge({}, state.herramientas, action.accesosCuenta.entities.herramientas),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                herramientas: merge({}, state.herramientas, action.accesosCuenta.entities.herramientas),
                lastUpdated: action.receivedAt
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                herramientas: {},
            });
        default:
            return state
    }
}

function allHerramientas(state = [], action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return action.accesosCuenta.entities.herramientas ? union(state, Object.keys(action.accesosCuenta.entities.herramientas)) : state;
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return action.accesosCuenta.entities.herramientas ? union(state, Object.keys(action.accesosCuenta.entities.herramientas)) : state;
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

const herramientas = combineReducers({
    byId: herramientaById,
    allIds: allHerramientas,
});

export default herramientas;