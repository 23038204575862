import {
    INVALIDATE_CLIENTES2,
    ERROR_CLIENTES2,
    RECEIVE_CLIENTES2,
    REQUEST_CLIENTES2,
    RESET_CLIENTES2,
    ERROR_CLIENTE2,
    RECEIVE_CLIENTE2,
    REQUEST_CLIENTE2,
    UPDATE_CLIENTE2,
    REQUEST_UPDATE_CLIENTE2,
    SUCCESS_UPDATE_CLIENTE2,
    ERROR_UPDATE_CLIENTE2,
    RESET_UPDATE_CLIENTE2,
    REQUEST_UPDATE_CLIENTES2,
    SUCCESS_UPDATE_CLIENTES2,
    ERROR_UPDATE_CLIENTES2,
    RESET_UPDATE_CLIENTES2,
    CREATE_CLIENTE2,
    ERROR_CREATE_CLIENTE2,
    REQUEST_CREATE_CLIENTE2,
    RESET_CREATE_CLIENTE2,
    SUCCESS_CREATE_CLIENTE2,
    REQUEST_CREATE_CLIENTES2,
    SUCCESS_CREATE_CLIENTES2,
    ERROR_CREATE_CLIENTES2,
    RESET_CREATE_CLIENTES2,
    DELETE_CLIENTE2,
    DELETE_CREATE_CLIENTE2,
    DELETE_UPDATE_CLIENTE2,
    REQUEST_DELETE_CLIENTE2,
    SUCCESS_DELETE_CLIENTE2,
    ERROR_DELETE_CLIENTE2,
    RESET_DELETE_CLIENTE2,
    REQUEST_PRINT_CLIENTE2,
    SUCCESS_PRINT_CLIENTE2,
    ERROR_PRINT_CLIENTE2,
    RESET_PRINT_CLIENTE2
} from '../actions/Cliente2Actions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        clientes2: {},
    }
}

function clientes2ById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_CLIENTES2:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CLIENTES2:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_CLIENTES2:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CLIENTES2:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                clientes2: {}
            });
        case RECEIVE_CLIENTES2:
            let dato = action.clientes2.entities.clientes2;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                clientes2: merge({}, state.clientes2, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_CLIENTE2:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_CLIENTE2:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_CLIENTE2:
            let datoCliente2 = action.cliente2.entities.clientes2;
            return Object.assign({}, state, {
                clientes2: merge({}, state.clientes2, datoCliente2),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_CLIENTE2:
            let datoCliente2Eliminado = action.cliente2.entities.clientes2;
            return Object.assign({}, state, {
                clientes2: mergeWith(clone(datoCliente2Eliminado), state.clientes2, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CLIENTE2:
            let datoCliente2Creado = action.cliente2.entities.clientes2;
            return Object.assign({}, state, {
                clientes2: mergeWith(clone(datoCliente2Creado), state.clientes2, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_CLIENTES2:
            let datosCliente2Creado = action.clientes2.entities.clientes2;
                return Object.assign({}, state, {
                    clientes2: mergeWith(clone(datosCliente2Creado), state.clientes2, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_CLIENTE2:
            let datoCliente2Actualizado = action.cliente2.entities.clientes2;
            return Object.assign({}, state, {
                clientes2: mergeWith(clone(datoCliente2Actualizado), state.clientes2, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_CLIENTES2:
            let datosCliente2Actualizado = action.clientes2.entities.clientes2;
                return Object.assign({}, state, {
                    clientes2: mergeWith(clone(datosCliente2Actualizado), state.clientes2, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                clientes2: {}
            });
        default:
            return state
    }
}


function allClientes2(state = [], action) {
    switch (action.type) {
        case RECEIVE_CLIENTES2:
            return action.clientes2.result && action.clientes2.result.clientes2 ? union(action.clientes2.result.clientes2, state) : (action.clientes2.result ? action.clientes2.result : state) ;
        case RECEIVE_CLIENTE2:
                return action.cliente2.result ? union([action.cliente2.result], state) : state;
        
        case SUCCESS_CREATE_CLIENTE2:
                   let datoCliente2SCreate = action.cliente2.entities.clientes2;
                   let idNuevoSCreate = null;
                   if (Object.values(datoCliente2SCreate).length > 0)
                       idNuevoSCreate = Object.values(datoCliente2SCreate)[0] && Object.values(datoCliente2SCreate)[0].id ? Object.values(datoCliente2SCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_CLIENTES2:
                   let clientes2Create = action.clientes2.entities && action.clientes2.entities.clientes2 ? action.clientes2.entities.clientes2 : null;
                   return clientes2Create ?
                       union(state, Object.values(clientes2Create).map((clientes2) => {
                           return clientes2.id
                       })) : state;
        case RESET_CLIENTES2:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalClientes2(state = null, action) {
    switch (action.type) {
        case RECEIVE_CLIENTES2:
            return action.clientes2 && action.clientes2.result.total ? action.clientes2.result.total : 0;
        case RESET_CLIENTES2:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_CLIENTE2:
            let dato = action.cliente2.entities.clientes2;
            let cliente2 = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: cliente2 ? cliente2 : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_CLIENTE2:
            let idsUpdate = [];
            Object.values(action.cliente2).map((cliente2Update) => {
                if (cliente2Update && cliente2Update.id)
                    idsUpdate.push(cliente2Update.id);
            });
            return merge({}, state, {
                activo: action.cliente2,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_CLIENTE2:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_CLIENTE2:
            let datoCliente2Actualizado = {};
            if (Object.values(action.cliente2.entities.clientes2).length > 0)
                datoCliente2Actualizado = Object.values(action.cliente2.entities.clientes2)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoCliente2Actualizado
            });
        case ERROR_UPDATE_CLIENTE2:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_CLIENTES2:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_CLIENTES2:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_CLIENTES2:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_CLIENTE2:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_CLIENTE2:
            let datoCliente2Delete = action.cliente2;
            let idsDelete = [];
           Object.values(action.cliente2).map((cliente2Delete) => {
               if (cliente2Delete && cliente2Delete.id)
                   idsDelete.push(cliente2Delete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoCliente2Delete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_CLIENTE2:
                       let datoCliente2DeleteUpdate = action.cliente2;
                       let idsDeleteUpdate = [];
                      Object.values(action.cliente2).map((cliente2Delete) => {
                          if (cliente2Delete && cliente2Delete.id)
                              idsDeleteUpdate.push(cliente2Delete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoCliente2DeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_CLIENTE2:
                    let datoCliente2Deleted = {};
                    if (Object.values(action.cliente2.entities.clientes2).length > 0)
                        datoCliente2Deleted = Object.values(action.cliente2.entities.clientes2)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoCliente2Deleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_CLIENTE2:
             let idsCreate = [];
             Object.values(action.cliente2).map((cliente2Create) => {
                 if (cliente2Create && cliente2Create.id)
                     idsCreate.push(cliente2Create.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.cliente2,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_CLIENTE2:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CLIENTE2:
            let datoCliente2Nuevo = {};
            if (Object.values(action.cliente2.entities.clientes2).length > 0)
                datoCliente2Nuevo = Object.values(action.cliente2.entities.clientes2)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoCliente2Nuevo,
                nuevos: []
            });
        case ERROR_CREATE_CLIENTE2:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_CLIENTES2:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_CLIENTES2:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_CLIENTES2:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CLIENTE2:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_CLIENTE2:
           let datoCliente2Delete = action.cliente2;
           let idsDelete = [];
           Object.values(action.cliente2).map((cliente2Delete) => {
               if (cliente2Delete && cliente2Delete.id)
                   idsDelete.push(cliente2Delete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoCliente2Delete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_CLIENTE2:
                  let datoCliente2DeleteCreate = action.cliente2;
                  let idsDeleteCreate = [];
                  Object.values(action.cliente2).map((cliente2Delete) => {
                      if (cliente2Delete && cliente2Delete.id)
                          idsDeleteCreate.push(cliente2Delete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoCliente2DeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_CLIENTE2:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.cliente2,
                error: null,
            });
        case REQUEST_DELETE_CLIENTE2:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_CLIENTE2:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_CLIENTE2:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_CLIENTE2:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_CLIENTE2:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_CLIENTE2:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_CLIENTE2:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const clientes2 = combineReducers({
    byId: clientes2ById,
    allIds: allClientes2,
    update: update,
    create: create,
    totalClientes2: totalClientes2,
    delete: deleter,
    print: print
});

export default clientes2;