import {
    INVALIDATE_PROVINCIAS,
    REQUEST_PROVINCIAS, RECEIVE_PROVINCIAS, ERROR_PROVINCIAS, RESET_PROVINCIAS
} from '../actions/ProvinciasActions';
import { combineReducers } from 'redux';
import normalizeDatos from "../normalizers/normalizeProvincias";

function provinciasById(state = {
    isFetching: false,
    didInvalidate: true,
    provincias: []
}, action) {
    switch (action.type) {
        case INVALIDATE_PROVINCIAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PROVINCIAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PROVINCIAS:
            let dato = normalizeDatos(action.provincias).entities.provincias;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                provincias: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_PROVINCIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PROVINCIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                provincias: {}
            });
        default:
            return state
    }
}


function allProvincias(state = [], action) {
    switch (action.type) {
        case RECEIVE_PROVINCIAS:
            return normalizeDatos(action.provincias).result ? normalizeDatos(action.provincias).result : [];
        case RESET_PROVINCIAS:
            return [];
        default:
            return state
    }
}


const provincias = combineReducers({
    byId : provinciasById,
    allIds : allProvincias
});

export default provincias;