import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'

//CSS
import '../../assets/css/Sumate.css';

//Images
import compra from '../../assets/img/comProfesionalBg.png';
import lock from '../../assets/img/lock.png';
import clase from '../../assets/img/class.png';
import screw from '../../assets/img/screw.png';
import asist from '../../assets/img/asist.png';


class Sumate extends Component {
    render() {

        return (
            <section className="tecnico">
                <div className="container-limit">
                    <Row>
                        <Col md={6} sm={5} xs={5}>
                                <img className="img-responsive" src={compra} alt=""/>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={7}>
                            <h2><span>Sumate a la </span> compra profesional</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={7}>
                            <p>Todas las compras realizadas en nuestro sitio incluyen el
                                servicio de un profesional.
                                Si sos instalador o integrador en seguridad electrónica,
                                esta puede ser una gran oportunidad para tu negocio.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} style={{borderBottom: "3px solid #00aeef", paddingBottom: "60px"}}>
                            <br/>
                            <Link to="/soluciones">Obtené mejores proyectos ></Link>
                        </Col>
                        <Col md={6}>
                            <Button onClick={()=>this.props.history.push("/compraProfesional")}>CONOCÉ MÁS</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={7}>
                            <h3>Los mejores proyectos se hacen en <span>  equipo</span></h3>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={7}>
                            <p>En Kit Experto desarrollamos un combinado de
                                soluciones que facilitan y complementan la labor
                                diaria de los Integradores en Seguridad Electrónica. </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={7} className="hidden-xs">
                            <p>
                                <sb>Registrate gratis para obtener:</sb>
                            </p>
                        </Col>
                    </Row>

                    <div className="sliderFeatures hidden-xs">
                        <OwlCarousel className="owl-theme" loop nav items={4} margin={1} navText="">
                            <div className="item">
                                <div>
                                    <img className="img-responsive" src={lock} alt=""/>
                                    <p>Despacho prioritario</p>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img className="img-responsive" src={clase} alt=""/>
                                    <p>Entrenamientos Gratuitos</p>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img className="img-responsive" src={screw} alt=""/>
                                    <p>Sistema de herramientas on-line customizable</p>
                                </div>
                            </div>
                            <div className="item">
                                <div>
                                    <img className="img-responsive" src={asist} alt=""/>
                                    <p>Asistencia profesional para proyectos</p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                    <Row>
                        <Col md={12} className="bigRegistro">
                            <Button onClick={()=>this.props.history.push("/registrar")}>REGISTRATE GRATIS</Button>
                        </Col>
                    </Row>
                </div>
            </section>
        );
    }
}

export default withRouter(Sumate);
