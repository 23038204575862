export const ARMANDO= 'Armando';
export const LISTO_PARA_ENTREGAR = 'Listo para entregar';
export const EN_TRANSITO = 'En tránsito';
export const RECIBIDO  = 'Recibido';
export const NO_RECIBIDO  = 'No Recibido';
export const RECIBIDO_CON_INCONVENIENTES  = 'Recibido con inconvenientes';
export const ARMANDO_CONFIGURACION = "Armando/En configuración";
export const RESUELTO  = 'Resuelto';
export const CANCELADO  = 'Cancelado';
export const ENTREGADO = 'Entregado';
