import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import "./BotonPago.css";
import {Row} from "react-bootstrap";
import EstadosPago from "./EstadosPago";
import rechazar from "../../../../assets/img/pago/rechazar.png";
import confirmar from "../../../../assets/img/pago/confirmar.png";
import volver from "../../../../assets/img/pago/volver.png";
import {createPago, fetchPago, saveUpdatePago, updatePago} from "../../../../actions/PagoActions";
import {fetchDolarIfNeeded} from "../../../../actions/ListaPreciosActions";
import {fetchPlanesCuotasIfNeeded} from "../../../../actions/PlanesCuotasActions";
import {fetchMediosPagoIfNeeded, resetMediosPago} from "../../../../actions/MediosPagoActions";
import {fetchProvinciasIfNeeded} from "../../../../actions/ProvinciasActions";
import * as moneda from "../../../../constants/Moneda";
import * as roles from "../../../../constants/Roles";
import * as EstadoPago from "../../../../constants/EstadoPago";
import max from "lodash/max"
import isNil from "lodash/isNil"

export default function LinkPagoCliente(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();
    const mounted = useRef();
    const mounted2 = useRef();
    const form = useRef();
    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;
    const planesCuotas = useSelector(state => state.planesCuotas);
    const listaPrecio = useSelector(state => state.listaPrecio);
    const estadosPago = useSelector(state => state.estadosPago);
    const usuarios = useSelector(state => state.usuarios);
    const usuarioLogueado = usuarios.byId.usuarioLogueado;
    const cotizacionDolar = listaPrecio.dolar && listaPrecio.dolar.dolar && listaPrecio.dolar.dolar.dolar ? parseFloat(listaPrecio.dolar.dolar.dolar.replace(",", ".")).toFixed(2) : 0;

    //State
    const [modalConfirmacion, setModalConfirmacion] = useState(false);

    const esEmpleadoKitAdministracion = usuarioLogueado && (usuarioLogueado.idRol === roles.ADMINISTRACION_ID || usuarioLogueado.idRol === roles.ADMINISTRADOR_ID);
    const ultimoEstadoPago = estadosPago.byId.estadosPago[max(pago.estados_pago)];
    const calcEstadoActivo = () => {
        if (ultimoEstadoPago)
            switch (ultimoEstadoPago.desEstadoPago) {
                case EstadoPago.LINK_GENERADO:
                    return 0;
                case EstadoPago.PENDIENTE:
                    return 1;
                case EstadoPago.CONFIRMADO:
                    return 2;
                case EstadoPago.CONFIRMADO_DIFERENCIAS:
                    return 3;
                case EstadoPago.RECHAZADO:
                    return 4;
                default:
                    return null;

            }
    };
    const estadoPagoActual = calcEstadoActivo();

    console.log("ESTADO", estadoPagoActual, ultimoEstadoPago);

    const disabled = !esEmpleadoKitAdministracion || estadoPagoActual === 0;

    //Hooks
    //Effects
    //Effects
    useEffect(() => {
        dispatch(fetchDolarIfNeeded());
        dispatch(fetchPlanesCuotasIfNeeded());
        dispatch(fetchProvinciasIfNeeded());
        dispatch(fetchMediosPagoIfNeeded({estado: true, habilitadoLink: true}));
        if (idPago)
            dispatch(fetchPago(idPago));
        return function cleanup() {
            dispatch(resetMediosPago())
        }
    }, []);

    useEffect(() => {
        console.log("entro")
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!pagos.byId.isFetching && pago && !pago.importeAcreditado && pago.importe != null) {
                let cambio = {};
                cambio.importeAcreditado = pago.importe;
                dispatch(updatePago(cambio));
            }
            if (!pagos.byId.isFetching) {
                let cambio = {};
                cambio.evaluarComprobantes = false;
                dispatch(updatePago(cambio));
            }
        }
    }, [pagos.byId.isFetching]);

    useEffect(() => {

        if (!mounted2.current) {
            mounted2.current = true;
        } else {
            console.log("entro act")
            let cambio = {};
            let diferenciaAcreditacion = pago.diferenciaAcreditacion ? pago.diferenciaAcreditacion : 0;
            let gastoAnulacion = pago.gastoAnulacion ? pago.gastoAnulacion : 0;
            let importe = !isNaN(parseFloat(pago.importe)) ? parseFloat(pago.importe) : 0;
            let importeAcreditado = 0;
            if (pago.tipoDiferenciaAcreditacion === "En contra" || pago.tipoDiferenciaAcreditacion === "Rechazado" || pago.tipoDiferenciaAcreditacion === "Sin diferencia")
                importeAcreditado = (importe - diferenciaAcreditacion - gastoAnulacion).toFixed(2);
            else if (pago.tipoDiferenciaAcreditacion === "A favor"){
                importeAcreditado = (importe + diferenciaAcreditacion - gastoAnulacion).toFixed(2);}
            cambio.importeAcreditado = importeAcreditado;
            cambio.importeAcreditadoEnMoneda = null;
            if (pago.idMonedaAcreditacion) {
                if (pago.idMonedaAcreditacion === pago.idMonedaPago)
                    cambio.importeAcreditadoEnMoneda = importeAcreditado;
                else if (pago.idMonedaAcreditacion !== pago.idMonedaPago && pago.idMonedaAcreditacion === moneda.DOLAR_ID && pago.tasaCambioAcreditacion)
                    cambio.importeAcreditadoEnMoneda = (importeAcreditado / pago.tasaCambioAcreditacion).toFixed(2);
                else if (pago.idMonedaAcreditacion !== pago.idMonedaPago && pago.idMonedaAcreditacion === moneda.PESO_ID && pago.tasaCambioAcreditacion)
                    cambio.importeAcreditadoEnMoneda = (importeAcreditado * pago.tasaCambioAcreditacion).toFixed(2);
                if (pago.idMonedaAcreditacion !== pago.idMonedaPago && !pago.tasaCambioAcreditacion)
                    cambio.importeAcreditadoEnMoneda = null;
            }
            console.log(cambio.importeAcreditado, pago.importeAcreditado, cambio.importeAcreditadoEnMoneda, pago.importeAcreditadoEnMoneda)
            if ((cambio.importeAcreditado && cambio.importeAcreditado !== pago.importeAcreditado) || cambio.importeAcreditadoEnMoneda !== pago.importeAcreditadoEnMoneda)
                dispatch(updatePago(cambio));

        }
    }, [pago]);


    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id === "importeAcreditado")
            cambio[e.target.id] = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null;
        if (e.target.id === "idMonedaAcreditacion") {
            cambio[e.target.id] = !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null;
            cambio.tasaCambioAcreditacion = null;
        }
        if (e.target.id === "tasaCambioAcreditacion")
            cambio[e.target.id] = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null;
        if (e.target.id === "gastoAnulacion") {
            cambio[e.target.id] = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null;
        }
        if (e.target.id === "diferenciaAcreditacion") {
            cambio[e.target.id] = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : null;
        }
        if (e.target.id === "tipoDiferenciaAcreditacion") {
            if (e.target.value === "Sin diferencia")
                cambio.diferenciaAcreditacion = 0;
            else if (e.target.value === "Rechazado")
                cambio.diferenciaAcreditacion = pago.importe;
            else
                cambio.diferenciaAcreditacion = null;
        }
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (form.current.reportValidity())
            dispatch(saveUpdatePago(null));
        setModalConfirmacion(false);
    };

    const onConfirm = (e) => {
        let cambio = {};
        cambio.estadoPago = "Confirmado";
        cambio.evaluarComprobantes = false;
        dispatch(updatePago(cambio));
        if (form.current.reportValidity())
            setModalConfirmacion(true);
    };

    const openModalConfirmacion = (e) => {
        e.preventDefault();
        form.current.dispatchEvent(new Event('submit', {cancelable: true}));
    };


    return (
        <div className="linkpago">
            <form ref={form} onSubmit={onSubmit}>
                <div>
                    <Row className="container-formulario">
                        <div className="titulo">
                            <h2>Datos del pago</h2>
                            <EstadosPago ultimoEstado={calcEstadoActivo()} idTipoPago={pago.idTipoPago}/>
                        </div>
                        <div className="input-row">
                            <label htmlFor="importe">Importe</label>
                            <input id="importe" type="number"
                                   name="importe"
                                   placeholder="Importe"
                                   disabled
                                   value={pago && pago.importe ? pago.importe : ""}
                                   onChange={(e) => onChangePago(e)}
                            />
                        </div>
                        <div className="input-row">
                            <label htmlFor="coeficienteCostoFinanciero">Coeficiente costo financiero</label>
                            <input id="coeficienteCostoFinanciero" type="number"
                                   name="coeficienteCostoFinanciero"
                                   placeholder="Coeficiente costo financiero"
                                   disabled
                                   value={pago && pago.coeficienteCostoFinanciero ? pago.coeficienteCostoFinanciero : ""}
                                   onChange={(e) => onChangePago(e)}
                            />
                        </div>
                        <div className="input-row">
                            <label htmlFor="importeConCostoFinanciero">Importe con costo financiero</label>
                            <input id="importeConCostoFinanciero" type="number"
                                   name="importeConCostoFinanciero"
                                   placeholder="Importe con costo financiero"
                                   disabled
                                   value={pago && pago.importeConCostoFinanciero ? pago.importeConCostoFinanciero : ""}
                                   onChange={(e) => onChangePago(e)}
                            />
                        </div>
                        <div className="input-row">
                            <label htmlFor="idMonedaPago">Moneda pago</label>
                            <select
                                id="idMonedaPago"
                                disabled
                                value={pago.idMonedaPago ? pago.idMonedaPago : ""}
                                onChange={(e) => onChangePago(e)}>
                                <option value={moneda.DOLAR_ID}>Dolar</option>
                                <option value={moneda.PESO_ID}>Peso</option>
                            </select>
                        </div>
                    </Row>
                    <Row className="container-formulario">
                        <h2>Datos de la acreditación del pago</h2>
                        {
                            ["Sin diferencia", "En contra", "A favor", "Rechazado"].map((valor) => {

                                return (
                                    <div style={{display: "flex", alignItems: "flex-end"}}>
                                        <div className="item-checkradio">
                                            <input type="radio" name="tipoDiferenciaAcreditacion"
                                                   id="tipoDiferenciaAcreditacion"
                                                   className={disabled ? "disabled" : ""}
                                                   required
                                                   disabled={disabled}
                                                   checked={pago.tipoDiferenciaAcreditacion === valor}
                                                   value={valor}
                                                   onChange={(e) => onChangePago(e)}
                                            />
                                            <label>{valor}</label>
                                        </div>
                                        {
                                            pago.tipoDiferenciaAcreditacion === valor && pago.tipoDiferenciaAcreditacion !== "Sin diferencia" &&
                                            <div className="input-row" style={{marginLeft: "10px"}}>
                                                <label htmlFor="diferenciaAcreditacion">Diferencia en la acreditación
                                                    del pago</label>
                                                <input id="diferenciaAcreditacion" type="number"
                                                       name="diferenciaAcreditacion"
                                                       placeholder="xxx"
                                                       disabled={disabled}
                                                       required
                                                       value={pago && !isNil(pago.diferenciaAcreditacion) ? pago.diferenciaAcreditacion : ""}
                                                       onChange={(e) => onChangePago(e)}
                                                />
                                            </div>

                                        }
                                    </div>
                                )
                            })
                        }
                        {
                            pago && pago.tipoDiferenciaAcreditacion && pago.tipoDiferenciaAcreditacion !== "Sin diferencia" &&
                            <div className="input-row">
                                <label htmlFor="gastoAnulacion">Gasto administrativo por transacciones érroneas</label>
                                <input id="gastoAnulacion" type="number"
                                       name="gastoAnulacion"
                                       disabled={disabled}
                                       placeholder="Gasto de anulación"
                                       required
                                       value={pago && !isNil(pago.gastoAnulacion) ? pago.gastoAnulacion : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                        }

                        <div className="input-row">
                            <label
                                htmlFor="importeAcreditado">{`Importe a acreditar en ${pago.idMonedaPago === moneda.DOLAR_ID ? "Dolares" : "Pesos"}`}</label>
                            <input id="importeAcreditado" type="number"
                                   name="importeAcreditado"
                                   placeholder="Importe a acreditar"
                                   required
                                   disabled
                                   value={pago && !isNil(pago.importeAcreditado) ? pago.importeAcreditado : ""}
                                   onChange={(e) => onChangePago(e)}
                            />
                        </div>
                        {
                            pago && pago.tipoDiferenciaAcreditacion && pago.tipoDiferenciaAcreditacion !== "Sin diferencia" &&
                            <div className="input-row">
                                <label htmlFor="motivoAnulacion">Motivo de diferencias</label>
                                <input id="motivoAnulacion" type="text"
                                       name="motivoAnulacion"
                                       disabled={disabled}
                                       placeholder="Motivo de diferencias"
                                       value={pago && pago.motivoAnulacion ? pago.motivoAnulacion : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                        }
                        <div className="input-row">
                            <label htmlFor="idMonedaAcreditacion">Moneda acreditación pago</label>
                            <select
                                id="idMonedaAcreditacion"
                                required
                                disabled={disabled}
                                value={pago.idMonedaAcreditacion ? pago.idMonedaAcreditacion : ""}
                                onChange={(e) => onChangePago(e)}>
                                <option value="">Seleccione moneda acreditación pago</option>
                                <option value={moneda.DOLAR_ID}>Dolar</option>
                                <option value={moneda.PESO_ID}>Peso</option>
                            </select>
                        </div>
                        {
                            (pago && pago.idMonedaAcreditacion && pago.idMonedaPago !== pago.idMonedaAcreditacion) &&
                            <div className="input-row">
                                <label htmlFor="tasaCambioAcreditacion">Tasa cambio acreditación</label>
                                <input id="tasaCambioAcreditacion" type="number"
                                       name="tasaCambioAcreditacion"
                                       disabled={disabled}
                                       placeholder="Tasa cambio acreditación"
                                       required
                                       value={pago && pago.tasaCambioAcreditacion ? pago.tasaCambioAcreditacion : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                        }
                        {
                            (pago && pago.idMonedaAcreditacion && pago.idMonedaPago !== pago.idMonedaAcreditacion) &&
                            <div>
                                <label
                                    htmlFor="cotizacionDolar">{`Cotización según BNA u$d 1 = $${cotizacionDolar}`}</label>
                            </div>
                        }
                        {
                            pago && pago.idMonedaAcreditacion &&
                            <div className="input-row">
                                <label
                                    htmlFor="importeAcreditado">{`Importe a acreditar en moneda acreditación ${pago.idMonedaAcreditacion === moneda.DOLAR_ID ? "Dolares" : "Pesos"}`} </label>
                                <input id="importeAcreditadoEnMoneda" type="number"
                                       name="importeAcreditadoEnMoneda"
                                       placeholder="Importe a acreditar"
                                       required
                                       disabled
                                       value={pago && !isNil(pago.importeAcreditadoEnMoneda) ? pago.importeAcreditadoEnMoneda : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                        }
                        <div className="input-row">
                            <label htmlFor="motivoAnulacion">Número de recibo</label>
                            <input id="nroRecibo" type="text"
                                   name="nroRecibo"
                                   disabled={disabled}
                                   placeholder="Número de recibo"
                                   value={pago && pago.nroRecibo ? pago.nroRecibo : ""}
                                   onChange={(e) => onChangePago(e)}
                            />
                        </div>

                    </Row>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-izq">
                                <button type="button" className="boton-redondo outline"
                                        onClick={() => props.history.push("/panel/listaMonitoresPagos/1")}>
                                    <img src={volver}/>
                                    Volver
                                </button>
                            </div>
                            <div className="botonera-der">
                                <button type="button" className="boton-redondo outline" disabled={disabled}
                                        onClick={onConfirm}>
                                    <img src={confirmar}/>
                                    Aceptar
                                </button>
                            </div>

                        </div>

                    </Row>
                </div>
            </form>
            {
                modalConfirmacion && pago &&
                <div className="modal-token">
                    <h2>{`Confirma los cambios del pago?`}</h2>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-der"></div>
                            <div className="botonera-izq">
                                <button type="button" className="boton-default"
                                        onClick={() => setModalConfirmacion(false)}>
                                    Cancelar
                                </button>
                                <button type="button" className="boton-default"
                                        onClick={(e) => openModalConfirmacion(e)}>
                                    Aceptar
                                </button>
                            </div>
                        </div>
                    </Row>
                </div>
            }
        </div>
    )
        ;
}
