import {normalize, schema} from 'normalizr';

export function normalizeDato(myData) {

    const cliente = new schema.Entity('clientes', {}, {idAttribute: "id"});
    const encuestaRespuesta = new schema.Entity('encuestaRespuestas', {}, {idAttribute: "id"});
    const landing = new schema.Entity('landing', {}, {idAttribute: "id"});
    const usuario = new schema.Entity('usuarios', {
        cliente: cliente,
        encuesta_respuesta: [encuestaRespuesta],
        landing: landing
    }, {idAttribute: "id"});

    const mySchema = usuario;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function normalizeDatos(myData) {


    const cliente = new schema.Entity('clientes', {}, {idAttribute: "id"});
    const encuestaRespuesta = new schema.Entity('encuestaRespuestas', {}, {idAttribute: "id"});
    const usuario = new schema.Entity('usuarios', {cliente: cliente,encuesta_respuesta: [encuestaRespuesta]}, {idAttribute: "id"});

    const mySchema = [usuario];
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

