import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, MenuItem, DropdownButton} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import siera from '../../../assets/img/evento/siera.png';
import kit from '../../../assets/img/evento/kit.png';
import banner from '../../../assets/img/evento/preguntas/header.png';
import premios from '../../../assets/img/evento/preguntas/premios.png';
import footer from '../../../assets/img/evento/preguntas/footer.png';

import '../../../assets/css/PreguntasEvento.css';


import {
    changeUsuarioLogueado,
    fetchUsuarioIfNeeded, invalidateUsuario, resetCreateUsuarioLogueado,
    resetUpdateUsuarioLogueado
} from "../../../actions/UsuarioActions";

import {createPregunta, saveCreatePregunta, saveJugaPregunta} from "../../../actions/PreguntasActions";

import isObject from "lodash/isObject";
import isString from "lodash/isString";


import Loader from '../../elementos/loader/Loader';
import FieldGroup from "../../camposTexto/FieldGroup";

import FieldGroupSubmit from "../../camposTexto/FieldGroupSubmit";

class PreguntasEvento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: "",
        };

        this.submitFormDni = this.submitFormDni.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.changeSelect = this.changeSelect.bind(this);
        this.submitJuga = this.submitJuga.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.params.dni) {
            this.props.invalidateUsuario();
            this.props.resetCreateUsuarioLogueado();
            this.props.resetUpdateUsuarioLogueado();
            this.props.fetchUsuarioIfNeeded(this.props.match.params.dni);
            const usuario = this.props.usuarios.byId.usuarioLogueado;
            this.setState({idUsuario: usuario.id ? usuario.id : null})
            this.refs.datos.classList.add("in");
        }
    }

    submitFormDni(e) {
        e.preventDefault();
        this.props.invalidateUsuario();
        this.props.resetCreateUsuario();
        this.props.resetUpdateUsuarioLogueado();
        this.props.fetchUsuarioIfNeeded(this.state.dni);
        this.refs.datos.classList.add("in");
    }

    submitForm(e) {
        e.preventDefault();
        if(this.props.preguntas.create.nuevo.idTema){
            this.props.createPregunta({idUsuario: this.props.usuarios.byId.usuarioLogueado.id});
            this.props.saveCreatePregunta();
        }else{
            alert("Seleccione tema");
        }
    }

    submitJuga(e){
        e.preventDefault();
        this.props.saveJugaPregunta(this.props.usuarios.byId.usuarioLogueado.dni);
    }

    changeDni(e) {
        this.setState({dni: e.target.value});
    }

    onChangePregunta(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.createPregunta(cambio);
    }

    changeSelect(eventKey) {
        let cambio = {};

        switch (eventKey) {
            case "1":
                cambio.desTema = "Video Vigilancia Siera HVR / NVR / MDVR / Cámaras HD 4 en 1 e IP";
                cambio.idTema = 1;
                break;
            case "2":
                cambio.desTema = "Cámaras Térmicas, Fisheye (Ojo de Pez) y LPR";
                cambio.idTema = 2;
                break;
            case "3":
                cambio.desTema = "Sistema 5000IP - Sistema de video Portero IP para edificios con integración a Domótica.";
                cambio.idTema = 3;
                break;
            case "4":
                cambio.desTema = "Controles de Acceso";
                cambio.idTema = 4;
                break;
        }
        this.props.createPregunta(cambio);
    }

    render() {
        const usuario = this.props.usuarios.byId.usuarioLogueado;
        const pregunta = this.props.preguntas.create.nuevo;

        const error = this.props.preguntas.juga.error;

        var msgError = null;

        if (error && isObject(error.message) && !isString(error.message)) {
            msgError = Object.keys(error.message).map(function (key, index) {
                return (<li>{error.message[key]}</li>);
            });
        } else {
            msgError = <h3>{error ? error.message : ""}</h3>;
        }

        return (
            <div className="preguntas">
                <div className="container-limit">
                    <div className="header">
                        <img src={siera} style={{paddingTop: "10px"}} alt=""/>
                        <Link to="/"><img src={kit} className="pull-right" alt=""/></Link>
                    </div>
                    <div className="banner">
                        <img className="img-responsive" src={banner} alt=""/>
                    </div>
                    <Row>
                        <Col md={12}>
                            <form ref="dni" onSubmit={(e) => {
                                this.submitFormDni(e)
                            }} hidden={this.props.match.params.dni}>
                                <FieldGroupSubmit
                                    id="dni"
                                    type="number"
                                    label="Ingresar DNI"
                                    placeholder=""
                                    required={true}
                                    value={this.state.dni}
                                    onChange={(e) => {
                                        this.changeDni(e)
                                    }}
                                />
                            </form>

                            <div className="collapse" ref="datos">
                                <Col md={11} className="center-block">
                                    {
                                        this.props.usuarios.byId.error ? <h3>No está en nuestro registro de datos</h3>
                                            :
                                            <h3>{usuario.nombre ? usuario.nombre + " " : ""}Hacenos tu pregunta sobre el
                                                tema que te interese y
                                                la responderemos al finalizar la charla correspondiente</h3>
                                    }
                                </Col>
                                <div hidden={this.props.usuarios.byId.error}>
                                    <form ref="ask" onSubmit={(e) => {
                                        this.submitForm(e)
                                    }}>

                                        <Row className="select-tema">
                                            <label htmlFor="tema">Elegir Tema</label>
                                            <DropdownButton
                                                id="tema"
                                                title={pregunta.desTema ? pregunta.desTema : ""}
                                                onSelect={eventKey => this.changeSelect(eventKey)}
                                                style={{zIndex: "1111"}}
                                            >
                                                <MenuItem eventKey="1">Video Vigilancia Siera HVR / NVR / MDVR / Cámaras
                                                    HD
                                                    4 en 1 e
                                                    IP</MenuItem>
                                                <MenuItem eventKey="2">Cámaras Térmicas, Fisheye (Ojo de Pez) y
                                                    LPR</MenuItem>
                                                <MenuItem eventKey="3">Sistema 5000IP - Sistema de video Portero IP para
                                                    edificios
                                                    con integración a Domótica.</MenuItem>
                                                <MenuItem eventKey="4">Controles de Acceso</MenuItem>
                                            </DropdownButton>
                                        </Row>

                                        <FieldGroup
                                            id="pregunta"
                                            type="text"
                                            label="¿Cuál es tu pregunta? Usá palabras referenciales (ej. uso, características, etc) "
                                            placeholder=""
                                            required={true}
                                            value={pregunta.pregunta ? pregunta.pregunta : ""}
                                            onChange={(e) => {
                                                this.onChangePregunta(e)
                                            }}
                                        />

                                        <div className="boton">
                                            {
                                                this.props.registros.create.isCreating ?
                                                    <Loader/>
                                                    :
                                                    <Button type="submit" className="center-block">Enviar
                                                        Pregunta</Button>
                                            }
                                        </div>
                                    </form>

                                    <div className="linea-verde"></div>

                                    <form onSubmit={(e) => {
                                        this.submitJuga(e)}} >
                                        <h3 style={{marginBottom: "20px"}}>Participá del sorteo</h3>
                                        <div className="boton-juga">
                                            {
                                                this.props.preguntas.juga.isCreating ?
                                                    <Loader/>
                                                    :
                                                    <Button type="submit" className="center-block">¡Jugá!</Button>
                                            }

                                            <p>El botón se activará al momento del sorteo. <br/>
                                                El sorteo es exclusivameante presencial. </p>
                                            <h3>{this.props.preguntas.juga ? this.props.preguntas.juga.message : ""}</h3>
                                            {msgError}
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="premios">
                                <h3>Premios</h3>
                                <img src={premios} className="img-responsive" alt=""/>
                            </div>


                        </Col>
                    </Row>

                </div>
                <div className="footer">
                    <img src={footer} className="img-responsive" alt=""/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        registros: state.registros,
        preguntas: state.preguntas
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsuarioIfNeeded: (dni) => {
            dispatch(fetchUsuarioIfNeeded(dni));
        },
        resetCreateUsuarioLogueado: () => {
            dispatch(resetCreateUsuarioLogueado())
        },
        resetUpdateUsuarioLogueado: () => {
            dispatch(resetUpdateUsuarioLogueado())
        },
        invalidateUsuario: () => {
            dispatch(invalidateUsuario());
        },
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario));
        },
        saveCreatePregunta: () => {
            dispatch(saveCreatePregunta());
        },
        createPregunta: (pregunta) => {
            dispatch(createPregunta(pregunta));
        },
        saveJugaPregunta:(dni) => {
            dispatch(saveJugaPregunta(dni))
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreguntasEvento));
