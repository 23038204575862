import {
    INVALIDATE_TRANSPORTES,
    REQUEST_TRANSPORTES, RECEIVE_TRANSPORTES, ERROR_TRANSPORTES, RESET_TRANSPORTES
    } from '../actions/TransporteActions';
import { combineReducers } from 'redux';
import normalizeDatos from "../normalizers/normalizeTransportes";
import merge from 'lodash/merge';
import union from 'lodash/union';

function transportesById(state = {
    isFetching: false,
    didInvalidate: true,
    transportes: {}
}, action) {
    switch (action.type) {
        case INVALIDATE_TRANSPORTES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_TRANSPORTES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_TRANSPORTES:
            let transporte = normalizeDatos(action.transportes).entities.transportes;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                // transportes: dato ? dato : [],
                transportes: merge({}, state.transportes, transporte),
                lastUpdated: action.receivedAt
            });
        case ERROR_TRANSPORTES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_TRANSPORTES:
            return Object.assign({}, state, {
                isFetching: false,
                transportes: {},
                error: null
            });
        default:
            return state
    }
}


function allTransportes(state = [], action) {
    switch (action.type) {
        case RECEIVE_TRANSPORTES:
            // return normalizeDatos(action.transportes).result ? normalizeDatos(action.transportes).result : [];
            return union(state, normalizeDatos(action.transportes).result);
        case RESET_TRANSPORTES:
            return [];
        default:
            return state
    }
}



const transportes = combineReducers({
    byId : transportesById,
    allIds : allTransportes,
});


export default transportes;