import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){
    const usuario = new schema.Entity('usuarios',{}, {idAttribute:"id_usr"});
    const ganador = new schema.Entity('ganadores',{usuario:usuario}, {idAttribute:"id_usr"});
    const mySchema = [ ganador ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;