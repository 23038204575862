import React, {Component} from 'react';
import {Row, Col, Breadcrumb} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

//css
import '../../assets/css/ProductoDetalle.css';
import 'react-image-gallery/styles/css/image-gallery.css';

//componentes
import Footer2 from '../layout/Footer2';
import OtrosProductos from "../elementos/productos/OtrosProductos";

//imagenes
import tool from '../../assets/img/bigTool.png';
import compra from '../../assets/img/conTarjeta.png';
import mail from '../../assets/img/mailBig.png';
import face from '../../assets/img/faceBig.png';
import  entrega from "../../assets/img/entregasPais.png";
import kitLogo from '../../assets/img/kitLogo.png';

//Actions
import {fetchKitsIfNeeded} from "../../actions/KitActions";

class ProductoDetalle extends Component {

    componentDidMount() {
        this.props.fetchKitsIfNeeded();
        console.log(this.props.match.params);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params["idKit"] != prevProps.match.params["idKit"])
            window.scrollTo(0, 0);
    }

    render() {
        const {history} = this.props;
        var path = kitLogo;
        var path2 = kitLogo;
        var path3 = kitLogo;
        var path4 = kitLogo;
        var images = [];
        //kit
        let idKit = this.props.match.params["idKit"];
        if (idKit)
            var kit = this.props.kits.byId.kits[idKit];
        if (kit) {
            var categoria = this.props.categorias.byId.categorias[kit.grupo];
            if (kit.pathImg) {
                try {
                    path = process.env.PUBLIC_URL + "/img/kits/" +  + kit.pathImg;
                } catch (e) {
                }
                images.push({original: path, thumbnail: path});
            }
            kit.producto_kit.some((idProductoKit) => {
                let productoKit = this.props.productosKits.byId.productosKits[idProductoKit];
                let producto = this.props.productos.byId.productos[productoKit.codigo_vd];
                try {
                    if (producto.detalle_producto) {
                        if (producto.detalle_producto.img1) {
                            path = process.env.PUBLIC_URL + "/img/productos/" + producto.detalle_producto.img1;
                            images.push({original: path, thumbnail: path});
                        }
                        if (producto.detalle_producto.img2) {
                            path2 = process.env.PUBLIC_URL + "/img/productos/" +  producto.detalle_producto.img2;
                            images.push({original: path2, thumbnail: path2});
                        }
                        if (producto.detalle_producto.img3) {
                            path3 = process.env.PUBLIC_URL + "/img/productos/" + producto.detalle_producto.img3;
                            images.push({original: path3, thumbnail: path3});
                        }
                        if (producto.detalle_producto.img4) {
                            path4 = process.env.PUBLIC_URL + "/img/productos/" + producto.detalle_producto.img4;
                            images.push({original: path4, thumbnail: path4});
                        }
                    }
                } catch (e) {
                }
            });

            //Precio
            var precioVigente = null;
            var precioKit = 0;
            kit.producto_kit.some((idProductoKit)=>{
                let productoKit = this.props.productosKits.byId.productosKits[idProductoKit];
                let producto = this.props.productos.byId.productos[productoKit.codigo_vd];
                precioKit = precioKit + (productoKit.cantidad * producto.precio);
            });

            if (kit.precio != 0) {
                var precioViejo = kit.precio;
                precioVigente = precioKit;
            }
            else {
                precioVigente = precioKit;
            }
        }


        return (
            <div>
                <section className="producto-detalle">
                    <div className="container-limit">
                        <br/>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => {
                                history.push('/')
                            }}>
                                Inicio
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => {
                                history.push('/productos/')
                            }}>
                                productos
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => {
                                history.push("/categorias/" + categoria.nombre.replace(/\s/g, "").toLowerCase())
                            }}>
                                {categoria ? categoria.desCategoria : ""}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {kit ? kit.nombre : ""}
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        <Row>
                            <Col md={6}>
                                <h1>{kit ? kit.nombre : ""}</h1>
                                <p className="codigo">CÓDIGO <span>{kit ? kit.codigo : ""}</span></p>
                            </Col>
                            <Col md={6}>
                                <div className="img-galeria">
                                    <img src="" alt=""/>
                                    <ImageGallery
                                        items={images}
                                        slideInterval={2000}
                                        onImageLoad={this.handleImageLoad}
                                        showNav={false}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        showBullets={false}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Row style={{marginTop: "50px"}}>
                                    {/*<Col md={6} xs={6} sm={6} className="marca sin-padding">*/}
                                        {/*<img className="img-responsive" src={zk} alt=""/>*/}
                                    {/*</Col>*/}
                                    <Col md={12} xs={12} sm={12} className="precio">
                                        <p>Precio sugerido consumidor final</p>
                                        <p style={{top: "-45px"}}>{precioViejo ? "Precio anterior: " + precioViejo : ""}
                                            <p style={{left: "0px", fontSize: "19px"}}
                                               className="precioTachado">{precioViejo ? " USD " + precioViejo : ""}</p>
                                        </p>
                                        <h3>{precioVigente ? "USD " + precioVigente : ""}</h3>
                                    </Col>
                                </Row>
                                <div className="instalador">
                                    <Row>
                                        <Col md={8}>
                                            <h4>¿Sos Integrador o Instalador?</h4>
                                            <p>Ingresá a
                                                <sb> registrarte</sb>
                                                para ver esta página adaptada a tus
                                                necesidades
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <img src={tool} alt=""/>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="descripcion">
                                    <p>
                                        {kit ? kit.desKit : ""}
                                    </p>
                                </div>

                            </Col>
                            <Col md={6} className="banner">
                                <img className="img-responsive" src={compra} alt=""/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="dudas">
                    <div className="container-limit">
                        <Row>
                            <Col md={8}>
                                <h3><b>¿Tenés dudas?</b> Consultá a uno de nuestros asesores.</h3>
                            </Col>
                            <Col md={4}>
                                <img src={mail} alt="" style={{marginRight: "10px"}}/>
                                <img src={face} alt="" style={{marginLeft: "10px"}}/>
                            </Col>
                        </Row>
                    </div>
                </section>

                <OtrosProductos categoria={categoria}/>

                <section className="entrega">
                    <img src={entrega} className="img-responsive" alt="" style={{borderBottom: "4px solid #00ffea"}}/>
                </section>

                <Footer2/>

            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias,
        subCategorias: state.subCategorias,
        kits: state.kits,
        productosKits: state.productosKits
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchKitsIfNeeded: () => {
            dispatch(fetchKitsIfNeeded())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoDetalle));