import {
     INVALIDATE_ENCUESTAS,
    REQUEST_ENCUESTAS, RECEIVE_ENCUESTAS, ERROR_ENCUESTAS
} from '../actions/EncuestaActions';
import { combineReducers } from 'redux';

function encuestasById(state = {
    isFetching: false,
    didInvalidate: true,
    encuestas: {}
}, action) {
    switch (action.type) {
        case INVALIDATE_ENCUESTAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ENCUESTAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_ENCUESTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                encuestas: action.encuestas ? action.encuestas.entities.encuestas : {},
                lastUpdated: action.receivedAt
            });
        case ERROR_ENCUESTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allEncuestas(state = [], action) {
    switch (action.type) {
        case RECEIVE_ENCUESTAS:
            return action.encuestas ? action.encuestas.result : [];
        default:
            return state
    }
}


const encuestas = combineReducers({
    byId : encuestasById,
    allIds : allEncuestas
});

export default encuestas;