import encuestas from "../api/encuestas";
import auth from "../api/authentication";
import * as errorMessages  from '../constants/MessageConstants';
import {logout} from "../actions/AuthenticationActions";

//normalizer
import normalizeDatos from "../normalizers/normalizeEncuesta"


//ENCUESTAS
export const REQUEST_ENCUESTAS = 'REQUEST_ENCUESTAS';
export const RECEIVE_ENCUESTAS = 'RECEIVE_ENCUESTAS';
export const INVALIDATE_ENCUESTAS = 'INVALIDATE_ENCUESTAS';
export const ERROR_ENCUESTAS= "ERROR_ENCUESTAS";


export function invalidateEncuestas(ciudad) {
    return {
        type: INVALIDATE_ENCUESTAS,
        ciudad
    }
}

function requestEncuestas() {
    return {
        type: REQUEST_ENCUESTAS,
    }
}

function receiveEncuestas(json) {
    return {
        type: RECEIVE_ENCUESTAS,
        encuestas: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorEncuestas(error) {
    return {
        type: ERROR_ENCUESTAS,
        error: error,
    }
}

function fetchEncuestas(idProvincia) {
    return dispatch => {
        dispatch(requestEncuestas());
        return encuestas.getAll(idProvincia)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveEncuestas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorEncuestas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEncuestas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchEncuestas(state) {
    const encuestas = state.encuestas.byId;
    if (!encuestas) {
        return true
    } else if (encuestas.isFetching) {
        return false
    } else {
        return encuestas.didInvalidate;
    }
}

export function fetchEncuestasIfNeeded(idProvincia) {
    return (dispatch, getState) => {
        if (shouldFetchEncuestas(getState(),idProvincia)) {
            return dispatch(fetchEncuestas(idProvincia))
        }
    }
}

