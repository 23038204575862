import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

//Images
import imgSierra from '../../../assets/img/evento/eventoRoad/sierraLearningCenter.png';
import camara1 from '../../../assets/img/evento/eventoRoad/camara1.png';
import camara2 from '../../../assets/img/evento/eventoRoad/camara2.png';
import mochila from '../../../assets/img/evento/eventoRoad/mochila.png';

import '../../../assets/css/PreguntasEventoRoad.css';

import {
    fetchUsuarioIfNeeded, invalidateUsuario, resetCreateUsuario,
    resetUpdateUsuarioLogueado
} from "../../../actions/UsuarioActions";

import FieldGroupSubmit from "../../camposTexto/FieldGroupSubmit";
import isObject from "lodash/isObject";
import isString from "lodash/isString";

import Loader from '../../elementos/loader/Loader';

import {FormControl} from "react-bootstrap";
import {resetJugaPregunta, saveJugaPregunta} from "../../../actions/PreguntasActions";

class PreguntasEventoRoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: "",
        };

        this.submitFormDni = this.submitFormDni.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submitFormDni(e) {
        e.preventDefault();
        this.props.invalidateUsuario();
        this.props.resetCreateUsuario();
        this.props.resetUpdateUsuarioLogueado();
        this.props.resetJugaPregunta();
        this.props.fetchUsuarioIfNeeded(this.state.dni);
        this.refs.datos.classList.add("in");
    }

    submitJuga(e) {
        e.preventDefault();
        this.props.saveJugaPregunta(this.props.match.params['idEvento'],this.props.usuarios.byId.usuarioLogueado.dni);
    }

    changeDni(e) {
        this.setState({dni: e.target.value});
    }


    render() {
        const usuario = this.props.usuarios.byId.usuarioLogueado;
        const pregunta = this.props.preguntas.create.nuevo;

        const {usuarioNoEncontrado} = this.state;
        console.log(usuarioNoEncontrado);

        const error = this.props.preguntas.juga.error;

        var msgError = null;

        if (error && isObject(error.message) && !isString(error.message)) {
            msgError = Object.keys(error.message).map(function (key, index) {
                return (<li>{error.message[key]}</li>);
            });
        } else {
            msgError = <h3>{error ? error.message : ""}</h3>;
        }

        console.log(!this.props.usuarios.byId.error);

        return (
            <div className="preguntas">
                <div className="container-limit">
                    <Row className="headerRoad">
                        <img src={imgSierra}/>
                        <div className="textoTitulo">
                            <h1>¡PARTICIPÁ DEL SORTEO!</h1>
                        </div>
                    </Row>
                    <Row className="seccion-jugar">
                        <Col md={12}>
                            <form ref="asd" onSubmit={(e) => {
                                this.submitFormDni(e)
                            }}>
                                <FieldGroupSubmit
                                    id="dni"
                                    type="number"
                                    placeholder="DNI:"
                                    required={true}
                                    help={usuario.dni ? "Ya estás en nuestro registro de datos " + usuario.nombre + " " + usuario.apellido
                                    : ""}
                                    value={this.state.dni}
                                    onChange={(e) => {
                                        this.changeDni(e)
                                    }}
                                />
                            </form>
                            <Col md={11} className="center-block">
                                {
                                    this.props.usuarios.byId.error ? <h3 style={{color:"white"}}>No está en nuestro registro de datos</h3>
                                        :
                                        ""
                                }
                            </Col>
                            <div className="datos collapse" ref="datos">
                                <div style={{display: !this.props.usuarios.byId.error ? "block" :  "none"}}>
                                    <Row>
                                        <form onSubmit={(e) => {
                                            this.submitJuga(e)
                                        }}>
                                            <div>
                                                <p>{msgError}</p>
                                                <p className="cartelCorrecto">{this.props.preguntas.juga.message}</p>
                                                {
                                                    this.props.preguntas.juga.isCreating ?
                                                        <Loader/>
                                                        :
                                                        <div>
                                                            <Button type="submit"
                                                                    className="center-block boton">Jugar</Button>
                                                            <p className="textoInscripcion">Presioná este botón para
                                                                inscribirte</p>
                                                        </div>

                                                }
                                            </div>
                                        </form>
                                    </Row>
                                    <Col lg={8} md={8} sm={10} xs={12} smOffset={1} mdOffset={2}>
                                        <p className="titulo-premios">PREMIOS</p>
                                        <div className="premio">
                                            <p>Camara Bullet Siera AHD LGT 015HD PAL</p>
                                            <img src={camara1} className="imgPremio1"/>
                                        </div>
                                        <div className="premio">
                                            <p>Cámara Domo Siera LGT 009/HD/28 4 en 1 HD 720p</p>
                                            <img src={camara2} className="imgPremio2"/>
                                        </div>
                                        <div className="premio">
                                            <p>Mochila Siera</p>
                                            <img src={mochila} className="imgPremio3"/>
                                        </div>
                                    </Col>
                                    {/*<p className="registro-error">{msgError ? msgError : ""}</p>*/}
                                    {/*<p className="registro-error">{this.props.registros.create.message ? this.props.registros.create.message : ""}</p>*/}
                                </div>
                            </div>
                            <br/>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        preguntas: state.preguntas,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsuarioIfNeeded: (dni) => {
            dispatch(fetchUsuarioIfNeeded(dni));
        },
        resetCreateUsuario: () => {
            dispatch(resetCreateUsuario())
        },
        resetUpdateUsuarioLogueado: () => {
            dispatch(resetUpdateUsuarioLogueado())
        },
        invalidateUsuario: () => {
            dispatch(invalidateUsuario());
        },
        saveJugaPregunta:(idEvento,dni) => {
            dispatch(saveJugaPregunta(idEvento,dni))
        },
        resetJugaPregunta: () => {
            dispatch(resetJugaPregunta())
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreguntasEventoRoad));
