import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var usuario = {

    saveCreate(idCuenta, idHerramienta, idUsuario, codAcceso) {


        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify({idCuenta, idHerramienta, idUsuario, codAcceso})
        };

        return fetch(c.BASE_URL + '/habilitacion', defaultOptions);
    },

};

export default usuario;