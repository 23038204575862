import React, {useEffect, useState, useRef} from 'react';
import {Row, Col} from 'react-bootstrap';

import Header from "../../layout/HeaderLogo"
import {HashLink as Link} from 'react-router-hash-link';
import '../../../assets/css/Documentacion.css';

import kit from '../../../assets/img/kitLogo.png';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import sobreNegro from '../../../assets/img/formaPagoDoc/sobreNegro.png';
import sobreEfectivo from '../../../assets/img/formaPagoDoc/SobreEfectivo.png';
import sobreCheque from '../../../assets/img/formaPagoDoc/sobreDeCheque.png';
import pagoTerceroEfectivo from '../../../assets/img/formaPagoDoc/pagoTerceroEfectivo.png';
import pasosTAS from '../../../assets/img/formaPagoDoc/pasosTAS.png';
import pasosECheq from '../../../assets/img/formaPagoDoc/pasosECheq.png';
import history from "../../../history";
import clone from "lodash/clone"

function LinkHash(props) {
    const scrollWidthOffset = (el, offset = -80) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = offset;
        console.log(yCoordinate);
        window.scrollTo({top: yCoordinate + yOffset, behavior: 'smooth'});
    }

    return (
        <Link to={props.to}
              scroll={(el) => scrollWidthOffset(el, props.offset)}>
            {props.children}
        </Link>
    );
}

export default function Documentacion(props) {
    const [isSticky, setSticky] = useState(null);
    const refHeader = useRef([]);

    const handleScroll = () => {
        if (refHeader.current && refHeader.current.length > 0) {
            refHeader.current.forEach((referencia, i) => {
                if (i === 1 && referencia.getBoundingClientRect().top > 0) {
                    setSticky(null);
                }
                if (referencia.getBoundingClientRect().top <= 0) {
                    setSticky(i);
                }
            });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll);

        setTimeout(() => {
            let el = document.getElementById(props.location.hash.replace("#", ""));
            if (el) {
                const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
                let offset = el.getAttribute("offset");
                console.log("offset: ", yCoordinate, offset);
            window.scrollTo({top: yCoordinate + (offset ? offset : -80), behavior: 'smooth'});
            }
        }, 200);

        if (props.location.hash) {
            history.push(props.location.hash)
        }
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [])

    return (
        <div className="container-fluid documentacion">
            <Header/>
            <Row>
                <div className="container-limit">
                    <h1>
                        Formas de <br/><b>pago</b>
                    </h1>
                </div>
            </Row>
            <Row className="seccion-blanca">
                <section className="indice">
                    <div className="container-limit">
                        <h3>ÍNDICE</h3>
                        <ul>
                            <li>
                                <LinkHash to={"#entregaPersonalmente"} offset={8}>Estoy entregando
                                    personalmente</LinkHash>
                                <ul>
                                    <li><LinkHash to="#efectivoPersonal">Contado
                                        Efectivo</LinkHash></li>
                                    <li><LinkHash to="#chequesPersonal">Cheques</LinkHash>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <LinkHash to={"#pagoViaTercero"} offset={8}>He enviado el pago con un
                                    tercero</LinkHash>
                                <ul>
                                    <li><LinkHash to="#efectivoTercero">Contado
                                        Efectivo</LinkHash></li>
                                    <li><LinkHash to="#chequesTercero">Cheques</LinkHash>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <LinkHash to={"#transaccionesElectronicas"} offset={8}>He realizado
                                    transacciones electrónicas</LinkHash>
                                <ul>
                                    <li><LinkHash to={"#transferenciaBancaria"}>Transferencia
                                        Bancaria</LinkHash></li>
                                    <li><LinkHash to={"#mercadoPago"}>MercadoPago</LinkHash>
                                    </li>
                                    <li><LinkHash to={"#retenciones"}>Retenciones de
                                        Impuestos</LinkHash></li>
                                    <li><LinkHash to={"#echeq"}>Cheque
                                        electrónico</LinkHash></li>
                                </ul>
                            </li>
                            <li>
                                <LinkHash to={"#SeccionDepositoEnCuenta"} offset={8}>He depositado
                                    en cuentas habilitadas</LinkHash>
                                <ul>
                                    <li><LinkHash to={"#tas"}>Cuenta Recaudadora (TAS
                                        MACRO)</LinkHash></li>
                                    <li><LinkHash to={"#depositoEfectivoOCheques"}>Efectivo o Cheques</LinkHash></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </section>
                <div className="container-limit seccion-forma-pago">
                    <div className={`sticky-wrapper${isSticky && isSticky === 1 ? ' sticky' : ''}`}
                         ref={el => refHeader.current[1] = el}>
                        <h2 id={"entregaPersonalmente"} className={"sticky-inner"} offset={8}>
                            Estoy entregando personalmente
                        </h2>
                    </div>
                    {/*Efectivo en persona*/}
                    <div className="container-flex">
                        <div className="container-instructivo">
                            <h3 id={"efectivoPersonal"}>Contado Efectivo</h3>
                            <h4>PASO 1</h4>
                            <p>Es necesario que, en el sobre donde depositás el dinero, escribas:</p>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Por el frente:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Importe del Efectivo (de ser superior al monto adeudado,
                                                administración
                                                te
                                                registrará el pago, dejando como saldo a favor para futuras compras)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Tu número de DNI
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Por el dorso:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Realizá tu firma sobre el pliegue donde cierra el sobre. Ejemplo en
                                                Imagen (1).
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h4>PASO 2</h4>
                            <p>
                                Encontrarás un <b>sobre negro de plastico</b>, como en la
                                imagen (2), para guardar tu sobre dentro de él. Luego será introducido en un buzón
                                hasta
                                que
                                sea retirado por una persona del área de administración, que lo llevará al sector de
                                apertura para contar y registrar el pago.
                            </p>

                            <p className="advertencia">
                                Todo el proceso será registrado por cámaras de seguridad.
                            </p>
                        </div>
                        <div className="container-imagen-instructivo">
                            <div className="imagen-instructivo">
                                <img src={sobreEfectivo} alt=""/>
                            </div>
                            <div className="imagen-instructivo">
                                <img src={sobreNegro} alt=""/>
                            </div>
                        </div>
                    </div>

                    {/*Cheques en persona*/}
                    <div className="container-flex">
                        <div className="container-instructivo">
                            <h3 id={"chequesPersonal"}>Cheques</h3>

                            <h4>PASO 1</h4>
                            <p>Te recomendamos que, antes de ingresar los cheques al sobre, <b>corrobores que no
                                tenga defectos formales</b>:</p>
                            <ul className={""}>
                                <li>
                                    <p>
                                        Si es un cheque que dice NO A LA ORDEN, con el mismo no vas a poder
                                        abonarnos ya que únicamente puede cobrar la persona que se describe en
                                        el páguese.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si es un cheque a doble fecha, la fecha de emisión no puede ser la misma
                                        que la fecha de cobro.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si es un cheque a una sola fecha, la fecha de cobro ya tiene que estar
                                        al día.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si es un cheque que está librado a una persona, es decir el páguese a
                                        contiene una descripción de nombre o empresa, el cheque tiene que estar
                                        endosado en su reverso con firma y nombre escrito de igual forma que la
                                        descripción del cheque.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si el cheque contiene números o letras sobre escritas , el cheques en su
                                        reverso tiene que estar endosado corrigiendo el error del frente.
                                    </p>
                                </li>
                            </ul>

                            <h4>PASO 2</h4>
                            <p>Es necesario que, en el sobre donde depositás el cheque, escribas:</p>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Por el frente:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Importe total de los cheques (de ser superior al monto adeudado,
                                                administración te registrará el pago, dejando como saldo a favor para
                                                futuras compras).
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Cantidad de cheques
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Tu número de DNI
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Por el dorso:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Realizá tu firma sobre el pliegue donde cierra el sobre. Ejemplo en
                                                Imagen (1).
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h4>PASO 3</h4>
                            <p>
                                Encontrarás un <b>sobre negro de plástico</b>, como en la imagen (2), para guardar tu
                                sobre
                                dentro de él. Luego será introducido en un buzón hasta que sea retirado por una persona
                                del área de administración, que lo llevará al sector de apertura para contar y registrar
                                el pago.
                            </p>

                            <p className="advertencia">
                                Todo el proceso será registrado por cámaras de seguridad.
                            </p>
                        </div>
                        <div className="container-imagen-instructivo">
                            <div className="imagen-instructivo">
                                <img src={sobreCheque} alt=""/>
                            </div>
                            <div className="imagen-instructivo">
                                <img src={sobreNegro} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>


                {/*------------------*/}
                {/*Pago con un tercero*/}
                <div className="container-limit seccion-forma-pago">
                    <div className={`sticky-wrapper${isSticky && isSticky === 2 ? ' sticky' : ''}`}
                         ref={el => refHeader.current[2] = el}>
                        <h2 id={"pagoViaTercero"} className={"sticky-inner"} offset={8}>
                            He enviado el pago con un tercero
                        </h2>
                    </div>
                    {/*Efectivo en tercero*/}
                    <div className="container-flex">
                        <div className="container-instructivo">
                            <h3 id={"efectivoTercero"}>Contado Efectivo</h3>
                            <h4>PASO 1</h4>
                            <p>Es necesario que, en el sobre donde depositás el dinero, escribas:</p>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Por el frente:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Importe del Efectivo (de ser superior al monto adeudado,
                                                administración
                                                te
                                                registrará el pago, dejando como saldo a favor para futuras compras)
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Tu número de DNI
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Por el dorso:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Realizá tu firma sobre el pliegue donde cierra el sobre. Ejemplo en
                                                Imagen (1).
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="container-imagen-instructivo">
                            <div className="imagen-instructivo">
                                <img src={sobreEfectivo} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p style={{padding: "15px 0 5px 0"}}>
                            El comisionista o tercero entregará el bulto al ejecutivo de cuenta, quien buscará el
                            sobre dentro del mismo y lo guardará en un sobre negro tipo bolsa. Luego lo ingresará en
                            un buzón hasta que sea retirado por una persona del área de administración, que lo
                            llevará al sector de apertura para contar y registrar el pago.
                        </p>

                        <img style={{width: "100%", margin: "25px auto", display: "block"}}
                             src={pagoTerceroEfectivo} alt=""/>

                        <p style={{padding: "15px 0 5px 0"}}>
                            En caso de que envíes el sobre dentro de un bulto por encomienda, el personal de nuestra
                            empresa que abra el bulto será quien deposite el sobre en el buzón.
                        </p>

                        <p className="advertencia">
                            Desde el momento que la persona llegué y coloque el sobre en el buzón de pagos, hasta que
                            sea abierto por una persona del área de Administración que valide el monto que ingresaste,
                            todo el proceso será filmado por cámara de seguridad
                        </p>

                    </div>

                    {/*Cheques en tercero*/}
                    <div className="container-flex">
                        <div className="container-instructivo">
                            <h3 id={"chequesTercero"}>Cheques</h3>

                            <h4>PASO 1</h4>
                            <p>Te recomendamos que, antes de ingresar los cheques al sobre, <b>corrobores que no
                                tenga defectos formales</b>:</p>
                            <ul className={""}>
                                <li>
                                    <p>
                                        Si es un cheque que dice NO A LA ORDEN, con el mismo no vas a poder
                                        abonarnos ya que únicamente puede cobrar la persona que se describe en
                                        el páguese.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si es un cheque a doble fecha, la fecha de emisión no puede ser la misma
                                        que la fecha de cobro.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si es un cheque a una sola fecha, la fecha de cobro ya tiene que estar
                                        al día.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si es un cheque que está librado a una persona, es decir el páguese a
                                        contiene una descripción de nombre o empresa, el cheque tiene que estar
                                        endosado en su reverso con firma y nombre escrito de igual forma que la
                                        descripción del cheque.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Si el cheque contiene números o letras sobre escritas , el cheques en su
                                        reverso tiene que estar endosado corrigiendo el error del frente.
                                    </p>
                                </li>
                            </ul>

                            <h4>PASO 2</h4>
                            <p>Es necesario que, en el sobre donde depositás el cheque, escribas:</p>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Por el frente:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Importe total de los cheques (de ser superior al monto adeudado,
                                                administración te registrará el pago, dejando como saldo a favor para
                                                futuras compras).
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Cantidad de cheques
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Tu número de DNI
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Por el dorso:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Realizá tu firma sobre el pliegue donde cierra el sobre. Ejemplo en
                                                Imagen (1).
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div className="container-imagen-instructivo">
                            <div className="imagen-instructivo">
                                <img src={sobreCheque} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p>
                            En caso de que envíes el sobre dentro de un bulto por encomienda, el personal de nuestra
                            empresa que abra el bulto será quien deposite el sobre en el buzón.
                        </p>

                        <p className="advertencia">
                            Desde el momento que la persona llegué y coloque el sobre en el buzón de pagos, hasta
                            que sea abierto por una persona del área de Administración que valide el monto que
                            ingresaste, todo el proceso será filmado por cámara de seguridad
                        </p>
                    </div>
                </div>


                {/*------------------*/}
                {/*He realizado transacciones electrónicas*/}
                <div className="container-limit seccion-forma-pago">
                    <div className={`sticky-wrapper${isSticky && isSticky === 3 ? ' sticky' : ''}`}
                         ref={el => refHeader.current[3] = el}>
                        <h2 className={"sticky-inner"} id="transaccionesElectronicas" offset={8}>
                            He realizado transacciones electrónicas
                        </h2>
                    </div>

                    <div className="container-instructivo">
                        <h3 id={"transferenciaBancaria"}>Transferencia Bancaria</h3>
                        <p>Detallamos nuestras cuentas habilitadas:</p>
                        <div className="container-flex">
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Banco Macro:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Cuenta Corriente en Pesos
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                A nombre de: QUALITY SYSTEMS GROUP SRL
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CUIT: 30-70945880-5
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CBU: 2850840330000019524530
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Nro de Cuenta: 3-840-0000195245-3
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Alias: QUALITY.EXPERTO
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Banco Municipal:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Cuenta Corriente en Pesos
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                A nombre de: QUALITY SYSTEMS GROUP SRL
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CUIT: 30-70945880-5
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CBU: 0650060301000000141913
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Nro de Cuenta: 6000014191
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Alias: QUALITY.EXPERTO.MUNI
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Banco Nación:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Cuenta Corriente en Pesos
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                A nombre de: QUALITY SYSTEMS GROUP SRL
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CUIT: 30-70945880-5
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CBU: 0110140520014000392373
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Nro de Cuenta: 11701400039237
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Alias: BECADO.BATATA.PLOMO
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <h3 id={"mercadoPago"}>MercadoPago</h3>
                        <div className="container-flex">
                            <ul>
                                <li>
                                    <p>
                                        A nombre de: QUALITY SYSTEMS GROUP SRL
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        CUIT: 30-70945880-5
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        E-mail: qscontacto@gmail.com
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        CVU: 0000003100020229585388
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Alias: QUALITY.EXPERTO.MP
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <h3 id={"retenciones"}>Retenciones de Impuestos</h3>
                        <p>
                            Si necesitas nuestras constancias para generar una retención podés acceder a los formularios
                            para descargarlos haciendo click en este <a
                            href="https://drive.google.com/drive/u/0/folders/1P7YmRsOr8ml7UXk8SJx6CU_I1cVOXnkk"
                            target="_blank">LINK</a>.
                        </p>

                        <h3 id={"echeq"}>Cheque electrónico</h3>
                        <p style={{fontWeight: "500"}}>¿Cómo lo emito?</p>
                        <h4>PASOS</h4>
                        <img style={{width: "100%", margin: "25px auto", display: "block"}} src={pasosECheq} alt=""/>
                        <p>
                            El destinatario del ECHEQ será alertado que tiene un cheque a su favor por su banco o por
                            alguna de las infraestructuras de mercados financieros autorizadas.
                        </p>

                        <br/>
                        <p>A tener en cuenta:</p>
                        <ul className={""}>
                            <li>
                                <p>
                                    Solo emiten ECHEQ los titulares de cuentas corrientes.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Se pueden depositar en cuentas corrientes o cajas de ahorro.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Por el momento, sólo podrán recibir ECHEQ las personas bancarizadas.
                                </p>
                            </li>
                        </ul>

                    </div>
                </div>


                {/*------------------*/}
                {/*He depositado en cuentas habilitadas*/}
                <div className="container-limit seccion-forma-pago">
                    <div className={`sticky-wrapper${isSticky && isSticky === 5 ? ' sticky' : ''}`}
                         ref={el => refHeader.current[5] = el}>
                        <h2 id={"SeccionDepositoEnCuenta"} offset={8} className={"sticky-inner"}>
                            He depositado en cuentas habilitadas
                        </h2>
                    </div>

                    <div className="container-instructivo">
                        <h3 id={"tas"}>Cuenta Recaudadora (TAS MACRO)</h3>
                        <p>Contamos con el servicio habilitado para que deposites <b>cheques de pago diferido</b> en
                            cajeros automáticos o humanos de cualquier sucursal del banco Macro. Encontrá tu sucursal
                            más
                            cercana haciendo clic <a
                                href="https://www.macro.com.ar/conocenos/canales-de-atencion/buscador-de-cajeros-y-sucursales"
                                target="_blank">AQUÍ</a>.</p>
                        <br/>
                        <p>
                            Dirigite con los valores y tu numero de DNI a nuestra cuenta recaudadora.
                        </p>
                        <br/>
                        <p>
                            En este <a href="https://drive.google.com/file/d/1Ld6fTwnDPU8mVY5s7SGzbsauaflghkth/view"
                                       target={"_blank"}>LINK</a> se encuentra el paso a paso con imágenes de la
                            pantalla del cajero automatico
                            de como realizar el depósito y al pie las aclaraciones a tener en cuenta.
                        </p>
                        <p>
                            Para Los cheques no hace falta tu endoso.
                        </p>
                        <br/>
                        <h4>PASOS</h4>
                        <img style={{width: "100%", margin: "25px auto", display: "block"}} src={pasosTAS} alt=""/>

                        <h3 id={"depositoEfectivoOCheques"}>Efectivo o Cheques</h3>
                        <p>
                            Podes depositar Efectivo o Cheques en nuestras cuentas
                        </p>

                        <div className="container-flex">
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Banco Macro:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Cuenta Corriente en Pesos
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                A nombre de: QUALITY SYSTEMS GROUP SRL
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CUIT: 30-70945880-5
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CBU: 2850840330000019524530
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Nro de Cuenta: 3-840-0000195245-3
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p style={{fontWeight: "200", maxWidth: "400px", marginTop: "35px"}}>
                                        Te dejamos un link para que encuentre las sucursales más cercanas <a
                                        href="https://www.macro.com.ar/conocenos/canales-de-atencion/buscador-de-cajeros-y-sucursales"
                                        target="_blank">AQUÍ</a>
                                    </p>
                                </li>
                            </ul>
                            <ul className={"ul-styless"}>
                                <li>
                                    <b>Banco Municipal:</b>
                                    <ul>
                                        <li>
                                            <p>
                                                Cuenta Corriente en Pesos
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                A nombre de: QUALITY SYSTEMS GROUP SRL
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CUIT: 30-70945880-5
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                CBU: 0650060301000000141913
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                Nro de Cuenta: 6000014191
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p style={{fontWeight: "200", maxWidth: "400px", marginTop: "35px"}}>
                                        Te dejamos un link para que encuentre las sucursales más cercanas <a
                                        href="https://www.bmros.com.ar/canales/localizanos/#1518718488320-fb504078-8368"
                                        target="_blank">AQUÍ</a>
                                    </p>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>


            </Row>


            <Row className="footer">
                <a href="http://www.kitexperto.com">www.kitexperto.com</a>
            </Row>
        </div>
    )
}
