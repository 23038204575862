import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import "./TarjetaCredito.css";
import {Row} from "react-bootstrap";
import {createPago, updatePago} from "../../../../../actions/PagoActions";
import * as moneda from "../../../../../constants/Moneda";
import moment from "moment";

export default function Contado(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();
    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;

    //Effects
    useEffect(() => {
        if (!idPago) {
            let cambio = {};
            cambio.fecha = moment().format("YYYY-MM-DD");
            cambio.idMonedaPago = moneda.PESO_ID;
            dispatch(createPago(cambio));
        }
    }, []);

    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };


    return (
        <div className="tarjeta-credito container-fluid">
            <Row className="container-formulario">
                <div className="input-row">
                    <label htmlFor="fecha">Fecha</label>
                    <input id="fecha" type="date" required
                           name="fecha"
                           value={pago && pago.fecha ? pago.fecha : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
                <div className="input-row">
                    <label htmlFor="importe">Importe</label>
                    <input id="importe" type="number" required
                           name="importe"
                           placeholder="Importe"
                           value={pago && pago.importe ? pago.importe : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
                <div className="input-row">
                    <label htmlFor="idMonedaPago">Moneda pago</label>
                    <select
                        id="idMonedaPago"
                        required
                        disabled={props.disabled}
                        value={pago.idMonedaPago ? pago.idMonedaPago : ""}
                        onChange={(e) => onChangePago(e)}>
                        <option value={""}>Seleccione moneda</option>
                        <option value={moneda.DOLAR_ID}>Dolar</option>
                        <option value={moneda.PESO_ID}>Peso</option>
                    </select>
                </div>
            </Row>
        </div>
    )
        ;
}
