import {combineReducers} from 'redux';
import merge from "lodash/merge";
import union from "lodash/union";

//Actions
import {
    INVALIDATE_PEDIDOS,
    REQUEST_PEDIDOS,
    RECEIVE_PEDIDOS,
    ERROR_PEDIDOS,
    RESET_PEDIDOS,
    CREATE_PEDIDO,
    REQUEST_CREATE_PEDIDO,
    SUCCESS_CREATE_PEDIDO,
    ERROR_CREATE_PEDIDO,
    RESET_CREATE_PEDIDO,
    ERROR_UPDATE_PEDIDO,
    REQUEST_UPDATE_PEDIDO,
    RESET_UPDATE_PEDIDO,
    SUCCESS_UPDATE_PEDIDO,
    UPDATE_PEDIDO,
    REQUEST_INICIAR_PEDIDO,
    SUCCESS_INICIAR_PEDIDO,
    ERROR_INICIAR_PEDIDO,
    REQUEST_ULTIMO_PEDIDO, RECEIVE_ULTIMO_PEDIDO, ERROR_ULTIMO_PEDIDO, INVALIDATE_ULTIMO_PEDIDO, RESET_ULTIMO_PEDIDO
} from '../actions/PedidoActions';
import {CREATE_DETALLEPEDIDOMON, DELETE_DETALLEPEDIDOMON} from "../actions/DetallePedidoMonActions"
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

function pedidosById(state = {
    isFetching: false,
    didInvalidate: true,
    isFetchingUltimo: false,
    didInvalidateUltimo: true,
    pedidos: [],
    ultimoPedido: {},
    didInvalidateActivo: true,
    error: null,
}, action) {
    switch (action.type) {
        case INVALIDATE_PEDIDOS:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                // pedidos: action.pedidos.entities.pedidos,
                pedidos: merge({}, state.pedidos, action.pedidos.entities.pedidos),
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                pedidos: [],
                error: null,
                ultimoPedido: {},
                didInvalidate: true,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                pedidos: [],
            });
        case UPDATE_PEDIDO:
            if (action.idPedido) {
                var pedido = {};
                pedido[action.idPedido] = action.pedido;
                return Object.assign({}, state, {
                    pedidos: merge({}, state.pedidos, pedido),
                });
            } else {
                return state;
            };
            return Object.assign({}, state, {
                didInvalidateUltimo: true
            });
        case INVALIDATE_ULTIMO_PEDIDO:
            return Object.assign({}, state, {
                didInvalidateUltimo: true,
                error: null,
            });
        case REQUEST_ULTIMO_PEDIDO:
            return Object.assign({}, state, {
                isFetchingUltimo: true,
                didInvalidateUltimo: false,
                error:null,
            });
        case RECEIVE_ULTIMO_PEDIDO:
            return Object.assign({}, state, {
                isFetchingUltimo: false,
                didInvalidateUltimo: false,
                ultimoPedido: action.ultimoPedido,
                lastUpdated: action.receivedAt,
                error:null,
            });
        case RESET_ULTIMO_PEDIDO:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                ultimoPedido: {},
                didInvalidate: true,
            });
        case ERROR_ULTIMO_PEDIDO:
            return Object.assign({}, state, {
                isFetchingUltimo: false,
                didInvalidateUltimo: true,
                error: action.error
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    error: null
}, action) {
    switch (action.type) {
        case CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: merge({}, state.nuevo, action.pedido),
                error: null
            });
        case REQUEST_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null
            });
        case ERROR_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: [],
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                nuevo: [],
            });
        case CREATE_DETALLEPEDIDOMON:
            if (!state.nuevo.detalle_pedido_mon) {
                state.nuevo.detalle_pedido_mon = [];
            }
            var nuevo = [];
            var idProducto = {};
            nuevo.detalle_pedido_mon = [];
            if (Object.keys(action.detallePedidoMon)[0].indexOf("-") !== -1) {
                idProducto = Object.keys(action.detallePedidoMon)[0];
            }
            else {
                idProducto = action.detallePedidoMon.idProducto + "-";
            }
            nuevo.detalle_pedido_mon.push(idProducto);
            nuevo.detalle_pedido_mon = union(state.nuevo.detalle_pedido_mon, nuevo.detalle_pedido_mon);
            return merge({}, state, {
                isCreating: false,
                nuevo: merge({}, state.nuevo, nuevo),
                error: null,
            });
        case DELETE_DETALLEPEDIDOMON:
            var nuevo = state.nuevo;
            var index = nuevo.detalle_pedido_mon.indexOf(Object.keys(action.detallePedidoMon)[0]);
            console.log(index);
            if (index > -1) {
                nuevo.detalle_pedido_mon.splice(index, 1);
            }
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                nuevo: merge({}, state.nuevo, nuevo),
            });
        default:
            return state
    }

}

function update(state = {
    isUpdating: false,
    activo: {},
    error: ""
}, action) {
    switch (action.type) {
        case UPDATE_PEDIDO:
            return merge({}, state, {
                activo: action.pedido,
                error: ""
            });
        case REQUEST_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt
            });
        case ERROR_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        case REQUEST_INICIAR_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null,
            });
        case SUCCESS_INICIAR_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_INICIAR_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        default:
            return state
    }
}

function allPedidos(state = [], action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return union(state, action.pedidos.result);
        case RESET_PEDIDOS:
            return [];
        default:
            return state
    }
}


const pedidos = combineReducers({
    byId: pedidosById,
    allIds: allPedidos,
    create: create,
    update: update,
});

export default pedidos;