import c from '../constants/constants';
import usuario from "./registro";

require('es6-promise').polyfill();
require('isomorphic-fetch');


var pedidos = {

    getAll(filtros) {
        var esc = encodeURIComponent;
        var query = "";
        if (filtros)
            query = Object.keys(filtros)
                .map(k => esc(k) + '=' + esc(filtros[k]))
                .join('&');

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json'
        };
        return fetch(c.BASE_URL + '/pedidos?' + query, defaultOptions);
    },

    saveCreate(pedido) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(pedido)
        };

        return fetch(c.BASE_URL+'/pedidos', defaultOptions);
    },
    generarOrdenPedidoEnvio(datos){
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(datos)
        };

        return fetch(c.BASE_URL + '/pedidoEnvio/', defaultOptions);
    },
    saveUpdate(pedido) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(pedido)
        };

        return fetch(c.BASE_URL + '/pedidos/' + pedido.id, defaultOptions);
    },
    iniciarPedido(pedido) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL + '/pedidos/' + pedido.id + '/edit', defaultOptions);
    },
    getByUltimoPedido(idUsuario) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/pedido/usuario/'+idUsuario, defaultOptions);
    },
};

export default pedidos;