import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, Redirect} from 'react-router-dom'
import {Switch} from 'react-router';
import {Link} from 'react-router-dom';
import {Button, Row, Col} from 'react-bootstrap';
import socketIOClient from 'socket.io-client';

//Actions
import {
    createUsuario, updateUsuario,
    fetchUsuario, invalidateUsuario,
    resetUpdateUsuario, resetCreateUsuario,
    saveUsuarioAndHabilitaciones,
} from "../../../../actions/UsuarioActions";
import {fetchAccesoCuentaUsuario} from "../../../../actions/AccesosCuentaActions";


//CSS
import '../../../../assets/css/OpenSafe.css';

//Components
import FieldGroup from "../../../camposTexto/FieldGroup";
import Loader from '../../../elementos/loader/Loader';
import FieldTelefono from "../../../camposTexto/FieldTelefono";
import FieldGroupSubmit from "../../../camposTexto/FieldGroupSubmit";

//Constants
import * as herramientas from "../../../../constants/Herramientas";
import * as roles from "../../../../constants/Roles";
import * as cuentas from "../../../../constants/Cuentas";

import isEmpty from "lodash/isEmpty";

// var JSMpeg = require('../../../../api/librerias/jsmpeg.pipe').JSMpeg;

class OpenSafe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dni: '',
            usrExisted: false,
            codAcceso: '',
        };

        this.submitForm = this.submitForm.bind(this);
        this.submitDatos = this.submitDatos.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        //Busco accesoCuenta y habilitacion del usuario buscado
        if (prevProps.usuarios.update.isFetching != this.props.usuarios.update.isFetching && !this.props.usuarios.update.isFetching && this.props.usuarios.update.activo.id) {
            this.props.fetchAccesoCuentaUsuario(this.props.match.params["idCuenta"], this.props.usuarios.update.activo.id);
        }

        if (prevProps.accesosCuenta.byId.isFetching != this.props.accesosCuenta.byId.isFetching && !this.props.accesosCuenta.byId.isFetching) {
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (this.props.usuarios.update.activo.id && accesoCuenta && accesoCuenta.idUsuario == this.props.usuarios.update.activo.id) {
                    this.props.habilitaciones.allIds.map((idHabilitacion) => {
                        var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        //Si la habilitacion tiene codigo de acceso lo muestro, sino muestro el state con el dni cortado a 8 caracteres
                        if (habilitacion.idAccesoCuenta = idAccesoCuenta && habilitacion.idHerramienta == herramientas.OPEN_SAFE_ID && !isEmpty(habilitacion.codAcceso)) {
                            this.setState({codAcceso: habilitacion.codAcceso});
                        }
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.resetUpdateUsuario();
    }

    submitForm(e) {
        e.preventDefault();
        this.props.invalidateUsuario();
        this.props.resetUpdateUsuario();
        this.props.resetCreateUsuario();
        this.props.fetchUsuario(this.state.dni);
        this.refs.datos.classList.add("in");
        this.refs.fotos.classList.add("in");

        this.setState({codAcceso: this.state.dni.length >= 8 ? this.state.dni.substr(0, 8) : this.state.dni});
    }


    submitDatos(e) {
        e.preventDefault();
        var cambio = {dni: this.state.dni};

        if (!isEmpty(this.props.usuarios.update.activo))
            this.props.updateUsuario(cambio);
        else
            this.props.createUsuario(cambio);

        this.props.saveUsuarioAndHabilitaciones(this.props.match.params['idCuenta'], herramientas.OPEN_SAFE_ID, this.state.codAcceso);
    }

    changeDni(e) {
        var valor = e.target.value;
        if (valor.length >= 11) {
            valor = valor.substr(0, 10);
        }

        this.setState({
            dni: valor,
            codAcceso: '',
        });

        if (!isEmpty(this.props.usuarios.update.activo) || !isEmpty(this.props.usuarios.update.error))
            this.props.resetUpdateUsuario();

        this.refs.datos.classList.remove("in");
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        if (!isEmpty(this.props.usuarios.update.activo)) {
            this.props.updateUsuario(cambio);
        } else {
            this.props.createUsuario(cambio);
        }
    }

    onChangeCodAcceso(e) {
        var valor = e.target.value;
        if (valor.length >= 9) {
            valor = valor.substr(0, 8);
        }
        this.setState({codAcceso: valor});
    }

    render() {
        const codAcceso = this.state.codAcceso;
        let usuario = {};
        let usrExisted = false;
        if (!isEmpty(this.props.usuarios.update.activo)) {
            usuario = this.props.usuarios.update.activo;
            usrExisted = true;
        } else {
            usuario = this.props.usuarios.create.nuevo;
        }

        //Verifico que el usuario no sea usuarioInvitado
        var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
        var cuentaInvitado = '';
        var cuenta = null;
        if (usuarioLogueado)
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta && accesoCuenta.idCuenta == cuentas.OPENSAFE_ID && accesoCuenta.idUsuario == usuarioLogueado.id) {
                    cuenta = this.props.cuentas.byId.cuentas[accesoCuenta.idCuenta];
                    if (cuenta && accesoCuenta.idRol == roles.INVITADO_OPENSAFE_ID) {
                        cuentaInvitado = cuenta.alias + " - " + cuenta.identificacion;
                    }
                }
            });

        return (
            <div>
                <div className="opensafe-head">
                    <div className="container-limit">
                        <div className="container-cuenta">
                            <h3>{cuenta ? cuenta.alias : ""},</h3>
                            <h4>{cuenta ? cuenta.identificacion : ""}</h4>
                            <Link to="/panel/cuentas">[Volver]</Link>
                        </div>
                        <div className="herramienta-head">
                            <div className="dot-blanco"></div>
                            <h1>Open Safe</h1>
                        </div>
                    </div>
                </div>
                <div className="opensafe-herramientas">
                    <div className="container-limit">
                        <div className="usuarioInvitado"
                             style={{display: !this.props.accesosCuenta.byId.isFetching && cuentaInvitado != '' ? "block" : "none"}}>
                            <h4>Fuiste invitado a {cuentaInvitado}, próximamente podrás ver tu historial.</h4>
                        </div>
                        <div className="herramienta-invitar"
                             style={{display: !this.props.accesosCuenta.byId.isFetching && cuentaInvitado == '' ? "block" : "none"}}>
                            <h5>Invitar</h5>
                            <p>Ingrese el DNI de la persona que desea invitar a su inmueble, si la persona no
                                existe en el registro por favor complete los datos.</p>
                            <Row>
                                <Col md={8} style={{padding: 0}}>
                                    <form onSubmit={(e) => {
                                        this.submitForm(e)
                                    }}>
                                        <FieldGroupSubmit
                                            id="dni"
                                            type="number"
                                            label="Ingresar DNI"
                                            placeholder=""
                                            required={true}
                                            max={9999999999}
                                            help={this.props.usuarios.update.error ?
                                                this.props.usuarios.update.error
                                                :
                                                usuario.dni ?
                                                    "Ya estás en nuestro registro de datos " + usuario.nombre + " " + usuario.apellido
                                                    :
                                                    ""
                                            }
                                            value={this.state.dni}
                                            onChange={(e) => {
                                                this.changeDni(e)
                                            }}
                                        />
                                    </form>
                                    <form onSubmit={e => {
                                        this.submitDatos(e)
                                    }}
                                    >
                                        <div className="datos collapse" ref="datos">
                                            <FieldGroup
                                                id="email"
                                                type="email"
                                                label="E-mail"
                                                required={true}
                                                placeholder=""
                                                value={usuario.email ? usuario.email : ""}
                                                onChange={(e) => {
                                                    this.onChangeUsuario(e)
                                                }}
                                                disabled={usrExisted}
                                            />

                                            <FieldGroup
                                                id="nombre"
                                                type="text"
                                                label="Nombre"
                                                placeholder=""
                                                required={true}
                                                value={usuario.nombre ? usuario.nombre : ""}
                                                onChange={(e) => {
                                                    this.onChangeUsuario(e)
                                                }}
                                                disabled={usrExisted}
                                            />

                                            <FieldGroup
                                                id="apellido"
                                                type="text"
                                                label="Apellido"
                                                placeholder=""
                                                required={true}
                                                value={usuario.apellido ? usuario.apellido : ""}
                                                onChange={(e) => {
                                                    this.onChangeUsuario(e)
                                                }}
                                                disabled={usrExisted}
                                            />

                                            <FieldTelefono
                                                id="celular"
                                                type="text"
                                                label="Celular"
                                                required={false}
                                                placeholder="(Cod. Area sin 0 - Num. Tel. sin 15 ej: 341 5111111)"
                                                value={usuario.celular ? (usuario.celular == null ? "" : usuario.celular) : ""}
                                                options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}
                                                onChange={(e) => {
                                                    this.onChangeUsuario(e)
                                                }}
                                                disabled={usrExisted}
                                            />

                                            <FieldGroup
                                                className="input-codacceso"
                                                id="codAcceso"
                                                type="text"
                                                label="Su código de acceso es"
                                                placeholder=""
                                                value={codAcceso ? codAcceso : ""}
                                                onChange={(e) => {
                                                    this.onChangeCodAcceso(e)
                                                }}
                                                disabled={true}
                                            />

                                            {
                                                this.props.pullSdk.create.isCreating ?
                                                    <div>
                                                        <Loader/>
                                                        <p>Conectandose con la controladora...</p>
                                                    </div>
                                                    :
                                                    <Button type="submit"
                                                            className="center-block boton">Enviar</Button>
                                            }
                                        </div>
                                        <br/>

                                    </form>

                                </Col>


                                <Col md={4}>
                                    <div className="collapse" ref="fotos" style={{display: "none"}}>
                                        <div style={{display: "flex"}}>
                                            <div className="video" style={{
                                                border: "1px solid black",
                                                width: "50%",
                                                height: "180px"
                                            }}>
                                            </div>
                                            <div className="video" style={{
                                                border: "1px solid black",
                                                width: "50%",
                                                height: "180px"
                                            }}>
                                            </div>
                                        </div>
                                        <Button style={{
                                            float: "right",
                                            borderRadius: "0",
                                            fontSize: "10px",
                                            padding: "8px 23px",
                                            marginTop: "20px"
                                        }}>
                                            ← Tomar foto
                                        </Button>
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        registros: state.registros,
        pullSdk: state.pullSdk,
        accesosCuenta: state.accesosCuenta,
        habilitaciones: state.habilitaciones,
        cuentas: state.cuentas,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsuario: (dni) => {
            dispatch(fetchUsuario(dni));
        },
        invalidateUsuario: () => {
            dispatch(invalidateUsuario());
        },
        createUsuario: (usuario) => {
            dispatch(createUsuario(usuario));
        },
        updateUsuario: (usuario) => {
            dispatch(updateUsuario(usuario));
        },
        saveUsuarioAndHabilitaciones: (idCuenta, idHerramienta, codAcceso) => {
            dispatch(saveUsuarioAndHabilitaciones(idCuenta, idHerramienta, codAcceso));
        },
        resetUpdateUsuario: () => {
            dispatch(resetUpdateUsuario());
        },
        resetCreateUsuario: () => {
            dispatch(resetCreateUsuario());
        },
        fetchAccesoCuentaUsuario: (idCuenta, idUsuario) => {
            dispatch(fetchAccesoCuentaUsuario(idCuenta, idUsuario))
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenSafe));
