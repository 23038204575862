import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

import {
    RECEIVE_ACCESOSCUENTA,
    RECEIVE_ACCESOCUENTA_USUARIO
} from "../actions/AccesosCuentaActions";

import {
    CREATE_HABILITACION,
    ERROR_CREATE_HABILITACION,
    REQUEST_CREATE_HABILITACION,
    RESET_CREATE_HABILITACION,
    SUCCESS_CREATE_HABILITACION
} from "../actions/HabilitacionActions";

function habilitacionById(
    state = {
        isFetching: false,
        didInvalidate: true,
        habilitaciones: {},
    }, action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                habilitaciones: merge({}, state.habilitaciones, action.accesosCuenta.entities.habilitaciones),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                habilitaciones: merge({}, state.habilitaciones, action.accesosCuenta.entities.habilitaciones),
                lastUpdated: action.receivedAt
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                habilitaciones: {},
            });
        default:
            return state
    }
}

function allHabilitaciones(state = [], action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return action.accesosCuenta.entities.habilitaciones ? union(state, Object.keys(action.accesosCuenta.entities.habilitaciones)) : state;
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return action.accesosCuenta.entities.habilitaciones ? union(state, Object.keys(action.accesosCuenta.entities.habilitaciones)) : state;
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_HABILITACION:
            return merge({}, state, {
                nuevo: action.habilitaciones
            });
        case REQUEST_CREATE_HABILITACION:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_HABILITACION:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt
            });
        case ERROR_CREATE_HABILITACION:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_HABILITACION:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: {},
                error: ""
            });

        default:
            return state
    }
}

const habilitaciones = combineReducers({
    create: create,
    byId: habilitacionById,
    allIds: allHabilitaciones,
});

export default habilitaciones;