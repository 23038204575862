import {
    INVALIDATE_FECHALISTAPRECIO,
    REQUEST_FECHALISTAPRECIO,
    RECEIVE_FECHALISTAPRECIO,
    ERROR_FECHALISTAPRECIO,
    FINISH_DOWNLOADING_IMPRIMIR_LISTAPRECIOS,
    REQUEST_IMPRIMIR_LISTAPRECIOS,
    SUCCESS_IMPRIMIR_LISTAPRECIOS,
    ERROR_IMPRIMIR_LISTAPRECIOS,
    DOWNLOADING_IMPRIMIR_LISTAPRECIOS,
    INVALIDATE_DOLAR,
    REQUEST_DOLAR,
    RECEIVE_DOLAR,
    ERROR_DOLAR,
    REQUEST_LISTAPRECIOS,
    RECEIVE_LISTAPRECIOS,
    INVALIDATE_LISTAPRECIOS,
    ERROR_LISTAPRECIOS,
    RESET_LISTAPRECIOS,
    REQUEST_LISTAPRECIO,
    RECEIVE_LISTAPRECIO,
    INVALIDATE_LISTAPRECIO,
    ERROR_LISTAPRECIO,
    RESET_LISTAPRECIO,
} from '../actions/ListaPreciosActions';
import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import union from "lodash/union";
import {
    UPDATE_LISTAPRECIO,
    REQUEST_UPDATE_LISTAPRECIO,
    SUCCESS_UPDATE_LISTAPRECIO,
    ERROR_UPDATE_LISTAPRECIO,
    RESET_UPDATE_LISTAPRECIO
} from "../actions/ListaPreciosActions";

function fechaListaPrecioById(state = {
    isFetching: false,
    didInvalidate: true,
    fechaListaPrecio: null,
    listaPrecio: {},
}, action) {
    switch (action.type) {
        //FECHALISTAPRECIO
        case INVALIDATE_FECHALISTAPRECIO:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_FECHALISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_FECHALISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                fechaListaPrecio: action.fechaListaPrecio,
                lastUpdated: action.receivedAt
            });
        case ERROR_FECHALISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        //LISTA PRECIOS
        case REQUEST_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                listaPrecio: action.listaPrecio.entities.listaPrecio,
                lastUpdated: action.receivedAt
            });
        case INVALIDATE_LISTAPRECIOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case ERROR_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                listaPrecio: {},
            });
        //LISTA PRECIO
        case REQUEST_LISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
            });
        case RECEIVE_LISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                listaPrecio: action.listaPrecio.entities.listaPrecio,
                lastUpdated: action.receivedAt,
            });
        case INVALIDATE_LISTAPRECIO:
            return Object.assign({}, state, {
                didInvalidate: true,
            });
        case ERROR_LISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error,
            });
        case RESET_LISTAPRECIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                listaPrecio: {},
            });
        //LOGOUT
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                fechaListaPrecio: null,
                listaPrecio: {},
            });

        default:
            return state
    }
}

function imprimir(state = {
    isFetching: false,
    error: null,
    bytes: 0,
    total: 0,
    isDownloading: false
}, action) {
    switch (action.type) {
        case REQUEST_IMPRIMIR_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: true,
                error: null,
                bytes: 0,
                total: 0
            });
        case SUCCESS_IMPRIMIR_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                bytes: 0,
                total: action.total
            });
        case DOWNLOADING_IMPRIMIR_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                bytes: parseInt((action.bytesDescargados * 100) / state.total),
                isDownloading: true
            });
        case FINISH_DOWNLOADING_IMPRIMIR_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                bytes: 0,
                isDownloading: false
            });
        case ERROR_IMPRIMIR_LISTAPRECIOS:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                bytes: 0,
                total: 0
            });
        default:
            return state
    }
}

function dolar(state = {
    isFetching: false,
    didInvalidate: true,
    dolar: null
}, action) {
    switch (action.type) {
        case INVALIDATE_DOLAR:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_DOLAR:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_DOLAR:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                dolar: action.dolar,
                lastUpdated: action.receivedAt
            });
        case ERROR_DOLAR:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    error: ""
}, action) {
    switch (action.type) {
        case UPDATE_LISTAPRECIO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: action.listaPrecio,
                error: null
            });
        case RESET_UPDATE_LISTAPRECIO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: null,
            });
        case REQUEST_UPDATE_LISTAPRECIO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_LISTAPRECIO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null
            });
        case ERROR_UPDATE_LISTAPRECIO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function allListaPrecios(state = [], action) {
    switch (action.type) {
        case RECEIVE_LISTAPRECIOS:
            return union(state, action.listaPrecio.result);
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}


const listaPrecio = combineReducers({
    byId: fechaListaPrecioById,
    allIds: allListaPrecios,
    imprimir: imprimir,
    dolar: dolar,
    update: update,
});

export default listaPrecio;