import {combineReducers} from 'redux';
import {normalizeDatos, normalizeDato} from "../normalizers/normalizeCategorias";
import {RECEIVE_PEDIDOS} from "../actions/PedidoActions";
import {
    RECEIVE_CATEGORIA,
    RECEIVE_CATEGORIAS
} from '../actions/CategoriaActions';
import {RESET_PRODUCTOS} from "../actions/ProductoActions";
import merge from "lodash/merge";
import union from "lodash/union"
function productosById(state = {
    isFetching: false,
    didInvalidate: true,
    productos: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productos: normalizeDatos(action.categorias).entities.productos,
                lastUpdated: action.receivedAt
            });
        case RECEIVE_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productos: merge({}, state.productos, normalizeDato(action.categoria).entities.productos),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productos: merge({}, state.productos, action.pedidos.entities.producto),
                lastUpdated: action.receivedAt
            });
        case RESET_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: false,
                productos: [],
                error: null,
                didInvalidate: true,
            });
        default:
            return state
    }
}


function allProductos(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            let productos1 = normalizeDatos(action.categorias).entities.productos;
            return productos1 ? Object.keys(productos1) : [];
        case RECEIVE_CATEGORIA:
            let productos2 = normalizeDato(action.categoria).entities.productos;
            return productos2 ? Object.keys(productos2) : [];
        case RECEIVE_PEDIDOS:
            return action.pedidos.entities.producto ? union(state,Object.keys(action.pedidos.entities.producto)) : state;
        default:
            return state
    }
}


const productos = combineReducers({
    byId: productosById,
    allIds: allProductos
});

export default productos;