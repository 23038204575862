import React from "react";
import {Button, Modal} from "react-bootstrap";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class MensajeError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {mostrar: false, error: null};
    }

    componentDidUpdate(prevProps) {
        let error = null;
        Object.entries(this.props.state).forEach(
            ([key, value]) => {
                var valuePrev = prevProps.state[key];
                if (value.byId && valuePrev && valuePrev.byId && !value.byId.isFetching && valuePrev.byId.isFetching && value.byId.error) {
                    error = value.byId.error;
                }
                if (value.create && valuePrev && valuePrev.create && !value.create.isCreating && valuePrev.create.isCreating && value.create.error) {
                    error = value.create.error;
                }
                if (value.update && valuePrev && valuePrev.update && !value.update.isUpdating && valuePrev.update.isUpdating && value.update.error) {
                    error = value.update.error;
                }
                if (value.delete && valuePrev && valuePrev.delete && !value.delete.isDeleting && valuePrev.delete.isDeleting && value.delete.error) {
                    error = value.delete.error;
                }
                if (value.validate && valuePrev && valuePrev.validate && !value.validate.isValidating && valuePrev.validate.isValidating && value.validate.error) {
                    error = value.validate.error;
                }
            });

        if (this.state.mostrar != (error != null) && error != null) {
            this.setState({mostrar: true, error: error});
        }
    }

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    render() {
        const {mostrar, error} = this.state;

        let Errores = "";
        try {
            let err = "";
            if (this.IsJsonString(error))
                err = JSON.parse(error);
            else
                err = error;
            if (err && typeof (err) == "object") {
                Errores = Object.values(err).map((er, index) => {
                    return <p key={er[0] + index}>{er[0]}</p>;
                });
            }
            else
                Errores = <p>{error}</p>
        } catch (e) {
            Errores = <p>{error}</p>
        }

        return (
            <Modal show={mostrar} onHide={() => {
                this.setState({mostrar: false})
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Errores}
                    <p className="text-warning">
                        <small>Intentelo nuevamente.</small>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="danger" onClick={() => {
                        this.setState({mostrar: false})
                    }}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state,
    };
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps)(MensajeError));
