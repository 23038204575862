import c from '../constants/constants';
import $ from 'jquery';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var linksPagos = {

    getAll(filtros) {
        var esc = encodeURIComponent;
        var query = "";
        if (filtros)
            query = Object.keys(filtros)
                .map(k => esc(k) + '=' + esc(filtros[k]))
                .join('&');

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                'idCuenta': window.location.pathname.split('/')[3], 'idHerramienta': 6,
            },
            // cache: false,
            dataType: 'json',
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }
        return fetch(c.BASE_URL + '/linksPagos/?' + query, defaultOptions);
    },
    getOne(idLinkPago) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                'idCuenta': window.location.pathname.split('/')[3], 'idHerramienta': 6,
            },
            // cache: false,
            dataType: 'json',
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }
        return fetch(c.BASE_URL + '/linksPagos/' + idLinkPago, defaultOptions);
    },
    getFile(idAsset, filtros) {
        var esc = encodeURIComponent;
        var query = "";
        if (filtros)
            query = Object.keys(filtros)
                .map(k => esc(k) + '=' + esc(filtros[k]))
                .join('&');
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "pragma": "no-cache",
                "cache-control": "no-cache",
            }
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }
        return fetch(c.BASE_URL + '/linksPagos/' + idAsset + '/file/?' + query, defaultOptions);
    },

    saveCreate(nuevo, files) {
        var formData = new FormData();
        if (files) {
            files.forEach((file) => {
                if (file)
                    formData.append(file.name, file);
            });
        }
        formData.append("linkPago", JSON.stringify(nuevo));
        return $.ajax({
            url: c.BASE_URL + '/linksPagos/',
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('authorization', "Bearer " + localStorage.token);
                xhr.setRequestHeader('idCuenta', window.location.pathname.split('/')[3]);
                xhr.setRequestHeader('idHerramienta', 6);
            },
            contentType: false,
            type: 'POST',
            processData: false,
            enctype: 'multipart/form-data',
        });

    },
    saveUpdate(activo, files) {
        var formData = new FormData();
        if (files) {
            files.forEach((file) => {
                if (file)
                    formData.append(file.name, file);
            });
        }

        formData.append("linkPago", JSON.stringify(activo));
        return $.ajax({
            url: c.BASE_URL + '/linksPagos/' + activo.id,
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('authorization', "Bearer " + localStorage.token);
                xhr.setRequestHeader('idCuenta', window.location.pathname.split('/')[3]);
                xhr.setRequestHeader('idHerramienta', 6);
            },
            contentType: false,
            type: 'POST',
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    saveUpdateLinksPagos(activos) {
        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token,
                'idCuenta': window.location.pathname.split('/')[3], 'idHerramienta': 6,
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(activos)
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }
        return fetch(c.BASE_URL + '/linksPagos/all', defaultOptions);
    },
    saveDelete(linkPago) {
        let defaultOptions = {
            url: '',
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token,
                'idCuenta': window.location.pathname.split('/')[3], 'idHerramienta': 6,
            },
            // cache: false,
            dataType: 'json',
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }
        return fetch(c.BASE_URL + '/linksPagos/' + linkPago.id, defaultOptions);
    },
    saveCreateLinksPagos(nuevos) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token,
                'idCuenta': window.location.pathname.split('/')[3], 'idHerramienta': 6,
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(nuevos)
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }
        return fetch(c.BASE_URL + '/linksPagos/all', defaultOptions);
    },
    printLinkPago(idLinkPago) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.token,
                'idCuenta': window.location.pathname.split('/')[3], 'idHerramienta': 6,
            },
            cache: "no-cache"
        };
        return fetch(c.BASE_URL + '/linksPagos/' + idLinkPago + "/edit/", defaultOptions);
    },
};

export default linksPagos;