import {combineReducers} from 'redux';
import merge from "lodash/merge";
import union from "lodash/union";

//Actions
import {
    REQUEST_ACCESOSCUENTA,
    RECEIVE_ACCESOSCUENTA,
    INVALIDATE_ACCESOSCUENTA,
    ERROR_ACCESOSCUENTA,
    INVALIDATE_ACCESOCUENTA_USUARIO,
    REQUEST_ACCESOCUENTA_USUARIO,
    RECEIVE_ACCESOCUENTA_USUARIO,
    ERROR_ACCESOCUENTA_USUARIO
} from "../actions/AccesosCuentaActions";

import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";


function accesosCuentaById(state = {
    isFetching: false,
    didInvalidate: true,
    accesosCuenta: {},
}, action) {
    switch (action.type) {
        // GET ALL
        case REQUEST_ACCESOSCUENTA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null
            });
        case RECEIVE_ACCESOSCUENTA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                accesosCuenta: action.accesosCuenta.entities.accesosCuenta,
                lastUpdated: action.receivedAt
            });
        case INVALIDATE_ACCESOSCUENTA:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null
            });
        case ERROR_ACCESOSCUENTA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                accesosCuenta: {},
            });
        // GET ONE
        case REQUEST_ACCESOCUENTA_USUARIO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null
            });
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                accesosCuenta: merge({}, state.accesosCuenta, action.accesosCuenta.entities.accesosCuenta),
                lastUpdated: action.receivedAt
            });
        case ERROR_ACCESOCUENTA_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}

function allAccesosCuenta(state = [], action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return union(state, action.accesosCuenta.result);
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return union(state, action.accesosCuenta.result);
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

const accesosCuenta = combineReducers({
    byId: accesosCuentaById,
    allIds: allAccesosCuenta,
});

export default accesosCuenta;