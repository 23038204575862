import React from "react";
import * as EstadoPedido from "../constants/EstadoPedido"
import {Label} from "react-bootstrap";
import c from '../constants/constants';
require('es6-promise').polyfill();
require('isomorphic-fetch');

var estadosPedido = {

    // save(estado,idPedido) {
    //
    //     let defaultOptions = {
    //         url: '',
    //         method: 'POST',
    //         mode: 'cors',
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             "Content-Type": "application/json;charset=UTF-8",
    //             "Authorization": "Bearer "+localStorage.token
    //         },
    //         // cache: false,
    //         dataType: 'json',
    //         body: JSON.stringify({desEstadoPedido: estado})
    //     };
    //
    //     return fetch(c.BASE_URL + '/estadosPedido/'+idPedido, defaultOptions);
    // },
    // labelEstadoPedido(etiquetaEstado) {
    //     switch (etiquetaEstado) {
    //         case EstadoPedido.APROBADO:
    //             return <Label bsStyle="success">{EstadoPedido.APROBADO}</Label>;
    //         case EstadoPedido.STOCK_VERIFICADO_CON_DIFICULTAD:
    //             return <Label bsStyle="danger">{EstadoPedido.STOCK_VERIFICADO_CON_DIFICULTAD}</Label>;
    //         case EstadoPedido.EN_CURSO:
    //             return <Label bsStyle="warning">{EstadoPedido.EN_CURSO}</Label>;
    //         case EstadoPedido.CERRADO:
    //             return <Label bsStyle="primary">{EstadoPedido.CERRADO}</Label>;
    //         case EstadoPedido.CERRADO_CON_INCONVENIENTES:
    //             return <Label bsStyle="danger">{EstadoPedido.CERRADO_CON_INCONVENIENTES}</Label>;
    //         case EstadoPedido.CERRADO_RESUELTO:
    //             return <Label bsStyle="default">{EstadoPedido.CERRADO_RESUELTO}</Label>;
    //         case EstadoPedido.CANCELADO:
    //             return <Label bsStyle="danger">{EstadoPedido.CANCELADO}</Label>;
    //         default:
    //             return <Label bsStyle="default">{etiquetaEstado}</Label>;
    //     }
    // },
    // estiloEstadoPedido(etiquetaEstado) {
    //     switch (etiquetaEstado) {
    //         case EstadoPedido.APROBADO:
    //             return "success";
    //         case EstadoPedido.STOCK_VERIFICADO_CON_DIFICULTAD:
    //             return "danger";
    //         case EstadoPedido.EN_CURSO:
    //             return "warning";
    //         case EstadoPedido.CERRADO:
    //             return "primary";
    //         case EstadoPedido.CERRADO_CON_INCONVENIENTES:
    //             return "danger";
    //         case EstadoPedido.CERRADO_RESUELTO:
    //             return "default";
    //         case EstadoPedido.CANCELADO:
    //             return "danger";
    //         default:
    //             return "default";
    //     }
    // },
    buscarUltimoEstadoPedido(listaIdEstados,estadoById){
        let ultimoEstadoPedido = null;
        let maxFecha = null;
        listaIdEstados.some(function (idEstado) {
            let estado = estadoById[idEstado];
            if (!maxFecha || (estado && estado.fechaEstado > maxFecha)) {
                maxFecha = estado.fechaEstado;
                ultimoEstadoPedido = estado;
            }
        });
        return ultimoEstadoPedido;
    }
};

export default estadosPedido;