/**
 * Created by joelg on 6/9/17.
 */

//localhost
if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_ENVI) {
    var BASE_URL = "http://localhost:8888/api";
    var IMG_URL = "http://localhost:8888";
    var EXTERNAL_URL = "http://localhost:3001";
}
//testing
if (process.env.REACT_APP_ENVI == 'develop') {
    var BASE_URL = "https://test.kitexperto.com/kitBE/api";
    var IMG_URL = "https://test.kitexperto.com/kitBE";
    var EXTERNAL_URL = "https://test.kitexperto.com";
}

//Produccion
if (process.env.NODE_ENV === 'production' && !process.env.REACT_APP_ENVI) {
    var BASE_URL = "https://kitexperto.com/kitBE/api";
    var IMG_URL = "https://kitexperto.com/kitBE";
    var EXTERNAL_URL = "https://kitexperto.com";
}

export default {
    BASE_URL: BASE_URL,
    EXTERNAL_URL: EXTERNAL_URL,
    IMG_URL:IMG_URL
}