import {
    UPDATE_SORTEO,
    ERROR_UPDATE_SORTEO,
    RESET_UPDATE_SORTEO,
    REQUEST_UPDATE_SORTEO,
    SUCCESS_UPDATE_SORTEO,
    ERROR_PARTICIPANTESEVENTO,
    INVALIDATE_PARTICIPANTESEVENTO,
    RECEIVE_PARTICIPANTESEVENTO,
    REQUEST_PARTICIPANTESEVENTO
} from '../actions/SorteoActions';
import {combineReducers} from 'redux';
import {RECEIVE_LISTAPRECIOS} from "../actions/ListaPreciosActions";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import union from "lodash/union";


function update(state = {
    isUpdating: false,
    error: "",
    message: "",
}, action) {
    switch (action.type) {
        case UPDATE_SORTEO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                message: null
            });
        case REQUEST_UPDATE_SORTEO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null,
                message: null
            });
        case SUCCESS_UPDATE_SORTEO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                message: action.message
            });
        case ERROR_UPDATE_SORTEO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error,
                message: null,
            });
        case RESET_UPDATE_SORTEO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                message: null,
            });
        default:
            return state
    }
}


function participantesById(state = {
    isFetching: false,
    didInvalidate: true,
    participantes: null,
}, action) {
    switch (action.type) {
        //PARTICIPANTES
        case INVALIDATE_PARTICIPANTESEVENTO:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PARTICIPANTESEVENTO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PARTICIPANTESEVENTO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                participantes: action.participantes,
                lastUpdated: action.receivedAt
            });
        case ERROR_PARTICIPANTESEVENTO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state;
    }
}


const sorteos = combineReducers({
    update: update,
    byId: participantesById,
});

export default sorteos;