import {
    RECEIVE_PEDIDOS, RESET_PEDIDOS
} from '../actions/PedidoActions';
import { combineReducers } from 'redux';

import merge from "lodash/merge";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

function estadosEntregaById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosEntrega: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                estadosEntrega:merge({}, state.estadosEntrega, action.pedidos.entities.estadosEntrega),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                estadosEntrega: [],
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                estadosEntrega: [],
            });
        default:
            return state
    }
}


function allEstadosEntrega(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const estadosEntrega = combineReducers({
    byId : estadosEntregaById,
    allIds : allEstadosEntrega
});

export default estadosEntrega;