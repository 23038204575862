import {
    INVALIDATE_MEDIOSPAGO,
    REQUEST_MEDIOSPAGO, RECEIVE_MEDIOSPAGO, ERROR_MEDIOSPAGO, RESET_MEDIOSPAGO
} from '../actions/MediosPagoActions';
import { combineReducers } from 'redux';
import normalizeDatos from "../normalizers/normalizeMediosPago";
import {RECEIVE_PAGO, RECEIVE_PAGOS} from "../actions/PagoActions";
import merge from "lodash/merge";

function mediosPagoById(state = {
    isFetching: false,
    didInvalidate: true,
    mediosPago: []
}, action) {
    switch (action.type) {
        case INVALIDATE_MEDIOSPAGO:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_MEDIOSPAGO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_MEDIOSPAGO:
            let dato = normalizeDatos(action.mediosPago).entities.mediosPago;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                mediosPago: dato ? dato : {},
                lastUpdated: action.receivedAt
            });
        case ERROR_MEDIOSPAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_MEDIOSPAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                mediosPago: {}
            });

        //PAGOS
        case RECEIVE_PAGOS:
            return Object.assign({}, state, {
                mediosPago: merge({}, state.mediosPago, action.pagos.entities && action.pagos.entities.mediosPago ? action.pagos.entities.mediosPago : {}),
                error: null
            });
        case RECEIVE_PAGO:
            return Object.assign({}, state, {
                mediosPago: merge({}, state.mediosPago, action.pago.entities && action.pago.entities.mediosPago ? action.pago.entities.mediosPago : {}),
                error: null
            });
        default:
            return state
    }
}


function allMediosPago(state = [], action) {
    switch (action.type) {
        case RECEIVE_MEDIOSPAGO:
            return normalizeDatos(action.mediosPago).result ? normalizeDatos(action.mediosPago).result : [];
        case RESET_MEDIOSPAGO:
            return [];
        default:
            return state
    }
}


const mediosPago = combineReducers({
    byId : mediosPagoById,
    allIds : allMediosPago
});

export default mediosPago;