import {
    INVALIDATE_CATEGORIAS,
    REQUEST_CATEGORIAS,
    RECEIVE_CATEGORIAS,
    ERROR_CATEGORIAS,
    REQUEST_CATEGORIA,
    RECEIVE_CATEGORIA,
    ERROR_CATEGORIA, RESET_CATEGORIAS
} from '../actions/CategoriaActions';
import {combineReducers} from 'redux';
import {normalizeDatos, normalizeDato} from "../normalizers/normalizeCategorias";

import merge from "lodash/merge"

function categoriasById(state = {
    isFetching: false,
    didInvalidate: true,
    categorias: {}
}, action) {
    switch (action.type) {
        case INVALIDATE_CATEGORIAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                categorias: normalizeDatos(action.categorias).entities.categorias,
                lastUpdated: action.receivedAt
            });
        case ERROR_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case REQUEST_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                categorias: merge({}, state.categorias, normalizeDato(action.categoria).entities.categorias),
                lastUpdated: action.receivedAt
            });
        case ERROR_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                categorias: {}
            });
        default:
            return state
    }
}


function allCategorias(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return normalizeDatos(action.categorias).result;
        default:
            return state
    }
}


const categorias = combineReducers({
    byId: categoriasById,
    allIds: allCategorias
});

export default categorias;