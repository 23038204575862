import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../../assets/css/Footer.css';
import face from '../../assets/img/face.png';
import linked from '../../assets/img/linked.png';
import youtube from '../../assets/img/youtube.png';
import compraProfesional from "../../assets/img/comProfesionalBg.png";

class Footer2 extends Component {
    render() {

        return (
            <section className="footer">
                <div className="container-limit" style={{margin: "0 auto"}}>
                    <Row style={{marginTop: "160px"}}>
                        <Col md={4} sm={4} xs={12} className="primer-col">
                            <div className="foot-uno">
                                <img src={compraProfesional} alt=""/>
                                <p>
                                    En Kit trabajamos para brindar el
                                    mejor precio, siempre de la mano de un
                                    servicio profesional. Por ese motivo, tu compra
                                    será supervisada, entregada y respaldada por
                                    un técnico integrador o instalador
                                    verificado en tu zona.
                                </p>
                            </div>
                        </Col>

                        <Col md={4} sm={4} xs={12}>
                            <div className="center-foot">
                                <h4>Sumate a nuestras <span> redes sociales</span></h4>
                                <div className="table-sociales">
                                    <Row className="spacing-table">
                                        <a href="http://www.facebook.com/kitexperto" target="_blank">
                                            <img src={face} alt=""/>
                                            <p>Facebook</p>
                                        </a>
                                    </Row>
                                    <Row className="spacing-table">
                                        <a href="http://www.linkedin.com/company/16266330/" target="_blank">
                                            <img src={linked} alt=""/>
                                            <p>LinkedIn</p>
                                        </a>
                                    </Row>
                                    <Row className="spacing-table">
                                        <a href="http://www.youtube.com/channel/UC4NLSi0xNZ9bt74qhDfRLGA" target="_blank">
                                            <img src={youtube} alt=""/>
                                            <p>YouTube</p>
                                        </a>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={4} xs={12}>

                            <div className="table-ayuda center-foot">
                                <h4>Nosotros podemos <span> ayudarte</span></h4>
                                <Row className="spacing-table">
                                    <Link to="">
                                        <p>Vias de contacto directo</p>
                                    </Link>
                                </Row>
                                <Row className="spacing-table">
                                    <Link to="">
                                        <p>Condiciones de registro</p>
                                    </Link>
                                </Row>
                                <Row className="spacing-table">
                                    <Link to="">
                                        <p>Envío y recepción de mercaderia</p>
                                    </Link>
                                </Row>
                                <Row className="spacing-table">
                                    <Link to="">
                                        <p>Ayuda</p>
                                    </Link>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <div style={{backgroundColor: "black", height: "160px", marginTop: "60px"}}></div>
                </Row>
            </section>
        );
    }
}

export default Footer2;
