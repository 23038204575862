/* global google */
// Import all the third party stuff
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';


//import registerServiceWorker from './registerServiceWorker';

//Router
import {Redirect, Route, Router, Switch} from "react-router-dom";
import {HashLink as Link} from 'react-router-hash-link';
import history from './history';

//Redux
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers/index';
import {createLogger} from 'redux-logger';
import {Provider} from 'react-redux';


// Import the CSS file, which webpack transfers to the build folder
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './assets/css/index.css';

// Import the components used as pages
import App from './components/App';

import NotFound from './components/NotFound';
import NuevoLocal from "./components/paginas/NuevoLocal";
import Cotizacion from "./components/paginas/Cotizacion";
import RegistroEventoSiera from "./components/paginas/EventoSiera/RegistroEvento";
import CheckIn from "./components/paginas/EventoSiera/CheckIn";
import PreguntasEvento from "./components/paginas/EventoSiera/PreguntasEvento";
import Sorteo from "./components/paginas/EventoSiera/Sorteo";
import RegistroEventoTarde from "./components/paginas/EventoPSS/RegistroEventoTarde";
import RegistroEventoManiana from "./components/paginas/EventoPSS/RegistroEventoManiana";
import RegistroEventoManianaRoad from "./components/paginas/EventoRoad/RegistroEventoManianaRoad";
import CheckInPss from "./components/paginas/EventoPSS/CheckInPss";
import ResponderPreguntas from "./components/paginas/EventoPSS/ResponderPreguntas";
import PreguntasEventoRoad from "./components/paginas/EventoRoad/PreguntasEventoRoad";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Documentacion from "./components/paginas/Documentacion/Documentacion";

window.$ = window.jQuery = require("jquery");


const google = window.google;
// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    createStoreWithMiddleware = applyMiddleware(thunk, loggerMiddleware)(createStore);
    console.log("hola estoy en debug")
} else {
    console.log("hola estoy en produccion")
    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

    //chau console.los metodo 4 de esta pagina https://stapp.space/how-to-remove-javascript-console-log-pros-2/
    (function () {
        var method;
        var noop = function noop() {
        };
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeStamp', 'trace', 'warn'
        ];
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];
            console[method] = noop;
        }
    }());
}

const store = createStoreWithMiddleware(appReducers);

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/espaciotecnologico" component={NuevoLocal}/>
                <Route path="/cotizacion" component={Cotizacion}/>
                <Route path="/eventos/roadshowsiera" exact component={RegistroEventoSiera}/>
                <Route path="/eventos/pss/man" exact component={RegistroEventoManiana}/>
                <Route path="/eventosIntersec" exact component={RegistroEventoManianaRoad}/>
                <Route path="/eventos/pss/tar" exact component={RegistroEventoTarde}/>
                <Route path="/checkin" component={CheckIn}/>
                <Route path="/checkin-pss" component={CheckInPss}/>
                <Route path="/sorteo/:evento?" component={Sorteo}/>
                <Route path="/preguntas/:evento/:dni?" exact component={PreguntasEvento}/>
                <Route path="/participa/:idEvento/:dni?" exact component={PreguntasEventoRoad}/>
                <Route path="/responder/:idEvento" exact component={ResponderPreguntas}/>
                <Route path="/dolar" component={Cotizacion}/>
                <Route path="/doc" component={Documentacion}/>
                <Route path="/" component={App}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);


// registerServiceWorker();
