import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){
    const marca = new schema.Entity('marcas',{}, {idAttribute: "id"});
    const producto = new schema.Entity('productos',{marca: marca}, {idAttribute: "codigo_vd"});
    const productoKit = new schema.Entity('productosKits',{producto: producto}, {idAttribute: "id"});
    const kit = new schema.Entity('kits', {producto_kit: [productoKit]}, {idAttribute: "id"});

    const mySchema = [ kit ] ;
    const normalizedData = normalize(myData, mySchema);
    console.log(normalizedData);
    return normalizedData;
}

export default normalizeDatos;