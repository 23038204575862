import {normalize, schema} from 'normalizr';

function normalizeDatos(myData) {
    const tiposTarjetaCredito = new schema.Entity('tiposTarjetaCredito', {}, {idAttribute: "id"});
    const formaDePago = new schema.Entity('formaDePago', {}, {idAttribute: "id"});
    const entidadesFinancieras = new schema.Entity('entidadesFinancieras', {forma_de_pago: [formaDePago]}, {idAttribute: "id"});
    formaDePago.define({
        tipo_tarjeta_credito: tiposTarjetaCredito,
        entidad_financiera: entidadesFinancieras
    });
    const mySchema = {formasDePago: [formaDePago]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;