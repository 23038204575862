import React from 'react';
import {
    FormGroup,
    ControlLabel,
    HelpBlock,
    FormControl,
    Tooltip,
    OverlayTrigger
} from 'react-bootstrap';


function FieldGroup({id, label, help, ...props}) {

    return (
        <FormGroup controlId={id}>
            <ControlLabel>{props.required ? "*"+label : label}</ControlLabel>
            <div className="required-field-block">
                <FormControl {...props} />
            </div>
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

export default FieldGroup;