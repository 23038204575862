import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";
import history from "../../../../history";
import clone from "lodash/clone";

import {Row, Table, Button} from "react-bootstrap";

//Actions
import {
    fetchPedidosIfNeeded,
    updatePedido,
    saveUpdatePedido,
    resetUpdatePedido,
} from "../../../../actions/PedidoActions";
import {
    updateDetallePedidoMon,
    saveUpdateDetallesPedidoMon,
} from "../../../../actions/DetallePedidoMonActions";
import {fetchDolarIfNeeded} from "../../../../actions/ListaPreciosActions";

//Components
import HeaderPlataforma from "./HeaderPlataforma";
import Loader from "../../../../components/elementos/loader/Loader";

//CSS
import "../../../../assets/css/CuentaYOrden.css";

//Constants
import * as modoCuentaYOrden from "../../../../constants/ModoCuentaYOrden";
import * as moneda from "../../../../constants/Moneda";

class CuentaYOrden extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipoFacturacion: "",
            buscarPedidosUsuario: true,
            totalConvertido: null,
            totalCuentaYOrdenConvertido: null,
            multiplicador: 1,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let state = this.state;
        this.props.fetchPedidosIfNeeded({
            id: this.props.match.params.idPedido,
            buscarPedidosUsuario: state.buscarPedidosUsuario,
        });
        this.props.fetchDolarIfNeeded();
    }

    componentWillUnmount() {
        this.props.resetUpdatePedido();
    }

    componentDidUpdate(prevProps, prevState) {
        //Si la division del totalizador es menor al total le agrego 0.01 (asi el totalCuentaYOrden no es menor al total)
        if (
            prevState.tipoFacturacion != this.state.tipoFacturacion &&
            this.state.tipoFacturacion == modoCuentaYOrden.TOTALIZADOR &&
            parseFloat(this.props.pedidos.update.activo.totalCuentaYOrden) <
                parseFloat(this.props.pedidos.update.activo.total)
        ) {
            var totalCuentaYOrden = 0;
            this.props.detallesPedidoMon.allIds.map((idDetallePedidoMon) => {
                var detalle =
                    this.props.detallesPedidoMon.byId.detallesPedidoMon[
                        idDetallePedidoMon
                    ];
                var detalleClonado = clone(detalle);
                if (
                    detalle &&
                    detalle.idPedidoMon ==
                        this.props.pedidos.update.activo.id &&
                    detalle.nroItem == 1
                ) {
                    detalleClonado.precioCuentaYOrden = (
                        parseFloat(detalleClonado.precioCuentaYOrden) + 0.01
                    ).toFixed(2);
                    this.props.updateDetallePedidoMon(
                        detalleClonado,
                        idDetallePedidoMon
                    );
                    totalCuentaYOrden +=
                        detalleClonado.precioCuentaYOrden *
                        detalleClonado.cantidad;
                    var cambio = {};
                    cambio["totalCuentaYOrden"] = totalCuentaYOrden.toString();
                    this.props.updatePedido(cambio, null);
                }
            });
        }
        //Si el pedido tiene definido un modo de facturacion lo guardo en el state y muestro la tabla
        if (
            (prevProps.pedidos.update.activo.modoCuentaYOrden !=
                this.props.pedidos.update.activo.modoCuentaYOrden &&
                this.props.pedidos.update.activo.modoCuentaYOrden) ||
            (this.props.pedidos.update.activo.modoCuentaYOrden &&
                this.state.tipoFacturacion == "")
        ) {
            this.setState({
                tipoFacturacion:
                    this.props.pedidos.update.activo.modoCuentaYOrden,
            });
            this.refs.tablaPrecios.classList.add("in");
        }
        if (
            prevProps.pedidos.update.activo !=
                this.props.pedidos.update.activo &&
            this.props.pedidos.update.activo.idMonedaFacturacion &&
            this.props.pedidos.update.activo.idMonedaFacturacion ==
                moneda.PESO_ID &&
            this.props.pedidos.update.activo.valorDolarConversion
        ) {
            this.calcularTotalesConvertidos(
                this.props.pedidos.update.activo.valorDolarConversion,
                moneda.PESO_ID
            );
        }
    }

    changeTipoFacturacion(e) {
        this.setState({tipoFacturacion: e.target.value});
        if (e.target.value != "") {
            this.actualizarTotalTuPrecio(e.target.value, null, null);
        }
        //Mostrar/Ocultar tabla
        if (e.target.value != "") this.refs.tablaPrecios.classList.add("in");
        else this.refs.tablaPrecios.classList.remove("in");
    }

    actualizarTotalTuPrecio(
        tipoFacturacion,
        idDetalleCambiado,
        precioCuentaYOrdenCambiado
    ) {
        var totalCuentaYOrden = 0;
        this.props.detallesPedidoMon.allIds.map((idDetallePedidoMon) => {
            var detalle =
                this.props.detallesPedidoMon.byId.detallesPedidoMon[
                    idDetallePedidoMon
                ];
            var detalleClonado = clone(detalle);
            //Actualizo detalles solo cuando cambio de tipo de facturacion
            if (
                detalle &&
                detalle.idPedidoMon == this.props.pedidos.update.activo.id &&
                !idDetalleCambiado &&
                !precioCuentaYOrdenCambiado
            ) {
                if (
                    tipoFacturacion == modoCuentaYOrden.ESTANDAR &&
                    detalleClonado.precioProducto != "0.00"
                ) {
                    detalleClonado.precioCuentaYOrden =
                        detalleClonado.precioProducto;
                    this.props.updateDetallePedidoMon(
                        detalleClonado,
                        idDetallePedidoMon
                    );
                } else if (
                    tipoFacturacion == modoCuentaYOrden.TOTALIZADOR &&
                    detalle.nroItem == 1
                ) {
                    detalleClonado.precioCuentaYOrden = parseFloat(
                        this.props.pedidos.update.activo.total /
                            detalle.cantidad
                    ).toFixed(2);
                    this.props.updateDetallePedidoMon(
                        detalleClonado,
                        idDetallePedidoMon
                    );
                } else if (
                    tipoFacturacion == modoCuentaYOrden.TOTALIZADOR &&
                    detalle.nroItem != 1
                ) {
                    detalleClonado.precioCuentaYOrden = 0;
                    this.props.updateDetallePedidoMon(
                        detalleClonado,
                        idDetallePedidoMon
                    );
                }
            }
            //Actualizo total usando el detalleClonado que puede o no estar modificado
            if (
                detalle &&
                detalle.idPedidoMon == this.props.pedidos.update.activo.id &&
                (tipoFacturacion == modoCuentaYOrden.ESTANDAR ||
                    (tipoFacturacion == modoCuentaYOrden.TOTALIZADOR &&
                        detalle.nroItem == "1"))
            ) {
                if (
                    idDetalleCambiado &&
                    idDetalleCambiado == detalle.id &&
                    precioCuentaYOrdenCambiado != null
                ) {
                    if (precioCuentaYOrdenCambiado != "")
                        totalCuentaYOrden +=
                            parseFloat(precioCuentaYOrdenCambiado) *
                            detalle.cantidad;
                } else if (
                    ((idDetalleCambiado && idDetalleCambiado != detalle.id) ||
                        !idDetalleCambiado) &&
                    detalleClonado.precioCuentaYOrden != null
                ) {
                    totalCuentaYOrden +=
                        parseFloat(detalleClonado.precioCuentaYOrden) *
                        detalle.cantidad;
                } else if (
                    detalleClonado.precioProducto &&
                    detalleClonado.precioProducto != "0.00"
                ) {
                    totalCuentaYOrden +=
                        parseFloat(detalleClonado.precioProducto) *
                        detalle.cantidad;
                }
            }
        });
        var cambio = {};
        cambio["totalCuentaYOrden"] = parseFloat(totalCuentaYOrden).toFixed(2);
        this.props.updatePedido(cambio, null);
    }

    changePrecio(e, idDetalle, detalle) {
        var detalleUpdate = clone(detalle);
        if (e.target.value.indexOf(".") == -1) {
            //Si ingreso algo distinto de 0 o vacio convierto a float, si fue 0 lo paso a string para que no lo confunda con boolean en el if del value del input
            detalleUpdate.precioCuentaYOrden =
                e.target.value === 0
                    ? "0"
                    : e.target.value == ""
                    ? ""
                    : parseFloat(e.target.value);
        } else {
            detalleUpdate.precioCuentaYOrden = e.target.value;
        }
        this.props.updateDetallePedidoMon(detalleUpdate, idDetalle);
        //Actualizo el totalCuentaYOrden en base al precio modificado
        this.actualizarTotalTuPrecio(
            this.state.tipoFacturacion,
            idDetalle,
            detalleUpdate.precioCuentaYOrden
        );
    }

    changeMoneda(e) {
        var valorDolar = this.props.listaPrecio.dolar.dolar
            ? this.props.listaPrecio.dolar.dolar.dolar
            : "";
        valorDolar = parseFloat(valorDolar.replace(",", ".")).toFixed(2);
        var cotizacion = (
            parseFloat(valorDolar) * this.state.multiplicador
        ).toFixed(2);
        if (this.props.pedidos.update.activo.valorDolarConversion) {
            cotizacion = this.props.pedidos.update.activo.valorDolarConversion;
        }

        this.calcularTotalesConvertidos(cotizacion, e.target.value);

        var cambio = {};
        cambio[e.target.id] = e.target.value;
        cambio["valorDolarConversion"] = cotizacion;
        if (e.target.value == moneda.DOLAR_ID) {
            cambio["valorDolarConversion"] = null;
            cambio["formaDePago"] = null;
        }
        this.props.updatePedido(cambio, null);
    }

    changeCotizacion(e, valorDolar) {
        var multiplicador = 1;
        switch (e.target.value) {
            case "Cotización U$D":
                multiplicador = 1;
                break;
            case "Contado o E-cheq 7 días":
                multiplicador = 1;
                break;
            case "E-cheq 30 días":
                multiplicador = 1.04;
                break;
            case "E-cheq 60 días":
                multiplicador = 1.08;
                break;
            case "E-cheq 90 días":
                multiplicador = 1.13;
                break;
            case "E-cheq 120 días":
                multiplicador = 1.19;
                break;
            default:
                multiplicador = 1;
                break;
        }
        this.setState({multiplicador: multiplicador});
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        cambio["valorDolarConversion"] = parseFloat(
            valorDolar * multiplicador
        ).toFixed(2);
        this.props.updatePedido(cambio, null);
        this.calcularTotalesConvertidos(
            cambio["valorDolarConversion"],
            moneda.PESO_ID
        );
    }

    calcularTotalesConvertidos(cotizacion, monedaActual) {
        var pedido = this.props.pedidos.update.activo;
        var total = 0;
        var totalCuentaYOrden = 0;
        this.props.detallesPedidoMon.allIds.map((idDetalle) => {
            var detalle =
                this.props.detallesPedidoMon.byId.detallesPedidoMon[idDetalle];
            if (
                detalle &&
                detalle.idPedidoMon == this.props.match.params.idPedido
            ) {
                var detalleClonado = clone(detalle);
                //PrecioProducto convertido a la moneda de cambio
                var conversionPrecioProducto = null;
                if (
                    detalleClonado.precioProducto != 0.0 &&
                    monedaActual == moneda.PESO_ID
                ) {
                    //PrecioProducto a pesos
                    conversionPrecioProducto = parseFloat(
                        detalle.precioProducto * cotizacion
                    );
                    //PrecioProductoTuPrecio a pesos
                    detalleClonado.precioCuentaYOrden = parseFloat(
                        detalleClonado.precioCuentaYOrden * cotizacion
                    );
                } else if (
                    detalleClonado.precioProducto != 0.0 &&
                    monedaActual == moneda.DOLAR_ID &&
                    pedido.idMoneda == moneda.PESO_ID
                ) {
                    //PrecioProducto a pesos
                    conversionPrecioProducto = parseFloat(
                        detalle.precioProducto / cotizacion
                    ).toFixed(2);
                    conversionPrecioProducto = parseFloat(
                        conversionPrecioProducto
                    );
                    //PrecioProductoTuPrecio a pesos
                    detalleClonado.precioCuentaYOrden = parseFloat(
                        detalleClonado.precioCuentaYOrden / cotizacion
                    ).toFixed(2);
                    detalleClonado.precioCuentaYOrden = parseFloat(
                        detalleClonado.precioCuentaYOrden
                    );
                }

                if (conversionPrecioProducto) {
                    total += conversionPrecioProducto * detalleClonado.cantidad;
                } else {
                    total +=
                        detalleClonado.precioProducto * detalleClonado.cantidad;
                }

                if (detalleClonado.precioCuentaYOrden != null) {
                    totalCuentaYOrden += parseFloat(
                        detalleClonado.precioCuentaYOrden *
                            detalleClonado.cantidad
                    );
                } else if (conversionPrecioProducto) {
                    totalCuentaYOrden += parseFloat(
                        conversionPrecioProducto * detalleClonado.cantidad
                    );
                } else {
                    totalCuentaYOrden += parseFloat(
                        detalleClonado.precioProducto * detalleClonado.cantidad
                    );
                }
            }
        });
        if (
            monedaActual == moneda.PESO_ID ||
            (monedaActual == moneda.DOLAR_ID &&
                pedido.idMoneda == moneda.PESO_ID)
        ) {
            this.setState({
                totalConvertido: total.toFixed(2),
                totalCuentaYOrdenConvertido: totalCuentaYOrden.toFixed(2),
            });
        } else {
            this.setState({
                totalConvertido: null,
                totalCuentaYOrdenConvertido: null,
            });
        }
    }

    guardarCambiosPedido(e) {
        var pedido = clone(this.props.pedidos.update.activo);
        if (this.props.detallesPedidoMon.update.detallesPedidoMon.length) {
            pedido["modoCuentaYOrden"] = this.state.tipoFacturacion;
            this.props.saveUpdateDetallesPedidoMon();
        }
        //Si no selecciono moneda de facturacion guardo por defecto la moneda original del pedido
        if (!pedido.idMonedaFacturacion) {
            pedido.idMonedaFacturacion = pedido.idMoneda;
        }
        this.props.saveUpdatePedido(pedido);
    }

    render() {
        var array = [1, 2, 3, 4];
        var pedido = this.props.pedidos.update.activo;

        var simbolo = pedido.moneda ? pedido.moneda.simbolo : moneda.DOLAR;
        if (pedido.idMonedaFacturacion) {
            if (pedido.idMonedaFacturacion == moneda.DOLAR_ID)
                simbolo = moneda.DOLAR;
            else if (pedido.idMonedaFacturacion == moneda.PESO_ID)
                simbolo = moneda.PESO;
        }

        var valorDolar = this.props.listaPrecio.dolar.dolar
            ? this.props.listaPrecio.dolar.dolar.dolar
            : "";
        if (valorDolar == "") valorDolar = 0;
        else valorDolar = parseFloat(valorDolar.replace(",", ".")).toFixed(2);
        var cotizacion = (
            parseFloat(valorDolar) * this.state.multiplicador
        ).toFixed(2);

        var totalCuentaYOrdenMenor = false;
        if (
            (this.state.totalConvertido &&
                this.state.totalCuentaYOrdenConvertido &&
                parseFloat(this.state.totalCuentaYOrdenConvertido) <
                    parseFloat(this.state.totalConvertido)) ||
            (!this.state.totalConvertido &&
                !this.state.totalCuentaYOrdenConvertido &&
                parseFloat(pedido.totalCuentaYOrden) < parseFloat(pedido.total))
        ) {
            totalCuentaYOrdenMenor = true;
        }

        var Detalles = [];
        var detallesKit = new Map();
        this.props.pedidos.byId.isFetching &&
        this.props.pedidos.allIds.length === 0
            ? array.map(function (array, index) {
                  return (
                      <tr key={index}>
                          <th>
                              <p className="parrafoInvisible">Imagen</p>
                          </th>
                          <th>
                              <p className="parrafoInvisible">
                                  Artículo y Descripción
                              </p>
                          </th>
                          <th>
                              <p className="parrafoInvisible">
                                  Precio Unitario
                              </p>
                          </th>
                          <th>
                              <p className="parrafoInvisible">
                                  Precio Modificado
                              </p>
                          </th>
                          <th>
                              <p className="parrafoInvisible">Cantidad</p>
                          </th>
                          <th
                              style={{
                                  display:
                                      pedido.vd_idPrioridad == 1
                                          ? "table-cell"
                                          : "none",
                              }}
                          >
                              <p className="parrafoInvisible">IVA%</p>
                          </th>
                          <th>
                              <p className="parrafoInvisible">Total</p>
                          </th>
                          <th>
                              <p className="parrafoInvisible">
                                  Total Modificado
                              </p>
                          </th>
                      </tr>
                  );
              })
            : this.props.detallesPedidoMon.allIds.map((idDetalle) => {
                  var detalle =
                      this.props.detallesPedidoMon.byId.detallesPedidoMon[
                          idDetalle
                      ];
                  var detalleClonado = clone(detalle);
                  if (
                      detalleClonado &&
                      detalleClonado.idPedidoMon ==
                          this.props.match.params.idPedido
                  ) {
                      var producto =
                          this.props.productos.byId.productos[
                              detalleClonado.idProducto
                          ];
                      var imagenProducto = "/img/productos/" + producto.img1;
                      var ProductosKit = [];
                      if (producto.esKit) {
                          this.props.kits.allIds.map((idKit) => {
                              var kit = this.props.kits.byId.kits[idKit];
                              if (kit && kit.idProductoPadre == producto.id) {
                                  let productoKit =
                                      this.props.productos.byId.productos[
                                          kit.idProducto
                                      ];
                                  if (productoKit) {
                                      //Armo para cada detalle sus productos kits
                                      ProductosKit.push(productoKit);
                                      //Armo mapa de todos los detalles que son kits
                                      this.props.detallesPedidoMon.allIds.map(
                                          (idDetalleKit) => {
                                              var detaKit =
                                                  this.props.detallesPedidoMon
                                                      .byId.detallesPedidoMon[
                                                      idDetalleKit
                                                  ];
                                              if (
                                                  detaKit &&
                                                  detaKit.idProducto ==
                                                      productoKit.id &&
                                                  detaKit.precioProducto == 0.01
                                              ) {
                                                  detallesKit.set(
                                                      detaKit.id,
                                                      detaKit
                                                  );
                                              }
                                          }
                                      );
                                  }
                              }
                          });
                      }
                      var conversionPrecioProducto = null;
                      var cotizacionActual = pedido.valorDolarConversion
                          ? pedido.valorDolarConversion
                          : cotizacion;
                      if (
                          pedido.idMonedaFacturacion &&
                          pedido.idMoneda != pedido.idMonedaFacturacion
                      ) {
                          if (pedido.idMonedaFacturacion == moneda.DOLAR_ID) {
                              conversionPrecioProducto =
                                  detalleClonado.precioProducto /
                                  cotizacionActual;
                          } else if (
                              pedido.idMonedaFacturacion == moneda.PESO_ID
                          ) {
                              conversionPrecioProducto =
                                  detalleClonado.precioProducto *
                                  cotizacionActual;
                          }
                          conversionPrecioProducto =
                              conversionPrecioProducto.toFixed(2);
                      }

                      var totalDetalle = conversionPrecioProducto
                          ? conversionPrecioProducto * detalleClonado.cantidad
                          : detalleClonado.precioProducto *
                            detalleClonado.cantidad;
                      var totalDetalleModificado = null;
                      if (
                          pedido.idMonedaFacturacion &&
                          pedido.idMonedaFacturacion != pedido.idMoneda
                      ) {
                          if (pedido.idMonedaFacturacion == moneda.PESO_ID) {
                              if (detalleClonado.precioCuentaYOrden != null) {
                                  detalleClonado.precioCuentaYOrden =
                                      parseFloat(
                                          detalleClonado.precioCuentaYOrden *
                                              cotizacionActual
                                      ).toFixed(2);
                                  totalDetalleModificado =
                                      detalleClonado.precioCuentaYOrden;
                              } else {
                                  totalDetalleModificado = parseFloat(
                                      conversionPrecioProducto
                                  ).toFixed(2);
                              }
                          } else if (
                              pedido.idMonedaFacturacion == moneda.DOLAR_ID
                          ) {
                              if (detalleClonado.precioCuentaYOrden != null) {
                                  detalleClonado.precioCuentaYOrden =
                                      parseFloat(
                                          detalleClonado.precioCuentaYOrden /
                                              cotizacionActual
                                      ).toFixed(2);
                                  totalDetalleModificado =
                                      detalleClonado.precioCuentaYOrden;
                              } else {
                                  totalDetalleModificado = parseFloat(
                                      conversionPrecioProducto
                                  ).toFixed(2);
                              }
                          }
                      } else {
                          if (detalleClonado.precioCuentaYOrden != null)
                              totalDetalleModificado = parseFloat(
                                  detalleClonado.precioCuentaYOrden
                              ).toFixed(2);
                          else
                              totalDetalleModificado = parseFloat(
                                  detalleClonado.precioProducto
                              ).toFixed(2);
                      }

                      totalDetalleModificado =
                          totalDetalleModificado * detalleClonado.cantidad;

                      //Si los detalles no son detallesKit los muestro
                      if (!detallesKit.get(detalleClonado.id)) {
                          Detalles.push(
                              <tr
                                  key={detalleClonado.id}
                                  className={
                                      producto.esKit
                                          ? "columnaDetalles kit primera"
                                          : "columnaDetalles"
                                  }
                              >
                                  <td>
                                      <img
                                          className="imagenTabla"
                                          src={imagenProducto}
                                      />
                                  </td>
                                  <td style={{textAlign: "left"}}>
                                      <p>{producto.vd_idProducto}</p>
                                      <h5 className="nombreProducto">
                                          <b>{producto.nomProducto}</b>
                                      </h5>
                                      <Link
                                          target="_blank"
                                          to={"/productoDetalle/" + producto.id}
                                          className="verMas"
                                      >
                                          Ver mas
                                      </Link>
                                  </td>
                                  <td>
                                      {simbolo}
                                      {!conversionPrecioProducto
                                          ? detalleClonado.precioProducto
                                          : conversionPrecioProducto}
                                  </td>
                                  <td className="precioModificadoTD">
                                      {this.state.tipoFacturacion ==
                                          modoCuentaYOrden.ESTANDAR ||
                                      (this.state.tipoFacturacion ==
                                          modoCuentaYOrden.TOTALIZADOR &&
                                          detalleClonado.nroItem == 1) ? (
                                          (pedido.idMonedaFacturacion &&
                                              pedido.idMonedaFacturacion ==
                                                  moneda.PESO_ID) ||
                                          (!pedido.idMonedaFacturacion &&
                                              pedido.idMoneda ==
                                                  moneda.PESO_ID) ? (
                                              <p>
                                                  {detalleClonado.precioCuentaYOrden !=
                                                  null
                                                      ? simbolo +
                                                        detalleClonado.precioCuentaYOrden.toString()
                                                      : simbolo +
                                                        detalleClonado.precioProducto.toString()}
                                              </p>
                                          ) : (
                                              <div className="contenedorModificado">
                                                  <p> {simbolo}</p>
                                                  <input
                                                      id="precioCuentaYOrden"
                                                      className="inputModificado"
                                                      type="number"
                                                      value={
                                                          detalleClonado.precioCuentaYOrden !=
                                                          null
                                                              ? detalleClonado.precioCuentaYOrden.toString()
                                                              : detalleClonado.precioProducto.toString()
                                                      }
                                                      min={0}
                                                      placeholder={0}
                                                      pattern="^[0-9]*$"
                                                      required
                                                      onChange={(e) =>
                                                          this.changePrecio(
                                                              e,
                                                              detalleClonado.id,
                                                              detalleClonado
                                                          )
                                                      }
                                                  />
                                              </div>
                                          )
                                      ) : (
                                          <p>{simbolo}0</p>
                                      )}
                                  </td>
                                  <td>{detalleClonado.cantidad}</td>
                                  <td
                                      style={{
                                          display:
                                              this.props.pedidos.update.activo
                                                  .vd_idPrioridad == 1
                                                  ? "table-cell"
                                                  : "none",
                                      }}
                                  >
                                      {producto.iva}
                                  </td>
                                  <td>
                                      {simbolo}
                                      {totalDetalle
                                          ? totalDetalle.toFixed(2)
                                          : 0}
                                  </td>
                                  <td>
                                      {this.state.tipoFacturacion ==
                                          modoCuentaYOrden.ESTANDAR ||
                                      (this.state.tipoFacturacion ==
                                          modoCuentaYOrden.TOTALIZADOR &&
                                          detalleClonado.nroItem == 1) ? (
                                          <p>
                                              {simbolo}
                                              {totalDetalleModificado
                                                  ? totalDetalleModificado.toFixed(
                                                        2
                                                    )
                                                  : 0}
                                          </p>
                                      ) : (
                                          <p>{simbolo}0</p>
                                      )}
                                  </td>
                              </tr>
                          );
                      }
                      if (producto.esKit) {
                          ProductosKit.forEach((productoKit) => {
                              imagenProducto =
                                  "/img/productos/" + productoKit.img1;
                              let cantidadKit = 0;
                              producto.kit.map((idKit) => {
                                  var kit = this.props.kits.byId.kits[idKit];
                                  if (kit && kit.idProducto == productoKit.id) {
                                      cantidadKit =
                                          kit.cantidad *
                                          detalleClonado.cantidad;
                                  }
                              });
                              Detalles.push(
                                  <tr
                                      key={producto.id + " " + productoKit.id}
                                      className="columnaDetalles kit"
                                  >
                                      <td></td>
                                      <td style={{textAlign: "left"}}>
                                          <p>{productoKit.vd_idProducto}</p>
                                          <h5 className="nombreProducto">
                                              <b>{productoKit.nomProducto}</b>
                                          </h5>
                                          <Link
                                              target="_blank"
                                              to={
                                                  "/productoDetalle/" +
                                                  productoKit.id
                                              }
                                              className="verMas"
                                          >
                                              Ver mas
                                          </Link>
                                      </td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>{detalleClonado.cantidad}</td>
                                      <td
                                          style={{
                                              display:
                                                  pedido.vd_idPrioridad == 1
                                                      ? "table-cell"
                                                      : "none",
                                          }}
                                      >
                                          -
                                      </td>
                                      <td>-</td>
                                      <td>-</td>
                                  </tr>
                              );
                          });
                      }
                  }
              });
        //Total Pedido
        Detalles.push(
            <tr key="total" className="columnaDetalles">
                <td
                    colSpan={
                        this.props.pedidos.update.activo.vd_idPrioridad == 1
                            ? 7
                            : 6
                    }
                    style={{textAlign: "right", padding: "10px"}}
                >
                    Total
                </td>
                <td style={{whiteSpace: "nowrap"}}>
                    <p>
                        {simbolo}
                        {this.state.totalConvertido
                            ? this.state.totalConvertido
                            : pedido.total
                            ? parseFloat(pedido.total).toFixed(2)
                            : 0}
                    </p>
                </td>
            </tr>
        );
        //Total Modificado Pedido
        Detalles.push(
            <tr key="subtotal" className="columnaDetalles">
                <td
                    colSpan={
                        this.props.pedidos.update.activo.vd_idPrioridad == 1
                            ? 7
                            : 6
                    }
                    style={{textAlign: "right", padding: "10px"}}
                >
                    Total Modificado
                </td>
                <td style={{whiteSpace: "nowrap"}}>
                    <p>
                        {simbolo}
                        {this.state.totalCuentaYOrdenConvertido
                            ? this.state.totalCuentaYOrdenConvertido
                            : pedido.totalCuentaYOrden
                            ? parseFloat(pedido.totalCuentaYOrden).toFixed(2)
                            : pedido.total
                            ? parseFloat(pedido.total).toFixed(2)
                            : 0}
                    </p>
                </td>
            </tr>
        );

        return (
            <div>
                <HeaderPlataforma titulo={"Monitor de Pedidos"} />
                <div className="cuentaYOrden">
                    <Row>
                        <div className="flexTitulo">
                            <div className="titulo">
                                <h3>Cuenta y Orden.</h3>
                                <p
                                    onClick={() =>
                                        history.push("/panel/monitorPedidos/1")
                                    }
                                >
                                    [Volver]
                                </p>
                            </div>
                        </div>
                        <p>Tipo facturacion: </p>
                        <select
                            className="form-control"
                            id="tipoFacturacion"
                            required
                            value={
                                pedido.modoCuentaYOrden
                                    ? pedido.modoCuentaYOrden
                                    : this.state.tipoFacturacion
                            }
                            disabled={pedido.modoCuentaYOrden}
                            onChange={(e) => this.changeTipoFacturacion(e)}
                        >
                            <option value="">Seleccione condición</option>
                            <option value={modoCuentaYOrden.ESTANDAR}>
                                Estándar
                            </option>
                            <option value={modoCuentaYOrden.TOTALIZADOR}>
                                Totalizador
                            </option>
                        </select>
                    </Row>
                    <div
                        className="datos collapse"
                        ref="tablaPrecios"
                        style={{
                            display:
                                (this.props.listaPrecio.dolar.isFetching &&
                                    pedido.idMonedaFacturacion ==
                                        moneda.PESO_ID &&
                                    !pedido.valorDolarConversion) ||
                                !this.state.tipoFacturacion
                                    ? "none"
                                    : "block",
                        }}
                    >
                        <Table
                            className="tablaResumen"
                            id="tablaCarrito"
                            responsive
                        >
                            <thead className="tableHead">
                                <th>Imagen</th>
                                <th>Artículo y Descripción</th>
                                <th>Precio Unitario</th>
                                <th>Precio Modificado</th>
                                <th>Cantidad</th>
                                <th
                                    style={{
                                        display:
                                            pedido.vd_idPrioridad == 1
                                                ? "table-cell"
                                                : "none",
                                    }}
                                >
                                    IVA%
                                </th>
                                <th>Total</th>
                                <th>Total Modificado</th>
                            </thead>
                            <tbody>
                                {Detalles.length > 0 ? (
                                    Detalles
                                ) : (
                                    <td
                                        colSpan={10}
                                        style={{
                                            textAlign: "center",
                                            padding: "20px",
                                        }}
                                    >
                                        No hay registros de pedidos
                                    </td>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    <div
                        className="monedaPedido"
                        style={{
                            display:
                                this.state.tipoFacturacion != ""
                                    ? "block"
                                    : "none",
                        }}
                    >
                        <p>
                            Moneda facturación (Primero modifique los precios en
                            dólares y posteriormente elija la moneda de
                            facturación)
                        </p>
                        <select
                            className="form-control"
                            id="idMonedaFacturacion"
                            required
                            value={
                                pedido.idMonedaFacturacion
                                    ? pedido.idMonedaFacturacion
                                    : pedido.idMoneda
                            }
                            disabled={!this.state.tipoFacturacion}
                            onChange={(e) => this.changeMoneda(e)}
                        >
                            <option value={moneda.DOLAR_ID}>Dolar</option>
                            <option value={moneda.PESO_ID}>Peso</option>
                        </select>
                        <div
                            style={{
                                display:
                                    (pedido.idMonedaFacturacion &&
                                        pedido.idMonedaFacturacion ==
                                            moneda.DOLAR_ID) ||
                                    (!pedido.idMonedaFacturacion &&
                                        pedido.idMoneda == moneda.DOLAR_ID)
                                        ? "none"
                                        : this.props.listaPrecio.dolar
                                              .isFetching
                                        ? "none"
                                        : "block",
                            }}
                        >
                            <p>Forma de pago:</p>
                            <select
                                className="form-control"
                                id="formaDePago"
                                required
                                value={
                                    pedido.formaDePago
                                        ? pedido.formaDePago
                                        : "Cotización U$D"
                                }
                                disabled={
                                    (pedido.idMonedaFacturacion &&
                                        pedido.idMonedaFacturacion ==
                                            moneda.DOLAR_ID) ||
                                    (!pedido.idMonedaFacturacion &&
                                        pedido.idMoneda == moneda.DOLAR_ID) ||
                                    !this.state.tipoFacturacion
                                }
                                onChange={(e) =>
                                    this.changeCotizacion(e, valorDolar)
                                }
                            >
                                <option value="Cotización U$D" disabled>
                                    Cotización U$D
                                </option>
                                <option
                                    value="Contado o valores dentro de los 7 días"
                                    selected
                                >
                                    Contado o valores dentro de los 7 días
                                </option>
                                <option value="Valores dentro de los 30 días">
                                    Valores dentro de los 30 días
                                </option>
                                <option value="Valores dentro de los 60 días">
                                    Valores dentro de los 60 días
                                </option>
                                <option value="Valores dentro de los 90 días">
                                    Valores dentro de los 90 días
                                </option>
                                <option value="Valores dentro de los 120 días">
                                    Valores dentro de los 120 días
                                </option>
                            </select>
                        </div>
                        <p>
                            {pedido.valorDolarConversion
                                ? "U$D 1 = $ " + pedido.valorDolarConversion
                                : "U$D 1 = $ " + valorDolar}
                        </p>
                        <div
                            className="buscandoDolar"
                            style={{
                                display: this.props.listaPrecio.dolar.isFetching
                                    ? "block"
                                    : "none",
                            }}
                        >
                            <p>Buscando dolar..</p>
                            <Loader />
                        </div>
                    </div>
                    <div className="guardarPedido">
                        <p
                            style={{
                                display:
                                    totalCuentaYOrdenMenor &&
                                    this.state.tipoFacturacion != ""
                                        ? "block"
                                        : "none",
                                textAlign: "center",
                                color: "red",
                            }}
                        >
                            El Total Modificado no puede ser menor que el
                            original
                        </p>
                        <div className="botones">
                            <Button
                                onClick={(e) => {
                                    this.guardarCambiosPedido(e);
                                }}
                                className="center-block boton"
                                disabled={totalCuentaYOrdenMenor}
                            >
                                Modificar
                            </Button>
                            <Button
                                onClick={() => {
                                    history.push("/panel/monitorPedidos/1");
                                }}
                                className="center-block boton"
                            >
                                Volver
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pedidos: state.pedidos,
        detallesPedidoMon: state.detallesPedidoMon,
        productos: state.productos,
        kits: state.kits,
        listaPrecio: state.listaPrecio,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPedidosIfNeeded: (filtros) => {
            dispatch(fetchPedidosIfNeeded(filtros));
        },
        resetUpdatePedido: () => {
            dispatch(resetUpdatePedido());
        },
        updateDetallePedidoMon: (detallePedidoMon, idDetallePedidoMon) => {
            dispatch(
                updateDetallePedidoMon(detallePedidoMon, idDetallePedidoMon)
            );
        },
        saveUpdateDetallesPedidoMon: () => {
            dispatch(saveUpdateDetallesPedidoMon());
        },
        updatePedido: (pedido, idPedido) => {
            dispatch(updatePedido(pedido, idPedido));
        },
        saveUpdatePedido: (pedido) => {
            dispatch(saveUpdatePedido(pedido));
        },
        fetchDolarIfNeeded: () => {
            dispatch(fetchDolarIfNeeded());
        },
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CuentaYOrden)
);
