import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import timeline from '../../assets/img/timeline.png';
import tool from '../../assets/img/tool2.png';
import Footer2 from '../layout/Footer2';
//CSS
import '../../assets/css/CompraProfesional.css';


class CompraProfesional extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="profesional">
                <section className="profesional-portada">
                    <Row>
                        <Col md={8} className="center-block">
                            <div className="recuadro-celeste">
                                <h1>Compra Profesional</h1>
                                <p>
                                    Para que puedas comprar con mayor confianza productos de seguridad
                                    para tu casa o negocio, desarrollamos un sistema de compra y entrega
                                    a través de profesionales certificados.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </section>

                <section className="timeline">
                    <div className="container-limit">
                        <Row>
                            <h2>PASO A PASO</h2>
                        </Row>
                        <Row>
                            <img className="img-responsive" src={timeline} alt=""/>
                        </Row>
                    </div>
                </section>

                <section className="garantizado">

                    <div className="container-limit garantizado-bg">
                        <Row>
                            <Col md={6}>
                                <div className="titulos">
                                    <h2>100%</h2>
                                    <h3>Garantizado</h3>
                                    <p>En Kit Experto todas las compras cuentan con
                                        garantía y soporte técnico.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="llamanos" style={{marginTop: "0px"}}>
                    <div className="container-limit">
                        <Row>
                            <Col md={9} className="pull-right">
                                <h3 className="invisible">Entregas en <br/>
                                    <sb> todo </sb>
                                    el país
                                </h3>
                                <p>0810-3450724</p>
                            </Col>

                        </Row>
                    </div>
                </section>

                <section className="sos-integrador">
                    <div className="container-limit">
                        <Row>
                            <Col md={6}>
                                <div className="titulos pull-right">
                                    <h2>¿Sos integrador o
                                        instalador?
                                    </h2>
                                    <p>
                                        Conocé cómo obtener la
                                        <sb> verificación </sb>
                                        y
                                        forma parte del sistema de Compra Profesional.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <img src={tool} alt=""/>
                            </Col>
                        </Row>
                    </div>
                </section>

                <Footer2/>
            </div>
        );
    }
}

export default CompraProfesional;