import {
    INVALIDATE_PAGOS,
    ERROR_PAGOS,
    RECEIVE_PAGOS,
    REQUEST_PAGOS,
    RESET_PAGOS,
    ERROR_PAGO,
    RECEIVE_PAGO,
    REQUEST_PAGO,
    UPDATE_PAGO,
    REQUEST_UPDATE_PAGO,
    SUCCESS_UPDATE_PAGO,
    ERROR_UPDATE_PAGO,
    RESET_UPDATE_PAGO,
    CREATE_PAGO,
    ERROR_CREATE_PAGO,
    REQUEST_CREATE_PAGO,
    RESET_CREATE_PAGO,
    SUCCESS_CREATE_PAGO,
    DELETE_PAGO,
    REQUEST_DELETE_PAGO,
    SUCCESS_DELETE_PAGO,
    ERROR_DELETE_PAGO,
    RESET_DELETE_PAGO,
    REQUEST_COMPROBANTE,
    RECEIVE_COMPROBANTE,
    ERROR_COMPROBANTE,
    RESET_COMPROBANTE,
    REQUEST_LOGOPAGO,
    RECEIVE_LOGOPAGO, ERROR_LOGOPAGO, RESET_LOGOPAGO,

} from '../actions/PagoActions';
import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

import merge from "lodash/merge";
import union from "lodash/union";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        pagos: {},
    }
}

function pagosById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PAGOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PAGOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null
            });
        case ERROR_PAGOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PAGOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                pagos: {}
            });
        case RECEIVE_PAGOS:
            let dato = action.pagos.entities.pagos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                pagos: merge({}, state.pagos, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PAGO:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PAGO:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PAGO:
            let datoPago = action.pago.entities.pagos;
            return Object.assign({}, state, {
                pagos: merge({}, state.pagos, datoPago),
                isFetching: false,
            });
        case SUCCESS_DELETE_PAGO:
            let datoPagoEliminado = action.pago.entities.pagos;
            return Object.assign({}, state, {
                pagos: merge({}, state.pagos, datoPagoEliminado),
            });
        case SUCCESS_CREATE_PAGO:
            let datoPagoCreado = action.pago.entities.pagos;
            return Object.assign({}, state, {
                pagos: merge({}, state.pagos, datoPagoCreado),
            });
        case SUCCESS_UPDATE_PAGO:
            let datoPagoActualizado = action.pago.entities.pagos;
            return Object.assign({}, state, {
                pagos: merge({}, state.pagos, datoPagoActualizado),
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                pagos: {}
            });
        default:
            return state
    }
}


function allPagos(state = [], action) {
    switch (action.type) {
        case RECEIVE_PAGOS:
            return action.pagos.result.pagos;
        case RESET_PAGOS:
            return [];
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalPagos(state = null, action) {
    switch (action.type) {
        case RECEIVE_PAGOS:
            return action.pagos && action.pagos.result.total ? action.pagos.result.total : 0;
        case RESET_PAGOS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    comprobantes: [],
    logo: null
}, action) {
    switch (action.type) {
        case RECEIVE_PAGO:
            let dato = action.pago.entities.pagos;
            let pago = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: pago ? pago : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_PAGO:
            return merge({}, state, {
                activo: action.pago,
                error: ""
            });
        case REQUEST_UPDATE_PAGO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_PAGO:
            let datoU = action.pago.entities.pagos;
            let pagoU = datoU && Object.keys(datoU).length > 0 ? datoU[Object.keys(datoU)[0]] : {};
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: pagoU
            });
        case ERROR_UPDATE_PAGO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_PAGO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        case REQUEST_COMPROBANTE:
            return Object.assign({}, state, {
                isFetchingComprobante: true
            });
        case RECEIVE_COMPROBANTE:
            let file = action.imagen;
            return Object.assign({}, state, {
                isFetchingComprobante: false,
                comprobantes: file ? union(state.comprobantes, [file]) : state.comprobantes,
            });
        case ERROR_COMPROBANTE:
            return Object.assign({}, state, {
                isFetchingComprobante: false,
                error: action.error
            });
        case RESET_COMPROBANTE:
            return Object.assign({}, state, {
                isFetchingComprobante: false,
                error: null,
                comprobantes: []
            });

        case REQUEST_LOGOPAGO:
            return Object.assign({}, state, {
                isFetchingLogo: true
            });
        case RECEIVE_LOGOPAGO:
            let fileLogo = action.imagen;
            return Object.assign({}, state, {
                isFetchingLogo: false,
                logo: fileLogo ? fileLogo : state.logo,
            });
        case ERROR_LOGOPAGO:
            return Object.assign({}, state, {
                isFetchingLogo: false,
                error: action.error
            });
        case RESET_LOGOPAGO:
            return Object.assign({}, state, {
                isFetchingLogo: false,
                error: null,
                logo: null
            });


        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PAGO:
            return merge({}, state, {
                // isCreating: false,
                nuevo: action.pago,
                error: null,
            });
        case REQUEST_CREATE_PAGO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_PAGO:
            let datoC = action.pago.entities.pagos;
            let nuevoPago = datoC && Object.keys(datoC).length > 0 ? datoC[Object.keys(datoC)[0]] : {};
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: nuevoPago,
            });
        case ERROR_CREATE_PAGO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PAGO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_PAGO:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.pago,
                error: null,
            });
        case REQUEST_DELETE_PAGO:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_PAGO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_PAGO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_PAGO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

const pagos = combineReducers({
    byId: pagosById,
    allIds: allPagos,
    update: update,
    create: create,
    totalPagos: totalPagos,
    delete: deleter
});

export default pagos;