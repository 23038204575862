import c from '../constants/constants';


require('es6-promise').polyfill();
require('isomorphic-fetch');


var paises = {

        getAll(filtros) {
            var esc = encodeURIComponent;
            var query = "";
            if (filtros)
                query = Object.keys(filtros)
                    .map(k => esc(k) + '=' + esc(filtros[k]))
                    .join('&');

            let defaultOptions = {
                url: '',
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json;charset=UTF-8"
                },
                // cache: false,
                dataType: 'json',
            };
            return fetch(c.BASE_URL + '/paises/?' + query, defaultOptions);
        },

        getOne(idPais) {
            let defaultOptions = {
                url: '',
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    "Content-Type": "application/json;charset=UTF-8"
                },
                // cache: false,
                dataType: 'json',
            };

            return fetch(c.BASE_URL + '/paises/' + idPais, defaultOptions);
        }
    };

export default paises;