import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import kit from '../../../assets/img/evento/kitAzul.png';
import dahua from '../../../assets/img/evento/dahua.png';
import tv from '../../../assets/img/evento/tv.png';

import '../../../assets/css/RegistroEventoPss.css';
import FieldGroup from "../../camposTexto/FieldGroup";

import {
    changeUsuarioLogueado,
    fetchUsuarioIfNeeded, invalidateUsuario, resetCreateUsuarioLogueado,
    resetUpdateUsuarioLogueado
} from "../../../actions/UsuarioActions";
import FieldGroupSubmit from "../../camposTexto/FieldGroupSubmit";
import {saveCreateRegistro} from "../../../actions/RegistroActions";

import isObject from "lodash/isObject";
import isString from "lodash/isString";


import Loader from '../../elementos/loader/Loader';
import FieldTelefono from "../../camposTexto/FieldTelefono";

class RegistroEventoTarde extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: "",
            turno:"tarde"
        };

        this.submitForm = this.submitForm.bind(this);
        this.submitDatos = this.submitDatos.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submitForm(e) {
        e.preventDefault();
        this.props.invalidateUsuario();
        this.props.resetCreateUsuarioLogueado();
        this.props.resetUpdateUsuarioLogueado();
        this.props.fetchUsuarioIfNeeded(this.state.dni);
        this.refs.datos.classList.add("in");
    }

    submitDatos(e) {
        e.preventDefault();
        if (!this.props.usuarios.byId.usuarioLogueado.dni) {
            this.props.changeUsuarioLogueado({dni: this.state.dni});
        }
        var cambio = {};
        cambio["turno"] = this.state.turno;
        this.props.changeUsuarioLogueado(cambio);
        this.props.saveCreateRegistro();
    }

    changeDni(e) {
        this.setState({dni: e.target.value});
        console.log(this.state);
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio["turno"] = this.state.turno;
        cambio[e.target.id] = e.target.value;
        this.props.changeUsuarioLogueado(cambio);
    }


    render() {
        const usuario = this.props.usuarios.byId.usuarioLogueado;
        console.log(usuario);

        const error = this.props.registros.create.error;

        var msgError = null;

        if (error && isObject(error.message) && !isString(error.message)) {
            msgError = Object.keys(error.message).map(function (key, index) {
                return (<li>{error.message[key]}</li>);
            });
        } else {
            msgError = <p>{error ? error.message : ""}</p>;
        }

        return (
            <div className="evento-pss">
                <div className="container-limit">
                    <p className="copete">REGISTRO</p>
                    <div className="header">
                        <div className="hash-container">
                            <h1>#tpekit</h1>
                            <h2>Tu próximo entrenamiento</h2>
                        </div>

                        <Link to="/"><img src={kit} className="pull-right kitAzul" alt=""/></Link>
                    </div>
                    <div className="banner">
                        <img className="dahua-logo" src={dahua} alt=""/>
                        <Row>
                            <Col md={8} sm={8} xs={8}>
                                <p>Smart PSS Dahua</p>
                            </Col>
                            <Col md={4} sm={4} xs={4}>
                                <img className="img-responsive" src={tv} alt=""/>
                            </Col>
                        </Row>
                        <h4>12 de abril.</h4>
                        <h5>Espacio de Co-Working de Kit Experto. José Hernández 767. Rosario.</h5>
                    </div>
                    <div className="dashed"></div>
                    <Row className="seccion-formulario">
                        <Col md={12}>
                            <form ref="asd" onSubmit={(e) => {
                                this.submitForm(e)
                            }}>
                                <FieldGroupSubmit
                                    id="dni"
                                    type="number"
                                    label="Ingresar DNI"
                                    placeholder=""
                                    required={true}
                                    help={this.props.usuarios.byId.error ? this.props.usuarios.byId.error
                                        : usuario.dni ? "Ya estás en nuestro registro de datos " + usuario.nombre + " " + usuario.apellido :
                                            ""
                                    }
                                    value={this.state.dni}
                                    onChange={(e) => {
                                        this.changeDni(e)
                                    }}
                                />
                            </form>
                            <form onSubmit={e => {
                                this.submitDatos(e)
                            }}>
                                <div className="datos collapse" ref="datos">
                                    <FieldGroup
                                        id="nombre"
                                        type="text"
                                        label="Nombre"
                                        placeholder=""
                                        required={true}
                                        value={usuario.nombre ? usuario.nombre : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />

                                    <FieldGroup
                                        id="apellido"
                                        type="text"
                                        label="Apellido"
                                        placeholder=""
                                        required={true}
                                        value={usuario.apellido ? usuario.apellido : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />

                                    <FieldGroup
                                        id="empresa"
                                        type="text"
                                        label="Empresa o Institución"
                                        placeholder=""
                                        required={true}
                                        value={usuario.empresa ? usuario.empresa : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />

                                    <FieldGroup
                                        id="cargo"
                                        type="text"
                                        label="Cargo o Función"
                                        placeholder=""
                                        required={true}
                                        value={usuario.cargo ? usuario.cargo : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />
                                    <FieldTelefono
                                        id="telefono"
                                        type="text"
                                        label="Teléfono"
                                        required={true}
                                        placeholder="(Cod. Area sin 0 - Num. Tel. sin 15 ej: 341 511111)"
                                        disabled={false}
                                        value={usuario.telefono ? (usuario.telefono == null ? "" : usuario.telefono) : ""}
                                        options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />

                                    <FieldGroup
                                        id="email"
                                        type="email"
                                        label="E-mail"
                                        require={true}
                                        placeholder=""
                                        value={usuario.email ? usuario.email : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />
                                    <FieldGroup id="turno"
                                                label="Horario"
                                                componentClass="select"
                                                required={true}
                                                placeholder="Seleccione una empresa"
                                                value={this.state.turno ? this.state.turno : ""}
                                                onChange={(e) => {
                                                    this.onChangeUsuario(e)
                                                }}
                                                disabled>
                                        <option value="">Seleccione el horario</option>
                                        <option value="maniana">Mañana (10:00 a 13:00)</option>
                                        <option value="tarde" selected>Tarde (14:00 a 17:00)</option>
                                    </FieldGroup>
                                    {
                                        this.props.registros.create.isCreating ?
                                            <Loader/>
                                            :
                                            <Button type="submit" className="center-block boton">Enviar</Button>
                                    }

                                    <p className="registro-error">{msgError ? msgError : ""}</p>

                                    <p className="registro-error">{this.props.registros.create.message ? this.props.registros.create.message : ""}</p>
                                </div>
                                <br/>

                            </form>
                        </Col>
                    </Row>
                    <div className="fake-footer">
                        <Link to={"/"}>kitexperto.com</Link>
                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        registros: state.registros
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        //TODO CAMBIO USUARIO REDUCER ARREGLAR PARA PROXIMO EVENTO
        fetchUsuarioIfNeeded: (dni) => {
            dispatch(fetchUsuarioIfNeeded(dni));
        },
        resetCreateUsuarioLogueado: () => {
            dispatch(resetCreateUsuarioLogueado())
        },
        resetUpdateUsuarioLogueado: () => {
            dispatch(resetUpdateUsuarioLogueado())
        },
        invalidateUsuario: () => {
            dispatch(invalidateUsuario());
        },
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario));
        },
        saveCreateRegistro: () => {
            dispatch(saveCreateRegistro());
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistroEventoTarde));
