import React from "react";
import './Loader.css';


export default class loader extends React.Component {

    render() {
        return (
            <div className="half-circle-spinner center-block">
                <div className="circle circle-1"></div>
                <div className="circle circle-2"></div>
            </div>
        )
    }
}
