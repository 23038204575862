import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var usuario = {

    saveCreate(usuario) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(usuario)
        };

        return fetch(c.BASE_URL+'/registro', defaultOptions);
    },

    saveUpdate(dni) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+'/registro/' + dni, defaultOptions);
    },

};

export default usuario;