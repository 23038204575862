import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){


    const producto = new schema.Entity('producto',{}, {idAttribute: "id"});
    const preciosProducto = new schema.Entity('preciosProducto',{producto:producto}, {idAttribute:"idProducto"});
    const mySchema = [ preciosProducto ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;