import {combineReducers} from 'redux';
import merge from "lodash/merge";

//Actions
import {
    ERROR_CREATE_LANDING,
    CREATE_LANDING,
    RESET_CREATE_LANDING,
    RESET_UPDATE_LANDING,
    UPDATE_LANDING,
    REQUEST_CREATE_LANDING,
    SUCCESS_CREATE_LANDING,
    REQUEST_UPDATE_LANDING,
    SUCCESS_UPDATE_LANDING,
    ERROR_UPDATE_LANDING,
    INVALIDATE_LOGO_LANDING,
    REQUEST_LOGO_LANDING,
    RECEIVE_LOGO_LANDING,
    ERROR_LOGO_LANDING,
    UPDATE_LOGO_LANDING

} from "../actions/LandingActions";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import {RECEIVE_USUARIO_LOGUEADO} from "../actions/UsuarioActions";

function landingById(state = {
    isFetching: false,
    didInvalidate: true,
    landing: {},
}, action) {
    switch (action.type) {
        case RECEIVE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                landing: action.usuario.entities.landing ? merge({}, state.landing, action.usuario.entities.landing[Object.keys(action.usuario.entities.landing)]) : {},
                lastUpdated: action.receivedAt,
            });
        case CREATE_LANDING:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                landing: merge({}, state.landing, action.landing)
            });
        case UPDATE_LANDING:
            return Object.assign({}, state, {
                isFetching: false,
                landing: merge({}, state.landing, action.landing)
            });
        case RESET_CREATE_LANDING:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                landing: {},
            });
        case RESET_UPDATE_LANDING:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                landing: {},
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                landing: {},
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    error: null,
}, action) {
    switch (action.type) {
        case REQUEST_CREATE_LANDING:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_LANDING:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case ERROR_CREATE_LANDING:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_LANDING:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: "",
                nuevo: {}
            });
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    error: ""
}, action) {
    switch (action.type) {
        case RESET_UPDATE_LANDING:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: null,
            });
        case REQUEST_UPDATE_LANDING:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_LANDING:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null
            });
        case ERROR_UPDATE_LANDING:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function landingAllIds(state = [], action) {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function logo(state = {
    isFetching: false,
    didInvalidate: true,
    logo: null,
    error: null,
}, action) {
    switch (action.type) {
        case INVALIDATE_LOGO_LANDING:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_LOGO_LANDING:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_LOGO_LANDING:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                logo: action.logo,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case UPDATE_LOGO_LANDING:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                logo: action.logo,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_LOGO_LANDING:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                logo: null,
                error: null,
            });
        default:
            return state
    }
}


const landing = combineReducers({
    allIds: landingAllIds,
    byId: landingById,
    create: create,
    update: update,
    logo: logo
});

export default landing;