import {
    INVALIDATE_PAISES,
    ERROR_PAISES,
    RECEIVE_PAISES,
    REQUEST_PAISES,
    RESET_PAISES,
    ERROR_PAIS,
    RECEIVE_PAIS,
    REQUEST_PAIS,

} from '../actions/PaisActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        paises: {},
    }
}

function paisesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PAISES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PAISES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PAISES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PAISES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                paises: {}
            });
        case RECEIVE_PAISES:
            let dato = action.paises.entities.paises;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                paises: merge({}, state.paises, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PAIS:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PAIS:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PAIS:
            let datoPais = action.pais.entities.paises;
            return Object.assign({}, state, {
                paises: merge({}, state.paises, datoPais),
                isFetching: false,
            });
        default:
            return state
    }
}


function allPaises(state = [], action) {
    switch (action.type) {
        case RECEIVE_PAISES:
            return action.paises.result;
        case RESET_PAISES:
            return [];
        default:
            return state
    }
}

const paises = combineReducers({
    byId: paisesById,
    allIds: allPaises
});

export default paises;