import { combineReducers } from 'redux';

//Actions
import {
    UPDATE_CLIENTE,
    REQUEST_UPDATE_CLIENTE,
    SUCCESS_UPDATE_CLIENTE,
    ERROR_UPDATE_CLIENTE,
    RESET_UPDATE_CLIENTE,
} from '../actions/ClienteActions';
import { RECEIVE_USUARIO, RECEIVE_USUARIO_LOGUEADO, RECEIVE_USUARIOS } from "../actions/UsuarioActions";
import { RECEIVE_PEDIDOS, RESET_PEDIDOS } from "../actions/PedidoActions";
import {
    REQUEST_CLIENTE,
    INVALIDATE_CLIENTE,
    RESET_CLIENTE,
    RECEIVE_CLIENTE,
    ERROR_CLIENTE,
    CREATE_CLIENTE,
    ERROR_CREATE_CLIENTE,
    REQUEST_CREATE_CLIENTE,
    RESET_CREATE_CLIENTE,
    SUCCESS_CREATE_CLIENTE
} from "../actions/ClienteActions";
import { LOGOUT_SUCCESS } from "../actions/AuthenticationActions";

//Librerias
import merge from "lodash/merge";
import union from "lodash/union";
import { RECEIVE_PAGO, RECEIVE_PAGOS } from "../actions/PagoActions";
import { RECEIVE_LINKPAGO, RECEIVE_LINKSPAGOS } from "../actions/LinkPagoActions";


function clientesById(state = {
    isFetching: false,
    didInvalidate: true,
    clientes: null
}, action) {
    switch (action.type) {
        case RECEIVE_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                cliente: action.usuario.entities.clientes,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case RECEIVE_USUARIOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                clientes: action.usuarios && action.usuarios.entities ? merge({}, state.clientes, action.usuarios.entities.clientes) : {},
                lastUpdated: action.receivedAt,
                error: null,
            });
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                clientes: merge({}, state.clientes, action.pedidos.entities.clientes),
                lastUpdated: action.receivedAt,
                error: null,
            });
        //PAGOS
        case RECEIVE_PAGOS:
            return Object.assign({}, state, {
                clientes: merge({}, state.clientes, action.pagos.entities && action.pagos.entities.clientes ? action.pagos.entities.clientes : {}),
                error: null
            });
        case RECEIVE_PAGO:
            return Object.assign({}, state, {
                clientes: merge({}, state.clientes, action.pago.entities && action.pago.entities.clientes ? action.pago.entities.clientes : {}),
                error: null
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                clientes: [],
                error: null
            });
        //FETCH CLIENTE
        case INVALIDATE_CLIENTE:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case RESET_CLIENTE:
            return Object.assign({}, state, {
                isFetching: false,
                clientes: [],
                error: null,
            });
        case REQUEST_CLIENTE:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_CLIENTE:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                clientes: merge({}, state.clientes, action.cliente),
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_CLIENTE:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error,
            });
        case UPDATE_CLIENTE:
            return Object.assign({}, state, {
                error: null,
            });
        case CREATE_CLIENTE:
            return Object.assign({}, state, {
                error: null,
            });
        case RECEIVE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                clientes: merge({}, state.clientes, action.usuario.entities.clientes),
                lastUpdated: action.receivedAt,
            });
        //LINKS PAGOS
        case RECEIVE_LINKSPAGOS:
            return Object.assign({}, state, {
                clientes: merge({}, state.clientes, action.linksPagos.entities && action.linksPagos.entities.clientes ? action.linksPagos.entities.clientes : {}),
                error: null
            });
        case RECEIVE_LINKPAGO:
            return Object.assign({}, state, {
                clientes: merge({}, state.clientes, action.linkPago.entities && action.linkPago.entities.clientes ? action.linkPago.entities.clientes : {}),
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                cliente: {},
                error: null,
            });
        default:
            return state
    }
}


function update(state = {
    isUpdating: false,
    activo: {},
    error: ""
}, action) {
    switch (action.type) {
        case UPDATE_CLIENTE:
            return merge({}, state, {
                activo: action.cliente,
                error: ""
            });
        case REQUEST_UPDATE_CLIENTE:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_CLIENTE:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt
            });
        case ERROR_UPDATE_CLIENTE:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_CLIENTE:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}


function create(state = {
    isCreating: false,
    nuevo: {},
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_CLIENTE:
            return merge({}, state, {
                isCreating: false,
                nuevo: action.cliente,
                error: null,
            });
        case REQUEST_CREATE_CLIENTE:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CLIENTE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case ERROR_CREATE_CLIENTE:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CLIENTE:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function allIds(state = [], action) {
    switch (action.type) {
        case RECEIVE_USUARIO_LOGUEADO:
            return action.usuario.entities.clientes ? union(state, Object.keys(action.usuario.entities.clientes)) : state;
        default:
            return state
    }

}


const cliente = combineReducers({
    byId: clientesById,
    update: update,
    create: create,
    allIds: allIds,
});

export default cliente;