import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){

    const provincia = new schema.Entity('provincias',{}, {idAttribute:"id"});
    const mySchema = [ provincia ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;