import c from "../constants/constants";
// require('es6-promise').polyfill();

import $ from "jquery";
require('isomorphic-fetch');

var landing = {


    saveCreate(landing, logo) {

        var formData = new FormData();
        formData.append("landing", JSON.stringify(landing));
        formData.append("logo", logo);

        return $.ajax({
            url: c.BASE_URL + '/landing/',
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                xhr.setRequestHeader('authorization', "Bearer "+localStorage.token);
            },
            contentType: false,
            type: 'POST',
            // cache: false,
            processData: false,
            enctype: 'multipart/form-data',
        });
    },

    saveUpdate(landing, logo) {

        var formData = new FormData();
        formData.append("landing", JSON.stringify(landing));
        formData.append("logo", logo);

        return $.ajax({
            url: c.BASE_URL + '/landing/' + landing.id,
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                xhr.setRequestHeader('authorization', "Bearer "+localStorage.token);
            },
            contentType: false,
            type: 'POST',
            // cache: false,
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    getLogoLanding(idLanding) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            // dataType: 'image/png',
        };
        return fetch(c.BASE_URL+'/logoLanding/' + idLanding, defaultOptions);
    },
};

export default landing;