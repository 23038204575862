//api
import clientes2Api from "../api/clientes2Api"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "./AuthenticationActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeClientes2";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../api/authentication";

//CLIENTES2
export const REQUEST_CLIENTES2 = 'REQUEST_CLIENTES2';
export const RECEIVE_CLIENTES2 = 'RECEIVE_CLIENTES2';
export const INVALIDATE_CLIENTES2 = 'INVALIDATE_CLIENTES2';
export const ERROR_CLIENTES2 = "ERROR_CLIENTES2";
export const RESET_CLIENTES2 = "RESET_CLIENTES2";


export function invalidateClientes2() {
    return {
        type: INVALIDATE_CLIENTES2
    }
}

function requestClientes2() {
    return {
        type: REQUEST_CLIENTES2,
    }
}

function receiveClientes2(json) {
    return {
        type: RECEIVE_CLIENTES2,
        clientes2: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorClientes2(error) {
    return {
        type: ERROR_CLIENTES2,
        error: error,
    }
}

export function resetClientes2() {
    return {
        type: RESET_CLIENTES2
    }
}

export function fetchClientes2(filtros) {
    return dispatch => {
        dispatch(requestClientes2());
        return clientes2Api.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveClientes2(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorClientes2(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorClientes2(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchClientes2(state) {
    const clientes2 = state.clientes2.byId;
    if (!clientes2) {
        return true
    } else if (clientes2.isFetching) {
        return false
    } else {
        return clientes2.didInvalidate;
    }
}

export function fetchClientes2IfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchClientes2(getState())) {
            return dispatch(fetchClientes2(filtros))
        }
    }
}


//MODEL
export const REQUEST_CLIENTE2 = 'REQUEST_CLIENTE2';
export const RECEIVE_CLIENTE2 = 'RECEIVE_CLIENTE2';
export const INVALIDATE_CLIENTE2 = 'INVALIDATE_CLIENTE2';
export const ERROR_CLIENTE2 = "ERROR_CLIENTE2";
export const RESET_CLIENTE2 = "RESET_CLIENTE2";


export function invalidateCliente2() {
    return {
        type: INVALIDATE_CLIENTE2
    }
}

function requestCliente2() {
    return {
        type: REQUEST_CLIENTE2,
    }
}

export function receiveCliente2(json) {
    return {
        type: RECEIVE_CLIENTE2,
        cliente2: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorCliente2(error) {
    return {
        type: ERROR_CLIENTE2,
        error: error,
    }
}

export function fetchCliente2(idCliente2) {
    return dispatch => {
        dispatch(requestCliente2());
        return clientes2Api.getOne(idCliente2)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCliente2(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCliente2(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCliente2(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_CLIENTE2 = 'UPDATE_CLIENTE2';
export const REQUEST_UPDATE_CLIENTE2 = "REQUEST_UPDATE_CLIENTE2";
export const SUCCESS_UPDATE_CLIENTE2 = "SUCCESS_UPDATE_CLIENTE2";
export const ERROR_UPDATE_CLIENTE2 = "ERROR_UPDATE_CLIENTE2";
export const RESET_UPDATE_CLIENTE2 = "RESET_UPDATE_CLIENTE2";
export const DELETE_UPDATE_CLIENTE2 = "DELETE_UPDATE_CLIENTE2";

function requestUpdateCliente2() {
    return {
        type: REQUEST_UPDATE_CLIENTE2,
    }
}

function receiveUpdateCliente2(cliente2) {
    return {
        type: SUCCESS_UPDATE_CLIENTE2,
        receivedAt: Date.now(),
        cliente2: normalizeDato(cliente2)
    }
}

function errorUpdateCliente2(error) {
    return {
        type: ERROR_UPDATE_CLIENTE2,
        error: error,
    }
}

export function resetUpdateCliente2() {
    return {
        type: RESET_UPDATE_CLIENTE2,
    }
}

export function updateCliente2(cliente2) {
    return {
        type: UPDATE_CLIENTE2,
        cliente2
    }
}

export function saveUpdateCliente2(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateCliente2());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update ? getState()[key].update.activo : {}, getState()[key].create ? getState()[key].create.nuevo : {});
            });

        let cliente2 = denormalizeDato(getState().clientes2.update.activo, store);

        return clientes2Api.saveUpdate(cliente2, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    // authUtil.addToken(response);
                    dispatch(receiveUpdateCliente2(data));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateCliente2(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdateClientes2(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdateClientes2(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorUpdateCliente2(JSON.parse(error.message)));
                                if (error.data && error.data.length > 0)
                                    dispatch(receiveUpdateCliente2(error.data));
                            }).catch(() => {
                                dispatch(errorUpdateCliente2(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

export function deleteUpdateCliente2(cliente2) {
    return {
        type: DELETE_UPDATE_CLIENTE2,
        cliente2
    }
}

//UPDATE CLIENTES2
export const REQUEST_UPDATE_CLIENTES2 = "REQUEST_UPDATE_CLIENTES2";
export const SUCCESS_UPDATE_CLIENTES2 = "SUCCESS_UPDATE_CLIENTES2";
export const ERROR_UPDATE_CLIENTES2 = "ERROR_UPDATE_CLIENTES2";
export const RESET_UPDATE_CLIENTES2 = "RESET_UPDATE_CLIENTES2";

function requestUpdateClientes2() {
    return {
        type: REQUEST_UPDATE_CLIENTES2,
    }
}

function receiveUpdateClientes2(clientes2) {
    return {
        type: SUCCESS_UPDATE_CLIENTES2,
        receivedAt: Date.now(),
        clientes2: normalizeDatos(clientes2)
    }
}

function errorUpdateClientes2(error) {
    return {
        type: ERROR_UPDATE_CLIENTES2,
        error: error,
    }
}

export function resetUpdateClientes2() {
    return {
        type: RESET_UPDATE_CLIENTES2,
    }
}

export function saveUpdateClientes2() {
    return (dispatch, getState) => {
        dispatch(requestUpdateClientes2());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let clientes2 = getState().clientes2.update.activos.map((idCliente2) => {
            return denormalizeDato(getState().clientes2.update.activo[idCliente2], store);
        });

        return clientes2Api.saveUpdateClientes2(clientes2)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateClientes2(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateClientes2(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdateClientes2(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdateClientes2(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorUpdateClientes2(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorUpdateClientes2(errorMessages.GENERAL_ERROR));
                            });


                        return;
                }
            });
    }
}

//ALTA CLIENTE2
export const CREATE_CLIENTE2 = 'CREATE_CLIENTE2';
export const REQUEST_CREATE_CLIENTE2 = "REQUEST_CREATE_CLIENTE2";
export const SUCCESS_CREATE_CLIENTE2 = "SUCCESS_CREATE_CLIENTE2";
export const ERROR_CREATE_CLIENTE2 = "ERROR_CREATE_CLIENTE2";
export const RESET_CREATE_CLIENTE2 = "RESET_CREATE_CLIENTE2";
export const DELETE_CREATE_CLIENTE2 = "DELETE_CREATE_CLIENTE2";


//ALTA CLIENTE2
function requestCreateCliente2() {
    return {
        type: REQUEST_CREATE_CLIENTE2,
    }
}

function receiveCreateCliente2(cliente2) {
    return {
        type: SUCCESS_CREATE_CLIENTE2,
        receivedAt: Date.now(),
        cliente2: normalizeDato(cliente2)
    }
}

export function errorCreateCliente2(error) {
    return {
        type: ERROR_CREATE_CLIENTE2,
        error: error,
    }
}

export function resetCreateCliente2() {
    return {
        type: RESET_CREATE_CLIENTE2
    }
}

export function createCliente2(cliente2) {
    return {
        type: CREATE_CLIENTE2,
        cliente2
    }
}

export function deleteCreateCliente2(cliente2) {
    return {
        type: DELETE_CREATE_CLIENTE2,
        cliente2
    }
}

export function saveCreateCliente2(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateCliente2());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let cliente2 = denormalizeDato(getState().clientes2.create.nuevo, store);

        return clientes2Api.saveCreate(cliente2, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    dispatch(receiveCreateCliente2(data));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateCliente2(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorCreateClientes2(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreateClientes2(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorCreateCliente2(JSON.parse(error.message)));
                                if (error.data)
                                    dispatch(receiveCreateCliente2(error.data));
                            }).catch(() => {
                                dispatch(errorCreateCliente2(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//CREATE CLIENTES2
export const REQUEST_CREATE_CLIENTES2 = "REQUEST_CREATE_CLIENTES2";
export const SUCCESS_CREATE_CLIENTES2 = "SUCCESS_CREATE_CLIENTES2";
export const ERROR_CREATE_CLIENTES2 = "ERROR_CREATE_CLIENTES2";
export const RESET_CREATE_CLIENTES2 = "RESET_CREATE_CLIENTES2";

function requestCreateClientes2() {
    return {
        type: REQUEST_CREATE_CLIENTES2,
    }
}

function receiveCreateClientes2(clientes2) {
    return {
        type: SUCCESS_CREATE_CLIENTES2,
        receivedAt: Date.now(),
        clientes2: normalizeDatos(clientes2)
    }
}

function errorCreateClientes2(error) {
    return {
        type: ERROR_CREATE_CLIENTES2,
        error: error,
    }
}

export function resetCreateClientes2() {
    return {
        type: RESET_CREATE_CLIENTES2,
    }
}

export function saveCreateClientes2() {
    return (dispatch, getState) => {
        dispatch(requestCreateClientes2());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let clientes2 = getState().clientes2.create.nuevos.map((idCliente2) => {
            return denormalizeDato(getState().clientes2.create.nuevo[idCliente2], store);
        });

        return clientes2Api.saveCreateClientes2(clientes2)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateClientes2(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateClientes2(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorCreateClientes2(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreateClientes2(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorCreateClientes2(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorCreateClientes2(errorMessages.GENERAL_ERROR));
                            });


                        return;
                }
            });
    }
}

//DELETE CLIENTE2
export const DELETE_CLIENTE2 = 'DELETE_CLIENTE2';
export const REQUEST_DELETE_CLIENTE2 = "REQUEST_DELETE_CLIENTE2";
export const SUCCESS_DELETE_CLIENTE2 = "SUCCESS_DELETE_CLIENTE2";
export const ERROR_DELETE_CLIENTE2 = "ERROR_DELETE_CLIENTE2";
export const RESET_DELETE_CLIENTE2 = "RESET_DELETE_CLIENTE2";

function requestDeleteCliente2() {
    return {
        type: REQUEST_DELETE_CLIENTE2,
    }
}

function receiveDeleteCliente2(cliente2) {
    return {
        type: SUCCESS_DELETE_CLIENTE2,
        receivedAt: Date.now(),
        cliente2: normalizeDato(cliente2)
    }
}

function errorDeleteCliente2(error) {
    return {
        type: ERROR_DELETE_CLIENTE2,
        error: error,
    }
}

export function resetDeleteCliente2(error) {
    return {
        type: RESET_DELETE_CLIENTE2,
        error: error,
    }
}

export function deleteCliente2(cliente2) {
    return {
        type: DELETE_CLIENTE2,
        cliente2
    }
}

export function saveDeleteCliente2(cliente2) {
    return dispatch => {
        dispatch(requestDeleteCliente2());
        return clientes2Api.saveDelete(cliente2)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteCliente2());
                dispatch(receiveDeleteCliente2(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteCliente2(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorDeleteCliente2(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorDeleteCliente2(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorDeleteCliente2(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorDeleteCliente2(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//PRINT CLIENTE2
export const REQUEST_PRINT_CLIENTE2 = "REQUEST_PRINT_CLIENTE2";
export const SUCCESS_PRINT_CLIENTE2 = "SUCCESS_PRINT_CLIENTE2";
export const ERROR_PRINT_CLIENTE2 = "ERROR_PRINT_CLIENTE2";
export const RESET_PRINT_CLIENTE2 = "RESET_PRINT_CLIENTE2";

function requestPrintCliente2() {
    return {
        type: REQUEST_PRINT_CLIENTE2,
    }
}

function receivePrintCliente2(turnos) {
    return {
        type: SUCCESS_PRINT_CLIENTE2,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintCliente2(error) {
    return {
        type: ERROR_PRINT_CLIENTE2,
        error: error,
    }
}

export function resetPrintCliente2() {
    return {
        type: RESET_PRINT_CLIENTE2,
    }
}

export function printCliente2(idCliente2) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintCliente2());
        return clientes2Api.printCliente2(idCliente2)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintCliente2(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintCliente2(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintCliente2(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintCliente2(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintCliente2(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}