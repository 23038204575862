/* eslint-disable no-undef */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import $ from "jquery"

//Actions
import {changeLanding} from "../../../actions/LandingActions";

const _ = require("lodash");
const {compose, withProps, lifecycle} = require("recompose");
const {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} = require("react-google-maps");

const {StandaloneSearchBox} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

const MapWithASearchBox = compose(
    withProps({
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCkvwh0uiAf4V7eyViwaUp7ynGmVOJztdQ',
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div className="containerLugarEntrega"/>,
        mapElement: <div style={{height: "300px", zIndex: "99",}}/>,
    }),
    lifecycle({
        componentWillMount() {
            this.guardado = false;
            this.guardadoHabilitado = false;
            const refs = {};
            this.setState({
                bounds: null,
                center: {
                    lat: -32.907258, lng: -60.6819261
                },
                markers: [],
                onMapMounted: ref => {
                    refs.map = ref;
                },
                onInputMounted: ref => {
                    refs.input = ref;
                },
                onBoundsChanged: () => {
                    var defaultBounds = {
                        north: -61.02013,
                        south: -32.80805,
                        west: -60.37468,
                        east: -33.09614
                    };
                    this.setState({
                        bounds: refs.map.getBounds(),
                        center: refs.map.getCenter(),
                    })
                },
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },
                triggerSearch: (e) => {
                    console.log("hola");
                    e.preventDefault()
                    e.stopPropagation()

                    google.maps.event.trigger(refs.input, 'keydown', { keyCode: 13 })
                },
                onPlacesChanged: () => {
                    const places = refs.searchBox.getPlaces();

                    let eventoHab = {};
                    console.log(places);
                    if (!places || places.length == 0) {
                        eventoHab["direccionHabilitada"] = false;
                        this.props.changeLanding(eventoHab);
                    } else {
                        eventoHab["direccionHabilitada"] = true;
                        this.props.changeLanding(eventoHab);

                        let evento = {};
                        if (places[0]) {
                            evento["direccionEmpresa"] = places[0].formatted_address;
                            console.log(places[0]);
                            this.props.changeLanding(evento);
                        }


                        const bounds = new google.maps.LatLngBounds();

                        console.log(places);
                        places.forEach(place => {
                            console.log(place.geometry.location.lat, place.geometry.location.lng);
                            if (place.geometry.viewport) {
                                bounds.union(place.geometry.viewport)
                            } else {
                                bounds.extend(place.geometry.location)
                            }
                        });
                        const nextMarkers = places.map(place => ({
                            position: place.geometry.location,
                        }));
                        const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

                        // this.props.handleChangeDireccionEmpresa(evento);
                        console.log(nextCenter, nextMarkers)

                        this.setState({
                            center: nextCenter,
                            markers: nextMarkers
                        });

                        refs.map.fitBounds(bounds);
                    }

                },
            })
        },
        componentWillReceiveProps(nextProps) {

            try {
                console.log(nextProps.direccionEmpresa, this.props.direccionEmpresa)
                if (this.guardado == false && this.props.direccionEmpresa != nextProps.direccionEmpresa) {
                    let direccionEmpresa = nextProps.direccionEmpresa;
                    console.log("entro");
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({'address': direccionEmpresa}, (result, status) => {
                        if (status === 'OK') {
                            let marker = {position: result[0].geometry.location};
                            let center = _.get([marker], '0.position', this.state.center);
                            this.setState({markers: [marker], center: center});
                            this.guardado = true;
                            console.log("entro bien");
                            //Indico que la dirección está bien
                            // let evento = {};
                            // evento.target = {};
                            // evento.target.id = "direccionHabilitada";
                            // evento.target.value = true;
                            // this.props.handleChangeDireccionEmpresa(evento);
                        }
                    });
                }

            } catch (e) {
                console.log("error Google", e);
            }

        },
    }),
    withScriptjs,
    withGoogleMap
)((props, refs) =>
    <div>
        <GoogleMap
            ref={props.onMapMounted}
            defaultZoom={15}
            center={props.center}
            onBoundsChanged={props.onBoundsChanged}
            defaultOptions={{
                mapTypeControl: false,
                disableDefaultUI: true,
                scrollwheel: false,
                draggable: false,
                draggableCursor: "unset"
            }}
        >
            {props.markers.map((marker, index) =>
                <Marker key={index} position={marker.position}/>
            )}
        </GoogleMap>
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
            defaultOptions={{strictBounds: true, types: ['establishment']}}
        >
            <form onSubmit={(e) => {
                e.preventDefault();
            }} autoComplete="false">
                <input ref={props.onInputMounted} id="direccionEmpresa" type="text"
                       required={true}
                       className="inputLanding"
                       value={props.direccionEmpresa ? props.direccionEmpresa : ""}
                       onChange={props.handleChangeDireccionEmpresa.bind(this)}
                       placeholder="Calle número, ciudad, provincia"
                       //onBlur={props.triggerSearch}
                />
            </form>
        </StandaloneSearchBox>


    </div>
);


class MapaSearch extends Component {

    handleChangeDireccionEmpresa(e) {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.changeLanding(cambio);
        //Indico que la dirección es inválida hasta que seleccione
        if (e.target.id !== "direccionHabilitada") {
            cambio = {};
            cambio["direccionHabilitada"] = false;
            this.props.changeLanding(cambio);
        }

    }

    render() {
        return (
            <section className="lugarEntrega">
                <MapWithASearchBox handleChangeDireccionEmpresa={this.handleChangeDireccionEmpresa.bind(this)} changeLanding={this.props.changeLanding}
                                   direccionEmpresa={this.props.landing.byId.landing.direccionEmpresa}
                />
            </section>

        );
    }
}

function mapStateToProps(state) {
    return {
        landing: state.landing,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanding: (direccionEmpresa) => {
            dispatch(changeLanding(direccionEmpresa))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapaSearch));

