import {
    RECEIVE_CATEGORIAS
} from '../actions/CategoriaActions';
import {combineReducers} from 'redux';
import {normalizeDatos} from "../normalizers/normalizeCategorias";

function subCategoriasById(state = {
    isFetching: false,
    didInvalidate: true,
    subCategorias: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                subCategorias: normalizeDatos(action.categorias).entities.subCategorias,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allSubCategorias(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const subCategorias = combineReducers({
    byId: subCategoriasById,
    allIds: allSubCategorias
});

export default subCategorias;