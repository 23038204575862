import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom'
import history from "../../../history";
import clone from "lodash/clone"
import {OverlayTrigger, Tooltip, Button} from "react-bootstrap";

//Images
import help from '../../../assets/img/iconLista/help2.png';
import spinner from '../../../assets/img/spinner.gif';
import emptyLogo from '../../../assets/img/iconLista/empty-logo.png';

//Actions
import {logout} from "../../../actions/AuthenticationActions";
import {resetUpdateCliente, saveUpdateClienteLogo, updateCliente, updateLogo} from "../../../actions/ClienteActions";

//CSS
import "../../../assets/css/DatosUsuario.css";
import {saveUpdateCliente2, updateCliente2} from "../../../actions/Cliente2Actions";

class DatosUsuario extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            file: null
        };

        //Seteo valores iniciales
        this.verLogo = this.verLogo.bind(this);
        this.submitLogo = this.submitLogo.bind(this);
        this.cerrarError = this.cerrarError.bind(this);
        this.handleChangeColor = this.handleChangeColor.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.addEventListener('mousedown', this.cerrarError);
        this.cargarLogoCliente();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.cerrarError);
    }

    componentDidUpdate() {
        this.cargarLogoCliente();
    }

    cargarLogoCliente() {
        var preview = this.refs.imagen;
        if (this.props.usuarios.logo.logo) {
            var file = this.props.usuarios.logo.logo;
            var fileReader = new FileReader();
            fileReader.onload = function () {
                preview.src = this.result;
            };
            fileReader.readAsDataURL(file);
        }

    }

    submitLogo(e) {
        e.preventDefault();
        // if (this.props.usuarios.logo.logo)
        //     this.props.saveUpdateCliente();
        this.props.saveUpdateCliente2([this.state.file]);

    }

    handleChangeColor(e) {
        let cliente = this.props.clientes2.update.activo;
        let cambio = {};
        if (cliente && !cliente.id) {
            cambio = clone(this.props.usuarios.byId.clienteUsuarioLogueado);
        }
        cambio[e.target.id] = e.target.value;
        this.props.updateCliente2(cambio);
    }

    verLogo() {
        var preview = this.refs.imagen;


        var file = this.refs.file.files[0];
        let filename = "logo";
        if (file.name)
            filename = file.name.replace(/[^a-zA-Z0-9]/g, '_');
        Object.defineProperty(file, 'name', {
            writable: true,
            value: filename
        })

        var reader = new FileReader();

        reader.onloadend = function () {
            preview.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = "";
        }

        this.props.updateLogo(file);
        this.setState({file: file})

        let e = {target: {value: filename, id: "fileName"}};
        this.handleChangeColor(e);
    }


    cerrarError() {
        // let error = this.refs.error;
        // error.style.visibility = "hidden";
        if (this.props.clientes.update.error) {
            setTimeout(() => {
                this.props.resetUpdateCliente();
            }, 500)
        }
    }

    render() {
        let cliente = this.props.clientes2.update.activo.id ? this.props.clientes2.update.activo : this.props.usuarios.byId.clienteUsuarioLogueado;

        const tooltip = (
            <Tooltip id="tooltipCuentas">
                <p>Estamos desarrollando nuevas herramientas para vos y tu empresa, para utilizarlas necesitamos que
                    cargues tu logo o imagen de referencia antes tus clientes.</p>
            </Tooltip>
        );

        return (
            <div className="datosUsuario">
                <b className="custom-error"
                   ref="error"
                   hidden={!this.props.clientes.update.error}
                   onClickCapture={() => this.cerrarError()}>
                    {this.props.clientes.update.error}
                </b>
                <h3 style={{cursor: "pointer"}}
                    onClick={() => history.push("/encuesta")}>
                    Perfil
                </h3>
                <div className="nombre">
                    <span> {this.props.authentication.nombreUsuario} - {this.props.usuarios.byId.usuarioLogueado.empresa}</span>
                </div>
                <ul className="menu">
                    <li>
                        <a className="page-scroll" onClick={this.props.logout}>
                            Cerrar Sesion
                        </a>
                    </li>
                    <li>
                        <Link className="page-scroll" to="/cambiarPassword">
                            Cambiar Contraseña
                        </Link>
                    </li>
                    <li className="logo-empresa" style={{display: this.props.mostrarLogo ? "block" : "none"}}>
                        <form onSubmit={(e) => {
                            this.submitLogo(e);
                        }}>
                            <div className="container-logo botones-upload">
                                <span className="custom-tooltip">
                                    <OverlayTrigger placement="left" overlay={tooltip}>
                                        <img src={this.props.clientes.update.isUpdating ? spinner : help} alt=""/>
                                    </OverlayTrigger>
                                </span>
                                <img className="img-responsive" ref="imagen" src={emptyLogo}
                                     alt=""/>
                                <input ref="file" type="file" name="file" accept="image/*"
                                       onChange={this.verLogo}/>
                            </div>
                            <div style={{textAlign: "right", marginTop: "20px"}}>
                                <p style={{marginBottom: 0, color: "#00aaeb"}}>
                                    Paleta de colores
                                </p>
                            </div>
                            <div style={{textAlign: "right"}}>
                                <div className="colores">
                                    <div className="input-row">
                                        <label htmlFor="nombre">Primario</label>
                                        <input type="color" id="colorPrimario"
                                               value={cliente && cliente.colorPrimario ? cliente.colorPrimario : ""}
                                               onChange={(e) => this.handleChangeColor(e)}
                                        />
                                    </div>
                                    <div className="input-row">
                                        <label htmlFor="nombre">Secundario</label>
                                        <input type="color" id="colorSecundario"
                                               value={cliente && cliente.colorSecundario ? cliente.colorSecundario : ""}
                                               onChange={(e) => this.handleChangeColor(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button type="submit" className="subir btn-file-input">
                                Aceptar
                            </Button>
                        </form>
                    </li>
                </ul>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
        clientes: state.clientes,
        clientes2: state.clientes2,
        clienteUsuarioLogueado: state.usuarios.byId.clienteUsuarioLogueado,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        },
        updateLogo: (logo) => {
            dispatch(updateLogo(logo));
        },
        resetUpdateCliente: () => {
            dispatch(resetUpdateCliente())
        },
        updateCliente: (cliente) => {
            dispatch(updateCliente(cliente));
        },
        saveUpdateCliente: () => {
            dispatch(saveUpdateClienteLogo());
        },
        updateCliente2: (cliente) => {
            dispatch(updateCliente2(cliente));
        },
        saveUpdateCliente2: (files) => {
            dispatch(saveUpdateCliente2(files));
        },

    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatosUsuario));