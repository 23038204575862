import React, {Component} from 'react';
import {Row} from 'react-bootstrap';

//Components
import SliderHome from '../secciones/SliderHome';
import ProductosHome from "../elementos/productos/ProductosHome";
import SliderMarca from "../secciones/SliderMarca";
import Sumate from "../secciones/Sumate";
import BannerIntegrador from "../secciones/BannerIntegrador";
import Footer2 from "../layout/Footer2";
import SubBarra from '../layout/SubBarra';
import {connect} from "react-redux";

class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="container-fluid">
                <SubBarra logueado={this.props.authentication.token}/>
                <Row>
                    <SliderHome/>
                </Row>
                <Row>
                    <ProductosHome/>
                </Row>
                <Row>
                    <BannerIntegrador/>
                </Row>
                <Row>
                    <SliderMarca/>
                </Row>
                <Row>
                    <Sumate/>
                </Row>
                <Footer2/>
            </div>
        )
            ;
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
