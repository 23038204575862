import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import "./TransferenciaBancaria.css";
import {Row} from "react-bootstrap";
import {createPago, updatePago} from "../../../../../actions/PagoActions";


export default function Echeq(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();

    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;


    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };

    return (
        <div className="transferencia-bancaria container-fluid">
            <Row className="container-formulario">
                <div className="input-row">
                    <label htmlFor="fecha">Fecha acreditación</label>
                    <input id="fecha" type="date" required
                           name="fecha"
                           value={pago && pago.fecha ? pago.fecha : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
                <div className="input-row">
                    <label htmlFor="importe">Importe total del valor</label>
                    <input id="importe" type="number" required
                           name="importe"
                           placeholder="Importe total de los valores"
                           value={pago && pago.importe ? pago.importe : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
            </Row>
        </div>
    )
        ;
}
