import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){
    const usuario = new schema.Entity('usuarios',{}, {idAttribute:"id"});
    const pregunta = new schema.Entity('preguntas',{usuario:usuario}, {idAttribute:"id"});
    const mySchema = [ pregunta ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;