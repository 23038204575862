import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Table, Row, Col, Button, FormControl} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import history from "../../../../history";
import isEmpty from "lodash/isEmpty";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//CSS
import '../../../../assets/css/ResumenCarrito.css';
import spinner from '../../../../assets/img/spinner.gif';

//Actions
import {
    invalidatePreciosProducto,
    fetchPreciosProductoIfNeeded,
    invalidatePreciosProductoTuPrecio,
    fetchPreciosProductoTuPrecioIfNeeded
} from "../../../../actions/PreciosProductoActions";
import {
    invalidateListaPrecios,
    fetchListaPreciosIfNeeded,
    updateListaPrecio, resetUpdateListaPrecio
} from "../../../../actions/ListaPreciosActions";
import {
    saveCreatePedido,
    resetCreatePedido,
    createPedido,
    fetchUltimoPedidoIfNeeded,
    invalidateUltimoPedido,
    resetUltimoPedido
} from "../../../../actions/PedidoActions";
import {
    createDetallePedidoMon,
    deleteDetallePedidoMon,
    resetDetallesPedidoMon
} from "../../../../actions/DetallePedidoMonActions";
import {resetUpdateUsuario} from "../../../../actions/UsuarioActions";
import {fetchTransportesIfNeeded} from "../../../../actions/TransporteActions";
import {fetchFormasEntregaIfNeeded} from "../../../../actions/FormaEntregaActions";
import {invalidateProvincias, fetchProvinciasIfNeeded} from "../../../../actions/ProvinciasActions";
import {invalidateCiudades, fetchCiudadesIfNeeded} from "../../../../actions/CiudadActions";

//Constants
import * as herramientas from "../../../../constants/Herramientas";
import * as roles from "../../../../constants/Roles";
import * as cuentas from "../../../../constants/Cuentas";
import * as formasEntrega from "../../../../constants/FormaEntrega";

//Images
import logoKit from "../../../../assets/img/cuentas/logoKit.png";
import botonMas from "../../../../assets/img/carrito/botonMas.png";
import botonMenos from "../../../../assets/img/carrito/botonMenos.png";
import inicio from "../../../../assets/img/monitorPedidos/inicio.png";
import kitLogo from "../../../../assets/img/kitLogo.png";


//Components
import FieldGroup from "../../../camposTexto/FieldGroup";
import moment from "moment";
import {EXPERTO_A_DOMICILIO_ID} from "../../../../constants/FormaEntrega";

class DatosEntrega extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mostrar: false,
            deshabilitarEnvio: false,
            provinciaSeleccionada: null,
            formaPedido: 1,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchTransportesIfNeeded();
        this.props.fetchFormasEntregaIfNeeded();
        this.props.invalidateProvincias();
        this.props.fetchProvinciasIfNeeded();

        var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
        var usuarioSeleccionado = this.props.usuarios.update.activo;
        if (usuarioLogueado && usuarioLogueado.id) {
            this.props.invalidateUltimoPedido();
            this.props.fetchUltimoPedidoIfNeeded(usuarioSeleccionado && usuarioSeleccionado.id ? usuarioSeleccionado.id : usuarioLogueado.id);
        }
    }

    componentDidUpdate(prevProps) {
        //Si no tengo detalles vuelvo a carrito
        if (this.props.detallesPedidoMon.allIds.length == 0) {
            history.push('/panel/listaPrecios/1');
        }

        if (this.props.pedidos.create.error != prevProps.pedidos.create.error && this.props.pedidos.create.error) {
            this.setState({deshabilitarEnvio: false});
        }

        var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
        var usuarioSeleccionado = this.props.usuarios.update.activo;
        if (usuarioLogueado.id != prevProps.usuarios.byId.usuarioLogueado.id || (usuarioSeleccionado && prevProps.usuarios.update.activo && usuarioSeleccionado.id != prevProps.usuarios.update.activo.id)) {
            this.props.invalidateUltimoPedido();
            this.props.fetchUltimoPedidoIfNeeded(usuarioSeleccionado && usuarioSeleccionado.id ? usuarioSeleccionado.id : usuarioLogueado.id);
        }

        if (this.props.pedidos.byId.isFetchingUltimo != prevProps.pedidos.byId.isFetchingUltimo && !this.props.pedidos.byId.isFetchingUltimo) {
            var cambio = {};
            let ultimoPedido = this.props.pedidos.byId.ultimoPedido;
            if (ultimoPedido) {
                cambio["direccionEntrega"] = ultimoPedido.direccionEntrega;
                if (this.state.provinciaSeleccionada == null && ultimoPedido.ciudad != null) {
                    this.props.invalidateCiudades();
                    this.props.fetchCiudadesIfNeeded(ultimoPedido.ciudad.idProvincia);
                    this.setState({provinciaSeleccionada: ultimoPedido.ciudad.idProvincia});
                    cambio['idLocalidadEntrega'] = ultimoPedido.ciudad.id;
                }
                cambio['idTransporte'] = ultimoPedido.idTransporte;
            } else {
                cambio['direccionEntrega'] = this.props.usuarios.byId.usuarioLogueado.domicilio;
            }
            this.props.createPedido(cambio);
        }
    }

    componentWillUnmount() {
        //Si el pedido fue enviado lo borra.
        if (this.state.deshabilitarEnvio) {
            this.props.resetCreatePedido();
            this.props.resetDetallesPedidoMon();
            this.props.resetUpdateListaPrecio();
            this.props.resetUpdateUsuario();
        }
        this.props.resetUltimoPedido();

        //Inicializar todos los campos
        var cambio = {};
        cambio["idFormaEntrega"] = null;
        cambio['autorizadoARetirar'] = "";
        cambio['dniAutorizado'] = "";
        cambio['montoAAsegurar'] = "";
        cambio['direccionEntrega'] = "";
        cambio['idLocalidadEntrega'] = "";
        cambio['idTransporte'] = "";
        cambio['otroTransporte'] = "";
        cambio['fechaEntrega'] = "";
        cambio['horaEntrega'] = "";
        this.props.createPedido(cambio);
    }

    changeFormaPedido(e) {
        var cambio = {};
        cambio["idFormaEntrega"] = null;
        this.props.createPedido(cambio);
        this.setState({formaPedido: e.target.value});
    }

    onChangeDatosPedido(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id == "idFormaEntrega") {
            cambio['autorizadoARetirar'] = "";
            cambio['dniAutorizado'] = "";
            cambio['montoAAsegurar'] = "";
            if (e.target.value != formasEntrega.PAGO_EN_DOMICILIO_ID && e.target.value != formasEntrega.EXPERTO_A_DOMICILIO_ID) {
                cambio['direccionEntrega'] = "";
                cambio['idLocalidadEntrega'] = "";
            }
            cambio['idTransporte'] = "";
            cambio['otroTransporte'] = "";
            cambio['fechaEntrega'] = "";
            cambio['horaEntrega'] = "";
        }

        if (e.target.id == "idFormaEntrega" && (e.target.value == formasEntrega.PAGO_EN_DOMICILIO_ID || e.target.value == formasEntrega.EXPERTO_A_DOMICILIO_ID)) {
            if (this.props.pedidos.byId.ultimoPedido && this.props.pedidos.byId.ultimoPedido.direccionEntrega && this.props.pedidos.byId.ultimoPedido.ciudad) {
                this.props.invalidateCiudades();
                this.props.fetchCiudadesIfNeeded(this.props.pedidos.byId.ultimoPedido.ciudad.idProvincia);
                this.setState({provinciaSeleccionada: this.props.pedidos.byId.ultimoPedido.ciudad.idProvincia});
                cambio['idLocalidadEntrega'] = this.props.pedidos.byId.ultimoPedido.ciudad.id;
                cambio["direccionEntrega"] = this.props.pedidos.byId.ultimoPedido.direccionEntrega;
                cambio["celularDomicilio"] = this.props.pedidos.byId.ultimoPedido.celularDomicilio;
                if (e.target.value == formasEntrega.EXPERTO_A_DOMICILIO_ID)
                    cambio["horaEntrega"] = this.props.pedidos.byId.ultimoPedido.horaEntrega;
            }
            else {
                var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
                var usuarioSeleccionado = this.props.usuarios.update.activo;
                this.props.invalidateCiudades();
                if (usuarioSeleccionado && usuarioSeleccionado.id) {
                    if(usuarioSeleccionado.ciudad){
                        this.props.fetchCiudadesIfNeeded(usuarioSeleccionado.ciudad.idProvincia);
                        this.setState({provinciaSeleccionada: usuarioSeleccionado.ciudad.idProvincia});
                        cambio['direccionEntrega'] = usuarioSeleccionado.domicilio;
                        cambio['idLocalidadEntrega'] = usuarioSeleccionado.idLocalidad;
                        cambio['celularDomicilio'] = usuarioSeleccionado.celular ? usuarioSeleccionado.celular : usuarioSeleccionado.telefono;
                    }
                }
                else {
                    if (usuarioLogueado.ciudad) {
                        this.props.fetchCiudadesIfNeeded(usuarioLogueado.ciudad.idProvincia);
                        this.setState({provinciaSeleccionada: usuarioLogueado.ciudad.idProvincia});
                        cambio['direccionEntrega'] = usuarioLogueado.domicilio;
                        cambio['idLocalidadEntrega'] = usuarioLogueado.idLocalidad;
                        cambio['celularDomicilio'] = usuarioLogueado.celular ? usuarioLogueado.celular : usuarioLogueado.telefono;
                    }
                }
            }
        }
        if (e.target.id == "idFormaEntrega" && (e.target.value == formasEntrega.PAGO_EN_SUCURSAL_ID || e.target.value == formasEntrega.RETIRA_COMISIONISTA_ID || e.target.value == formasEntrega.EXPERTO_A_TRANPORTE_ID))
            if (this.props.pedidos.byId.ultimoPedido) {
                cambio['autorizadoARetirar'] = this.props.pedidos.byId.ultimoPedido.autorizadoARetirar;
                cambio['dniAutorizado'] = this.props.pedidos.byId.ultimoPedido.dniAutorizado;
            }

        if (e.target.id == "idFormaEntrega" && (e.target.value == formasEntrega.PAGO_EN_DOMICILIO_ID || e.target.value == formasEntrega.PAGO_EN_SUCURSAL_ID)) {
            if (this.props.pedidos.byId.ultimoPedido && this.props.pedidos.byId.ultimoPedido.idTransporte) {
                cambio['idTransporte'] = this.props.pedidos.byId.ultimoPedido.idTransporte;
                cambio["montoAAsegurar"] = this.props.pedidos.byId.ultimoPedido.montoAAsegurar;
            }
        }

        if (e.target.id == "fechaEntrega" || e.target.id == "dniAutorizado")
            this.validarDatos(e.target.id, e.target.value);
        this.props.createPedido(cambio);
    }

    validarDatos(id, valor) {
        var createPedido = this.props.pedidos.create.nuevo;
        var validacion = "";
        if (id == "fechaEntrega") {
            console.log(moment({}).format('YYYY-MM-DD'), moment(valor).format('YYYY-MM-DD'));
            //Valido que la fecha sea mayor a la actual
            if (moment({}).format('YYYY-MM-DD') > moment(valor).format('YYYY-MM-DD')) {
                validacion = "La fecha de entrega debe ser mayor a la fecha actual";
            } else if (createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_TRANPORTE_ID
                && moment(this.agregarDiasLaborales(2)).isAfter(moment(valor).format('YYYY-MM-DD'))) {
                validacion = "Para Experto a Transporte la fecha de entrega debe ser dentro de 2 días hábiles mínimo";
            } else if (createPedido.idFormaEntrega == formasEntrega.PAGO_EN_SUCURSAL_ID
                && moment(this.agregarDiasLaborales(1)).isAfter(moment(valor).format('YYYY-MM-DD'))) {
                validacion = "Para Experto a Transporte la fecha de entrega debe ser dentro de 1 día hábil mínimo";
            }
            document.getElementById(id).setCustomValidity(validacion);
        }
        if (id == "dniAutorizado") {
            var validacion = "";
            if ((valor.length < 7 || valor.length > 8)) {
                validacion = "El DNI debe tener entre 7 y 8 dígitos";
            }
            document.getElementById(id).setCustomValidity(validacion);
        }
    }

    onChangeOpcionesProvincia(e) {
        //Vacio idLocalidadEntrega
        let cambio = {};
        cambio['idLocalidadEntrega'] = "";
        this.props.createPedido(cambio);

        this.setState({
            provinciaSeleccionada: e.target.value,
        });
        this.props.invalidateCiudades();
        this.props.fetchCiudadesIfNeeded(e.target.value);
    }

    nuevoPedido(e) {
        e.preventDefault();
        this.setState({mostrar: true})
    }

    render() {
        const {deshabilitarEnvio, formaPedido} = this.state;

        var usuarioSeleccionado = this.props.usuarios.update.activo;
        var idUsuarioSeleccionado = usuarioSeleccionado && Object.keys(usuarioSeleccionado).length != 0 ? usuarioSeleccionado.id : null;

        var createPedido = this.props.pedidos.create.nuevo;


        var opcionesTransporte = this.props.transportes.allIds.map((idTransporte) => {
            var transporte = this.props.transportes.byId.transportes[idTransporte];
            if (transporte)
                return (
                    <option value={transporte.id}>{transporte.razonSocial}</option>
                )
        });

        let nombres = {};
        nombres[formasEntrega.RETIRA_CLIENTE_ID] = "Retiro yo";
        nombres[formasEntrega.RETIRA_COMISIONISTA_ID] = "Retira un tercero";
        nombres[formasEntrega.EXPERTO_A_DOMICILIO_ID] = "Recibo en domicilio";
        nombres[formasEntrega.EXPERTO_A_TRANPORTE_ID] = "Retiro en la sucursal del transporte";
        nombres[formasEntrega.PAGO_EN_DOMICILIO_ID] = "Recibo en domicilio";
        nombres[formasEntrega.PAGO_EN_SUCURSAL_ID] = "Retiro en la sucursal del transporte";


        var opcionesFormaEntrega = this.props.formasEntrega.allIds.map((idFormaEntrega) => {
            var formaEntrega = this.props.formasEntrega.byId.formasEntrega[idFormaEntrega];
            if (formaEntrega && formaEntrega.id && formaEntrega.id != formasEntrega.COMUNIDAD_KIT_ID && formaEntrega.id != formasEntrega.FACTURA_DE_ENVIO_ID
                && (formaPedido == 1 && (formaEntrega.id == formasEntrega.RETIRA_CLIENTE_ID || formaEntrega.id == formasEntrega.RETIRA_COMISIONISTA_ID))
                || (formaPedido == 2 && (formaEntrega.id == formasEntrega.EXPERTO_A_DOMICILIO_ID || formaEntrega.id == formasEntrega.EXPERTO_A_TRANPORTE_ID))
                || (formaPedido == 3 && (formaEntrega.id == formasEntrega.PAGO_EN_DOMICILIO_ID || formaEntrega.id == formasEntrega.PAGO_EN_SUCURSAL_ID)))
                return (
                    <option
                        value={formaEntrega.id}>{nombres[formaEntrega.id]}</option>
                )
        });

        var opcionesCiudad = this.props.ciudades.allIds.map((key) => {
            var ciudad = this.props.ciudades.byId.ciudades[key];
            return (
                <option value={ciudad.id ? ciudad.id : ""}>{ciudad ? ciudad.nombreCiudad : ""}</option>
            )
        });

        var opcionesProvincia = this.props.provincias.allIds.map((key) => {
            var provincia = this.props.provincias.byId.provincias[key];
            if (provincia.nombreProvincia != "")
                return (
                    <option value={provincia ? provincia.id : ""}>{provincia ? provincia.nombreProvincia : ""}</option>
                )
        });

        var transporteExperto = "";
        if (formaPedido == 2)
            transporteExperto = "Has seleccionado la mejor opción!! Si es tu primera vez que nos pedís de esta manera te bonificamos el 100% del envió, osea que " +
                "recibis el pedido SIN CARGO. Con envios experto seleccionamos el transporte mas rapido y economico para enviarte el pedido, con seguro " +
                "de a la A a la Z, la mercaderia es tuya cuando la tenes en la mano, el cargo de envio te lo facturamos nosotros. Es la forma mas fácil " +
                "rápida y segura de que tengas tu producto.";
        var textoRetiraComisionista = "";
        if (createPedido.idFormaEntrega == formasEntrega.RETIRA_COMISIONISTA_ID)
            textoRetiraComisionista = "Si es un comisionista ingresar el nombre de la empresa, si la persona que va a retirar ya está en la nómina de autorizados no hace falta " +
                "ingresar el nombre en los campos";


        var labelFecha = "Fecha", placeholderFecha = "Fecha", labelHora = "Hora", placeholderHora = "Hora", preguntaFecha = "";
        if (formaPedido == 1) {
            labelFecha = "Fecha Retiro";
            placeholderFecha = "Fecha Retiro";
            labelHora = "Hora Retiro";
            placeholderHora = "A partir de las 15hs";
            preguntaFecha = "A partir de que día y hora podés recibir tu pedido?";
        } else if (formaPedido == 3 && createPedido.idFormaEntrega == formasEntrega.PAGO_EN_SUCURSAL_ID) {
            labelFecha = "Fecha Entrega";
            placeholderFecha = "Fecha Entrega";
            labelHora = "Hora Entrega";
            placeholderHora = "Hora Entrega";
        } else if (formaPedido == 3 && createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID) {
            labelFecha = "Fecha Entrega";
            placeholderFecha = "Fecha Entrega";
            labelHora = "Hora Entrega";
            placeholderHora = "Hora Entrega";
        } else if (formaPedido == 2 && createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_DOMICILIO_ID) {
            labelFecha = "Fecha a recibir";
            placeholderFecha = "Fecha a recibir";
            labelHora = "Días y horarios disponibles";
            placeholderHora = "Ej: de lunes a jueves de 15hs a 20hs";
            preguntaFecha = "En qué días y horarios estas disponible para recibir tu pedido?";
        } else if (formaPedido == 2 && createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_TRANPORTE_ID) {
            labelFecha = "Fecha a retirar";
            placeholderFecha = "Fecha a retirar";
            labelHora = "Hora a retirar";
            placeholderHora = "Hora a retirar";
        }

        //Condiciones para mostrar inputs
        var mostrarFecha = createPedido && createPedido.idFormaEntrega != formasEntrega.EXPERTO_A_TRANPORTE_ID && createPedido.idFormaEntrega != formasEntrega.EXPERTO_A_DOMICILIO_ID && createPedido.idFormaEntrega != formasEntrega.PAGO_EN_DOMICILIO_ID && createPedido.idFormaEntrega != formasEntrega.PAGO_EN_SUCURSAL_ID;
        var mostrarHora = createPedido && createPedido.idFormaEntrega != formasEntrega.EXPERTO_A_TRANPORTE_ID && createPedido.idFormaEntrega != formasEntrega.PAGO_EN_DOMICILIO_ID && createPedido.idFormaEntrega != formasEntrega.PAGO_EN_SUCURSAL_ID;
        var mostrarAutorizado = createPedido.idFormaEntrega == formasEntrega.RETIRA_COMISIONISTA_ID
            || createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_TRANPORTE_ID
            || createPedido.idFormaEntrega == formasEntrega.PAGO_EN_SUCURSAL_ID;
        var mostrarDireccion = createPedido && (createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_DOMICILIO_ID
            || createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID);
        var mostrarProvinciaLocalidad = createPedido &&
            (createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID || createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_DOMICILIO_ID);
        var mostrarTransporte = createPedido && (createPedido.idFormaEntrega == formasEntrega.PAGO_EN_SUCURSAL_ID
            || createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID);

        return (
            <div className="resumenCarrito">
                <form onSubmit={(e) => this.nuevoPedido(e)} name="form">
                    <SweetAlert info
                                showCancel
                                confirmBtnText="Si"
                                cancelBtnText="No"
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="default"
                                title="Enviar pedido"
                                onConfirm={() => {
                                    this.props.saveCreatePedido(this.props.listaPrecio.update.activo.id, idUsuarioSeleccionado);
                                    this.setState({mostrar: false, deshabilitarEnvio: true});
                                }}
                                onCancel={() => {
                                    this.setState({mostrar: false})
                                }}
                                show={this.state.mostrar}>
                        Su pedido será enviado al equipo de KitExperto para realizar la compra. ¿Desea continuar?
                    </SweetAlert>

                    <Row>
                        <Col xs={10} sm={10} md={10} lg={10} xsOffset={1} className="tituloCarrito">
                            <h2>Datos entrega</h2>
                        </Col>
                    </Row>
                    <Row className="columnaDatosMayor">
                        <Col xs={10} sm={10} md={10} lg={10} xsOffset={1} className="columnaDatos">
                            <div className="cuadroDatosPedido">
                                <div className="datosPedido">
                                    <FieldGroup
                                        name="formaPedido"
                                        className="item comboFechaEntrega"
                                        type="select"
                                        componentClass="select"
                                        label="¿Cómo queres recibir o retirar tu Pedido?"
                                        required={true}
                                        value={formaPedido}
                                        disabled={false}
                                        onChange={(e) => this.changeFormaPedido(e)}>
                                        <option value={1}>Me encargo de retirarlo</option>
                                        <option value={2}>Por Transporte Experto</option>
                                        <option value={3}>Enviar por transporte y lo pago yo</option>
                                    </FieldGroup>
                                    <FieldGroup
                                        id="idFormaEntrega"
                                        name="idFormaEntrega"
                                        className="item formaEntrega"
                                        type="select"
                                        componentClass="select"
                                        label="Forma de Entrega"
                                        required={true}
                                        value={createPedido ? createPedido.idFormaEntrega : ""}
                                        disabled={false}
                                        onChange={(e) => this.onChangeDatosPedido(e)}>
                                        <option value="">Seleccione tipo de entrega</option>
                                        {opcionesFormaEntrega}
                                    </FieldGroup>
                                </div>
                                <div style={{display: createPedido.idFormaEntrega ? "block" : "none"}}>
                                    {
                                        transporteExperto.length != 0 ?
                                            <p className={transporteExperto.length != 0 ? "textoTipoEntrega" : ""}>{transporteExperto}</p> :
                                            <div></div>
                                    }
                                    <h3>{preguntaFecha}</h3>
                                    <div className="datosPedido">
                                        <div
                                            style={{
                                                display: mostrarFecha ? "inherit" : "none"
                                            }}>
                                            <FieldGroup
                                                id="fechaEntrega"
                                                name="fechaEntrega"
                                                className="item fechaEntrega"
                                                type="date"
                                                label={labelFecha}
                                                placeholder={placeholderFecha}
                                                disabled={false}
                                                required={mostrarFecha}
                                                value={createPedido && createPedido.fechaEntrega ? moment(createPedido.fechaEntrega).format('YYYY-MM-DD') : ""}
                                                onChange={(e) => this.onChangeDatosPedido(e)}/>
                                        </div>
                                        <div
                                            style={{
                                                display: mostrarHora ? "inherit" : "none"
                                            }}>
                                            <FieldGroup
                                                id="horaEntrega"
                                                name="horaEntrega"
                                                className="item"
                                                type="text"
                                                label={labelHora}
                                                placeholder={placeholderHora}
                                                required={mostrarHora}
                                                value={createPedido && createPedido.horaEntrega}
                                                disabled={false}
                                                onChange={(e) => this.onChangeDatosPedido(e)}/>
                                        </div>
                                    </div>

                                    {
                                        mostrarAutorizado &&
                                        <h3>Quién va a retirar tu pedido?</h3>
                                    }
                                    <div className="datosPedido">
                                        <div
                                            style={{
                                                display: mostrarAutorizado ? "inherit" : "none"
                                            }}>

                                            <FieldGroup
                                                id="autorizadoARetirar"
                                                name="autorizadoARetirar"
                                                className="item"
                                                type="text"
                                                label="Autorizado a Retirar"
                                                placeholder="Autorizado a Retirar"
                                                disabled={false}
                                                required={mostrarAutorizado}
                                                value={createPedido ? createPedido.autorizadoARetirar : ""}
                                                onChange={(e) => this.onChangeDatosPedido(e)}/>
                                            <FieldGroup
                                                id="dniAutorizado"
                                                name="dniAutorizado"
                                                className="item"
                                                type="number"
                                                label="DNI Autorizado (opcional)"
                                                placeholder="DNI Autorizado"
                                                disabled={false}
                                                required={false}
                                                min={0}
                                                value={createPedido ? createPedido.dniAutorizado : ""}
                                                onChange={(e) => this.onChangeDatosPedido(e)}/>
                                        </div>
                                        {
                                            textoRetiraComisionista.length != 0 ?
                                                <p className={textoRetiraComisionista.length != 0 ? "textoTipoEntrega" : ""}>{textoRetiraComisionista}</p>
                                                : <div></div>
                                        }
                                    </div>
                                    {
                                        mostrarDireccion &&
                                        <h3>En qué dirección recibirás el pedido?</h3>
                                    }
                                    <div className="datosPedido" style={{
                                        display: mostrarDireccion ? "flex" : "none"
                                    }}>
                                        <FieldGroup
                                            id="direccionEntrega"
                                            name="direccionEntrega"
                                            className="item direccionEntrega"
                                            type="text"
                                            label="Dirección Entrega"
                                            required={mostrarDireccion}
                                            placeholder="Dirección Entrega"
                                            value={createPedido ? createPedido.direccionEntrega : ""}
                                            onChange={(e) => {
                                                this.onChangeDatosPedido(e)
                                            }}
                                            disabled={false}/>
                                        <div style={{
                                            display: mostrarProvinciaLocalidad ? "flex" : "none"
                                        }}>
                                            <FieldGroup
                                                id="idProvincia"
                                                name="idProvincia"
                                                className="item"
                                                type="select"
                                                componentClass="select"
                                                label="Provincia"
                                                required={mostrarProvinciaLocalidad}
                                                value={this.state.provinciaSeleccionada}
                                                disabled={false}
                                                onChange={(e) => this.onChangeOpcionesProvincia(e)}>
                                                <option value="">Seleccione provincia</option>
                                                {opcionesProvincia}
                                            </FieldGroup>
                                            <FieldGroup
                                                id="idLocalidadEntrega"
                                                name="idLocalidadEntrega"
                                                className="item"
                                                type="select"
                                                componentClass="select"
                                                label="Localidad"
                                                required={mostrarProvinciaLocalidad}
                                                value={createPedido ? createPedido.idLocalidadEntrega : ""}
                                                disabled={false}
                                                onChange={(e) => this.onChangeDatosPedido(e)}>
                                                <option value="">Seleccione localidad</option>
                                                {opcionesCiudad}
                                            </FieldGroup>
                                        </div>
                                    </div>
                                    <div className="datosPedido">
                                        <div style={{
                                            display: createPedido && (createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_DOMICILIO_ID || createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID) ? "flex" : "none"
                                        }}>
                                            <FieldGroup
                                                id="celularDomicilio"
                                                name="celularDomicilio"
                                                className="item celularDomicilio"
                                                type="text"
                                                label="Celular"
                                                required={createPedido && (createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_DOMICILIO_ID || createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID)}
                                                placeholder="Celular"
                                                value={createPedido ? createPedido.celularDomicilio : ""}
                                                onChange={(e) => {
                                                    this.onChangeDatosPedido(e)
                                                }}
                                                disabled={false}/>
                                        </div>
                                    </div>
                                    {
                                        mostrarTransporte &&
                                        <h3>Cómo enviaremos tu pedido?</h3>
                                    }
                                    <div className="datosPedido" style={{
                                        display: mostrarTransporte ? "flex" : "none"
                                    }}>
                                        <FieldGroup
                                            id="idTransporte"
                                            name="idTransporte"
                                            className="item codTransporte"
                                            type="select"
                                            componentClass="select"
                                            label="Transporte"
                                            required={mostrarTransporte}
                                            value={createPedido && createPedido.idTransporte ? createPedido.idTransporte : ""}
                                            onChange={(e) => this.onChangeDatosPedido(e)}
                                            disabled={false}>
                                            <option value="">Seleccione transporte</option>
                                            {opcionesTransporte}
                                            <option value="otros">Otros</option>
                                        </FieldGroup>
                                        {
                                            createPedido.idTransporte == "otros" ?
                                                <FieldGroup
                                                    id="otroTransporte"
                                                    name="otroTransporte"
                                                    className="item transporte"
                                                    type="text"
                                                    label="Elegí el transporte con el que querés trabajar"
                                                    required={createPedido.idTransporte == "otros"}
                                                    value={createPedido ? createPedido.transporte : ""}
                                                    onChange={(e) => this.onChangeDatosPedido(e)}
                                                    disabled={false}/>
                                                : <div></div>
                                        }
                                    </div>
                                    <div className="datosPedido">
                                        <div style={{
                                            display: createPedido && (createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID || createPedido.idFormaEntrega == formasEntrega.PAGO_EN_SUCURSAL_ID) ? "flex" : "none"
                                        }}>
                                            <FieldGroup
                                                id="montoAAsegurar"
                                                name="montoAAsegurar"
                                                className="item"
                                                type="select"
                                                componentClass="select"
                                                label="Monto a asegurar"
                                                required={createPedido && (createPedido.idFormaEntrega == formasEntrega.PAGO_EN_DOMICILIO_ID)}
                                                value={createPedido && createPedido.montoAAsegurar ? createPedido.montoAAsegurar : ""}
                                                disabled={false}
                                                onChange={(e) => this.onChangeDatosPedido(e)}>
                                                <option value="">Seleccione un valor</option>
                                                <option value="Seguro Mínimo">Seguro Mínimo</option>
                                                <option value="50% del Total del pedido">50% del Total del pedido
                                                </option>
                                                <option value="100% del Total del pedido">100% del Total del pedido
                                                </option>
                                            </FieldGroup>
                                        </div>
                                    </div>
                                    <div className="datosPedido">
                                        <FieldGroup
                                            id="observacion"
                                            name="observacion"
                                            className="item observacion"
                                            type="textarea"
                                            componentClass="textarea"
                                            label="Observación"
                                            placeholder="Comunícanos cualquier información adicional que creas necesario acerca del pedido, estamos para facilitar tus procesos. Gracias"
                                            disabled={false}
                                            required={false}
                                            value={createPedido ? createPedido.observacion : ""}
                                            onChange={(e) => this.onChangeDatosPedido(e)}/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="contenedorBotones">
                        <Col xs={10} sm={10} md={10} lg={10} xsOffset={1} className="contenedorTotal">
                            <div style={{display: this.props.pedidos.create.isCreating ? "block" : "none"}}>
                                <img src={spinner} alt="" style={{float: "left"}}/>
                                <p className="textoEnviando">
                                    Enviando...
                                </p>
                            </div>
                            <div style={{display: this.props.pedidos.create.isCreating ? "none" : "", height: "80px"}}>
                                <p style={{display: this.state.deshabilitarEnvio ? "block" : "none"}}>Su pedido ha sido
                                    enviado. En instantes recibirá un mail con las indicaciones.</p>
                                <p style={{
                                    display: this.state.deshabilitarEnvio
                                    && createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_TRANPORTE_ID ? "block" : "none"
                                }}>Podrá ver el detalle del transporte elegido desde la herramienta Monitor De
                                    Pedidos.</p>
                                <Button className="botonInferiorEnviar"
                                        type="submit"
                                        disabled={deshabilitarEnvio}>
                                    Procesar
                                </Button>
                                <Link to="/panel/listaPrecios/checkout/1">
                                    <Button className="botonInferiorVolver">
                                        Volver
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        detallesPedidoMon: state.detallesPedidoMon,
        productos: state.productos,
        preciosProducto: state.preciosProducto,
        habilitaciones: state.habilitaciones,
        listaPrecio: state.listaPrecio,
        pedidos: state.pedidos,
        kits: state.kits,
        accesosCuenta: state.accesosCuenta,
        usuarios: state.usuarios,
        transportes: state.transportes,
        formasEntrega: state.formasEntrega,
        ciudades: state.ciudades,
        provincias: state.provincias,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        invalidatePreciosProducto: () => {
            dispatch(invalidatePreciosProducto())
        },
        fetchPreciosProductoIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoIfNeeded(idLista, idHabilitacion))
        },
        fetchListaPreciosIfNeeded: () => {
            dispatch(fetchListaPreciosIfNeeded())
        },
        invalidateListaPrecios: () => {
            dispatch(invalidateListaPrecios())
        },
        saveCreatePedido: (idLista, idUsuarioSeleccionado) => {
            dispatch(saveCreatePedido(idLista, idUsuarioSeleccionado))
        },
        resetCreatePedido: () => {
            dispatch(resetCreatePedido())
        },
        resetDetallesPedidoMon: () => {
            dispatch(resetDetallesPedidoMon())
        },
        updateListaPrecio: (listaPrecio) => {
            dispatch(updateListaPrecio(listaPrecio))
        },
        resetUpdateListaPrecio: () => {
            dispatch(resetUpdateListaPrecio())
        },
        resetUpdateUsuario: () => {
            dispatch(resetUpdateUsuario())
        },
        createDetallePedidoMon: (detalle) => {
            dispatch(createDetallePedidoMon(detalle))
        },
        deleteDetallePedidoMon: (detalle) => {
            dispatch(deleteDetallePedidoMon(detalle))
        },
        invalidatePreciosProductoTuPrecio: () => {
            dispatch(invalidatePreciosProductoTuPrecio())
        },
        fetchPreciosProductoTuPrecioIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoTuPrecioIfNeeded(idLista, idHabilitacion))
        },
        createPedido: (pedido) => {
            dispatch(createPedido(pedido))
        },
        fetchTransportesIfNeeded: (filtros) => {
            dispatch(fetchTransportesIfNeeded(filtros))
        },
        fetchUltimoPedidoIfNeeded: (idUsuario) => {
            dispatch(fetchUltimoPedidoIfNeeded(idUsuario))
        },
        invalidateUltimoPedido: () => {
            dispatch(invalidateUltimoPedido())
        },
        resetUltimoPedido: () => {
            dispatch(resetUltimoPedido())
        },
        fetchFormasEntregaIfNeeded: () => {
            dispatch(fetchFormasEntregaIfNeeded())
        },
        invalidateProvincias: () => {
            dispatch(invalidateProvincias())
        },
        fetchProvinciasIfNeeded: () => {
            dispatch(fetchProvinciasIfNeeded())
        },
        invalidateCiudades: () => {
            dispatch(invalidateCiudades())
        },
        fetchCiudadesIfNeeded: (idProvincia) => {
            dispatch(fetchCiudadesIfNeeded(idProvincia))
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatosEntrega));
