//api
import auth from "../api/authentication";
import detallesPedidoMon from "../api/detallesPedidoMon"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthenticationActions";
import encuestaRespuestaApi from "../api/encuestaRespuestas";


//DETALLESPEDIDOMON
export const REQUEST_DETALLESPEDIDOMON = 'REQUEST_DETALLESPEDIDOMON';
export const RECEIVE_DETALLESPEDIDOMON = 'RECEIVE_DETALLESPEDIDOMON';
export const INVALIDATE_DETALLESPEDIDOMON = 'INVALIDATE_DETALLESPEDIDOMON';
export const ERROR_DETALLESPEDIDOMON = "ERROR_DETALLESPEDIDOMON";
export const RESET_DETALLESPEDIDOMON = "RESET_DETALLESPEDIDOMON";


export function invalidateDetallesPedidoMon() {
    return {
        type: INVALIDATE_DETALLESPEDIDOMON,
    }
}

function requestDetallesPedidoMon() {
    return {
        type: REQUEST_DETALLESPEDIDOMON,
    }
}

function receiveDetallesPedidoMon(json) {
    return {
        type: RECEIVE_DETALLESPEDIDOMON,
        detallesPedidoMon: json,
        receivedAt: Date.now()
    }
}

function errorDetallesPedidoMon(error) {
    return {
        type: ERROR_DETALLESPEDIDOMON,
        error: error,
    }
}

export function resetDetallesPedidoMon(error) {
    return {
        type: RESET_DETALLESPEDIDOMON,
        error: error,
    }
}

function fetchDetallesPedidoMon(idPedido) {
    return dispatch => {
        dispatch(requestDetallesPedidoMon());
        return detallesPedidoMon.getByIdPedido(idPedido)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDetallesPedidoMon(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDetallesPedidoMon(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDetallesPedidoMon(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchDetallesPedidoMon(state, idPedido) {
    // TODO verificar si estan los detalles pedido de ese pedido
    var pedido = state.pedidos.byId.pedidos[idPedido];

    const detallesPedidoMon = state.detallesPedidoMon.byId;
    if (!detallesPedidoMon) {
        return true
    } else if (detallesPedidoMon.isFetching) {
        return false
    } else if (pedido != undefined) {
        for (var idDetallePedidoMon of pedido.detalle_pedido_mon) {
            if (!state.detallesPedidoMon.byId.detallesPedidoMon[idDetallePedidoMon]) {
                return true;
            }
        }
        /*pedido.detalle_pedido_mon.some((idDetallePedidoMon)=>{

            if(!state.detallesPedidoMon.byId.detallesPedidoMon[idDetallePedidoMon]) {
                return true;
            }
        });*/
        return detallesPedidoMon.didInvalidate;
    } else {
        return detallesPedidoMon.didInvalidate;
    }
}

export function fetchDetallesPedidoMonIfNeeded(idPedido) {
    return (dispatch, getState) => {
        if (shouldFetchDetallesPedidoMon(getState(), idPedido)) {
            return dispatch(fetchDetallesPedidoMon(idPedido))
        }
    }
}

// Alta detalle
export const CREATE_DETALLEPEDIDOMON = 'CREATE_DETALLEPEDIDOMON';
export const REQUEST_CREATE_DETALLEPEDIDOMON = "REQUEST_CREATE_DETALLEPEDIDOMON";
export const SUCCESS_CREATE_DETALLEPEDIDOMON = "SUCCESS_CREATE_DETALLEPEDIDOMON";
export const ERROR_CREATE_DETALLEPEDIDOMON = "ERROR_CREATE_DETALLEPEDIDOMON";
export const RESET_CREATE_DETALLEPEDIDOMON = "RESET_CREATE_DETALLEPEDIDOMON";

function requestCreateDetallePedidoMon() {
    return {
        type: REQUEST_CREATE_DETALLEPEDIDOMON,
    }
}

function receiveCreateDetallePedidoMon() {
    return {
        type: SUCCESS_CREATE_DETALLEPEDIDOMON,
        receivedAt: Date.now()
    }
}

function errorCreateDetallePedidoMon(error) {
    return {
        type: ERROR_CREATE_DETALLEPEDIDOMON,
        error: error,
    }
}

export function resetCreateDetallePedidoMon(error) {
    return {
        type: RESET_CREATE_DETALLEPEDIDOMON,
        error: error,
    }
}

export function createDetallePedidoMon(detallePedidoMon) {
    return {
        type: CREATE_DETALLEPEDIDOMON,
        detallePedidoMon
    }
}

// Borrar
export const DELETE_DETALLEPEDIDOMON = "DELETE_DETALLEPEDIDOMON";

export function deleteDetallePedidoMon(detallePedidoMon) {
    return {
        type: DELETE_DETALLEPEDIDOMON,
        detallePedidoMon
    }
}

//UPDATE
export const UPDATE_DETALLEPEDIDOMON = "UPDATE_DETALLEPEDIDOMON";
export const REQUEST_UPDATE_DETALLEPEDIDOMON = "REQUEST_UPDATE_DETALLEPEDIDOMON";
export const SUCCESS_UPDATE_DETALLEPEDIDOMON = "SUCCESS_UPDATE_DETALLEPEDIDOMON";
export const ERROR_UPDATE_DETALLEPEDIDOMON = "ERROR_UPDATE_DETALLEPEDIDOMON";
export const RESET_UPDATE_DETALLEPEDIDOMON = "RESET_UPDATE_DETALLEPEDIDOMON";


//UPDATE
function requestUpdateDetallesPedidoMon() {
    return {
        type: REQUEST_UPDATE_DETALLEPEDIDOMON,
    }
}

function receiveUpdateDetallesPedidoMon() {
    return {
        type: SUCCESS_UPDATE_DETALLEPEDIDOMON,
        receivedAt: Date.now()
    }
}

function errorUpdateDetallesPedidoMon(error) {
    return {
        type: ERROR_UPDATE_DETALLEPEDIDOMON,
        error: error,
    }
}

export function resetUpdateDetallesPedidoMon() {
    return {
        type: RESET_UPDATE_DETALLEPEDIDOMON
    }
}

export function updateDetallePedidoMon(detallePedidoMon, idDetallePedidoMon) {
    return {
        type: UPDATE_DETALLEPEDIDOMON,
        detallePedidoMon,
        idDetallePedidoMon,
    }
}

export function saveUpdateDetallesPedidoMon() {
    return (dispatch, getState) => {
        dispatch(requestUpdateDetallesPedidoMon());
        //Tomo los nuevos detalles
        var detallesPedidoMonM = getState().detallesPedidoMon.update.detallesPedidoMon.map((id) => {
            return (getState().detallesPedidoMon.byId.detallesPedidoMon[id]);
        });
        return detallesPedidoMon.update(detallesPedidoMonM)
            .then(function (response) {
                //Refresco token
                auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveUpdateDetallesPedidoMon());
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateDetallesPedidoMon(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateDetallesPedidoMon(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}