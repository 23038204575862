import {
    INVALIDATE_LINKSPAGOS,
    ERROR_LINKSPAGOS,
    RECEIVE_LINKSPAGOS,
    REQUEST_LINKSPAGOS,
    RESET_LINKSPAGOS,
    ERROR_LINKPAGO,
    RECEIVE_LINKPAGO,
    REQUEST_LINKPAGO,
    UPDATE_LINKPAGO,
    REQUEST_UPDATE_LINKPAGO,
    SUCCESS_UPDATE_LINKPAGO,
    ERROR_UPDATE_LINKPAGO,
    RESET_UPDATE_LINKPAGO,
    REQUEST_UPDATE_LINKSPAGOS,
    SUCCESS_UPDATE_LINKSPAGOS,
    ERROR_UPDATE_LINKSPAGOS,
    RESET_UPDATE_LINKSPAGOS,
    CREATE_LINKPAGO,
    ERROR_CREATE_LINKPAGO,
    REQUEST_CREATE_LINKPAGO,
    RESET_CREATE_LINKPAGO,
    SUCCESS_CREATE_LINKPAGO,
    REQUEST_CREATE_LINKSPAGOS,
    SUCCESS_CREATE_LINKSPAGOS,
    ERROR_CREATE_LINKSPAGOS,
    RESET_CREATE_LINKSPAGOS,
    DELETE_LINKPAGO,
    DELETE_CREATE_LINKPAGO,
    DELETE_UPDATE_LINKPAGO,
    REQUEST_DELETE_LINKPAGO,
    SUCCESS_DELETE_LINKPAGO,
    ERROR_DELETE_LINKPAGO,
    RESET_DELETE_LINKPAGO,
    REQUEST_PRINT_LINKPAGO,
    SUCCESS_PRINT_LINKPAGO,
    ERROR_PRINT_LINKPAGO,
    RESET_PRINT_LINKPAGO, RECEIVE_FILE_LINKPAGO
} from '../actions/LinkPagoActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        linksPagos: {},
        files: {}
    }
}

function linksPagosById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_LINKSPAGOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_LINKSPAGOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_LINKSPAGOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_LINKSPAGOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                linksPagos: {}
            });
        case RECEIVE_LINKSPAGOS:
            let dato = action.linksPagos.entities.linksPagos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                linksPagos: merge({}, state.linksPagos, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_LINKPAGO:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_LINKPAGO:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_LINKPAGO:
            let datoLinkPago = action.linkPago.entities.linksPagos;
            return Object.assign({}, state, {
                linksPagos: merge({}, state.linksPagos, datoLinkPago),
                isFetching: false,
            });
        case RECEIVE_FILE_LINKPAGO:
            return Object.assign({}, state, {
                files: merge({}, state.files, action.file),
            });

        case SUCCESS_DELETE_LINKPAGO:
            let datoLinkPagoEliminado = action.linkPago.entities.linksPagos;
            return Object.assign({}, state, {
                linksPagos: mergeWith(clone(datoLinkPagoEliminado), state.linksPagos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_LINKPAGO:
            let datoLinkPagoCreado = action.linkPago.entities.linksPagos;
            return Object.assign({}, state, {
                linksPagos: mergeWith(clone(datoLinkPagoCreado), state.linksPagos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_LINKSPAGOS:
            let datosLinkPagoCreado = action.linksPagos.entities.linksPagos;
                return Object.assign({}, state, {
                    linksPagos: mergeWith(clone(datosLinkPagoCreado), state.linksPagos, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_LINKPAGO:
            let datoLinkPagoActualizado = action.linkPago.entities.linksPagos;
            return Object.assign({}, state, {
                linksPagos: mergeWith(clone(datoLinkPagoActualizado), state.linksPagos, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_LINKSPAGOS:
            let datosLinkPagoActualizado = action.linksPagos.entities.linksPagos;
                return Object.assign({}, state, {
                    linksPagos: mergeWith(clone(datosLinkPagoActualizado), state.linksPagos, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                linksPagos: {}
            });
        default:
            return state
    }
}


function allLinksPagos(state = [], action) {
    switch (action.type) {
        case RECEIVE_LINKSPAGOS:
            return action.linksPagos.result && action.linksPagos.result.linksPagos ? union(action.linksPagos.result.linksPagos, state) : (action.linksPagos.result ? action.linksPagos.result : state) ;
        case RECEIVE_LINKPAGO:
                return action.linkPago.result ? union([action.linkPago.result], state) : state;
        
        case SUCCESS_CREATE_LINKPAGO:
                   let datoLinkPagoSCreate = action.linkPago.entities.linksPagos;
                   let idNuevoSCreate = null;
                   if (Object.values(datoLinkPagoSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoLinkPagoSCreate)[0] && Object.values(datoLinkPagoSCreate)[0].id ? Object.values(datoLinkPagoSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_LINKSPAGOS:
                   let linksPagosCreate = action.linksPagos.entities && action.linksPagos.entities.linksPagos ? action.linksPagos.entities.linksPagos : null;
                   return linksPagosCreate ?
                       union(state, Object.values(linksPagosCreate).map((linksPagos) => {
                           return linksPagos.id
                       })) : state;
        case RESET_LINKSPAGOS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalLinksPagos(state = null, action) {
    switch (action.type) {
        case RECEIVE_LINKSPAGOS:
            return action.linksPagos && action.linksPagos.result.total ? action.linksPagos.result.total : 0;
        case RESET_LINKSPAGOS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_LINKPAGO:
            let dato = action.linkPago.entities.linksPagos;
            let linkPago = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: linkPago ? linkPago : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_LINKPAGO:
            let idsUpdate = [];
            Object.values(action.linkPago).map((linkPagoUpdate) => {
                if (linkPagoUpdate && linkPagoUpdate.id)
                    idsUpdate.push(linkPagoUpdate.id);
            });
            return merge({}, state, {
                activo: action.linkPago,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_LINKPAGO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_LINKPAGO:
            let datoLinkPagoActualizado = {};
            if (Object.values(action.linkPago.entities.linksPagos).length > 0)
                datoLinkPagoActualizado = Object.values(action.linkPago.entities.linksPagos)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoLinkPagoActualizado
            });
        case ERROR_UPDATE_LINKPAGO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_LINKSPAGOS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_LINKSPAGOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_LINKSPAGOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_LINKPAGO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_LINKPAGO:
            let datoLinkPagoDelete = action.linkPago;
            let idsDelete = [];
           Object.values(action.linkPago).map((linkPagoDelete) => {
               if (linkPagoDelete && linkPagoDelete.id)
                   idsDelete.push(linkPagoDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoLinkPagoDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_LINKPAGO:
                       let datoLinkPagoDeleteUpdate = action.linkPago;
                       let idsDeleteUpdate = [];
                      Object.values(action.linkPago).map((linkPagoDelete) => {
                          if (linkPagoDelete && linkPagoDelete.id)
                              idsDeleteUpdate.push(linkPagoDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoLinkPagoDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_LINKPAGO:
                    let datoLinkPagoDeleted = {};
                    if (Object.values(action.linkPago.entities.linksPagos).length > 0)
                        datoLinkPagoDeleted = Object.values(action.linkPago.entities.linksPagos)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoLinkPagoDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_LINKPAGO:
             let idsCreate = [];
             Object.values(action.linkPago).map((linkPagoCreate) => {
                 if (linkPagoCreate && linkPagoCreate.id)
                     idsCreate.push(linkPagoCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.linkPago,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_LINKPAGO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_LINKPAGO:
            let datoLinkPagoNuevo = {};
            if (Object.values(action.linkPago.entities.linksPagos).length > 0)
                datoLinkPagoNuevo = Object.values(action.linkPago.entities.linksPagos)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoLinkPagoNuevo,
                nuevos: []
            });
        case ERROR_CREATE_LINKPAGO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_LINKSPAGOS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_LINKSPAGOS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_LINKSPAGOS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_LINKPAGO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_LINKPAGO:
           let datoLinkPagoDelete = action.linkPago;
           let idsDelete = [];
           Object.values(action.linkPago).map((linkPagoDelete) => {
               if (linkPagoDelete && linkPagoDelete.id)
                   idsDelete.push(linkPagoDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoLinkPagoDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_LINKPAGO:
                  let datoLinkPagoDeleteCreate = action.linkPago;
                  let idsDeleteCreate = [];
                  Object.values(action.linkPago).map((linkPagoDelete) => {
                      if (linkPagoDelete && linkPagoDelete.id)
                          idsDeleteCreate.push(linkPagoDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoLinkPagoDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_LINKPAGO:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.linkPago,
                error: null,
            });
        case REQUEST_DELETE_LINKPAGO:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_LINKPAGO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_LINKPAGO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_LINKPAGO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_LINKPAGO:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_LINKPAGO:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_LINKPAGO:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const linksPagos = combineReducers({
    byId: linksPagosById,
    allIds: allLinksPagos,
    update: update,
    create: create,
    totalLinksPagos: totalLinksPagos,
    delete: deleter,
    print: print
});

export default linksPagos;