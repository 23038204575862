import {combineReducers} from 'redux';
import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from "../actions/PedidoActions";
import merge from "lodash/merge";
import union from "lodash/union";

function bultosById(state = {
    isFetching: false,
    didInvalidate: true,
    bultos: {}
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                bultos: merge({}, state.bultos, action.pedidos.entities.bultos),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                bultos: {},
                error: null
            });
        default:
            return state
    }
}


function allBultos(state = [], action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return action.pedidos.entities.bultos ? union(state, Object.keys(action.pedidos.entities.bultos)) : state;
        case RESET_PEDIDOS:
            return [];
        default:
            return state
    }

}

const bultos = combineReducers({
    byId: bultosById,
    allIds: allBultos,
});

export default bultos;