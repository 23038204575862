import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Modal, Button, Row} from 'react-bootstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import history from "../../../history";

//Actions
import {changeLogin, login, olvideMiPassword, resetLogin, errorLogin} from "../../../actions/AuthenticationActions";

class ModalRegistro extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrar: false,
        };
    }

    closeModalBusSerie() {
        this.props.cerrar();
        this.props.errorLogin("");
        var cambio = {};
        cambio["password"] = "";
        cambio["email"] = "";
        this.props.changeLogin(cambio);
    }

    onChangeLogin(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.changeLogin(cambio);
    }

    submitLogin(e) {
        e.preventDefault();
        let idProducto = this.props.match.params["idProducto"];
        if (idProducto)
            var producto = this.props.productos.byId.productos[idProducto];
        var to = {};
        to.pathname = "/panel/listaPrecios/1/producto/" + producto.nomProducto.replace(/\//ig,"")
        this.props.login(this.props.authentication.usuario, to);
    }

    olvideMiPasswordInicio(e) {
        if (this.props.authentication.usuario.email == "")
            this.props.olvideMiPassword(this.props.authentication.usuario.email);
        else
            this.setState({mostrar: true});
    }

    olvideMiPassword(e) {
        this.props.olvideMiPassword(this.props.authentication.usuario.email);
        this.setState({mostrar: false});
    }

    render() {
        return (
            <section className="modalRegistroDetalle">
                <SweetAlert warning
                            showCancel
                            confirmBtnText="Continuar"
                            cancelBtnText="Cancelar"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Recuperación de contraseña"
                            onConfirm={() => this.olvideMiPassword()}
                            onCancel={() => {
                                this.setState({mostrar: false})
                            }}
                            show={this.state.mostrar}>
                    Se le enviará un email a su casilla de correo con los pasos a seguir.
                </SweetAlert>
                <Modal show={this.props.show}
                       onHide={() => this.closeModalBusSerie()}
                       aria-labelledby="contained-modal-title-lg"
                >
                    <Modal.Body className="modalBodyPD">
                        <div className="modalRegistro">
                            <div className="textoModalRegistro">
                                <h3><b>¿Sos Instalador o Integrador de Seguridad?</b></h3>
                                <p>Ingresá con tu usuario para poder acceder a los precios de los productos<br/>y
                                    obtené <span style={{
                                        borderBottom: "1px solid white",
                                        width: "fit-content"
                                    }}>gratis</span> todo el universo de servicios que tenemos para vos.<br/> <b>Si no
                                        tenés uno aún ¡registrate!</b></p>
                                <p style={{
                                    color: "yellow",
                                    display: this.props.authentication.currentlySending ? "block" : "none"
                                }}>Procesando
                                    solicitud</p>
                                <p style={{color: "red"}}>{this.props.authentication.errorMessage}</p>
                                <form onSubmit={(e) => {
                                    this.submitLogin(e)
                                }}>
                                    <Row>
                                        <input id="email" type="text" required placeholder="Email"
                                               value={this.props.authentication.usuario.email}
                                               onChange={(e) => this.onChangeLogin(e)}
                                               disabled={this.props.authentication.currentlySending}/>
                                    </Row>
                                    <Row>
                                        <input id="password" type="password" required placeholder="Contraseña"
                                               value={this.props.authentication.usuario.password}
                                               onChange={(e) => this.onChangeLogin(e)}
                                               disabled={this.props.authentication.currentlySending}/>
                                    </Row>
                                    <a style={{display: this.props.authentication.currentlySending ? "none" : "block"}}
                                       onClick={() => this.olvideMiPasswordInicio()}>
                                        ¿Olvidaste tu contraseña?
                                    </a>
                                    <Row className="contenedorBotones">
                                        <Button type="submit" className="botonEntrar"
                                                disabled={this.props.authentication.currentlySending}>Entrar</Button>
                                        <Button className="botonRegistroModal"
                                                onClick={() => this.props.history.push("/registrar")}
                                                disabled={this.props.authentication.currentlySending}>¡Registrate!</Button>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        );
    }

}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        productos: state.productos,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLogin: (usuario) => {
            dispatch(changeLogin(usuario))
        },
        login: (usuario, to) => {
            dispatch(login(usuario, to))
        },
        olvideMiPassword: (email) => {
            dispatch(olvideMiPassword(email))
        },
        resetLogin: () => {
            dispatch(resetLogin())
        },
        errorLogin: (error) => {
            dispatch(errorLogin(error))
        },
    }
};


// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalRegistro));