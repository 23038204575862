import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){

    const cuenta = new schema.Entity('cuentas',{},{idAttribute:"id"});
    const herramienta = new schema.Entity('herramientas',{},{idAttribute:"id"});
    const habilitacion = new schema.Entity('habilitaciones',{herramienta: herramienta},{idAttribute:"id"});
    const accesosCuenta = new schema.Entity('accesosCuenta',{cuenta: cuenta, habilitacion: [habilitacion]}, {idAttribute:"id"});
    const mySchema = [ accesosCuenta ];
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export default normalizeDatos;