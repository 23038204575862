import React from 'react';
import {Col, Row} from "react-bootstrap";
import headerIzq from "../../../../assets/img/carrito/headerIzq.png";
import history from "../../../../history";

import "./HeaderHerramienta.css";

export default function HeaderHerramienta(props) {

    return (
        <section className="headerHerramienta container-fluid">
            <div>
                <Row>
                    <img src={headerIzq} onClick={() => {
                        history.push("/panel/cuentas")
                    }}/>
                </Row>
                <Row className="mleft9">
                    <Col xs={12} sm={10} md={10} lg={10} smOffset={1}>
                        <Row>
                            <h1>{props.titulo}</h1>
                        </Row>
                        <div className="textoBienvenida">
                            <p> {props.texto ? props.texto : ""}</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
