import c from '../constants/constants';
require('es6-promise').polyfill();
require('isomorphic-fetch');


var encuestaRespuestas = {

    saveCreate(respuestas,idUsuario) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(respuestas)
        };

        return fetch(c.BASE_URL + '/encuestaRespuestas/'+idUsuario, defaultOptions);
    },
    saveUpdate(respuestas,idUsuario) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(respuestas)
        };

        return fetch(c.BASE_URL + '/encuestaRespuestas/'+idUsuario, defaultOptions);
    },
};

export default encuestaRespuestas;