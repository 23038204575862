import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Autosuggest.css"
import Autosuggest from "react-autosuggest";
import botonCancelarNavegador from "../../../../assets/img/carrito/botonCancelarNavegador.png";
import {
    fetchUsuariosIfNeeded,
    invalidateUsuarios,
    resetUpdateUsuario,
    resetUsuarios
} from "../../../../actions/UsuarioActions";
import { resetCliente } from "../../../../actions/ClienteActions";

var match = require('autosuggest-highlight/match');
var parse = require('autosuggest-highlight/parse');


export default function AutosuggestUsuarios(props) {
    const dispatch = useDispatch();
    const timer = useRef();
    const mounted = useRef();

    //Store
    const usuarios = useSelector(state => state.usuarios);
    //State
    const [suggestionsUsuarios, setSuggestionsUsuarios] = useState([]);
    const [valueUsuarios, setValueUsuarios] = useState(props.usuarioSeleccionado ? (props.usuarioSeleccionado.nombre + " " + props.usuarioSeleccionado.apellido) : "");

    //Effects
    useEffect(() => {

        return function cleanup() {
            dispatch(resetUsuarios());
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!usuarios.byId.isFetchingUsuarios) {
                setSuggestionsUsuarios(getSuggestionsUsuarios(valueUsuarios));
            }
        }
    }, [usuarios.byId.isFetchingUsuarios]);

    useEffect(() => {
        if (props.usuarioSeleccionado && props.usuarioSeleccionado.id && valueUsuarios === "") {
            setValueUsuarios(`${props.usuarioSeleccionado.nombre} ${props.usuarioSeleccionado.apellido} (${props.usuarioSeleccionado.vd_codCliente ? props.usuarioSeleccionado.vd_codCliente : "-"})`);
        }
        else if(!props.usuarioSeleccionado && valueUsuarios !== "")
            setValueUsuarios("");
    }, [props.usuarioSeleccionado]);

    // AUTOSUGGEST USUARIOS

    //Cuando selecciono una sugerencia
    const onChange = (event, {newValue, method}) => {
        clearTimeout(timer.current);
        if (method === "type") {
            setValueUsuarios(newValue);
            if (newValue !== "" && newValue.length > 1)
                timer.current = setTimeout(() => triggerChange(), WAIT_INTERVAL);
        } else
            setValueUsuarios(newValue);
    };

    const onClear = (e) => {
        let ev = {target: {id: "idUsuario", value: null}};
        props.onChange(ev);
        setValueUsuarios("");
    };

    const WAIT_INTERVAL = 500;
    const triggerChange = () => {
        dispatch(resetUsuarios());
        dispatch(resetCliente())
        dispatch(invalidateUsuarios());

        dispatch(fetchUsuariosIfNeeded({
            searchPhase: valueUsuarios,
            registros: 0,
            idCuenta: 1
        }));
    };

    //Actualiza array suggestions de usuarios segun valor ingresado
    const onSuggestionsUpdateRequestedUsuarios = ({value}) => {
        setSuggestionsUsuarios(getSuggestionsUsuarios(value));
    };

    const onSuggestionsClearRequestedUsuarios = () => {
        setSuggestionsUsuarios([]);
    };

    //Obtiene sugerencias de usuarios de acuerdo a lo ingresado
    const getSuggestionsUsuarios = (value) => {
        const escapedValue = escapeRegexCharacters(value.trim());

        if (escapedValue == '') {
            return [];
        }

        const regex = new RegExp('\\b' + escapedValue, 'i');
        let suggestionsUsuarios = [];
        usuarios.allIds.map((idUsuario) => {
            let usuario = usuarios.byId.usuarios[idUsuario];
            if (usuario && regex.test(`${usuario.nombre} ${usuario.apellido} ${usuario.vd_codCliente}`)) {
                suggestionsUsuarios.push(usuario);
            }
        });
        return suggestionsUsuarios;
    };

    // Setea ids para que en render se busquen usuarios
    const getSuggestionValueMostrarUsuarios = (suggestion) => {
        let e = {target: {id: "idUsuario", value: suggestion.id}};
        props.onChange(e);
        return `${suggestion.nombre} ${suggestion.apellido} (${suggestion.vd_codCliente ? suggestion.vd_codCliente : "-"})`;
    };

    //Renderiza sugerencias de usuarios
    const renderSuggestionUsuarios = (suggestion, {query, isHighlighted}) => {
        const suggestionTextDniCod = `DNI: ${suggestion.dni}  |  Cod VD: ${suggestion.vd_codCliente ? suggestion.vd_codCliente : "-"}`;
        const matchesDniCod = match(suggestionTextDniCod, query);
        const partsDniCod = parse(suggestionTextDniCod, matchesDniCod);


        const suggestionText = `${suggestion.nombre} ${suggestion.apellido}`;
        const matches = match(suggestionText, query);
        const partsCod = parse(suggestionText, matches);

        return (
            <div>
                <div className="usuarioAutosuggest dniCod">
                    {
                        partsDniCod.map((part, index) => {
                            const className = part.highlight ? 'highlight' : null;

                            return (
                                <span className={className} key={index}><b>{part.text}</b></span>
                            );
                        })
                    }
                </div>
                <div className="usuarioAutosuggest">
                    {
                        partsCod.map((part, index) => {
                            const className = part.highlight ? 'highlight' : null;

                            return (
                                <span className={className} key={index}><b>{part.text}</b></span>
                            );
                        })
                    }
                </div>
                <hr className="hrUsuarios"/>
            </div>
        );
    };

    const escapeRegexCharacters = (str) => {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };


    const inputPropsUsuarios = {
        placeholder: "Búsqueda de Usuarios",
        value: valueUsuarios,
        onChange: onChange,
        disabled: props.disabled
    };

    return (
        <div className="autosuggest-usuarios">
            <Autosuggest
                suggestions={suggestionsUsuarios}
                id="autosuggest-navbar-usuarios"
                onSuggestionsFetchRequested={onSuggestionsUpdateRequestedUsuarios}
                onSuggestionsClearRequested={onSuggestionsClearRequestedUsuarios}
                getSuggestionValue={getSuggestionValueMostrarUsuarios}
                renderSuggestion={renderSuggestionUsuarios}
                inputProps={inputPropsUsuarios}/>
            <img className="botonCancelarBusquedaUsuario" src={botonCancelarNavegador}
                 onClick={onClear}
                 style={{display: props.usuarioSeleccionado && props.usuarioSeleccionado.id && !props.disabled ? "" : "none"}}
            />
        </div>

    );
}