import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

//api
import registro from "../api/registro";

//normalizer
import normalizeDato from "../normalizers/normalizeUsuarios";


//REGISTRO CREATE
export const CREATE_REGISTRO = 'CREATE_REGISTRO';
export const REQUEST_CREATE_REGISTRO = "REQUEST_CREATE_REGISTRO";
export const SUCCESS_CREATE_REGISTRO = "SUCCESS_CREATE_REGISTRO";
export const ERROR_CREATE_REGISTRO = "ERROR_CREATE_REGISTRO";
export const RESET_CREATE_REGISTRO = "RESET_CREATE_REGISTRO";


//CREATE
function requestCreateRegistro() {
    return {
        type: REQUEST_CREATE_REGISTRO,
    }
}

function receiveCreateRegistro(message) {
    return {
        type: SUCCESS_CREATE_REGISTRO,
        receivedAt: Date.now(),
        message: message
    }
}

export function errorCreateRegistro(error) {
    return {
        type: ERROR_CREATE_REGISTRO,
        error: error,
    }
}

export function resetCreateRegistro() {
    return {
        type: RESET_CREATE_REGISTRO
    }
}

export function createRegistro(registro) {
    return {
        type: CREATE_REGISTRO,
        registro
    }
}

export function saveCreateRegistro() {
    return (dispatch, getState) => {
        dispatch(requestCreateRegistro());
        return registro.saveCreate(getState().usuarios.byId.usuarioLogueado)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    response.json().then(
                        response => {
                            dispatch(receiveCreateRegistro(response.message));
                            return response;
                        }
                    )

                }
            })
            .then((registro) => {
                dispatch(resetCreateRegistro());
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateRegistro(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error != "")
                                    dispatch(errorCreateRegistro(error));
                                else
                                    dispatch(errorCreateRegistro(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorCreateRegistro(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}


//REGISTRO CREATE
export const UPDATE_REGISTRO = 'UPDATE_REGISTRO';
export const REQUEST_UPDATE_REGISTRO = "REQUEST_UPDATE_REGISTRO";
export const SUCCESS_UPDATE_REGISTRO = "SUCCESS_UPDATE_REGISTRO";
export const ERROR_UPDATE_REGISTRO = "ERROR_UPDATE_REGISTRO";
export const RESET_UPDATE_REGISTRO = "RESET_UPDATE_REGISTRO";


//UPDATE
function requestUpdateRegistro() {
    return {
        type: REQUEST_UPDATE_REGISTRO,
    }
}

function receiveUpdateRegistro(message) {
    return {
        type: SUCCESS_UPDATE_REGISTRO,
        receivedAt: Date.now(),
        message: message
    }
}

export function errorUpdateRegistro(error) {
    return {
        type: ERROR_UPDATE_REGISTRO,
        error: error,
    }
}

export function resetUpdateRegistro() {
    return {
        type: RESET_UPDATE_REGISTRO
    }
}

export function updateRegistro(registro) {
    return {
        type: UPDATE_REGISTRO,
        registro
    }
}

export function saveUpdateRegistro(dni) {
    return dispatch => {
        dispatch(requestUpdateRegistro());
        return registro.saveUpdate(dni)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    response.json().then(
                        response => {
                            dispatch(receiveUpdateRegistro(response.message));
                            return response;
                        }
                    )

                }
            })
            .then(() => {
                dispatch(resetUpdateRegistro());
                history.push("/checkin-pss/ok");
            })
            .catch(function (error) {
                history.push('/checkin-pss/error');
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateRegistro(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error != "")
                                    dispatch(errorUpdateRegistro(error));
                                else
                                    dispatch(errorUpdateRegistro(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorUpdateRegistro(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}