import {
    RECEIVE_CATEGORIA,
    RECEIVE_CATEGORIAS
} from '../actions/CategoriaActions';
import { combineReducers } from 'redux';
import {normalizeDatos,normalizeDato} from "../normalizers/normalizeCategorias";

function marcasById(state = {
    isFetching: false,
    didInvalidate: true,
    marcas: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                marcas: normalizeDatos(action.categorias).entities.marcas,
                lastUpdated: action.receivedAt
            });
        case RECEIVE_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                marcas: normalizeDato(action.categoria).entities.marcas,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allMarcas(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            let marcas1 = normalizeDatos(action.categorias).entities.marcas;
            return marcas1 ? Object.keys(marcas1) : [];
        case RECEIVE_CATEGORIA:
            let marcas2 = normalizeDato(action.categoria).entities.marcas;
            return marcas2 ? Object.keys(marcas2) : [];
        default:
            return state
    }
}


const marcas = combineReducers({
    byId : marcasById,
    allIds : allMarcas
});

export default marcas;