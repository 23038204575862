import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import "./BotonPago.css";
import {Col, Row} from "react-bootstrap";
//img
import compartir from "../../../../assets/img/pago/compartir.png";
import guardarAhora from "../../../../assets/img/pago/guardarAhora.png";
import Confirmado from "../../../../assets/img/pago/confirmado.png"
import anular from "../../../../assets/img/pago/rechazado.png"
//actions
import {resetLogoPago} from "../../../../actions/PagoActions";
import {fetchPlanesCuotasIfNeeded, resetPlanesCuotas} from "../../../../actions/PlanesCuotasActions";
import {fetchMediosPago, resetMediosPago} from "../../../../actions/MediosPagoActions";
import {fetchUsuarioLogueadoIfNeeded, resetUsuarios} from "../../../../actions/UsuarioActions";
//constants
import * as EstadoPago from "../../../../constants/EstadoPago";
//lodash
import max from "lodash/max";
import volver from "../../../../assets/img/pago/volver.png";
import AutosuggestUsuarios from "./AutosuggestUsuarios";
import * as roles from "../../../../constants/Roles";
import {
    createLinkPago,
    fetchFileLinkPago,
    fetchLinkPago,
    resetCreateLinkPago,
    resetLinksPagos,
    resetUpdateLinkPago,
    saveCreateLinkPago, saveDeleteLinkPago,
    saveUpdateLinkPago,
    updateLinkPago
} from "../../../../actions/LinkPagoActions";

import HeaderHerramienta from "../Layout/HeaderHerramienta";
import {
    fetchLogoByIdCliente,
    fetchLogoIfNeeded,
    updateCliente,
    saveUpdateClienteLogo,
    resetUpdateCliente
} from "../../../../actions/ClienteActions";

export default function LinkPagoRecurrenteCliente(props) {
    let {idLinkPago} = useParams();
    const dispatch = useDispatch();
    const mounted = useRef();
    const mounted2 = useRef();
    const mounted3 = useRef();
    const inputToken = useRef();
    const inputLogo = useRef();
    const inputImgProducto = useRef();
    const previewLogo = useRef();
    const previewImgProducto = useRef();
    //Store
    const planesCuotas = useSelector(state => state.planesCuotas);
    const mediosPago = useSelector(state => state.mediosPago);
    const usuarios = useSelector(state => state.usuarios);
    const usuarioLogueado = usuarios.byId.usuarioLogueado;
    const clienteUsuarioLogueado = usuarios.byId.clienteUsuarioLogueado;
    const estadosPago = useSelector(state => state.estadosPago);
    const linksPagos = useSelector(state => state.linksPagos);
    const linkPago = idLinkPago ? linksPagos.update.activo : linksPagos.create.nuevo;
    const clientes = useSelector(state => state.clientes);

    //State
    const [fileLogo, setFileLogo] = useState(null);
    const [fileImgProducto, setFileImgProducto] = useState(null);
    const [modalToken, setModalToken] = useState(false);
    const [modalValidarLogo, setModalValidarLogo] = useState(false);

    //Evaluar PERMISOS para editar
    var esEmpleadoKit = usuarioLogueado && (
        usuarioLogueado.idRol == roles.ADMINISTRACION_ID || usuarioLogueado.idRol == roles.ADMINISTRADOR_ID
        || usuarioLogueado.idRol == roles.VENDEDOR_ID);

    const guardandoLoading = idLinkPago ? linksPagos.update.isUpdating : linksPagos.create.isCreating;
    const disabled = guardandoLoading || linkPago.estado === 0;

    //Effects
    useEffect(() => {
        dispatch(fetchPlanesCuotasIfNeeded());
        dispatch(fetchMediosPago({estado: true, habilitadoLink: true}));
        if (idLinkPago)
            dispatch(fetchLinkPago(idLinkPago));

        //Por defecto tarjeta credito
        if (!idLinkPago) {
            let cambio = {};
            cambio.idMedioPago = 3;
            cambio.colorPrimario = "#00aaeb";
            cambio.colorSecundario = "#00ffea";
            dispatch(createLinkPago(cambio));
        }

        return function cleanup() {
            dispatch(resetLinksPagos());
            dispatch(resetMediosPago());
            dispatch(resetCreateLinkPago());
            dispatch(resetUpdateLinkPago());
            dispatch(resetUsuarios());
            dispatch(resetPlanesCuotas());
            dispatch(resetLogoPago());
            setFileLogo(null);
            previewLogo.current.src = "";
            dispatch(fetchLogoIfNeeded());
        }
    }, []);

    useEffect(() => {
        dispatch(fetchUsuarioLogueadoIfNeeded());
        let cambio = {};
        if (clienteUsuarioLogueado) {
            cambio.colorPrimario = clienteUsuarioLogueado.colorPrimario && !linkPago.colorPrimario ? clienteUsuarioLogueado.colorPrimario : linkPago.colorPrimario ? linkPago.colorPrimario : "#00aaeb";
            cambio.colorSecundario = clienteUsuarioLogueado.colorSecundario && !linkPago.colorSecundario ? clienteUsuarioLogueado.colorSecundario : linkPago.colorSecundario ? linkPago.colorSecundario : "#00ffea";
        }
        changeLinkPago(cambio);
    }, [clienteUsuarioLogueado])

    //Cuando creo un link de pago pasa a la lista de liks de pagos
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!linksPagos.create.isCreating && !linksPagos.create.error) {
                props.history.push("/panel/listaMonitoresPagos/1/monitorLinksPagos");
                window.scrollTo(0, 0);
            }
        }
    }, [linksPagos.create.isCreating]);


    //La primera vez cargo el logo
    useEffect(() => {
        if (!mounted3.current) {
            mounted3.current = true;
        } else {
            if (linkPago.logo) {
                dispatch(fetchFileLinkPago(linkPago.id, {type: "logo"}));
            }
            if (linkPago.imgProducto) {
                dispatch(fetchFileLinkPago(linkPago.id, {type: "producto"}));
            }
        }

    }, [linkPago.id]);

    //Hago la preview del logo del backend
    useEffect(() => {
        ["logo", "imgProducto"].forEach((i) => {
            let filename = linkPago[i];

            //Cargar foto
            let fileSubido = linksPagos.byId.files[filename];
            if (fileSubido) {
                let reader = new FileReader();
                reader.onloadend = function () {
                    if (i === "logo")
                        previewLogo.current.src = reader.result;
                    else
                        previewImgProducto.current.src = reader.result;
                };
                if (fileSubido) {
                    reader.readAsDataURL(fileSubido);
                }
                // setFileLogo(linksPagos.update.logo);
            }
        })

    }, [linksPagos.byId.files]);

    //Hago la preview del logo del cliente
    useEffect(() => {
        //Logo
        if (!idLinkPago)
            cargarLogoCliente();
    }, [usuarios.logo]);

    const handleChangeLinkPago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id === "idMedioPago")
            cambio[e.target.id] = e.target.checked ? parseInt(e.target.value) : null;
        if (e.target.id === "importe")
            cambio[e.target.id] = parseFloat(e.target.value) ? parseFloat(e.target.value) : null;
        if (e.target.id === "idPlanCuotas")
            cambio[e.target.id] = parseInt(e.target.value) ? parseInt(e.target.value) : null;
        if (e.target.id === "idUsuario")
            if (e.target.value && e.target.value !== "") {
                let usuario = usuarios.byId.usuarios[e.target.value];
                if (usuario) {
                    dispatch(fetchLogoByIdCliente(usuario.idGremio));
                }
                let cliente = clientes.byId.clientes[usuario.idGremio];
                if (cliente) {
                    cambio.colorPrimario = cliente.colorPrimario ? cliente.colorPrimario : "#00aaeb";
                    cambio.colorSecundario = cliente.colorSecundario ? cliente.colorSecundario : "#00ffea";
                }
            } else {
                dispatch(fetchLogoIfNeeded());
                if (clienteUsuarioLogueado) {
                    cambio.colorPrimario = clienteUsuarioLogueado.colorPrimario ? clienteUsuarioLogueado.colorPrimario : "#00aaeb";
                    cambio.colorSecundario = clienteUsuarioLogueado.colorSecundario ? clienteUsuarioLogueado.colorSecundario : "#00ffea";
                }
            }
        changeLinkPago(cambio);
    };

    const changeLinkPago = (cambio) => {
        if (!idLinkPago)
            dispatch(createLinkPago(cambio));
        else
            dispatch(updateLinkPago(cambio));
    };

    const handleChangeEstado = () => {
        dispatch(saveDeleteLinkPago(linkPago));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!idLinkPago) {
            let cambio = {};
            cambio.idTipoPago = 1;
            dispatch(createLinkPago(cambio));
            dispatch(saveCreateLinkPago([fileLogo, fileImgProducto]));
        } else {
            dispatch(saveUpdateLinkPago([fileLogo, fileImgProducto]));
        }
    };

    const validarLogo = (e) => {
        e.preventDefault();
        let cambio = {};
        cambio.validadoLogo = 1;
        dispatch(updateLinkPago(cambio));
        dispatch(saveUpdateLinkPago(null, fileLogo));
        setModalValidarLogo(false);
    };

    const copiarToken = (e) => {
        inputToken.current.select();
        inputToken.current.setSelectionRange(0, 99999999999); /*For mobile devices*/
        document.execCommand("copy");
    };

    const verLogo = (e) => {
        e.preventDefault();
        var file = inputLogo.current.files[0];
        let filename = file.name.replace(/[^a-zA-Z0-9]/g, '_');
        Object.defineProperty(file, 'name', {
            writable: true,
            value: filename
        });


        setFileLogo(file);

        let cambio = {}
        cambio.logo = filename
        changeLinkPago(cambio);

        var reader = new FileReader();
        reader.onloadend = function () {
            previewLogo.current.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            previewLogo.current.src = "";
        }
    };

    const verImgProducto = (e) => {
        e.preventDefault();
        var file = inputImgProducto.current.files[0];
        if (file) {
            let filename = file.name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(file, 'name', {
                writable: true,
                value: filename
            });
            setFileImgProducto(file);

            let cambio = {};
            cambio.imgProducto = filename;
            changeLinkPago(cambio);

            var reader = new FileReader();
            reader.onloadend = function () {
                previewImgProducto.current.src = reader.result;
            }
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            previewImgProducto.current.src = "";
        }

    };

    const cargarLogoCliente = () => {
        var preview = previewLogo.current;
        if (usuarios.logo.logo && usuarios.logo.logo.size > 0) {
            var file = usuarios.logo.logo;
            setFileLogo(file);
            let filename = file.name.replace(/[^a-zA-Z0-9]/g, '_');
            Object.defineProperty(file, 'name', {
                writable: true,
                value: filename
            });
            let cambio = {}
            cambio.logo = filename;
            changeLinkPago(cambio);
            var fileReader = new FileReader();
            fileReader.onload = function () {
                preview.src = this.result;
            };
            fileReader.readAsDataURL(file);
        }
    };

    const cargarImgProducto = () => {
        var preview = previewImgProducto.current;
        if (usuarios.logo.logo && usuarios.logo.logo.size > 0) {
            var file = usuarios.logo.logo;
            setFileLogo(file);
            var fileReader = new FileReader();
            fileReader.onload = function () {
                preview.src = this.result;
            };
            fileReader.readAsDataURL(file);
        }
    };

    return (
        <div className="boton-pago container-fluid">
            <HeaderHerramienta titulo={"Link de Pago Recurrente"}
                               texto={"Generá un link de pago único para enviarselo a todos tus clientes. Completando los siguientes datos el link quedará activado para utilizarlo múltiples veces."}/>
            <div className="container-presupuesto" style={{paddingTop: "25px"}}>
                <div className="linkpago">
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div>
                            {
                                esEmpleadoKit //|| (usuarioLogueado && usuarioLogueado.idRol === roles.DISTRIBUIDOR_ID))
                                &&
                                <Row className="container-formulario">
                                    <label htmlFor="idUsuario">Usuario al cual se le acreditará</label>
                                    <AutosuggestUsuarios onChange={handleChangeLinkPago}
                                                         disabled={disabled || !!idLinkPago}
                                                         usuarioSeleccionado={linkPago.idUsuario ? usuarios.byId.usuarios[linkPago.idUsuario] : null}/>
                                </Row>
                            }
                            <Row className="container-formulario">
                                <div className="input-row">
                                    <label htmlFor="importe">Importe en pesos (iva incluido)</label>
                                    <input id="importe" type="number" required
                                           name="importe"
                                           disabled={disabled}
                                           placeholder="Importe en pesos (iva incluido)"
                                           value={linkPago && linkPago.importe ? linkPago.importe : ""}
                                           onChange={(e) => handleChangeLinkPago(e)}
                                    />
                                </div>
                            </Row>
                            <Row className="container-formulario">
                                <h2>Forma de pago</h2>
                                {
                                    mediosPago.allIds.map((idMedioPago) => {
                                        let medioPago = mediosPago.byId.mediosPago[idMedioPago];
                                        return (
                                            <div key={"mp-" + idMedioPago}
                                                 style={{display: "flex", alignItems: "flex-end"}}>
                                                <div className="item-checkradio">
                                                    <input type="radio" name="idMedioPago"
                                                           id="idMedioPago"
                                                           className={disabled ? "disabled" : ""}
                                                           required
                                                           disabled={disabled}
                                                           checked={linkPago.idMedioPago === medioPago.id}
                                                           value={medioPago.id}
                                                           onChange={(e) => handleChangeLinkPago(e)}
                                                    />
                                                    <label>{medioPago ? medioPago.nomMedioPago : ""}</label>
                                                </div>
                                                {
                                                    medioPago && medioPago.codMedioPago === "TCR" &&
                                                    <div className="input-row" style={{marginLeft: "20px"}}>
                                                        <label htmlFor="idPlanCuotas">Elija un plan de cuotas
                                                            predeterminado
                                                            para que vea tu cliente</label>
                                                        <select
                                                            id="idPlanCuotas"
                                                            value={linkPago && linkPago.idPlanCuotas ? linkPago.idPlanCuotas : ""}
                                                            disabled={!linkPago || !linkPago.idMedioPago || linkPago.idMedioPago != 3 || disabled}
                                                            onChange={(e) => handleChangeLinkPago(e)}
                                                        >
                                                            <option value="">Todos</option>
                                                            {
                                                                planesCuotas.allIds.map((id) => {
                                                                    let planCuotas = planesCuotas.byId.planesCuotas[id];
                                                                    return (
                                                                        <option key={id}
                                                                                value={planCuotas.id}>{planCuotas.desPlan}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <br/>
                                <div>
                                    <p>
                                        Los montos difieren de acuerdo a los intereses aplicados según los tipos de tarjeta y cantidad de cuotas.
                                    </p>
                                    <p>
                                        Puede verificarlos una vez generado el Link de Pago, si lo desea luego puede modificarlo ingresando nuevamente a esta sección.
                                    </p>
                                </div>
                                {/*{*/}
                                {/*    linkPago && linkPago.importe && linkPago.idPlanCuotas && planesCuotas.byId.planesCuotas[linkPago.idPlanCuotas] &&*/}
                                {/*    <div>*/}
                                {/*        <p>{`El importe total ga cobrar a tu cliente es de $${(linkPago.importe * planesCuotas.byId.planesCuotas[linkPago.idPlanCuotas].coeficiente).toFixed(2)}`}</p>*/}
                                {/*        <p>{`Lo va a pagar en ${planesCuotas.byId.planesCuotas[linkPago.idPlanCuotas].cuotas} cuotas de $${((linkPago.importe * planesCuotas.byId.planesCuotas[linkPago.idPlanCuotas].coeficiente) / planesCuotas.byId.planesCuotas[linkPago.idPlanCuotas].cuotas).toFixed(2)}`}</p>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {
                                    linkPago && linkPago.importe &&
                                    <p>{`Con la transacción finalizada se acreditará la suma de $${linkPago.importe}`}. Lo podrá visualizar en el botón de pagos.</p>
                                }
                            </Row>
                            <Row className="container-formulario">
                                <h2>Referencia del servicio que se va a cobrar</h2>
                                <Col xs={12} md={8}>
                                    <div className="input-row">
                                        <label htmlFor="nombre">Describa el servicio a cobrar</label>
                                        <textarea id="referencia" required
                                                  name="referencia"
                                                  disabled={disabled}
                                                  placeholder="Ej: Sistema de alarma compuesto por dos infrarojos, un magnético y una sirena interior."
                                                  value={linkPago && linkPago.referencia ? linkPago.referencia : ""}
                                                  onChange={(e) => handleChangeLinkPago(e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={4}>
                                    <div className="logo">
                                        <img ref={previewImgProducto}/>
                                        <button type="button" onClick={() => inputImgProducto.current.click()}>
                                            Cargar foto de producto
                                        </button>
                                    </div>
                                    <input type="file" ref={inputImgProducto} accept="image/png, image/jpeg"
                                           style={{display: "none"}}
                                           multiple={false}
                                           disabled={disabled} onChange={(e) => verImgProducto(e)}/>
                                </Col>
                            </Row>
                            <Row className="container-formulario">
                                <h2>Personalizar link de pago</h2>
                                <Col xs={12} md={12} style={{display: "flex", alignItems: "center"}}>
                                    <div className="logo personalizar" style={{marginRight: "15px"}}>
                                        <img ref={previewLogo}/>
                                        <button type="button" onClick={() => inputLogo.current.click()}>
                                            Cargar logo
                                        </button>
                                    </div>
                                    <input type="file" ref={inputLogo} accept="image/png, image/jpeg"
                                           style={{display: "none"}}
                                           multiple={false}
                                           disabled={disabled} onChange={(e) => verLogo(e)}/>
                                    <div className="colores">
                                        <div className="input-row" style={{marginRight: "5px"}}>
                                            <label htmlFor="nombre">Color primario</label>
                                            <input type="color" id="colorPrimario"
                                                   value={linkPago && linkPago.colorPrimario ? linkPago.colorPrimario : ""}
                                                // value={colorPrimario}
                                                   disabled={disabled} onChange={(e) => handleChangeLinkPago(e)}/>
                                        </div>
                                        <div className="input-row" style={{marginLeft: "5px"}}>
                                            <label htmlFor="nombre">Color secundario</label>
                                            <input type="color" id="colorSecundario"
                                                   value={linkPago && linkPago.colorSecundario ? linkPago.colorSecundario : ""}
                                                // value={colorSecundario}
                                                   disabled={disabled} onChange={(e) => handleChangeLinkPago(e)}/>
                                        </div>
                                    </div>

                                </Col>

                            </Row>
                            <Row>
                                <div className="botonera">
                                    <div className="botonera-izq">
                                        <button type="button" className="boton-redondo outline"
                                                onClick={() => props.history.push("/panel/listaMonitoresPagos/1/monitorLinksPagos")}>
                                            <img src={volver}/>
                                            Volver
                                        </button>
                                        {
                                            !!idLinkPago &&
                                            <button type="button" className="boton-redondo"
                                                    onClick={() => setModalToken(true)}>
                                                <img src={compartir}/>
                                                Compartir
                                            </button>
                                        }
                                        {
                                            !!idLinkPago && esEmpleadoKit &&
                                            <button type="button" className="boton-redondo outline"
                                                    onClick={() => setModalValidarLogo(true)}
                                                    disabled={linkPago.validadoLogo === 1}>
                                                <img src={Confirmado}/>
                                                Validar logo
                                            </button>
                                        }
                                    </div>
                                    <div className="botonera-der">
                                        <button type="button" className="boton-redondo" onClick={handleChangeEstado}
                                                disabled={linksPagos.update.isUpdating || linksPagos.create.isCreating}
                                        >
                                            <img src={guardarAhora}/>
                                            {linkPago.estado === 1 ? "Deshabilitar" : "Habilitar"}
                                        </button>
                                        <button type="submit" className="boton-redondo"
                                                disabled={disabled || linksPagos.update.isUpdating || linksPagos.create.isCreating}
                                        >
                                            <img src={guardarAhora}/>
                                            Guardar link
                                        </button>
                                    </div>
                                </div>

                            </Row>
                        </div>
                    </form>
                    {
                        modalToken &&
                        <div className="modal-token">
                            <h2>Copia el enlace para compartir el link de pago</h2>
                            <Row>
                                <div className="input-row">
                                    <input id="token" ref={inputToken} type="text" required
                                           name="token"
                                           placeholder="token"
                                           readOnly
                                           value={linkPago && linkPago.token ? "https://linkdepago.mialarma.com.ar/" + linkPago.token : ""}
                                    />
                                </div>
                                {
                                    linkPago && linkPago.validadoLogo === 0 &&
                                    <i>
                                        Su link funcionará sin logo hasta que la plataforma de Kit Experto lo autorice
                                        dentro de las 24hs
                                    </i>
                                }

                            </Row>
                            <Row>
                                <div className="botonera">
                                    <div className="botonera-der"></div>
                                    <div className="botonera-izq">
                                        <button className="boton-default" onClick={() => setModalToken(false)}>
                                            Cerrar
                                        </button>
                                        <button className="boton-default" onClick={copiarToken}>
                                            Copiar
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    }

                    {
                        modalValidarLogo &&
                        <div className="modal-token">
                            <h2>Está seguro que desea autorizar el logo del link de pago?</h2>
                            <Row>
                                <div className="botonera">
                                    <div className="botonera-der"></div>
                                    <div className="botonera-izq">
                                        <button className="boton-default"
                                                onClick={() => setModalValidarLogo(false)}>
                                            Cerrar
                                        </button>
                                        <button className="boton-default" onClick={validarLogo}>
                                            Validar
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}
