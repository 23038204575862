import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

//Images
import imgSierra from '../../../assets/img/evento/eventoRoad/sierraLearningCenter.png';

import '../../../assets/css/RegistroEventoManianaRoad.css';
import FieldGroup from "../../camposTexto/FieldGroup";

import {
    changeUsuarioLogueado,
    fetchUsuarioIfNeeded, invalidateUsuario, resetCreateUsuario,
    resetUpdateUsuarioLogueado
} from "../../../actions/UsuarioActions";
import {saveCreateRegistro,resetCreateRegistro} from "../../../actions/RegistroActions";
import FieldGroupSubmit from "../../camposTexto/FieldGroupSubmit";

import isObject from "lodash/isObject";
import isString from "lodash/isString";


import Loader from '../../elementos/loader/Loader';
import FieldTelefono from "../../camposTexto/FieldTelefono";

import {FormControl, FormGroup, Checkbox, Radio} from "react-bootstrap";

class RegistroEventoManianaRoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: "",
            idEvento: "",
            idRol: "",
            deshabilitarRegistro: false,
            permiteOnChange: false,
        };

        this.submitForm = this.submitForm.bind(this);
        this.submitDatos = this.submitDatos.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.registros.create.error != prevProps.registros.create.error && this.props.registros.create.error) {
            this.setState({deshabilitarRegistro: false});
        }

        if(this.props.usuarios.byId.error != prevProps.usuarios.byId.error && this.props.usuarios.byId.error) {
            this.setState({permiteOnChange: true});
        }
    }

    submitForm(e) {
        e.preventDefault();
        this.props.invalidateUsuario();
        this.props.resetCreateUsuario();
        this.props.resetUpdateUsuarioLogueado();
        this.props.resetCreateRegistro();
        this.setState({idRol: ""});
        this.props.fetchUsuarioIfNeeded(this.state.dni);
        this.refs.datos.classList.add("in");
    }

    submitDatos(e) {
        e.preventDefault();
        if (!this.props.usuarios.byId.usuarioLogueado.dni) {
            this.props.changeUsuarioLogueado({dni: this.state.dni});
        }
        this.props.saveCreateRegistro();
        this.setState({deshabilitarRegistro: true});
    }

    changeDni(e) {
        this.setState({dni: e.target.value});
        console.log(this.state);
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.changeUsuarioLogueado(cambio);
    }


    render() {
        const usuario = this.props.usuarios.byId.usuarioLogueado;
        const {deshabilitarRegistro, permiteOnChange, idRol} = this.state;
        console.log(usuario);

        const error = this.props.registros.create.error;

        var msgError = null;

        if (error && isObject(error.message) && !isString(error.message)) {
            msgError = Object.keys(error.message).map(function (key, index) {
                return (<li>{error.message[key]}</li>);
            });
        } else {
            msgError = <p>{error ? error.message : ""}</p>;
        }

        return (
            <div className="evento-pss-road">
                <div className="container-limit">
                    <Row className="headerRoad">
                        <img src={imgSierra}/>
                        <div className="textoTitulo">
                            <h2>CAPACITACION CONSTANTE</h2>
                            <h4>Porque tus clientes merecen el mejor servicio</h4>
                        </div>
                    </Row>
                    <Row className="seccion-formulario">
                        <Col md={12}>
                            <form ref="asd" onSubmit={(e) => {
                                this.submitForm(e)
                            }}>
                                <FieldGroupSubmit
                                    id="dni"
                                    type="number"
                                    placeholder="DNI:"
                                    required={true}
                                    help={this.props.usuarios.byId.error ? this.props.usuarios.byId.error
                                        : usuario.dni ? "Ya estás en nuestro registro de datos " + usuario.nombre + " " + usuario.apellido :
                                            ""
                                    }
                                    value={this.state.dni}
                                    onChange={(e) => {
                                        this.changeDni(e)
                                    }}
                                />
                            </form>
                            <form onSubmit={e => {
                                this.submitDatos(e)
                            }}>
                                <div className="datos collapse" ref="datos">
                                    <FieldGroup id="idEvento"
                                                componentClass="select"
                                                required={true}
                                                value={usuario.idEvento ? usuario.idEvento : ""}
                                                onChange={(e) => {
                                                    this.onChangeUsuario(e)
                                                }}>
                                        <option value="">Seleccione curso</option>
                                        <option value="37">DIA 1 Curso: 27/8 15.30 - CCTV Easy Configuration</option>
                                        <option value="38">DIA 1 Curso: 27/8 17.00 - Sistema 5000IP – Video Portero IP
                                            con Automatización
                                        </option>
                                        <option value="39">DIA 1 Curso: 27/8 18.00 - DVRs Móviles</option>
                                        <option value="40">DIA 2 Curso: 28/8 15.30 - CCTV Easy Configuration</option>
                                        <option value="41">DIA 2 Curso: 28/8 17.00 - Audio Profesional</option>
                                        <option value="42">DIA 2 Curso: 28/8 18.00 - Sistema 800 – Sistema de
                                            Intercomunicador con 2 hilos
                                        </option>
                                        <option value="43">DIA 3 Curso: 29/8 15.30 - CCTV Easy Configuration</option>
                                        <option value="44">DIA 3 Curso: 29/8 17.00 - SAC 4000 – Integración de Acceso
                                            con Video IP
                                        </option>
                                        <option value="45">DIA 3 Curso: 29/8 18.00 - Cámara LPR y análisis de video
                                            incorporado
                                        </option>
                                        <option value="46">Evento Prueba</option>
                                    </FieldGroup>
                                    <FieldGroup
                                        id="nombre"
                                        type="text"
                                        placeholder="Nombre:"
                                        required={true}
                                        value={usuario.nombre ? usuario.nombre : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />

                                    <FieldGroup
                                        id="apellido"
                                        type="text"
                                        placeholder="Apellido:"
                                        required={true}
                                        value={usuario.apellido ? usuario.apellido : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />

                                    <FieldTelefono
                                        id="telefono"
                                        autocomplete="off"
                                        type="text"
                                        required={true}
                                        placeholder="Teléfono:"
                                        disabled={false}
                                        value={usuario.telefono ? (usuario.telefono == null ? "" : usuario.telefono) : ""}
                                        options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                        style={{paddingLeft: "10px"}}
                                    />

                                    <FieldGroup
                                        id="email"
                                        type="email"
                                        require={true}
                                        placeholder="Mail:"
                                        value={usuario.email ? usuario.email : ""}
                                        onChange={(e) => {
                                            this.onChangeUsuario(e)
                                        }}
                                    />
                                    <div className="checkboxSection" style={{display: usuario.id ? "none" : "flex"}}>
                                        <label>

                                            <input type="radio" name="1"
                                                   id="idRol"
                                                   value={3}
                                                   checked={usuario.idRol ? (usuario.idRol == 3 ? true : false) : idRol == 3 ? true : false}
                                                   onChange={(e) => {
                                                       if(permiteOnChange) {
                                                           this.setState({idRol: 3});
                                                           this.onChangeUsuario(e);
                                                       }
                                                   }}
                                                   disabled={usuario.id}
                                                   required={!usuario.id}/>
                                            <span></span>
                                            <p>Gremio</p>
                                        </label>
                                        <label>
                                            <input type="radio" name="1"
                                                   id="idRol"
                                                   value={2}
                                                   checked={usuario.idRol ? (usuario.idRol == 2 ? true : false) : idRol == 2 ? true : false}
                                                   onChange={(e) => {
                                                       if(permiteOnChange) {
                                                           this.setState({idRol: 2});
                                                           this.onChangeUsuario(e);
                                                       }
                                                   }}
                                                   disabled={usuario.id}
                                                   required={!usuario.id}/>
                                            <span></span>
                                            <p>Cons. Final</p>
                                        </label>
                                        <label>
                                            <input type="radio" name="1"
                                                   id="idRol"
                                                   value={4}
                                                   checked={usuario.idRol ? (usuario.idRol == 4 ? true : false) : idRol == 4 ? true : false}
                                                   onChange={(e) => {
                                                       if(permiteOnChange) {
                                                           this.setState({idRol: 4});
                                                           this.onChangeUsuario(e);
                                                       }
                                                   }}
                                                   disabled={usuario.id}
                                                   required={!usuario.id}/>
                                            <span></span>
                                            <p>Corporativo</p>
                                        </label>
                                    </div>
                                    {
                                        this.props.registros.create.isCreating ?
                                            <Loader/>
                                            :
                                            <Button type="submit" className="center-block boton">Enviar</Button>
                                    }
                                    <p className="registro-error">{msgError ? msgError : ""}</p>

                                    <p className="registro-error">{this.props.registros.create.message ? this.props.registros.create.message : ""}</p>
                                </div>
                                <br/>

                            </form>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        registros: state.registros
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsuarioIfNeeded: (dni) => {
            dispatch(fetchUsuarioIfNeeded(dni));
        },
        resetCreateUsuario: () => {
            dispatch(resetCreateUsuario())
        },
        resetUpdateUsuarioLogueado: () => {
            dispatch(resetUpdateUsuarioLogueado())
        },
        invalidateUsuario: () => {
            dispatch(invalidateUsuario());
        },
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario));
        },
        saveCreateRegistro: () => {
            dispatch(saveCreateRegistro());
        },
        resetCreateRegistro: () =>{
            dispatch(resetCreateRegistro());
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistroEventoManianaRoad));
