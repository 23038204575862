import {
    CREATE_USUARIO,
    CREATE_USUARIO_LOGUEADO,
    ERROR_CREATE_USUARIO,
    ERROR_CREATE_USUARIO_LOGUEADO,
    ERROR_UPDATE_USUARIO,
    ERROR_UPDATE_USUARIO_LOGUEADO,
    ERROR_USUARIO,
    ERROR_USUARIO_LOGUEADO,
    ERROR_USUARIO_POR_CAMPO,
    ERROR_USUARIOS,
    INVALIDATE_USUARIO,
    INVALIDATE_USUARIO_LOGUEADO,
    INVALIDATE_USUARIO_POR_CAMPO,
    INVALIDATE_USUARIOS,
    RECEIVE_USUARIO,
    RECEIVE_USUARIO_LOGUEADO,
    RECEIVE_USUARIO_POR_CAMPO,
    RECEIVE_USUARIOS,
    REQUEST_CREATE_USUARIO,
    REQUEST_CREATE_USUARIO_LOGUEADO,
    REQUEST_UPDATE_USUARIO,
    REQUEST_UPDATE_USUARIO_LOGUEADO,
    REQUEST_USUARIO,
    REQUEST_USUARIO_LOGUEADO,
    REQUEST_USUARIO_POR_CAMPO,
    REQUEST_USUARIOS,
    RESET_CREATE_USUARIO,
    RESET_CREATE_USUARIO_LOGUEADO,
    RESET_UPDATE_USUARIO,
    RESET_UPDATE_USUARIO_LOGUEADO,
    RESET_USUARIOS,
    SUCCESS_CREATE_USUARIO,
    SUCCESS_CREATE_USUARIO_LOGUEADO,
    SUCCESS_UPDATE_USUARIO,
    SUCCESS_UPDATE_USUARIO_LOGUEADO,
    UPDATE_USUARIO,
    UPDATE_USUARIO_LOGUEADO
} from '../actions/UsuarioActions';
import {combineReducers} from 'redux';

import {ERROR_LOGO, INVALIDATE_LOGO, RECEIVE_LOGO, REQUEST_LOGO, UPDATE_LOGO} from '../actions/ClienteActions';


import merge from "lodash/merge";
import {RECEIVE_PREGUNTAS} from "../actions/PreguntasActions";
import normalizeDatos from "../normalizers/normalizePreguntas";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import {RECEIVE_PAGO, RECEIVE_PAGOS} from "../actions/PagoActions";
import {RECEIVE_LINKPAGO, RECEIVE_LINKSPAGOS} from "../actions/LinkPagoActions";


function usuariosById(state = {
    isFetchingUsuarioLogueado: false,
    isFetchingUsuarios: false,
    didInvalidate: true,
    usuarioLogueado: {},
    usuarios: [],
    usuarioExistente: false,
    didInvalidatePorCampo: true,
    isFetchingUsuarioPorCampo: false,
    error: null,
}, action) {
    switch (action.type) {
        //GET USUARIO LOGUEADO
        case INVALIDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: true,
                didInvalidate: false,
                error: null,
            });
        case ERROR_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                didInvalidate: true,
                error: action.error
            });
        case RECEIVE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                didInvalidate: false,
                usuarioLogueado: action.usuario ? action.usuario.entities.usuarios[action.usuario.result] : {},
                clienteUsuarioLogueado: action.usuario && action.usuario.entities && action.usuario.entities.clientes && action.usuario.entities.usuarios[action.usuario.result] ? action.usuario.entities.clientes[action.usuario.entities.usuarios[action.usuario.result].idGremio] : {},
                lastUpdated: action.receivedAt,
                error: null
            });
        //REGISTRO USUARIOLOGUEADO NUEVO
        case CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                usuarioLogueado: merge({}, state.usuarioLogueado, action.usuario),
                error: null,
            });
        case RESET_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                usuarioLogueado: {},
                clienteUsuarioLogueado: {}
            });
        //UPDATE USUARIO PERFIL
        case UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                usuarioLogueado: merge({}, state.usuarioLogueado, action.usuario),
                error: null,
            });
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetchingUsuarioLogueado: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                usuarioLogueado: {},
                clienteUsuarioLogueado: {}
            });
        //GET ALL USUARIOS BY CUENTA
        case INVALIDATE_USUARIOS:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        //USUARIOS BY CUENTA
        case REQUEST_USUARIOS:
            return Object.assign({}, state, {
                isFetchingUsuarios: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_USUARIOS:
            return Object.assign({}, state, {
                isFetchingUsuarios: false,
                didInvalidate: false,
                usuarios: action.usuarios.entities.usuarios ? action.usuarios.entities.usuarios : {},
                lastUpdated: action.receivedAt,
                error: null
            });
        case ERROR_USUARIOS:
            return Object.assign({}, state, {
                isFetchingUsuarios: false,
                didInvalidate: true,
                error: action.error,
            });
        case RESET_USUARIOS:
            return Object.assign({}, state, {
                isFetchingUsuarios: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                usuarios: [],
            });
        // todo preguntas de evento
        case RECEIVE_PREGUNTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                error: null,
                usuarios: normalizeDatos(action.preguntas).entities.usuarios
            });
        //USUARIO POR CAMPO
        case INVALIDATE_USUARIO_POR_CAMPO:
            return Object.assign({}, state, {
                didInvalidatePorCampo: true,
                error: null,
            });
        case REQUEST_USUARIO_POR_CAMPO:
            return Object.assign({}, state, {
                isFetchingUsuarioPorCampo: true,
                didInvalidate: false,
                error: null,
            });
        case ERROR_USUARIO_POR_CAMPO:
            return Object.assign({}, state, {
                isFetchingUsuarioPorCampo: false,
                didInvalidatePorCampo: true,
                error: action.error,
            });
        case RECEIVE_USUARIO_POR_CAMPO:
            return Object.assign({}, state, {
                isFetchingUsuarioPorCampo: false,
                didInvalidatePorCampo: false,
                usuarioExistente: action.usuarioExistente ? action.usuarioExistente : {},
                lastUpdated: action.receivedAt,
                error: null
            });

        //PAGOS
        case RECEIVE_PAGOS:
            return Object.assign({}, state, {
                usuarios: merge({}, state.usuarios, action.pagos.entities && action.pagos.entities.usuarios ? action.pagos.entities.usuarios : {}),
                error: null
            });
        case RECEIVE_PAGO:
            return Object.assign({}, state, {
                usuarios: merge({}, state.usuarios, action.pago.entities && action.pago.entities.usuarios ? action.pago.entities.usuarios : {}),
                error: null
            });
        //LINKS PAGOS
        case RECEIVE_LINKSPAGOS:
            return Object.assign({}, state, {
                usuarios: merge({}, state.usuarios, action.linksPagos.entities && action.linksPagos.entities.usuarios ? action.linksPagos.entities.usuarios : {}),
                error: null
            });
        case RECEIVE_LINKPAGO:
            return Object.assign({}, state, {
                usuarios: merge({}, state.usuarios, action.linkPago.entities && action.linkPago.entities.usuarios ? action.linkPago.entities.usuarios : {}),
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetchingUsuarioPorCampo: false,
                didInvalidatePorCampo: true,
                usuarioLogueado: {},
                usuarios: [],
                usuarioExistente: false,
                error: null,
            });
        default:
            return state
    }
}

function usuariosAllIds(state = [], action) {
    switch (action.type) {
        case RECEIVE_USUARIOS:
            return action.usuarios.result ? action.usuarios.result : [];
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    usuarioNuevo: false,
    nuevo: {},
    error: ""
}, action) {
    switch (action.type) {
        //REGISTRO NUEVO USUARIOLOGUEADO
        case CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: false,
                usuarioNuevo: true,
                error: null,
            });
        case REQUEST_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: false,
                usuarioNuevo: false,
                error: null,
            });
        case ERROR_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                usuarioNuevo: false
            });
        //REGISTRO NUEVO
        case CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                usuarioNuevo: true,
                nuevo: merge({}, state.nuevo, action.usuario),
                error: null,
            });
        case REQUEST_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                usuarioNuevo: false,
                error: null,
            });
        case ERROR_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                usuarioNuevo: false
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                usuarioNuevo: false,
                error: "",
                nuevo: {}
            });
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    isFetching: false,
    usuarioModificado: false,
    activo: {},
    error: null
}, action) {
    switch (action.type) {
        //GET ONE USUARIO ACTIVO
        case INVALIDATE_USUARIO:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_USUARIO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case ERROR_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RECEIVE_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: action.usuario ? action.usuario.entities.usuarios[action.usuario.result] : {},
                lastUpdated: action.receivedAt,
                error: null
            });
        //UPDATE USUARIO ACTIVO
        case UPDATE_USUARIO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: merge({}, state.activo, action.usuario),
                error: null
            });
        case RESET_UPDATE_USUARIO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: null,
            });
        case REQUEST_UPDATE_USUARIO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_USUARIO:
            return Object.assign({}, state, {
                isUpdating: false,
                // activo: false, //todo
                error: null
            });
        case ERROR_UPDATE_USUARIO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        //UPDATE USUARIO LOGUEADO
        case UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: false,
                usuarioModificado: true,
                error: null
            });
        case REQUEST_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: false,
                usuarioModificado: false,
                error: null
            });
        case ERROR_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                usuarioModificado: false,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                usuarioModificado: false,
                activo: {},
                error: null,
            });
        default:
            return state
    }
}

function logo(state = {
    isFetching: false,
    didInvalidate: true,
    logo: null,
    error: null,
}, action) {
    switch (action.type) {
        case INVALIDATE_LOGO:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_LOGO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_LOGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                logo: action.logo,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case UPDATE_LOGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                logo: action.logo,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_LOGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                logo: null,
                error: null,
            });
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                logo: null,
                error: null,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                logo: null,
                error: null,
            });
        default:
            return state
    }
}


const usuarios = combineReducers({
    allIds: usuariosAllIds,
    byId: usuariosById,
    create: create,
    update: update,
    logo: logo,
});

export default usuarios;