import {
    ERROR_PARTICIPANTES,
    INVALIDATE_PARTICIPANTES,
    RECEIVE_PARTICIPANTES,
    REQUEST_PARTICIPANTES
} from '../actions/ParticipantesActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";

import normalizeDatos from '../normalizers/normalizeParticipantes';

function participantesById(state = {
    isFetching: false,
    didInvalidate: true,
    participantes: [],
}, action) {
    switch (action.type) {
        case INVALIDATE_PARTICIPANTES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PARTICIPANTES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PARTICIPANTES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                participantes: normalizeDatos(action.participantes).entities.participantes,
                lastUpdated: action.receivedAt
            });
        case ERROR_PARTICIPANTES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allParticipantes(state = [], action) {
    switch (action.type) {
        case RECEIVE_PARTICIPANTES:
            return normalizeDatos(action.participantes).result;
        default:
            return state
    }
}

const participantes = combineReducers({
    byId: participantesById,
    allIds: allParticipantes,
});

export default participantes;