import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


class OkCheckIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        return (
            <Row className="start-sorteo">
                <Col md={12} className="center-block">
                    <h1 style={{color:"white", fontSize:"75px"}}>!Gracias por venir!</h1>
                    <p>Los mejores proyectos se hacen <span style={{color:"#00ffeb"}}> en equipo</span></p>
                    <div className="lineaCorta"></div>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {};
}


const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OkCheckIn));
