import {
    RECEIVE_PEDIDOS, RESET_PEDIDOS
} from '../actions/PedidoActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";
import union from "lodash/union";
import {RECEIVE_ACCESOSCUENTA} from "../actions/AccesosCuentaActions";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

function estadosPedidoById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosPedido: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                estadosPedido: merge({}, state.estadosPedido, action.pedidos.entities.estadosPedido),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                estadosPedido: [],
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                estadosPedido: [],
            });
        default:
            return state
    }
}


function allEstadosPedido(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const estadosPedido = combineReducers({
    byId: estadosPedidoById,
    allIds: allEstadosPedido,
});

export default estadosPedido;