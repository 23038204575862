import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){

    // const moneda = new schema.Entity('moneda',{},{idAttribute:"id"});
    const listaPrecio = new schema.Entity('listaPrecio',{}, {idAttribute:"id"});
    const mySchema = [ listaPrecio ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;