import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import {connect} from 'react-redux';

//CSS
import '../../assets/css/SliderMarca.css';

import loadImg from '../../assets/img/loader.gif';


class SliderMarca extends Component {
    render() {
        var Marcas = [];
        this.props.marcas.allIds.some((idMarca) => {
            let marca = this.props.marcas.byId.marcas[idMarca];
            if (marca && marca.visible && marca.pathImagen) {
                try {
                    const svg = require("!raw-loader!../../assets/img/marcas/" + marca.pathImagen);
                    if (svg)
                        var pathmarca = <span className="marcaSlider" dangerouslySetInnerHTML={{__html: svg}}/>;
                } catch (e) {
                }
                {/*<div className="item" style={{padding: marca.desMarca=="Kit"? "80px": "50px", paddingTop: marca.desMarca=="Kit"? "30px": "80px"}}>*/
                }
                // style={{padding: marca.desMarca == "Kit" ? "49px 95px 95px" : "88px 95px 95px"}}
                Marcas.push(
                    <div key={marca.id} className="item" style={{height: "214px", paddingTop: "60px"}}>
                        {pathmarca}
                    </div>
                )
            }
        });

        var Loader = () => {
            return (
                <div style={{textAlign: "center", float: "none", margin: "0 auto"}}><img style={{width: "32px"}}
                                                                                         src={loadImg} alt=""/></div>
            );
        }

        return (
            <div>
                <div className="sliderMarca">
                    {
                        Marcas.length > 0 ?
                            <OwlCarousel className="owl-theme"
                                         loop
                                         nav
                                         autoplay
                                         items={4}
                                         margin={2}
                                         navText=""
                                         responsive={
                                             {
                                                 0: {
                                                     items: 3
                                                 },
                                                 // breakpoint from 480 up
                                                 480: {
                                                     items: 3
                                                 },
                                                 // breakpoint from 768 up
                                                 768: {
                                                     items: 3
                                                 },
                                                 // breakpoint from 768 up
                                                 991: {
                                                     items: 4
                                                 }
                                             }
                                         }>
                                {Marcas}
                            </OwlCarousel>
                    : ""}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        marcas: state.marcas
    };
}


export default connect(mapStateToProps)(SliderMarca);
