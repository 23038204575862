import {
    RECEIVE_PEDIDOS, RESET_PEDIDOS
} from '../actions/PedidoActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";
import union from "lodash/union";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

function remitosById(state = {
    isFetching: false,
    didInvalidate: true,
    remitos: {},
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                remitos: merge({}, state.remitos, action.pedidos.entities.remitos),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                remitos: {},
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                remitos: {},
            });
        default:
            return state
    }
}

function allRemitos(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

const remitos = combineReducers({
    byId: remitosById,
    allIds: allRemitos,
});

export default remitos;