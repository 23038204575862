import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});
    //const detalleProducto = new schema.Entity('marcas',{}, {idAttribute: "codigo_vd"});
    //const precioPromocional = new schema.Entity('preciosPromocionales',{}, {idAttribute: "id"});
    const kit = new schema.Entity('kits', {}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {marca: marca, kit: [kit]}, {idAttribute: "id"});
    const subCategoria = new schema.Entity('subCategorias', {producto: [producto]}, {idAttribute: "id"});
    const categoria = new schema.Entity('categorias', {sub_categoria: [subCategoria]}, {idAttribute: "id"});
    const mySchema = [categoria];
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function normalizeDato(myData) {
    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});
    //const detalleProducto = new schema.Entity('marcas',{}, {idAttribute: "codigo_vd"});
    //const precioPromocional = new schema.Entity('preciosPromocionales',{}, {idAttribute: "id"});
    const kit = new schema.Entity('kits', {}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {marca: marca, kit: [kit]}, {idAttribute: "id"});
    // const subCategoria = new schema.Entity('subCategorias', {producto: [producto]}, {idAttribute: "id"});
    const categoria = new schema.Entity('categorias', {producto: [producto]}, {idAttribute: "id"});
    const mySchema = categoria;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}