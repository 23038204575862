import React from 'react';

import '../../../../assets/css/ProgesoCompra.css';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import history from "../../../../history";

class ProgesoCompra extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        this.changePaso(this.props.numero);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.paso != this.props.match.params.paso) {
            this.changePaso(this.props.numero);
        }
    }

    changePaso(numero) {
        if (numero == 1) {
            this.refs.paso1.classList.remove("done");
            this.refs.paso2.classList.remove("done");
            // this.refs.paso3.classList.remove("done");
            this.refs.paso1.classList.add("active");
            this.refs.paso2.classList.remove("active");
            // this.refs.paso3.classList.remove("active");
        } else if (numero == 2) {
            this.refs.paso1.classList.add("done");
            this.refs.paso2.classList.remove("done");
            // this.refs.paso3.classList.remove("done");
            this.refs.paso1.classList.remove("active");
            this.refs.paso2.classList.add("active");
            // this.refs.paso3.classList.remove("active");
        }
        // else if (numero == 3) {
        //     this.refs.paso1.classList.add("done");
        //     this.refs.paso2.classList.add("done");
        //     this.refs.paso3.classList.remove("done");
        //     this.refs.paso1.classList.remove("active");
        //     this.refs.paso2.classList.remove("active");
        //     this.refs.paso3.classList.add("active");
        // } else if (numero == 4) {
        //     this.refs.paso1.classList.add("done");
        //     this.refs.paso2.classList.add("done");
        //     this.refs.paso3.classList.add("done");
        //     this.refs.paso1.classList.remove("active");
        //     this.refs.paso2.classList.remove("active");
        //     this.refs.paso3.classList.remove("active");
        // }
    }


    render() {
        return (
            <div className="progreso-compra">
                <div ref="paso1" className="paso">
                    <div className="bullet"
                         onClick={() => history.push("/comprar/" + this.props.match.params.idProducto + "/1")}>1
                    </div>
                    <span>Verificar pedido</span>
                </div>
                <div ref="paso2" className="paso">
                    <div className="bullet">2</div>
                    <span>Datos entrega</span>
                </div>
                {/*<div ref="paso3" className="paso">*/}
                    {/*<div className="bullet">3</div>*/}
                    {/*<span>Pago</span>*/}
                {/*</div>*/}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProgesoCompra));