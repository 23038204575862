import * as errorMessages  from '../constants/MessageConstants';
import history from '../history';


//api
import contacto from "../api/contacto";

//CONTACTO
export const CREATE_CONTACTO = 'CREATE_CONTACTO';
export const REQUEST_CREATE_CONTACTO = "REQUEST_CREATE_CONTACTO";
export const SUCCESS_CREATE_CONTACTO = "SUCCESS_CREATE_CONTACTO";
export const ERROR_CREATE_CONTACTO = "ERROR_CREATE_CONTACTO";
export const RESET_CREATE_CONTACTO = "RESET_CREATE_CONTACTO";


//CREATE
function requestCreateContacto() {
    return {
        type: REQUEST_CREATE_CONTACTO,
    }
}

function receiveCreateContacto() {
    return {
        type: SUCCESS_CREATE_CONTACTO,
        receivedAt: Date.now()
    }
}

export function errorCreateContacto(error) {
    return {
        type: ERROR_CREATE_CONTACTO,
        error: error,
    }
}

export function resetCreateContacto() {
    return {
        type: RESET_CREATE_CONTACTO
    }
}

export function createContacto(contacto) {
    return {
        type: CREATE_CONTACTO,
        contacto
    }
}

export function saveCreateContacto() {
    return (dispatch, getState) => {
        dispatch(requestCreateContacto());
        return contacto.saveCreate(getState().contacto.create.nuevo)
            .then(function (response) {

                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveCreateContacto());
                    return response.json();
                }
            })
            .then(() => {
                dispatch(resetCreateContacto());
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateContacto(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorCreateContacto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}
