import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "./BotonPago.css";
import { Row } from "react-bootstrap";
//lodash
import guardarAhora from "../../../../assets/img/pago/guardarAhora.png";

import {
    createPago,
    fetchComprobante,
    fetchPago,
    resetComprobante,
    resetCreatePago,
    resetUpdatePago,
    saveCreatePago,
    saveUpdatePago,
    updatePago
} from "../../../../actions/PagoActions";
import { fetchMediosPago, resetMediosPago } from "../../../../actions/MediosPagoActions";
import TarjetaCredito from "./MediosPago/TarjetaCredito";
import Contado from "./MediosPago/Contado";
import TransferenciaBancaria from "./MediosPago/TransferenciaBancaria";
import ValorCuentaRecaudadora from "./MediosPago/ValorCuentaRecaudadora";
import ValorPagoDiferido from "./MediosPago/ValorPagoDiferido";
import Bono from "./MediosPago/Bono";
import RetencionesDeImpuestos from "./MediosPago/RetencionesDeImpuestos";
import Echeq from "./MediosPago/Echeq";
import clone from "lodash/clone";
import { resetUsuarios } from "../../../../actions/UsuarioActions";
import { resetEstadosPago } from "../../../../actions/EstadosPagoActions";
import { resetPlanesCuotas } from "../../../../actions/PlanesCuotasActions";
import { resetProvincias } from "../../../../actions/ProvinciasActions";
import { resetCiudades } from "../../../../actions/CiudadActions";
import volver from "../../../../assets/img/pago/volver.png";
import * as roles from "../../../../constants/Roles";
import AutosuggestUsuarios from "./AutosuggestUsuarios";
import Glyphicon from "react-bootstrap/lib/Glyphicon";
import spinner from "../../../../assets/img/spinner.gif";
import { fetchPedidosIfNeeded, invalidatePedidos } from "../../../../actions/PedidoActions";
import moment from "moment";
import { saveUpdatePagoDecidir } from "../../../../actions/DecidirActions";
import { resetFormasDePago } from "../../../../actions/FormaDePagoActions";
import { resetTarjeta } from "../../../../actions/TarjetaCreditoActions";

export default function PagoCuentaPropia(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();
    const mounted = useRef();
    const mounted2 = useRef();
    const mounted3 = useRef();
    const msjMedioPago = useRef();
    const msjTipoPago = useRef();

    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;
    const mediosPago = useSelector(state => state.mediosPago);
    const usuarios = useSelector(state => state.usuarios);
    const usuarioLogueado = usuarios.byId.usuarioLogueado;
    const pedidos = useSelector(state => state.pedidos);
    const clientes = useSelector(state => state.clientes);

    // State
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [catOpen, setCatOpen] = useState(null);
    const [modalContinuar, setModalContinuar] = useState(false);

    const disabled = !!idPago;
    var esEmpleadoKit = usuarioLogueado && (
        usuarioLogueado.idRol == roles.ADMINISTRACION_ID || usuarioLogueado.idRol == roles.ADMINISTRADOR_ID || usuarioLogueado.idRol == roles.COMMUNITY_MANAGER_ID
        || usuarioLogueado.idRol == roles.VENDEDOR_ID || usuarioLogueado.idRol == roles.DESPACHO_ID);

    //Hooks
    //Effects
    useEffect(() => {
        dispatch(fetchMediosPago({estado: true}));
        if (idPago)
            dispatch(fetchPago(idPago));
        return function cleanup() {
            dispatch(resetMediosPago());
            dispatch(resetCreatePago());
            dispatch(resetUpdatePago());
            dispatch(resetUsuarios());
            dispatch(resetEstadosPago());
            dispatch(resetPlanesCuotas());
            dispatch(resetProvincias());
            dispatch(resetCiudades());
            setFiles([]);
            setPreviews([]);
            dispatch(resetComprobante());
            dispatch(resetFormasDePago());
            dispatch(resetTarjeta());
        }
    }, []);

    useEffect(() => {
        if (idPago && !catOpen && pago.categoriaMedioPago !== catOpen) {
            setCatOpen(pago.categoriaMedioPago);
        }
    }, [pago]);

    //Cuando crea paso a la pantalla de actualizacion
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!pagos.create.isCreating && !pagos.create.error) {
                props.history.push("/panel/pagos/" + pagos.create.nuevo.id);
                dispatch(fetchPago(pagos.create.nuevo.id));
                dispatch(resetCreatePago());
                setFiles([]);
                setPreviews([]);
                setModalContinuar(true);
            }
        }
    }, [pagos.create.isCreating]);

    //Buscar pedidos cyo
    useEffect(() => {
        dispatch(invalidatePedidos());
        if (pago.idUsuario ? pago.idUsuario : usuarioLogueado.id)
            dispatch(fetchPedidosIfNeeded({
                cyo: true,
                idUsuario: pago.idUsuario ? pago.idUsuario : usuarioLogueado.id,
                order: "fechaPedido",
                direction: "desc"
            }));
    }, [pago.idUsuario, pago.idTipoPago]);

    //Cuando actualiza recargo imagenes
    useEffect(() => {
        if (!mounted2.current) {
            mounted2.current = true;
        } else {
            if (!pagos.update.isUpdating && !pagos.update.error) {
                setFiles([]);
                setPreviews([]);
                dispatch(resetComprobante());
                if (pago.comprobantes && pago.comprobantes.length > 0) {
                    //Preview
                    let previewsImg = [];
                    //let cant = clone(previewsImg.length);
                    let fotos = [];
                    for (let i = 0; i < pago.comprobantes.length; i++) {
                        let imagen = pago.comprobantes[i];
                        dispatch(fetchComprobante(imagen.id));
                        let vuelta = clone(i);
                        previewsImg[vuelta] = "loading";
                    }
                    setPreviews(previewsImg);
                }
            }
        }
    }, [pagos.update.isUpdating]);

    //La primera vez cargo las fotos
    useEffect(() => {
        if (pago.comprobantes && pago.comprobantes.length > 0 && (pago.comprobantes.length !== files.length && pago.comprobantes.length !== previews.length)) {
            //Preview
            let previewsImg = clone(previews);
            //let cant = clone(previewsImg.length);
            let fotos = [];
            for (let i = 0; i < pago.comprobantes.length; i++) {
                let imagen = pago.comprobantes[i];
                dispatch(fetchComprobante(imagen.id));
                let vuelta = clone(i);
                previewsImg[vuelta] = "loading";
            }
            setPreviews(previewsImg);
        }

    }, [pago.comprobantes && pago.comprobantes.length]);

    //Hago la preview de las fotos cargadas del backend
    useEffect(() => {
            //Cargar foto
            let previewsImg = clone(previews);
            for (let i = 0; i < pagos.update.comprobantes.length; i++) {

                let vuelta = clone(i);
                let fileSubido = pagos.update.comprobantes[i];
                let reader = new FileReader();
                reader.onloadend = function () {
                    if (fileSubido.type === "application/pdf" || fileSubido.type === "application/doc" || fileSubido.type === "application/docx")
                        previewsImg[vuelta] = guardarAhora;
                    else
                        previewsImg[vuelta] = reader.result;
                    setTimeout(() => setPreviews(previewsImg), 100);
                };
                if (fileSubido) {
                    reader.readAsDataURL(fileSubido);
                }
            }
            setFiles(pagos.update.comprobantes);
        }
        ,
        [pagos.update.comprobantes && pagos.update.comprobantes.length]
    )


    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        console.log("e.target.name", e.target.name);
        if (e.target.id === "idMedioPago") {
            cambio.categoriaMedioPago = catOpen ? catOpen : null;
            cambio[e.target.id] = e.target.checked === true || e.target.checked === undefined ? parseInt(e.target.value) : null;
            dispatch(resetCreatePago());
            setPreviews([]);
            setFiles([]);
            msjMedioPago.current.classList.remove("visible");
        }
        if (e.target.id === "idTipoPago") {
            cambio[e.target.id] = e.target.checked === true || e.target.checked === undefined ? parseInt(e.target.value) : null;
            cambio.idPedido = null;
            msjTipoPago.current.classList.remove("visible");
        }
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };


    const onSubmit = (e) => {
        e.preventDefault();
        let cambio = {};
        cambio.estadoPago = "Pendiente";
        cambio.evaluarComprobantes = false;
        dispatch(updatePago(cambio));
        if (!pago.idMedioPago || pago.idMedioPago === "") {
            msjMedioPago.current.classList.add("visible");
            msjMedioPago.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else if (!pago.idTipoPago || pago.idTipoPago === "") {
            msjTipoPago.current.classList.add("visible");
            msjTipoPago.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else if (!idPago) {
            if (pago.idMedioPago === 3) {
                //Tarjeta crédito
                dispatch(saveUpdatePagoDecidir()).then(() => {
                    let cambio = {};
                    cambio.estadoPago = "Pendiente";
                    cambio.pagarOnline = true;
                    cambio.evaluarComprobantes = false;
                    return dispatch(createPago(cambio));
                }).then(() => {
                    dispatch(saveCreatePago());
                })
            } else
                dispatch(saveCreatePago(files));
        } else
            dispatch(saveUpdatePago(files));
    };

    const continuar = (continuar) => {
        setModalContinuar(false);
        dispatch(resetCreatePago());
        dispatch(resetUpdatePago());
        dispatch(resetEstadosPago());
        setFiles([]);
        setPreviews([]);
        dispatch(resetComprobante());
        if (!continuar) {
            props.history.push("/panel/listaMonitoresPagos/1/monitorPagos");
        } else {
            props.history.push("/panel/pagos/nuevo/2");
        }
    };


    //Medios pago
    let mediosPagoByCat = {};
    mediosPago.allIds.map((idMedioPago) => {
        let medioPago = mediosPago.byId.mediosPago[idMedioPago];
        medioPago.categoria.split(",").forEach((categoria) => {
            categoria = categoria.trim();
            if (!mediosPagoByCat[categoria])
                mediosPagoByCat[categoria] = [];
            mediosPagoByCat[categoria].push(medioPago);
        });

    });
    return (
        <div className="pagocuentapropia">
            <form onSubmit={onSubmit}>
                <div>
                    <Row className="container-formulario">
                        <h2>¿Cómo voy a pagar?</h2>
                        <i>Informe sus pagos para agilizar nuestros procesos administrativos.</i>
                        <br/>
                        <p className="mensaje" ref={msjMedioPago}>Debe seleccionar un medio de pago</p>
                        {
                            Object.keys(mediosPagoByCat).map((categoria) => {
                                return (
                                    <div className="medios-pago" key={categoria}>
                                        <div className="header"
                                             onClick={() => categoria !== catOpen ? setCatOpen(categoria) : setCatOpen(null)}>
                                            <h3>{categoria}</h3>
                                            <Glyphicon glyph="chevron-down"/>
                                        </div>
                                        {
                                            catOpen === categoria &&
                                            <div className={"body"}>
                                                {
                                                    mediosPagoByCat[categoria].map((medioPago) => {
                                                        return (
                                                            <div style={{display: "flex", alignItems: "flex-end"}}
                                                                 key={"mp" + medioPago.id}>
                                                                <div className="item-checkradio">
                                                                    <input type="radio" name="idMedioPago"
                                                                           className={!!idPago ? "disabled" : ""}
                                                                           id="idMedioPago"
                                                                           checked={pago.idMedioPago === medioPago.id}
                                                                           value={medioPago.id}
                                                                           onChange={(e) => onChangePago(e)}
                                                                           disabled={!!idPago}
                                                                    />
                                                                    <label onClick={() => {
                                                                        let e = {
                                                                            target: {
                                                                                id: "idMedioPago",
                                                                                value: medioPago.id
                                                                            }
                                                                        };
                                                                        !idPago &&
                                                                        onChangePago(e);
                                                                    }}>
                                                                        {medioPago ? medioPago.nomMedioPago : ""}
                                                                    </label>
                                                                    {
                                                                        medioPago.linkInstructivo &&
                                                                        <Glyphicon glyph="question-sign"
                                                                                   onClick={() => {
                                                                                       var win = window.open(medioPago.linkInstructivo, '_blank');
                                                                                       win.focus();
                                                                                   }}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </Row>
                    <Row className="container-formulario">
                        <h2>¿A qué cuenta voy depositar?</h2>
                        {
                            (esEmpleadoKit || (usuarioLogueado && usuarioLogueado.idRol === roles.DISTRIBUIDOR_ID)) &&
                            <Row>
                                <label htmlFor="idUsuario">Usuario al cual se le acreditará</label>
                                <AutosuggestUsuarios onChange={onChangePago} disabled={disabled || !!idPago}
                                                     usuarioSeleccionado={pago.idUsuario ? usuarios.byId.usuarios[pago.idUsuario] : null}/>
                            </Row>
                        }
                        <Row style={{marginTop: "15px"}}>
                            <label htmlFor="idUsuario">Cuenta</label>
                            <div style={{display: "flex", alignItems: "flex-end"}}>
                                <div className="item-checkradio">
                                    <input type="radio" name="idTipoPago"
                                           id="idTipoPago"
                                           className={disabled ? "disabled" : ""}
                                           disabled={disabled}
                                           checked={pago.idTipoPago === 2}
                                           value={2}
                                           onChange={(e) => onChangePago(e)}
                                    />
                                    <label>A cuenta propia</label>
                                </div>
                                <div className="item-checkradio">
                                    <input type="radio" name="idTipoPago"
                                           id="idTipoPago"
                                           className={disabled ? "disabled" : ""}
                                           disabled={disabled}
                                           checked={pago.idTipoPago === 3}
                                           value={3}
                                           onChange={(e) => onChangePago(e)}
                                    />
                                    <label>Cuenta y Orden</label>
                                </div>
                            </div>
                            <p className="mensaje" ref={msjTipoPago}>Debe seleccionar una cuenta</p>
                        </Row>
                        {
                            pago && pago.idTipoPago === 3 &&
                            <div className="input-row">
                                <label htmlFor="idPedido">Pedido cuenta y orden</label>
                                <select id="idPedido" name="idPedido" required={true}
                                        value={pago && pago.idPedido ? pago.idPedido : ""}
                                        disabled={disabled}
                                        onChange={(e) => onChangePago(e)}>
                                    <option value="">Seleccione pedido</option>
                                    {
                                        pedidos.allIds.map((idPedido) => {
                                            let pedido = pedidos.byId.pedidos[idPedido];
                                            let cliente = clientes.byId.clientes[pedido.idCliente];
                                            if (pedido)
                                                return (
                                                    <option key={pedido.id}
                                                            value={pedido.id}>{`Nro. ${pedido.vd_idPedido} Fecha: ${moment(pedido.fechaPedido).format("DD-MM-YYYY")} (${cliente ? cliente.vd_codCliente : ""}-${cliente ? cliente.razonSocial : ""})`}</option>
                                                );
                                        })
                                    }
                                </select>
                            </div>
                        }
                    </Row>
                    <Row className="container-formulario">
                        {
                            pago && pago.idMedioPago &&
                            <h2>Detalles del pago</h2>
                        }

                        {
                            pago && pago.idMedioPago === 1 &&
                            <Contado disabled={disabled}/>
                        }

                        {
                            pago && pago.idMedioPago === 2 &&
                            <TransferenciaBancaria files={files} setFiles={setFiles.bind(this)} previews={previews}
                                                   setPreviews={setPreviews.bind(this)} disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 3 &&
                            <TarjetaCredito disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 4 &&
                            <ValorCuentaRecaudadora files={files} setFiles={setFiles.bind(this)} previews={previews}
                                                    setPreviews={setPreviews.bind(this)} disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 5 &&
                            <ValorPagoDiferido files={files} setFiles={setFiles.bind(this)} previews={previews}
                                               setPreviews={setPreviews.bind(this)} disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 6 &&
                            <Echeq disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 7 &&
                            <Bono files={files} setFiles={setFiles.bind(this)} previews={previews}
                                  setPreviews={setPreviews.bind(this)} disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 8 &&
                            <RetencionesDeImpuestos files={files} setFiles={setFiles.bind(this)} previews={previews}
                                                    setPreviews={setPreviews.bind(this)} disabled={disabled}/>
                        }
                        {
                            pago && pago.idMedioPago === 9 &&
                            <TransferenciaBancaria files={files} setFiles={setFiles.bind(this)} previews={previews}
                                                   setPreviews={setPreviews.bind(this)} disabled={disabled}/>
                        }
                    </Row>
                    <Row className="container-formulario">
                        <h2>Observaciones</h2>
                        <div className="input-row">
                            <label htmlFor="nombre">Observaciones</label>
                            <textarea id="observaciones"
                                      name="observaciones"
                                      disabled={disabled || !(esEmpleadoKit || (usuarioLogueado && usuarioLogueado.idRol === roles.DISTRIBUIDOR_ID))}
                                      placeholder=""
                                      value={pago && pago.observaciones ? pago.observaciones : ""}
                                      onChange={(e) => onChangePago(e)}
                            />
                        </div>
                    </Row>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-izq">
                                <button type="button" className="boton-redondo outline"
                                        onClick={() => props.history.push("/panel/ListaMonitoresPagos/1/monitorPagos")}>
                                    <img src={volver}/>
                                    Volver
                                </button>
                            </div>
                            <div className="botonera-der">
                                <button type="submit" className="boton-redondo"
                                        disabled={disabled || pagos.update.isUpdating || pagos.create.isCreating}>
                                    <img
                                        src={(pagos.update.isUpdating || pagos.create.isCreating) ? spinner : guardarAhora}/>
                                    {(pagos.update.isUpdating || pagos.create.isCreating) ? "Guardando" : "Guardar pago"}
                                </button>
                            </div>
                        </div>
                    </Row>
                </div>
            </form>
            {
                modalContinuar && pago &&
                <div className="modal-token">
                    <h2>{pago && pago.idMedioPago !== 3 ? `Gracias! Esperamos su pago para completar el proceso. ¿Desea ingresar otro pago?` : `Gracias! Su pago fue procesado con éxito. ¿Desea ingresar otro pago?`}</h2>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-der"></div>
                            <div className="botonera-izq">
                                <button type="button" className="boton-default"
                                        onClick={() => {
                                            continuar(false)
                                        }}>
                                    No
                                </button>
                                <button type="button" className="boton-default"
                                        onClick={(e) => {
                                            continuar(true)
                                        }}>
                                    Sí
                                </button>
                            </div>
                        </div>
                    </Row>
                </div>
            }
        </div>
    )
        ;
}
