import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {
    Button,
    Col,
    DropdownButton,
    FormControl,
    MenuItem,
    Navbar,
    Panel,
    PanelGroup,
    Row,
    Table,
} from "react-bootstrap";
import $ from "jquery";
import Scrollchor from "react-scrollchor";
//CSS
import "../../../../assets/css/Carrito.css";
//Actions
import {logout} from "../../../../actions/AuthenticationActions";
import {
    fetchDolarIfNeeded,
    fetchFechaListaPrecioIfNeeded,
    fetchListaPreciosIfNeeded,
    imprimirListaPrecios,
    invalidateDolar,
    invalidateListaPrecios,
    updateListaPrecio,
} from "../../../../actions/ListaPreciosActions";
import {fetchCategoriasIfNeeded} from "../../../../actions/CategoriaActions";
import {
    fetchUsuarioLogueadoIfNeeded,
    fetchUsuariosIfNeeded,
    resetUpdateUsuario,
    resetUsuarios,
    updateUsuario,
} from "../../../../actions/UsuarioActions";
import {
    createDetallePedidoMon,
    deleteDetallePedidoMon,
    resetDetallesPedidoMon,
} from "../../../../actions/DetallePedidoMonActions";
import {
    fetchPreciosProductoIfNeeded,
    fetchPreciosProductoTuPrecioIfNeeded,
    fetchPreciosProductoTuPrecioLista2IfNeeded,
    invalidatePreciosProducto,
    invalidatePreciosProductoTuPrecio,
} from "../../../../actions/PreciosProductoActions";
import {
    fetchAccesoCuentaUsuario,
    fetchAccesosCuentaIfNeeded,
    invalidateAccesosCuenta,
} from "../../../../actions/AccesosCuentaActions";
import {
    createPedido,
    fetchPedidosIfNeeded,
    resetCreatePedido,
    resetUpdatePedido,
} from "../../../../actions/PedidoActions";

import Autosuggest from "react-autosuggest";
//Images
import botonCancelarCarrito from "../../../../assets/img/carrito/botonCancelarCarrito.png";
import carrito from "../../../../assets/img/carrito/carrito.png";
import flechaAbajoCeleste from "../../../../assets/img/carrito/botonFlechaAbajoCeleste.png";
import botonCancelarNavegador from "../../../../assets/img/carrito/botonCancelarNavegador.png";
import botonCancelarNavegadorCeleste from "../../../../assets/img/carrito/botonCancelarNavegadorCeleste.png";
import carritoCeleste from "../../../../assets/img/carrito/carritoCeleste.png";
import botonMas from "../../../../assets/img/carrito/botonMas.png";
import botonMenos from "../../../../assets/img/carrito/botonMenos.png";
import spinner from "../../../../assets/img/spinner.gif";
import headerIzq from "../../../../assets/img/carrito/headerIzq.png";
import kitLogo from "../../../../assets/img/kitLogo.png";
//Components
import WifiSignal from "../../../../components/elementos/basicos/WifiSignal";
import Loader from "../../../../components/elementos/loader/Loader";
//Constants
import * as roles from "../../../../constants/Roles";
import * as herramientas from "../../../../constants/Herramientas";
import * as cuentas from "../../../../constants/Cuentas";
import {LISTA_GREMIO2} from "../../../../constants/ListasPrecios";

import history from "../../../../history";

var match = require("autosuggest-highlight/match");
var parse = require("autosuggest-highlight/parse");

const WAIT_INTERVAL = 1300;
const ENTER_KEY = 13;

class Carrito extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            valueProductos: "",
            valueCategorias: "",
            valueUsuarios: this.props.usuarios.update.activo.id
                ? this.props.usuarios.update.activo.nombre +
                  " " +
                  this.props.usuarios.update.activo.apellido
                : "",
            suggestionsProductos: this.getSuggestionsProductos(""),
            suggestionsCategorias: this.getSuggestionsCategorias(""),
            suggestionsUsuarios: this.getSuggestionsUsuarios(""),
            idProductoSeleccionado: null,
            idCategoriaSeleccionada: null,
            idSubCategoriaSeleccionada: null,
            activeKey: 0,
            ordenarPor: "menorPrecio",
            multiplicador: null,
            titleCotizacion: "Periodo Cotización",
            buscarPorNombre: "",
            mostrarStock: false,
            //TODO
            //imagenes: new Map(),
        };

        //METODOS AUTOSUGGESTS
        this.escapeRegexCharacters = this.escapeRegexCharacters.bind(this);
        //ATS Productos
        this.onSuggestionsUpdateRequestedProductos =
            this.onSuggestionsUpdateRequestedProductos.bind(this);
        this.onSuggestionsClearRequestedProductos =
            this.onSuggestionsClearRequestedProductos.bind(this);
        this.onChangeProductos = this.onChangeProductos.bind(this);
        this.getSuggestionValueMostrarProductos =
            this.getSuggestionValueMostrarProductos.bind(this);
        this.renderSuggestionProductos =
            this.renderSuggestionProductos.bind(this);
        //ATS Categorias
        this.onSuggestionsUpdateRequestedCategorias =
            this.onSuggestionsUpdateRequestedCategorias.bind(this);
        this.onSuggestionsClearRequestedCategorias =
            this.onSuggestionsClearRequestedCategorias.bind(this);
        this.onChangeCategorias = this.onChangeCategorias.bind(this);
        this.getSuggestionValueMostrarCategorias =
            this.getSuggestionValueMostrarCategorias.bind(this);
        this.renderSuggestionCategorias =
            this.renderSuggestionCategorias.bind(this);
        //ATS Usuarios
        this.onSuggestionsUpdateRequestedUsuarios =
            this.onSuggestionsUpdateRequestedUsuarios.bind(this);
        this.onSuggestionsClearRequestedUsuarios =
            this.onSuggestionsClearRequestedUsuarios.bind(this);
        this.onChangeUsuarios = this.onChangeUsuarios.bind(this);
        this.getSuggestionValueMostrarUsuarios =
            this.getSuggestionValueMostrarUsuarios.bind(this);
        this.renderSuggestionUsuarios =
            this.renderSuggestionUsuarios.bind(this);
        //OTROS
        this.changePanelActivoCategoria =
            this.changePanelActivoCategoria.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.quitarDetalleDeCarrito = this.quitarDetalleDeCarrito.bind(this);
        this.changeCotizacion = this.changeCotizacion.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchCategoriasIfNeeded();
        //Esconder la barra de navegacion cuando el scroll baja mas que la tabla de productos
        document.addEventListener("scroll", this.handleScroll);
        document
            .getElementById("barraNavegacion")
            .classList.add("ocultarBarra");

        this.props.fetchListaPreciosIfNeeded();

        //Busco los precios del dolar
        this.props.invalidateDolar();
        this.props.fetchDolarIfNeeded();

        //Busco usuarios según id cuenta
        this.props.resetUsuarios();
        this.props.fetchUsuariosIfNeeded({idCuenta: 1});

        //Reset usuario activo
        // this.props.resetUpdateUsuario();

        this.refs.autosuggestPrincipal.input.id = "autosuggest-principal";
        this.refs.autossugestNavegador.input.id =
            "autosuggest-navbar-productos";

        //Busca en autossugest productos por nombre de producto y scrollea hasta la tabla
        document.querySelector("#autosuggest-principal").addEventListener(
            "keypress",
            function (e) {
                var key = e.which || e.keyCode;
                if (key === ENTER_KEY) {
                    history.push(
                        "/panel/listaPrecios/1/producto/" +
                            this.state.valueProductos
                    );
                    this.setState({
                        buscarPorNombre: this.state.valueProductos,
                        idProductoSeleccionado: null,
                        idCategoriaSeleccionada: null,
                        idSubCategoriaSeleccionada: null,
                    });
                    $("html, body").animate(
                        {
                            scrollTop:
                                $(document).height() -
                                $("#carrito-compra").height(),
                        },
                        1000
                    );
                }
            }.bind(this)
        );

        //Busca en autossugest productos del navegador por nombre de producto y scrollea hasta la tabla
        document
            .querySelector("#autosuggest-navbar-productos")
            .addEventListener(
                "keypress",
                function (e) {
                    var key = e.which || e.keyCode;
                    if (key === ENTER_KEY) {
                        history.push(
                            "/panel/listaPrecios/1/producto/" +
                                this.state.valueProductos
                        );
                        this.setState({
                            buscarPorNombre: this.state.valueProductos,
                            idProductoSeleccionado: null,
                            idCategoriaSeleccionada: null,
                            idSubCategoriaSeleccionada: null,
                        });
                        $("html, body").animate(
                            {
                                scrollTop:
                                    $(document).height() -
                                    $("#carrito-compra").height(),
                            },
                            1000
                        );
                    }
                }.bind(this)
            );

        if (this.props.match.params.nomProducto) {
            this.setState({
                buscarPorNombre: this.props.match.params.nomProducto,
            });
            this.timer = setTimeout(
                () =>
                    $("html, body").animate(
                        {
                            scrollTop:
                                $(document).height() -
                                $("#carrito-compra").height(),
                        },
                        1000
                    ),
                WAIT_INTERVAL
            );
        }

        if (this.props.match.params.idPedido) {
            this.props.resetCreatePedido();
            this.props.resetDetallesPedidoMon();
            this.props.fetchPedidosIfNeeded({
                id: this.props.match.params.idPedido,
                buscarPedidosUsuario: true,
            });
        }
    }

    componentWillUpdate(nextProps) {
        //Al llegar el accesoCuenta del usuario logueado
        if (
            nextProps.listaPrecio.allIds.length &&
            (this.props.accesosCuenta.allIds.length !==
                nextProps.accesosCuenta.allIds.length ||
                //Si tengo el accesoCuenta del logueado pero no hay lista activa (por haber enviado un pedido en resumen por ej)
                (nextProps.accesosCuenta.allIds.length > 0 &&
                    nextProps.listaPrecio.update.activo &&
                    Object.keys(nextProps.listaPrecio.update.activo).length ===
                        0) ||
                //Si se selecciona un usuario en el autosuggest
                this.props.usuarios.update.activo.id !==
                    nextProps.usuarios.update.activo.id)
        ) {
            nextProps.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta =
                    nextProps.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                //Si el accesoCuenta coincide con el usuario activo o usuario logueado busco precios y actualizo lista activa
                if (
                    accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID &&
                    ((nextProps.usuarios.update.activo.id &&
                        accesoCuenta.idUsuario ==
                            nextProps.usuarios.update.activo.id) ||
                        (!nextProps.usuarios.update.activo.id &&
                            accesoCuenta.idUsuario ==
                                nextProps.usuarios.byId.usuarioLogueado.id))
                ) {
                    accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                        var habilitacion =
                            nextProps.habilitaciones.byId.habilitaciones[
                                idHabilitacion
                            ];
                        if (
                            habilitacion &&
                            habilitacion.idHerramienta ==
                                herramientas.LISTA_PRECIOS_ID
                        ) {
                            var listaDef = null;
                            //Si posee lista default la busco, sino le asigno la primera de la lista en el store (listaDef es null cuando es empleado kit)
                            if (habilitacion.listaDefault)
                                listaDef = habilitacion.listaDefault;
                            else listaDef = nextProps.listaPrecio.allIds[0];
                            this.props.invalidatePreciosProducto();
                            this.props.fetchPreciosProductoIfNeeded(
                                listaDef,
                                habilitacion.id
                            );
                            this.props.updateListaPrecio(
                                nextProps.listaPrecio.byId.listaPrecio[listaDef]
                            );

                            //Si usuario logueado tiene rol Distribuidor, guardo sus precios en preciosProductoTuPrecio
                            if (
                                accesoCuenta.idUsuario ==
                                    nextProps.usuarios.byId.usuarioLogueado
                                        .id &&
                                nextProps.authentication.rol ==
                                    roles.DISTRIBUIDOR &&
                                Object.keys(
                                    this.props.preciosProducto.byId
                                        .preciosProductoTuPrecio
                                ).length == 0
                            ) {
                                this.props.fetchPreciosProductoTuPrecioIfNeeded(
                                    listaDef,
                                    habilitacion.id
                                );
                            }
                        }

                        if (
                            accesoCuenta.idUsuario ==
                                nextProps.usuarios.byId.usuarioLogueado.id &&
                            nextProps.authentication.rol ==
                                roles.DISTRIBUIDOR &&
                            Object.keys(
                                this.props.preciosProducto.byId
                                    .preciosProductoTuPrecioLista2
                            ).length == 0
                        ) {
                            this.props.fetchPreciosProductoTuPrecioLista2IfNeeded(
                                LISTA_GREMIO2,
                                habilitacion.id
                            );
                        }
                    });
                }
            });
        }

        // TODO
        // if (this.state.imagenes.size == 0 && this.props.productos.allIds.length !== 0) {
        //     var imgs = new Map();
        //     this.props.productos.allIds.map((idProducto) => {
        //         var producto = this.props.productos.byId.productos[idProducto];
        //         var path = process.env.PUBLIC_URL + "/img/productos/" + producto.img1;
        //         imgs.set(producto.id, path);
        //     });
        //     this.setState({
        //         imagenes: imgs,
        //     });
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.match.params.desCategoria &&
            prevProps.categorias.allIds.length !=
                this.props.categorias.allIds.length &&
            this.props.categorias.allIds.length
        ) {
            this.props.categorias.allIds.map((idCategoria) => {
                var categoria =
                    this.props.categorias.byId.categorias[idCategoria];
                if (
                    categoria &&
                    categoria.desCategoria.replace(/\s/g, "").toLowerCase() ==
                        this.props.match.params.desCategoria
                ) {
                    var idSubCategoriaSeleccionada = 0;
                    categoria.sub_categoria.map((idSubCategoria) => {
                        var subCategoria =
                            this.props.subCategorias.byId.subCategorias[
                                idSubCategoria
                            ];
                        if (
                            subCategoria &&
                            this.props.match.params.desSubCategoria &&
                            subCategoria.desSubCategoria
                                .replace(/\s/g, "")
                                .toLowerCase() ==
                                this.props.match.params.desSubCategoria
                        ) {
                            idSubCategoriaSeleccionada = subCategoria.id;
                        }
                    });
                    this.setState({
                        idCategoriaSeleccionada: categoria.id,
                        idSubCategoriaSeleccionada: idSubCategoriaSeleccionada,
                    });
                    $("html, body").animate(
                        {
                            scrollTop:
                                $(document).height() -
                                $("#carrito-compra").height(),
                        },
                        1000
                    );
                }
            });
        }
        if (
            prevProps.pedidos.update.activo.id !=
            this.props.pedidos.update.activo.id
        ) {
            let usuario = this.props.pedidos.update.activo.usuario;
            this.props.updateUsuario(usuario);
            this.props.fetchAccesoCuentaUsuario(1, usuario.id);
            this.setState({
                valueUsuarios: `${usuario.vd_codCliente} ${usuario.nombre} ${usuario.apellido}`,
            });
            this.props.pedidos.update.activo.detalle_pedido_mon.forEach(
                (idDetallePedido) => {
                    var detalle =
                        this.props.detallesPedidoMon.byId.detallesPedidoMon[
                            idDetallePedido
                        ];
                    if (detalle && detalle.precioProducto != "0.01") {
                        let detalleNuevo = {};
                        var cantidad = {};
                        cantidad["idProducto"] = detalle.idProducto;
                        cantidad["cantidad"] = detalle.cantidad;
                        detalleNuevo[detalle.idProducto + "-"] = cantidad;
                        this.props.createDetallePedidoMon(detalleNuevo);
                    }
                }
            );

            let cambio = {};
            cambio.id = this.props.pedidos.update.activo.id;
            this.props.createPedido(cambio);
        }
    }

    componentWillUnmount() {
        this.props.resetUpdatePedido();
    }

    // AUTOSUGGEST PRODUCTOS

    //Cuando selecciono un producto de la lista
    onChangeProductos(event, {newValue, method}) {
        this.setState({
            valueProductos: newValue,
        });
    }

    //Actualiza array suggestions de productos segun valor ingresado
    onSuggestionsUpdateRequestedProductos({value}) {
        this.setState({
            suggestionsProductos: this.getSuggestionsProductos(value),
        });
    }

    onSuggestionsClearRequestedProductos() {
        this.setState({
            suggestionsProductos: [],
        });
    }

    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    //Obtiene sugerencias de productos de acuerdo a lo ingresado
    getSuggestionsProductos(value) {
        //.normalize('NFD').replace(/[\u0300-\u036f]/g, "") sirve para quitar tildes y caracteres especiales
        const escapedValue = this.escapeRegexCharacters(
            value
                .trim()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
        );

        if (escapedValue == "") {
            return [];
        }

        const regex = new RegExp(escapedValue, "i");
        let suggestionsProductos = [];
        this.props.productos.allIds.some((idProducto) => {
            let producto = this.props.productos.byId.productos[idProducto];
            //.normalize('NFD').replace(/[\u0300-\u036f]/g, "") sirve para quitar tildes y caracteres especiales
            if (
                regex.test(
                    `${producto.codProducto
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")} ${producto.nomProducto
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")} ${
                        producto.vd_idProducto
                    }`
                )
            ) {
                suggestionsProductos.push(producto);
            }
        });
        return suggestionsProductos;
    }

    // Setea ids para que se busquen productos por nombre o codigo para mostrar en la tabla
    // y define que valor se muestra en el input cuando selecciona
    getSuggestionValueMostrarProductos(suggestion) {
        this.setState({
            idProductoSeleccionado: suggestion.id,
            idCategoriaSeleccionada: null,
            idSubCategoriaSeleccionada: null,
            buscarPorNombre: "",
            open: false,
        });
        return `${suggestion.vd_idProducto} ${suggestion.codProducto} ${suggestion.nomProducto}`;
    }

    //Renderiza sugerencias de productos
    renderSuggestionProductos(suggestion, {query, isHighlighted}) {
        const suggestionTextCodVD = `Código Kit: ${suggestion.vd_idProducto}  |  ${suggestion.codProducto}`;
        const matchesCodVD = match(suggestionTextCodVD, query);
        const partsCodVD = parse(suggestionTextCodVD, matchesCodVD);

        const suggestionTextName = `${suggestion.nomProducto}`;
        const matchesName = match(suggestionTextName, query);
        const partsName = parse(suggestionTextName, matchesName);

        return (
            <div>
                <Scrollchor
                    className="scrollProducto"
                    animate={{offset: -150, duration: 1000}}
                    disableHistory={true}
                    to="#carrito-compra"
                >
                    <div className="codigoAutosuggest">
                        {partsCodVD.map((part, index) => {
                            const className = part.highlight
                                ? "highlight"
                                : null;

                            return (
                                <span className={className} key={index}>
                                    <b>{part.text}</b>
                                </span>
                            );
                        })}
                    </div>
                    <div className="nombreAutosuggest">
                        {partsName.map((part, index) => {
                            const className = part.highlight
                                ? "highlight"
                                : null;

                            return (
                                <span className={className} key={index}>
                                    {part.text}
                                </span>
                            );
                        })}
                    </div>
                </Scrollchor>
                <hr className="lineaPunteadaListaAutosuggestProductos" />
            </div>
        );
    }

    // AUTOSUGGEST CATEGORIAS

    //Cuando selecciono una categoria de la lista
    onChangeCategorias(event, {newValue, method}) {
        this.setState({
            valueCategorias: newValue,
        });
    }

    //Actualiza array suggestions de categorias segun valor ingresado
    onSuggestionsUpdateRequestedCategorias({value}) {
        this.setState({
            suggestionsCategorias: this.getSuggestionsCategorias(value),
        });
    }

    onSuggestionsClearRequestedCategorias() {
        this.setState({
            suggestionsCategorias: [],
        });
    }

    //Obtiene sugerencias de categorias de acuerdo a lo ingresado
    getSuggestionsCategorias(value) {
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue == "") {
            return [];
        }

        const regex = new RegExp("\\b" + escapedValue, "i");
        let suggestionsCategorias = [];
        this.props.categorias.allIds.map((idCategoria) => {
            let categoria = this.props.categorias.byId.categorias[idCategoria];
            if (regex.test(`${categoria.desCategoria}`)) {
                suggestionsCategorias.push(categoria);
            }
        });
        return suggestionsCategorias;
    }

    // Setea ids para que en render se busquen productos por categoria para mostrar en la tabla
    // y define que valor se muestra en el input cuando selecciona
    getSuggestionValueMostrarCategorias(suggestion) {
        history.push(
            "/panel/listaPrecios/1/categoria/" +
                suggestion.desCategoria.replace(/\s/g, "").toLowerCase()
        );
        this.setState({
            idProductoSeleccionado: null,
            idCategoriaSeleccionada: suggestion.id,
            idSubCategoriaSeleccionada: 0,
            buscarPorNombre: "",
            open: false,
        });
        return `${suggestion.desCategoria}`;
    }

    //Renderiza sugerencias de categorias
    renderSuggestionCategorias(suggestion, {query, isHighlighted}) {
        const suggestionText = `${suggestion.desCategoria}`;
        const matches = match(suggestionText, query);
        const partsCod = parse(suggestionText, matches);

        return (
            <div>
                <div className="categoriaAutosuggest">
                    {partsCod.map((part, index) => {
                        const className = part.highlight ? "highlight" : null;

                        return (
                            <span className={className} key={index}>
                                <b>{part.text}</b>
                            </span>
                        );
                    })}
                </div>
                <hr className="lineaPunteadaListaAutosuggestCategorias" />
            </div>
        );
    }

    // AUTOSUGGEST USUARIOS

    //Cuando selecciono un usuario de la lista
    onChangeUsuarios(event, {newValue, method}) {
        this.setState({
            valueUsuarios: newValue,
        });
    }

    //Actualiza array suggestions de usuarios segun valor ingresado
    onSuggestionsUpdateRequestedUsuarios({value}) {
        this.setState({
            suggestionsUsuarios: this.getSuggestionsUsuarios(value),
        });
    }

    onSuggestionsClearRequestedUsuarios() {
        this.setState({
            suggestionsUsuarios: [],
        });
    }

    //Obtiene sugerencias de usuarios de acuerdo a lo ingresado
    getSuggestionsUsuarios(value) {
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue == "") {
            return [];
        }

        const regex = new RegExp("\\b" + escapedValue, "i");
        let suggestionsUsuarios = [];
        this.props.usuarios.allIds.map((idUsuario) => {
            let usuario = this.props.usuarios.byId.usuarios[idUsuario];
            if (
                usuario &&
                regex.test(
                    `${usuario.nombre} ${usuario.apellido} ${usuario.vd_codCliente}`
                )
            ) {
                suggestionsUsuarios.push(usuario);
            }
        });
        return suggestionsUsuarios;
    }

    // Setea ids para que en render se busquen usuarios
    getSuggestionValueMostrarUsuarios(suggestion) {
        this.props.fetchAccesoCuentaUsuario(1, suggestion.id);
        this.props.updateUsuario(suggestion);
        return `${suggestion.vd_codCliente} ${suggestion.nombre} ${suggestion.apellido}`;
    }

    //Renderiza sugerencias de usuarios
    renderSuggestionUsuarios(suggestion, {query, isHighlighted}) {
        const suggestionTextDniCod = `DNI: ${suggestion.dni}  |  Cod VD: ${suggestion.vd_codCliente}`;
        const matchesDniCod = match(suggestionTextDniCod, query);
        const partsDniCod = parse(suggestionTextDniCod, matchesDniCod);

        const suggestionText = `${suggestion.nombre} ${suggestion.apellido}`;
        const matches = match(suggestionText, query);
        const partsCod = parse(suggestionText, matches);

        return (
            <div>
                <div className="usuarioAutosuggest dniCod">
                    {partsDniCod.map((part, index) => {
                        const className = part.highlight ? "highlight" : null;

                        return (
                            <span className={className} key={index}>
                                <b>{part.text}</b>
                            </span>
                        );
                    })}
                </div>
                <div className="usuarioAutosuggest">
                    {partsCod.map((part, index) => {
                        const className = part.highlight ? "highlight" : null;

                        return (
                            <span className={className} key={index}>
                                <b>{part.text}</b>
                            </span>
                        );
                    })}
                </div>
                <hr className="hrUsuarios" />
            </div>
        );
    }

    changePanelActivoCategoria(activeKey) {
        this.setState({activeKey});
    }

    // Muestra/oculta imagen de flecha en la categoria segun este seleccionada la misma
    // y setea la busqueda de productos en null
    seleccionarCategoria(categoria) {
        this.setState({
            idCategoriaSeleccionada: this.state.idCategoriaSeleccionada
                ? null
                : categoria.id,
            idProductoSeleccionado: null,
            idSubCategoriaSeleccionada: null,
            buscarPorNombre: "",
            value: "",
        });
    }

    seleccionarSubCategoria(categoria, subCategoria) {
        if (subCategoria)
            history.push(
                "/panel/listaPrecios/1/categoria/" +
                    categoria.desCategoria.replace(/\s/g, "").toLowerCase() +
                    "/" +
                    subCategoria.desSubCategoria
                        .replace(/\s/g, "")
                        .toLowerCase()
            );
        else
            history.push(
                "/panel/listaPrecios/1/categoria/" +
                    categoria.desCategoria.replace(/\s/g, "").toLowerCase()
            );
        this.setState({
            idProductoSeleccionado: null,
            idCategoriaSeleccionada: categoria.id,
            idSubCategoriaSeleccionada: subCategoria ? subCategoria.id : 0,
            buscarPorNombre: "",
            value: "",
        });
    }

    //Muestra oculta la barra de navegacion (con los dos autosuggest) y el boton resumen segun height
    handleScroll(e) {
        var heightTabla = 0;
        var documentoTabla = document.getElementById("tableProductos");
        var documentoResumen = document.getElementById("botonResumen");
        if (documentoTabla) {
            heightTabla = document
                .getElementById("tableProductos")
                .getBoundingClientRect();
            if (heightTabla.top > 300 || heightTabla.top == 0) {
                document
                    .getElementById("barraNavegacion")
                    .classList.add("ocultarBarra");
            } else {
                document
                    .getElementById("barraNavegacion")
                    .classList.remove("ocultarBarra");
            }
        }
        if (documentoResumen) {
            if (heightTabla.top < -200) {
                document
                    .getElementById("botonResumen")
                    .classList.remove("ocultarBoton");
            } else {
                document
                    .getElementById("botonResumen")
                    .classList.add("ocultarBoton");
            }
        }
    }

    handleChangeCantidad(e, idProducto) {
        var detalle =
            this.props.detallesPedidoMon.byId.detallesPedidoMon[
                idProducto + "-"
            ];
        if (!detalle) {
            if (e.target.value > 0) {
                detalle = {};
                var cantidad = {};
                cantidad["idProducto"] = idProducto;
                cantidad["cantidad"] = parseInt(e.target.value);
                detalle[idProducto + "-"] = cantidad;
                this.props.createDetallePedidoMon(detalle);
            }
        } else {
            if (e.target.value > 0) {
                var cambio = {};
                detalle.cantidad = parseInt(e.target.value);
                cambio[idProducto + "-"] = detalle;
                this.props.createDetallePedidoMon(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                this.props.deleteDetallePedidoMon(cambio);
            }
        }
    }

    handleAgregarCantidad(idProducto) {
        var detalle =
            this.props.detallesPedidoMon.byId.detallesPedidoMon[
                idProducto + "-"
            ];
        if (!detalle) {
            detalle = {};
            var cantidad = {};
            cantidad["idProducto"] = idProducto;
            cantidad["cantidad"] = 1;
            detalle[idProducto + "-"] = cantidad;
            this.props.createDetallePedidoMon(detalle);
        } else {
            var cambio = {};
            detalle.cantidad = detalle.cantidad + 1;
            cambio[idProducto + "-"] = detalle;
            this.props.createDetallePedidoMon(cambio);
        }
    }

    handleQuitarCantidad(idProducto) {
        var detalle =
            this.props.detallesPedidoMon.byId.detallesPedidoMon[
                idProducto + "-"
            ];
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                cambio[idProducto + "-"] = detalle;
                this.props.createDetallePedidoMon(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                this.props.deleteDetallePedidoMon(cambio);
            }
        }
    }

    //Se ejecuta desde la imagen de cruz del carrito.
    quitarDetalleDeCarrito(idProducto) {
        var detalle =
            this.props.detallesPedidoMon.byId.detallesPedidoMon[
                idProducto + "-"
            ];
        var cambio = {};
        cambio[idProducto + "-"] = detalle;
        this.props.deleteDetallePedidoMon(cambio);
    }

    //Al seleccionar otra lista busco precios de esa lista
    updatePrecios(e) {
        this.props.invalidatePreciosProducto();
        this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
            var accesoCuenta =
                this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
            //Busco precios del usuario seleccionado si esta seleccionado o si no está seleccionado busco del logueado
            if (
                accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID &&
                ((this.props.usuarios.update.activo.id &&
                    accesoCuenta.idUsuario ==
                        this.props.usuarios.update.activo.id) ||
                    (!this.props.usuarios.update.activo.id &&
                        accesoCuenta.idUsuario ==
                            this.props.usuarios.byId.usuarioLogueado.id))
            ) {
                accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                    var habilitacion =
                        this.props.habilitaciones.byId.habilitaciones[
                            idHabilitacion
                        ];
                    if (
                        habilitacion &&
                        habilitacion.idHerramienta ==
                            herramientas.LISTA_PRECIOS_ID
                    ) {
                        this.props.fetchPreciosProductoIfNeeded(
                            e.target.value,
                            habilitacion.id
                        );
                    }
                });
            }
            if (
                this.props.usuarios.byId.usuarioLogueado.idRol ==
                    roles.DISTRIBUIDOR_ID &&
                accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID &&
                accesoCuenta.idUsuario ==
                    this.props.usuarios.byId.usuarioLogueado.id
            ) {
                accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                    var habilitacion =
                        this.props.habilitaciones.byId.habilitaciones[
                            idHabilitacion
                        ];
                    if (
                        habilitacion &&
                        habilitacion.idHerramienta ==
                            herramientas.LISTA_PRECIOS_ID
                    ) {
                        this.props.invalidatePreciosProductoTuPrecio();
                        this.props.fetchPreciosProductoTuPrecioIfNeeded(
                            e.target.value,
                            habilitacion.id
                        );
                    }
                });
            }
        });
        this.props.updateListaPrecio(
            this.props.listaPrecio.byId.listaPrecio[e.target.value]
        );
    }

    //Al cambiar el combo ordenarPor, ordena los productos por < precio, > precio, alf asc, alf dsc
    changeOrden(e) {
        this.setState({
            ordenarPor: e.target.value,
        });
    }

    onChangeMostrarStock(e) {
        this.setState({mostrarStock: !this.state.mostrarStock});
    }

    changeCotizacion(eventKey) {
        let inputPrecio = this.refs["precio-dolar"];
        inputPrecio.classList.remove("precio-selected");

        setTimeout(() => {
            inputPrecio.classList.add("precio-selected");
        }, 250);
        let fecha = this.props.listaPrecio.dolar.dolar
            ? this.props.listaPrecio.dolar.dolar.fecha
            : "";

        switch (eventKey) {
            case "1":
                this.setState({
                    multiplicador: 1,
                    titleCotizacion: "DOLAR HOY: " + fecha,
                });
                break;
            case "2":
                this.setState({
                    multiplicador: 1,
                    titleCotizacion: "Contado o E-cheq 7 días",
                });
                break;
            case "3":
                this.setState({
                    multiplicador: 1.04,
                    titleCotizacion: "E-cheq 30 días",
                });
                break;
            case "4":
                this.setState({
                    multiplicador: 1.08,
                    titleCotizacion: "E-cheq 60 días",
                });
                break;
            case "5":
                this.setState({
                    multiplicador: 1.13,
                    titleCotizacion: "E-cheq 90 días",
                });
                break;
            case "6":
                this.setState({
                    multiplicador: 1.19,
                    titleCotizacion: "E-cheq 120 días",
                });
                break;
            default:
                this.setState({
                    multiplicador: 1,
                    titleCotizacion: "DOLAR HOY",
                });
                break;
        }
    }

    render() {
        const {
            valueProductos,
            valueCategorias,
            valueUsuarios,
            suggestionsProductos,
            suggestionsCategorias,
            suggestionsUsuarios,
            idProductoSeleccionado,
            idCategoriaSeleccionada,
            idSubCategoriaSeleccionada,
            ordenarPor,
            buscarPorNombre,
            mostrarStock,
        } = this.state;

        var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
        var usuarioSeleccionado = this.props.usuarios.update.activo;

        var esEmpleadoKit =
            usuarioLogueado &&
            (usuarioLogueado.idRol == roles.ADMINISTRACION_ID ||
                usuarioLogueado.idRol == roles.ADMINISTRADOR_ID ||
                usuarioLogueado.idRol == roles.COMMUNITY_MANAGER_ID ||
                usuarioLogueado.idRol == roles.VENDEDOR_ID ||
                usuarioLogueado.idRol == roles.DESPACHO_ID);

        let fecha = this.props.listaPrecio.dolar.dolar
            ? this.props.listaPrecio.dolar.dolar.fecha
            : "";
        let precio = this.props.listaPrecio.dolar.dolar
            ? parseFloat(
                  this.props.listaPrecio.dolar.dolar.dolar.replace(",", ".")
              )
            : 0;
        let cotizacion = (precio * this.state.multiplicador).toFixed(2);

        const inputPropsProductos = {
            placeholder: "Buscá por Código o Nombre de Producto",
            value: valueProductos,
            onChange: this.onChangeProductos,
        };
        const inputPropsCategorias = {
            placeholder: "Búsqueda por Categoria",
            value: valueCategorias,
            onChange: this.onChangeCategorias,
        };
        const inputPropsUsuarios = {
            placeholder: "Búsqueda de Usuarios",
            value: valueUsuarios,
            onChange: this.onChangeUsuarios,
        };

        //Mapea los productos agregados al store para mostrarlos en el carrito
        var mapaDetalles = new Map();
        if (this.props.pedidos.create.nuevo.detalle_pedido_mon)
            this.props.pedidos.create.nuevo.detalle_pedido_mon.some(
                (idDetalle) => {
                    var detalle =
                        this.props.detallesPedidoMon.byId.detallesPedidoMon[
                            idDetalle
                        ];
                    if (detalle)
                        mapaDetalles.set(
                            detalle.idProducto.toString(),
                            detalle.cantidad
                        );
                }
            );

        //Categorias
        var categoriaSeleccionada = null;
        var subCategoriaSeleccionada = null;
        var Categorias = this.props.categorias.allIds.map((idCategoria) => {
            var categoria = this.props.categorias.byId.categorias[idCategoria];
            if (categoria) {
                var subCategorias = [];
                subCategorias.push(
                    <Scrollchor
                        animate={{offset: -150, duration: 1000}}
                        disableHistory={true}
                        to="#carrito-compra"
                    >
                        <li
                            key={0}
                            className="subCategoriaTodas"
                            onClick={() => {
                                this.seleccionarSubCategoria(categoria, null);
                                history.push(
                                    "/panel/listaPrecios/1/categoria/" +
                                        categoria.desCategoria
                                            .replace(/\s/g, "")
                                            .toLowerCase()
                                );
                            }}
                            style={{
                                backgroundColor:
                                    0 == this.state.idSubCategoriaSeleccionada
                                        ? "#00ffeb"
                                        : "#00aaeb",
                                color:
                                    0 == this.state.idSubCategoriaSeleccionada
                                        ? "#00aaeb"
                                        : "#FFFFFF",
                                fontWeight:
                                    0 == this.state.idSubCategoriaSeleccionada
                                        ? "400"
                                        : "100",
                            }}
                        >
                            Todas
                        </li>
                    </Scrollchor>
                );

                categoria.sub_categoria.map((idSubCategoria) => {
                    var subCategoria =
                        this.props.subCategorias.byId.subCategorias[
                            idSubCategoria
                        ];
                    if (subCategoria) {
                        subCategorias.push(
                            <Scrollchor
                                animate={{offset: -150, duration: 1000}}
                                disableHistory={true}
                                to="#carrito-compra"
                            >
                                <li
                                    key={subCategoria.idSubCategoria}
                                    className="subCategoria"
                                    onClick={() => {
                                        this.seleccionarSubCategoria(
                                            categoria,
                                            subCategoria
                                        );
                                        history.push(
                                            "/panel/listaPrecios/1/categoria/" +
                                                categoria.desCategoria
                                                    .replace(/\s/g, "")
                                                    .toLowerCase() +
                                                "/" +
                                                subCategoria.desSubCategoria
                                                    .replace(/\s/g, "")
                                                    .toLowerCase()
                                        );
                                    }}
                                    style={{
                                        backgroundColor:
                                            subCategoria.id ==
                                            this.state
                                                .idSubCategoriaSeleccionada
                                                ? "#00ffeb"
                                                : "#00aaeb",
                                        color:
                                            subCategoria.id ==
                                            this.state
                                                .idSubCategoriaSeleccionada
                                                ? "#00aaeb"
                                                : "#FFFFFF",
                                        fontWeight:
                                            subCategoria.id ==
                                            this.state
                                                .idSubCategoriaSeleccionada
                                                ? "400"
                                                : "100",
                                    }}
                                >
                                    {subCategoria.desSubCategoria}
                                </li>
                            </Scrollchor>
                        );
                    }
                });

                return (
                    <li className="categoriaMenuCarrito" key={categoria.id}>
                        <PanelGroup
                            accordion
                            id="accordion-controlled-example"
                            activeKey={this.state.activeKey}
                            onSelect={this.changePanelActivoCategoria}
                        >
                            <Panel
                                eventKey={categoria.id}
                                id={categoria.id}
                                className="panelCarrito"
                            >
                                <Panel.Heading>
                                    <Panel.Toggle
                                        onClick={() =>
                                            this.seleccionarCategoria(categoria)
                                        }
                                        style={{
                                            fontWeight:
                                                categoria.id ==
                                                this.state
                                                    .idCategoriaSeleccionada
                                                    ? "400"
                                                    : "100",
                                        }}
                                        toggle
                                    >
                                        {categoria.desCategoria}
                                        <img
                                            className="flechaAbajoCelesteCategoria"
                                            style={{
                                                display:
                                                    categoria.id ==
                                                    this.state
                                                        .idCategoriaSeleccionada
                                                        ? "block"
                                                        : "none",
                                            }}
                                            src={flechaAbajoCeleste}
                                        />
                                    </Panel.Toggle>
                                </Panel.Heading>
                                <Panel.Collapse>
                                    <Panel.Body
                                        style={{
                                            display:
                                                categoria.sub_categoria
                                                    .length == 0
                                                    ? "none"
                                                    : "block",
                                        }}
                                    >
                                        {subCategorias}
                                    </Panel.Body>
                                </Panel.Collapse>
                            </Panel>
                        </PanelGroup>
                    </li>
                );
            }
        });

        //Productos
        var ProductosOrdenados = [];
        this.props.productos.allIds.some((idProducto) => {
            var producto = this.props.productos.byId.productos[idProducto];
            if (producto) {
                //busqueda por producto
                if (
                    (idProductoSeleccionado == idProducto ||
                        //busqueda por nombreProducto
                        (buscarPorNombre != "" &&
                            producto.nomProducto
                                .replace(/\//gi, "")
                                .toLowerCase()
                                .indexOf(buscarPorNombre.toLowerCase()) >= 0) ||
                        //busqueda por codigo
                        (buscarPorNombre != "" &&
                            producto.vd_idProducto ==
                                parseInt(buscarPorNombre)) ||
                        //busqueda por subcategoria
                        (producto.idCategoria == idCategoriaSeleccionada &&
                            producto.idSubCategoria &&
                            producto.idSubCategoria ==
                                idSubCategoriaSeleccionada) ||
                        //busqueda por categoria
                        (producto.idCategoria == idCategoriaSeleccionada &&
                            idSubCategoriaSeleccionada == 0) ||
                        //busqueda por subcategoria "Otros"
                        (producto.idCategoria == idCategoriaSeleccionada &&
                            !producto.idSubCategoria &&
                            idSubCategoriaSeleccionada == "Otros" &&
                            !producto.esKit) ||
                        //busqueda por subcategoria "Kits"
                        (producto.idCategoria == idCategoriaSeleccionada &&
                            idSubCategoriaSeleccionada == "Kits" &&
                            producto.esKit)) &&
                    //mostrar productos con stock si mostrarStock = true, sino mostrar todos
                    ((mostrarStock &&
                        (producto.stock > 0 || producto.barras > 0)) ||
                        !mostrarStock)
                ) {
                    ProductosOrdenados.push(producto);
                }
            }
        });

        //Ordeno productos
        switch (ordenarPor) {
            case "menorPrecio":
                ProductosOrdenados.sort(
                    function (a, b) {
                        var precioA =
                            this.props.preciosProducto.byId.preciosProducto[
                                a.id
                            ];
                        var precioB =
                            this.props.preciosProducto.byId.preciosProducto[
                                b.id
                            ];
                        return (
                            (precioA ? precioA.precio : 0) -
                            (precioB ? precioB.precio : 0)
                        );
                    }.bind(this)
                );
                break;
            case "mayorPrecio":
                ProductosOrdenados.sort(
                    function (a, b) {
                        var precioA =
                            this.props.preciosProducto.byId.preciosProducto[
                                a.id
                            ];
                        var precioB =
                            this.props.preciosProducto.byId.preciosProducto[
                                b.id
                            ];
                        return (
                            (precioB ? precioB.precio : 0) -
                            (precioA ? precioA.precio : 0)
                        );
                    }.bind(this)
                );
                break;
            case "alfAsc":
                ProductosOrdenados.sort(function (a, b) {
                    if (a.nomProducto > b.nomProducto) {
                        return 1;
                    }
                    if (a.nomProducto < b.nomProducto) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                break;
            case "alfDsc":
                ProductosOrdenados.sort(function (a, b) {
                    if (a.nomProducto < b.nomProducto) {
                        return 1;
                    }
                    if (a.nomProducto > b.nomProducto) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
                break;
            default:
        }

        //Cargo productos ordenados
        var Productos = [];
        //Obtengo moneda
        var simbolo = "u$s";
        if (
            this.props.listaPrecio.update.activo &&
            Object.keys(this.props.listaPrecio.update.activo).length
        ) {
            simbolo = this.props.listaPrecio.update.activo.moneda.simbolo;
        }
        ProductosOrdenados.forEach((producto) => {
            //Busco el precio del producto
            var precioProducto =
                this.props.preciosProducto.byId.preciosProducto[producto.id];
            var precioProductoTuPrecio =
                this.props.preciosProducto.byId.preciosProductoTuPrecio[
                    producto.id
                ];
            var precioProductoTuPrecioLista2 =
                this.props.preciosProducto.byId.preciosProductoTuPrecioLista2[
                    producto.id
                ];
            var precio = 0;
            var tuPrecio = 0;

            if (precioProducto) precio = parseFloat(precioProducto.precio);
            if (
                this.props.listaPrecio.update.activo.id != LISTA_GREMIO2 &&
                precioProductoTuPrecio
            ) {
                tuPrecio = parseFloat(precioProductoTuPrecio.precio);
            } else {
                if (precioProductoTuPrecioLista2)
                    tuPrecio = parseFloat(precioProductoTuPrecioLista2.precio);
            }

            // Verifico si el producto ya fue pedido
            var cantidad = mapaDetalles.get(producto.id.toString());
            if (!cantidad) cantidad = "";

            // Si existe en el carrito el producto y es != 0 calculo el total
            var total = 0;
            if (cantidad && cantidad !== 0)
                total = parseFloat(precio) * cantidad;

            //TODO
            // var imagenProducto = this.state.imagenes.get(producto.id);

            var imagenProducto = "/img/productos/" + producto.img1;

            Productos.push(
                <tr key={producto.img1} className="columnaProductos">
                    <td>
                        <img
                            className="imagenTabla"
                            id={producto.id}
                            ref={producto.id}
                            src={imagenProducto}
                            onError={(e) => {
                                e.target.src = kitLogo;
                            }}
                        />
                    </td>
                    <td
                        style={{textAlign: "left"}}
                        className="columnaDescripcionArticulos"
                    >
                        <p>{producto.vd_idProducto}</p>
                        <h5>
                            <b>{producto.nomProducto}</b>
                        </h5>
                        <Link
                            target="_blank"
                            to={"/productoDetalle/" + producto.id}
                            className="verMas"
                        >
                            Ver mas
                        </Link>
                    </td>
                    <td style={{width: "85px"}}>
                        <WifiSignal
                            stock={producto.barras}
                            idProducto={producto.id}
                        />
                        <br />
                        <p
                            className="stock"
                            style={{
                                display:
                                    (esEmpleadoKit ||
                                        (usuarioLogueado &&
                                            usuarioLogueado.idRol ==
                                                roles.DISTRIBUIDOR_ID)) &&
                                    !producto.esKit
                                        ? "block"
                                        : "none",
                            }}
                        >
                            Stock: {producto.stock}
                        </p>
                        <p
                            className="stock"
                            style={{
                                display:
                                    (esEmpleadoKit ||
                                        (usuarioLogueado &&
                                            usuarioLogueado.idRol ==
                                                roles.DISTRIBUIDOR_ID)) &&
                                    !producto.esKit
                                        ? "block"
                                        : "none",
                            }}
                        >
                            Disp : {producto.stockDisponible}
                        </p>
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        {this.props.preciosProducto.byId.isFetching ||
                        this.props.accesosCuenta.byId.isFetching ? (
                            <img
                                className="spinnerPrecios"
                                src={spinner}
                                style={{margin: " 0 auto"}}
                            />
                        ) : (
                            <div>
                                <p>
                                    {simbolo +
                                        " " +
                                        (precio ? precio.toFixed(2) : 0)}
                                </p>
                                <b
                                    style={{
                                        display:
                                            this.props.authentication.rol ==
                                                roles.DISTRIBUIDOR &&
                                            usuarioSeleccionado &&
                                            usuarioSeleccionado.id
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    Tu precio: <br />{" "}
                                    {simbolo +
                                        " " +
                                        (tuPrecio ? tuPrecio.toFixed(2) : 0)}
                                </b>
                            </div>
                        )}
                    </td>
                    <td>
                        <FormControl
                            id={producto.id.toString()}
                            className="formCantidad"
                            type="number"
                            value={cantidad}
                            placeholder={0}
                            onChange={(e) =>
                                this.handleChangeCantidad(e, producto.id)
                            }
                        />
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        {this.props.preciosProducto.byId.isFetching ? (
                            <img
                                className="spinnerPrecios"
                                src={spinner}
                                style={{margin: " 0 auto"}}
                            />
                        ) : (
                            <p>
                                {simbolo +
                                    " " +
                                    (precio ? total.toFixed(2) : 0)}
                            </p>
                        )}
                    </td>
                    <td>
                        <p>{producto.iva}</p>
                    </td>
                    <td>
                        <p>
                            <img
                                src={botonMas}
                                onClick={() =>
                                    this.handleAgregarCantidad(producto.id)
                                }
                            />
                        </p>
                        <p>
                            <img
                                src={botonMenos}
                                onClick={() =>
                                    this.handleQuitarCantidad(producto.id)
                                }
                            />
                        </p>
                    </td>
                </tr>
            );
        });

        //Calculo del total del pedido
        var totalPedido = 0;
        var detallesPedido = [];
        mapaDetalles.forEach((cantidad, idProducto) => {
            var producto = this.props.productos.byId.productos[idProducto];
            var precioProducto =
                this.props.preciosProducto.byId.preciosProducto[producto.id];
            var precio = 0;
            if (precioProducto) precio = parseFloat(precioProducto.precio);
            detallesPedido.push(
                <div>
                    <Row className="itemsDetalle">
                        <Col
                            md={4}
                            lg={4}
                            style={{paddingRight: "0", paddingLeft: "0"}}
                        >
                            <p className="filasCarritoIzq">
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                    }}
                                >
                                    {cantidad}
                                </span>{" "}
                                {producto.nomProducto}
                            </p>
                        </Col>
                        <Col
                            md={4}
                            lg={4}
                            style={{
                                paddingRight: "0",
                                paddingLeft: "0",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {this.props.preciosProducto.byId.isFetching ? (
                                <Loader />
                            ) : (
                                <p
                                    className="filasCarritoIzq precioUnitarioCarrito"
                                    style={{
                                        display: this.props.preciosProducto.byId
                                            .isFetching
                                            ? "none"
                                            : "block",
                                    }}
                                >
                                    {simbolo} {precio ? precio.toFixed(2) : 0}
                                </p>
                            )}
                        </Col>
                        <Col
                            md={4}
                            lg={4}
                            style={{paddingRight: "0", paddingLeft: "0"}}
                        >
                            <img
                                src={botonCancelarCarrito}
                                onClick={() =>
                                    this.quitarDetalleDeCarrito(producto.id)
                                }
                                className="botonCancelarCarrito"
                            />
                        </Col>
                    </Row>
                    <hr className="lineaCelesteDottedCarrito" />
                </div>
            );
            //Calculo precio final
            var precioProducto =
                this.props.preciosProducto.byId.preciosProducto[producto.id];
            var precio = 0;
            if (precioProducto) precio = precioProducto.precio;
            totalPedido = totalPedido + precio * cantidad;
        });

        var opcionesLista = [];
        this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
            var accesoCuenta =
                this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
            if (
                (accesoCuenta &&
                    accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID &&
                    !usuarioSeleccionado.id &&
                    accesoCuenta.idUsuario == usuarioLogueado.id) ||
                (usuarioSeleccionado.id &&
                    accesoCuenta.idUsuario == usuarioSeleccionado.id)
            ) {
                accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                    var habilitacion =
                        this.props.habilitaciones.byId.habilitaciones[
                            idHabilitacion
                        ];
                    if (
                        habilitacion &&
                        habilitacion.idHerramienta ==
                            herramientas.LISTA_PRECIOS_ID
                    ) {
                        if (habilitacion.listasHabilitadas != "todas") {
                            let idListas =
                                habilitacion.listasHabilitadas.split(",");
                            for (let idLista of idListas) {
                                var listaPrecio =
                                    this.props.listaPrecio.byId.listaPrecio[
                                        idLista
                                    ];
                                if (listaPrecio) {
                                    if (
                                        !usuarioSeleccionado.id ||
                                        usuarioLogueado.idRol ==
                                            roles.DISTRIBUIDOR_ID
                                    )
                                        opcionesLista.push(
                                            <option value={listaPrecio.id}>
                                                {listaPrecio.area}
                                            </option>
                                        );
                                    else
                                        opcionesLista.push(
                                            <option value={listaPrecio.id}>
                                                {listaPrecio.tipoLista}-{" "}
                                                {listaPrecio.area}
                                            </option>
                                        );
                                }
                            }
                        } else {
                            this.props.listaPrecio.allIds.map((idLista) => {
                                var lista =
                                    this.props.listaPrecio.byId.listaPrecio[
                                        idLista
                                    ];
                                if (lista) {
                                    if (
                                        usuarioLogueado.idRol ==
                                        roles.DISTRIBUIDOR_ID
                                    )
                                        opcionesLista.push(
                                            <option value={lista.id}>
                                                {lista.area}
                                            </option>
                                        );
                                    else
                                        opcionesLista.push(
                                            <option value={lista.id}>
                                                {lista.tipoLista} - {lista.area}
                                            </option>
                                        );
                                }
                            });
                        }
                    }
                });
            }
        });
        return (
            <div className="carritoPrincipal">
                <Navbar
                    fixedTop={true}
                    id="barraNavegacion"
                    className="barraNavegacionBusqueda"
                >
                    <Row>
                        <Col
                            xsHidden
                            smHidden
                            md={6}
                            lg={6}
                            className="nav-busqueda-rapida"
                        >
                            <div className="buscadorRapidoNav">
                                <hr />
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={11}>
                                        <Autosuggest
                                            suggestions={suggestionsProductos}
                                            id="autosuggest-navbar-productos"
                                            ref="autossugestNavegador"
                                            onSuggestionsFetchRequested={
                                                this
                                                    .onSuggestionsUpdateRequestedProductos
                                            }
                                            onSuggestionsClearRequested={
                                                this
                                                    .onSuggestionsClearRequestedProductos
                                            }
                                            getSuggestionValue={
                                                this
                                                    .getSuggestionValueMostrarProductos
                                            }
                                            renderSuggestion={
                                                this.renderSuggestionProductos
                                            }
                                            inputProps={inputPropsProductos}
                                        />
                                    </Col>
                                    <Col xsHidden smHidden mdHidden lg={1}>
                                        <img
                                            className="botonCancelarBusquedaRapidaNav"
                                            src={botonCancelarNavegador}
                                            onClick={() =>
                                                this.setState({
                                                    valueProductos: "",
                                                })
                                            }
                                            style={{
                                                display:
                                                    valueProductos == ""
                                                        ? "none"
                                                        : "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                            </div>
                        </Col>
                        <Col
                            xsHidden
                            smHidden
                            md={6}
                            lg={6}
                            className="nav-busqueda-categoria"
                        >
                            <Row>
                                <Col xs={9} sm={9} md={9} lg={9}>
                                    <hr />
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={11}>
                                            <Autosuggest
                                                suggestions={
                                                    suggestionsCategorias
                                                }
                                                id="autosuggest-navbar-categorias"
                                                onSuggestionsFetchRequested={
                                                    this
                                                        .onSuggestionsUpdateRequestedCategorias
                                                }
                                                onSuggestionsClearRequested={
                                                    this
                                                        .onSuggestionsClearRequestedCategorias
                                                }
                                                getSuggestionValue={
                                                    this
                                                        .getSuggestionValueMostrarCategorias
                                                }
                                                renderSuggestion={
                                                    this
                                                        .renderSuggestionCategorias
                                                }
                                                inputProps={
                                                    inputPropsCategorias
                                                }
                                            />
                                        </Col>
                                        <Col xsHidden smHidden mdHidden lg={1}>
                                            <img
                                                className="flechitaCelesteDerechaNav"
                                                src={
                                                    botonCancelarNavegadorCeleste
                                                }
                                                onClick={() =>
                                                    this.setState({
                                                        valueCategorias: "",
                                                    })
                                                }
                                                style={{
                                                    display:
                                                        valueCategorias == ""
                                                            ? "none"
                                                            : "",
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <hr />
                                </Col>
                                <Col
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    style={{paddingLeft: "0"}}
                                >
                                    <Row>
                                        <Col
                                            xs={9}
                                            sm={9}
                                            md={8}
                                            lg={6}
                                            style={{
                                                paddingRight: "0",
                                                marginLeft: "5px",
                                                paddingLeft: "0",
                                            }}
                                        >
                                            <img
                                                className="imgCarritoCeleste"
                                                src={carritoCeleste}
                                                alt="carrito-celeste"
                                            />
                                        </Col>
                                        <Col
                                            xs={3}
                                            sm={3}
                                            md={2}
                                            lg={2}
                                            style={{paddingLeft: "0"}}
                                        >
                                            <p className="cantidadComprasCarrito">
                                                {detallesPedido.length}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Navbar>
                <div className="carrito container-fluid">
                    <section
                        style={{
                            backgroundColor: "#00aeef",
                            marginTop: "0",
                            paddingTop: "30px",
                        }}
                    >
                        <div
                            className="cointainer-fluid"
                            style={{backgroundColor: "#00aeef"}}
                        >
                            <div className="headerCarrito">
                                <Row>
                                    <img
                                        className="headerIzq"
                                        src={headerIzq}
                                        onClick={() => {
                                            history.push("/panel/cuentas");
                                        }}
                                    />
                                </Row>
                                <Row className="mleft9">
                                    <Col
                                        xs={12}
                                        sm={10}
                                        md={10}
                                        lg={10}
                                        smOffset={1}
                                        className="descripcionListaPrecio"
                                    >
                                        <Row>
                                            <h1>Lista de Precios</h1>
                                        </Row>
                                        <div
                                            className="textoBienvenida"
                                            style={{
                                                display: usuarioLogueado.nombre
                                                    ? "flex"
                                                    : "none",
                                            }}
                                        >
                                            <p style={{order: "1"}}>
                                                Bienvenido{" "}
                                                {usuarioLogueado.nombre}.
                                            </p>
                                            <p style={{order: "2"}}>
                                                Los siguientes son los mejores y
                                                más actualizados precios de
                                                nuestros productos y servicios.
                                            </p>
                                            <p style={{order: "3"}}>
                                                {" "}
                                                Debajo de cada lista vas a
                                                encontrar recursos para poder
                                                imprimirla o descargarla a tu
                                                dispositivos.
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="maxdiv">
                                <Row className="select-cotizacion">
                                    <div className="col-md-8 center-block">
                                        <p style={{color: "white"}}>
                                            Conocé la cotización según tu forma
                                            de pago
                                        </p>
                                        <Col
                                            md={6}
                                            style={{textAlign: "center"}}
                                        >
                                            <DropdownButton
                                                title={
                                                    this.state.titleCotizacion
                                                }
                                                onSelect={(eventKey) =>
                                                    this.changeCotizacion(
                                                        eventKey
                                                    )
                                                }
                                                style={{zIndex: "1111"}}
                                            >
                                                <MenuItem eventKey="1">
                                                    {"DOLAR HOY: " + fecha}
                                                </MenuItem>
                                                <MenuItem eventKey="2">
                                                    Contado o valores dentro de
                                                    los 7 días
                                                </MenuItem>
                                                <MenuItem eventKey="3">
                                                    Valores dentro de los 30
                                                    días
                                                </MenuItem>
                                                <MenuItem eventKey="4">
                                                    Valores dentro de los 60
                                                    días
                                                </MenuItem>
                                                <MenuItem eventKey="5">
                                                    Valores dentro de los 90
                                                    días
                                                </MenuItem>
                                                <MenuItem eventKey="6">
                                                    Valores dentro de los 120
                                                    días
                                                </MenuItem>
                                            </DropdownButton>
                                        </Col>
                                        <Col
                                            md={6}
                                            style={{textAlign: "center"}}
                                        >
                                            <input
                                                type="text"
                                                ref="precio-dolar"
                                                className="precio-dolar"
                                                disabled={true}
                                                value={
                                                    cotizacion == 0
                                                        ? "Valor de Cambio U$D"
                                                        : "$ " + cotizacion
                                                }
                                            />
                                        </Col>
                                    </div>
                                </Row>
                                <Row style={{marginTop: "30px"}}>
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={7}
                                        lg={7}
                                        mdOffset={1}
                                        className="lesspadin contenedor-busqueda-rapida"
                                    >
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={11}
                                            className="h3autoSuggest"
                                        >
                                            <h3>BÚSQUEDA RÁPIDA</h3>
                                            <hr />
                                            <Row className="flexBusquedaRapida">
                                                <div
                                                    className="autoSuggestProductos"
                                                    style={{
                                                        paddingRight: "0",
                                                        paddingLeft: "0",
                                                    }}
                                                >
                                                    <Autosuggest
                                                        suggestions={
                                                            suggestionsProductos
                                                        }
                                                        id="autosuggest-principal"
                                                        ref="autosuggestPrincipal"
                                                        onSuggestionsFetchRequested={
                                                            this
                                                                .onSuggestionsUpdateRequestedProductos
                                                        }
                                                        onSuggestionsClearRequested={
                                                            this
                                                                .onSuggestionsClearRequestedProductos
                                                        }
                                                        getSuggestionValue={
                                                            this
                                                                .getSuggestionValueMostrarProductos
                                                        }
                                                        renderSuggestion={
                                                            this
                                                                .renderSuggestionProductos
                                                        }
                                                        inputProps={
                                                            inputPropsProductos
                                                        }
                                                    />
                                                </div>
                                                <img
                                                    className="imagenCruzCancelarBusqueda"
                                                    src={botonCancelarNavegador}
                                                    onClick={() =>
                                                        this.setState({
                                                            valueProductos: "",
                                                        })
                                                    }
                                                    style={{
                                                        display:
                                                            valueProductos == ""
                                                                ? "none"
                                                                : "",
                                                        paddingLeft: "0px",
                                                    }}
                                                />
                                            </Row>
                                            <hr />
                                        </Col>
                                        <Col xsHidden smHidden mdHidden lg={1}>
                                            <div className="circulo-azul"></div>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row style={{backgroundColor: "white"}}>
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={7}
                                        lg={7}
                                        mdOffset={1}
                                        style={{display: "flex"}}
                                        className="lesspadin contenedor-busqueda-categoria"
                                    >
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            className="h3autoSuggest"
                                        >
                                            <h3>BÚSQUEDA POR CATEGORIAS</h3>
                                            <ul
                                                style={{
                                                    marginBottom: "0px",
                                                    paddingLeft: "20px",
                                                }}
                                            >
                                                <li>
                                                    <Panel>
                                                        <Panel.Body
                                                            className="panelTodas"
                                                            onClick={() =>
                                                                this.setState({
                                                                    open: !this
                                                                        .state
                                                                        .open,
                                                                })
                                                            }
                                                        >
                                                            <div className="flexSeleccionarCategoria">
                                                                <p className="seleccionarCategoria">
                                                                    Seleccionar
                                                                    categoria
                                                                </p>
                                                                <img
                                                                    className="flechaAbajoCelesteTodas"
                                                                    src={
                                                                        flechaAbajoCeleste
                                                                    }
                                                                />
                                                            </div>
                                                        </Panel.Body>
                                                    </Panel>
                                                </li>
                                            </ul>
                                            <ul
                                                style={{
                                                    display: this.state.open
                                                        ? "block"
                                                        : "none",
                                                }}
                                            >
                                                {Categorias}
                                            </ul>
                                        </Col>
                                        <Col xsHidden smHidden mdHidden lg={1}>
                                            <div className="circulo-blanco"></div>
                                        </Col>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </section>
                    <section id="carrito-compra">
                        <Row>
                            <Col
                                xs={10}
                                sm={10}
                                md={7}
                                lg={7}
                                xsOffset={1}
                                smOffset={1}
                                className="aclaracionPrecios lesspadin"
                            >
                                <p>
                                    Valores expresados en Dolares (u$s), sujetos
                                    a la cotización vigente según BNA. Los
                                    valores no incluyen IVA.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={10}
                                sm={10}
                                smOffset={1}
                                className="verResumenXsMd"
                                style={{
                                    display:
                                        Productos.length == 0 &&
                                        detallesPedido.length == 0
                                            ? "none"
                                            : "block",
                                }}
                            >
                                <Link to={"/panel/listaPrecios/checkout/1"}>
                                    <Button
                                        className="buttonResumen"
                                        id="botonResumen"
                                        disabled={detallesPedido.length == 0}
                                    >
                                        Ver resumen
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={10}
                                sm={7}
                                md={7}
                                lg={7}
                                xsOffset={1}
                                className="autoSuggestUsuarios lesspadin"
                                style={{
                                    display:
                                        esEmpleadoKit ||
                                        (usuarioLogueado &&
                                            usuarioLogueado.idRol ==
                                                roles.DISTRIBUIDOR_ID)
                                            ? "block"
                                            : "none",
                                }}
                            >
                                <p style={{marginLeft: "20px"}}>
                                    Visualizar listas de precios por usuario
                                </p>
                                <hr />
                                <Row>
                                    <Col
                                        xs={9}
                                        sm={6}
                                        md={7}
                                        lg={7}
                                        style={{paddingRight: "0px"}}
                                    >
                                        <Autosuggest
                                            suggestions={suggestionsUsuarios}
                                            id="autosuggest-navbar-usuarios"
                                            onSuggestionsFetchRequested={
                                                this
                                                    .onSuggestionsUpdateRequestedUsuarios
                                            }
                                            onSuggestionsClearRequested={
                                                this
                                                    .onSuggestionsClearRequestedUsuarios
                                            }
                                            getSuggestionValue={
                                                this
                                                    .getSuggestionValueMostrarUsuarios
                                            }
                                            renderSuggestion={
                                                this.renderSuggestionUsuarios
                                            }
                                            inputProps={inputPropsUsuarios}
                                        />
                                    </Col>
                                    <Col
                                        xs={3}
                                        sm={3}
                                        md={2}
                                        lg={2}
                                        style={{paddingLeft: "0px"}}
                                    >
                                        <img
                                            className="botonCancelarBusquedaUsuario"
                                            src={botonCancelarNavegador}
                                            onClick={() => {
                                                this.setState({
                                                    valueUsuarios: "",
                                                });
                                                this.props.resetUpdateUsuario();
                                            }}
                                            style={{
                                                display: usuarioSeleccionado.id
                                                    ? ""
                                                    : "none",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                            </Col>
                        </Row>
                        <Row style={{marginTop: "10px"}}>
                            <Col
                                xs={10}
                                sm={10}
                                md={8}
                                lg={8}
                                xsOffset={1}
                                className="lesspadin tablaProductos"
                            >
                                <Row className="contenedorMostrarStock">
                                    <p>Mostrar sólo productos con STOCK</p>
                                    <label>
                                        <input
                                            type="radio"
                                            name="checkboxStock"
                                            checked={mostrarStock}
                                            onClick={(e) =>
                                                this.onChangeMostrarStock(e)
                                            }
                                        />
                                        <span></span>
                                    </label>
                                </Row>
                                <Row
                                    className="contenedorCombos"
                                    style={{
                                        display:
                                            Productos.length == 0
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    <div className="ordenarPor lesspadin">
                                        <p className="textoOrdenar">
                                            Ordenar por:
                                        </p>
                                        <FormControl
                                            className="comboOrdenarPor"
                                            componentClass="select"
                                            placeholder="select"
                                            onChange={(e) =>
                                                this.changeOrden(e)
                                            }
                                        >
                                            <option value="menorPrecio">
                                                Menor Precio
                                            </option>
                                            <option value="mayorPrecio">
                                                Mayor Precio
                                            </option>
                                            <option value="alfAsc">
                                                Nombre ASC
                                            </option>
                                            <option value="alfDsc">
                                                Nombre DSC
                                            </option>
                                        </FormControl>
                                    </div>
                                    <div className="listaPrecios lesspadin">
                                        <FormControl
                                            className="comboListaPrecios"
                                            componentClass="select"
                                            placeholder="select"
                                            value={
                                                this.props.listaPrecio.update
                                                    .activo.id
                                                    ? this.props.listaPrecio
                                                          .update.activo.id
                                                    : 3
                                            }
                                            onChange={(e) =>
                                                this.updatePrecios(e)
                                            }
                                        >
                                            {opcionesLista}
                                        </FormControl>
                                        <p className="textoListaPrecios">
                                            Lista precios:
                                        </p>
                                    </div>
                                </Row>
                                <Table
                                    id="tableProductos"
                                    responsive
                                    style={{
                                        display:
                                            Productos.length == 0
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    <thead className="tableHead">
                                        <th>Imagen</th>
                                        <th>Artículo y Descripción</th>
                                        <th>Disponibles</th>
                                        <th>Precio Unitario</th>
                                        <th>Cantidad</th>
                                        <th>Total</th>
                                        <th>IVA%</th>
                                        <th>Agregar</th>
                                    </thead>
                                    <tbody>{Productos}</tbody>
                                </Table>
                            </Col>
                            <Col
                                xsHidden
                                smHidden
                                md={3}
                                lg={3}
                                className="lesspadin"
                                style={{
                                    float: Productos.length == 0 ? "right" : "",
                                }}
                            >
                                <div
                                    className="carritoCompra"
                                    style={{
                                        display:
                                            Productos.length == 0 &&
                                            detallesPedido.length == 0
                                                ? "none"
                                                : "block",
                                    }}
                                >
                                    <Row>
                                        <Col
                                            md={4}
                                            lg={4}
                                            style={{paddingRight: "0"}}
                                        >
                                            <img
                                                src={carrito}
                                                className="imgCarrito"
                                            />
                                        </Col>
                                        <Col md={5} lg={5}>
                                            <p className="nombreCarrito">
                                                EN EL CARRITO
                                            </p>
                                        </Col>
                                    </Row>
                                    <hr
                                        className="lineaAzulCarrito"
                                        style={{
                                            display:
                                                detallesPedido.length == 0
                                                    ? "none"
                                                    : "block",
                                        }}
                                    />
                                    {detallesPedido}
                                    <hr
                                        className="lineaAzulCarrito"
                                        style={{marginTop: "10px"}}
                                    />
                                    <Row>
                                        <Col
                                            md={6}
                                            lg={6}
                                            style={{
                                                paddingRight: "0",
                                                paddingLeft: "0",
                                                fontSize: "20px",
                                            }}
                                        >
                                            <h4 className="totalTotal">
                                                TOTAL
                                            </h4>
                                        </Col>
                                        <Col
                                            md={6}
                                            lg={6}
                                            className="totalCarritoCompra"
                                            style={{
                                                paddingRight: "0",
                                                paddingLeft: "0",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {this.props.preciosProducto.byId
                                                .isFetching ? (
                                                <Loader />
                                            ) : (
                                                <p
                                                    className="filasCarritoDer"
                                                    style={{
                                                        float: "right",
                                                        display: this.props
                                                            .preciosProducto
                                                            .byId.isFetching
                                                            ? "none"
                                                            : "block",
                                                    }}
                                                >
                                                    {simbolo}{" "}
                                                    {totalPedido.toFixed(2)}
                                                </p>
                                            )}
                                        </Col>
                                    </Row>
                                    <hr className="lineaAzulCarrito" />
                                    <Row
                                        style={{
                                            height:
                                                detallesPedido.length == 0
                                                    ? "20px"
                                                    : "",
                                        }}
                                    >
                                        <Link
                                            to={
                                                "/panel/listaPrecios/checkout/1"
                                            }
                                            style={{
                                                display:
                                                    detallesPedido.length == 0
                                                        ? "none"
                                                        : "block",
                                            }}
                                        >
                                            <u className="verResumen">
                                                Ver Resumen
                                            </u>
                                        </Link>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientes: state.clientes,
        usuarios: state.usuarios,
        categorias: state.categorias,
        subCategorias: state.subCategorias,
        productos: state.productos,
        detallesPedidoMon: state.detallesPedidoMon,
        pedidos: state.pedidos,
        preciosProducto: state.preciosProducto,
        listaPrecio: state.listaPrecio,
        habilitaciones: state.habilitaciones,
        kits: state.kits,
        cuentas: state.cuentas,
        accesosCuenta: state.accesosCuenta,
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout());
        },
        imprimirListaPrecios: () => {
            dispatch(imprimirListaPrecios());
        },
        fetchFechaListaPrecioIfNeeded: () => {
            dispatch(fetchFechaListaPrecioIfNeeded());
        },
        fetchDolarIfNeeded: () => {
            dispatch(fetchDolarIfNeeded());
        },
        invalidateDolar: () => {
            dispatch(invalidateDolar());
        },
        fetchUsuarioLogueadoIfNeeded: () => {
            dispatch(fetchUsuarioLogueadoIfNeeded());
        },
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded());
        },
        createDetallePedidoMon: (detalle) => {
            dispatch(createDetallePedidoMon(detalle));
        },
        deleteDetallePedidoMon: (detalle) => {
            dispatch(deleteDetallePedidoMon(detalle));
        },
        fetchPreciosProductoIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoIfNeeded(idLista, idHabilitacion));
        },
        invalidatePreciosProducto: () => {
            dispatch(invalidatePreciosProducto());
        },
        fetchListaPreciosIfNeeded: () => {
            dispatch(fetchListaPreciosIfNeeded());
        },
        fetchAccesosCuentaIfNeeded: () => {
            dispatch(fetchAccesosCuentaIfNeeded());
        },
        invalidateListaPrecios: () => {
            dispatch(invalidateListaPrecios());
        },
        invalidateAccesosCuenta: () => {
            dispatch(invalidateAccesosCuenta());
        },
        fetchUsuariosIfNeeded: (filtros) => {
            dispatch(fetchUsuariosIfNeeded(filtros));
        },
        fetchAccesoCuentaUsuario: (idCuenta, idUsuario) => {
            dispatch(fetchAccesoCuentaUsuario(idCuenta, idUsuario));
        },
        updateUsuario: (usuario) => {
            dispatch(updateUsuario(usuario));
        },
        resetUpdateUsuario: () => {
            dispatch(resetUpdateUsuario());
        },
        updateListaPrecio: (listaPrecio) => {
            dispatch(updateListaPrecio(listaPrecio));
        },
        fetchPreciosProductoTuPrecioIfNeeded: (idLista, idHabilitacion) => {
            dispatch(
                fetchPreciosProductoTuPrecioIfNeeded(idLista, idHabilitacion)
            );
        },
        fetchPreciosProductoTuPrecioLista2IfNeeded: (
            idLista,
            idHabilitacion
        ) => {
            dispatch(
                fetchPreciosProductoTuPrecioLista2IfNeeded(
                    idLista,
                    idHabilitacion
                )
            );
        },
        resetUsuarios: () => {
            dispatch(resetUsuarios());
        },
        invalidatePreciosProductoTuPrecio: () => {
            dispatch(invalidatePreciosProductoTuPrecio());
        },
        fetchPedidosIfNeeded: (filtros) => {
            dispatch(fetchPedidosIfNeeded(filtros));
        },
        resetUpdatePedido: () => {
            dispatch(resetUpdatePedido());
        },
        createPedido: (pedido) => {
            dispatch(createPedido(pedido));
        },
        resetCreatePedido: () => {
            dispatch(resetCreatePedido());
        },
        resetDetallesPedidoMon: () => {
            dispatch(resetDetallesPedidoMon());
        },
    };
};
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Carrito)
);
