import c from "../constants/constants";
require('es6-promise').polyfill();
require('isomorphic-fetch');


var accesosCuenta = {

    getAll() {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+ localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+"/accesosCuenta", defaultOptions);
    },
    getAccesoCuentaUsuario(idCuenta, idUsuario)
    {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+ localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+"/accesosCuenta/"+idCuenta+"/"+idUsuario, defaultOptions);
    }
};

export default accesosCuenta;