import * as errorMessages  from '../constants/MessageConstants';

//Actions
import {logout} from "../actions/AuthenticationActions";

//api
import encuestaRespuestaApi from "../api/encuestaRespuestas"



//UPDATE
export const UPDATE_ENCUESTARESPUESTAS = 'UPDATE_ENCUESTARESPUESTAS';
export const REQUEST_UPDATE_ENCUESTARESPUESTAS = "REQUEST_UPDATE_ENCUESTARESPUESTAS";
export const SUCCESS_UPDATE_ENCUESTARESPUESTAS = "SUCCESS_UPDATE_ENCUESTARESPUESTAS";
export const ERROR_UPDATE_ENCUESTARESPUESTAS = "ERROR_UPDATE_ENCUESTARESPUESTAS";
export const RESET_UPDATE_ENCUESTARESPUESTAS = "RESET_UPDATE_ENCUESTARESPUESTAS";


//UPDATE
function requestUpdateEncuestaRespuestas() {
    return {
        type: REQUEST_UPDATE_ENCUESTARESPUESTAS,
    }
}

function receiveUpdateEncuestaRespuestas() {
    return {
        type: SUCCESS_UPDATE_ENCUESTARESPUESTAS,
        receivedAt: Date.now()
    }
}

function errorUpdateEncuestaRespuestas(error) {
    return {
        type: ERROR_UPDATE_ENCUESTARESPUESTAS,
        error: error,
    }
}

export function resetUpdateEncuestaRespuestas() {
    return {
        type: RESET_UPDATE_ENCUESTARESPUESTAS
    }
}

export function updateEncuestaRespuestas(encuestaRespuestas) {
    return {
        type: UPDATE_ENCUESTARESPUESTAS,
        encuestaRespuestas
    }
}

export function saveUpdateEncuestaRespuestas(idUsuario) {
    return (dispatch, getState) => {
        dispatch(requestUpdateEncuestaRespuestas());

        //Tomo los nuevos packing
        var encuestaRtas = getState().encuestaRespuestas.update.encuestaRespuestas.map((id) => {
            return (getState().encuestaRespuestas.byId.encuestaRespuestas[id]);
        });

        return encuestaRespuestaApi.saveUpdate(encuestaRtas,idUsuario)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveUpdateEncuestaRespuestas());
                    //Refresco token
                    //auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateEncuestaRespuestas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateEncuestaRespuestas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//CREATE
export const CREATE_ENCUESTARESPUESTAS = 'CREATE_ENCUESTARESPUESTAS';
export const REQUEST_CREATE_ENCUESTARESPUESTAS = "REQUEST_CREATE_ENCUESTARESPUESTAS";
export const SUCCESS_CREATE_ENCUESTARESPUESTAS = "SUCCESS_CREATE_ENCUESTARESPUESTAS";
export const ERROR_CREATE_ENCUESTARESPUESTAS = "ERROR_CREATE_ENCUESTARESPUESTAS";
export const RESET_CREATE_ENCUESTARESPUESTAS = "RESET_CREATE_ENCUESTARESPUESTAS";


//CREATE
function requestCreateEncuestaRespuestas() {
    return {
        type: REQUEST_CREATE_ENCUESTARESPUESTAS,
    }
}

function receiveCreateEncuestaRespuestas() {
    return {
        type: SUCCESS_CREATE_ENCUESTARESPUESTAS,
        receivedAt: Date.now()
    }
}

function errorCreateEncuestaRespuestas(error) {
    return {
        type: ERROR_CREATE_ENCUESTARESPUESTAS,
        error: error,
    }
}

export function resetCreateEncuestaRespuestas() {
    return {
        type: RESET_CREATE_ENCUESTARESPUESTAS
    }
}

export function createEncuestaRespuestas(encuestaRespuestas) {
    return {
        type: CREATE_ENCUESTARESPUESTAS,
        encuestaRespuestas
    }
}

export function saveCreateEncuestaRespuestas(idUsuario) {
    return (dispatch, getState) => {
        dispatch(requestCreateEncuestaRespuestas());

        //Tomo los nuevos packing
        var encuestaRtas = getState().encuestaRespuestas.create.encuestaRespuestas.map((id) => {
            return (getState().encuestaRespuestas.byId.encuestaRespuestas[id]);
        });

        return encuestaRespuestaApi.saveCreate(encuestaRtas,idUsuario)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveCreateEncuestaRespuestas());
                    //Refresco token
                    //auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateEncuestaRespuestas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCreateEncuestaRespuestas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}
