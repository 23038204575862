export const ADMINISTRADOR = 'Administrador';
export const CONSUMIDOR_FINAL = 'Consumidor Final';
export const INSTALADOR_INTEGRADOR = 'Instalador o Integrador';
export const CORPORATIVO = 'Corporativo';
export const VENDEDOR = 'Vendedor';
export const TECNICO = 'Técnico';
export const DESPACHO = 'Despacho';
export const ADMINISTRACION = 'Administración';
export const COMMUNITY_MANAGER = 'Community Manager';
export const DISTRIBUIDOR = 'Distribuidor';
export const INVITADO_OPENSAFE = 'Invitado Open Safe';

export const ADMINISTRADOR_ID = 1;
export const CONSUMIDOR_FINAL_ID = 2;
export const INSTALADOR_INTEGRADOR_ID = 3;
export const CORPORATIVO_ID = 4;
export const VENDEDOR_ID = 6;
export const TECNICO_ID = 7;
export const DESPACHO_ID = 8;
export const ADMINISTRACION_ID = 9;
export const COMMUNITY_MANAGER_ID = 10;
export const DISTRIBUIDOR_ID = 11;
export const INVITADO_OPENSAFE_ID = 12;
