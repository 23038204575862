import formaDePago from "../api/formaDePago"
import * as errorMessages from '../constants/MessageConstants';
import isEmpty from "lodash/isEmpty";
import normalizeDatos from "../normalizers/normalizeFormaDePago";

//FORMA DE PAGO
export const REQUEST_FORMA_DE_PAGO = 'REQUEST_FORMA_DE_PAGO';
export const RECEIVE_FORMA_DE_PAGO = 'RECEIVE_FORMA_DE_PAGO';
export const INVALIDATE_FORMA_DE_PAGO = 'INVALIDATE_FORMA_DE_PAGO';
export const ERROR_FORMA_DE_PAGO = "ERROR_FORMA_DE_PAGO";
export const RESET_FORMA_DE_PAGO = "RESET_FORMA_DE_PAGO";


export function invalidateFormaDePago() {
    return {
        type: INVALIDATE_FORMA_DE_PAGO,
    }
}

function requestFormaDePago() {
    return {
        type: REQUEST_FORMA_DE_PAGO,
    }
}

function receiveFormaDePago(json) {
    return {
        type: RECEIVE_FORMA_DE_PAGO,
        formaDePago: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorFormaDePago(error) {
    return {
        type: ERROR_FORMA_DE_PAGO,
        error: error,
    }
}

export function resetFormasDePago() {
    return {
        type: RESET_FORMA_DE_PAGO
    }
}

export function fetchFormasDePago(filtros) {
    return dispatch => {
        dispatch(requestFormaDePago());
        return formaDePago.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveFormaDePago(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorFormaDePago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorFormaDePago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchFormasDePago(state) {
    const formaDePagos = state.formasDePago.byId;
    if (!formaDePagos) {
        return true
    } else if (isEmpty(formaDePagos.formaDePago)) {
        return true
    } else if (formaDePagos.isFetching) {
        return false
    } else {
        return formaDePagos.didInvalidate;
    }
}

export function fetchFormasDePagoIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchFormasDePago(getState())) {
            return dispatch(fetchFormasDePago(filtros))
        }
    }
}
