import * as errorMessages from '../constants/MessageConstants';
import history from '../history';
import isEmpty from 'lodash/isEmpty';

//Actions
import {logout} from "../actions/AuthenticationActions";

//api
import landing from "../api/landing";
import {saveUpdateClienteLogo} from "./ClienteActions";

//LANDING CREATE
export const CREATE_LANDING = 'CREATE_LANDING';
export const REQUEST_CREATE_LANDING = "REQUEST_CREATE_LANDING";
export const SUCCESS_CREATE_LANDING = "SUCCESS_CREATE_LANDING";
export const ERROR_CREATE_LANDING = "ERROR_CREATE_LANDING";
export const RESET_CREATE_LANDING = "RESET_CREATE_LANDING";

//LANDING UPDATE
export const UPDATE_LANDING = 'UPDATE_LANDING';
export const REQUEST_UPDATE_LANDING = "REQUEST_UPDATE_LANDING";
export const SUCCESS_UPDATE_LANDING = "SUCCESS_UPDATE_LANDING";
export const ERROR_UPDATE_LANDING = "ERROR_UPDATE_LANDING";
export const RESET_UPDATE_LANDING = "RESET_UPDATE_LANDING";

//LANDING CREATE
function requestCreateLanding() {
    return {
        type: REQUEST_CREATE_LANDING,
    }
}

function receiveCreateLanding() {
    return {
        type: SUCCESS_CREATE_LANDING,
        receivedAt: Date.now()
    }
}

export function errorCreateLanding(error) {
    return {
        type: ERROR_CREATE_LANDING,
        error: error,
    }
}

export function resetCreateLanding() {
    return {
        type: RESET_CREATE_LANDING
    }
}

export function createLanding(landing) {
    return {
        type: CREATE_LANDING,
        landing
    }
}

function saveCreateLanding() {
    return (dispatch, getState) => {
        dispatch(requestCreateLanding());
        return landing.saveCreate(getState().landing.byId.landing, getState().landing.logo.logo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateLanding());
                    return response.json();
                }
            })
            .then(function (data) {
                dispatch(changeLanding(data))
                dispatch(saveUpdateClienteLogo())
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateLanding(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return Promise.reject(error);
                    default:
                        if (error.responseJSON && error.responseJSON.message != "")
                            dispatch(errorCreateLanding(error.responseJSON.message));
                        else
                            dispatch(errorCreateLanding(errorMessages.GENERAL_ERROR));
                }
            });
    }
}


//LANDING UPDATE
function requestUpdateLanding() {
    return {
        type: REQUEST_UPDATE_LANDING,
    }
}

function receiveUpdateLanding() {
    return {
        type: SUCCESS_UPDATE_LANDING,
        receivedAt: Date.now()
    }
}

function errorUpdateLanding(error) {
    return {
        type: ERROR_UPDATE_LANDING,
        error: error,
    }
}

export function resetUpdateLanding() {
    return {
        type: RESET_UPDATE_LANDING
    }
}

export function updateLanding(landing) {
    return {
        type: UPDATE_LANDING,
        landing
    }
}

function saveUpdateLanding() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLanding());
        return landing.saveUpdate(getState().landing.byId.landing, getState().landing.logo.logo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateLanding());
                    return response.json();
                }
            })
            .then(function () {
                dispatch(saveUpdateClienteLogo())
            })
            .catch(function (error) {
                console.log(error.responseJSON);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateLanding(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message != "")
                            dispatch(errorUpdateLanding(error.responseJSON.message));
                        else
                            dispatch(errorUpdateLanding(errorMessages.GENERAL_ERROR));

                }
            });
    }
}

export function changeLanding(landing) {
    return (dispatch, getState) => {
        if (!getState().landing.byId.landing.id)
            dispatch(createLanding(landing));
        else
            dispatch(updateLanding(landing));
    }
}

export function saveLanding() {
    return (dispatch, getState) => {
        if (!getState().landing.byId.landing.id)
            dispatch(saveCreateLanding());
        else
            dispatch(saveUpdateLanding())

    }
}

//LOGO_LANDING
export const REQUEST_LOGO_LANDING = 'REQUEST_LOGO_LANDING';
export const RECEIVE_LOGO_LANDING = 'RECEIVE_LOGO_LANDING';
export const INVALIDATE_LOGO_LANDING = 'INVALIDATE_LOGO_LANDING';
export const ERROR_LOGO_LANDING = "ERROR_LOGO_LANDING";
export const UPDATE_LOGO_LANDING = "UPDATE_LOGO_LANDING";

export function invalidateLogoLanding() {
    return {
        type: INVALIDATE_LOGO_LANDING,
    }
}

function requestLogoLanding() {
    return {
        type: REQUEST_LOGO_LANDING,
    }
}

function receiveLogoLanding(logo) {
    return {
        type: RECEIVE_LOGO_LANDING,
        receivedAt: Date.now(),
        logo
    }
}

export function updateLogoLanding(logo) {
    return {
        type: UPDATE_LOGO_LANDING,
        logo
    }
}

function errorLogoLanding(error) {
    return {
        type: ERROR_LOGO_LANDING,
        error: error,
    }
}

function fetchLogoLanding() {
    return (dispatch, getState) => {
        dispatch(requestLogoLanding());
        return landing.getLogoLanding(getState().landing.byId.landing.id)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLogoLanding(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLogoLanding(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLogoLanding(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchLogoLanding(state) {
    const logo = state.landing.logo;
    if (!logo) {
        return true
    } else if (logo.isFetching) {
        return false
    } else {
        return logo.didInvalidate;
    }
}

export function fetchLogoLandingIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchLogoLanding(getState())) {
            return dispatch(fetchLogoLanding())
        }
    }
}