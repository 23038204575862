import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){

    const provincia = new schema.Entity('provincias',{}, {idAttribute:"id"});
    const ciudad = new schema.Entity('ciudades',{provincia:provincia}, {idAttribute:"id"});
    const mySchema = [ ciudad ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;