import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {

    const tarjetaCredito = new schema.Entity('tarjetasCredito', {}, {idAttribute: "id"});
    const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});
    const cliente = new schema.Entity('clientes', {}, {idAttribute: "id"});
    const estadoPago = new schema.Entity('estadosPago', {}, {idAttribute: "id"});
    const medioPago = new schema.Entity('mediosPago', {}, {idAttribute: "id"});
    const pago = new schema.Entity('pagos', {usuario:usuario, usuarioCreacion: usuario, estados_pago: [estadoPago], medio_pago: medioPago, tarjeta_credito: tarjetaCredito, cliente: cliente}, {idAttribute: "id"});
    const mySchema = {pagos: [pago]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData) {
    const tarjetaCredito = new schema.Entity('tarjetasCredito', {}, {idAttribute: "id"});
    const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});
    const cliente = new schema.Entity('clientes', {}, {idAttribute: "id"});
    const estadoPago = new schema.Entity('estadosPago', {}, {idAttribute: "id"});
    const medioPago = new schema.Entity('mediosPago', {}, {idAttribute: "id"});
    const pago = new schema.Entity('pagos', {usuario:usuario, usuarioCreacion: usuario, estados_pago: [estadoPago], medio_pago: medioPago, tarjeta_credito: tarjetaCredito, cliente: cliente}, {idAttribute: "id"});
    const mySchema = pago;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}
