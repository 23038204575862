export const CREATE_TARJETA = "CREATE_TARJETA";
export const RESET_TARJETA = "RESET_TARJETA";

export function createTarjeta(tarjeta) {
    return {
        type: CREATE_TARJETA,
        tarjeta
    }
}

export function resetTarjeta() {
    return {
        type: RESET_TARJETA,
    }
}