import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link, NavLink} from 'react-router-dom';

//CSS
import '../../assets/css/SubBarra.css';

class SubBarra extends Component {
    render() {
        return (
            <div className="container-fluid greenBorder" style={{display: this.props.logueado ? "none" : "block"}}>
                <div className="container-limit">
                    <Row>
                        <Col xs={12} sm={12} md={9} className="pull-right subBarra hidden-sm hidden-xs" style={{paddingLeft: "0px"}}>
                            <p>¿Sos Integrador? Adaptá nuestro sitio a tus necesidades. <span><Link to="/login"><button>INGRESAR</button></Link></span>
                                ¿No tenes usuario? <span><NavLink
                                    to="/registrar">Registrate</NavLink></span></p>
                        </Col>
                        <Col xs={12} sm={12} md={9} className="pull-right subBarra visible-sm visible-xs hidden-md hidden-lg" style={{paddingLeft: "0px"}}>
                            <p>¿Sos Integrador? <span><Link to="/login">
                                <button>LogIn</button></Link></span></p>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default SubBarra;
