import React, {Component} from 'react';
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import {NavLink, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../assets/css/Barra.css';
import {logout} from "../../actions/AuthenticationActions";

class Barra extends Component {
    constructor(props) {
        super(props);
        this.state = {open: false};
    }

    onToggle() {
        this.setState({open: !this.state.open});
    }

    render() {
        return (
            <div className="centerNav">
                <Navbar onToggle={(e) => this.onToggle()}
                        expanded={this.state.open}
                        collapseOnSelect>
                    <Navbar.Header>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav>
                            <li>
                                <NavLink onClick={() => this.onToggle()} to="/" exact>INICIO</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.onToggle()} activeClassName="active"
                                         to="/productos">PRODUCTOS</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.onToggle()} activeClassName="active" exact
                                         to="/soluciones">SOLUCIONES</NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.onToggle()} activeClassName="active" exact
                                         to="/equipo">EQUIPO</NavLink>
                            </li>
                            {/*<li>*/}
                            {/*<NavLink activeClassName="active" exact to="/capacitacion">CAPACITACIÓN</NavLink>*/}
                            {/*</li>*/}
                            <li>
                                <NavLink onClick={() => this.onToggle()} activeClassName="active" exact
                                         to="/registrar">{this.props.authentication.token ? "MI PERFIL" : "REGISTRATE"}</NavLink>
                            </li>
                            <li className="hidden-sm hidden-md hidden-lg"
                                style={{display: this.props.authentication.token ? "block" : "none"}}>
                                <NavLink onClick={() => this.onToggle()} activeClassName="active" to="/cambiarPassword">CAMBIAR
                                    CONTRASEÑA</NavLink>
                            </li>
                            <li className="hidden-sm hidden-md hidden-lg"
                                style={{display: this.props.authentication.token ? "block" : "none"}}>
                                <a onClick={() => {
                                    this.props.logout();
                                    this.onToggle()
                                }}>CERRAR SESION</a>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        },
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Barra));
