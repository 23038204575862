import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import history from "../../../history";

//Actions
import {saveUpdateSorteo, fetchParticipantes,saveUpdateSortear} from "../../../actions/SorteoActions";
import {resetGanadores, invalidateGanadores} from "../../../actions/GanadoresActions";

//Components
import FieldGroup from "../../camposTexto/FieldGroup";
import Loader from "../../elementos/loader/Loader";

class StartSorteo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idEvento: "",
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.goSorteo = this.goSorteo.bind(this);
        if (this.state.idEvento) {
            this.props.fetchParticipantes(this.state.idEvento);
        }

    }

    componentWillUnmount(){
        this.props.invalidateGanadores();
        this.props.resetGanadores();
    }

    goSorteo(e) {
        e.preventDefault();
        if (this.state.idEvento) {
            this.props.saveUpdateSortear(this.state.idEvento);
        } else {
            alert('Seleccione un evento');
        }

    }

    habilitarSorteo(e) {
        e.preventDefault();
        if (this.state.idEvento)
            this.props.saveUpdateSorteo(this.state.idEvento);
    }

    render() {
        var Participantes = [];
        if (this.props.sorteos.byId.participantes) {
            if (this.props.sorteos.byId.participantes.length)
                Participantes.push(
                    <p>Participantes</p>
                );
            this.props.sorteos.byId.participantes.map((participante) => {
                Participantes.push(
                    <div className="participante">
                        <p>{participante.usuario.username} {participante.usuario.last_name}</p>
                    </div>
                );
            });
        }

        return (
            <Row className="start-sorteo">
                <Col md={12} className="center-block">
                    <p className="registro-error">{this.props.sorteos.update.message ? this.props.sorteos.update.message : ""}</p>
                    <FieldGroup id="idEvento"
                                componentClass="select"
                                required={true}
                                value={this.state.idEvento ? this.state.idEvento : ""}
                                onChange={(e) => {
                                    this.setState({idEvento: e.target.value});
                                    if (e.target.value != "") {
                                        this.props.fetchParticipantes(e.target.value);
                                        setInterval(() => {
                                            this.props.fetchParticipantes(this.state.idEvento);
                                        }, 20000)
                                    }
                                }}>
                        <option value="">Seleccione curso</option>
                        <option value="37">DIA 1 Curso: 27/8 15.30 - CCTV Easy Configuration</option>
                        <option value="38">DIA 1 Curso: 27/8 17.00 - Sistema 5000IP – Video Portero IP
                            con Automatización
                        </option>
                        <option value="39">DIA 1 Curso: 27/8 18.00 - DVRs Móviles</option>
                        <option value="40">DIA 2 Curso: 28/8 15.30 - CCTV Easy Configuration</option>
                        <option value="41">DIA 2 Curso: 28/8 17.00 - Audio Profesional</option>
                        <option value="42">DIA 2 Curso: 28/8 18.00 - Sistema 800 – Sistema de
                            Intercomunicador con 2 hilos
                        </option>
                        <option value="43">DIA 3 Curso: 29/8 15.30 - CCTV Easy Configuration</option>
                        <option value="44">DIA 3 Curso: 29/8 17.00 - SAC 4000 – Integración de Acceso
                            con Video IP
                        </option>
                        <option value="45">DIA 3 Curso: 29/8 18.00 - Cámara LPR y análisis de video
                            incorporado
                        </option>
                        <option value="46">Evento Prueba</option>
                    </FieldGroup>
                    <p style={{display: this.state.idEvento === "" ? "none" : "block"}}>
                        http://{window.location.hostname}/participa/{this.state.idEvento}
                    </p>
                    <div className="botonesSorteo">
                        {this.props.sorteos.update.isUpdating ?
                            <Loader/>
                            :
                            <Button onClick={(e) => {
                                this.habilitarSorteo(e)
                            }}>Habilitar</Button>
                        }
                        <Button onClick={(e) => {
                            this.goSorteo(e)
                        }}>Jugar</Button>
                    </div>
                    {this.props.sorteos.byId.isFetching ?
                        <Loader/>
                        :
                        <div>
                            {Participantes}
                        </div>
                    }
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        ganadores: state.ganadores,
        sorteos: state.sorteos,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        saveUpdateSorteo: (idEvento) => {
            dispatch(saveUpdateSorteo(idEvento))
        },
        fetchParticipantes: (idEvento) => {
            dispatch(fetchParticipantes(idEvento))
        },
        saveUpdateSortear: (idEvento) =>{
            dispatch(saveUpdateSortear(idEvento))
        },
        invalidateGanadores: () => {
            dispatch(invalidateGanadores())
        },
        resetGanadores: () => {
            dispatch(resetGanadores())
        }

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartSorteo));
