import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, Redirect, Switch} from 'react-router-dom'


//CSS
import '../../assets/css/Cuentas.css';

//Actions
import {fetchUsuarioLogueadoIfNeeded} from "../../actions/UsuarioActions";
import {invalidateCategorias} from "../../actions/CategoriaActions";
import {fetchAccesosCuentaUsuarioIfNeeded} from "../../actions/AccesosCuentaActions";


//Components
import auth from "../../api/authentication";
import Productos from "../paginas/Productos";
import Institucional from "../paginas/Institucional";
import ProductosInicio from "../paginas/ProductosInicio";
import ProductoDetalle from "../paginas/ProductoDetalle";
import KitDetalle from "../paginas/KitDetalle";
import CambiarPassword from "../paginas/CambiarPassword";
import Encuesta from "../paginas/Encuesta";
import Equipo from "../paginas/Equipo";
import CompraProfesional from "../paginas/CompraProfesional";
import Contacto from "../paginas/Contacto";
import Soluciones from "../paginas/Soluciones";
import Home from "../paginas/Home";
import Register from "../paginas/Register";
import LogIn from "../paginas/LogIn";
import Barra from '../../components/layout/Barra';
import Header from '../../components/layout/Header';

class Plataforma extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if(this.props.authentication.token) {
            this.props.fetchUsuarioLogueadoIfNeeded();
        }
        this.props.invalidateCategorias();
        // this.props.fetchAccesosCuentaUsuarioIfNeeded(1, auth.idUsuario());
    }

    render() {
        return (
            <div>
                <Header/>
                <Barra/>
                <div className="page-main container-fluid">
                    <Switch>
                        <Route path="/login" render={() => (
                            this.props.authentication.token ? (
                                <Redirect
                                    to={{
                                        pathname: '/panel/cuentas',
                                        state: {from: this.props.location}, // eslint-disable-line
                                    }}
                                />
                            ) : (
                                <LogIn/>
                            )
                        )}/>

                        <Route path="/productos" exact component={ProductosInicio}/>
                        <Route path="/categorias/:desCategoria/:desSubCategoria" component={Productos}/>
                        <Route path="/categorias/:desCategoria" component={Productos}/>
                        <Route path="/marcas/:desMarca" component={Productos}/>
                        <Route path="/productoDetalle/:idProducto" component={ProductoDetalle}/>
                        <Route path="/kitDetalle/:idKit" component={KitDetalle}/>
                        <Route path="/institucional" component={Institucional}/>

                        <Route path="/registrar" render={() => (
                            !this.props.authentication.token ? (
                                <Register/>
                            ) : (
                                <Encuesta/>
                            )
                        )}/>

                        <Route path="/equipo" component={Equipo}/>
                        <Route path="/soluciones" component={Soluciones}/>
                        <Route path="/compraProfesional" component={CompraProfesional}/>
                        <Route path="/" exact component={Home}/>
                        <Route path="/encuesta" exact component={Encuesta}/>
                        <Route path="/cambiarPassword" exact component={CambiarPassword}/>
                        <Route path="/resetPassword/:token" exact component={CambiarPassword}/>
                        <Route path="/contacto" exact component={Contacto}/>

                        {/*<Route path="/login" component={LogIn}/>*/}
                    </Switch>
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {

        fetchUsuarioLogueadoIfNeeded: () => {
            dispatch(fetchUsuarioLogueadoIfNeeded());
        },
        fetchAccesosCuentaUsuarioIfNeeded: (idCuenta, idUsuario) => {
            dispatch(fetchAccesosCuentaUsuarioIfNeeded(idCuenta, idUsuario))
        },
        invalidateCategorias: () => {
            dispatch(invalidateCategorias())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plataforma));
