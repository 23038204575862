import {
    INVALIDATE_PLANESCUOTAS,
    REQUEST_PLANESCUOTAS, RECEIVE_PLANESCUOTAS, ERROR_PLANESCUOTAS, RESET_PLANESCUOTAS
} from '../actions/PlanesCuotasActions';
import { combineReducers } from 'redux';
import normalizeDatos from "../normalizers/normalizePlanesCuotas";

function planesCuotasById(state = {
    isFetching: false,
    didInvalidate: true,
    planesCuotas: []
}, action) {
    switch (action.type) {
        case INVALIDATE_PLANESCUOTAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PLANESCUOTAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PLANESCUOTAS:
            let dato = normalizeDatos(action.planesCuotas).entities.planesCuotas;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                planesCuotas: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_PLANESCUOTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PLANESCUOTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                planesCuotas: {}
            });
        default:
            return state
    }
}


function allPlanesCuotas(state = [], action) {
    switch (action.type) {
        case RECEIVE_PLANESCUOTAS:
            return normalizeDatos(action.planesCuotas).result ? normalizeDatos(action.planesCuotas).result.planesCuotas : [];
        case RESET_PLANESCUOTAS:
            return [];
        default:
            return state
    }
}


const planesCuotas = combineReducers({
    byId : planesCuotasById,
    allIds : allPlanesCuotas
});

export default planesCuotas;