import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

//Actions
import {logout} from "../actions/AuthenticationActions";

//api
import habilitacion from "../api/habilitacion";

//normalizer

//HABILITACION CREATE
export const CREATE_HABILITACION = 'CREATE_HABILITACION';
export const REQUEST_CREATE_HABILITACION = "REQUEST_CREATE_HABILITACION";
export const SUCCESS_CREATE_HABILITACION = "SUCCESS_CREATE_HABILITACION";
export const ERROR_CREATE_HABILITACION = "ERROR_CREATE_HABILITACION";
export const RESET_CREATE_HABILITACION = "RESET_CREATE_HABILITACION";


//CREATE
function requestCreateHabilitacion() {
    return {
        type: REQUEST_CREATE_HABILITACION,
    }
}

function receiveCreateHabilitacion() {
    return {
        type: SUCCESS_CREATE_HABILITACION,
        receivedAt: Date.now()
    }
}

export function errorCreateHabilitacion(error) {
    return {
        type: ERROR_CREATE_HABILITACION,
        error: error,
    }
}

export function resetCreateHabilitacion(error) {
    return {
        type: RESET_CREATE_HABILITACION
    }
}

export function createHabilitacion(habilitacion) {
    return {
        type: CREATE_HABILITACION,
        habilitacion
    }
}

export function saveCreateHabilitacion(idCuenta, idHerramienta, idUsuario, codAcceso) {
    return (dispatch, getState) => {
        dispatch(requestCreateHabilitacion());
        return habilitacion.saveCreate(idCuenta, idHerramienta, idUsuario, codAcceso)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveCreateHabilitacion());
                }
            })
            .then((habilitacion) => {
                dispatch(resetCreateHabilitacion());
                // history.push("login");
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateHabilitacion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return Promise.reject(error);
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorCreateHabilitacion(error.message));
                                else
                                    dispatch(errorCreateHabilitacion(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorCreateHabilitacion(errorMessages.GENERAL_ERROR));
                            });
                        return Promise.reject(error);
                }
            });
    }
}

