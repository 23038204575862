//api
import paises from "../api/paises"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthenticationActions";
//normalizers
import {normalizeDatos, normalizeDato} from "../normalizers/normalizePaises";

//PAISES
export const REQUEST_PAISES = 'REQUEST_PAISES';
export const RECEIVE_PAISES = 'RECEIVE_PAISES';
export const INVALIDATE_PAISES = 'INVALIDATE_PAISES';
export const ERROR_PAISES = "ERROR_PAISES";
export const RESET_PAISES = "RESET_PAISES";


export function invalidatePaises() {
    return {
        type: INVALIDATE_PAISES
    }
}

function requestPaises() {
    return {
        type: REQUEST_PAISES,
    }
}

function receivePaises(json) {
    return {
        type: RECEIVE_PAISES,
    paises: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPaises(error) {
    return {
        type: ERROR_PAISES,
        error: error,
    }
}

export function resetPaises() {
    return {
        type: RESET_PAISES
    }
}

export function fetchPaises(filtros) {
    return dispatch => {
        dispatch(requestPaises());
        return paises.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePaises(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPaises(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPaises(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPaises(state) {
    const paises = state.paises.byId;
    if (!paises) {
        return true
    } else if (paises.isFetching) {
        return false
    } else {
        return paises.didInvalidate;
    }
}

export function fetchPaisesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPaises(getState())) {
            return dispatch(fetchPaises(filtros))
        }
    }
}


//MODEL
export const REQUEST_PAIS = 'REQUEST_PAIS';
export const RECEIVE_PAIS = 'RECEIVE_PAIS';
export const INVALIDATE_PAIS = 'INVALIDATE_PAIS';
export const ERROR_PAIS = "ERROR_PAIS";
export const RESET_PAIS = "RESET_PAIS";


export function invalidatePais() {
    return {
        type: INVALIDATE_PAIS
    }
}

function requestPais() {
    return {
        type: REQUEST_PAIS,
    }
}

function receivePais(json) {
    return {
        type: RECEIVE_PAIS,
    pais: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPais(error) {
    return {
        type: ERROR_PAIS,
        error: error,
    }
}

export function resetPais() {
    return {
        type: RESET_PAIS
    }
}

export function fetchPais(idPais) {
    return dispatch => {
        dispatch(requestPais());
        return paises.getOne(idPais)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePais(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPais(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPais(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}