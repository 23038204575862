import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var sorteo = {

    saveUpdate(idEvento) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+'/habilitarSorteo/' + idEvento, defaultOptions);
    },
    sortear(idEvento) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+'/sortear/' + idEvento, defaultOptions);
    },
    getParticipantes(idEvento) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+'/participantesEvento/' + idEvento, defaultOptions);
    }


};

export default sorteo;