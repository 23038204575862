import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var pregunta = {

    getAll(idEvento) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
        };
        console.log("idEvento");
        return fetch(c.BASE_URL + '/pregunta/' + idEvento, defaultOptions);
    },
    saveCreate(idEvento, pregunta) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(pregunta)
        };

        return fetch(c.BASE_URL + '/pregunta/' + idEvento, defaultOptions);
    },

    juga(idEvento, dni) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL + '/juega/' + idEvento + "/" + dni, defaultOptions);
    },
};

export default pregunta;