import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import SweetAlert from "react-bootstrap-sweetalert"

//CSS
import "../../assets/css/LogIn.css";


import Footer2 from '../layout/Footer2';

//Actions
import {changeLogin, login, olvideMiPassword, resetLogin} from "../../actions/AuthenticationActions";

class LogIn extends Component {

    constructor(props) {
        super(props);
        this.state = {mostrar: false};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.authentication.token)
            this.props.history.push("/panel/cuentas");
        this.props.resetLogin();
    }

    onChangeLogin(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        console.log(cambio);
        this.props.changeLogin(cambio);
    }

    submitLogin(e) {
        e.preventDefault();
        var to = {};
        to.pathname = "/panel/cuentas/";
        this.props.login(this.props.authentication.usuario, to);
    }

    olvideMiPassword(e) {
        this.props.olvideMiPassword(this.props.authentication.usuario.email);
        this.setState({mostrar: false});
    }

    olvideMiPasswordInicio(e) {
        if (this.props.authentication.usuario.email == "")
            this.props.olvideMiPassword(this.props.authentication.usuario.email);
        else
            this.setState({mostrar: true});
    }

    render() {

        return (
            <div>
                <SweetAlert warning
                            showCancel
                            confirmBtnText="Continuar"
                            cancelBtnText="Cancelar"
                            confirmBtnBsStyle="warning"
                            cancelBtnBsStyle="default"
                            title="Recuperación de contraseña"
                            onConfirm={() => this.olvideMiPassword()}
                            onCancel={() => {
                                this.setState({mostrar: false})
                            }}
                            show={this.state.mostrar}>
                    Se le enviará un email a su casilla de correo con los pasos a seguir.
                </SweetAlert>
                <section className="login-portada">
                    <div className="container-limit">
                        <Row>
                            <Col md={9} className="recuadro">
                                <h2>
                                    Ingresar
                                </h2>
                                <h1>
                                    Accedé a nuestro universo de servicios
                                </h1>
                                <p>
                                    Asegurate de mantener tus datos actualizados, para que podamos responder a tus
                                    necesidades
                                    con nuestras propuestas más recientes.
                                </p>
                                <div className="separador-verde">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="login-ingreso">
                    <Row>
                        <Col md={10} mdOffset={1} off className="recuadro">
                            <p style={{display: this.props.authentication.currentlySending ? "none" : "block"}}>Bienvenido/a,
                                por favor ingresa tus datos para acceder:</p>
                            <p style={{color: "yellow", display: this.props.authentication.currentlySending ? "block" : "none"}}>Procesando
                                solicitud</p>
                            <p style={{color: "red"}}>{this.props.authentication.errorMessage}</p>
                            <form onSubmit={(e) => {
                                this.submitLogin(e)
                            }}>
                                <Row>
                                    <input id="email" type="text" required placeholder="Email"
                                           value={this.props.authentication.usuario.email}
                                           onChange={(e) => this.onChangeLogin(e)}
                                           disabled={this.props.authentication.currentlySending}/>
                                </Row>
                                <Row>
                                    <input id="password" type="password" required placeholder="Contraseña"
                                           value={this.props.authentication.usuario.password}
                                           onChange={(e) => this.onChangeLogin(e)}
                                           disabled={this.props.authentication.currentlySending}
                                           autocomplete={"current-password"}
                                    />
                                </Row>
                                <a style={{display:this.props.authentication.currentlySending ? "none" : "block"}} onClick={() => this.olvideMiPasswordInicio()}>
                                    ¿Olvidaste tu contraseña?
                                </a>
                                <Row>
                                    <Col md={5} className="botoneraIngreso">
                                        <Button type="submit" className="registrate" disabled={this.props.authentication.currentlySending}>Entrar</Button>
                                    </Col>
                                    <Col md={5} mdOffset={1} className="botoneraIngreso">
                                        <Button className="registrate"
                                                onClick={() => this.props.history.push("/registrar")} disabled={this.props.authentication.currentlySending}>Registrate</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </section>

                <Footer2/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLogin: (usuario) => {
            dispatch(changeLogin(usuario))
        },
        login: (usuario, to) => {
            dispatch(login(usuario, to))
        },
        olvideMiPassword: (email) => {
            dispatch(olvideMiPassword(email))
        },
        resetLogin: () => {
            dispatch(resetLogin())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
