import {combineReducers} from "redux";
import {CREATE_TARJETA, RESET_TARJETA} from "../actions/TarjetaCreditoActions";
import merge from "lodash/merge";
import {RECEIVE_PAGO} from "../actions/PagoActions";

function create(state = {
    isCreating: false,
    nuevo: {},
    error: ""
}, action) {
    switch (action.type) {
        case RECEIVE_PAGO:
            let tarjeta = action.pago.entities.tarjetasCredito ? Object.values(action.pago.entities.tarjetasCredito)[0] : {};
            return Object.assign({}, state, {
                nuevo: tarjeta,
            });
        case CREATE_TARJETA:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: merge({}, state.nuevo, action.tarjeta),
                error: null,
            });
        case RESET_TARJETA:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: {},
                error: null,
            });
        default:
            return state
    }
}

const tarjetas = combineReducers({
    create: create,
});

export default tarjetas;