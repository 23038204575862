import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
//CSS
import './App.css';
//Components
import Pagina from "./paginaGeneral/Pagina"
import MensajeCorrecto from "./elementos/MensajeCorrecto";
import MensajeError from "./elementos/MensajeError";
import Plataforma from "./plataforma/Plataforma";
//api
import auth from "../api/authentication";
//Actions
import {fetchAccesosCuentaIfNeeded, fetchAccesosCuentaUsuarioIfNeeded} from "../actions/AccesosCuentaActions";

class App extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //Para obtener el acceso cuenta y asi mostrar el precio del usuario logueado en Producto, ProductoDestacado, ProductoDos, ProductoFAPI
        if (auth.idUsuario())
            this.props.fetchAccesosCuentaIfNeeded();
    }

    render() {
        return (
            <div>

                {/*<SubBarra/>*/}
                <div className="page-main container-fluid">

                    <Switch>
                        <Route path="/panel"
                               render={(props) => this.props.authentication.token ? (<Plataforma {...props}/>) :
                                   <Redirect to={{pathname: '/login', state: {from: props.location}}}/>}/>
                        <Route path="/" component={Pagina}/>
                    </Switch>

                    <MensajeCorrecto/>
                    <MensajeError/>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAccesosCuentaUsuarioIfNeeded: (idCuenta, idUsuario) => {
            dispatch(fetchAccesosCuentaUsuarioIfNeeded(idCuenta, idUsuario))
        },
        fetchAccesosCuentaIfNeeded: () => {
            dispatch(fetchAccesosCuentaIfNeeded())
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
