import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const cliente = new schema.Entity('clientes', {}, {idAttribute: "id"});
    const estadoPedido = new schema.Entity('estadosPedido', {}, {idAttribute: "id"});
    const estadoEntrega = new schema.Entity('estadosEntrega', {}, {idAttribute: "id"});
    const kit = new schema.Entity('kits', {}, {idAttribute: "id"});
    const producto = new schema.Entity('producto', {kit: [kit]}, {idAttribute: "id"});
    const detallePedidoMon = new schema.Entity('detallesPedidoMon', {producto: producto}, {idAttribute: "id"});
    const estadoRemito = new schema.Entity('estadosRemito', {}, {idAttribute: "id"});
    const packingList = new schema.Entity('packingList', {}, {idAttribute: "id"});
    const bultos = new schema.Entity('bultos', {packing_list: [packingList],}, {idAttribute: "id"});
    const detallesRemitidos = new schema.Entity('detallesRemitidos', {}, {idAttribute: "id"});
    const remito = new schema.Entity('remitos', {
        estado_remito: [estadoRemito],
        bulto: [bultos],
        detalle_remitido: [detallesRemitidos],
    }, {idAttribute: "id"});
    const pedido = new schema.Entity('pedidos',
        {
            cliente: cliente,
            estado_pedido: [estadoPedido],
            estado_entrega: [estadoEntrega],
            detalle_pedido_mon: [detallePedidoMon],
            bultos: [bultos],
            remito: [remito],
        }, {idAttribute: "id"});
    const mySchema = [pedido];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}
