import {combineReducers} from 'redux';
import {RECEIVE_ACCESOCUENTA_USUARIO, RECEIVE_ACCESOSCUENTA} from "../actions/AccesosCuentaActions";
import merge from "lodash/merge";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";
import {UPDATE_CUENTA, REQUEST_UPDATE_CUENTA, SUCCESS_UPDATE_CUENTA, ERROR_UPDATE_CUENTA, RESET_UPDATE_CUENTA} from "../actions/CuentaAction";
import union from "lodash/union";

function cuentaById(
    state = {
        isFetching: false,
        didInvalidate: true,
        cuentas: {},
    }, action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                cuentas: merge({}, state.cuentas, action.accesosCuenta.entities.cuentas),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                cuentas: merge({}, state.cuentas, action.accesosCuenta.entities.cuentas),
                lastUpdated: action.receivedAt
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                cuentas: {},
            });
        default:
            return state
    }
}

function allCuentas(state = [], action) {
    switch (action.type) {
        case RECEIVE_ACCESOSCUENTA:
            return action.accesosCuenta.entities.cuentas ? union(state, Object.keys(action.accesosCuenta.entities.cuentas)) : state;
        case RECEIVE_ACCESOCUENTA_USUARIO:
            return action.accesosCuenta.entities.cuentas ? union(state, Object.keys(action.accesosCuenta.entities.cuentas)) : state;
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    error: ""
}, action) {
    switch (action.type) {
        case UPDATE_CUENTA:
            return merge({}, state, {activo: action.cuenta});
        case REQUEST_UPDATE_CUENTA:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_CUENTA:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt
            });
        case ERROR_UPDATE_CUENTA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_CUENTA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

const cuentas = combineReducers({
    byId: cuentaById,
    allIds: allCuentas,
    update: update,
});

export default cuentas;