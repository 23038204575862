import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import FieldGroup from "../../camposTexto/FieldGroup";

import {saveUpdateRegistro, resetUpdateRegistro} from "../../../actions/RegistroActions";

import Loader from '../../elementos/loader/Loader';

class FormCheckInPss extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: ""
        };

        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetUpdateRegistro();
    }

    submitForm(e) {
        e.preventDefault();
        this.props.saveUpdateRegistro(this.state.dni);
    }

    changeDni(e) {
        this.setState({dni: e.target.value});
    }


    render() {
        return (
            <Row>
                <Col md={6} className="center-block">
                    <form ref="asd" onSubmit={(e) => {
                        this.submitForm(e)
                    }}>
                        <FieldGroup
                            id="dni"
                            type="number"
                            label="Ingresar DNI"
                            placeholder=""
                            required={true}
                            value={this.state.dni}
                            onChange={(e) => {
                                this.changeDni(e)
                            }}
                        />
                        {
                            this.props.registros.update.isUpdating ?
                                <Loader/>
                                :
                                <Button type="submit" className="center-block boton">Confirmar</Button>
                        }

                    </form>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        registros: state.registros
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        saveUpdateRegistro: (dni) => {
            dispatch(saveUpdateRegistro(dni));
        },
        resetUpdateRegistro: () => {
            dispatch(resetUpdateRegistro());
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormCheckInPss));
