import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {useLocation} from "react-router";
import {Link, Route, Switch} from "react-router-dom";
import HeaderHerramienta from "../Layout/HeaderHerramienta";
import './ListaMonitoresPagos.css';
import history from "../../../../history";
//lodash
import {Col, Glyphicon, Row} from "react-bootstrap";
import LinkGenerado from "../../../../assets/img/pago/linkPago.png"
import agregar from "../../../../assets/img/pago/agregar.png";
import MonitorPagos from "./MonitorPagos";
import MonitorLinksPagos from "./MonitorLinksPagos";
//Constants
import * as roles from "../../../../constants/Roles";
import c from "../../../../constants/constants";


export default function ListasMonitoresPagos(props) {
    const location = useLocation();
    let pathName = location.pathname;

    const usuarios = useSelector(state => state.usuarios);
    const usuarioLogueado = usuarios.byId.usuarioLogueado;
    const esEmpleadoKitAdministracion = usuarioLogueado && (usuarioLogueado.idRol === roles.ADMINISTRACION_ID || usuarioLogueado.idRol === roles.ADMINISTRADOR_ID);

    let refSelector = useRef();
    //State
    let [stickySelector, setStickySelector] = useState(false);

    //Effects
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        if (pathName.indexOf("monitorPagos") === -1 && pathName.indexOf("monitorLinksPagos") === -1)
            history.push("/panel/listaMonitoresPagos/1/monitorPagos")

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
        }

    }, [])

    const handleScroll = (e) => {
        if (window.pageYOffset > 483)
            setStickySelector(true);
        else
            setStickySelector(false);
    }

    return (
        <div className="monitor-pagos lista-monitor-pagos">
            <HeaderHerramienta titulo={"Monitor de pagos"}
                               texto={"Mantenete informado acerca del estado de todos tus pagos. Podrás generar links de pagos para tus clientes y también ingresar dinero a tu cuenta."}/>
            {
                !!esEmpleadoKitAdministracion &&
                <Row className="mleft9">
                    <Col xs={12} sm={10} md={10} lg={10} smOffset={1}>
                        <div className="ajustes" onClick={() => window.location = `${c.EXTERNAL_URL}/panel/gestorformaspago/1`}>
                            <Glyphicon glyph="cog" bsSize="large"/>
                            Ajustes
                        </div>
                    </Col>
                </Row>
            }
            <div className={`container-tags ${stickySelector ? "sticky" : ""}`}>
                <div className="tags" ref={refSelector}>
                    <ul>
                        <li className={pathName.indexOf("monitorPagos") !== -1 ? "active" : ""}>
                            <Link to={"/panel/listaMonitoresPagos/1/monitorPagos"}>
                                Pagos
                            </Link>
                        </li>
                        <li className={pathName.indexOf("monitorLinksPagos") !== -1 ? "active" : ""}>
                            <Link to={"/panel/listaMonitoresPagos/1/monitorLinksPagos"}>
                                Links de pago
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div style={{paddingTop: stickySelector ? "80px" : ""}}>
                <Switch>
                    <Route path={"/panel/listaMonitoresPagos/:idCuenta/monitorPagos"} component={MonitorPagos}/>
                    <Route path={"/panel/listaMonitoresPagos/:idCuenta/monitorLinksPagos"}
                           component={MonitorLinksPagos}/>
                </Switch>
            </div>
            <div className="botonera">
                <div className="botonera-der">
                    <Link to={"/panel/linksPagos/nuevo"}>
                        <button className="boton-default outline">
                            <img src={LinkGenerado}/>
                            Generar link de pago
                        </button>
                    </Link>
                    <Link to={"/panel/pagos/nuevo/2"}>
                        <button className="boton-default outline">
                            <img src={agregar}/>
                            Nuevo pago
                        </button>
                    </Link>
                </div>

            </div>

        </div>
    );
}
