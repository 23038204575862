import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sound from 'react-sound';

import {fetchGanadoresIfNeeded} from "../../../actions/GanadoresActions";
import {fetchParticipantesIfNeeded} from "../../../actions/ParticipantesActions";

import TextLoop from 'react-text-loop';

import premio1 from '../../../assets/img/evento/sorteo/1.png'
import premio2 from '../../../assets/img/evento/sorteo/2.png'
import premio3 from '../../../assets/img/evento/sorteo/3.png'

import history from "../../../history";


class Ganadores extends Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchGanadoresIfNeeded(this.props.match.params.evento);
        this.props.fetchParticipantesIfNeeded();
    }

    render() {
        let ganador = [];
        let datosGanadores = this.props.ganadores.allIds.map(
            (key) => {
                return this.props.ganadores.byId.datosGanadores[key];
            }
        )
        let participante = [];
        let participantes = this.props.participantes.allIds.map(
            (key) => {
                participante = this.props.participantes.byId.participantes[key]
                return (
                    <p className="nombre-participante">{participante.nombre + " " + participante.apellido}</p>
                )
            }
        );
        return (
            <Row>
                <Col md={12} className="center-block tabla">
                    <Row className="border">
                        <Col md={5} className="sin-padding">
                            <h3>Premios</h3>
                        </Col>
                        <Col md={7} className="sin-padding">
                            <h3>Ganadores</h3>
                        </Col>
                    </Row>
                    <Row className="border">
                        <Col md={1} className="numero">
                            1
                        </Col>
                        <Col md={4} className="container-premio">
                            <div className="img-container">
                                <p>Access Point Cygnus RAP2-P</p>
                                <img className="" src={premio1} alt=""/>
                            </div>
                        </Col>
                        <Col md={7} className="winner-field">
                            <p className="nombre-participante">{datosGanadores[0] ?
                                datosGanadores[0].username + " " + datosGanadores[0].last_name : ""}</p>
                        </Col>
                    </Row>
                    <Row className="border">
                        <Col md={1} className="numero">
                            2
                        </Col>
                        <Col md={4} className="container-premio">
                            <div className="img-container">
                                <p>Switch POE 4 puertos Cygnus S1004-60</p>
                                <img className="" src={premio2} alt=""/>
                            </div>
                        </Col>
                        <Col md={7} className="winner-field">
                            <p className="nombre-participante">{datosGanadores[1] ?
                                datosGanadores[1].username + " " + datosGanadores[1].last_name : ""}</p>
                        </Col>
                    </Row>
                    <Row className="border" >
                        <Col md={1} className="numero" style={{borderBottom: "none"}}>
                            3
                        </Col>
                        <Col md={4} className="container-premio">
                            <div className="img-container">
                                <p>Cámara Smart Pixel IP minidomo 4 MP H.265</p>
                                <img className="" src={premio3} alt=""/>
                            </div>
                        </Col>
                        <Col md={7} className="winner-field">
                            <p className="nombre-participante">{datosGanadores[2] ?
                                datosGanadores[2].username + " " + datosGanadores[2].last_name : ""}</p>
                        </Col>
                    </Row>

                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        ganadores: state.ganadores,
        participantes: state.participantes
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchGanadoresIfNeeded: (idEvento) => {
            dispatch(fetchGanadoresIfNeeded(idEvento));
        },
        fetchParticipantesIfNeeded: () => {
            dispatch(fetchParticipantesIfNeeded());
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Ganadores));
