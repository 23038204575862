import React, {Component} from 'react';
import {connect} from 'react-redux';

var moment = require('moment');

class Fecha extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState() {
        return {
            dia: "",
            mes: "",
            anio: "",
            fechaInvalida: false,
        }
    }

    componentDidMount() {
        if (this.props.fecha) {
            var arrayFecha = this.props.fecha.split("-");
            if (this.props.fecha != "Invalid date")
                this.setState({
                    dia: arrayFecha[2],
                    mes: arrayFecha[1],
                    anio: arrayFecha[0],
                });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fecha != this.props.fecha && this.props.fecha) {
            var arrayFecha = this.props.fecha.split("-");
            if (this.props.fecha != "Invalid date")
                this.setState({
                    dia: arrayFecha[2],
                    mes: arrayFecha[1],
                    anio: arrayFecha[0],
                });
        }

        //Cuando la fecha se completa en los 3 combobox
        if (this.state.dia != "" && this.state.mes != "" && this.state.anio != ""
            && (prevState.dia != this.state.dia || prevState.mes != this.state.mes || prevState.anio != this.state.anio)) {
            var fecha = "";
            fecha = fecha.concat(this.state.anio, '-', this.state.mes, '-', this.state.dia);
            fecha = moment(fecha);
            var fechaNueva = fecha.format("YYYY-MM-DD");

            if (fechaNueva == "Invalid date") {
                this.setState({fechaInvalida: true});
            } else {
                this.setState({fechaInvalida: false});
            }

            var e = {};
            e.target = {};
            e.target.id = this.props.id;
            e.target.value = fechaNueva;
            this.props.onChange(e);
        }
    }

    onChangeFecha(e) {
        switch (e.target.id) {
            case "dia":
                this.setState({dia: e.target.value});
                break;
            case "mes":
                this.setState({mes: e.target.value});
                break;
            case "anio":
                this.setState({anio: e.target.value});
                break;
            default:
                break;
        }
    }

    render() {
        var i, j, k, opcionesDias, opcionesMeses, opcionesAnios;
        opcionesDias = [];
        for (i = 1; i <= 31; i++) {
            var valueDia = i.toString();
            if (i < 10) {
                valueDia = "0".concat(valueDia);
            }
            opcionesDias.push(<option key={"dia" + valueDia} value={valueDia}>{valueDia}</option>);
        }
        opcionesMeses = [];
        for (j = 1; j <= 12; j++) {
            var valueMes = j.toString();
            if (j < 10) {
                valueMes = "0".concat(valueMes);
            }
            opcionesMeses.push(<option key={"mes" + valueMes} value={valueMes}>{valueMes}</option>);
        }
        opcionesAnios = [];
        for (k = 2018; k >= 1908; k--) {
            opcionesAnios.push(<option key={"ano" + k} value={k}>{k}</option>);
        }

        return (
            <div>
                <div className="componenteFecha" style={{display: "flex"}}>
                    <div className="input-row">
                        <select autoComplete="off"
                                required={this.props.required}
                                disabled={this.props.readOnly} id="dia"
                                onChange={(e) => this.onChangeFecha(e)}
                                value={this.state.dia}
                                style={{width: "80px", marginRight: "10px"}}>
                            <option value="" disabled>Día</option>
                            {opcionesDias}
                        </select>
                    </div>
                    <div className="input-row">
                        <select autoComplete="off"
                                required={this.props.required}
                                disabled={this.props.readOnly} id="mes"
                                onChange={(e) => this.onChangeFecha(e)}
                                value={this.state.mes}
                                style={{width: "80px", marginRight: "10px"}}> >
                            <option value="" disabled>Mes</option>
                            {opcionesMeses}
                        </select>
                    </div>
                    <div className="input-row">
                        <select autoComplete="off"
                                required={this.props.required}
                                disabled={this.props.readOnly} id="anio"
                                onChange={(e) => this.onChangeFecha(e)}
                                value={this.state.anio}
                                style={{width: "80px"}}>>
                            <option value="" disabled>Año</option>
                            {opcionesAnios}
                        </select>
                    </div>
                </div>
                <p style={{display: this.state.fechaInvalida ? "block" : "none", marginLeft: "0"}}>Fecha inválida</p>
            </div>
        );
    }

}

// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {}
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(Fecha);