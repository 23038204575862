//api
import linksPagosApi from "../api/linksPagosApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from "./AuthenticationActions";
//normalizers
import { normalizeDatos, normalizeDato, denormalizeDato } from "../normalizers/normalizeLinksPagos";
//lodash
import merge from "lodash/merge";
//utils
import authUtil from "../api/authentication";

//LINKSPAGOS
export const REQUEST_LINKSPAGOS = 'REQUEST_LINKSPAGOS';
export const RECEIVE_LINKSPAGOS = 'RECEIVE_LINKSPAGOS';
export const INVALIDATE_LINKSPAGOS = 'INVALIDATE_LINKSPAGOS';
export const ERROR_LINKSPAGOS = "ERROR_LINKSPAGOS";
export const RESET_LINKSPAGOS = "RESET_LINKSPAGOS";


export function invalidateLinksPagos() {
    return {
        type: INVALIDATE_LINKSPAGOS
    }
}

function requestLinksPagos() {
    return {
        type: REQUEST_LINKSPAGOS,
    }
}

function receiveLinksPagos(json) {
    return {
        type: RECEIVE_LINKSPAGOS,
        linksPagos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorLinksPagos(error) {
    return {
        type: ERROR_LINKSPAGOS,
        error: error,
    }
}

export function resetLinksPagos() {
    return {
        type: RESET_LINKSPAGOS
    }
}

export function fetchLinksPagos(filtros) {
    return dispatch => {
        dispatch(requestLinksPagos());
        return linksPagosApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLinksPagos(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLinksPagos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLinksPagos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchLinksPagos(state) {
    const linksPagos = state.linksPagos.byId;
    if (!linksPagos) {
        return true
    } else if (linksPagos.isFetching) {
        return false
    } else {
        return linksPagos.didInvalidate;
    }
}

export function fetchLinksPagosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchLinksPagos(getState())) {
            return dispatch(fetchLinksPagos(filtros))
        }
    }
}


//MODEL
export const REQUEST_LINKPAGO = 'REQUEST_LINKPAGO';
export const RECEIVE_LINKPAGO = 'RECEIVE_LINKPAGO';
export const INVALIDATE_LINKPAGO = 'INVALIDATE_LINKPAGO';
export const ERROR_LINKPAGO = "ERROR_LINKPAGO";
export const RESET_LINKPAGO = "RESET_LINKPAGO";


export function invalidateLinkPago() {
    return {
        type: INVALIDATE_LINKPAGO
    }
}

function requestLinkPago() {
    return {
        type: REQUEST_LINKPAGO,
    }
}

export function receiveLinkPago(json) {
    return {
        type: RECEIVE_LINKPAGO,
        linkPago: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorLinkPago(error) {
    return {
        type: ERROR_LINKPAGO,
        error: error,
    }
}

export function fetchLinkPago(idLinkPago) {
    return dispatch => {
        dispatch(requestLinkPago());
        return linksPagosApi.getOne(idLinkPago)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLinkPago(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLinkPago(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLinkPago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FILE
export const RECEIVE_FILE_LINKPAGO = 'RECEIVE_FILE_LINKPAGO';

function receiveFileLinkPago(file) {
    return {
        type: RECEIVE_FILE_LINKPAGO,
        file: file,
        receivedAt: Date.now()
    }
}

export function fetchFileLinkPago(idLinkPago, filtros) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        return linksPagosApi.getFile(idLinkPago, filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {

                    let contentDisposition = response.headers.get("Content-Disposition");
                    let filenameRegex = /filename[^;=\n]*=((['"]).?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        nombreArchivo = matches[1].replace(/['"]/g, '');
                    }

                    let contentType = response.headers.get("Content-Type");
                    tipoArchivo = contentType;

                    return response.blob();
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let fileObj = {};
                fileObj[nombreArchivo] = file;
                dispatch(receiveFileLinkPago(fileObj));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLinkPago(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLinkPago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_LINKPAGO = 'UPDATE_LINKPAGO';
export const REQUEST_UPDATE_LINKPAGO = "REQUEST_UPDATE_LINKPAGO";
export const SUCCESS_UPDATE_LINKPAGO = "SUCCESS_UPDATE_LINKPAGO";
export const ERROR_UPDATE_LINKPAGO = "ERROR_UPDATE_LINKPAGO";
export const RESET_UPDATE_LINKPAGO = "RESET_UPDATE_LINKPAGO";
export const DELETE_UPDATE_LINKPAGO = "DELETE_UPDATE_LINKPAGO";

function requestUpdateLinkPago() {
    return {
        type: REQUEST_UPDATE_LINKPAGO,
    }
}

function receiveUpdateLinkPago(linkPago) {
    return {
        type: SUCCESS_UPDATE_LINKPAGO,
        receivedAt: Date.now(),
        linkPago: normalizeDato(linkPago)
    }
}

function errorUpdateLinkPago(error) {
    return {
        type: ERROR_UPDATE_LINKPAGO,
        error: error,
    }
}

export function resetUpdateLinkPago() {
    return {
        type: RESET_UPDATE_LINKPAGO,
    }
}

export function updateLinkPago(linkPago) {
    return {
        type: UPDATE_LINKPAGO,
        linkPago
    }
}

export function saveUpdateLinkPago(files) {
    return (dispatch, getState) => {
        dispatch(requestUpdateLinkPago());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update ? getState()[key].update.activo : {}, getState()[key].create ? getState()[key].create.nuevo : {});
            });

        let linkPago = denormalizeDato(getState().linksPagos.update.activo, store);

        return linksPagosApi.saveUpdate(linkPago, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    // authUtil.addToken(response);
                    dispatch(receiveUpdateLinkPago(data));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateLinkPago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdateLinksPagos(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdateLinksPagos(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorUpdateLinkPago(JSON.parse(error.message)));
                                if (error.data && error.data.length > 0)
                                    dispatch(receiveUpdateLinkPago(error.data));
                            }).catch(() => {
                                dispatch(errorUpdateLinkPago(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

export function deleteUpdateLinkPago(linkPago) {
    return {
        type: DELETE_UPDATE_LINKPAGO,
        linkPago
    }
}

//UPDATE LINKSPAGOS
export const REQUEST_UPDATE_LINKSPAGOS = "REQUEST_UPDATE_LINKSPAGOS";
export const SUCCESS_UPDATE_LINKSPAGOS = "SUCCESS_UPDATE_LINKSPAGOS";
export const ERROR_UPDATE_LINKSPAGOS = "ERROR_UPDATE_LINKSPAGOS";
export const RESET_UPDATE_LINKSPAGOS = "RESET_UPDATE_LINKSPAGOS";

function requestUpdateLinksPagos() {
    return {
        type: REQUEST_UPDATE_LINKSPAGOS,
    }
}

function receiveUpdateLinksPagos(linksPagos) {
    return {
        type: SUCCESS_UPDATE_LINKSPAGOS,
        receivedAt: Date.now(),
        linksPagos: normalizeDatos(linksPagos)
    }
}

function errorUpdateLinksPagos(error) {
    return {
        type: ERROR_UPDATE_LINKSPAGOS,
        error: error,
    }
}

export function resetUpdateLinksPagos() {
    return {
        type: RESET_UPDATE_LINKSPAGOS,
    }
}

export function saveUpdateLinksPagos() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLinksPagos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let linksPagos = getState().linksPagos.update.activos.map((idLinkPago) => {
            return denormalizeDato(getState().linksPagos.update.activo[idLinkPago], store);
        });

        return linksPagosApi.saveUpdateLinksPagos(linksPagos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLinksPagos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateLinksPagos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdateLinksPagos(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdateLinksPagos(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorUpdateLinksPagos(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorUpdateLinksPagos(errorMessages.GENERAL_ERROR));
                            });


                        return;
                }
            });
    }
}

//ALTA LINKPAGO
export const CREATE_LINKPAGO = 'CREATE_LINKPAGO';
export const REQUEST_CREATE_LINKPAGO = "REQUEST_CREATE_LINKPAGO";
export const SUCCESS_CREATE_LINKPAGO = "SUCCESS_CREATE_LINKPAGO";
export const ERROR_CREATE_LINKPAGO = "ERROR_CREATE_LINKPAGO";
export const RESET_CREATE_LINKPAGO = "RESET_CREATE_LINKPAGO";
export const DELETE_CREATE_LINKPAGO = "DELETE_CREATE_LINKPAGO";


//ALTA LINKPAGO
function requestCreateLinkPago() {
    return {
        type: REQUEST_CREATE_LINKPAGO,
    }
}

function receiveCreateLinkPago(linkPago) {
    return {
        type: SUCCESS_CREATE_LINKPAGO,
        receivedAt: Date.now(),
        linkPago: normalizeDato(linkPago)
    }
}

export function errorCreateLinkPago(error) {
    return {
        type: ERROR_CREATE_LINKPAGO,
        error: error,
    }
}

export function resetCreateLinkPago() {
    return {
        type: RESET_CREATE_LINKPAGO
    }
}

export function createLinkPago(linkPago) {
    return {
        type: CREATE_LINKPAGO,
        linkPago
    }
}

export function deleteCreateLinkPago(linkPago) {
    return {
        type: DELETE_CREATE_LINKPAGO,
        linkPago
    }
}

export function saveCreateLinkPago(files) {
    return (dispatch, getState) => {
        dispatch(requestCreateLinkPago());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update ? getState()[key].update.activo : {}, getState()[key].create ? getState()[key].create.nuevo : {});
            });

        let linkPago = denormalizeDato(getState().linksPagos.create.nuevo, store);

        return linksPagosApi.saveCreate(linkPago, files)
            .then(function (data, textStatus, response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    // authUtil.addToken(response);
                    dispatch(receiveCreateLinkPago(data));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateLinkPago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorCreateLinksPagos(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreateLinksPagos(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorCreateLinkPago(JSON.parse(error.message)));
                                if (error.data)
                                    dispatch(receiveCreateLinkPago(error.data));
                            }).catch(() => {
                                dispatch(errorCreateLinkPago(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//CREATE LINKSPAGOS
export const REQUEST_CREATE_LINKSPAGOS = "REQUEST_CREATE_LINKSPAGOS";
export const SUCCESS_CREATE_LINKSPAGOS = "SUCCESS_CREATE_LINKSPAGOS";
export const ERROR_CREATE_LINKSPAGOS = "ERROR_CREATE_LINKSPAGOS";
export const RESET_CREATE_LINKSPAGOS = "RESET_CREATE_LINKSPAGOS";

function requestCreateLinksPagos() {
    return {
        type: REQUEST_CREATE_LINKSPAGOS,
    }
}

function receiveCreateLinksPagos(linksPagos) {
    return {
        type: SUCCESS_CREATE_LINKSPAGOS,
        receivedAt: Date.now(),
        linksPagos: normalizeDatos(linksPagos)
    }
}

function errorCreateLinksPagos(error) {
    return {
        type: ERROR_CREATE_LINKSPAGOS,
        error: error,
    }
}

export function resetCreateLinksPagos() {
    return {
        type: RESET_CREATE_LINKSPAGOS,
    }
}

export function saveCreateLinksPagos() {
    return (dispatch, getState) => {
        dispatch(requestCreateLinksPagos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = merge({}, getState()[key].byId[key], getState()[key].update.activo, getState()[key].create.nuevo);
            });

        let linksPagos = getState().linksPagos.create.nuevos.map((idLinkPago) => {
            return denormalizeDato(getState().linksPagos.create.nuevo[idLinkPago], store);
        });

        return linksPagosApi.saveCreateLinksPagos(linksPagos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    //Refresco token
                    authUtil.addToken(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateLinksPagos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateLinksPagos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorCreateLinksPagos(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreateLinksPagos(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorCreateLinksPagos(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorCreateLinksPagos(errorMessages.GENERAL_ERROR));
                            });


                        return;
                }
            });
    }
}

//DELETE LINKPAGO
export const DELETE_LINKPAGO = 'DELETE_LINKPAGO';
export const REQUEST_DELETE_LINKPAGO = "REQUEST_DELETE_LINKPAGO";
export const SUCCESS_DELETE_LINKPAGO = "SUCCESS_DELETE_LINKPAGO";
export const ERROR_DELETE_LINKPAGO = "ERROR_DELETE_LINKPAGO";
export const RESET_DELETE_LINKPAGO = "RESET_DELETE_LINKPAGO";

function requestDeleteLinkPago() {
    return {
        type: REQUEST_DELETE_LINKPAGO,
    }
}

function receiveDeleteLinkPago(linkPago) {
    return {
        type: SUCCESS_DELETE_LINKPAGO,
        receivedAt: Date.now(),
        linkPago: normalizeDato(linkPago)
    }
}

function errorDeleteLinkPago(error) {
    return {
        type: ERROR_DELETE_LINKPAGO,
        error: error,
    }
}

export function resetDeleteLinkPago(error) {
    return {
        type: RESET_DELETE_LINKPAGO,
        error: error,
    }
}

export function deleteLinkPago(linkPago) {
    return {
        type: DELETE_LINKPAGO,
        linkPago
    }
}

export function saveDeleteLinkPago(linkPago) {
    return dispatch => {
        dispatch(requestDeleteLinkPago());
        return linksPagosApi.saveDelete(linkPago)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteLinkPago());
                dispatch(receiveDeleteLinkPago(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteLinkPago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorDeleteLinkPago(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorDeleteLinkPago(error.responseJSON.message));
                            }
                        else
                            error.json().then((error) => {
                                dispatch(errorDeleteLinkPago(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorDeleteLinkPago(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//PRINT LINKPAGO
export const REQUEST_PRINT_LINKPAGO = "REQUEST_PRINT_LINKPAGO";
export const SUCCESS_PRINT_LINKPAGO = "SUCCESS_PRINT_LINKPAGO";
export const ERROR_PRINT_LINKPAGO = "ERROR_PRINT_LINKPAGO";
export const RESET_PRINT_LINKPAGO = "RESET_PRINT_LINKPAGO";

function requestPrintLinkPago() {
    return {
        type: REQUEST_PRINT_LINKPAGO,
    }
}

function receivePrintLinkPago(turnos) {
    return {
        type: SUCCESS_PRINT_LINKPAGO,
        receivedAt: Date.now(),
        turnos: normalizeDatos(turnos)
    }
}

function errorPrintLinkPago(error) {
    return {
        type: ERROR_PRINT_LINKPAGO,
        error: error,
    }
}

export function resetPrintLinkPago() {
    return {
        type: RESET_PRINT_LINKPAGO,
    }
}

export function printLinkPago(idLinkPago) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestPrintLinkPago());
        return linksPagosApi.printLinkPago(idLinkPago)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log(nombreArchivo, tipoArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                let reader = new FileReader();
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                reader.onloadend = function () {
                    a.href = reader.result;
                    a.download = file.name;
                    a.click();
                };
                if (file) {
                    reader.readAsDataURL(file);
                }
                dispatch(receivePrintLinkPago(file));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorPrintLinkPago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorPrintLinkPago(JSON.parse(error.responseJSON.message)));
                        else
                            error.json().then((error) => {
                                dispatch(errorPrintLinkPago(JSON.parse(error.message)));
                            }).catch(() => {
                                dispatch(errorPrintLinkPago(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}