import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";

import Header from "../layout/HeaderLogo";

import "../../assets/css/Cotizacion.css";

import GoogleMapReact from "google-map-react";
import kit from "../../assets/img/kitLogo.png";
import {fetchDolarIfNeeded} from "../../actions/ListaPreciosActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

const Marcador = () => {
    return (
        <a href="https://goo.gl/maps/NvGNtRhqM2n" target="_blank">
            <img src={kit} alt="Kit Experto" className="marker-map" />
        </a>
    );
};

class Cotizacion extends Component {
    constructor(props) {
        super(props);
        this.state = {multiplicador: 1};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchDolarIfNeeded();
    }

    changeCotizacion(e) {
        switch (e.target.value) {
            case "1":
                this.setState({
                    multiplicador: 1,
                });
                break;
            case "2":
                this.setState({
                    multiplicador: 1,
                });
                break;
            case "3":
                this.setState({
                    multiplicador: 1.04,
                });
                break;
            case "4":
                this.setState({
                    multiplicador: 1.08,
                });
                break;
            case "5":
                this.setState({
                    multiplicador: 1.13,
                });
                break;
            case "6":
                this.setState({
                    multiplicador: 1.19,
                });
                break;
            default:
                this.setState({
                    multiplicador: 1,
                });
                break;
        }
    }

    createMapOptions() {
        return {
            panControl: false,
            mapTypeControl: false,
            scrollwheel: this.props.scroll,
            styles: [
                {
                    featureType: "all",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#dcedf5",
                        },
                    ],
                },
                {
                    featureType: "poi",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "all",
                    stylers: [
                        {
                            saturation: -70,
                        },
                    ],
                },
                {
                    featureType: "road",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#ffffff",
                        },
                    ],
                },
                {
                    featureType: "transit",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "off",
                        },
                    ],
                },
                {
                    featureType: "water",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "simplified",
                        },
                        {
                            saturation: -60,
                        },
                    ],
                },
            ],
        };
    }

    render() {
        let precio = this.props.listaPrecio.dolar.dolar
            ? parseFloat(
                  this.props.listaPrecio.dolar.dolar.dolar.replace(",", ".")
              )
            : 0;
        let cotizacion = (precio * this.state.multiplicador).toFixed(2);

        var marcadores = [];
        marcadores.push(<Marcador lat={-32.907258} lng={-60.6819261} />);

        return (
            <div className="container-fluid cotizacion">
                <Header />
                <Row>
                    <div className="container-limit">
                        <Col md={12}>
                            <h1>
                                Cotización del <b>dolar</b>
                            </h1>
                        </Col>
                    </div>
                </Row>
                <Row className="seccion-blanca">
                    <div className="container-limit cotizador">
                        <Col md={8} className="center-block">
                            <h5>
                                Cotización del día:{" "}
                                <span>
                                    {this.props.listaPrecio.dolar.dolar
                                        ? this.props.listaPrecio.dolar.dolar
                                              .fecha
                                        : ""}
                                </span>
                            </h5>

                            <div className="cotizador-select">
                                <select
                                    onChange={(e) => this.changeCotizacion(e)}
                                >
                                    <option value="1" disabled>
                                        Cotización U$D
                                    </option>
                                    <option value="2" selected>
                                        Contado o E-cheq 7 días
                                    </option>
                                    <option value="3">E-cheq 30 días</option>
                                    <option value="4">E-cheq 60 días</option>
                                    <option value="5">E-cheq 90 días</option>
                                    <option value="6">E-cheq 120 días</option>
                                </select>
                            </div>
                            <p>{"U$D 1 = $ " + cotizacion}</p>
                        </Col>
                    </div>
                </Row>
                <Row className="direccion">
                    <div className="container-limit">
                        <Col
                            md={6}
                            sm={6}
                            style={{height: "340px", display: "inline-table"}}
                        >
                            <div className="center-vertical">
                                <p>Ubicado en:</p>
                                <h3>José Hernández 767. Rosario</h3>
                            </div>
                        </Col>
                    </div>
                    <Col
                        md={6}
                        sm={6}
                        style={{paddingRight: "0px", paddingLeft: "0px"}}
                    >
                        <div style={{height: "340px", width: "100%"}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: "AIzaSyCkvwh0uiAf4V7eyViwaUp7ynGmVOJztdQ",
                                    language: "es",
                                }}
                                center={{lat: -32.907258, lng: -60.6819261}}
                                defaultCenter={{
                                    lat: -32.907258,
                                    lng: -60.6819261,
                                }}
                                defaultZoom={17}
                                zoomControl={false}
                                gestureHandling="none"
                                zoom={this.props.zoom}
                                options={this.createMapOptions.bind(this)}
                            >
                                {marcadores}
                            </GoogleMapReact>
                        </div>
                    </Col>
                </Row>
                <Row className="footer">
                    <a href="http://www.kitexperto.com">www.kitexperto.com</a>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        listaPrecio: state.listaPrecio,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDolarIfNeeded: () => {
            dispatch(fetchDolarIfNeeded());
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Cotizacion)
);
