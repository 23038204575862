//ACCESOSCUENTA
import accesosCuentaApi from "../api/accesosCuenta";
import * as errorMessages from "../constants/MessageConstants";
import {logout} from "./AuthenticationActions";
import normalizeDatos from "../normalizers/normalizeAccesosCuenta";
import auth from "../api/authentication";

export const REQUEST_ACCESOSCUENTA = 'REQUEST_ACCESOSCUENTA';
export const RECEIVE_ACCESOSCUENTA = 'RECEIVE_ACCESOSCUENTA';
export const INVALIDATE_ACCESOSCUENTA = 'INVALIDATE_ACCESOSCUENTA';
export const ERROR_ACCESOSCUENTA= "ERROR_ACCESOSCUENTA";


export function invalidateAccesosCuenta(accesosCuenta) {
    return {
        type: INVALIDATE_ACCESOSCUENTA,
        accesosCuenta
    }
}

function requestAccesosCuenta() {
    return {
        type: REQUEST_ACCESOSCUENTA,
    }
}

function receiveAccesosCuenta(json) {
    return {
        type: RECEIVE_ACCESOSCUENTA,
        accesosCuenta: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAccesosCuenta(error) {
    return {
        type: ERROR_ACCESOSCUENTA,
        error: error,
    }
}

function fetchAccesosCuenta() {
    return dispatch => {
        dispatch(requestAccesosCuenta());
        return accesosCuentaApi.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAccesosCuenta(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAccesosCuenta(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAccesosCuenta(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAccesosCuenta(state) {
    const accesosCuenta = state.accesosCuenta.byId;
    if (!accesosCuenta) {
        return true
    } else if (accesosCuenta.isFetching) {
        return false
    } else {
        return accesosCuenta.didInvalidate;
    }
}

export function fetchAccesosCuentaIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchAccesosCuenta(getState(),)) {
            return dispatch(fetchAccesosCuenta())
        }
    }
}


export const REQUEST_ACCESOCUENTA_USUARIO = 'REQUEST_ACCESOCUENTA_USUARIO';
export const RECEIVE_ACCESOCUENTA_USUARIO = 'RECEIVE_ACCESOCUENTA_USUARIO';
export const INVALIDATE_ACCESOCUENTA_USUARIO = 'INVALIDATE_ACCESOCUENTA_USUARIO';
export const ERROR_ACCESOCUENTA_USUARIO= "ERROR_ACCESOCUENTA_USUARIO";

function requestAccesoCuentaUsuario() {
    return {
        type: REQUEST_ACCESOCUENTA_USUARIO,
    }
}

function receiveAccesoCuentaUsuario(json) {
    return {
        type: RECEIVE_ACCESOCUENTA_USUARIO,
        accesosCuenta: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAccesoCuentaUsuario(error) {
    return {
        type: ERROR_ACCESOCUENTA_USUARIO,
        error: error,
    }
}

export function fetchAccesoCuentaUsuario(idCuenta, idUsuario) {
    return dispatch => {
        dispatch(requestAccesoCuentaUsuario());
        return accesosCuentaApi.getAccesoCuentaUsuario(idCuenta, idUsuario)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAccesoCuentaUsuario(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAccesoCuentaUsuario(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAccesoCuentaUsuario(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAccesosCuentaUsuario(state) {
    const accesosCuenta = state.accesosCuenta.byId;
    if (!accesosCuenta) {
        return true
    } else if (accesosCuenta.isFetching) {
        return false
    } else {
        return accesosCuenta.didInvalidate;
    }
}

export function fetchAccesosCuentaUsuarioIfNeeded(idCuenta, idUsuario) {
    return (dispatch, getState) => {
        if (shouldFetchAccesosCuentaUsuario(getState(),)) {
            return dispatch(fetchAccesoCuentaUsuario(idCuenta, idUsuario))
        }
    }
}



