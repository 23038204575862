import {
    CREATE_PULLSDK_USR,
    ERROR_CREATE_PULLSDK_USR,
    REQUEST_CREATE_PULLSDK_USR,
    RESET_CREATE_PULLSDK_USR,
    SUCCESS_CREATE_PULLSDK_USR
} from '../actions/sdk/pullSdk/PullSDKUsuariosActions';
import {combineReducers} from 'redux';


function create(state = {
    isCreating: false,
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_PULLSDK_USR:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case REQUEST_CREATE_PULLSDK_USR:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case ERROR_CREATE_PULLSDK_USR:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_PULLSDK_USR:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case SUCCESS_CREATE_PULLSDK_USR:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        default:
            return state
    }
}


const pullSdk = combineReducers({
    create: create,
});

export default pullSdk;