import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


//CSS
import '../../../assets/css/WifiSignal.css';

class WifiSignal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        var stock = this.props.stock;
        var idProducto = this.props.idProducto;
        if(stock == 0) {
            this.refs[idProducto+"barra1"].classList.add('ocultarBarrita');
            this.refs[idProducto+"barra2"].classList.add('ocultarBarrita');
            this.refs[idProducto+"barra3"].classList.add('ocultarBarrita');

        }
        else if(stock == 1) {
            this.refs[idProducto+"barra1"].classList.add('mostrarBarrita');
            this.refs[idProducto+"barra2"].classList.add('ocultarBarrita');
            this.refs[idProducto+"barra3"].classList.add('ocultarBarrita');
        }
        else if(stock == 2) {
            this.refs[idProducto+"barra1"].classList.add('mostrarBarrita');
            this.refs[idProducto+"barra2"].classList.add('mostrarBarrita');
            this.refs[idProducto+"barra3"].classList.add('ocultarBarrita');
        }
        else if(stock == 3) {
            this.refs[idProducto+"barra1"].classList.add('mostrarBarrita');
            this.refs[idProducto+"barra2"].classList.add('mostrarBarrita');
            this.refs[idProducto+"barra3"].classList.add('mostrarBarrita');
        }
    }

    render() {
        const {stock, idProducto} = this.props;
        return (
            <div className="signalStrength" style={{display: "table"}}>
                <div ref={idProducto+"barra1"}  className="very-weak"></div>
                <div ref={idProducto+"barra2"} className="weak"></div>
                <div ref={idProducto+"barra3"} className="strong-signal"></div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {}
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WifiSignal));