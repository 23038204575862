import {combineReducers} from 'redux';
import normalizeDatos from "../normalizers/normalizePreciosProducto";
import merge from "lodash/merge";
import union from "lodash/union";

//Actions
import {
    INVALIDATE_PRECIOSPRODUCTO,
    REQUEST_PRECIOSPRODUCTO,
    RECEIVE_PRECIOSPRODUCTO,
    ERROR_PRECIOSPRODUCTO,
    RESET_PRECIOSPRODUCTO,
    INVALIDATE_PRECIOSPRODUCTO_CONSUMIDOR,
    REQUEST_PRECIOSPRODUCTO_CONSUMIDOR,
    RECEIVE_PRECIOSPRODUCTO_CONSUMIDOR,
    ERROR_PRECIOSPRODUCTO_CONSUMIDOR,
    RESET_PRECIOSPRODUCTO_CONSUMIDOR,
    INVALIDATE_PRECIOSPRODUCTO_TUPRECIO,
    REQUEST_PRECIOSPRODUCTO_TUPRECIO,
    RECEIVE_PRECIOSPRODUCTO_TUPRECIO,
    ERROR_PRECIOSPRODUCTO_TUPRECIO,
    RESET_PRECIOSPRODUCTO_TUPRECIO,
    INVALIDATE_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
    REQUEST_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
    RECEIVE_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
    ERROR_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
    RESET_PRECIOSPRODUCTO_TUPRECIO_LISTA2

} from "../actions/PreciosProductoActions";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

function preciosProductoById(state = {
    isFetching: false,
    didInvalidate: true,
    isFetchingConsumidor: false,
    didInvalidateConsumidor: true,
    isFetchingTuPrecio: false,
    didInvalidateTuPrecio: true,
    isFetchingTuPrecioLista2: false,
    didInvalidateTuPrecioLista2: true,
    preciosProducto: {},
    preciosProductoConsumidor: {},
    preciosProductoTuPrecio: {},
    preciosProductoTuPrecioLista2: {},
    error: null,
}, action) {
    switch (action.type) {
        case INVALIDATE_PRECIOSPRODUCTO:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_PRECIOSPRODUCTO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_PRECIOSPRODUCTO:
            let dato = normalizeDatos(action.preciosProducto).entities.preciosProducto;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                preciosProducto: dato,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRECIOSPRODUCTO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRECIOSPRODUCTO:
            return Object.assign({}, state, {
                isFetching: false,
                preciosProducto: [],
                error: null,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                preciosProducto: {},
                preciosProductoConsumidor: {},
                preciosProductoTuPrecio: {},
                error: null,
            });
        //ConsumidorFinal
        case INVALIDATE_PRECIOSPRODUCTO_CONSUMIDOR:
            return Object.assign({}, state, {
                didInvalidateConsumidor: true,
                error: null,
            });
        case REQUEST_PRECIOSPRODUCTO_CONSUMIDOR:
            return Object.assign({}, state, {
                isFetchingConsumidor: true,
                didInvalidateConsumidor: false,
                error: null,
            });
        case RECEIVE_PRECIOSPRODUCTO_CONSUMIDOR:
            let datoCF = normalizeDatos(action.preciosProductoConsumidor).entities.preciosProducto;
            return Object.assign({}, state, {
                isFetchingConsumidor: false,
                didInvalidate: false,
                preciosProductoConsumidor: datoCF,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRECIOSPRODUCTO_CONSUMIDOR:
            return Object.assign({}, state, {
                isFetchingConsumidor: false,
                didInvalidateConsumidor: true,
                error: action.error
            });
        case RESET_PRECIOSPRODUCTO_CONSUMIDOR:
            return Object.assign({}, state, {
                isFetchingConsumidor: false,
                preciosProductoConsumidor: [],
                error: null,
            });
        //TuPrecio
        case INVALIDATE_PRECIOSPRODUCTO_TUPRECIO:
            return Object.assign({}, state, {
                didInvalidateTuPrecio: true,
                error: null,
            });
        case REQUEST_PRECIOSPRODUCTO_TUPRECIO:
            return Object.assign({}, state, {
                isFetchingTuPrecio: true,
                didInvalidateTuPrecio: false,
                error: null,
            });
        case RECEIVE_PRECIOSPRODUCTO_TUPRECIO:
            let datoTP = normalizeDatos(action.preciosProductoTuPrecio).entities.preciosProducto;
            return Object.assign({}, state, {
                isFetchingTuPrecio: false,
                didInvalidate: false,
                preciosProductoTuPrecio: datoTP,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRECIOSPRODUCTO_TUPRECIO:
            return Object.assign({}, state, {
                isFetchingTuPrecio: false,
                didInvalidateTuPrecio: true,
                error: action.error
            });
        case RESET_PRECIOSPRODUCTO_TUPRECIO:
            return Object.assign({}, state, {
                isFetchingTuPrecio: false,
                preciosProductoTuPrecio: [],
                error: null,
            });
        //TuPrecioLista2
        case INVALIDATE_PRECIOSPRODUCTO_TUPRECIO_LISTA2:
            return Object.assign({}, state, {
                didInvalidateTuPrecioLista2: true,
                error: null,
            });
        case REQUEST_PRECIOSPRODUCTO_TUPRECIO_LISTA2:
            return Object.assign({}, state, {
                isFetchingTuPrecioLista2: true,
                didInvalidateTuPrecioLista2: false,
                error: null,
            });
        case RECEIVE_PRECIOSPRODUCTO_TUPRECIO_LISTA2:
            let datoTP2 = normalizeDatos(action.preciosProductoTuPrecioLista2).entities.preciosProducto;
            return Object.assign({}, state, {
                isFetchingTuPrecioLista2: false,
                didInvalidate: false,
                preciosProductoTuPrecioLista2: datoTP2,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRECIOSPRODUCTO_TUPRECIO_LISTA2:
            return Object.assign({}, state, {
                isFetchingTuPrecioLista2: false,
                didInvalidateTuPrecioLista2: true,
                error: action.error
            });
        case RESET_PRECIOSPRODUCTO_TUPRECIO_LISTA2:
            return Object.assign({}, state, {
                isFetchingTuPrecioLista2: false,
                preciosProductoTuPrecioLista2: [],
                error: null,
            });
        default:
            return state
    }
}

function allPreciosProducto(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRECIOSPRODUCTO:
            return union(state, action.preciosProducto.result);
        case RESET_PRECIOSPRODUCTO:
            return [];
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

const preciosProducto = combineReducers({
    byId: preciosProductoById,
    allIds: allPreciosProducto,
});

export default preciosProducto;