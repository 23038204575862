import {
  CREATE_CONTACTO,
    ERROR_CREATE_CONTACTO,
    REQUEST_CREATE_CONTACTO,
    RESET_CREATE_CONTACTO,
    SUCCESS_CREATE_CONTACTO
} from '../actions/ContactoActions';
import {combineReducers} from 'redux';


import merge from "lodash/merge";


function create(state = {
    isCreating: false,
    nuevo: {},
    error: ""}, action) {
    switch (action.type) {
        case CREATE_CONTACTO:
            return merge({}, state, {
                isCreating: false,
                nuevo: action.contacto,
                error: null,
            });
        case REQUEST_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case ERROR_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_CONTACTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

const contacto = combineReducers({
    create: create,
});

export default contacto;