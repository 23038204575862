import React, {Component} from 'react';
import {Col, Row, Button} from 'react-bootstrap';
import '../../assets/css/Equipo.css';
import Footer2 from '../layout/Footer2';
import smHr from '../../assets/img/smHr.png';


class Equipo extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="topBorder">
                <section className="equipo">
                    <div className="cuadro-white col-md-5 center-block">
                        <h1>Tu Equipo. Tu Kit.</h1>
                        <p>Detrás de Kit Experto se encuentra un grupo de profesionales que trabaja todos los días para
                            entender las necesidades específicas del rubro, y para lograr más oportunidades para
                            nuestros socios registrados; porque entendemos que nuestro éxito depende del tuyo.
                        </p>
                    </div>
                </section>
                <section className="historia">
                    <div className="container-limit">
                        <Row>
                            <Col md={6} className="sin-padding">
                                <div className="anios">
                                    <h2>15 años</h2>
                                    <p>
                                        Desde 2001 trabajamos en seguridad electrónica.
                                        Planificamos, coordinamos, ejecutamos y proveímos a
                                        miles de proyectos en la provincia de Santa Fe.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6} className="sin-padding">
                                <div className="linea-bottom">
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>

                        <Row>
                            <Col md={3} className="sin-padding">
                                <div className="linea-bottom">

                                </div>
                            </Col>
                            <Col md={3} className="sin-padding">
                                <div className="entregados">
                                    <p>Productos<br/>entregados e instalados</p>
                                </div>
                            </Col>
                            <Col md={6} className="sin-padding">
                                <div className="nro-entregados">
                                    <h2>+50.000</h2>
                                    <p>
                                        Hoy nos acompañan las mejores marcas,
                                        para que ese número siga creciendo.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{
                            marginRight: "110px",
                            marginTop: "60px",
                            marginBottom: "80px",
                            position: "relative",
                            left: "86%"
                        }}/>

                        <Row>
                            <Col md={6} className="sin-padding">
                                <div className="anios anios-especialistas">
                                    <h2>Especialistas</h2>
                                    <p>
                                        en cada segmento
                                    </p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="items-tareas">
                                    <ul>
                                        <li>
                                            <p>Alarmas</p>
                                        </li>
                                        <li>
                                            <p>Electrónica</p>
                                        </li>
                                        <li>
                                            <p className="recuadro">CCTV</p>
                                        </li>
                                        <li>
                                            <p>Proyectos</p>
                                        </li>
                                        <li>
                                            <p>Logística</p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md={3} className="sin-padding">
                                <div className="linea-cctv">

                                </div>
                            </Col>
                        </Row>
                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>

                    </div>
                </section>


                <section className="historia-responsive">
                    <div className="container-limit">
                        <Row>
                            <Col md={3} sm={6} className="center-block">
                                <div className="anios">
                                    <h2>15 años</h2>
                                    <p>
                                        Desde 2001 trabajamos en seguridad electrónica.
                                        Planificamos, coordinamos, ejecutamos y proveímos a
                                        miles de proyectos en la provincia de Santa Fe.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{margin: "30px auto", display: "block"}}/>

                        <Row>
                            <Col md={3} sm={6} className="center-block">
                                <div className="entregados">
                                    <p>Productos<br/>entregados e instalados</p>
                                    <h2>+50.000</h2>
                                    <p>
                                        Hoy nos acompañan las mejores marcas,
                                        para que ese número siga creciendo.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{margin: "30px auto", display: "block"}}/>


                        <Row>
                            <Col md={3} sm={6} className="center-block">
                                <div className="anios anios-especialistas">
                                    <h2>Especialistas</h2>
                                    <p>
                                        en cada segmento:
                                    </p>

                                <div className="items-tareas">
                                    <ul>
                                        <li>
                                            <p>Alarmas</p>
                                        </li>
                                        <li>
                                            <p>Electrónica</p>
                                        </li>
                                        <li>
                                            <p className="recuadro">CCTV</p>
                                        </li>
                                        <li>
                                            <p>Proyectos</p>
                                        </li>
                                        <li>
                                            <p>Logística</p>
                                        </li>
                                    </ul>
                                </div>
                                </div>
                            </Col>
                            <Col md={3} className="">
                                <div className="linea-cctv">

                                </div>
                            </Col>
                        </Row>
                        <img src={smHr} alt="" style={{margin: "30px auto", display: "block"}}/>


                    </div>
                </section>


                <section className="llamanos">
                    <div className="container-limit">
                        <Row>
                            <Col md={9} className="pull-right">
                                <h3 className="invisible">Entregas en <br/>
                                    <sb> todo </sb>
                                    el país
                                </h3>
                                <p>0810-3450724</p>
                            </Col>

                        </Row>
                    </div>
                </section>

                <section className="mejores-proyectos">
                    <div className="container-limit">
                        <Row>
                            <Col md={8} className="center-block">
                                <h2>Mejores Proyectos</h2>
                                <p>
                                    La totalidad de los que hacemos Kit Experto tenemos una mirada en común: sabemos que
                                    trabajar en equipo rinde sus frutos. Así formamos nuestra empresa. Hace un tiempo
                                    creímos que toda nuestra experiencia en el rubro y con el cliente final, podría
                                    impulsar los proyectos de nuestros clientes integradores e instaladores. ¿Por qué?
                                    Porque la necesidad de conformar una base sólida y confiable detrás de cada trabajo,
                                    es siempre el mayor de los desafios. Es simple, los mejores proyectos se hacen en
                                    equipo.
                                </p>
                                <Button onClick={()=>this.props.history.push("/equipo")}>¡Hagamos Equipo!</Button>
                                <br/><br/>
                                <a onClick={()=>this.props.history.push("/registrar")} style={{cursor: "cursor"}}>Registrate gratis</a>
                            </Col>
                        </Row>
                    </div>
                </section>
                <Footer2/>
            </div>
        );
    }
}

export default Equipo;