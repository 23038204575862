import {
    CREATE_REGISTRO,
    REQUEST_CREATE_REGISTRO,
    SUCCESS_CREATE_REGISTRO,
    ERROR_CREATE_REGISTRO,
    RESET_CREATE_REGISTRO,
    UPDATE_REGISTRO,
    ERROR_UPDATE_REGISTRO,
    RESET_UPDATE_REGISTRO,
    REQUEST_UPDATE_REGISTRO,
    SUCCESS_UPDATE_REGISTRO
} from '../actions/RegistroActions';
import {combineReducers} from 'redux';

function create(state = {
    isCreating: false,
    error: "",
    message:""
}, action) {
    switch (action.type) {
        case CREATE_REGISTRO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message:null
            });
        case REQUEST_CREATE_REGISTRO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
                message:null
            });
        case SUCCESS_CREATE_REGISTRO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message: action.message
            });
        case ERROR_CREATE_REGISTRO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error,
                message: null,
            });
        case RESET_CREATE_REGISTRO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message: null,
            });
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    error: "",
    message:""
}, action) {
    switch (action.type) {
        case UPDATE_REGISTRO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                message:null
            });
        case REQUEST_UPDATE_REGISTRO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null,
                message:null
            });
        case SUCCESS_UPDATE_REGISTRO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                message: action.message
            });
        case ERROR_UPDATE_REGISTRO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error,
                message: null,
            });
        case RESET_UPDATE_REGISTRO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                message: null,
            });
        default:
            return state
    }
}


const registros = combineReducers({
    create: create,
    update: update,
});

export default registros;