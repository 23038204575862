import c from "../../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var pullsdk = {

    saveCreate(usuario, idHabilitacion, codAcceso) {


        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify({usuario, idHabilitacion, codAcceso}),
        };

        return fetch(c.BASE_URL + '/pullSDK', defaultOptions);
    },

};

export default pullsdk;