import {combineReducers} from 'redux';
import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from "../actions/PedidoActions";
import merge from "lodash/merge";
import union from "lodash/union";

function packingListById(state = {
    isFetching: false,
    didInvalidate: true,
    packingList: {}
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                packingList: merge({}, state.packingList, action.pedidos.entities.packingList),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                packingList: {},
                error: null
            });
        default:
            return state
    }
}


function allPackingList(state = [], action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return action.pedidos.entities.packingList ? union(state, Object.keys(action.pedidos.entities.packingList)) : state;
        case RESET_PEDIDOS:
            return [];
        default:
            return state
    }

}

const packingList = combineReducers({
    byId: packingListById,
    allIds: allPackingList,
});

export default packingList;