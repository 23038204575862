import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var contacto = {

    saveCreate(contacto) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(contacto)
        };

        return fetch(c.BASE_URL + '/contacto', defaultOptions);
    },
}

export default contacto;