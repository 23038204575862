//api
import listaPrecios from "../api/listaPrecios";

import * as errorMessages from '../constants/MessageConstants';

//Actions
import {logout} from "../actions/AuthenticationActions";
import encuestas from "../api/encuestas";

//Normalizer
import normalizeDatos from "../normalizers/normalizeListaPrecios";
import auth from "../api/authentication";

var FileSaver = require('file-saver');

//IMPRIMIR
export const REQUEST_IMPRIMIR_LISTAPRECIOS = "REQUEST_IMPRIMIR_LISTAPRECIOS";
export const SUCCESS_IMPRIMIR_LISTAPRECIOS = "SUCCESS_IMPRIMIR_LISTAPRECIOS";
export const ERROR_IMPRIMIR_LISTAPRECIOS = "ERROR_IMPRIMIR_LISTAPRECIOS";
export const DOWNLOADING_IMPRIMIR_LISTAPRECIOS = "DOWNLOADING_IMPRIMIR_LISTAPRECIOS";
export const FINISH_DOWNLOADING_IMPRIMIR_LISTAPRECIOS = "FINISH_DOWNLOADING_IMPRIMIR_LISTAPRECIOS";


//IMPRESION
function requestImpresionListaPrecios() {
    return {
        type: REQUEST_IMPRIMIR_LISTAPRECIOS,
    }
}

function receiveImpresionListaPrecios(total) {
    return {
        type: SUCCESS_IMPRIMIR_LISTAPRECIOS,
        receivedAt: Date.now(),
        total
    }
}

function downloadingImpresionListaPrecios(bytesDescargados) {
    return {
        type: DOWNLOADING_IMPRIMIR_LISTAPRECIOS,
        bytesDescargados: bytesDescargados
    }
}

function finishDownloadingImpresionListaPrecios() {
    return {
        type: FINISH_DOWNLOADING_IMPRIMIR_LISTAPRECIOS
    }
}

function errorImpresionListaPrecios(error) {
    return {
        type: ERROR_IMPRIMIR_LISTAPRECIOS,
        error: error,
    }
}

function consume(reader, dispatch) {
    var total = 0;
    var blob = [];
    var i = 0;
    return new Promise((resolve, reject) => {
        function pump() {
            reader.read().then(({done, value}) => {
                if (done) {
                    // console.log(blob);
                    // resolve(new Blob(blob), {
                    //     type: "application/vnd.ms-excel"
                    // });
                    resolve(blob);
                    return
                }
                total += value.byteLength;
                blob.push(value);
                i++;
                //if(i%20==0)
                dispatch(downloadingImpresionListaPrecios(total));
                // console.log(value.byteLength,total);
                // console.log(value);
                pump()
            }).catch(() => {
                reject();
            })
        }

        pump()
    })
}


export function imprimirListaPrecios() {
    return (dispatch, getState) => {
        dispatch(requestImpresionListaPrecios());

        return listaPrecios.imprimir()
            .then(
                response => {
                    if (response.status >= 400) {
                        return Promise.reject(response);
                    }
                    else {
                        var total = 0;
                        response.headers.forEach(function (val, key) {
                            console.log(key + ' -> ' + val);
                            if (key.toLowerCase() == "content-length") {
                                total = parseInt(val);
                                return;
                            }
                        });
                        console.log(response);
                        dispatch(receiveImpresionListaPrecios(total));
                        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                            //Para firefox de pc que no anda porq no existe el body con el StreamReader
                            dispatch(downloadingImpresionListaPrecios(0));
                            return response.blob();
                        }
                        else
                            return consume(response.body.getReader(), dispatch);
                        //return response.blob();
                    }

                })
            .then(
                blob => {
                    dispatch(finishDownloadingImpresionListaPrecios());
                    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
                    //Para firefox de pc que no anda
                        FileSaver.saveAs(blob, "ListaPreciosKit.xls");
                    else {
                        var file = new File(blob, "ListaPreciosKit.xls", {type: "application/vnd.ms-excel"});
                        FileSaver.saveAs(file);
                    }
                }
            )
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorImpresionListaPrecios(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorImpresionListaPrecios(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//FECHALISTAPRECIO
export const REQUEST_FECHALISTAPRECIO = 'REQUEST_FECHALISTAPRECIO';
export const RECEIVE_FECHALISTAPRECIO = 'RECEIVE_FECHALISTAPRECIO';
export const INVALIDATE_FECHALISTAPRECIO = 'INVALIDATE_FECHALISTAPRECIO';
export const ERROR_FECHALISTAPRECIO = "ERROR_FECHALISTAPRECIO";


export function invalidateFechaListaPrecio(pedido) {
    return {
        type: INVALIDATE_FECHALISTAPRECIO,
        pedido
    }
}

function requestFechaListaPrecio() {
    return {
        type: REQUEST_FECHALISTAPRECIO,
    }
}

function receiveFechaListaPrecio(fechaListaPrecio) {
    return {
        type: RECEIVE_FECHALISTAPRECIO,
        receivedAt: Date.now(),
        fechaListaPrecio
    }
}

function errorFechaListaPrecio(error) {
    return {
        type: ERROR_FECHALISTAPRECIO,
        error: error,
    }
}

function fetchFechaListaPrecio() {
    return dispatch => {
        dispatch(requestFechaListaPrecio());
        return listaPrecios.getUltimaFechaActualizacion()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    console.log(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                console.log(data);
                dispatch(receiveFechaListaPrecio(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorFechaListaPrecio(errorMessages.UNAUTHORIZED_TOKEN));
                        // dispatch(logout());
                        return;
                    default:
                        dispatch(errorFechaListaPrecio(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchFechaListaPrecio(state) {
    const listaPrecio = state.listaPrecio.byId;
    if (!listaPrecio) {
        return true
    } else if (listaPrecio.isFetching) {
        return false
    } else {
        return listaPrecio.didInvalidate;
    }
}

export function fetchFechaListaPrecioIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchFechaListaPrecio(getState())) {
            return dispatch(fetchFechaListaPrecio())
        }
    }
}

//DOLAR
export const REQUEST_DOLAR = 'REQUEST_DOLAR';
export const RECEIVE_DOLAR = 'RECEIVE_DOLAR';
export const INVALIDATE_DOLAR = 'INVALIDATE_DOLAR';
export const ERROR_DOLAR = "ERROR_DOLAR";


export function invalidateDolar() {
    return {
        type: INVALIDATE_DOLAR,
    }
}

function requestDolar() {
    return {
        type: REQUEST_DOLAR,
    }
}

function receiveDolar(dolar) {
    return {
        type: RECEIVE_DOLAR,
        receivedAt: Date.now(),
        dolar
    }
}

function errorDolar(error) {
    return {
        type: ERROR_DOLAR,
        error: error,
    }
}

function fetchDolar() {
    return dispatch => {
        dispatch(requestDolar());
        return listaPrecios.getDolar()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDolar(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDolar(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDolar(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchDolar(state) {
    const dolar = state.listaPrecio.dolar;
    if (!dolar) {
        return true
    } else if (dolar.isFetching) {
        return false
    } else {
        return dolar.didInvalidate;
    }
}

export function fetchDolarIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchDolar(getState())) {
            return dispatch(fetchDolar())
        }
    }
}

//LISTAPRECIOS
export const REQUEST_LISTAPRECIOS = 'REQUEST_LISTAPRECIOS';
export const RECEIVE_LISTAPRECIOS = 'RECEIVE_LISTAPRECIOS';
export const INVALIDATE_LISTAPRECIOS = 'INVALIDATE_LISTAPRECIOS';
export const ERROR_LISTAPRECIOS = "ERROR_LISTAPRECIOS";
export const RESET_LISTAPRECIOS = "RESET_LISTAPRECIOS";


export function invalidateListaPrecios() {
    return {
        type: INVALIDATE_LISTAPRECIOS,
    }
}

function requestListaPrecios() {
    return {
        type: REQUEST_LISTAPRECIOS,
    }
}

function receiveListaPrecios(json) {
    return {
        type: RECEIVE_LISTAPRECIOS,
        listaPrecio: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorListaPrecios(error) {
    return {
        type: ERROR_LISTAPRECIOS,
        error: error,
    }
}

export function resetListaPrecios(error) {
    return {
        type: RESET_LISTAPRECIOS,
        error: error,
    }
}

function fetchListaPrecios() {
    return dispatch => {
        dispatch(requestListaPrecios());
        return listaPrecios.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveListaPrecios(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorListaPrecios(errorMessages.UNAUTHORIZED_TOKEN));
                        //dispatch(logout());
                        return;
                    default:
                        dispatch(errorListaPrecios(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchListaPrecios(state) {
    const listaPrecio = state.listaPrecio.byId;
    if (!listaPrecio) {
        return true
    } else if (listaPrecio.isFetching) {
        return false
    } else if(listaPrecio.didInvalidate) {
        return true;
    } else if(state.listaPrecio.allIds.length == 0) {
        return true;
    }
}

export function fetchListaPreciosIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchListaPrecios(getState(),)) {
            return dispatch(fetchListaPrecios())
        }
    }
}

//LISTAPRECIO
export const REQUEST_LISTAPRECIO = 'REQUEST_LISTAPRECIO';
export const RECEIVE_LISTAPRECIO = 'RECEIVE_LISTAPRECIO';
export const INVALIDATE_LISTAPRECIO = 'INVALIDATE_LISTAPRECIO';
export const ERROR_LISTAPRECIO = "ERROR_LISTAPRECIO";
export const RESET_LISTAPRECIO = "RESET_LISTAPRECIO";


export function invalidateListaPrecio() {
    return {
        type: INVALIDATE_LISTAPRECIO,
    }
}

function requestListaPrecio() {
    return {
        type: REQUEST_LISTAPRECIO,
    }
}

function receiveListaPrecio(json) {
    return {
        type: RECEIVE_LISTAPRECIO,
        listaPrecio: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorListaPrecio(error) {
    return {
        type: ERROR_LISTAPRECIO,
        error: error,
    }
}

export function resetListaPrecio(error) {
    return {
        type: RESET_LISTAPRECIO,
        error: error,
    }
}

function fetchListaPrecio(idLista) {
    return dispatch => {
        dispatch(requestListaPrecio());
        return listaPrecios.getById(idLista)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveListaPrecio(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorListaPrecio(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorListaPrecio(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchListaPrecio(state, idLista) {
    const listaPrecio = state.listaPrecio.byId;
    if (!listaPrecio) {
        return true
    } else if (listaPrecio.isFetching) {
        return false
    } else if (listaPrecio.listaPrecio[idLista]) {
        return false
    } else {
        return listaPrecio.didInvalidate;
    }
}

export function fetchListaPrecioIfNeeded(idLista) {
    return (dispatch, getState) => {
        if (shouldFetchListaPrecio(getState(), idLista)) {
            return dispatch(fetchListaPrecio(idLista))
        }
    }
}

//UPDATE LISTAPRECIO
export const UPDATE_LISTAPRECIO = 'UPDATE_LISTAPRECIO';
export const REQUEST_UPDATE_LISTAPRECIO = "REQUEST_UPDATE_LISTAPRECIO";
export const SUCCESS_UPDATE_LISTAPRECIO = "SUCCESS_UPDATE_LISTAPRECIO";
export const ERROR_UPDATE_LISTAPRECIO = "ERROR_UPDATE_LISTAPRECIO";
export const RESET_UPDATE_LISTAPRECIO = "RESET_UPDATE_LISTAPRECIO";

function requestUpdateListaPrecio() {
    return {
        type: REQUEST_UPDATE_LISTAPRECIO,
    }
}

function receiveUpdateListaPrecio() {
    return {
        type: SUCCESS_UPDATE_LISTAPRECIO,
        receivedAt: Date.now()
    }
}

function errorUpdateListaPrecio(error) {
    return {
        type: ERROR_UPDATE_LISTAPRECIO,
        error: error,
    }
}

export function resetUpdateListaPrecio() {
    return {
        type: RESET_UPDATE_LISTAPRECIO
    }
}

export function updateListaPrecio(listaPrecio) {
    return {
        type: UPDATE_LISTAPRECIO,
        listaPrecio
    }
}

export function saveUpdateListaPrecio() {
    return (dispatch, getState) => {
        dispatch(requestUpdateListaPrecio());

        return listaPrecios.saveUpdate(getState().listaPrecio.update.activo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveUpdateListaPrecio());
                }
            })
            .catch(function (error) {
                console.log(error.status);
                console.log(error.message);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateListaPrecio(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateListaPrecio(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}