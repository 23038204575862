/* eslint import/no-webpack-loader-syntax: off */
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {Col, Row, Button} from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';


import "../../assets/css/Soluciones.css";

import SliderMarca from "../secciones/SliderMarca";
import Footer2 from "../layout/Footer2";

import icon1 from '../../assets/img/iconSoluciones/1.png';
import icon2 from '../../assets/img/iconSoluciones/2.png';
import icon3 from '../../assets/img/iconSoluciones/3.png';
import icon4 from '../../assets/img/iconSoluciones/4.png';
import icon5 from '../../assets/img/iconSoluciones/5.png';
import icon6 from '../../assets/img/iconSoluciones/6.png';
import icon7 from '../../assets/img/iconSoluciones/7.png';
import lgHr from "../../assets/img/longHr.png";
import xy from "../../assets/img/xy.png";
import infa from '../../assets/img/compraInfalible.png';
import mayores from "../../assets/img/iconSoluciones/mayoresVentas.png";
import capacit from "../../assets/img/iconSoluciones/capGratis.png";
import financi from "../../assets/img/iconSoluciones/financiacion.png";
import herrami from "../../assets/img/iconSoluciones/herramientas.png";
import compros from "../../assets/img/iconSoluciones/compromiso.png";
import present from "../../assets/img/iconSoluciones/presentacion.png";
import asesora from "../../assets/img/iconSoluciones/asesoramiento.png";
import licitac from "../../assets/img/iconSoluciones/licitacion.png";

class Soluciones extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.refs.nombres)
            this.refs.nombres.to(0, 100);
    }

    constructor(props) {
        super(props);
    }

    sincronizarConNombre(evento) {
        console.log(evento);
        if (this.refs.nombres)
            this.refs.nombres.to(evento.page.index, 100);
    }

    render() {

        return (

            <div className="soluciones topBorder">
                <section className="mejores-proyectos notebook">
                    <div className="container-limit">
                        <Row>
                            <Col md={12} className="center-block">
                                <h2>¿Cuál es tu<br/>próximo proyecto?</h2>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="mejores-proyectos">
                    <div className="container-limit">
                        <Row>
                            <Col md={7} className="center-block">
                                <h2>Mejores Proyectos</h2>
                                <p>
                                    Kit Experto es el combinado de productos y servicios que tu
                                    trabajo estaba necesitando. Un set de herramientas informáticas, recursos
                                    administrativos y un equipo de profesionales
                                    te acompañan en cada nuevo desafío.
                                    ¡Los mejores proyectos se hacen en equipo!
                                </p>
                                <Button onClick={() => this.props.history.push("/registrar")}>Registrate gratis</Button>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="como-ayudamos">
                    <Row>
                        <h2>¿Cómo podemos ayudarte?</h2>
                    </Row>
                    <div className="container-fluid">

                        <Row className="hidden-sm hidden-xs">
                            <OwlCarousel ref="nombres"
                                         className="owl-text" items={5} dots={false} center={true} loop={true}
                                         rewind={false} mouseDrag={false} touchDrag={false}
                                         navText="">
                                <div className="item">
                                    <p>Seguimiento técnico-comercial de proyectos</p>
                                </div>
                                <div className="item">
                                    <p>Compra-profesional</p>
                                </div>
                                <div className="item">
                                    <p>Capacitaciones gratuitas</p>
                                </div>
                                <div className="item">
                                    <p>Restocking sin cargo</p>
                                </div>
                                <div className="item">
                                    <p>Facturación a tu medidad</p>
                                </div>
                                <div className="item">
                                    <p>Diagnostico RMA en 48 hs</p>
                                </div>
                                <div className="item">
                                    <p>Asesoramiento en desarrollo de proyectos</p>
                                </div>
                            </OwlCarousel>
                        </Row>
                        <Row className="slider">
                            <OwlCarousel ref="imagenes"
                                         className="owl-theme"
                                         onChanged={(e) => {
                                             this.sincronizarConNombre(e)
                                         }}
                                         loop={true}
                                         nav={true}
                                         items={1}
                                         navText="">
                                <div className="item">
                                    <p>Seguimiento técnico-comercial de proyectos</p>
                                    <img src={icon1} alt=""/>
                                </div>
                                <div className="item">
                                    <p>Compra-profesional</p>
                                    <img src={icon2} alt=""/>
                                </div>
                                <div className="item">
                                    <p>Restocking sin cargo</p>
                                    <img src={icon3} alt=""/>
                                </div>
                                <div className="item">
                                    <p>Restocking sin cargo</p>
                                    <img src={icon4} alt=""/>
                                </div>
                                <div className="item">
                                    <p>Facturación a tu medidad</p>
                                    <img src={icon5} alt=""/>
                                </div>
                                <div className="item">
                                    <p>Diagnostico RMA en 48 hs</p>
                                    <img src={icon6} alt=""/>
                                </div>
                                <div className="item">
                                    <p>Asesoramiento en desarrollo de proyectos</p>
                                    <img src={icon7} alt=""/>
                                </div>
                            </OwlCarousel>
                        </Row>
                    </div>

                    <img className="img-responsive" src={lgHr} alt=""
                         style={{margin: "0 auto", display: "block", marginTop: "40px"}}/>

                    <Row>
                        <div className="xy-grafico">
                            <img src={xy} alt=""/>
                            <p>Los intregradores e instaladores que trabajan con
                                Kit Experto obtienen proyectos 30% más grandes</p>
                        </div>
                    </Row>
                </section>

                <SliderMarca/>
                <section className="compra-infalible">
                    <div className="container-limit">
                        <Row>
                            <Col md={8} sm={7}>
                                <img className="img-responsive" src={infa} alt=""/>
                            </Col>
                            <Col md={4} sm={5}>

                                <h2>Sumate a la compra profesional</h2>
                                <p>Desarrollamos una plataforma para estimular y generar nuevas oportunidades.
                                    Dinamizamos las distintas etapas de venta, envío, entrega, instalación y soporte;
                                    para que cada profesional concentre su labor en lo que mejor sabe hacer. Siendo
                                    usuario registrado de Kit Experto, podés obtener la verificación y sumarte vos
                                    también. </p>

                                <a style={{cursor: "cursor"}} onClick={() => this.props.history.push("/registrar")}>Registrate
                                    gratis</a>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="soluciones-registrate">
                    <div className="container-limit">
                        <Row>
                            <Col md={6} className="center-block">
                                <h2>¡Registrate Hoy!</h2>
                                <p>
                                    Obtené gratis todos los beneficios de trabajar con nosotros,
                                    con tu cuenta certificada.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} className="center-block">
                                <Row>
                                    <Col md={6}>
                                        <div className="features">
                                            <img src={mayores} alt=""/>
                                            <p>Mayores Ventas.</p>
                                        </div>
                                        <div className="features">
                                            <img src={capacit} alt=""/>
                                            <p>Capacitación gratuita.</p>
                                        </div>
                                        <div className="features">
                                            <img src={financi} alt=""/>
                                            <p>Financiación.</p>
                                        </div>
                                        <div className="features">
                                            <img src={herrami} alt=""/>
                                            <p>Herramientas administrativas.</p>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="features">
                                            <img src={compros} alt=""/>
                                            <p>Compromiso y absoluta reserva
                                                en proyectos.</p>
                                        </div>
                                        <div className="features">
                                            <img src={present} alt=""/>
                                            <p>Presentaciones ante directorios.</p>
                                        </div>
                                        <div className="features">
                                            <img src={asesora} alt=""/>
                                            <p>Asesoramiento sin cargo.</p>
                                        </div>
                                        <div className="features">
                                            <img src={licitac} alt=""/>
                                            <p>Licitación de obras.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7} className="center-block" style={{textAlign: "center"}}>
                                <Button onClick={() => this.props.history.push("/registrar")}>Registrate gratis</Button>
                            </Col>
                        </Row>
                    </div>
                </section>
                <Footer2/>
            </div>
        )
            ;
    }
}

export default withRouter(Soluciones);