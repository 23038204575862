import mediosPago from "../api/mediosPago";
import * as errorMessages  from '../constants/MessageConstants';
import {logout} from "../actions/AuthenticationActions";


//MEDIOSPAGO
export const REQUEST_MEDIOSPAGO = 'REQUEST_MEDIOSPAGO';
export const RECEIVE_MEDIOSPAGO = 'RECEIVE_MEDIOSPAGO';
export const INVALIDATE_MEDIOSPAGO = 'INVALIDATE_MEDIOSPAGO';
export const ERROR_MEDIOSPAGO= "ERROR_MEDIOSPAGO";
export const RESET_MEDIOSPAGO= "RESET_MEDIOSPAGO";


export function invalidateMediosPago(plan) {
    return {
        type: INVALIDATE_MEDIOSPAGO,
        plan
    }
}

export function resetMediosPago(plan) {
    return {
        type: RESET_MEDIOSPAGO,
        plan
    }
}

function requestMediosPago() {
    return {
        type: REQUEST_MEDIOSPAGO,
    }
}

function receiveMediosPago(json) {
    return {
        type: RECEIVE_MEDIOSPAGO,
        mediosPago: json,
        receivedAt: Date.now()
    }
}

function errorMediosPago(error) {
    return {
        type: ERROR_MEDIOSPAGO,
        error: error,
    }
}

export function fetchMediosPago(filtros) {
    return dispatch => {
        dispatch(requestMediosPago());
        return mediosPago.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMediosPago(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorMediosPago(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMediosPago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchMediosPago(state) {
    const mediosPago = state.mediosPago.byId;
    if (!mediosPago) {
        return true
    } else if (mediosPago.isFetching) {
        return false
    } else {
        return mediosPago.didInvalidate;
    }
}

export function fetchMediosPagoIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchMediosPago(getState())) {
            return dispatch(fetchMediosPago(filtros))
        }
    }
}

