import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import "./BotonPago.css";
import {Col, Row} from "react-bootstrap";
//img
import compartir from "../../../../assets/img/pago/compartir.png";
import guardarAhora from "../../../../assets/img/pago/guardarAhora.png";
import Confirmado from "../../../../assets/img/pago/confirmado.png"
//actions
import {fetchCiudadesIfNeeded, invalidateCiudades, resetCiudades} from "../../../../actions/CiudadActions";
import {
    createPago,
    fetchLogoPago,
    fetchPago,
    resetCreatePago,
    resetLogoPago,
    resetUpdatePago,
    saveCreatePago,
    saveUpdatePago,
    updatePago
} from "../../../../actions/PagoActions";
import {fetchPlanesCuotasIfNeeded, resetPlanesCuotas} from "../../../../actions/PlanesCuotasActions";
import {fetchMediosPago, resetMediosPago} from "../../../../actions/MediosPagoActions";
import {fetchProvinciasIfNeeded, resetProvincias} from "../../../../actions/ProvinciasActions";
import {resetUsuarios} from "../../../../actions/UsuarioActions";
import {resetEstadosPago} from "../../../../actions/EstadosPagoActions";
//constants
import * as EstadoPago from "../../../../constants/EstadoPago";
//lodash
import max from "lodash/max";
import volver from "../../../../assets/img/pago/volver.png";
import AutosuggestUsuarios from "./AutosuggestUsuarios";
import * as roles from "../../../../constants/Roles";
import {fetchFileLinkPago} from "../../../../actions/LinkPagoActions";

export default function LinkPagoCliente(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();
    const mounted = useRef();
    const mounted2 = useRef();
    const mounted3 = useRef();
    const inputToken = useRef();
    const inputLogo = useRef();
    const previewLogo = useRef();
    //Store
    const pagos = useSelector(state => state.pagos);
    const linksPagos = useSelector(state => state.linksPagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;
    const planesCuotas = useSelector(state => state.planesCuotas);
    const mediosPago = useSelector(state => state.mediosPago);
    const provincias = useSelector(state => state.provincias);
    const ciudades = useSelector(state => state.ciudades);
    const usuarios = useSelector(state => state.usuarios);
    const usuarioLogueado = usuarios.byId.usuarioLogueado;
    const estadosPago = useSelector(state => state.estadosPago);

    //State
    const [fileLogo, setFileLogo] = useState(null);
    const [modalToken, setModalToken] = useState(false);
    const [modalValidarLogo, setModalValidarLogo] = useState(false);

    //Evaluar PERMISOS para editar
    var esEmpleadoKit = usuarioLogueado && (
        usuarioLogueado.idRol == roles.ADMINISTRACION_ID || usuarioLogueado.idRol == roles.ADMINISTRADOR_ID || usuarioLogueado.idRol == roles.COMMUNITY_MANAGER_ID
        || usuarioLogueado.idRol == roles.VENDEDOR_ID || usuarioLogueado.idRol == roles.DESPACHO_ID);
    const ultimoEstadoPago = estadosPago.byId.estadosPago[max(pago.estados_pago)];
    const calcEstadoActivo = () => {
        if (ultimoEstadoPago)
            switch (ultimoEstadoPago.desEstadoPago) {
                case EstadoPago.LINK_GENERADO:
                    return 0;
                case EstadoPago.PENDIENTE:
                    return 1;
                case EstadoPago.CONFIRMADO:
                    return 2;
                case EstadoPago.CONFIRMADO_DIFERENCIAS:
                    return 3;
                case EstadoPago.RECHAZADO:
                    return 4;
                default:
                    return null;

            }
    };
    const estadoPagoActual = calcEstadoActivo();
    const disabled = idPago && ((usuarioLogueado && (usuarioLogueado.id !== pago.idUsuarioCreacion || usuarioLogueado.id !== pago.idUsuario)) || estadoPagoActual >= 1 || pago.idTipoPago === 2);

    //Effects
    useEffect(() => {
        dispatch(fetchPlanesCuotasIfNeeded());
        dispatch(fetchProvinciasIfNeeded());
        dispatch(fetchMediosPago({estado: true, habilitadoLink: true}));
        if (idPago)
            dispatch(fetchPago(idPago));

        //Por defecto tarjeta credito
        if (!idPago) {
            let cambio = {};
            cambio.idMedioPago = 3;
            cambio.colorPrimario = "#00aaeb";
            cambio.colorSecundario = "#00ffea";
            dispatch(createPago(cambio));
        }

        return function cleanup() {
            dispatch(resetMediosPago());
            dispatch(resetCreatePago());
            dispatch(resetUpdatePago());
            dispatch(resetUsuarios());
            dispatch(resetEstadosPago());
            dispatch(resetPlanesCuotas());
            dispatch(resetProvincias());
            dispatch(resetCiudades());
            dispatch(resetLogoPago());
            setFileLogo(null);
            previewLogo.current.src = "";
        }
    }, []);

    //Cuando creo un pago pasa a la modificacion
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!pagos.create.isCreating && !pagos.create.error) {
                props.history.push("/panel/pagos/" + pagos.create.nuevo.id);
                setFileLogo(null);
                previewLogo.current.src = "";
                dispatch(fetchPago(pagos.create.nuevo.id));
                dispatch(resetCreatePago());
                dispatch(resetLogoPago());
                window.scrollTo(0, 0);
            }
        }
    }, [pagos.create.isCreating]);

    //Cuando acutualizo un pago pasa a la modificacion
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!pagos.update.isUpdating && !pagos.update.error) {
                setFileLogo(null);
            }
        }
    }, [pagos.update.isUpdating]);

    //Cuando se recupera un pago busco las ciudades de la provincia
    useEffect(() => {
        if (!mounted2.current) {
            mounted2.current = true;
        } else {
            if (!pagos.byId.isFetching && pago && pago.idProvincia) {
                dispatch(invalidateCiudades());
                dispatch(fetchCiudadesIfNeeded(pago.idProvincia));
            }
        }
    }, [pagos.byId.isFetching]);

    //La primera vez cargo el logo
    useEffect(() => {
        if (!mounted3.current) {
            mounted3.current = true;
        } else {
            if (pago.logo && !fileLogo) {
                // dispatch(fetchLogoPago(pago.id));
                dispatch(fetchFileLinkPago(pago.idLinkPago, {type: "producto"}));
                // previewLogo.current = "loading";
            }
        }

    }, [pago.logo]);

    //Hago la preview del logo del backend
    useEffect(() => {
        if(linksPagos.byId.files && Object.keys(linksPagos.byId.files).length === 1) {
            //Cargar foto
            let fileSubido = linksPagos.byId.files[(Object.keys(linksPagos.byId.files)[0])];
            if (fileSubido) {
                let reader = new FileReader();
                reader.onloadend = function () {
                    previewLogo.current.src = reader.result;
                };
                if (fileSubido) {
                    reader.readAsDataURL(fileSubido);
                }
            }
        }
    }, [linksPagos.byId.files]);

    //Hago la preview del logo del cliente
    useEffect(() => {
        //Logo
        if (!idPago)
            cargarLogoCliente();
    }, [usuarios.logo]);


    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id === "idMedioPago")
            cambio[e.target.id] = e.target.checked ? parseInt(e.target.value) : null;
        if (e.target.id === "importe")
            cambio[e.target.id] = parseFloat(e.target.value) ? parseFloat(e.target.value) : null;
        if (e.target.id === "idPlanCuotas")
            cambio[e.target.id] = parseInt(e.target.value) ? parseInt(e.target.value) : null;
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };

    const onChangeOpcionesProvincia = (ev) => {
        dispatch(invalidateCiudades());
        dispatch(fetchCiudadesIfNeeded(ev.target.value));
        onChangePago(ev);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!idPago) {
            let cambio = {};
            cambio.idTipoPago = 1;
            dispatch(createPago(cambio));
            dispatch(saveCreatePago(null, fileLogo));
        } else
            dispatch(saveUpdatePago(null, fileLogo));
    };

    const validarLogo = (e) => {
        e.preventDefault();
        let cambio = {};
        cambio.validadoLogo = 1;
        dispatch(updatePago(cambio));
        dispatch(saveUpdatePago(null, fileLogo));
        setModalValidarLogo(false);
    };

    const copiarToken = (e) => {
        inputToken.current.select();
        inputToken.current.setSelectionRange(0, 99999999999); /*For mobile devices*/
        document.execCommand("copy");
    };

    const verImagen = (e) => {
        e.preventDefault();
        var file = inputLogo.current.files[0];
        var reader = new FileReader();
        setFileLogo(file);

        reader.onloadend = function () {
            previewLogo.current.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            previewLogo.current.src = "";
        }
    };

    const cargarLogoCliente = () => {
        var preview = previewLogo.current;
        if (usuarios.logo.logo && usuarios.logo.logo.size > 0) {
            var file = usuarios.logo.logo;
            setFileLogo(file);
            var fileReader = new FileReader();
            fileReader.onload = function () {
                preview.src = this.result;
            };
            fileReader.readAsDataURL(file);
        }
    };

    return (
        <div className="linkpago">
            <form onSubmit={(e) => onSubmit(e)}>

                <div>
                    {
                        (esEmpleadoKit || (usuarioLogueado && usuarioLogueado.idRol === roles.DISTRIBUIDOR_ID)) &&
                        <Row className="container-formulario">
                            <label htmlFor="idUsuario">Usuario al cual se le acreditará</label>
                            <AutosuggestUsuarios onChange={onChangePago} disabled={disabled || !!idPago}
                                                 usuarioSeleccionado={pago.idUsuario ? usuarios.byId.usuarios[pago.idUsuario] : null}/>
                        </Row>
                    }
                    <Row className="container-formulario">
                        <div className="input-row">
                            <label htmlFor="importe">Importe en pesos (iva incluido)</label>
                            <input id="importe" type="number" required
                                   name="importe"
                                   disabled={disabled}
                                   placeholder="Importe en pesos (iva incluido)"
                                   value={pago && pago.importe ? pago.importe : ""}
                                   onChange={(e) => onChangePago(e)}
                            />
                        </div>
                    </Row>
                    <Row className="container-formulario">
                        <h2>Forma de pago</h2>
                        {
                            mediosPago.allIds.map((idMedioPago) => {
                                let medioPago = mediosPago.byId.mediosPago[idMedioPago];
                                return (
                                    <div key={"mp-" + idMedioPago} style={{display: "flex", alignItems: "flex-end"}}>
                                        <div className="item-checkradio">
                                            <input type="radio" name="idMedioPago"
                                                   id="idMedioPago"
                                                   className={disabled ? "disabled" : ""}
                                                   required
                                                   disabled={disabled}
                                                   checked={pago.idMedioPago === medioPago.id}
                                                   value={medioPago.id}
                                                   onChange={(e) => onChangePago(e)}
                                            />
                                            <label>{medioPago ? medioPago.nomMedioPago : ""}</label>
                                        </div>
                                        {
                                            medioPago && medioPago.codMedioPago === "TCR" &&
                                            <div className="input-row" style={{marginLeft: "20px"}}>
                                                <label htmlFor="idPlanCuotas">Elija un plan de cuotas predeterminado
                                                    para que vea tu cliente</label>
                                                <select
                                                    id="idPlanCuotas"
                                                    value={pago && pago.idPlanCuotas ? pago.idPlanCuotas : ""}
                                                    disabled={!pago || !pago.idMedioPago || pago.idMedioPago != 3 || disabled}
                                                    onChange={(e) => onChangePago(e)}
                                                >
                                                    <option value="">Todos</option>
                                                    {
                                                        planesCuotas.allIds.map((id) => {
                                                            let planCuotas = planesCuotas.byId.planesCuotas[id];
                                                            return (
                                                                <option key={id}
                                                                        value={planCuotas.id}>{planCuotas.desPlan}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        <br/>
                        {
                            pago && pago.importe && pago.idPlanCuotas && planesCuotas.byId.planesCuotas[pago.idPlanCuotas] &&
                            <div>
                                <p>{`El importe total a cobrar a tu cliente es de $${(pago.importe * planesCuotas.byId.planesCuotas[pago.idPlanCuotas].coeficiente).toFixed(2)}`}</p>
                                <p>{`Lo va a pagar en ${planesCuotas.byId.planesCuotas[pago.idPlanCuotas].cuotas} cuotas de $${((pago.importe * planesCuotas.byId.planesCuotas[pago.idPlanCuotas].coeficiente) / planesCuotas.byId.planesCuotas[pago.idPlanCuotas].cuotas).toFixed(2)}`}</p>
                            </div>
                        }
                        {
                            pago && pago.importe &&
                            <p>{`Con la transacción finalizada se acreditará la suma de $${pago.importe} (valorizada en dólares a tasa de cambio del BNA ventas al momento de efectuada la transacción)`}</p>
                        }


                    </Row>
                    <Row className="container-formulario">
                        <h2>Referencia del servicio que se va a cobrar</h2>
                        <Col xs={12} md={8}>
                            <div className="input-row">
                                <label htmlFor="nombre">Describa el servicio a cobrar</label>
                                <textarea id="referencia" required
                                          name="referencia"
                                          disabled={disabled}
                                          placeholder="Ej: Sistema de alarma compuesto por dos infrarojos, un magnético y una sirena interior."
                                          value={pago && pago.referencia ? pago.referencia : ""}
                                          onChange={(e) => onChangePago(e)}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="logo">
                                <img ref={previewLogo}/>
                            {/*    <button type="button" onClick={() => inputLogo.current.click()}>Cargar logo</button>*/}
                            {/*</div>*/}
                            {/*<input type="file" ref={inputLogo} accept="image/png, image/jpeg" style={{display: "none"}}*/}
                            {/*       multiple={false}*/}
                            {/*       disabled={disabled} onChange={(e) => verImagen(e)}/>*/}
                            {/*<div className="colores">*/}
                            {/*    <div className="input-row">*/}
                            {/*        <label htmlFor="nombre">Color primario</label>*/}
                            {/*        <input type="color" id="colorPrimario"*/}
                            {/*               value={pago && pago.colorPrimario ? pago.colorPrimario : ""}*/}
                            {/*               disabled={disabled} onChange={(e) => onChangePago(e)}/>*/}
                            {/*    </div>*/}
                            {/*    <div className="input-row">*/}
                            {/*        <label htmlFor="nombre">Color secundario</label>*/}
                            {/*        <input type="color" id="colorSecundario"*/}
                            {/*               value={pago && pago.colorSecundario ? pago.colorSecundario : ""}*/}
                            {/*               disabled={disabled} onChange={(e) => onChangePago(e)}/>*/}
                            {/*    </div>*/}
                            </div>

                        </Col>

                    </Row>
                    <Row className="container-formulario">
                        <h2>Datos de contacto de quien realizó el pago</h2>
                        <Col md={6} xs={12}>
                            <div className="input-row">
                                <label htmlFor="nombre">Nombre</label>
                                <input id="nombre" type="text" required
                                       name="nombre"
                                       placeholder="Nombre"
                                       disabled={disabled}
                                       value={pago && pago.nombre ? pago.nombre : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                            <div className="input-row">
                                <label htmlFor="apellido">Apellido</label>
                                <input id="apellido" type="text" required
                                       name="apellido"
                                       placeholder="Apellido"
                                       value={pago && pago.apellido ? pago.apellido : ""}
                                       disabled={disabled}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <div className="input-row">
                                <label htmlFor="email">Email</label>
                                <input id="email" type="text" required
                                       name="email"
                                       disabled={disabled}
                                       placeholder="Email"
                                       value={pago && pago.email ? pago.email : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                            <div className="input-row">
                                <label htmlFor="celular">Celular</label>
                                <input id="celular" type="text" required
                                       name="celular"
                                       disabled={disabled}
                                       placeholder="Celular"
                                       value={pago && pago.celular ? pago.celular : ""}
                                       onChange={(e) => onChangePago(e)}
                                />
                            </div>
                            {/*<div className="input-row">*/}
                            {/*    <label htmlFor="direccionInstalacion">Dirección de la instalación</label>*/}
                            {/*    <input id="direccionInstalacion" type="text" required*/}
                            {/*           name="direccionInstalacion"*/}
                            {/*           placeholder="Dirección de la instalación"*/}
                            {/*           disabled={disabled}*/}
                            {/*           value={pago && pago.direccionInstalacion ? pago.direccionInstalacion : ""}*/}
                            {/*           onChange={(e) => onChangePago(e)}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className="input-row">*/}
                            {/*    <label htmlFor="idPlanCuotas">Provincia</label>*/}
                            {/*    <select*/}
                            {/*        id="idProvincia"*/}
                            {/*        required*/}
                            {/*        value={pago ? pago.idProvincia : ""}*/}
                            {/*        disabled={disabled}*/}
                            {/*        // disabled={pedido.modoCuentaYOrden}*/}
                            {/*        onChange={(e) => onChangeOpcionesProvincia(e)}*/}
                            {/*    >*/}
                            {/*        <option value="">Seleccione una provincia</option>*/}
                            {/*        {*/}
                            {/*            provincias.allIds.map((key) => {*/}
                            {/*                var provincia = provincias.byId.provincias[key];*/}
                            {/*                return (*/}
                            {/*                    <option key={"prov-" + key}*/}
                            {/*                            value={provincia ? provincia.id : ""}>{provincia ? provincia.nombreProvincia : ""}</option>*/}
                            {/*                )*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            {/*<div className="input-row">*/}
                            {/*    <label htmlFor="idLocalidad">*/}
                            {/*        Localidad*/}
                            {/*    </label>*/}
                            {/*    <select*/}
                            {/*        id="idLocalidad"*/}
                            {/*        required*/}
                            {/*        disabled={disabled}*/}
                            {/*        value={pago ? pago.idLocalidad : ""}*/}
                            {/*        onChange={(e) => onChangePago(e)}*/}
                            {/*    >*/}
                            {/*        <option value="">Seleccione localidad</option>*/}
                            {/*        {*/}
                            {/*            ciudades.allIds.map((key) => {*/}
                            {/*                var ciudad = ciudades.byId.ciudades[key];*/}
                            {/*                return (*/}
                            {/*                    <option key={"local" + key}*/}
                            {/*                            value={parseInt(key)}>{ciudad.nombreCiudad}</option>*/}
                            {/*                )*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </Col>
                    </Row>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-izq">
                                <button type="button" className="boton-redondo outline"
                                        onClick={() => props.history.push("/panel/listaMonitoresPagos/1")}>
                                    <img src={volver}/>
                                    Volver
                                </button>
                                {
                                    !!idPago && pago.idTipoPago === 1 &&
                                    <button type="button" className="boton-redondo" onClick={() => setModalToken(true)}>
                                        <img src={compartir}/>
                                        Compartir
                                    </button>
                                }
                                {
                                    !!idPago && pago.idTipoPago === 1 && esEmpleadoKit &&
                                    <button type="button" className="boton-redondo outline"
                                            onClick={() => setModalValidarLogo(true)}
                                            disabled={pago.validadoLogo === 1}>
                                        <img src={Confirmado}/>
                                        Validar logo
                                    </button>
                                }
                            </div>
                            <div className="botonera-der">
                                <button type="submit" className="boton-redondo"
                                        disabled={disabled || pagos.update.isUpdating || pagos.create.isCreating}>
                                    <img src={guardarAhora}/>
                                    Guardar pago
                                </button>
                            </div>
                        </div>

                    </Row>
                </div>
            </form>
            {
                modalToken &&
                <div className="modal-token">
                    <h2>Copia el enlace para compartir el link de pago</h2>
                    <Row>
                        <div className="input-row">
                            <input id="token" ref={inputToken} type="text" required
                                   name="token"
                                   placeholder="token"
                                   readOnly
                                   value={pago && pago.token ? "https://linkdepago.mialarma.com.ar/" + pago.token : ""}
                            />
                        </div>
                        {
                            pago && pago.validadoLogo === 0 &&
                            <i>Su link funcionará sin logo hasta que la plataforma de Kit Experto lo autorice dentro de
                                las 24hs</i>
                        }

                    </Row>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-der"></div>
                            <div className="botonera-izq">
                                <button className="boton-default" onClick={() => setModalToken(false)}>
                                    Cerrar
                                </button>
                                <button className="boton-default" onClick={copiarToken}>
                                    Copiar
                                </button>
                            </div>
                        </div>
                    </Row>
                </div>
            }

            {
                modalValidarLogo &&
                <div className="modal-token">
                    <h2>Está seguro que desea autorizar el logo del link de pago?</h2>
                    <Row>
                        <div className="botonera">
                            <div className="botonera-der"></div>
                            <div className="botonera-izq">
                                <button className="boton-default" onClick={() => setModalValidarLogo(false)}>
                                    Cerrar
                                </button>
                                <button className="boton-default" onClick={validarLogo}>
                                    Validar
                                </button>
                            </div>
                        </div>
                    </Row>
                </div>
            }

        </div>
    )
        ;
}
