import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter, Route,Link} from "react-router-dom";
import {Switch} from 'react-router';

import kit from '../../../assets/img/evento/kitAzul.png';

import '../../../assets/css/CheckInSiera.css';
import FormCheckInPss from "./FormCheckInPss";
import ErrorCheckInPss from './ErrorCheckInPss';
import OkCheckInPss from './OkCheckInPss';


class CheckInPss extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="evento-pss">
                <div className="container-limit">
                    <p className="copete">CHECK IN</p>
                    <div className="header" style={{marginBottom:"70px"}}>
                        <div className="hash-container">
                            <h1>#tpekit</h1>
                            <h2>Smart PSS</h2>
                        </div>

                        <Link to="/"><img src={kit} className="pull-right kitAzul" alt=""/></Link>
                    </div>

                    <Row>
                        <Col md={12} className="center-block">
                            <Switch>
                                <Route path="/checkin-pss" exact component={FormCheckInPss}/>
                                <Route path="/checkin-pss/ok" component={OkCheckInPss}/>
                                <Route path="/checkin-pss/error" component={ErrorCheckInPss}/>
                            </Switch>

                        </Col>
                    </Row>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}


const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckInPss));
