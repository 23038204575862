//api
import auth from "../api/authentication";
//constants
import * as errorMessages  from '../constants/MessageConstants';


//UPDATE CUENTA
export const UPDATE_CUENTA = 'UPDATE_CUENTA';
export const REQUEST_UPDATE_CUENTA = "REQUEST_UPDATE_CUENTA";
export const SUCCESS_UPDATE_CUENTA = "SUCCESS_UPDATE_CUENTA";
export const ERROR_UPDATE_CUENTA = "ERROR_UPDATE_CUENTA";
export const RESET_UPDATE_CUENTA = "RESET_UPDATE_CUENTA";

function requestUpdateCuenta() {
    return {
        type: REQUEST_UPDATE_CUENTA,
    }
}

function receiveUpdateCuenta() {
    return {
        type: SUCCESS_UPDATE_CUENTA,
        receivedAt: Date.now()
    }
}

function errorUpdateCuenta(error) {
    return {
        type: ERROR_UPDATE_CUENTA,
        error: error,
    }
}

export function resetUpdateCuenta() {
    return {
        type: RESET_UPDATE_CUENTA
    }
}

export function updateCuenta(cuenta) {
    return {
        type: UPDATE_CUENTA,
        cuenta
    }
}

// export function saveUpdateCuentas() {
//     return (dispatch, getState) => {
//         dispatch(requestUpdateCuentas());
//
//         return cuenta.saveUpdate(getState().usuarios.byId.usuarioLogueado, getState().usuarios.logo.logo)
//             .then(function (response) {
//                 if (response.status >= 400) {
//                     return Promise.reject(response);
//                 }
//                 else {
//                     dispatch(receiveUpdateCuentas());
//                 }
//             })
//             .catch(function (error) {
//                 console.log(error.status);
//                 console.log(error.message);
//                 switch (error.status) {
//                     case 401:
//                         dispatch(errorUpdateCuentas(errorMessages.UNAUTHORIZED_TOKEN));
//                         dispatch(logout());
//                         return;
//                     default:
//                         dispatch(errorUpdateCuentas(errorMessages.GENERAL_ERROR));
//                         return;
//                 }
//             });
//     }
// }