import {combineReducers} from 'redux';
import {
    ERROR_FORMA_DE_PAGO,
    RECEIVE_FORMA_DE_PAGO,
    REQUEST_FORMA_DE_PAGO,
    RESET_FORMA_DE_PAGO,
    INVALIDATE_FORMA_DE_PAGO
} from "../actions/FormaDePagoActions";

function formasDePagoById(state = {
    isFetching: false,
    didInvalidate: true,
    formasDePago: {},
}, action) {
    switch (action.type) {
        case INVALIDATE_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                formasDePago: action.formaDePago ? action.formaDePago.entities.formaDePago : {},
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                formasDePago: {},
                error: null,
            });
        default:
            return state
    }
}

function allFormasDePago(state = [], action) {
    switch (action.type) {
        case RECEIVE_FORMA_DE_PAGO:
            return action.formaDePago ? action.formaDePago.result : [];
        default:
            return state
    }
}

const formasDePago = combineReducers({
    byId: formasDePagoById,
    allIds: allFormasDePago,
});

export default formasDePago;