import moment from 'moment';
require("../../node_modules/moment/locale/es");
moment.locale("es");

var fechasFormat = {

    formatFecha(fechahora){
        if (fechahora != null) {
            var fecha = fechahora.split(" ")[0];
            return fecha.split("-").reverse().join("/");
        }
        else
            return "";
    },

    formatFechaTypeDate(fechahora){
        if (fechahora != null) {
            var fecha = fechahora.split(" ")[0];
            return fecha.split("-").join("-");
        }
        else
            return "";
    },
    addHours(date, hours) {
        var fechaInicial = moment(date);
        fechaInicial.add(hours, 'h');
        if (fechaInicial.isoWeekday() == 7)
            fechaInicial.add(24, 'h');
        return fechaInicial.format("DD/MM/YYYY HH:mm");
    },
    formatHora(fechaHora){
        if (fechaHora != null) {
            var horaFormat = fechaHora.split(":")[0] + ":" + fechaHora.split(":")[1];
            return horaFormat;
        }
        else
            return "";
    },
    formatFechaHora(fechahora){
        if (fechahora != null) {
            var fecha = fechahora.split(" ")[0];
            var hora = fechahora.split(" ")[1]
            return fecha.split("-").reverse().join("/") + " " + hora;
        }
        else
            return "";
    },

    convertToDate(str1){
        if (typeof(str1) == "string") {
            var dt1 = parseInt(str1.substring(8, 10));
            var mon1 = parseInt(str1.substring(5, 7));
            var yr1 = parseInt(str1.substring(0, 4));
            var h = parseInt(str1.substring(11, 14));
            var m = parseInt(str1.substring(14, 17));
            var s = parseInt(str1.substring(17, 20));
            var date1 = new Date(yr1, mon1 - 1, dt1, h, m, s);
            return date1;
        } else
            return str1;
    },

    formatDate(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();

        return day + '/' + month + '/' + year;
    }

};

export default fechasFormat;