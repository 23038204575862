import {
    ERROR_GANADORES,
    INVALIDATE_GANADORES,
    RECEIVE_GANADORES,
    REQUEST_GANADORES,
    RESET_GANADORES
} from '../actions/GanadoresActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";

import normalizeDatos from '../normalizers/normalizeGanadores';

function ganadoresById(state = {
    isFetching: false,
    didInvalidate: true,
    ganadores: [],
    datosGanadores:{}
}, action) {
    switch (action.type) {
        case INVALIDATE_GANADORES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case RESET_GANADORES:
            return Object.assign({}, state, {
                isFetching: false,
                ganadores: [],
                datosGanadores:{}
            });
        case REQUEST_GANADORES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_GANADORES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                ganadores: normalizeDatos(action.ganadores).entities.ganadores,
                datosGanadores: normalizeDatos(action.ganadores).entities.usuarios,
                lastUpdated: action.receivedAt
            });
        case ERROR_GANADORES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allGanadores(state = [], action) {
    switch (action.type) {
        case RECEIVE_GANADORES:
            return normalizeDatos(action.ganadores).result;
        default:
            return state
    }
}

const ganadores = combineReducers({
    byId: ganadoresById,
    allIds: allGanadores,
});

export default ganadores;