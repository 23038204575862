import * as decidirConstants from "../../constants/DecidirConstants";
import { FormDecidir } from "./FormDecidir";

const obtenerTokenDecidir = async (tarjeta) => {
    return new Promise(function (resolve, reject) {
        console.log(window)
        let Decidir = window.Decidir

        let publicApiKey = null;
        let url = null;
        if (decidirConstants.AMBIENT === "test") {
            url = decidirConstants.URL_SANDBOX;
            publicApiKey = decidirConstants.TEST_PUBLIC_API_KEY;
        } else if (decidirConstants.AMBIENT === "prod") {
            url = decidirConstants.URL_PRODUCCION;
            publicApiKey = decidirConstants.PROD_PUBLIC_API_KEY
        }

        const decidir = new Decidir(url, false);
        //Se indica la public API Key
        decidir.setPublishableKey(publicApiKey);
        decidir.setTimeout(20000);//timeout de 5 segundos
        //formulario
        var formDecidir = new FormDecidir(tarjeta.nroTarjeta, tarjeta.titular, tarjeta.mesVencimiento, tarjeta.anoVencimiento, tarjeta.codigoSeguridad, tarjeta.dni);
        //Asigna la funcion de invocacion al evento de submit del formulario
        //funcion para manejar la respuesta
        let htmlString = new DOMParser().parseFromString(formDecidir.formTemplate, "text/html").firstChild;

        let res = decidir.createToken(htmlString, sdkResponseHandler);//formulario y callback
        console.log("afuera", res);
        return res;

        function sdkResponseHandler(status, response) {
            console.log("status", status);
            console.log("response", response);
            if (status != 200 && status != 201) {
                //Manejo de error: Ver Respuesta de Error
                //...codigo...
                reject(response);
            } else {
                resolve(response);
                //Manejo de respuesta donde response = {token: "99ab0740-4ef9-4b38-bdf9-c4c963459b22"}
                //..codigo...
            }
        }
    })
}

export default obtenerTokenDecidir;