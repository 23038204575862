import React from 'react';
import {
    FormGroup,
    ControlLabel,
    HelpBlock,
    FormControl,
    Label,
    Glyphicon,
    InputGroup
} from 'react-bootstrap';

import lupa from '../../assets/img/evento/eventoRoad/search.png';
import "../../assets/css/FieldGroupSubmit.css";

function FieldGroupButton({id, label, help, colorLbl, textLbl, ...props}) {
    return (
        <FormGroup controlId={id} style={{cursor: "pointer"}} className="FieldGroupButton">
            <ControlLabel>{label}</ControlLabel>
            <Label className={colorLbl} style={{paddingTop: "5px", marginLeft: "10px"}}>{textLbl}</Label>
            <InputGroup>
                <FormControl {...props} />
                <InputGroup.Addon>
                    <button type="submit"><img src={lupa} alt=""/></button>
                </InputGroup.Addon>
            </InputGroup>
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>

    );
}

export default FieldGroupButton;