import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Cleave from 'cleave.js/react';
//Api
import fechasFormat from "../../api/fechas";
//CSS
import "../../assets/css/Encuesta.css";
//Actions
import {
    changeUsuarioLogueado,
    errorCreateUsuarioLogueado,
    fetchUsuarioLogueadoIfNeeded,
    fetchUsuarioPorCampo,
    saveUsuarioLogueado,
    updateUsuario
} from "../../actions/UsuarioActions";
import {fetchCiudadesIfNeeded, invalidateCiudades} from '../../actions/CiudadActions';
import {fetchProvinciasIfNeeded, invalidateProvincias} from '../../actions/ProvinciasActions';
import {fetchEncuestasIfNeeded} from '../../actions/EncuestaActions';
import {createEncuestaRespuestas, updateEncuestaRespuestas} from '../../actions/EncuestaRespuestaActions';
import {updateLogo} from "../../actions/ClienteActions";
import {fetchPaisesIfNeeded} from '../../actions/PaisActions';
//Constants
import * as roles from "../../constants/Roles";
//Images
import flechaAbajo from "../../assets/img/flechitaAbajoEncuesta.png";
import Loading from "../../assets/img/loader.gif"
//Components
import Footer2 from '../../components/layout/Footer2';
import Autosuggest from 'react-autosuggest';

//Require
require('cleave.js/dist/addons/cleave-phone.ar');
var match = require('autosuggest-highlight/match');
var parse = require('autosuggest-highlight/parse');

class Encuesta extends Component {

    constructor(props) {
        super(props);

        //mapa para desactivar encuestas
        let mapaDesactivar = new Map();
        //mapa para ver relaciones de encuestas en onChange
        this.mapaRelacionesEncuestas = new Map();

        this.state = {
            provinciaSeleccionada: null,
            provinciaEmpresaSeleccionada: null,
            ciudadesEmpresa: null,
            ciudadesPersonal: null,
            modo: null,
            mapaDesactivar: mapaDesactivar,
            campoBuscado: '',
            dni: '',
            email: '',
            suggestionsUsuarios: this.getSuggestionsUsuarios(''),
            valueUsuarios: '',
            idEncuestaAutosuggest: '',
        };

        this.onChangeUsuarios = this.onChangeUsuarios.bind(this);
        this.onSuggestionsUpdateRequestedUsuarios = this.onSuggestionsUpdateRequestedUsuarios.bind(this);
        this.onSuggestionsClearRequestedUsuarios = this.onSuggestionsClearRequestedUsuarios.bind(this);
        this.getSuggestionsUsuarios = this.getSuggestionsUsuarios.bind(this);
        this.getSuggestionValueMostrarUsuarios = this.getSuggestionValueMostrarUsuarios.bind(this);
        this.renderSuggestionUsuarios = this.renderSuggestionUsuarios.bind(this);
        this.escapeRegexCharacters = this.escapeRegexCharacters.bind(this);

    }

    componentDidMount() {
        this.props.fetchEncuestasIfNeeded();
        // Uso el invalidateProvincias para que cambie el store y se ejecute el DidUpdate
        this.props.invalidateProvincias();
        this.props.fetchProvinciasIfNeeded();
        this.props.fetchPaisesIfNeeded();
        if (this.props.authentication.token) {
            this.props.fetchUsuarioLogueadoIfNeeded();
        }

        window.scrollTo(0, 0);
        if (!this.props.authentication.token && !this.props.usuarios.byId.usuarioLogueado.idRol) {
            this.props.history.push("/registrar");
        }
        //Armo el mapaDesactivar si las encuestas y sus respuestas ya estaban en el store y no se me ejecuta el DidUpdate
        let mapaDesactivar = new Map();
        if (this.props.encuestas.allIds.length > 0 && this.props.encuestaRespuestas.allIds.length > 0) {
            this.props.encuestas.allIds.map((idEncuesta) => {
                let encuesta = this.props.encuestas.byId.encuestas[idEncuesta];
                let respuestas = [];
                this.props.encuestaRespuestas.allIds.some((idRespuesta) => {
                    let respuesta = this.props.encuestaRespuestas.byId.encuestaRespuestas[idRespuesta];
                    if (respuesta.idEncuesta == idEncuesta) {
                        respuesta.fechaUltMdf = fechasFormat.convertToDate(respuesta.fechaUltMdf);
                        respuestas.push(respuesta);
                    }
                });
                respuestas.sort((a, b) => {
                    if (a.fechaUltMdf > b.fechaUltMdf) return -1;
                    if (a.fechaUltMdf < b.fechaUltMdf) return 1;
                    if (a.fechaUltMdf == b.fechaUltMdf) return 0;
                });
                var ultimaRespuesta = respuestas[0];

                //Si el autosuggest tiene respuesta la muestro, guardo idEncuesta para actualizar la respuesta en onChange
                if (encuesta.tipoEncuesta == 'AutoSuggest') {
                    this.setState({idEncuestaAutosuggest: idEncuesta});
                    if (ultimaRespuesta)
                        this.setState({valueUsuarios: ultimaRespuesta.desRespuesta});
                }

                if (ultimaRespuesta && encuesta.encuestasRelacionadas) {
                    let idEncuestasRelacionadas = (encuesta.encuestasRelacionadas).split(',');
                    for (let idEncRel of idEncuestasRelacionadas) {
                        this.mapaRelacionesEncuestas.set(idEncuesta, {
                            relaciones: encuesta.encuestasRelacionadas,
                            valorDesactivar: encuesta.valorDesactivar
                        });
                        if (idEncRel != null && idEncRel != "") {
                            if (ultimaRespuesta.desRespuesta == encuesta.valorDesactivar) {
                                mapaDesactivar.set(parseInt(idEncRel), true);
                            } else {
                                mapaDesactivar.set(parseInt(idEncRel), false);
                            }
                        }
                    }
                }

            });
            this.setState({
                mapaDesactivar: mapaDesactivar,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.usuarios.byId.usuarioLogueado.id
            && ((this.state.provinciaSeleccionada == null && this.props.usuarios.byId.usuarioLogueado.ciudad != null)
                || (this.props.usuarios.byId.usuarioLogueado.idRol == roles.CORPORATIVO_ID && this.state.provinciaEmpresaSeleccionada == null
                    && this.props.usuarios.byId.usuarioLogueado.ciudad_empresa != null)) && !this.props.ciudades.byId.isFetching) {

            var idProvincia = null;
            this.props.invalidateCiudades();
            if (this.state.provinciaSeleccionada == null) {
                idProvincia = this.props.usuarios.byId.usuarioLogueado.ciudad.idProvincia;
                this.setState({modo: 'Personal', provinciaSeleccionada: idProvincia});
            } else if (this.state.provinciaSeleccionada != null && this.state.provinciaEmpresaSeleccionada == null) {
                idProvincia = this.props.usuarios.byId.usuarioLogueado.ciudad_empresa.idProvincia;
                this.setState({modo: 'Empresa', provinciaEmpresaSeleccionada: idProvincia});
            }
            this.props.fetchCiudadesIfNeeded(idProvincia);
        }

        if (prevProps.ciudades.byId.isFetching != this.props.ciudades.byId.isFetching && !this.props.ciudades.byId.isFetching) {
            var opcionesCiudad = this.props.ciudades.allIds.map((key) => {
                var ciudad = this.props.ciudades.byId.ciudades[key];
                return (
                    <option value={parseInt(key)}>{ciudad.nombreCiudad}</option>
                )
            });
            if (this.state.modo == 'Personal') {
                this.setState({ciudadesPersonal: opcionesCiudad});
            } else if (this.state.modo == "Empresa") {
                this.setState({ciudadesEmpresa: opcionesCiudad});
            }

        }
        //Armo el mapaDesactivar encuestas al llegar las respuestas de encuestas
        let mapaDesactivar = new Map();
        if (prevProps.encuestaRespuestas.allIds.length != this.props.encuestaRespuestas.allIds.length && this.props.encuestaRespuestas.allIds) {
            this.props.encuestas.allIds.map((idEncuesta) => {
                let encuesta = this.props.encuestas.byId.encuestas[idEncuesta];
                let respuestas = [];
                this.props.encuestaRespuestas.allIds.some((idRespuesta) => {
                    let respuesta = this.props.encuestaRespuestas.byId.encuestaRespuestas[idRespuesta];
                    if (respuesta.idEncuesta == idEncuesta) {
                        respuesta.fechaUltMdf = fechasFormat.convertToDate(respuesta.fechaUltMdf);
                        respuestas.push(respuesta);
                    }
                });
                respuestas.sort((a, b) => {
                    if (a.fechaUltMdf > b.fechaUltMdf) return -1;
                    if (a.fechaUltMdf < b.fechaUltMdf) return 1;
                    if (a.fechaUltMdf == b.fechaUltMdf) return 0;
                });
                var ultimaRespuesta = respuestas[0];

                //Si el autosuggest tiene respuesta la muestro, guardo idEncuesta para actualizar la respuesta en onChange
                if (encuesta.tipoEncuesta == 'AutoSuggest') {
                    this.setState({idEncuestaAutosuggest: idEncuesta});
                    if (ultimaRespuesta)
                        this.setState({valueUsuarios: ultimaRespuesta.desRespuesta});
                }
                if (ultimaRespuesta && encuesta.encuestasRelacionadas) {
                    let idEncuestasRelacionadas = (encuesta.encuestasRelacionadas).split(',');
                    for (let idEncRel of idEncuestasRelacionadas) {
                        this.mapaRelacionesEncuestas.set(idEncuesta, {
                            relaciones: encuesta.encuestasRelacionadas,
                            valorDesactivar: encuesta.valorDesactivar
                        });
                        if (idEncRel != null && idEncRel != "") {
                            if (ultimaRespuesta.desRespuesta == encuesta.valorDesactivar) {
                                mapaDesactivar.set(parseInt(idEncRel), true);
                            } else {
                                mapaDesactivar.set(parseInt(idEncRel), false);
                            }
                        }
                    }
                }

            });
            this.setState({
                mapaDesactivar: mapaDesactivar,
            });
        }

        if (prevProps.usuarios.byId.isFetchingUsuarioPorCampo && !this.props.usuarios.byId.isFetchingUsuarioPorCampo && this.props.usuarios.byId.usuarioExistente != false) {
            if (this.state.campoBuscado == 'dni') {
                this.setState({
                    dni: this.props.usuarios.byId.usuarioExistente.dni,
                });
            } else {
                this.setState({
                    email: this.props.usuarios.byId.usuarioExistente.email,
                });
            }
        }
    }


    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id == "idLocalidad") {
            cambio[e.target.id] = parseInt(e.target.value);
        }
        this.props.changeUsuarioLogueado(cambio);
        if (e.target.id == "password") {
            if (this.props.usuarios.byId.usuarioLogueado.confirmaPass != e.target.value) {
                this.refs.confirmaPass.setCustomValidity("Las contraseñas no coinciden");
            } else {
                console.log("bien");
                this.refs.confirmaPass.setCustomValidity("");
            }
        }
        if (e.target.id == "confirmaPass") {
            if (this.props.usuarios.byId.usuarioLogueado.password != e.target.value) {
                this.refs.confirmaPass.setCustomValidity("Las contraseñas no coinciden");
            } else {
                console.log("bien");
                this.refs.confirmaPass.setCustomValidity("");
            }
        }

    }

    onChangeCheckBox(e, valor, idEncuesta) {
        //Armo el mapa desactivar encuestas
        var mapaRelaciones = this.mapaRelacionesEncuestas.get(parseInt(idEncuesta));
        if (mapaRelaciones && mapaRelaciones.relaciones != null && mapaRelaciones.relaciones != "") {
            let idEncuestasRelacionadas = mapaRelaciones.relaciones.split(',');
            for (let idEncRel of idEncuestasRelacionadas) {
                if (idEncRel != null && idEncRel != "")
                    if (valor == mapaRelaciones.valorDesactivar)
                        this.setState({
                            mapaDesactivar: this.state.mapaDesactivar.set(parseInt(idEncRel), true)
                        });
                    else
                        this.setState({
                            mapaDesactivar: this.state.mapaDesactivar.set(parseInt(idEncRel), false)
                        });
            }
        }
        //Armo la respuesta a la encuesta
        var cambio = {};
        cambio.desRespuesta = valor;
        cambio.idEncuesta = idEncuesta;
        cambio.id = idEncuesta + "-";
        cambio.fechaUltMdf = new Date();
        cambio.idUsuario = this.props.usuarios.byId.usuarioLogueado.id;
        if (cambio.idUsuario)
            this.props.updateEncuestaRespuestas(cambio);
        else
            this.props.createEncuestaRespuestas(cambio);
    }

    onChangeTextField(e, idEncuesta) {
        var cambio = {};
        cambio.desRespuesta = e.target.value;
        cambio.idEncuesta = idEncuesta;
        cambio.id = idEncuesta + "-";
        cambio.fechaUltMdf = new Date();
        cambio.idUsuario = this.props.usuarios.byId.usuarioLogueado.id;
        if (cambio.idUsuario)
            this.props.updateEncuestaRespuestas(cambio);
        else
            this.props.createEncuestaRespuestas(cambio);

    }

    onChangeOpcionesPais(ev) {
        //Vacio idLocalidad
        let cambioUsuario = {};
        cambioUsuario[ev.target.id] = ev.target.value;
        cambioUsuario.idLocalidadEmpresa = null;
        cambioUsuario.idLocalidad = null;
        cambioUsuario.desProvincia = null;
        cambioUsuario.desLocalidad = null;
        cambioUsuario.ciudad = null;

        //Vacio la ciudad del usuario
        this.props.changeUsuarioLogueado(cambioUsuario);
        this.setState({
            provinciaSeleccionada: null,
            provinciaEmpresaSeleccionada: null,
        });

    }

    onChangeOpcionesProvincia(ev) {
        this.props.invalidateCiudades();
        this.props.fetchCiudadesIfNeeded(ev.target.value);

        //Vacio idLocalidad
        let cambioUsuario = {};
        cambioUsuario.target = {};
        cambioUsuario.target.value = "";

        if (ev.target.id == "idProvincia") {
            this.setState({
                provinciaSeleccionada: ev.target.value,
                modo: 'Personal',
            });
            cambioUsuario.target.id = "idLocalidad";
        }
        if (ev.target.id == "idProvinciaEmpresa") {
            this.setState({
                provinciaEmpresaSeleccionada: ev.target.value,
                modo: 'Empresa',
            });
            cambioUsuario.target.id = "idLocalidadEmpresa";
        }

        //Vacio la ciudad del usuario
        this.onChangeUsuario(cambioUsuario);

    }

    submitForm(e) {
        e.preventDefault();
        this.props.saveUsuarioLogueado();
    }

    validarUsuarioPorCampo(tipoCampo, usuarioLogueado) {
        if (!this.props.authentication.token) {
            this.props.fetchUsuarioPorCampo({
                tipoCampo: tipoCampo,
                dni: usuarioLogueado.dni,
                email: usuarioLogueado.email
            });
        }
        this.setState({campoBuscado: tipoCampo});
    }

    // AUTOSUGGEST USUARIOS

    //Cuando selecciono un usuario de la lista
    onChangeUsuarios(event, {newValue, method}) {
        this.setState({
            valueUsuarios: newValue,
        });
        //Armo la respuesta a la encuesta
        var cambio = {};
        cambio.desRespuesta = newValue;
        cambio.idEncuesta = this.state.idEncuestaAutosuggest;
        cambio.id = this.state.idEncuestaAutosuggest + "-";
        cambio.fechaUltMdf = new Date();
        cambio.idUsuario = this.props.usuarios.byId.usuarioLogueado.id;
        if (cambio.idUsuario)
            this.props.updateEncuestaRespuestas(cambio);
        else
            this.props.createEncuestaRespuestas(cambio);

    }

    //Actualiza array suggestions de usuarios segun valor ingresado
    onSuggestionsUpdateRequestedUsuarios({value}) {
        this.setState({
            suggestionsUsuarios: this.getSuggestionsUsuarios(value)
        });
    }

    onSuggestionsClearRequestedUsuarios() {
        this.setState({
            suggestionsUsuarios: []
        });
    }

    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    //Obtiene sugerencias de usuarios de acuerdo a lo ingresado
    getSuggestionsUsuarios(value) {
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue == '') {
            return [];
        }

        const regex = new RegExp('\\b' + escapedValue, 'i');
        let suggestionsUsuarios = [];
        this.props.usuarios.allIds.map((idUsuario) => {
            let usuario = this.props.usuarios.byId.usuarios[idUsuario];
            if (regex.test(`${usuario.nombre} ${usuario.apellido} ${usuario.empresa}`)) {
                suggestionsUsuarios.push(usuario);
            }
        });
        return suggestionsUsuarios;
    }

    // Setea ids para que en render se busquen usuarios
    getSuggestionValueMostrarUsuarios(suggestion) {
        this.props.updateUsuario(suggestion);
        return `${suggestion.nombre} ${suggestion.apellido} - Empresa: ${suggestion.empresa}`;
    }

    //Renderiza sugerencias de usuarios
    renderSuggestionUsuarios(suggestion, {query, isHighlighted}) {
        const suggestionText = `${suggestion.nombre} ${suggestion.apellido} - ${suggestion.empresa}`;
        const matches = match(suggestionText, query);
        const partsCod = parse(suggestionText, matches);

        return (
            <div>
                <div className="usuarioAutosuggest">
                    {
                        partsCod.map((part, index) => {
                            const className = part.highlight ? 'highlight' : null;

                            return (
                                <span className={className} key={index}><b>{part.text}</b></span>
                            );
                        })
                    }
                </div>
                <hr className="hrUsuarios"/>
            </div>
        );
    }

    render() {
        const {mapaDesactivar, suggestionsUsuarios, valueUsuarios} = this.state;
        const {usuarioLogueado} = this.props.usuarios.byId;
        var opcionesPaises = this.props.paises.allIds.map((key) => {
            var pais = this.props.paises.byId.paises[key];
            return (
                <option value={pais ? pais.id : ""}>{pais ? pais.nombrePais : ""}</option>
            )
        });
        var opcionesProvincia = this.props.provincias.allIds.map((key) => {
            var provincia = this.props.provincias.byId.provincias[key];
            return (
                <option value={provincia ? provincia.id : ""}>{provincia ? provincia.nombreProvincia : ""}</option>
            )
        });

        var usuarioExistente = this.props.usuarios.byId.usuarioExistente;

        //Soluciones
        let Soluciones = [];
        this.props.encuestas.allIds.map((idEncuesta) => {
            let encuesta = this.props.encuestas.byId.encuestas[idEncuesta];
            if (encuesta.codCategoria == "Soluciones" && encuesta.idRol == usuarioLogueado.idRol) {
                let respuestas = [];
                this.props.encuestaRespuestas.allIds.some((idRespuesta) => {
                    let respuesta = this.props.encuestaRespuestas.byId.encuestaRespuestas[idRespuesta];
                    if (respuesta.idEncuesta == idEncuesta) {
                        respuesta.fechaUltMdf = fechasFormat.convertToDate(respuesta.fechaUltMdf);
                        respuestas.push(respuesta);
                    }
                });
                respuestas.sort((a, b) => {
                    if (a.fechaUltMdf > b.fechaUltMdf) return -1;
                    if (a.fechaUltMdf < b.fechaUltMdf) return 1;
                    if (a.fechaUltMdf == b.fechaUltMdf) return 0;
                });
                var ultimaRespuesta = respuestas[0];
                Soluciones.push(
                    <tr key={idEncuesta}>
                        <td>{encuesta.desEncuesta}</td>
                        <td>
                            <label>
                                <input type="radio" name={idEncuesta}
                                       required
                                       checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "1" : false}
                                       value={ultimaRespuesta ? ultimaRespuesta.desRespuesta : ""}
                                       onChange={(e) => this.onChangeCheckBox(e, 1, idEncuesta)}/>
                                <span></span>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input type="radio" name={idEncuesta}
                                       checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "2" : false}
                                       onChange={(e) => this.onChangeCheckBox(e, 2, idEncuesta)}/>
                                <span></span>
                            </label>
                        </td>
                        <td><label>
                            <input type="radio" name={idEncuesta}
                                   checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "3" : false}
                                   onChange={(e) => this.onChangeCheckBox(e, 3, idEncuesta)}/>
                            <span></span>
                        </label>
                        </td>
                        <td>
                            <label>
                                <input type="radio" name={idEncuesta}
                                       checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "4" : false}
                                       onChange={(e) => this.onChangeCheckBox(e, 4, idEncuesta)}/>
                                <span></span>
                            </label>
                        </td>
                    </tr>
                )
            }
        });

        //Clientes
        let Clientes = [];
        this.props.encuestas.allIds.map((idEncuesta) => {
            let encuesta = this.props.encuestas.byId.encuestas[idEncuesta];
            if (encuesta.codCategoria == "Clientes" && encuesta.idRol == usuarioLogueado.idRol) {
                let respuestas = [];
                this.props.encuestaRespuestas.allIds.some((idRespuesta) => {
                    let respuesta = this.props.encuestaRespuestas.byId.encuestaRespuestas[idRespuesta];
                    if (respuesta.idEncuesta == idEncuesta) {
                        respuesta.fechaUltMdf = fechasFormat.convertToDate(respuesta.fechaUltMdf);
                        respuestas.push(respuesta);
                    }
                });
                respuestas.sort((a, b) => {
                    if (a.fechaUltMdf > b.fechaUltMdf) return -1;
                    if (a.fechaUltMdf < b.fechaUltMdf) return 1;
                    if (a.fechaUltMdf == b.fechaUltMdf) return 0;
                });

                var ultimaRespuesta = respuestas[0];
                Clientes.push(
                    <tr key={idEncuesta}>
                        <td>{encuesta.desEncuesta}</td>
                        <td>
                            <label>
                                <input type="radio" name={idEncuesta}
                                       required
                                       checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "1" : false}
                                       value={ultimaRespuesta ? ultimaRespuesta.desRespuesta : ""}
                                       onChange={(e) => this.onChangeCheckBox(e, 1, idEncuesta)}/>
                                <span/>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input type="radio" name={idEncuesta}
                                       checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "2" : false}
                                       onChange={(e) => this.onChangeCheckBox(e, 2, idEncuesta)}/>
                                <span></span>
                            </label>
                        </td>
                        <td><label>
                            <input type="radio" name={idEncuesta}
                                   checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "3" : false}
                                   onChange={(e) => this.onChangeCheckBox(e, 3, idEncuesta)}/>
                            <span></span>
                        </label>
                        </td>
                        <td>
                            <label>
                                <input type="radio" name={idEncuesta}
                                       checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "4" : false}
                                       onChange={(e) => this.onChangeCheckBox(e, 4, idEncuesta)}/>
                                <span></span>
                            </label>
                        </td>
                    </tr>
                )
            }
        });

        //Contanos
        let Contanos = [];
        this.props.encuestas.allIds.map((idEncuesta) => {
            let encuesta = this.props.encuestas.byId.encuestas[idEncuesta];
            if (encuesta.codCategoria == "Contanos" && encuesta.idRol == usuarioLogueado.idRol) {
                let respuestas = [];
                this.props.encuestaRespuestas.allIds.some((idRespuesta) => {
                    let respuesta = this.props.encuestaRespuestas.byId.encuestaRespuestas[idRespuesta];
                    if (respuesta.idEncuesta == idEncuesta) {
                        respuesta.fechaUltMdf = fechasFormat.convertToDate(respuesta.fechaUltMdf);
                        respuestas.push(respuesta);
                    }
                });
                respuestas.sort((a, b) => {
                    if (a.fechaUltMdf > b.fechaUltMdf) return -1;
                    if (a.fechaUltMdf < b.fechaUltMdf) return 1;
                    if (a.fechaUltMdf == b.fechaUltMdf) return 0;
                });

                var ultimaRespuesta = respuestas[0];

                if (encuesta.tipoEncuesta == 'Texto') {
                    Contanos.push(
                        <Row key={idEncuesta} className="encuestaTipoTexto">
                            <p>{encuesta.desEncuesta}</p>
                            <img style={{display: encuesta.desEncuesta == "¿Cómo nos conociste?" ? "block" : "none"}}
                                 src={flechaAbajo}/>
                            <input id={idEncuesta} name={idEncuesta} type="text" placeholder=""
                                   disabled={mapaDesactivar.get(idEncuesta)}
                                   required={encuesta.requerido}
                                   value={mapaDesactivar.get(idEncuesta) ? "" : (ultimaRespuesta ? ultimaRespuesta.desRespuesta : "")}
                                   onChange={(e) => this.onChangeTextField(e, idEncuesta)}/>
                        </Row>
                    )
                }

                if (encuesta.tipoEncuesta == 'AutoSuggest') {
                    Contanos.push(
                        <Row key={idEncuesta} className="encuestaTipoAutoSuggest">
                            <p>{encuesta.desEncuesta}</p>
                            <Autosuggest
                                suggestions={suggestionsUsuarios}
                                id="autosuggest-usuarios"
                                onSuggestionsFetchRequested={this.onSuggestionsUpdateRequestedUsuarios}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequestedUsuarios}
                                getSuggestionValue={this.getSuggestionValueMostrarUsuarios}
                                renderSuggestion={this.renderSuggestionUsuarios}
                                inputProps={{
                                    id: idEncuesta,
                                    disabled: mapaDesactivar.get(idEncuesta),
                                    required: encuesta.requerido,
                                    value: mapaDesactivar.get(idEncuesta) ? "" : valueUsuarios,
                                    onChange: this.onChangeUsuarios,
                                }}/>
                        </Row>
                    )
                }

                if (encuesta.tipoEncuesta == "SiNo") {
                    Contanos.push(
                        <Row className="encuestaTipoSiNo">
                            <p>{encuesta.desEncuesta}</p>
                            <div>
                                <label>
                                    <input type="radio" name={idEncuesta}
                                           required={encuesta.requerido}
                                           checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "1" : false}
                                           onChange={(e) => this.onChangeCheckBox(e, 1, idEncuesta)}/>
                                    <span></span>
                                    SI
                                </label>
                                <label>
                                    <input type="radio" name={idEncuesta}
                                           required={encuesta.requerido}
                                           checked={ultimaRespuesta ? ultimaRespuesta.desRespuesta == "2" : false}
                                           onChange={(e) => this.onChangeCheckBox(e, 2, idEncuesta)}/>
                                    <span></span>
                                    NO
                                </label>
                            </div>
                        </Row>)
                }
            }
        });

        return (
            <div className="encuesta">
                <section className="login-encuesta">
                    <div className="container-limit">
                        <Row>
                            <Col md={9} className="recuadro">
                                <h2 style={{display: usuarioLogueado.idRol == roles.CONSUMIDOR_FINAL_ID ? "block" : "none"}}>
                                    Usuario Consumidor final
                                </h2>
                                <h2 style={{display: usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID ? "block" : "none"}}>
                                    Usuario Instalador, Integrador o distribuidor
                                </h2>
                                <h2 style={{display: usuarioLogueado.idRol == roles.CORPORATIVO_ID ? "block" : "none"}}>
                                    Usuario Corporativo
                                </h2>
                                <h1 style={{display: !this.props.authentication.token ? "block" : "none"}}>
                                    ¡Registrate!
                                </h1>
                                <h1 style={{display: this.props.authentication.token ? "block" : "none"}}>
                                    ¡Mantené siempre tus datos actualizados!
                                </h1>
                                <p>
                                    Asegurate de ingresar y mantener tus datos correctos, de esta manera
                                    podés acceder a nuestra oferta de productos, servicios instaladores e integradores
                                    verificados.
                                </p>
                                <div className="separador-verde">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="login-ingreso">
                    <Row>
                        <Col md={10} mdOffset={1} className="recuadro">
                            <p className="subtitulo">
                                Ingresá tus datos personales
                            </p>
                            <form onSubmit={(e) => {
                                this.submitForm(e)
                            }}>
                                <div className="borderCeleste">
                                    <Row>
                                        <p className="campoYaExiste"
                                           style={{display: !this.props.authentication.token && usuarioExistente && usuarioLogueado.dni && (usuarioExistente.dni == usuarioLogueado.dni || this.state.dni == usuarioLogueado.dni) ? "block" : "none"}}>Ya
                                            existe un usuario para este DNI</p>
                                        <input id="dni" type="number" required placeholder="DNI del solicitante"
                                               value={usuarioLogueado ? usuarioLogueado.dni : ""}
                                               onChange={(e) => this.onChangeUsuario(e)} min={0}
                                               onBlur={() => this.validarUsuarioPorCampo('dni', usuarioLogueado)}/>
                                    </Row>
                                    <Row>
                                        <p className="campoYaExiste"
                                           style={{display: !this.props.authentication.token && usuarioExistente && usuarioLogueado.email && (usuarioExistente.email == usuarioLogueado.email || this.state.email == usuarioLogueado.email) ? "block" : "none"}}>Ya
                                            existe un usuario para este mail</p>
                                        <input id="email" type="email" required placeholder="Email"
                                               value={usuarioLogueado ? usuarioLogueado.email : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}
                                               onBlur={() => this.validarUsuarioPorCampo('email', usuarioLogueado)}/>
                                    </Row>
                                    <Row>
                                        <input id="nombre" type="text" required placeholder="Nombre"
                                               value={usuarioLogueado ? usuarioLogueado.nombre : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>
                                    <Row>
                                        <input id="apellido" type="text" required placeholder="Apellido"
                                               value={usuarioLogueado ? usuarioLogueado.apellido : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>
                                    <Row className="rowFechaNacimiento">
                                        <p>Fecha de Nacimiento</p>
                                        <input id="fechaNacimiento" type="date" required
                                               placeholder="Fecha de Nacimiento"
                                               value={usuarioLogueado ? usuarioLogueado.fechaNacimiento : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}/>

                                    </Row>
                                    <Row>
                                        <Cleave
                                            id="telefono"
                                            type="text"
                                            label="Teléfono"
                                            required={false}
                                            placeholder="(Cod. Area - Num. Tel.)"
                                            disabled={false}
                                            value={usuarioLogueado ? (usuarioLogueado.telefono == null ? "" : usuarioLogueado.telefono) : ""}
                                            options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}
                                            onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>
                                    <Row>
                                        <Cleave
                                            id="celular"
                                            type="text"
                                            label="Teléfono"
                                            required={true}
                                            placeholder="(Cod. Area sin 0 - Num. Cel. sin 15 ej: 3415111111)"
                                            disabled={false}
                                            value={usuarioLogueado ? (usuarioLogueado.celular == null ? "" : usuarioLogueado.celular) : ""}
                                            options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}
                                            onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>
                                    <Row>
                                        <input id="domicilio" type="text" required placeholder="Domicilio"
                                               value={usuarioLogueado ? usuarioLogueado.domicilio : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>

                                    <Row>
                                        <select id="idPais" required
                                                onChange={(e) => this.onChangeOpcionesPais(e)}
                                                value={usuarioLogueado ? usuarioLogueado.idPais : ""}>
                                            <option value="" disabled selected>Seleccione un País</option>
                                            {opcionesPaises}
                                        </select>
                                    </Row>
                                    {usuarioLogueado && usuarioLogueado.idPais && usuarioLogueado.idPais == 1 &&
                                    <div>
                                        <Row>
                                            <select id="idProvincia" required
                                                    onChange={(e) => this.onChangeOpcionesProvincia(e)}
                                                    value={this.state.provinciaSeleccionada}>
                                                <option value="" disabled selected>Seleccione una Provincia</option>
                                                {opcionesProvincia}
                                            </select>
                                        </Row>
                                        <Row>
                                            <select id="idLocalidad" required
                                                    onChange={(e) => this.onChangeUsuario(e)}
                                                    value={usuarioLogueado && usuarioLogueado.idLocalidad ? usuarioLogueado.idLocalidad : ""}>
                                                <option value="" disabled selected>Seleccione una Ciudad</option>
                                                {this.state.ciudadesPersonal}
                                            </select>
                                        </Row>
                                    </div>
                                    }
                                    {
                                        usuarioLogueado && usuarioLogueado.idPais && usuarioLogueado.idPais != 1 &&
                                        <div>
                                            <Row>
                                                <input id="desProvincia" type="text" required
                                                       placeholder="Estado/Provincia"
                                                       value={usuarioLogueado ? usuarioLogueado.desProvincia : ""}
                                                       onChange={(e) => this.onChangeUsuario(e)}/>
                                            </Row>
                                            <Row>
                                                <input id="desLocalidad" type="text" required placeholder="Localidad"
                                                       value={usuarioLogueado ? usuarioLogueado.desLocalidad : ""}
                                                       onChange={(e) => this.onChangeUsuario(e)}/>
                                            </Row>
                                        </div>
                                    }
                                    <Row
                                        style={{display: usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID ? "block" : "none"}}>
                                        <input id="empresa" type="text"
                                               required={usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID}
                                               placeholder="Empresa a la que perteneces"
                                               value={usuarioLogueado ? usuarioLogueado.empresa : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>
                                    <Row
                                        style={{display: usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID ? "block" : "none"}}>
                                        <input id="cargo" type="text"
                                               required={usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID}
                                               placeholder="Cargo y área en la que trabajas"
                                               value={usuarioLogueado ? usuarioLogueado.cargo : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}/>
                                    </Row>
                                </div>
                                <div
                                    style={{display: usuarioLogueado.idRol == roles.CORPORATIVO_ID ? "block" : "none"}}>
                                    <p className="subtitulo">
                                        Ingresá los datos corporativos
                                    </p>
                                    <div className="borderCeleste">
                                        <Row>
                                            <input id="empresa" type="text"
                                                   required={usuarioLogueado.idRol == roles.CORPORATIVO_ID}
                                                   placeholder="Empresa a la que perteneces"
                                                   value={usuarioLogueado ? usuarioLogueado.empresa : ""}
                                                   onChange={(e) => this.onChangeUsuario(e)}/>
                                        </Row>
                                        <Row>
                                            <input id="cargo" type="text"
                                                   required={usuarioLogueado.idRol == roles.CORPORATIVO_ID}
                                                   placeholder="Cargo y área en la que trabajas"
                                                   value={usuarioLogueado ? usuarioLogueado.cargo : ""}
                                                   onChange={(e) => this.onChangeUsuario(e)}/>
                                        </Row>
                                        <Row>
                                            <input id="domicilioEmpresa" type="text"
                                                   required={usuarioLogueado.idRol == roles.CORPORATIVO_ID}
                                                   placeholder="Domicilio de la empresa"
                                                   value={usuarioLogueado ? usuarioLogueado.domicilioEmpresa : ""}
                                                   onChange={(e) => this.onChangeUsuario(e)}/>
                                        </Row>
                                        <Row>
                                            <Cleave
                                                id="telefonoEmpresa"
                                                type="text"
                                                label="Teléfono"
                                                required={usuarioLogueado.idRol == roles.CORPORATIVO_ID}
                                                placeholder="(Cod. Area - Num. Tel. Empresa)"
                                                disabled={false}
                                                value={usuarioLogueado ? (usuarioLogueado.telefonoEmpresa == null ? "" : usuarioLogueado.telefonoEmpresa) : ""}
                                                options={{phone: true, phoneRegionCode: 'AR', delimiter: "-"}}
                                                onChange={(e) => this.onChangeUsuario(e)}/>
                                        </Row>
                                        <Row>
                                            <select id="idProvinciaEmpresa"
                                                    required={usuarioLogueado.idRol == roles.CORPORATIVO_ID}
                                                    onChange={(e) => this.onChangeOpcionesProvincia(e)}
                                                    value={this.state.provinciaEmpresaSeleccionada}>
                                                <option value="" disabled selected>Seleccione una Provincia</option>
                                                {opcionesProvincia}
                                            </select>
                                        </Row>
                                        <Row>
                                            <select id="idLocalidadEmpresa"
                                                    required={usuarioLogueado.idRol == roles.CORPORATIVO_ID}
                                                    onChange={(e) => this.onChangeUsuario(e)}
                                                    value={usuarioLogueado ? usuarioLogueado.idLocalidadEmpresa : ""}>
                                                <option value="" disabled selected>Seleccione una Ciudad</option>
                                                {this.state.ciudadesEmpresa}
                                            </select>
                                        </Row>
                                    </div>
                                </div>
                                <p className="subtitulo"
                                   style={{display: this.props.authentication.token ? "none" : "block"}}>
                                    Escribí tu nueva contraseña
                                </p>
                                <div className="borderCeleste"
                                     style={{display: this.props.authentication.token ? "none" : "block"}}>
                                    <Row>
                                        <input id="password" type="password" minLength="8"
                                               required={!this.props.authentication.token}
                                               placeholder="Contraseña"
                                               value={usuarioLogueado ? usuarioLogueado.password : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}
                                               autocomplete="new-password"
                                        />
                                    </Row>
                                    <Row>
                                        <input id="confirmaPass" type="password" ref="confirmaPass" minLength="8"
                                               required={!this.props.authentication.token}
                                               placeholder="Confirmar Contraseña"
                                               value={usuarioLogueado ? usuarioLogueado.confirmaPass : ""}
                                               onChange={(e) => this.onChangeUsuario(e)}
                                               autocomplete="new-password"
                                        />
                                    </Row>
                                </div>
                                <div
                                    style={{display: usuarioLogueado.idRol == roles.CONSUMIDOR_FINAL_ID || usuarioLogueado.idRol == roles.CORPORATIVO_ID ? "block" : "none"}}>
                                    <p className="subtitulo">
                                        Contanos un poco más
                                    </p>
                                    <Row className="borderCeleste">
                                        {Contanos}
                                    </Row>
                                </div>


                                <div className="separador-celeste"
                                     style={{display: Soluciones.length > 0 ? "block" : "none"}}/>

                                <p style={{display: Soluciones.length > 0 ? "block" : "none"}}>Seleccione en que
                                    soluciones se considera MáS EXPERTO:</p>

                                <div className="table-responsive"
                                     style={{display: Soluciones.length > 0 ? "block" : "none"}}>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th className="no-center noBorder-right">Soluciones</th>
                                            <th className="noBorder-left noBorder-right">Sin Conocimiento</th>
                                            <th className="noBorder-right noBorder-left">Conozco</th>
                                            <th className="noBorder-right noBorder-left">Mucho conocimiento</th>
                                            <th className="noBorder-left">Experto</th>
                                        </tr>
                                        </thead>
                                        <tbody className="sinBorder">
                                        <tr className="spacer"></tr>
                                        {Soluciones}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="separador-celeste"
                                     style={{display: Clientes.length > 0 ? "block" : "none"}}/>

                                <div className="table-responsive"
                                     style={{display: Clientes.length > 0 ? "block" : "none"}}>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th className=" no-center noBorder-right">Clientes</th>
                                            <th className=" noBorder-right noBorder-left">Nada</th>
                                            <th className=" noBorder-right noBorder-left">Algo</th>
                                            <th className=" noBorder-right noBorder-left">Bastante</th>
                                            <th className=" noBorder-left">La mayoría</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className=" spacer"></tr>
                                        {Clientes}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="separador-celeste"/>
                                <Row>
                                    <p style={{fontSize: "16px"}}>Los datos provistos serán utilizados para validar tu
                                        cuenta y brindarte un servicio más acorde.</p>
                                    <p style={{fontSize: "16px", fontWeight: "500"}}>Todos los datos son requeridos para
                                        el registro</p>
                                </Row>
                                <Row>
                                    <Col md={12} className="botoneraIngreso">
                                        {/*<p style={{color: "red"}}>{this.props.usuarios.create.error}</p>*/}
                                        {/*<p style={{color: "red"}}>{this.props.usuarios.update.error}</p>*/}
                                        <Button type="submit" className="registrate"
                                                disabled={this.props.usuarios.create.isCreating}>{this.props.authentication.token ? "Guardar cambios" : "Registrarme"}</Button>
                                        <br/>
                                        <img src={Loading} style={{
                                            display: this.props.usuarios.create.isCreating ? "inline" : "none",
                                            width: "25px",
                                            marginTop: "21px"
                                        }}/>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </section>

                <Footer2/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
        paises: state.paises,
        provincias: state.provincias,
        ciudades: state.ciudades,
        encuestas: state.encuestas,
        encuestaRespuestas: state.encuestaRespuestas,
        clientes: state.clientes,
        landing: state.landing,
        accesosCuenta: state.accesosCuenta,
        habilitaciones: state.habilitaciones,
        preciosProducto: state.preciosProducto,
        productos: state.productos,
        kits: state.kits,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario))
        },
        errorCreate: (error) => {
            dispatch(errorCreateUsuarioLogueado(error))
        },
        saveUsuarioLogueado: () => {
            dispatch(saveUsuarioLogueado());
        },
        fetchUsuarioLogueadoIfNeeded: () => {
            dispatch(fetchUsuarioLogueadoIfNeeded());
        },
        fetchCiudadesIfNeeded: (idProvincia) => {
            dispatch(fetchCiudadesIfNeeded(idProvincia))
        },
        invalidateCiudades: () => {
            dispatch(invalidateCiudades())
        },
        fetchProvinciasIfNeeded: () => {
            dispatch(fetchProvinciasIfNeeded())
        },
        fetchEncuestasIfNeeded: () => {
            dispatch(fetchEncuestasIfNeeded())
        },
        updateEncuestaRespuestas: (cambio) => {
            dispatch(updateEncuestaRespuestas(cambio))
        },
        createEncuestaRespuestas: (cambio) => {
            dispatch(createEncuestaRespuestas(cambio))
        },
        invalidateProvincias: () => {
            dispatch(invalidateProvincias())
        },
        fetchUsuarioPorCampo: (filtros) => {
            dispatch(fetchUsuarioPorCampo(filtros))
        },
        updateUsuario: (usuario) => {
            dispatch(updateUsuario(usuario))
        },
        updateLogo: (file) => {
            dispatch(updateLogo(file))
        },
        fetchPaisesIfNeeded: () => {
            dispatch(fetchPaisesIfNeeded())
        },

    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Encuesta));