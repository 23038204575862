import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import {Col} from 'react-bootstrap';

//Images
import slide1 from '../../assets/img/slider/banner-capacitaciones-abr2019.jpg';
import slide2 from '../../assets/img/slider/banners-web_productos-nuevos-08.jpg';
import slide3 from '../../assets/img/slider/banners-web_productos-nuevos-13.jpg';
import slide4 from '../../assets/img/slider/banners-web_productos-nuevos-12.jpg';
import slide5 from '../../assets/img/slider/banners-web_productos-nuevos-11.jpg';
import slide6 from '../../assets/img/slider/banners-web_productos-nuevos-SIERA-Storage-01.jpg';
import uniglob from '../../assets/img/slider/banner-uniglob.png';
import cctv from "../../assets/img/slider/banner-camaras.png";
import positron from "../../assets/img/slider/banner-positron.png";

//CSS
import '../../assets/css/Slider.css';
import profPosta from "../../assets/img/slider/ProfesionalPosta-01.jpg";

class SliderHome extends Component {
    render() {
        return (
            <Col className="slider sliderHome">
                <OwlCarousel className="owl-theme" loop nav items={1} navText="" autoplay autoplayTimeout={7000}>
                    {/*<div className="item">*/}
                    {/*<img src={slide1} alt=""/>*/}
                    {/*</div>*/}
                    {/*<div className="item">*/}
                    {/*    <a target="_blank" href="https://www.facebook.com/kitexperto/"><img src={profPosta} alt=""/></a>*/}
                    {/*</div>*/}
                    {/*<div className="item">*/}
                    {/*    <img src={slide2} alt=""/>*/}
                    {/*</div>*/}
                    {/*<div className="item">*/}
                    {/*    <img src={slide3} alt=""/>*/}
                    {/*</div>*/}
                    {/*<div className="item">*/}
                    {/*    <img src={slide4} alt=""/>*/}
                    {/*</div>*/}
                    {/*<div className="item">*/}
                    {/*    <img src={slide5} alt=""/>*/}
                    {/*</div>*/}
                    {/*<div className="item">*/}
                    {/*    <img src={slide6} alt=""/>*/}
                    {/*</div>*/}
                    <div className="item" style={{position: "relative"}}>
                        <a
                            href="https://www.kitexperto.com/panel/listaPrecios/1/categoria/alarma-intrusion/clubdelbeneficiosoftguard"
                            target="_blank">
                            <img src={uniglob} alt=""/>
                        </a>
                        <div className="container-boton-banner">
                            <a target="_blank"
                               href="https://drive.google.com/file/d/1tTYmUBLmeW5JNVY9xXbrThylAlu-0nIr/view?usp=sharing">
                                <button>
                                    PERSONALIZÁ CON TU MARCA
                                </button>
                            </a>
                            <a target="_blank"
                               href="https://drive.google.com/file/d/1SCXdrhCCRRDmVF4FG-DnX92Hf7QgG9Y3/view?usp=sharing">
                                <button>
                                    DESCARGAR DATASHEET
                                </button>
                            </a>
                            <a target="_blank" href="https://www.youtube.com/watch?v=QbAKynKOoF8&t=32s">
                                <button>
                                    VIDEO PRESENTACIÓN
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="item">
                        <a href="https://www.kitexperto.com/productoDetalle/1851" target="_blank">
                            <img src={cctv} alt=""/>
                        </a>
                    </div>
                    <div className="item">
                        <a href="https://www.kitexperto.com/panel/listaPrecios/1/producto/Kit%20Positron%20Hibrido%20HM264RF" target="_blank">
                            <img src={positron} alt=""/>
                        </a>
                    </div>
                </OwlCarousel>
            </Col>
        );
    }
}

export default SliderHome;
