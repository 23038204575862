import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import history from "../../../history";
import isString from "lodash/isString";
import isObject from "lodash/isObject";


class ErrorCheckInPss extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            history.push("/checkin-pss");
        }, 4000);
    }

    render() {
        const error = this.props.registros.update.error;

        var msgError = null;

        if (error && isObject(error.message) && !isString(error.message)) {
            msgError = Object.keys(error.message).map(function (key, index) {
                return (<li>{error.message[key]}</li>);
            });
        } else {
            msgError = error ? error.message : "";
        }


        return (
            <Row className="gracias">
                <Col md={12} className="center-block">
                    <h1>Datos incorrectos</h1>
                    <h4>Ingresá tu DNI nuevamente</h4>
                    <p style={{color:"#EE1844"}}>{msgError}</p>
                    <div className="lineaCorta"></div>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        registros: state.registros
    };
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ErrorCheckInPss));
