import {
    RECEIVE_PEDIDOS, RESET_PEDIDOS
} from '../actions/PedidoActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";


function estadosRemitoById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosRemito: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                estadosRemito: merge({}, state.estadosRemito, action.pedidos.entities.estadosRemito),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                estadosRemito: [],
                error: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                estadosRemito: {},
            });
        default:
            return state
    }
}


function allEstadosRemito(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

const estadosRemito = combineReducers({
    byId: estadosRemitoById,
    allIds: allEstadosRemito,
});

export default estadosRemito;