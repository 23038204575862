import c from "../constants/constants";
//require('es6-promise').polyfill();
import $ from "jquery";
require('isomorphic-fetch');

var cliente = {
    getLogo(cliente) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            // dataType: 'image/png',
        };
        return fetch(c.BASE_URL+'/logo/' + cliente.idGremio, defaultOptions);
    }, getLogoById(idCliente) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            // dataType: 'image/png',
        };
        return fetch(c.BASE_URL+'/logo/' + idCliente, defaultOptions);
    },
    saveUpdate(usuarioLogueado, logo) {
        var formData = new FormData();
        formData.append("idGremio", usuarioLogueado.idGremio);
        formData.append("logo", logo);

        return $.ajax({
            url: c.BASE_URL + '/logo/',
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
                xhr.setRequestHeader('authorization', "Bearer "+localStorage.token);
            },
            contentType: false,
            type: 'POST',
            // cache: false,
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    getCliente(tipoDocumento, documento) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/clientes/'+tipoDocumento+"/"+documento, defaultOptions);
    },
    saveCreate(cliente) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(cliente)
        };

        return fetch(c.BASE_URL + '/clientes', defaultOptions);

    }
};

export default cliente;