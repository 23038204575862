import {
    INVALIDATE_FORMASENTREGA,
    REQUEST_FORMASENTREGA, RECEIVE_FORMASENTREGA, ERROR_FORMASENTREGA
} from '../actions/FormaEntregaActions';
import { combineReducers } from 'redux';
import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){
    const formasEntrega = new schema.Entity('formasEntrega');
    const mySchema = [ formasEntrega ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

function formasEntregaById(state = {
    isFetching: false,
    didInvalidate: true,
    formasEntrega: []
}, action) {
    switch (action.type) {
        case INVALIDATE_FORMASENTREGA:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_FORMASENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_FORMASENTREGA:
            let dato = normalizeDatos(action.formasEntrega).entities.formasEntrega;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                formasEntrega: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_FORMASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allFormasEntrega(state = [], action) {
    switch (action.type) {
        case RECEIVE_FORMASENTREGA:
            return normalizeDatos(action.formasEntrega).result;
        default:
            return state
    }
}


const formasEntrega = combineReducers({
    byId : formasEntregaById,
    allIds : allFormasEntrega
});

export default formasEntrega;