//api
import cliente from "../api/cliente";

import * as errorMessages from '../constants/MessageConstants';

//Actions
import {logout} from "../actions/AuthenticationActions";
import isEmpty from "lodash/isEmpty";
import {fetchPedidosIfNeeded} from "./PedidoActions";


var FileSaver = require('file-saver');

//LOGO
export const REQUEST_LOGO = 'REQUEST_LOGO';
export const RECEIVE_LOGO = 'RECEIVE_LOGO';
export const INVALIDATE_LOGO = 'INVALIDATE_LOGO';
export const ERROR_LOGO = "ERROR_LOGO";
export const UPDATE_LOGO = "UPDATE_LOGO";

export function invalidateLogo() {
    return {
        type: INVALIDATE_LOGO,
    }
}

function requestLogo() {
    return {
        type: REQUEST_LOGO,
    }
}

function receiveLogo(logo) {
    return {
        type: RECEIVE_LOGO,
        receivedAt: Date.now(),
        logo
    }
}

export function updateLogo(logo) {
    return {
        type: UPDATE_LOGO,
        logo
    }
}

function errorLogo(error) {
    return {
        type: ERROR_LOGO,
        error: error,
    }
}

function fetchLogo() {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestLogo());
        return cliente.getLogo(getState().usuarios.byId.usuarioLogueado)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                dispatch(receiveLogo(file));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLogo(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLogo(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

export function fetchLogoByIdCliente(idCliente) {
    return (dispatch, getState) => {
        let nombreArchivo = "";
        let tipoArchivo = "";
        dispatch(requestLogo());
        return cliente.getLogoById(idCliente)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                dispatch(receiveLogo(file));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLogo(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLogo(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchLogo(state) {
    const logo = state.clientes.logo;
    if (!logo) {
        return true
    } else if (logo.isFetching) {
        return false
    } else {
        return logo.didInvalidate;
    }
}

export function fetchLogoIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchLogo(getState())) {
            return dispatch(fetchLogo())
        }
    }
}


//UPDATE CLIENTE
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
export const REQUEST_UPDATE_CLIENTE = "REQUEST_UPDATE_CLIENTE";
export const SUCCESS_UPDATE_CLIENTE = "SUCCESS_UPDATE_CLIENTE";
export const ERROR_UPDATE_CLIENTE = "ERROR_UPDATE_CLIENTE";
export const RESET_UPDATE_CLIENTE = "RESET_UPDATE_CLIENTE";

function requestUpdateCliente() {
    return {
        type: REQUEST_UPDATE_CLIENTE,
    }
}

function receiveUpdateCliente() {
    return {
        type: SUCCESS_UPDATE_CLIENTE,
        receivedAt: Date.now()
    }
}

function errorUpdateCliente(error) {
    return {
        type: ERROR_UPDATE_CLIENTE,
        error: error,
    }
}

export function resetUpdateCliente() {
    return {
        type: RESET_UPDATE_CLIENTE,
    }
}

export function updateCliente(cliente) {
    return {
        type: UPDATE_CLIENTE,
        cliente
    }
}

export function saveUpdateClienteLogo() {
    return (dispatch, getState) => {
        dispatch(requestUpdateCliente());

        return cliente.saveUpdate(getState().usuarios.byId.usuarioLogueado, getState().usuarios.logo.logo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateCliente());
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateCliente(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateCliente(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//ALTA CLIENTE
export const CREATE_CLIENTE = 'CREATE_CLIENTE';
export const REQUEST_CREATE_CLIENTE = "REQUEST_CREATE_CLIENTE";
export const SUCCESS_CREATE_CLIENTE = "SUCCESS_CREATE_CLIENTE";
export const ERROR_CREATE_CLIENTE = "ERROR_CREATE_CLIENTE";
export const RESET_CREATE_CLIENTE = "RESET_CREATE_CLIENTE";


//ALTA CLIENTE
function requestCreateCliente() {
    return {
        type: REQUEST_CREATE_CLIENTE,
    }
}

function receiveCreateCliente() {
    return {
        type: SUCCESS_CREATE_CLIENTE,
        receivedAt: Date.now()
    }
}

export function errorCreateCliente(error) {
    return {
        type: ERROR_CREATE_CLIENTE,
        error: error,
    }
}

export function resetCreateCliente() {
    return {
        type: RESET_CREATE_CLIENTE
    }
}

export function createCliente(cliente) {
    return {
        type: CREATE_CLIENTE,
        cliente
    }
}

export function saveCreateCliente() {
    return (dispatch, getState) => {
        dispatch(requestCreateCliente());
        let clienteMonitor = {};
        if (!isEmpty(getState().clientes.update.activo)) {
            clienteMonitor = getState().clientes.update.activo;
        } else {
            clienteMonitor = getState().clientes.create.nuevo;
        }
        return cliente.saveCreate(clienteMonitor)
            .then(function (response) {

                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateCliente());
                    // dispatch(resetCreateCliente());
                    dispatch(fetchPedidosIfNeeded({id: getState().pedidos.update.activo.id}))
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateCliente(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorCreateCliente(error.message));
                                else
                                    dispatch(errorCreateCliente(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorCreateCliente(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//CLIENTE REQUEST
export const REQUEST_CLIENTE = 'REQUEST_CLIENTE';
export const RECEIVE_CLIENTE = 'RECEIVE_CLIENTE';
export const INVALIDATE_CLIENTE = 'INVALIDATE_CLIENTE';
export const ERROR_CLIENTE = "ERROR_CLIENTE";
export const RESET_CLIENTE = "RESET_CLIENTE";


//CLIENTE REQUEST
export function invalidateCliente() {
    return {
        type: INVALIDATE_CLIENTE,
    }
}

function requestCliente() {
    return {
        type: REQUEST_CLIENTE,
    }
}

function receiveCliente(json) {
    return {
        type: RECEIVE_CLIENTE,
        cliente: json,
        receivedAt: Date.now()
    }
}

function errorCliente(error) {
    return {
        type: ERROR_CLIENTE,
        error: error,
    }
}

export function resetCliente() {
    return {
        type: RESET_CLIENTE
    }
}

export function fetchCliente(tipoDocumento, documento) {
    return dispatch => {
        dispatch(requestCliente());
        return cliente.getCliente(tipoDocumento, documento)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCliente(data));
                if (data.id) {
                    dispatch(updateCliente(data))
                } else {
                    dispatch(createCliente(data));
                }

            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCliente(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if(tipoDocumento == "cuit")
                            dispatch(errorCliente("Verifique los datos y vuelva a intentarlo."));
                        else
                            dispatch(errorCliente("Complete sus datos."));
                        return;
                }
            });
    }
}

function shouldFetchCliente(state) {
    const clientes = state.clientes.byId;
    if (clientes.isFetchingCliente) {
        return false
    } else if (!clientes.usuarioLogueado.id && state.authentication.token) {
        return true
    } else {
        return clientes.didInvalidate;
    }
}

export function fetchClienteIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchCliente(getState())) {
            return dispatch(fetchCliente())
        }
    }
}