import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import "./TarjetaCredito.css";
//lodash
import { fetchFormasDePagoIfNeeded, resetFormasDePago } from "../../../../../actions/FormaDePagoActions";
import { Col, Row } from "react-bootstrap";
import { createPago, updatePago } from "../../../../../actions/PagoActions";
import Fecha from "./Fecha";
//Images
import chip from "../../../../../assets/img/pago/tarjetas/chip.png";
import visa from "../../../../../assets/img/pago/tarjetas/visa.png";
import mastercard from "../../../../../assets/img/pago/tarjetas/mastercard.png";
import americanExpress from "../../../../../assets/img/pago/tarjetas/americanexpress.png";
import dinersInternational from "../../../../../assets/img/pago/tarjetas/dinersInternational.png";
import { createTarjeta, resetTarjeta } from "../../../../../actions/TarjetaCreditoActions";
import { fetchPlanesCuotasIfNeeded } from "../../../../../actions/PlanesCuotasActions";

export default function TarjetaCredito(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();
    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;
    const entidadesFinancieras = useSelector(state => state.entidadesFinancieras);
    const tiposTarjetaCredito = useSelector(state => state.tiposTarjetaCredito);
    const planesCuotas = useSelector(state => state.planesCuotas);
    const formasDePago = useSelector(state => state.formasDePago);
    const tarjetasCredito = useSelector(state => state.tarjetasCredito);
    const tarjeta = tarjetasCredito.create.nuevo;

    // State
    const [idEntidadFinanciera, setIdEntidadFinanciera] = useState(null);
    const [idTipoTarjetaCredito, setIdTipoTarjetaCredito] = useState(null);

    //Hooks
    //Effects
    useEffect(() => {
        dispatch(fetchPlanesCuotasIfNeeded());
        dispatch(fetchFormasDePagoIfNeeded({
            with: ["tipoTarjetaCredito", "planCuotas"],
            idEntidadFinanciera: null,
            limit: -1,
            estado: 1
        }));
        return function cleanup() {
            dispatch(resetFormasDePago());
            dispatch(resetTarjeta());
        }
    }, []);

    //Cuando se recupera un pago busco las ciudades de la provincia
    useEffect(() => {
        console.log("entra pago", pago, pago.idFormaPago, idEntidadFinanciera, idTipoTarjetaCredito);
        if (pago && pago.idFormaPago && !idEntidadFinanciera && !idTipoTarjetaCredito) {
            let formaPago = formasDePago.byId.formasDePago[pago.idFormaPago];
            if (formaPago) {
                setIdEntidadFinanciera(formaPago.idEntidadFinanciera);
                setIdTipoTarjetaCredito(formaPago.idTipoTarjetaCredito);
            }
        }
    }, [pago, formasDePago.byId.isFetching]);

    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id === "idMedioPago")
            cambio[e.target.id] = e.target.checked ? parseInt(e.target.value) : null;
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };

    const changeEntidadFinanciera = (e) => {
        setIdEntidadFinanciera(parseInt(e.target.value));

        var cambio = {};
        cambio["idFormaPago"] = null;
        // this.props.createPedido(cambio);
    };

    const changeTipoTarjetaCredito = (e) => {
        var cambio = {};
        cambio["idFormaPago"] = null;
        //this.props.createPedido(cambio);
        setIdTipoTarjetaCredito(parseInt(e.target.value));
    };

    const onChangeTarjeta = (e) => {
        console.log(e);
        var cambio = {};
        if (e.target.id == "nroTarjeta" && e.target.value.length >= 19)
            e.target.value = e.target.value.substr(0, 19);
        if (e.target.id == "codigoSeguridad" && e.target.value.length >= 3)
            e.target.value = e.target.value.substr(0, 3);
        if (e.target.id == "mesVencimiento" && e.target.value.length >= 2)
            e.target.value = e.target.value.substr(0, 2);
        if (e.target.id == "anoVencimiento" && e.target.value.length >= 2)
            e.target.value = e.target.value.substr(0, 2);

        cambio[e.target.id] = e.target.value;

        dispatch(createTarjeta(cambio));

        //Valido el dni
        if (e.target.id == "dni") {
            let validacionDni = "";
            if ((e.target.value.length < 7 || e.target.value.length > 8)) {
                validacionDni = "El DNI debe tener entre 7 y 8 dígitos";
            }
            document.getElementById("dni").setCustomValidity(validacionDni);
        }

        //Valido el nroTarjeta
        if (e.target.id == "nroTarjeta") {
            let validacionnroTarjeta = "";
            if ((e.target.value.length < 14)) {
                validacionnroTarjeta = "El número de tarjeta debe tener al menos 14 dígitos";
            }
            document.getElementById("nroTarjeta").setCustomValidity(validacionnroTarjeta);
        }

        //Valido el nroTarjeta
        if (e.target.id == "codigoSeguridad") {
            let validacioncodigoSeguridad = "";
            if ((e.target.value.length != 3)) {
                validacioncodigoSeguridad = "El código de seguridad debe tener 3 dígitos";
            }
            document.getElementById("codigoSeguridad").setCustomValidity(validacioncodigoSeguridad);
        }
    }

    var opcionesTarjetas = [];
    var opcionesCuotas = [];

    let entidadFinancieraSeleccionada = null;
    if (idEntidadFinanciera)
        entidadFinancieraSeleccionada = entidadesFinancieras.byId.entidadesFinancieras[idEntidadFinanciera];

    if (formasDePago.allIds.formasDePago) {
        let idsFormasDePago = formasDePago.allIds.formasDePago;


        let distinctOpcionesTarjetas = [...new Set(idsFormasDePago.map(idFormaPago => {
            let formaDePago = formasDePago.byId.formasDePago[idFormaPago];
            if (formaDePago) {
                let tipoTarjetaCredito = tiposTarjetaCredito.byId.tiposTarjetaCredito[formaDePago.idTipoTarjetaCredito];
                let planCuota = planesCuotas.byId.planesCuotas[formaDePago.idPlanCuotas];
                if (tipoTarjetaCredito.id === idTipoTarjetaCredito && planCuota) {
                    opcionesCuotas.push(
                        <option key={formaDePago.id} value={formaDePago.id}>{planCuota.desPlan}</option>
                    );
                }
                return tipoTarjetaCredito.id;
            }
        }))];

        opcionesTarjetas = distinctOpcionesTarjetas.map((idTipoTarjeta) => {
            let tipoTarjetaCredito = tiposTarjetaCredito.byId.tiposTarjetaCredito[idTipoTarjeta];
            if (tipoTarjetaCredito)
                return (<option key={tipoTarjetaCredito.id}
                                value={tipoTarjetaCredito.id}>
                    {
                        tipoTarjetaCredito.renameTipoTarjetaCredito ? tipoTarjetaCredito.renameTipoTarjetaCredito : tipoTarjetaCredito.desTipoTarjetaCredito
                    }
                </option>);
        });
        console.log(opcionesTarjetas, distinctOpcionesTarjetas)


        var esVisa = false, esMastercard = false, esAmericanExpress = false, esDiners = false;
        if (tarjeta && tarjeta.nroTarjeta) {
            if (tarjeta.nroTarjeta.startsWith("4"))
                esVisa = true;
            if (tarjeta.nroTarjeta.startsWith("34") || tarjeta.nroTarjeta.startsWith("37"))
                esAmericanExpress = true;
            let primerosCuatroDigitos = parseInt(tarjeta.nroTarjeta.substr(0, 4));
            if ((primerosCuatroDigitos >= 2221 && primerosCuatroDigitos <= 2720) || tarjeta.nroTarjeta.startsWith("51")
                || tarjeta.nroTarjeta.startsWith("52") || tarjeta.nroTarjeta.startsWith("53")
                || tarjeta.nroTarjeta.startsWith("54") || tarjeta.nroTarjeta.startsWith("55"))
                esMastercard = true;
            if (tarjeta.nroTarjeta.startsWith("300") || tarjeta.nroTarjeta.startsWith("301")
                || tarjeta.nroTarjeta.startsWith("302") || tarjeta.nroTarjeta.startsWith("303")
                || tarjeta.nroTarjeta.startsWith("304") || tarjeta.nroTarjeta.startsWith("305")
                || tarjeta.nroTarjeta.startsWith("36") || tarjeta.nroTarjeta.startsWith("38"))
                esDiners = true;
        }
        var nroTarjetaDeACuatro = "";
        if (tarjeta && tarjeta.nroTarjeta) {
            //Muestro el numero de tarjeta separado por un espacio cada 4 caracteres
            nroTarjetaDeACuatro = tarjeta.nroTarjeta.replace(/[^\dA-Z]/g, 'x').replace(/(.{4})/g, '$1 ').trim();
        }
    }

    let formaDePago = formasDePago.byId.formasDePago[pago.idFormaPago];
    return (
        <div className="tarjeta-credito container-fluid">
            <Row className="container-formulario">
                <div className="input-row">
                    <label htmlFor="importe">Importe en pesos (iva incluido)</label>
                    <input id="importe" type="number" required
                           name="importe"
                           placeholder="Importe en pesos (iva incluido)"
                           value={pago && pago.importe ? pago.importe : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
                <br/>
                {
                    pago && pago.importe && pago.idFormaPago && formasDePago.byId.formasDePago[pago.idFormaPago] &&
                    <div>
                        <p>{`El importe total a pagar es de $${(pago.importe * formasDePago.byId.formasDePago[pago.idFormaPago].coeficienteCostoFinanciero).toFixed(2)}`}</p>
                        <p>{`En ${planesCuotas.byId.planesCuotas[formaDePago.idPlanCuotas] ? planesCuotas.byId.planesCuotas[formaDePago.idPlanCuotas].cuotas : ''} cuotas de $${((pago.importe * formaDePago.coeficienteCostoFinanciero) / (planesCuotas.byId.planesCuotas[formaDePago.idPlanCuotas] ? planesCuotas.byId.planesCuotas[formaDePago.idPlanCuotas].cuotas : 0)).toFixed(2)}`}</p>
                    </div>
                }
                <div style={{display: "flex", alignItems: "flex-end"}}>
                    {/*<div className="input-row" style={{flexGrow: 1, marginRight: "15px"}}>*/}
                    {/*    <label htmlFor="idEntidadFinanciera">Banco o Entidad</label>*/}
                    {/*    <select id="idEntidadFinanciera" name="idEntidadFinanciera"*/}
                    {/*            value={idEntidadFinanciera}*/}
                    {/*            onChange={(e) => changeEntidadFinanciera(e)}*/}
                    {/*            disabled={props.disabled}>*/}
                    {/*        <option value="0">Seleccione Entidad Bancaria</option>*/}
                    {/*        {*/}
                    {/*            entidadesFinancieras.allIds.map((idEntidad) => {*/}
                    {/*                let entidad = entidadesFinancieras.byId.entidadesFinancieras[idEntidad];*/}
                    {/*                if (entidad)*/}
                    {/*                    return (*/}
                    {/*                        <option key={entidad.id}*/}
                    {/*                                value={entidad.id}>{entidad.desEntidadFinanciera}</option>*/}
                    {/*                    );*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <div className="input-row" style={{flexGrow: 1, marginRight: "15px"}}>
                        <label htmlFor="idTipoTarjetaCredito">Tarjeta</label>
                        <select id="idTipoTarjetaCredito" name="idTipoTarjetaCredito" required={true}
                                value={idTipoTarjetaCredito}
                                disabled={props.disabled}
                                onChange={(e) => changeTipoTarjetaCredito(e)}>
                            <option value="">Seleccione Tarjeta</option>
                            {opcionesTarjetas}
                        </select>
                    </div>
                    <div className="input-row" style={{marginRight: "15px"}}>
                        <label htmlFor="idFormaPago">Cuotas</label>
                        <select id="idFormaPago" name="idFormaPago" required={true}
                                value={pago && pago.idFormaPago ? pago.idFormaPago : ""}
                                disabled={props.disabled}
                                onChange={(e) => onChangePago(e)}>
                            <option value="">Seleccione Cuotas</option>
                            {opcionesCuotas}
                        </select>
                    </div>
                </div>
            </Row>
            <Row className="container-formulario">
                <Col md={6} xs={12}>
                    <div style={{display: "flex", alignItems: "flex-end"}}>
                        <div className="input-row" style={{flexGrow: 1, marginRight: "15px"}}>
                            <label htmlFor="nroTarjeta">Número de tarjeta</label>
                            <input id="nroTarjeta" name="cardnumber" inputMode="numeric" type="text"
                                   pattern="[0-9]*" required={true}
                                   value={tarjeta && tarjeta.nroTarjeta ? tarjeta.nroTarjeta : ""}
                                   disabled={props.disabled}
                                   onChange={(e) => onChangeTarjeta(e)}/>
                        </div>
                        <div className="input-row">
                            <div className="input-row contenedor-vencycod">
                                <label htmlFor="codigoSeguridad">Código de Seguridad</label>
                                <input id="codigoSeguridad" name="securitycode" pattern="[0-9]*"
                                       type="number"
                                       inputMode="numeric"
                                       disabled={props.disabled}
                                       value={tarjeta && tarjeta.codigoSeguridad ? tarjeta.codigoSeguridad : ""}
                                       required={true} onChange={(e) => onChangeTarjeta(e)}
                                       style={{width: "80px", alignSelf: "flex-start"}}
                                       autoComplete="new-password"/>
                            </div>
                        </div>
                    </div>
                    <div className="input-row">
                        <label htmlFor="vencimiento">Vencimiento (MM AA)</label>
                        <div style={{display: "flex"}} className="contenedor-vencycod">
                            <input id="mesVencimiento" name="mesVencimiento" type="number"
                                   style={{width: "50px", marginRight: "10px"}} min={1} max={12}
                                   disabled={props.disabled}
                                   value={tarjeta && tarjeta.mesVencimiento ? tarjeta.mesVencimiento : ""}
                                   required={true} onChange={(e) => onChangeTarjeta(e)}
                            />
                            <input id="anoVencimiento" name="anoVencimiento" type="number"
                                   style={{width: "50px"}} min={0} max={99}
                                   disabled={props.disabled}
                                   value={tarjeta && tarjeta.anoVencimiento ? tarjeta.anoVencimiento : ""}
                                   required={true} onChange={(e) => onChangeTarjeta(e)}
                            />
                        </div>
                    </div>

                    <div className="input-row">
                        <label htmlFor="titular">Nombre y Apellido del Titular</label>
                        <input id="titular" name="titular" type="text" required={true}
                               disabled={props.disabled}
                               value={tarjeta && tarjeta.titular ? tarjeta.titular : ""}
                               onChange={(e) => onChangeTarjeta(e)} autoComplete="new-password"/>
                    </div>

                    <div className="input-row">
                        <label htmlFor="dni">DNI de Titular</label>
                        <input id="dni" name="dni" type="number" required={true}
                               disabled={props.disabled}
                               onChange={(e) => onChangeTarjeta(e)}
                               value={tarjeta && tarjeta.dni ? tarjeta.dni : ""}
                        />
                    </div>

                    {/*<div className="input-row">*/}
                    {/*    <label htmlFor="fechaNacimiento">Fecha de Nacimiento</label>*/}
                    {/*    <div style={{display: "flex"}} className="contenedor-vencycod">*/}
                    {/*        <Fecha id="fechaNacimiento" required={false}*/}
                    {/*               readOnly={props.disabled}*/}
                    {/*               onChange={(e) => onChangeTarjeta(e)}*/}
                    {/*               fecha={tarjeta && tarjeta.fechaNacimiento ? tarjeta.fechaNacimiento : ""}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="input-row">
                        <label htmlFor="cuitFactura">CUIT para Facturar</label>
                        <input id="cuitFactura" name="cuitFactura"
                               disabled={props.disabled}
                               onChange={(e) => onChangePago(e)}
                               value={pago && pago.cuitFactura ? pago.cuitFactura : ""}
                        />
                    </div>

                </Col>
                <Col md={6} xs={12}>

                    <div className="contenedorTarjetita">
                        <div
                            className={`tarjeta ${esVisa ? "visa" : ""} ${esMastercard ? "mastercard" : ""}`}>
                            <div className="chip">
                                <img src={chip} alt="chip"/>
                            </div>
                            <div className="tipoTarjeta" style={{display: esVisa ? "block" : "none"}}>
                                <img src={visa} alt="visa"/>
                            </div>
                            <div className="tipoTarjeta" style={{display: esMastercard ? "block" : "none"}}>
                                <img src={mastercard} alt="mastercard"/>
                            </div>
                            <div className="tipoTarjeta"
                                 style={{display: esAmericanExpress ? "block" : "none"}}>
                                <img src={americanExpress} alt="americanExpress"/>
                            </div>
                            <div className="tipoTarjeta" style={{display: esDiners ? "block" : "none"}}>
                                <img style={{width: "80px"}} src={dinersInternational}
                                     alt="dinersInternational"/>
                            </div>
                            <div
                                className="nroTarjeta">{tarjeta && tarjeta.nroTarjeta ? nroTarjetaDeACuatro : "•••• •••• •••• ••••"}</div>
                            <div
                                className="nombreTitular">{tarjeta && tarjeta.titular ? tarjeta.titular : "•••••• ••••••"}</div>
                            <div className="contenedorVencimientoTarjeta">
                                <div className="vencimientoTarjeta">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginRight: "5px"
                                    }}>
                                        <span style={{fontSize: "6px"}}>valid</span>
                                        <span style={{fontSize: "6px"}}>thru</span>
                                    </div>
                                    <span
                                        className="mes">{tarjeta && tarjeta.mesVencimiento ? tarjeta.mesVencimiento : "••"}</span>
                                    <span>/</span>
                                    <span
                                        className="año">{tarjeta && tarjeta.anoVencimiento ? tarjeta.anoVencimiento : "••"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
        ;
}
