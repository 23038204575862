import {
    RECEIVE_CATEGORIAS
} from '../actions/CategoriaActions';
import {combineReducers} from 'redux';
import {normalizeDatos} from "../normalizers/normalizeCategorias";

function preciosPromocionalesById(state = {
    isFetching: false,
    didInvalidate: true,
    preciosPromocionales: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                preciosPromocionales: normalizeDatos(action.categorias).entities.preciosPromocionales,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allPreciosPromocionales(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            let precios = normalizeDatos(action.categorias).entities.preciosPromocionales;
            return precios ? Object.keys(precios) : [];
        default:
            return state
    }
}


const preciosPromocionales = combineReducers({
    byId: preciosPromocionalesById,
    allIds: allPreciosPromocionales
});

export default preciosPromocionales;
/**
 * Created by marinagarcia on 5/9/17.
 */
