import kits from "../api/kits"
import * as errorMessages  from '../constants/MessageConstants';

//KITS
export const REQUEST_KITS = 'REQUEST_KITS';
export const RECEIVE_KITS = 'RECEIVE_KITS';
export const INVALIDATE_KITS = 'INVALIDATE_KITS';
export const ERROR_KITS= "ERROR_KITS";


export function invalidateKits(pedido) {
    return {
        type: INVALIDATE_KITS,
        pedido
    }
}

function requestKits() {
    return {
        type: REQUEST_KITS,
    }
}

function receiveKits(json) {
    return {
        type: RECEIVE_KITS,
        kits: json,
        receivedAt: Date.now()
    }
}

function errorKits(error) {
    return {
        type: ERROR_KITS,
        error: error,
    }
}

function fetchKits() {
    return dispatch => {
        dispatch(requestKits());
        return kits.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                console.log(data);
                dispatch(receiveKits(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorKits(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorKits(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchKits(state) {
    const kits = state.kits.byId;
    if (!kits) {
        return true
    } else if (kits.isFetching) {
        return false
    } else {
        return kits.didInvalidate;
    }
}

export function fetchKitsIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchKits(getState())) {
            return dispatch(fetchKits())
        }
    }
}
