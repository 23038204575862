import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import history from "../../../history";

class OkCheckInPss extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            history.push("/checkin-pss");
        }, 4000);
    }

    render() {

        return (
            <Row className="gracias">
                <Col md={12} className="center-block">
                    <h1>Gracias!</h1>
                    <h4>Tu check-in se realizó correctamente</h4>
                    <p>Recibirás un e-mail para realizar preguntas en el evento</p>
                    <div className="lineaCorta"></div>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {};
}


const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OkCheckInPss));
