import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row, Col} from 'react-bootstrap';

//Components
import ProductoDestacado from "../producto/Producto";

//Images
import loadImg from '../../../assets/img/loader.gif';


//CSS
import "../../../assets/css/CatalogoMarca.css";

//Actions
import {fetchCategoriasIfNeeded} from "../../../actions/CategoriaActions";

//Lodash
import pickBy from "lodash/pickBy";
import sampleSize from "lodash/sampleSize";

class CatalogoMarca extends Component {

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
    }

    render() {

        //encontrar marca en campaña
        var marcaEnCampana = null;
        if (this.props.marcas.byId.marcas)
            Object.values(this.props.marcas.byId.marcas).some((marca) => {
                if (marca.enCampaña) {
                    marcaEnCampana = marca;
                    return;
                }
            });


        if (marcaEnCampana) {
            let path = null;
            if (marcaEnCampana.pathImagen != undefined)
                try {
                    const svg = require("!raw-loader!../../../assets/img/marcas/" + marcaEnCampana.pathImagen);
                    if (svg)
                        path = <span className="" dangerouslySetInnerHTML={{__html: svg}}/>;
                } catch (e) {
                }
            var bannerCampana =
                <div className="container-marca">
                    {path}
                    <h2 className="sinMarca"
                        style={{display: !path ? "inline" : "none"}}>{marcaEnCampana.desMarca}</h2>
                </div>;
            var seleccionMarca = pickBy(this.props.productos.byId.productos, function (producto, key) {
                let marca = this.props.marcas.byId.marcas[producto.idMarca];
                if (marca)
                    return marca.id == marcaEnCampana.id && producto.destacado;
            }.bind(this));
            var seleccionRandom = sampleSize(seleccionMarca, 5);

            var Productos = seleccionRandom.map((producto) => {
                producto = this.props.productos.byId.productos[producto.id];
                return <ProductoDestacado key={producto.id + "-" + producto.vd_idProducto} col={4} producto={producto}/>
            });
        }
        var Loader = () => {
            return (
                <div style={{
                    textAlign: "center",
                    float: "none",
                    margin: "0 auto",
                    paddingTop: "60px",
                    paddingBottom: "60px"
                }}><img style={{width: "32px"}}
                        src={loadImg} alt=""/></div>
            );
        }
        return (
            !this.props.categorias.byId.isFetching ? (
                    <section className="productos-destacados catalogo-marca">
                        <div className="separador"></div>
                        <div className="container-limit">
                            <div className="grid">
                                {bannerCampana}
                                {Productos}
                            </div>
                        </div>
                        <div className="separador"></div>
                    </section>
                ) : (Loader())
        );
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogoMarca);