import planesCuotas from "../api/planesCuotas";
import * as errorMessages  from '../constants/MessageConstants';
import {logout} from "../actions/AuthenticationActions";


//PLANESCUOTAS
export const REQUEST_PLANESCUOTAS = 'REQUEST_PLANESCUOTAS';
export const RECEIVE_PLANESCUOTAS = 'RECEIVE_PLANESCUOTAS';
export const INVALIDATE_PLANESCUOTAS = 'INVALIDATE_PLANESCUOTAS';
export const ERROR_PLANESCUOTAS= "ERROR_PLANESCUOTAS";
export const RESET_PLANESCUOTAS = "RESET_PLANESCUOTAS";


export function invalidatePlanesCuotas(plan) {
    return {
        type: INVALIDATE_PLANESCUOTAS,
        plan
    }
}

function requestPlanesCuotas() {
    return {
        type: REQUEST_PLANESCUOTAS,
    }
}

export function resetPlanesCuotas() {
    return {
        type: RESET_PLANESCUOTAS,
    }
}

function receivePlanesCuotas(json) {
    return {
        type: RECEIVE_PLANESCUOTAS,
        planesCuotas: json,
        receivedAt: Date.now()
    }
}

function errorPlanesCuotas(error) {
    return {
        type: ERROR_PLANESCUOTAS,
        error: error,
    }
}

function fetchPlanesCuotas() {
    return dispatch => {
        dispatch(requestPlanesCuotas());
        return planesCuotas.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePlanesCuotas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPlanesCuotas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPlanesCuotas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPlanesCuotas(state) {
    const planesCuotas = state.planesCuotas.byId;
    if (!planesCuotas) {
        return true
    } else if (planesCuotas.isFetching) {
        return false
    } else {
        return planesCuotas.didInvalidate;
    }
}

export function fetchPlanesCuotasIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchPlanesCuotas(getState())) {
            return dispatch(fetchPlanesCuotas())
        }
    }
}

