import {combineReducers} from 'redux';
import {ERROR_FORMA_DE_PAGO, INVALIDATE_FORMA_DE_PAGO, RECEIVE_FORMA_DE_PAGO, REQUEST_FORMA_DE_PAGO} from "../actions/FormaDePagoActions";

function tipoTarjetaCreditoReducer(state = {
    isFetching: false,
    didInvalidate: true,
    tiposTarjetaCredito: null,
    error: null,
}, action) {
    switch (action.type) {
        case INVALIDATE_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                didInvalidate: true,
                error: null,
            });
        case REQUEST_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false,
                error: null,
            });
        case RECEIVE_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                tiposTarjetaCredito: action.formaDePago ? action.formaDePago.entities.tiposTarjetaCredito : {},
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_FORMA_DE_PAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}

function allTiposTarjetaCredito(state = [], action) {
    switch (action.type) {
        case RECEIVE_FORMA_DE_PAGO:
            return action.formaDePago && action.formaDePago.entities.tiposTarjetaCredito ? Object.keys(action.formaDePago.entities.tiposTarjetaCredito) : [];
        default:
            return state
    }
}


const tiposTarjetaCredito = combineReducers({
    byId: tipoTarjetaCreditoReducer,
    allIds: allTiposTarjetaCredito,
});

export default tiposTarjetaCredito;