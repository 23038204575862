import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, MenuItem, DropdownButton, ListGroup, ListGroupItem} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import siera from '../../../assets/img/evento/siera.png';
import kit from '../../../assets/img/evento/kit.png';
import banner from '../../../assets/img/evento/preguntas/header.png';
import footer from '../../../assets/img/evento/preguntas/footer.png';

import '../../../assets/css/PreguntasEvento.css';


import {fetchPreguntasIfNeeded} from "../../../actions/PreguntasActions";

class ResponderPreguntas extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var idEvento = this.props.match.params.idEvento;
        this.props.fetchPreguntasIfNeeded(idEvento);

    }

    render() {
        let Preguntas = [];
        let i = 0;
        while (this.props.preguntas.allIds.length > i) {
            let idPregunta = this.props.preguntas.allIds[i];
            let pregunta = this.props.preguntas.byId.preguntas[idPregunta];
            let idPreguntaAnt = this.props.preguntas.allIds[i];
            let preguntaAnt = this.props.preguntas.byId.preguntas[idPreguntaAnt];
            let preguntasTemas = [];
            while (this.props.preguntas.allIds.length > i && pregunta.idTema == preguntaAnt.idTema) {
                let usuario = this.props.usuarios.byId.usuarios[pregunta.idUsuario];
                preguntasTemas.push(
                    <ListGroupItem>
                        {pregunta.pregunta}
                        <br/>
                        <p className="usuario">{usuario.nombre +" "+usuario.apellido+" - "+usuario.empresa}</p>
                    </ListGroupItem>
                );
                i++;
                idPregunta = this.props.preguntas.allIds[i];
                pregunta = this.props.preguntas.byId.preguntas[idPregunta];

            }
            Preguntas.push(
                <div>
                    <h3>{preguntaAnt.desTema}</h3>
                    <ListGroup>
                        {preguntasTemas}
                    </ListGroup>
                </div>
            );

        }


        return (
            <div className="preguntas">
                <div className="container-limit">
                    <div className="header">
                        <Link to="/"><img src={kit} className="pull-right" alt=""/></Link>
                    </div>
                    <Row>
                        <Col md={12}>
                            {Preguntas.length > 0 ? Preguntas : <h3>No hay preguntas</h3>}
                        </Col>
                    </Row>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        preguntas: state.preguntas
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchPreguntasIfNeeded: (idEvento) => {
            dispatch(fetchPreguntasIfNeeded(idEvento));
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResponderPreguntas));
