import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from 'react-redux';

//Lodash
import sampleSize from "lodash/sampleSize";
import pickBy from "lodash/pickBy";

//Components
import Producto from '../producto/Producto';

//Images
import '../../../assets/css/ProductosHome.css';

//Actions
import {fetchCategoriasIfNeeded} from "../../../actions/CategoriaActions";

class OtrosProductos extends Component {

    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
    }

    verMas(categoria, subCategoria) {
        if (categoria)
            this.props.history.push("/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase());
        else if (subCategoria)
            this.props.history.push("/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase() + "/" + subCategoria.desSubCategoria.replace(/\s/g, "").toLowerCase())
    }

    render() {
        const {categoria, subcategoria} = this.props;
        var seleccionProductos = [];
        var Productos = [];
        if (!subcategoria && categoria) {
            seleccionProductos = pickBy(this.props.productos.byId.productos, (producto) => {
                return producto.grupo == categoria.codigo;
            });
        }
        else if (subcategoria)
            seleccionProductos = pickBy(this.props.productos.byId.productos, (producto) => {
                return producto.sub_grupo == subcategoria.codigo_sub;
            });
        if (seleccionProductos) {
            var seleccionRandom = sampleSize(seleccionProductos, 3);

            Productos = seleccionRandom.map((producto) => {
                // let producto = this.props.productos.byId.productos[idProducto];
                return <Producto producto={producto}/>
            });
        }
        return (
            <section className="container-limit otros-productos">
                <div style={{display: Productos.length >0 ? "inline":"none"}}>
                    <Row>
                        <Col md={12}>
                            <h2 className="titulo">OTROS PRODUCTOS PARA TU PROYECTO</h2>
                        </Col>
                    </Row>
                    <div className="contenedorOtros">
                        {Productos}
                    </div>
                    <Row className="vermas">
                        <a onClick={() => this.verMas(categoria, subcategoria)}>Ver más</a>
                    </Row>
                </div>
            </section>
        )
            ;

    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OtrosProductos));
