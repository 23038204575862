import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import {Table, Row, Col, Button, FormControl} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import history from "../../../../history";
import isEmpty from "lodash/isEmpty";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//CSS
import '../../../../assets/css/ResumenCarrito.css';
import spinner from '../../../../assets/img/spinner.gif';

//Actions
import {
    invalidatePreciosProducto,
    fetchPreciosProductoIfNeeded,
    invalidatePreciosProductoTuPrecio,
    fetchPreciosProductoTuPrecioIfNeeded
} from "../../../../actions/PreciosProductoActions";
import {
    invalidateListaPrecios,
    fetchListaPreciosIfNeeded,
    updateListaPrecio, resetUpdateListaPrecio
} from "../../../../actions/ListaPreciosActions";
import {
    saveCreatePedido,
    resetCreatePedido,
    createPedido,
    fetchUltimoPedidoIfNeeded,
    invalidateUltimoPedido,
} from "../../../../actions/PedidoActions";
import {
    createDetallePedidoMon,
    deleteDetallePedidoMon,
    resetDetallesPedidoMon
} from "../../../../actions/DetallePedidoMonActions";
import {resetUpdateUsuario} from "../../../../actions/UsuarioActions";
import {fetchTransportesIfNeeded} from "../../../../actions/TransporteActions";
import {fetchFormasEntregaIfNeeded} from "../../../../actions/FormaEntregaActions";
import {invalidateProvincias, fetchProvinciasIfNeeded} from "../../../../actions/ProvinciasActions";
import {invalidateCiudades, fetchCiudadesIfNeeded} from "../../../../actions/CiudadActions";

//Constants
import * as herramientas from "../../../../constants/Herramientas";
import * as roles from "../../../../constants/Roles";
import * as cuentas from "../../../../constants/Cuentas";
import * as formasEntrega from "../../../../constants/FormaEntrega";

//Images
import logoKit from "../../../../assets/img/cuentas/logoKit.png";
import botonMas from "../../../../assets/img/carrito/botonMas.png";
import botonMenos from "../../../../assets/img/carrito/botonMenos.png";
import inicio from "../../../../assets/img/monitorPedidos/inicio.png";
import kitLogo from "../../../../assets/img/kitLogo.png";


//Components
import FieldGroup from "../../../camposTexto/FieldGroup";
import moment from "moment";
import {EXPERTO_A_DOMICILIO_ID} from "../../../../constants/FormaEntrega";

class ResumenCarrito extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mostrar: false,
            deshabilitarEnvio: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchListaPreciosIfNeeded();
    }

    componentDidUpdate(prevProps) {
        //Si no tengo detalles vuelvo a carrito
        if (this.props.detallesPedidoMon.allIds.length == 0) {
            history.push('/panel/listaPrecios/1');
        }

    }

    componentWillUnmount() {
        //Si el pedido fue enviado lo borra.
        if (this.state.deshabilitarEnvio) {
            this.props.resetCreatePedido();
            this.props.resetDetallesPedidoMon();
            this.props.resetUpdateListaPrecio();
            this.props.resetUpdateUsuario();
        }
    }

    updatePrecios(e) {
        //Al cambiar la lista de precios busco los nuevos precios
        this.props.invalidatePreciosProducto();
        this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
            var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
            //Busco precios del usuario seleccionado si esta seleccionado o si no está seleccionado busco del logueado
            if ((this.props.usuarios.update.activo.id && accesoCuenta.idUsuario == this.props.usuarios.update.activo.id)
                || (!this.props.usuarios.update.activo.id && accesoCuenta.idUsuario == this.props.usuarios.byId.usuarioLogueado.id)) {
                accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                    var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                    if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                        this.props.fetchPreciosProductoIfNeeded(e.target.value, habilitacion.id);
                    }
                });
            }
            if (this.props.usuarios.byId.usuarioLogueado.idRol == roles.DISTRIBUIDOR_ID && accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID &&
                accesoCuenta.idUsuario == this.props.usuarios.byId.usuarioLogueado.id) {
                accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                    var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                    if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                        this.props.invalidatePreciosProductoTuPrecio();
                        this.props.fetchPreciosProductoTuPrecioIfNeeded(e.target.value, habilitacion.id);
                    }
                });

            }
        });
        this.setState({deshabilitarEnvio: false});
        this.props.updateListaPrecio(this.props.listaPrecio.byId.listaPrecio[e.target.value]);

    }

    handleAgregarCantidad(idProducto) {
        var detalle = this.props.detallesPedidoMon.byId.detallesPedidoMon[idProducto + "-"];
        if (!detalle) {
            detalle = {};
            var cantidad = {};
            cantidad["idProducto"] = idProducto;
            cantidad["cantidad"] = 1;
            detalle[idProducto + "-"] = cantidad;
            this.props.createDetallePedidoMon(detalle);
        } else {
            var cambio = {};
            detalle.cantidad = detalle.cantidad + 1;
            cambio[idProducto + "-"] = detalle;
            this.props.createDetallePedidoMon(cambio);
        }

    }

    handleQuitarCantidad(idProducto) {
        var detalle = this.props.detallesPedidoMon.byId.detallesPedidoMon[idProducto + "-"];
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                cambio[idProducto + "-"] = detalle;
                this.props.createDetallePedidoMon(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                this.props.deleteDetallePedidoMon(cambio);
            }
        }
    }

    nuevoPedido(e) {
        e.preventDefault();
        this.props.history.push("/panel/listaPrecios/checkout/2");
        let cambio = {};
        cambio.esPresupuesto = false;
        this.props.createPedido(cambio);
    }

    guardarPedido(e) {
        e.preventDefault();
        this.setState({mostrar: true});
        let cambio = {};
        cambio.esPresupuesto = true;
        this.props.createPedido(cambio);
    }


    render() {
        const {deshabilitarEnvio, formaPedido} = this.state;
        var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
        var usuarioSeleccionado = this.props.usuarios.update.activo;
        var calcularIva = this.props.listaPrecio.update.activo.iva == 0;

        var createPedido = this.props.pedidos.create.nuevo;

        var opcionesLista = [];
        this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
            var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
            if (accesoCuenta && accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID
                && (!usuarioSeleccionado.id && accesoCuenta.idUsuario == usuarioLogueado.id) || (usuarioSeleccionado.id && accesoCuenta.idUsuario == usuarioSeleccionado.id)) {
                accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                    var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                    if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                        if (habilitacion.listasHabilitadas != 'todas') {
                            let idListas = (habilitacion.listasHabilitadas).split(',');
                            for (let idLista of idListas) {
                                var listaPrecio = this.props.listaPrecio.byId.listaPrecio[idLista];
                                if (listaPrecio) {
                                    if (!usuarioSeleccionado.id || usuarioLogueado.idRol == roles.DISTRIBUIDOR_ID)
                                        opcionesLista.push(<option value={listaPrecio.id}>{listaPrecio.area}</option>);
                                    else
                                        opcionesLista.push(<option
                                            value={listaPrecio.id}>{listaPrecio.tipoLista}
                                            - {listaPrecio.area}</option>);
                                }
                            }
                        } else {
                            this.props.listaPrecio.allIds.map((idLista) => {
                                var lista = this.props.listaPrecio.byId.listaPrecio[idLista];
                                if (lista) {
                                    if (usuarioLogueado.idRol == roles.DISTRIBUIDOR_ID)
                                        opcionesLista.push(<option value={lista.id}>{lista.area}</option>);
                                    else
                                        opcionesLista.push(<option
                                            value={lista.id}>{lista.tipoLista} - {lista.area}</option>);
                                }
                            });
                        }
                    }
                });
            }
        });

        //Inicializo el subtotal e impuestos
        var subTotal = 0;
        var impuestos = 0;

        //Obtengo moneda
        var simbolo = '$';
        if (this.props.listaPrecio.update.activo && Object.keys(this.props.listaPrecio.update.activo).length !== 0) {
            simbolo = this.props.listaPrecio.update.activo.moneda.simbolo;
        }

        //Cargo productos segun lista.
        var Productos = [];
        this.props.detallesPedidoMon.allIds.map((idDetalle) => {
            var detalle = this.props.detallesPedidoMon.byId.detallesPedidoMon[idDetalle + '-'];
            var producto = this.props.productos.byId.productos[idDetalle];
            if (detalle && producto) {
                //Busco precio de producto segun lista
                var precioProducto = this.props.preciosProducto.byId.preciosProducto[idDetalle];
                var precioActualizado = 0;
                if (precioProducto && precioProducto.precio) {
                    precioActualizado = parseFloat(precioProducto.precio).toFixed(2);
                }
                var imagenProducto = "/img/productos/" + producto.img1;
                var total = detalle.cantidad * precioActualizado;
                impuestos = impuestos + total * parseFloat(producto.iva) / 100;
                //Calculo monto total
                subTotal = subTotal + total;
                var ProductosKit = [];
                if (producto.esKit) {
                    this.props.kits.allIds.map((idKit) => {
                        var kit = this.props.kits.byId.kits[idKit];
                        if (kit && kit.idProductoPadre == producto.id) {
                            let productoKit = this.props.productos.byId.productos[kit.idProducto];
                            if (productoKit) {
                                ProductosKit.push(productoKit);
                            }
                        }
                    });
                }
                if (calcularIva) {
                    Productos.push(
                        <tr key={producto.id}
                            className={producto.esKit ? "columnaProductos kit primera" : "columnaProductos"}
                        >
                            <td>
                                <img className="imagenTabla" src={imagenProducto} onError={(e) => {
                                    e.target.src = kitLogo
                                }}/>
                            </td>
                            <td style={{textAlign: "left"}}>
                                <p>{producto.vd_idProducto}</p>
                                <h5 className="nombreProducto"><b>{producto.nomProducto}</b></h5>
                                <Link target="_blank" to={"/productoDetalle/" + producto.id} className="verMas">Ver
                                    mas</Link>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>{simbolo + " "}{precioActualizado}</p>
                                <img src={spinner}
                                     style={{
                                         display: this.props.preciosProducto.byId.isFetching ? "block" : "none",
                                         margin: "0 auto"
                                     }}/>
                            </td>
                            <td className="tdCantidad">
                                <img className="botonMasMenos" src={botonMenos}
                                     onClick={() => this.handleQuitarCantidad(producto.id)}/>
                                {detalle.cantidad}
                                <img className="botonMasMenos" src={botonMas}
                                     onClick={() => this.handleAgregarCantidad(producto.id)}/>
                            </td>
                            <td>{producto.iva}</td>
                            <td style={{whiteSpace: "nowrap"}}>
                                <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>{simbolo + " "}{total.toFixed(2)}</p>
                                <img src={spinner} style={{
                                    display: this.props.preciosProducto.byId.isFetching ? "block" : "none",
                                    margin: " 0 auto"
                                }}/>
                            </td>
                        </tr>
                    );
                    if (producto.esKit) {
                        ProductosKit.forEach((productoKit) => {
                            imagenProducto = "/img/productos/" + productoKit.img1;
                            let cantidadKit = 0;
                            producto.kit.map((idKit) => {
                                var kit = this.props.kits.byId.kits[idKit];
                                if (kit && kit.idProducto == productoKit.id) {
                                    cantidadKit = kit.cantidad * detalle.cantidad;
                                }
                            });
                            Productos.push(
                                <tr key={producto.id + " " + productoKit.id} className="columnaProductos kit">
                                    <td>
                                    </td>
                                    <td style={{textAlign: "left"}}>
                                        <h5 className="nombreProducto">
                                            <b>{productoKit.vd_idProducto} - {productoKit.nomProducto}</b></h5>
                                        <Link target="_blank" to={"/productoDetalle/" + productoKit.id}
                                              className="verMas">Ver mas</Link>
                                    </td>
                                    <td style={{whiteSpace: "nowrap"}}>
                                        <p>-</p>
                                    </td>
                                    <td>{cantidadKit}</td>
                                    <td>-</td>
                                    <td style={{whiteSpace: "nowrap"}}>-</td>
                                </tr>
                            );
                        })
                    }
                } else {
                    Productos.push(
                        <tr key={producto.id}
                            className={producto.esKit ? "columnaProductos kit primera" : "columnaProductos"}>
                            <td>
                                <img className="imagenTabla" src={imagenProducto}/>
                            </td>
                            <td style={{textAlign: "left"}}>
                                <p>{producto.vd_idProducto}</p>
                                <h5><b>{producto.nomProducto}</b></h5>
                                <Link target="_blank" to={"/productoDetalle/" + producto.id} className="verMas">Ver
                                    mas</Link>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>{simbolo + " "}{precioActualizado}</p>
                                <img src={spinner} style={{
                                    display: this.props.preciosProducto.byId.isFetching ? "block" : "none",
                                    margin: " 0 auto"
                                }}/>
                            </td>
                            <td>
                                <img className="botonMasMenos" src={botonMenos}
                                     onClick={() => this.handleQuitarCantidad(producto.id)}/>
                                {detalle.cantidad}
                                <img className="botonMasMenos" src={botonMas}
                                     onClick={() => this.handleAgregarCantidad(producto.id)}/>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>
                                    {simbolo + " "}{total.toFixed(2)}
                                </p>
                                {this.props.preciosProducto.byId.isFetching ?
                                    <img src={spinner} style={{margin: " 0 auto"}}/>
                                    : ""
                                }
                            </td>
                        </tr>
                    );
                    if (producto.esKit) {
                        ProductosKit.forEach((productoKit) => {
                            imagenProducto = "/img/productos/" + productoKit.img1;
                            let cantidadKit = 0;
                            producto.kit.map((idKit) => {
                                var kit = this.props.kits.byId.kits[idKit];
                                if (kit && kit.idProducto == productoKit.id) {
                                    cantidadKit = kit.cantidad * detalle.cantidad;
                                }
                            });
                            Productos.push(
                                <tr key={producto.id + " " + productoKit.id} className="columnaProductos kit">
                                    <td>
                                    </td>
                                    <td style={{textAlign: "left"}}>
                                        <h5><b>{productoKit.vd_idProducto} - {productoKit.nomProducto}</b></h5>
                                        <Link target="_blank" to={"/productoDetalle/" + productoKit.id}
                                              className="verMas">Ver mas</Link>
                                    </td>
                                    <td style={{whiteSpace: "nowrap"}}>
                                        <p>-</p>
                                    </td>
                                    <td>{cantidadKit}</td>
                                    <td style={{whiteSpace: "nowrap"}}>-</td>
                                </tr>
                            );
                        });
                    }
                }
            }

        });
        //Si es Lista 1 se calcula IVA
        if (calcularIva) {
            Productos.push(
                <tr key="subtotal" className="columnaProductos">
                    <td colSpan={5} style={{textAlign: "right", padding: "10px"}}>
                        Sub Total
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>
                            {simbolo + " "}{subTotal.toFixed(2)}
                        </p>
                        {this.props.preciosProducto.byId.isFetching ?
                            <img src={spinner} style={{margin: " 0 auto"}}/>
                            : ""
                        }
                    </td>
                </tr>
            );
            Productos.push(
                <tr key="impuestos" className="columnaProductos">
                    <td colSpan={5} style={{textAlign: "right", padding: "10px"}}>
                        Impuestos
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>
                            {simbolo + " "}{impuestos.toFixed(2)}
                        </p>
                        {this.props.preciosProducto.byId.isFetching ?
                            <img src={spinner} style={{margin: " 0 auto"}}/>
                            : ""
                        }
                    </td>
                </tr>
            );
        }

        var totalCarrito = calcularIva ? impuestos + subTotal : subTotal;
        Productos.push(
            <tr key="total" className="columnaProductos">
                <td colSpan={calcularIva ? 5 : 4} style={{textAlign: "right", padding: "10px"}}>
                    Total
                </td>
                <td style={{whiteSpace: "nowrap"}}>
                    <p style={{display: this.props.preciosProducto.byId.isFetching ? "none" : "block"}}>
                        {simbolo + " "}{totalCarrito.toFixed(2)}
                    </p>
                    {this.props.preciosProducto.byId.isFetching ?
                        <img src={spinner} style={{margin: " 0 auto"}}/>
                        : ""
                    }
                </td>
            </tr>
        );

        var idUsuarioSeleccionado =
            usuarioSeleccionado && Object.keys(usuarioSeleccionado).length != 0 ? usuarioSeleccionado.id : null;

        return (
            <div className="resumenCarrito">
                <SweetAlert info
                            showCancel
                            confirmBtnText="Si"
                            cancelBtnText="No"
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="default"
                            title="Guardar pedido"
                            onConfirm={() => {
                                this.props.saveCreatePedido(this.props.listaPrecio.update.activo.id, idUsuarioSeleccionado);
                                this.setState({mostrar: false, deshabilitarEnvio: true});
                            }}
                            onCancel={() => {
                                this.setState({mostrar: false})
                            }}
                            show={this.state.mostrar}>
                    Su pedido será guardado. Posteriormente podrá continuar o modificarlo desde la herramienta monitor
                    de pedidos ¿Desea continuar?
                </SweetAlert>

                <form onSubmit={(e) => this.nuevoPedido(e)} name="form">
                    <Row>
                        <Col xs={10} sm={10} md={10} lg={10} xsOffset={1} className="tituloCarrito">
                            <h2>Resumen</h2>
                        </Col>
                    </Row>
                    <Row className="columnaDatosMayor">
                        <Col xs={10} sm={10} md={10} lg={10} xsOffset={1} className="columnaDatos">
                            <Row>
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="tablaCarrito"
                                    filename="tablexls"
                                    sheet="tablexls"
                                    buttonText="Descargar Excel"/>
                                <Col xs={4} sm={4} md={2} lg={2} xsOffset={1} className="listaPrecios lesspadin">
                                    <Row style={{display: usuarioSeleccionado.id ? "block" : "none"}}>
                                        <p className="textoListaPrecios"
                                           style={{whiteSpace: "nowrap"}}>
                                            Usuario: {usuarioSeleccionado.id ? usuarioSeleccionado.nombre : ""} {usuarioSeleccionado.id ? usuarioSeleccionado.apellido : ""}</p>
                                    </Row>
                                    {/*<Row style={{display: usuarioSeleccionado.id ? "block" : "none"}}>*/}
                                    {/*<p className="textoListaPrecios" style={{width: "100%"}}>Se le enviará un mail*/}
                                    {/*a {usuarioSeleccionado.id ? usuarioSeleccionado.email : ""}</p>*/}
                                    {/*</Row>*/}
                                    <Row>
                                        <p className="textoListaPrecios">Lista precios:</p>
                                        <FormControl className="comboListaPrecios" componentClass="select"
                                                     name="comboListaPrecios"
                                                     placeholder="select"
                                                     disabled={this.props.preciosProducto.byId.isFetching}
                                                     onChange={(e) => this.updatePrecios(e)}
                                                     value={this.props.listaPrecio.update.activo && this.props.listaPrecio.update.activo.id ? this.props.listaPrecio.update.activo.id : 3}>
                                            {opcionesLista}
                                        </FormControl>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Table className="tablaResumen" id="tablaCarrito" responsive>
                                    <thead className="tableHead">
                                    <th>Imagen</th>
                                    <th>Artículo y Descripción</th>
                                    <th>Precio Unitario</th>
                                    <th>Cantidad</th>
                                    <th style={{display: calcularIva ? "table-cell" : "none"}}>IVA%</th>
                                    <th>Total</th>
                                    </thead>
                                    <tbody>
                                    {Productos}
                                    </tbody>
                                </Table>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="contenedorBotones">
                        <Col xs={10} sm={10} md={10} lg={10} xsOffset={1} className="contenedorTotal">
                            <div style={{display: this.props.pedidos.create.isCreating ? "block" : "none"}}>
                                <img src={spinner} alt="" style={{float: "left"}}/>
                                <p className="textoEnviando">
                                    Enviando...
                                </p>
                            </div>
                            <div style={{display: this.props.pedidos.create.isCreating ? "none" : "", height: "80px"}}>
                                <p style={{display: this.state.deshabilitarEnvio ? "block" : "none"}}>Su pedido ha sido
                                    enviado. En instantes recibirá un mail con las indicaciones.</p>
                                <p style={{
                                    display: this.state.deshabilitarEnvio
                                    && createPedido.idFormaEntrega == formasEntrega.EXPERTO_A_TRANPORTE_ID ? "block" : "none"
                                }}>Podrá ver el detalle del transporte elegido desde la herramienta Monitor De
                                    Pedidos.</p>
                                <div className="botoneraDerecha">
                                    <Button className="botonInferiorEnviar"
                                            type="submit"
                                            disabled={deshabilitarEnvio}>
                                        Procesar
                                    </Button>
                                </div>
                                <div className="botoneraIzquierda">
                                    <Button className="botonInferiorEnviar"
                                            type="button" onClick={(e) => this.guardarPedido(e)}
                                            disabled={deshabilitarEnvio}>
                                        Guardar
                                    </Button>
                                    <Link to="/panel/listaPrecios/1">
                                        <Button className="botonInferiorVolver">
                                            Volver a carrito
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        detallesPedidoMon: state.detallesPedidoMon,
        productos: state.productos,
        preciosProducto: state.preciosProducto,
        habilitaciones: state.habilitaciones,
        listaPrecio: state.listaPrecio,
        pedidos: state.pedidos,
        kits: state.kits,
        accesosCuenta: state.accesosCuenta,
        usuarios: state.usuarios,
        transportes: state.transportes,
        formasEntrega: state.formasEntrega,
        ciudades: state.ciudades,
        provincias: state.provincias,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        invalidatePreciosProducto: () => {
            dispatch(invalidatePreciosProducto())
        },
        fetchPreciosProductoIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoIfNeeded(idLista, idHabilitacion))
        },
        fetchListaPreciosIfNeeded: () => {
            dispatch(fetchListaPreciosIfNeeded())
        },
        invalidateListaPrecios: () => {
            dispatch(invalidateListaPrecios())
        },
        saveCreatePedido: (idLista, idUsuarioSeleccionado) => {
            dispatch(saveCreatePedido(idLista, idUsuarioSeleccionado))
        },
        resetCreatePedido: () => {
            dispatch(resetCreatePedido())
        },
        resetDetallesPedidoMon: () => {
            dispatch(resetDetallesPedidoMon())
        },
        updateListaPrecio: (listaPrecio) => {
            dispatch(updateListaPrecio(listaPrecio))
        },
        resetUpdateListaPrecio: () => {
            dispatch(resetUpdateListaPrecio())
        },
        resetUpdateUsuario: () => {
            dispatch(resetUpdateUsuario())
        },
        createDetallePedidoMon: (detalle) => {
            dispatch(createDetallePedidoMon(detalle))
        },
        deleteDetallePedidoMon: (detalle) => {
            dispatch(deleteDetallePedidoMon(detalle))
        },
        invalidatePreciosProductoTuPrecio: () => {
            dispatch(invalidatePreciosProductoTuPrecio())
        },
        fetchPreciosProductoTuPrecioIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoTuPrecioIfNeeded(idLista, idHabilitacion))
        },
        createPedido: (pedido) => {
            dispatch(createPedido(pedido))
        },
        fetchTransportesIfNeeded: (filtros) => {
            dispatch(fetchTransportesIfNeeded(filtros))
        },
        fetchUltimoPedidoIfNeeded: (idUsuario) => {
            dispatch(fetchUltimoPedidoIfNeeded(idUsuario))
        },
        invalidateUltimoPedido: () => {
            dispatch(invalidateUltimoPedido())
        },
        fetchFormasEntregaIfNeeded: () => {
            dispatch(fetchFormasEntregaIfNeeded())
        },
        invalidateProvincias: () => {
            dispatch(invalidateProvincias())
        },
        fetchProvinciasIfNeeded: () => {
            dispatch(fetchProvinciasIfNeeded())
        },
        invalidateCiudades: () => {
            dispatch(invalidateCiudades())
        },
        fetchCiudadesIfNeeded: (idProvincia) => {
            dispatch(fetchCiudadesIfNeeded(idProvincia))
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResumenCarrito));
