import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){

    const medioPago = new schema.Entity('mediosPago',{}, {idAttribute:"id"});
    const mySchema = [ medioPago ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;