import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import {Button, Row, Table} from "react-bootstrap";
import history from "../../../../history";
import moment from 'moment';

//Actions
import {fetchPedidosIfNeeded, resetUpdatePedido} from "../../../../actions/PedidoActions";
import {fetchFormasEntregaIfNeeded} from "../../../../actions/FormaEntregaActions";
import {fetchTransportesIfNeeded} from "../../../../actions/TransporteActions";
import {invalidateProvincias, fetchProvinciasIfNeeded} from "../../../../actions/ProvinciasActions";
import {invalidateCiudades, fetchCiudadesIfNeeded} from "../../../../actions/CiudadActions";

//Components
import HeaderPlataforma from "./HeaderPlataforma";
import FieldGroup from "../../../camposTexto/FieldGroup";

//CSS
import '../../../../assets/css/DetallesPedido.css';
import * as formasEntrega from "../../../../constants/FormaEntrega";

//Images
import kitLogo from "../../../../assets/img/kitLogo.png";

class DetallesPedido extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchFormasEntregaIfNeeded();
        this.props.fetchTransportesIfNeeded();
        this.props.fetchProvinciasIfNeeded();
        this.props.resetUpdatePedido();
        this.props.fetchPedidosIfNeeded({
            id: this.props.match.params.idPedido,
            buscarPedidosUsuario: true,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentWillUnmount() {
        this.props.resetUpdatePedido();
    }

    render() {
        let idPedido = this.props.match.params['idPedido'];
        let pedido = {};
        pedido = this.props.pedidos.byId.pedidos[idPedido];
        let impuestos = 0;
        let impuestosCyO = 0;

        var cuentaYOrden = pedido && pedido.idUsuario == this.props.usuarios.byId.usuarioLogueado.id && pedido.totalCuentaYOrden;

        //Obtengo moneda
        var simbolo = '$';
        if (pedido && pedido.moneda) {
            simbolo = pedido.moneda.simbolo;
        }

        var Detalles = [];
        var detallesKit = new Map();
        var productosAgregadosDelKit = [];
        this.props.detallesPedidoMon.allIds.map((idDetallePedidoMon) => {
            var detallePedidoMon = this.props.detallesPedidoMon.byId.detallesPedidoMon[idDetallePedidoMon];
            if (detallePedidoMon && detallePedidoMon.idPedidoMon == this.props.match.params.idPedido) {
                var producto = this.props.productos.byId.productos[detallePedidoMon.idProducto];
                impuestos += detallePedidoMon.precioProducto * detallePedidoMon.cantidad * parseFloat(producto.iva) / 100;
                impuestosCyO += detallePedidoMon.precioCuentaYOrden * detallePedidoMon.cantidad * parseFloat(producto.iva) / 100;
                if (producto) {
                    var imagenProducto = "/img/productos/" + producto.img1;
                    Detalles.push(
                        <tr key={detallePedidoMon.id}
                            className={producto.esKit ? "columnaProductos kit primera" : "columnaProductos"}
                        >
                            <td>
                                <img className="imagenTabla" src={imagenProducto} onError={(e) => {
                                    e.target.src = kitLogo
                                }}/>
                            </td>
                            <td style={{textAlign: "left"}}>
                                <p>{producto.vd_idProducto}</p>
                                <h5 className="nombreProducto"><b>{producto.nomProducto}</b></h5>
                                <Link target="_blank" to={"/productoDetalle/" + producto.id} className="verMas">Ver
                                    mas</Link>
                            </td>
                            <td style={{whiteSpace: "nowrap"}}>
                                <p>{simbolo + " "}{detallePedidoMon.precioProducto}</p>
                            </td>
                            <td className="tdCantidad">{detallePedidoMon.cantidad}</td>
                            <td className="tdCantidad">{detallePedidoMon.cantidadEntregada}</td>
                            <td style={{display: pedido && pedido.vd_idPrioridad == 1 ? "table-cell" : "none"}}>{producto.iva}</td>
                            <td style={{whiteSpace: "nowrap"}}>
                                <p>{simbolo + " "}{(detallePedidoMon.precioProducto * detallePedidoMon.cantidad).toFixed(2)}</p>
                            </td>
                            <td style={{
                                whiteSpace: "nowrap",
                                display: cuentaYOrden ? "table-cell" : "none"
                            }}>
                                <p>{simbolo + " "}{(detallePedidoMon.precioCuentaYOrden * detallePedidoMon.cantidad).toFixed(2)}</p>
                            </td>
                        </tr>);
                }
            }
        });
        //Total Pedido
        Detalles.push(
            <tr key="SubTotal" className="columnaDetalles borderAzul">
                <td colSpan={pedido && pedido.vd_idPrioridad == 1 && cuentaYOrden ? 7 : (pedido && pedido.vd_idPrioridad == 1 || cuentaYOrden) ? 6 : 5}
                    style={{textAlign: "right", paddingRight: "20px"}}>
                    {pedido && pedido.vd_idPrioridad == 2 ? "Total" : "SubTotal"}
                </td>
                <td style={{whiteSpace: "nowrap"}}>
                    <p>
                        {simbolo}{pedido ? pedido.total : 0}
                    </p>
                </td>
            </tr>
        );
        if (pedido && pedido.vd_idPrioridad == 1) {
            Detalles.push(
                <tr key="Impuestos" className="columnaDetalles borderAzul">
                    <td colSpan={pedido && pedido.vd_idPrioridad == 1 && cuentaYOrden ? 7 : (pedido && pedido.vd_idPrioridad == 1 || cuentaYOrden) ? 6 : 5}
                        style={{textAlign: "right", paddingRight: "20px"}}>
                        Impuestos
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        <p>
                            {simbolo}{impuestos.toFixed(2)}
                        </p>
                    </td>
                </tr>
            );
            Detalles.push(
                <tr key="total" className="columnaDetalles borderAzul">
                    <td colSpan={pedido && pedido.vd_idPrioridad == 1 && cuentaYOrden ? 7 : (pedido && pedido.vd_idPrioridad == 1 || cuentaYOrden) ? 6 : 5}
                        style={{textAlign: "right", paddingRight: "20px"}}>
                        Total
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        <p>
                            {simbolo}{pedido ? (parseFloat(pedido.total) + impuestos).toFixed(2) : 0}
                        </p>
                    </td>
                </tr>
            );
        }
        //Total Modificado Pedido
        if (cuentaYOrden) {
            Detalles.push(
                <tr key="subtotalmodificado" className="columnaDetalles borderAzul">
                    <td colSpan={pedido && pedido.vd_idPrioridad == 1 && cuentaYOrden ? 7 : (pedido && pedido.vd_idPrioridad == 1 || cuentaYOrden) ? 6 : 5}
                        style={{textAlign: "right", paddingRight: "20px"}}>
                        {pedido && pedido.vd_idPrioridad == 2 ? "Total modificado Cuenta y Orden" : "Subtotal modificado Cuenta y Orden"}
                    </td>
                    <td style={{whiteSpace: "nowrap"}}>
                        <p>{simbolo}{pedido && pedido.totalCuentaYOrden ? pedido.totalCuentaYOrden : 0}</p>
                    </td>
                </tr>
            );
            if (pedido && pedido.vd_idPrioridad == 1) {
                Detalles.push(
                    <tr key="Impuestosmodificado" className="columnaDetalles borderAzul">
                        <td colSpan={pedido && pedido.vd_idPrioridad == 1 && cuentaYOrden ? 7 : (pedido && pedido.vd_idPrioridad == 1 || cuentaYOrden) ? 6 : 5}
                            style={{textAlign: "right", paddingRight: "20px"}}>
                            Impuestos Cuenta y Orden
                        </td>
                        <td style={{whiteSpace: "nowrap"}}>
                            <p>
                                {simbolo}{impuestosCyO.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                );
                Detalles.push(
                    <tr key="totalmodificado" className="columnaDetalles borderAzul">
                        <td colSpan={pedido && pedido.vd_idPrioridad == 1 && cuentaYOrden ? 7 : (pedido && pedido.vd_idPrioridad == 1 || cuentaYOrden) ? 6 : 5}
                            style={{textAlign: "right", paddingRight: "20px"}}>
                            Total Cuenta y Orden
                        </td>
                        <td style={{whiteSpace: "nowrap"}}>
                            <p>
                                {simbolo}{pedido ? (parseFloat(pedido.totalCuentaYOrden) + impuestosCyO).toFixed(2) : 0}
                            </p>
                        </td>
                    </tr>
                );
            }
        }

        return (
            <div>
                <HeaderPlataforma titulo={"Monitor de Pedidos"}/>
                <Row className="detallesPedido">
                    <div className="titulo">
                        <h3>Detalles Pedido.</h3>
                        <p onClick={() => history.push("/panel/monitorPedidos/1")}>[Volver]</p>
                    </div>
                    <Row>
                        <Table className="tablaResumen" id="tablaCarrito" responsive>
                            <thead className="tableHead">
                            <th>Imagen</th>
                            <th>Artículo y Descripción</th>
                            <th>Precio Unitario</th>
                            <th>Cantidad</th>
                            <th>Cantidad Entregada</th>
                            <th style={{display: pedido && pedido.vd_idPrioridad == 1 ? "table-cell" : "none"}}>IVA%</th>
                            <th>Total</th>
                            <th style={{display: cuentaYOrden ? "table-cell" : "none"}}>Total Modificado</th>
                            </thead>
                            <tbody>
                            {Detalles}
                            </tbody>
                        </Table>
                    </Row>
                    <Button onClick={() => {
                        history.push("/panel/monitorPedidos/1")
                    }} className="center-block boton">Volver</Button>
                </Row>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        pedidos: state.pedidos,
        formasEntrega: state.formasEntrega,
        ciudades: state.ciudades,
        provincias: state.provincias,
        transportes: state.transportes,
        listaPrecio: state.listaPrecio,
        detallesPedidoMon: state.detallesPedidoMon,
        kits: state.kits,
        productos: state.productos,
        usuarios: state.usuarios,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPedidosIfNeeded: (filtros) => {
            dispatch(fetchPedidosIfNeeded(filtros))
        },
        fetchFormasEntregaIfNeeded: () => {
            dispatch(fetchFormasEntregaIfNeeded())
        },
        fetchTransportesIfNeeded: () => {
            dispatch(fetchTransportesIfNeeded())
        },
        invalidateProvincias: () => {
            dispatch(invalidateProvincias())
        },
        fetchProvinciasIfNeeded: () => {
            dispatch(fetchProvinciasIfNeeded())
        },
        invalidateCiudades: () => {
            dispatch(invalidateCiudades())
        },
        fetchCiudadesIfNeeded: (idProvincia) => {
            dispatch(fetchCiudadesIfNeeded(idProvincia))
        },
        resetUpdatePedido: () => {
            dispatch(resetUpdatePedido())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetallesPedido));
