import React, {Component} from 'react';
import {Navbar, Nav, Grid, NavItem, Row, Col} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

//Components
import ProductosHome from '../elementos/productos/ProductosHome';
import ProductosDestacados from '../elementos/productos/ProductosDestacados';

//CSS
import "../../assets/css/Catalogo.css";

//Actions
import {fetchCategoriasIfNeeded} from "../../actions/CategoriaActions";
import BarraCategoriasResponsive from "../layout/BarraCategoriasResponsive";

class Catalogo extends Component {

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
    }

    render() {
        var i = 0;
        var Categorias = this.props.categorias.allIds.map((idCategoria) => {
            i = i + 1;
            if (i <= 6) {
                let categoria = this.props.categorias.byId.categorias[idCategoria];
                return (<li key={idCategoria} className="catBarraCentro">
                    <NavLink
                        to={"/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase()}>{categoria.desCategoria.toUpperCase()}</NavLink>
                </li>);
            }
        });
        return (
            <section className="catalogo">
                <div className="barraCatalogo">
                    <Row>
                        <Col md={6} className="center-block titulo">
                            <h3>CATEGORÍAS PRODUCTOS</h3>
                        </Col>
                    </Row>
                    <Row>
                        <div className="centerNav">
                            <Navbar>
                                <Navbar.Header>
                                    <Navbar.Toggle/>
                                </Navbar.Header>
                                <Navbar.Collapse>
                                    <Nav>
                                        {Categorias}
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </Row>
                </div>
                <BarraCategoriasResponsive/>
                <Row className="bg-productosHome">
                    <ProductosHome/>
                </Row>
                <Row>
                    <ProductosDestacados/>
                </Row>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        categorias: state.categorias
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Catalogo);
