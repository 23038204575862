import c from '../constants/constants';
import $ from 'jquery';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var pagos = {

    getAll(filtros) {
        var esc = encodeURIComponent;
        var query = "";
        if (filtros)
            query = Object.keys(filtros)
                .map(k => esc(k) + '=' + esc(filtros[k]))
                .join('&');

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/pagos/?' + query, defaultOptions);
    },

    getOne(idPago) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL + '/pagos/' + idPago, defaultOptions);
    },
    saveUpdate(pago, files, fileLogo) {

        var formData = new FormData();
        if (files) {
            files.forEach((file) => {
                formData.append(file.name, file);
            });
        }
        formData.append("pago", JSON.stringify(pago));
        formData.append("logo", fileLogo);
        return $.ajax({
            url: c.BASE_URL + '/pagos/' + pago.id,
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', "Bearer " + localStorage.token);
            },
            contentType: false,
            type: 'POST',
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    saveCreate(pago, files, fileLogo) {

        var formData = new FormData();
        if (files) {
            files.forEach((file) => {
                formData.append(file.name, file);
            });
        }
        formData.append("pago", JSON.stringify(pago));
        formData.append("logo", fileLogo);
        return $.ajax({
            url: c.BASE_URL + '/pagos/',
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('Authorization', "Bearer " + localStorage.token);
            },
            contentType: false,
            type: 'POST',
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    getComprobante(idComprobante) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            // dataType: 'image/png',
        };
        return fetch(c.BASE_URL + '/comprobantes/' + idComprobante, defaultOptions);
    },
    getLogoPago(idPago) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            // dataType: 'image/png',
        };
        return fetch(c.BASE_URL + '/pagos/logo/' + idPago, defaultOptions);
    },

};

export default pagos;