import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

//api
import ganadores from "../api/ganadores";

//GANADORES
export const RESET_GANADORES = 'RESET_GANADORES';
export const REQUEST_GANADORES = 'REQUEST_GANADORES';
export const RECEIVE_GANADORES = 'RECEIVE_GANADORES';
export const INVALIDATE_GANADORES = 'INVALIDATE_GANADORES';
export const ERROR_GANADORES= "ERROR_GANADORES";


export function invalidateGanadores(ganador) {
    return {
        type: INVALIDATE_GANADORES,
        ganador
    }
}

export function resetGanadores() {
    return {
        type: RESET_GANADORES
    }
}

function requestGanadores() {
    return {
        type: REQUEST_GANADORES,
    }
}

function receiveGanadores(json) {
    return {
        type: RECEIVE_GANADORES,
        ganadores: json,
        receivedAt: Date.now()
    }
}

function errorGanadores(error) {
    return {
        type: ERROR_GANADORES,
        error: error,
    }
}

function fetchGanadores(idEvento) {
    return dispatch => {
        dispatch(requestGanadores());
        return ganadores.getGanadores(idEvento)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveGanadores(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorGanadores(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorGanadores(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchGanadores(state) {
    const ganadores = state.ganadores.byId;
    console.log('should', ganadores.byId, !ganadores.byId);
    if (!ganadores) {
        return true
    } else if (ganadores.isFetching) {
        return false
    } else {
        return ganadores.didInvalidate;
    }
}

export function fetchGanadoresIfNeeded(idEvento) {
    return (dispatch, getState) => {
        if (shouldFetchGanadores(getState())) {
            return dispatch(fetchGanadores(idEvento))
        }
    }
}