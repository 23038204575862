import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Sound from 'react-sound';

import {fetchGanadoresIfNeeded, invalidateGanadores, resetGanadores} from "../../../actions/GanadoresActions";
import {fetchParticipantesIfNeeded} from "../../../actions/ParticipantesActions";

import TextLoop from 'react-text-loop';

// import premio1 from '../../../assets/img/evento/sorteo/1.png'
// import premio2 from '../../../assets/img/evento/sorteo/2.png'
// import premio3 from '../../../assets/img/evento/sorteo/3.png'

import premio1 from '../../../assets/img/evento/eventoRoad/camara1.png';
import premio2 from '../../../assets/img/evento/eventoRoad/camara2.png';
import premio3 from '../../../assets/img/evento/eventoRoad/mochila.png';

import sorteoSound from '../../../assets/sound/sorteoSound.mp3';
import winSound from '../../../assets/sound/winSound.mp3';
import sieraSoud from '../../../assets/sound/sieraSound.mp3';
import history from "../../../history";


class TablaSorteo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            win1: false,
            win2: false,
            win3: false,
            win4: false,
            win5: false,
            soundPlay: Sound.status.STOPPED,
            soundWin: Sound.status.STOPPED,
            soundSiera: Sound.status.STOPPED,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.invalidateGanadores();
        this.props.resetGanadores();
        this.props.fetchGanadoresIfNeeded(this.props.match.params['evento']);
        this.props.fetchParticipantesIfNeeded();
        this.startSorteo();


    }

    startSorteo(){
        console.log("me monte");
        console.log("me monte");
        console.log("me monte");
        if (this.props.ganadores.byId.isFetching){
            this.startSorteo();
        } {
            this.setState({soundPlay: Sound.status.PLAYING});
            setTimeout(() => {
                this.setState({soundWin:Sound.status.PLAYING, win3:true});
            }, 3000);
            setTimeout(() => {
                this.setState({soundWin:Sound.status.PLAYING, win2: true});
            }, 6000);
            setTimeout(() => {
                this.setState({soundPlay:Sound.status.STOPPED, soundWin:Sound.status.PLAYING, win1: true});
            }, 9000);
            setTimeout(() => {
                this.setState({soundPlay:Sound.status.STOPPED, soundWin:Sound.status.STOPPED,
                    soundSiera: Sound.status.PLAYING});
            }, 12000);
            // setTimeout(()=>{
            //     history.push("/sorteo/ok/saludos");
            // }, 30000);
        }

    }

    render() {
        let ganador = [];
        let datosGanadores = this.props.ganadores.allIds.map(
            (key) => {
                return this.props.ganadores.byId.datosGanadores[key];
            }
        );
        let participante = [];
        let participantes = this.props.participantes.allIds.map(
            (key) => {
                participante = this.props.participantes.byId.participantes[key]
                return (
                    <p className="nombre-participante">{participante.nombre + " " + participante.apellido}</p>
                )
            }
        );
        return (
            <Row>
                <Sound
                    url={sorteoSound}
                    playStatus={this.state.soundPlay}
                    playFromPosition={0 /* in milliseconds */}
                />
                <Sound
                    url={winSound}
                    playStatus={this.state.soundWin}
                    playFromPosition={0 /* in milliseconds */}
                />
                <Sound
                    url={sieraSoud}
                    playStatus={this.state.soundSiera}
                    playFromPosition={0 /* in milliseconds */}
                />
                <Col md={12} className="center-block tabla">
                    <Row className="border">
                        <Col md={5} className="sin-padding">
                            <h3>Premios</h3>
                        </Col>
                        <Col md={7} className="sin-padding">
                            <h3>Ganadores</h3>
                        </Col>
                    </Row>
                    <Row className="border">
                        <Col md={1} className="numero">
                            1
                        </Col>
                        <Col md={4} className="container-premio">
                            <div className="img-container">
                                <p>Camara Bullet Siera AHD LGT 015HD PAL</p>
                                <img className="premio1" src={premio1} alt=""/>
                            </div>
                        </Col>
                        <Col md={7} className="winner-field">
                            {this.state.win1 ? <p className="nombre-participante">{datosGanadores[0] ?
                                datosGanadores[0].username + " " + datosGanadores[0].last_name : ""}</p> :
                                <TextLoop speed={80} adjustingSpeed={50}>
                                    <br/>
                                    {participantes}
                                </TextLoop>
                            }
                        </Col>
                    </Row>
                    <Row className="border">
                        <Col md={1} className="numero">
                            2
                        </Col>
                        <Col md={4} className="container-premio">
                            <div className="img-container">
                                <p>Cámara Domo Siera LGT 009/HD/28 4 en 1 HD 720p</p>
                                <img className="premio2" src={premio2} alt=""/>
                            </div>
                        </Col>
                        <Col md={7} className="winner-field">
                            {this.state.win2 ? <p className="nombre-participante">{datosGanadores[1] ?
                                datosGanadores[1].username + " " + datosGanadores[1].last_name : ""}</p> :
                                <TextLoop speed={80} adjustingSpeed={50}>
                                    <br/>
                                    {participantes}
                                </TextLoop>
                            }
                        </Col>
                    </Row>
                    <Row className="border">
                        <Col md={1} className="numero" style={{borderBottom: "none"}}>
                            3
                        </Col>
                        <Col md={4} className="container-premio">
                            <div className="img-container">
                                <p>Mochila Siera</p>
                                <img className="premio3" src={premio3} alt=""/>
                            </div>
                        </Col>
                        <Col md={7} className="winner-field">
                            {this.state.win3 ? <p className="nombre-participante">{datosGanadores[2] ?
                                datosGanadores[2].username + " " + datosGanadores[2].last_name : ""}</p> :
                                <TextLoop speed={80} adjustingSpeed={50}>
                                    <br/>
                                    {participantes}
                                </TextLoop>
                            }
                        </Col>
                    </Row>

                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {
        ganadores: state.ganadores,
        participantes: state.participantes
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchParticipantesIfNeeded: () => {
            dispatch(fetchParticipantesIfNeeded());
        },
        fetchGanadoresIfNeeded: (idEvento) => {
            dispatch(fetchGanadoresIfNeeded(idEvento));
        },
        invalidateGanadores: () => {
            dispatch(invalidateGanadores())
        },
        resetGanadores: () => {
            dispatch(resetGanadores())
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TablaSorteo));
