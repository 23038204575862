import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link, NavLink, withRouter} from "react-router-dom"

import '../../assets/css/Header.css';

import logo from '../../assets/img/kitLogo.png';
import down from '../../assets/img/down.png';
import home from '../../assets/img/home.png';
import tool from '../../assets/img/tool.png';
import mail from '../../assets/img/mail.png';
import {
    fetchPedidosIfNeeded,
    iniciarPedido,
    invalidatePedidos,
    resetPedidos,
    updatePedido
} from "../../actions/PedidoActions";
import {connect} from "react-redux";
import {fetchFormasEntregaIfNeeded} from "../../actions/FormaEntregaActions";



class Header extends Component {
    render() {
        return (
            <div className="container-fluid header">
                <div className="container-limit">
                    <Row>
                        <Col xs={1} sm={1} style={{paddingLeft:"0px"}}>
                            <img className="logo" src={logo} alt=""/>
                        </Col>
                        <Col xs={11} className="hidden-xs">
                            <ul>
                                {/*<li><span><img src={down} alt=""/></span>Español</li>*/}
                                <li><NavLink to="/institucional" activeClassName="active"><span><img className="img-institucional" alt=""/></span>Institucional</NavLink></li>
                                <li><NavLink to="/login" activeClassName="active"><span><img className="img-login" alt=""/></span>{this.props.authentication.token == true ? "Tu Kit" : "LogIn"}</NavLink></li>
                                <li><NavLink to="/contacto"><span><img src={mail} alt=""/></span>Contacto</NavLink></li>
                            </ul>
                        </Col>
                        <Col xs={11} sm={11} className="visible-xs icon-responsive">
                            <ul>
                                {/*<li><span><img src={down} alt=""/></span></li>*/}
                                <li><NavLink to="/institucional" activeClassName="active"><span><img className="img-institucional" alt=""/></span></NavLink></li>
                                <li><NavLink to="/login" activeClassName="active"><span><img className="img-login" alt=""/></span></NavLink></li>
                                <li><NavLink to="/contacto"><span><img src={mail} alt=""/></span></NavLink></li>

                                {/*<li><span><img src={home} alt=""/></span></li>*/}
                                {/*<li><span><img src={tool} alt=""/></span></li>*/}
                                {/*<li><span><img src={mail} alt=""/></span></li>*/}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
