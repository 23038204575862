import React, {Component} from 'react';
import {Navbar, Nav, Grid, NavItem, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';

//Lodash
import sampleSize from "lodash/sampleSize";
import pickBy from "lodash/pickBy";

//Css
import "../../../assets/css/ProductosDestacados.css";

//Components
import ProductoDestacado from "../producto/Producto";

//Images
import entrega from "../../../assets/img/entregasPais.png";

//Actions
import {fetchCategoriasIfNeeded} from "../../../actions/CategoriaActions";

import loadImg from '../../../assets/img/loader.gif';


class ProductosDestacados extends Component {

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
    }


    render() {
        var seleccionDestacados = pickBy(this.props.productos.byId.productos, function (producto, key) {
            return producto.destacado == 1;
        });

        var seleccionRandom = sampleSize(seleccionDestacados, 8);

        var Productos = seleccionRandom.map((producto) => {
            return <ProductoDestacado key={producto.id + "-" + producto.vd_idProducto} col={3} producto={producto}/>
        });
        var Loader = () => {
            return (
                <div style={{textAlign: "center", float: "none", margin: "0 auto"}}><img style={{width: "32px"}}
                                                                                         src={loadImg} alt=""/></div>
            );
        }
        return (
            <section className="container-limit productos-destacados">
                <Row>
                    <h2 className="titulo">ÚLTIMOS PRODUCTOS | DESTACADOS</h2>
                </Row>
                <Row>
                    {!this.props.categorias.byId.isFetching ? <div className="grid">{Productos}</div> : Loader()}
                </Row>
                <Row>
                    <img className="img-responsive" src={entrega} alt=""/>
                </Row>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductosDestacados);