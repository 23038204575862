import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

//CSS
import "../../assets/css/Register.css";


import Footer2 from '../layout/Footer2';

//Actions
import {changeUsuarioLogueado, errorCreateUsuarioLogueado} from "../../actions/UsuarioActions";

//Constants
import * as roles from "../../constants/Roles";

//Images
import logoCorporativo from "../../assets/img/corporativo.png";
import logoInstalador from "../../assets/img/instaladorIntegradorDistribuidor.png";
import logoConsumidorFinal from "../../assets/img/consumidorFinal.png";

class Register extends Component {


    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onChangeRol(e, idRol) {
        var cambio = {};
        cambio.idRol = idRol;
        this.props.changeUsuarioLogueado(cambio);
    }

    continuar() {
        if (!this.props.usuarios.byId.usuarioLogueado.idRol) {
            this.props.errorCreate("Debe seleccionar su tipo de usuario");
        }
        else
            this.props.history.push("/encuesta");
    }

    render() {
        const {usuarioLogueado}=this.props.usuarios.byId;
        return (
            <div>
                <section className="register-portada">
                    <div className="container-limit">
                        <Row>
                            <Col md={9} className="recuadro">
                                <h1>
                                    Nuevo Usuario
                                </h1>
                                <p>
                                    Seleccioná que <b>tipo de usuario</b> sos, para poder responder mejor a tus
                                    necesidades.
                                </p>
                                <small>Para brindar un mejor servicio, todos los nuevos registros son verificados antes
                                    de convertirse en usuarios. No olvides que
                                    una vez elegido el perfil, no será posible cambiarlo.
                                </small>
                                <div className="separador-verde">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="register-tipoUsuario">
                    <Row>
                        <Col md={10} mdOffset={1} off className="recuadro">
                            <Row className="is-flex">
                                <Col md={4}>
                                    <div>
                                        <div
                                            className={usuarioLogueado.idRol == roles.CONSUMIDOR_FINAL_ID ? "tipoUsuario seleccionado" : "tipoUsuario"}>
                                            <div className="nombre">
                                                <img src={logoConsumidorFinal}/>
                                                <h2 className="textoConsumidorFinal">Consumidor <br/> Final</h2>
                                            </div>
                                            <div className="descripcion">
                                                <ul>
                                                    <li>Obtenés asesoramiento en tus compras.</li>
                                                    <li>Contás con servicio post-venta.</li>
                                                    <li>Tus compras son controladas antes de la entrega.</li>
                                                    <li>Contás con financiación directa, sin intermediarios.</li>

                                                </ul>
                                            </div>
                                            <div className="soyYo"
                                                 onClick={(e) => this.onChangeRol(e, roles.CONSUMIDOR_FINAL_ID)}>
                                                <h2 style={{display: usuarioLogueado.idRol == roles.CONSUMIDOR_FINAL_ID ? "none" : "block"}}>
                                                    Soy yo</h2>
                                                <span style={{
                                                    fontSize: "59px",
                                                    display: usuarioLogueado.idRol == roles.CONSUMIDOR_FINAL_ID ? "block" : "none"
                                                }} className="glyphicon glyphicon-ok"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <div
                                            className={usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID ? "tipoUsuario seleccionado" : "tipoUsuario"}>
                                            <div className="nombre">
                                                <img src={logoInstalador}/>
                                                <h2 className="textoInstalador">Instalador, <br/> Integrador <br/> o Distribuidor</h2>
                                            </div>
                                            <div className="descripcion">
                                                <ul>
                                                    <li>Sin cargos de restocking ante devolución de mercadería.</li>
                                                    <li>Obtené el diagnóstico de tus reparaciones o garantías (RMA) en
                                                        48
                                                        hs.
                                                    </li>
                                                    <li>Contás con seguimiento de tus proyectos sin cargo.</li>
                                                    <li>Accedés a capacitaciones gratuitas, para vos y tus clientes.
                                                    </li>
                                                    <li>Facturación por orden y cuenta con fondo de factura a tu medida.
                                                    </li>
                                                    <li>Seguimiento técnico-comercial de tus proyectos, desde que
                                                        cotizás
                                                        hasta que lo finalizás.
                                                    </li>
                                                    <li>Accedés a integrar el sistema de Compra Profesional (Previa
                                                        Validación).
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="soyYo"
                                                 onClick={(e) => this.onChangeRol(e, roles.INSTALADOR_INTEGRADOR_ID)}>
                                                <h2 style={{display: usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID ? "none" : "block"}}>
                                                    Soy yo</h2>
                                                <span style={{
                                                    fontSize: "59px",
                                                    display: usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID ? "block" : "none"
                                                }} className="glyphicon glyphicon-ok"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <div
                                            className={usuarioLogueado.idRol == roles.CORPORATIVO_ID ? "tipoUsuario seleccionado" : "tipoUsuario"}>
                                            <div className="nombre">
                                                <img src={logoCorporativo}/>
                                                <h2 className="textoCorporativo">Corporativo</h2>
                                            </div>
                                            <div className="descripcion">
                                                <ul>
                                                    <li>Accedés al servicio de consultoría sin cargo.</li>
                                                    <li>Contás con soporte en integración y planificación de tecnología.
                                                    </li>
                                                    <li>Obtenés background en proyectos de toda índole, gracias a
                                                        nuestro
                                                        know
                                                        how.
                                                    </li>
                                                    <li>Precios especiales para grandes proyectos.</li>
                                                    <li>Acceso al catálogo de productos de equipos de alta tecnología.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="soyYo"
                                                 onClick={(e) => this.onChangeRol(e, roles.CORPORATIVO_ID)}>
                                                <h2 style={{display: usuarioLogueado.idRol == roles.CORPORATIVO_ID ? "none" : "block"}}>
                                                    Soy yo</h2>
                                                <span style={{
                                                    fontSize: "59px",
                                                    display: usuarioLogueado.idRol == roles.CORPORATIVO_ID ? "block" : "none"
                                                }} className="glyphicon glyphicon-ok"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="botoneraRegistrarse">
                                    <p style={{color: "red"}}>{this.props.usuarios.create.error}</p>
                                    <Button className="registrate" onClick={() => this.continuar()}>Registrarse</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </section>

                <Footer2/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario))
        },
        errorCreate: (error) => {
            dispatch(errorCreateUsuarioLogueado(error))
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Register));
