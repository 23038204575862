import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import {Panel, PanelGroup, Row} from "react-bootstrap";

//CSS
import "../../assets/css/BarraCategoriaResponsive.css";

//Images
import lista from "../../assets/img/list.png";
import rightArrow from "../../assets/img/right-arrow.png";
import Scrollchor from "react-scrollchor";

class BarraCategoriasResponsive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openBarra: false,
            activeKey: 0,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    onToggle() {
        this.setState({openBarra: !this.state.openBarra});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.openBarra) {
                this.onToggle();
            }
        }
    }

    changePanelActivoCategoria(activeKey) {
        this.setState({
            activeKey: activeKey,
        })
    }

    render() {

        var categoriaSeleccionada = null;
        var subCategoriaSeleccionada = null;
        var Categorias = this.props.categorias.allIds.map((idCategoria) => {
            var categoria = this.props.categorias.byId.categorias[idCategoria];

            //Seleccion de categoria
            if (this.props.match.params["desCategoria"] == categoria.desCategoria.replace(/\s/g, "").toLowerCase())
                categoriaSeleccionada = categoria;

            //Seleccion de subcategoria
            if (this.props.match.params["desSubCategoria"] == "kits")
                subCategoriaSeleccionada = "kits";
            else if (this.props.match.params["desSubCategoria"] == "otros")
                subCategoriaSeleccionada = "otros";

            //Subcategorias
            var subCategorias = [];
            categoria.sub_categoria.map((idSubCategoria) => {
                var subCategoria = this.props.subCategorias.byId.subCategorias[idSubCategoria];
                if (this.props.match.params["desSubCategoria"] == subCategoria.desSubCategoria.replace(/\s/g, "").toLowerCase()
                    && this.props.categorias.byId.categorias[subCategoria.idCategoria].desCategoria.replace(/\s/g, "").toLowerCase() == this.props.match.params["desCategoria"])
                    subCategoriaSeleccionada = subCategoria;
                if (subCategoria) {
                    subCategorias.push(
                        <Scrollchor animate={{duration: 500, offset: -80}}
                                    disableHistory={true}
                                    to="#productos">
                            <li key={"li_subCat_" + subCategoria.id} className="subCategoria">
                                <NavLink
                                    key={"navLink_subCat_" + subCategoria.id}
                                    onClick={() => this.onToggle()}
                                    to={"/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase() + "/" + subCategoria.desSubCategoria.replace(/\s/g, "").toLowerCase()}
                                    activeClassName="active" exact>
                                    {subCategoria.desSubCategoria}
                                </NavLink>
                            </li>
                        </Scrollchor>);
                }
            });
            //Categorias
            return (
                <Panel eventKey={categoria.id} id={categoria.id} className="panelCategoria">
                    <Panel.Heading>
                        <Panel.Toggle toggle>
                            <Scrollchor animate={{duration: 500, offset: -80}}
                                        disableHistory={true}
                                        to="#productos">
                                <NavLink
                                    onClick={() => this.onToggle()}
                                    to={"/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase()}
                                    activeClassName="active" key={"navLink_categoria_" + categoria.id}>
                                    {categoria.desCategoria.toUpperCase()}
                                </NavLink>
                            </Scrollchor>
                        </Panel.Toggle>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body style={{display: categoria.sub_categoria.length == 0 ? "none" : "block"}}>
                            {subCategorias}
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            );
        });

        //Marcas
        var marcaSeleccionada = null;
        var Marcas = this.props.marcas.allIds.map((idMarca) => {
            let marca = this.props.marcas.byId.marcas[idMarca];
            if (marca) {
                if (this.props.match.params["desMarca"] == marca.desMarca.replace(/\s/g, "").toLowerCase())
                    marcaSeleccionada = marca;
                return (
                    <Scrollchor animate={{duration: 500}}
                                disableHistory={true}
                                to="#productos">
                        <li className="marcas" key={idMarca}>
                            <NavLink activeClassName="active" key={"navLink_" + idMarca}
                                     to={"/marcas/" + marca.desMarca.replace(/\s/g, "").toLowerCase()}>{marca.desMarca}
                            </NavLink>
                        </li>
                    </Scrollchor>
                );
            }
        });

        return (
            <div className="barraCategorias" ref={this.setWrapperRef}>
                <div style={{display: this.state.openBarra ? "none" : "flex"}} className="botonCategoria"
                     onClick={() => {
                         this.onToggle()
                     }}>
                    <p>Categorias</p>
                    <img src={lista} alt="lista"/>
                </div>
                <div style={{display: this.state.openBarra ? "block" : "none"}} className="menuCategorias">
                    <div className="tituloCategorias">
                        <p>Categorias</p>
                        <img src={rightArrow} alt="flechaDerecha" onClick={() => this.onToggle()}/>
                    </div>
                    <div>
                        <PanelGroup
                            accordion
                            id="accordion-controlled-example"
                            activeKey={this.state.activeKey}
                            onSelect={(e) => this.changePanelActivoCategoria(e)}
                        >
                            {Categorias}
                            <Panel eventKey="marcas" className="panelCategoria">
                                <Panel.Heading>
                                    <Panel.Toggle toggle>
                                        MARCAS
                                    </Panel.Toggle>
                                </Panel.Heading>
                                <Panel.Collapse>
                                    <Panel.Body>
                                        {Marcas}
                                    </Panel.Body>
                                </Panel.Collapse>
                            </Panel>
                        </PanelGroup>
                    </div>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        categorias: state.categorias,
        subCategorias: state.subCategorias,
        marcas: state.marcas,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BarraCategoriasResponsive));
