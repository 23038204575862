import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

//api
import participantes from "../api/participantes";

//PARTICIPANTES
export const REQUEST_PARTICIPANTES = 'REQUEST_PARTICIPANTES';
export const RECEIVE_PARTICIPANTES = 'RECEIVE_PARTICIPANTES';
export const INVALIDATE_PARTICIPANTES = 'INVALIDATE_PARTICIPANTES';
export const ERROR_PARTICIPANTES= "ERROR_PARTICIPANTES";


export function invalidateParticipantes(participante) {
    return {
        type: INVALIDATE_PARTICIPANTES,
        participante
    }
}

function requestParticipantes() {
    return {
        type: REQUEST_PARTICIPANTES,
    }
}

function receiveParticipantes(json) {
    return {
        type: RECEIVE_PARTICIPANTES,
        participantes: json,
        receivedAt: Date.now()
    }
}

function errorParticipantes(error) {
    return {
        type: ERROR_PARTICIPANTES,
        error: error,
    }
}

function fetchParticipantes() {
    return dispatch => {
        dispatch(requestParticipantes());
        return participantes.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveParticipantes(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorParticipantes(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorParticipantes(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchParticipantes(state) {
    const participantes = state.participantes.byId;
    if (!participantes) {
        return true
    } else if (participantes.isFetching) {
        return false
    } else {
        return participantes.didInvalidate;
    }
}

export function fetchParticipantesIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchParticipantes(getState())) {
            return dispatch(fetchParticipantes())
        }
    }
}