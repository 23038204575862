import { normalize, schema } from 'normalizr';

export function normalizeDatos(myData){

    const pais = new schema.Entity('paises',{}, {idAttribute:"id"});
    const mySchema = [ pais ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData){

    const pais = new schema.Entity('paises',{}, {idAttribute:"id"});
    const mySchema =  pais  ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}