import { normalize, schema, denormalize } from 'normalizr';

const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});
const cliente = new schema.Entity('clientes', {}, {idAttribute: "id"});
const linkPago = new schema.Entity('linksPagos', {usuario: usuario, cliente: cliente}, {idAttribute: "id"});

export function normalizeDatos(myData) {


    const mySchema = {linksPagos: [linkPago]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {


    const mySchema = linkPago;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {


    const mySchema = linkPago;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}

export default linkPago;
