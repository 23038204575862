import { combineReducers } from 'redux'
import productos from './producto';
import categorias from './categoria';
import subCategorias from './subCategoria';
import marcas from "./marca";
import productosKits from "./productoKit";
import kits from "./kit";
import preciosPromocionales from "./precioPromocional";
import authentication from "./authentication";
import usuarios from "./usuarios";
import clientes from "./cliente";
import ciudades from "./ciudades";
import provincias from "./provincias";
import encuestas from "./encuesta";
import encuestaRespuestas from "./encuestaRespuesta";
import contacto from "./contacto";
import registros from "./registro";
import preguntas from "./pregunta";
import ganadores from "./ganador";
import participantes from "./participante";
import detallesPedidoMon from "./detallesPedidoMon";
import pedidos from "./pedidos";
import preciosProducto from "./preciosProducto";
import listaPrecio from "./listaPrecios";
import accesosCuenta from "./accesosCuenta";
import habilitaciones from "./habilitaciones";
import cuentas from "./cuentas";
import herramientas from "./herramientas";
import sorteos from "./sorteo";
import pullSdk from "./pullSdk"
import formasEntrega from './formaEntrega';
import estadosPedido from "./estadosPedido";
import estadosEntrega from "./estadosEntrega";
import remitos from "./remitos";
import estadosRemito from "./estadosRemito";
import transportes from './transportes';
import landing from './landing';
import bultos from './bultos';
import packingList from './packingList';
import detallesRemitidos from "./detallesRemitidos";
import planesCuotas from './planesCuotas';
import paises from './pais';
import mediosPago from './mediosPago';
import pagos from './pago';
import formasDePago from './formasDePago';
import tarjetasCredito from './tarjetasCredito';
import tiposTarjetaCredito from './tiposTarjetasCredito';
import estadosPago from './estadoPago';
import linksPagos from "./linksPagos";
import entidadesFinancieras from "./entidadesFinancieras";
import clientes2 from "./clientes2";

const appReducers = combineReducers({
    accesosCuenta,
    authentication,
    bultos,
    categorias,
    ciudades,
    clientes,
    clientes2,
    contacto,
    cuentas,
    detallesRemitidos,
    detallesPedidoMon,
    encuestas,
    encuestaRespuestas,
    estadosPago,
    estadosPedido,
    estadosEntrega,
    estadosRemito,
    entidadesFinancieras,
    formasDePago,
    formasEntrega,
    habilitaciones,
    herramientas,
    ganadores,
    kits,
    landing,
    linksPagos,
    listaPrecio,
    marcas,
    mediosPago,
    packingList,
    pagos,
    paises,
    participantes,
    pedidos,
    planesCuotas,
    productos,
    preciosProducto,
    preguntas,
    productosKits,
    provincias,
    pullSdk,
    registros,
    remitos,
    sorteos,
    tarjetasCredito,
    tiposTarjetaCredito,
    subCategorias,
    usuarios,
    transportes
});

export default appReducers;