import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, Redirect, Switch} from 'react-router-dom'


//CSS
import '../../../../assets/css/Cuentas.css';

//Actions
import {
    invalidateListaPrecios,
    fetchListaPreciosIfNeeded,
    resetUpdateListaPrecio
} from "../../../../actions/ListaPreciosActions";
import {resetUpdateUsuario} from "../../../../actions/UsuarioActions";

//Components
import Carrito from "./Carrito";
import Checkout from "./Checkout";
import history from "../../../../history";

class ListaPrecios extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.invalidateListaPrecios();
        this.props.fetchListaPreciosIfNeeded();
    }

    componentWillUnmount() {
        // this.props.resetUpdateListaPrecio();
        // this.props.resetUpdateUsuario();
        console.log('Unmount');
    }

    render() {
        return (
            <div>
                <div className="page-main container-fluid">
                    <Switch>
                        <Route path="/panel/listaPrecios/checkout/:paso?" exact component={Checkout}/>
                        <Route
                            path={[
                                "/panel/listaPrecios/1",
                                "/panel/listaPrecios/1/continuarPedido/:idPedido",
                                "/panel/listaPrecios/1/producto/:nomProducto",
                                "/panel/listaPrecios/1/categoria/:desCategoria/:desSubCategoria?"
                            ]}
                            exact component={Carrito}/>

                    </Switch>
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        invalidateListaPrecios: () => {
            dispatch(invalidateListaPrecios())
        },
        fetchListaPreciosIfNeeded: () => {
            dispatch(fetchListaPreciosIfNeeded())
        },
        resetUpdateListaPrecio: () => {
            dispatch(resetUpdateListaPrecio())
        },
        resetUpdateUsuario: () => {
            dispatch(resetUpdateUsuario())
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListaPrecios));
