import React from 'react';
import {
    FormGroup,
    ControlLabel,
    HelpBlock,
} from 'react-bootstrap';

import Cleave from 'cleave.js/react';
require('cleave.js/dist/addons/cleave-phone.ar');

function FieldTelefono({label, help, ...props}) {

    return (
        <FormGroup>
            <ControlLabel>{props.required ? "*"+label : label}</ControlLabel>
            <div className="required-field-block">
                <Cleave {...props}/>
            </div>
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

export default FieldTelefono;