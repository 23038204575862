import * as errorMessages from '../constants/MessageConstants';
import history from '../history';
import isEmpty from 'lodash/isEmpty';

//Actions
import {logout} from "../actions/AuthenticationActions";
import {saveUpdateEncuestaRespuestas, saveCreateEncuestaRespuestas} from "../actions/EncuestaRespuestaActions";
import {saveCreateHabilitacion} from "../actions/HabilitacionActions";
import {saveCreatePullSDKUsr} from "./sdk/pullSdk/PullSDKUsuariosActions";

//api
import usuario from "../api/usuario";

//normalizer
import {normalizeDato, normalizeDatos} from "../normalizers/normalizeUsuarios";
import {fetchLogoIfNeeded} from "./ClienteActions";

//USUARIOLOGUEADO REQUEST
export const REQUEST_USUARIO_LOGUEADO = 'REQUEST_USUARIO_LOGUEADO';
export const RECEIVE_USUARIO_LOGUEADO = 'RECEIVE_USUARIO_LOGUEADO';
export const INVALIDATE_USUARIO_LOGUEADO = 'INVALIDATE_USUARIO_LOGUEADO';
export const ERROR_USUARIO_LOGUEADO = "ERROR_USUARIO_LOGUEADO";

//USUARIOS REQUEST
export const INVALIDATE_USUARIOS = "INVALIDATE_USUARIOS";
export const REQUEST_USUARIOS = "REQUEST_USUARIOS";
export const RECEIVE_USUARIOS = "RECEIVE_USUARIOS";
export const ERROR_USUARIOS = "ERROR_USUARIOS";
export const RESET_USUARIOS = "RESET_USUARIOS";

//USUARIO REQUEST
export const REQUEST_USUARIO = 'REQUEST_USUARIO';
export const RECEIVE_USUARIO = 'RECEIVE_USUARIO';
export const INVALIDATE_USUARIO = 'INVALIDATE_USUARIO';
export const ERROR_USUARIO = "ERROR_USUARIO";

//USUARIOLOGUEADO CREATE
export const CREATE_USUARIO_LOGUEADO = 'CREATE_USUARIO_LOGUEADO';
export const REQUEST_CREATE_USUARIO_LOGUEADO = "REQUEST_CREATE_USUARIO_LOGUEADO";
export const SUCCESS_CREATE_USUARIO_LOGUEADO = "SUCCESS_CREATE_USUARIO_LOGUEADO";
export const ERROR_CREATE_USUARIO_LOGUEADO = "ERROR_CREATE_USUARIO_LOGUEADO";
export const RESET_CREATE_USUARIO_LOGUEADO = "RESET_CREATE_USUARIO_LOGUEADO";

//USUARIO CREATE
export const CREATE_USUARIO = 'CREATE_USUARIO';
export const REQUEST_CREATE_USUARIO = "REQUEST_CREATE_USUARIO";
export const SUCCESS_CREATE_USUARIO = "SUCCESS_CREATE_USUARIO";
export const ERROR_CREATE_USUARIO = "ERROR_CREATE_USUARIO";
export const RESET_CREATE_USUARIO = "RESET_CREATE_USUARIO";

//USUARIOLOGUEADO UPDATE
export const UPDATE_USUARIO_LOGUEADO = 'UPDATE_USUARIO_LOGUEADO';
export const REQUEST_UPDATE_USUARIO_LOGUEADO = "REQUEST_UPDATE_USUARIO_LOGUEADO";
export const SUCCESS_UPDATE_USUARIO_LOGUEADO = "SUCCESS_UPDATE_USUARIO_LOGUEADO";
export const ERROR_UPDATE_USUARIO_LOGUEADO = "ERROR_UPDATE_USUARIO_LOGUEADO";
export const RESET_UPDATE_USUARIO_LOGUEADO = "RESET_UPDATE_USUARIO_LOGUEADO";


//USUARIO UPDATE
export const UPDATE_USUARIO = 'UPDATE_USUARIO';
export const REQUEST_UPDATE_USUARIO = "REQUEST_UPDATE_USUARIO";
export const SUCCESS_UPDATE_USUARIO = "SUCCESS_UPDATE_USUARIO";
export const ERROR_UPDATE_USUARIO = "ERROR_UPDATE_USUARIO";
export const RESET_UPDATE_USUARIO = "RESET_UPDATE_USUARIO";

//USUARIO REQUEST POR CAMPO
export const REQUEST_USUARIO_POR_CAMPO = 'REQUEST_USUARIO_POR_CAMPO';
export const RECEIVE_USUARIO_POR_CAMPO = 'RECEIVE_USUARIO_POR_CAMPO';
export const INVALIDATE_USUARIO_POR_CAMPO = 'INVALIDATE_USUARIO_POR_CAMPO';
export const ERROR_USUARIO_POR_CAMPO = 'ERROR_USUARIO_POR_CAMPO';

//USUARIO LOGUEADO REQUEST
export function invalidateUsuarioLogueado() {
    return {
        type: INVALIDATE_USUARIO_LOGUEADO,
    }
}

function requestUsuarioLogueado() {
    return {
        type: REQUEST_USUARIO_LOGUEADO,
    }
}

function receiveUsuarioLogueado(json) {
    return {
        type: RECEIVE_USUARIO_LOGUEADO,
        usuario: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorUsuarioLogueado(error) {
    return {
        type: ERROR_USUARIO_LOGUEADO,
        error: error,
    }
}

function fetchUsuarioLogueado() {
    return dispatch => {
        dispatch(requestUsuarioLogueado());
        return usuario.getLogueado()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUsuarioLogueado(data));
                dispatch(fetchLogoIfNeeded());
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUsuarioLogueado(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorUsuarioLogueado(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchUsuarioLogueado(state) {
    const usuarios = state.usuarios.byId;
    if (usuarios.isFetchingUsuarioLogueado) {
        return false
    } else if (!usuarios.usuarioLogueado.id && state.authentication.token) {
        return true
    } else {
        return usuarios.didInvalidate;
    }
}

export function fetchUsuarioLogueadoIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchUsuarioLogueado(getState())) {
            return dispatch(fetchUsuarioLogueado())
        }
    }
}

//USUARIOS REQUEST
export function invalidateUsuarios() {
    return {
        type: INVALIDATE_USUARIOS,
    }
}

function requestUsuarios() {
    return {
        type: REQUEST_USUARIOS,
    }
}

function receiveUsuarios(json) {
    return {
        type: RECEIVE_USUARIOS,
        usuarios: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorUsuarios(error) {
    return {
        type: ERROR_USUARIOS,
        error: error,
    }
}

export function resetUsuarios() {
    return {
        type: RESET_USUARIOS,
    }
}

function fetchUsuarios(filtros) {
    return (dispatch) => {
        dispatch(requestUsuarios());
        return usuario.getUsuarios(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUsuarios(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUsuarios(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUsuarios(error.message));
                                else
                                    dispatch(errorUsuarios(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUsuarios(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

function shouldFetchUsuarios(state) {
    const usuarios = state.usuarios.byId;
    if (usuarios.isFetchingUsuarios) {
        return false
    } else if (!usuarios.usuarios) {
        return true;
    } else {
        return usuarios.didInvalidate;
    }
}

export function fetchUsuariosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchUsuarios(getState())) {
            return dispatch(fetchUsuarios(filtros))
        }
    }
}


//USUARIO REQUEST
export function invalidateUsuario() {
    return {
        type: INVALIDATE_USUARIO,
    }
}

function requestUsuario() {
    return {
        type: REQUEST_USUARIO,
    }
}

function receiveUsuario(json) {
    return {
        type: RECEIVE_USUARIO,
        usuario: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorUsuario(error) {
    return {
        type: ERROR_USUARIO,
        error: error,
    }
}

export function fetchUsuario(dni) {
    return (dispatch) => {
        dispatch(requestUsuario());
        return usuario.getUsuario(dni)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUsuario(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUsuario(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUsuario(error.message));
                                else
                                    dispatch(errorUsuario(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUsuario(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//todo borrar cuando borremos lo de los eventos.
function shouldFetchUsuario(state) {
    const usuarios = state.usuarios.byId;
    if (!usuarios.usuarioLogueado.dni) {
        return true
    } else if (usuarios.isFetchingUsuarioLogueado) {
        return false
    } else {
        return usuarios.didInvalidate;
    }
}

export function fetchUsuarioIfNeeded(dni) {
    return (dispatch, getState) => {
        if (shouldFetchUsuario(getState())) {
            return dispatch(fetchUsuario(dni))
        }
    }
}

//USUARIOLOGUEADO CREATE
function requestCreateUsuarioLogueado() {
    return {
        type: REQUEST_CREATE_USUARIO_LOGUEADO,
    }
}

function receiveCreateUsuarioLogueado() {
    return {
        type: SUCCESS_CREATE_USUARIO_LOGUEADO,
        receivedAt: Date.now()
    }
}

export function errorCreateUsuarioLogueado(error) {
    return {
        type: ERROR_CREATE_USUARIO_LOGUEADO,
        error: error,
    }
}

export function resetCreateUsuarioLogueado(error) {
    return {
        type: RESET_CREATE_USUARIO_LOGUEADO
    }
}

export function createUsuarioLogueado(usuario) {
    return {
        type: CREATE_USUARIO_LOGUEADO,
        usuario
    }
}

function saveCreateUsuarioLogueado() {
    return (dispatch, getState) => {
        dispatch(requestCreateUsuarioLogueado());
        return usuario.saveCreate(getState().usuarios.byId.usuarioLogueado)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateUsuarioLogueado());
                    return response.json();
                }
            })
            .then((usuario) => {
                dispatch(saveCreateEncuestaRespuestas(usuario.id));
                dispatch(resetCreateUsuarioLogueado());
                history.push("login");
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateUsuarioLogueado(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorCreateUsuarioLogueado(error.message));
                                else
                                    dispatch(errorCreateUsuarioLogueado(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorCreateUsuarioLogueado(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}


//USUARIO CREATE
function requestCreateUsuario() {
    return {
        type: REQUEST_CREATE_USUARIO,
    }
}

function receiveCreateUsuario() {
    return {
        type: SUCCESS_CREATE_USUARIO,
        receivedAt: Date.now()
    }
}

export function errorCreateUsuario(error) {
    return {
        type: ERROR_CREATE_USUARIO,
        error: error,
    }
}

export function resetCreateUsuario(error) {
    return {
        type: RESET_CREATE_USUARIO
    }
}

export function createUsuario(usuario) {
    return {
        type: CREATE_USUARIO,
        usuario
    }
}

function saveCreateUsuario() {
    return (dispatch, getState) => {
        dispatch(requestCreateUsuario());
        return usuario.saveCreate(getState().usuarios.create.nuevo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateUsuario());
                    return response.json();
                }
            })
            .then((usuario) => {
                dispatch(resetCreateUsuario());
                return dispatch(fetchUsuario(usuario.dni));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateUsuario(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return Promise.reject(error);
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorCreateUsuario(error.message));
                                else
                                    dispatch(errorCreateUsuario(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorCreateUsuario(errorMessages.GENERAL_ERROR));
                            });
                        return Promise.reject(error);
                }
            });
    }
}


//USUARIOLOGUEADO UPDATE
function requestUpdateUsuarioLogueado() {
    return {
        type: REQUEST_UPDATE_USUARIO_LOGUEADO,
    }
}

function receiveUpdateUsuarioLogueado() {
    return {
        type: SUCCESS_UPDATE_USUARIO_LOGUEADO,
        receivedAt: Date.now()
    }
}

function errorUpdateUsuarioLogueado(error) {
    return {
        type: ERROR_UPDATE_USUARIO_LOGUEADO,
        error: error,
    }
}

export function resetUpdateUsuarioLogueado() {
    //todo buscar usuario
    return {
        type: RESET_UPDATE_USUARIO_LOGUEADO
    }
}

export function updateUsuarioLogueado(usuario) {
    return {
        type: UPDATE_USUARIO_LOGUEADO,
        usuario
    }
}

function saveUpdateUsuarioLogueado() {
    return (dispatch, getState) => {
        dispatch(requestUpdateUsuarioLogueado());

        return usuario.saveUpdate(getState().usuarios.byId.usuarioLogueado)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateUsuarioLogueado());
                    return response.json();
                }
            })
            .then((usuario) => {
                dispatch(saveUpdateEncuestaRespuestas(usuario.id));
                history.push("/login");
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateUsuarioLogueado(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUpdateUsuarioLogueado(error.message));
                                else
                                    dispatch(errorUpdateUsuarioLogueado(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUpdateUsuarioLogueado(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}


//USUARIO UPDATE
function requestUpdateUsuario() {
    return {
        type: REQUEST_UPDATE_USUARIO,
    }
}

function receiveUpdateUsuario() {
    return {
        type: SUCCESS_UPDATE_USUARIO,
        receivedAt: Date.now()
    }
}

function errorUpdateUsuario(error) {
    return {
        type: ERROR_UPDATE_USUARIO,
        error: error,
    }
}

export function resetUpdateUsuario() {
    return {
        type: RESET_UPDATE_USUARIO
    }
}

export function updateUsuario(usuario) {
    return {
        type: UPDATE_USUARIO,
        usuario
    }
}

function saveUpdateUsuario() {
    return (dispatch, getState) => {
        dispatch(requestUpdateUsuario());

        return usuario.saveUpdate(getState().usuarios.update.activo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateUsuario());
                    return response.json();
                }
            })
            .then((usuario) => {

            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateUsuario(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return Promise.reject(error);
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUpdateUsuario(error.message));
                                else
                                    dispatch(errorUpdateUsuario(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUpdateUsuario(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}


export function changeUsuarioLogueado(usuario) {
    return (dispatch, getState) => {
        //Decidir si actualizar o modificar
        if (!getState().usuarios.byId.usuarioLogueado.id)
            dispatch(createUsuarioLogueado(usuario));
        else
            dispatch(updateUsuarioLogueado(usuario));
    }
}

export function saveUsuarioLogueado() {
    return (dispatch, getState) => {
        if (!getState().usuarios.byId.usuarioLogueado.id)
            dispatch(saveCreateUsuarioLogueado());
        else
            dispatch(saveUpdateUsuarioLogueado())

    }
}

function saveUsuario() {
    return (dispatch, getState) => {
        return (!isEmpty(getState().usuarios.create.nuevo) ?
            dispatch(saveCreateUsuario()) : dispatch(saveUpdateUsuario()))
    }

}

export function saveUsuarioAndHabilitaciones(idCuenta, idHerramienta, codAcceso) {
    return (dispatch, getState) => {
        dispatch(saveUsuario())
            .then(() => {
                let idUsuario = getState().usuarios.update.activo ? getState().usuarios.update.activo.id : "";
                return dispatch(saveCreateHabilitacion(idCuenta, idHerramienta, idUsuario, codAcceso))
            })
            .then(() => {
                //buscar del state el idHabilitacion
                var idHabilitacionSDK = null;
                getState().accesosCuenta.allIds.map((idAccesoCuenta) => {
                    var accesoCuenta = getState().accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                    if (accesoCuenta && getState().usuarios.update.activo.id && accesoCuenta.idUsuario == getState().usuarios.update.activo.id) {
                        getState().habilitaciones.allIds.map((idHabilitacion) => {
                            var habilitacion = getState().habilitaciones.byId.habilitaciones[idHabilitacion];
                            if (habilitacion.idAccesoCuenta = idAccesoCuenta) {
                                idHabilitacionSDK = idHabilitacion;
                            }
                        });
                    }

                });
                return dispatch(saveCreatePullSDKUsr(idHabilitacionSDK, codAcceso));


            });
    }
}

//REQUEST USUARIO POR CAMPO
export function invalidateUsuarioPorCampo() {
    return {
        type: INVALIDATE_USUARIO_POR_CAMPO,
    }
}

function requestUsuarioPorCampo() {
    return {
        type: REQUEST_USUARIO_POR_CAMPO,
    }
}

function receiveUsuarioPorCampo(json) {
    return {
        type: RECEIVE_USUARIO_POR_CAMPO,
        usuarioExistente: json,
        receivedAt: Date.now()
    }
}

function errorUsuarioPorCampo(error) {
    return {
        type: ERROR_USUARIO_POR_CAMPO,
        error: error,
    }
}

export function fetchUsuarioPorCampo(filtros) {
    return (dispatch) => {
        dispatch(requestUsuarioPorCampo());
        return usuario.getUsuarioPorCampo(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUsuarioPorCampo(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUsuarioPorCampo(errorMessages.GENERAL_ERROR));
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUsuarioPorCampo(error.message));
                                else
                                    dispatch(errorUsuarioPorCampo(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUsuarioPorCampo(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

function shouldFetchUsuarioPorCampo(state) {
    const usuarios = state.usuarios.byId;
    if (!usuarios.usuarioLogueado.dni) {
        return true
    } else if (usuarios.isFetchingUsuarioLogueado) {
        return false
    } else {
        return usuarios.didInvalidate;
    }
}

export function fetchUsuarioPorCampoIfNeeded(dni) {
    return (dispatch, getState) => {
        if (shouldFetchUsuarioPorCampo(getState())) {
            return dispatch(fetchUsuarioPorCampo(dni))
        }
    }
}
