import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

//api
import sorteo from "../api/sorteo";
import listaPrecios from "../api/listaPrecios";


//SORTEO CREATE
export const UPDATE_SORTEO = 'UPDATE_SORTEO';
export const REQUEST_UPDATE_SORTEO = "REQUEST_UPDATE_SORTEO";
export const SUCCESS_UPDATE_SORTEO = "SUCCESS_UPDATE_SORTEO";
export const ERROR_UPDATE_SORTEO = "ERROR_UPDATE_SORTEO";
export const RESET_UPDATE_SORTEO = "RESET_UPDATE_SORTEO";


//UPDATE
function requestUpdateSorteo() {
    return {
        type: REQUEST_UPDATE_SORTEO,
    }
}

function receiveUpdateSorteo(message) {
    return {
        type: SUCCESS_UPDATE_SORTEO,
        receivedAt: Date.now(),
        message: message
    }
}

export function errorUpdateSorteo(error) {
    return {
        type: ERROR_UPDATE_SORTEO,
        error: error,
    }
}

export function resetUpdateSorteo() {
    return {
        type: RESET_UPDATE_SORTEO
    }
}

export function updateSorteo(sorteo) {
    return {
        type: UPDATE_SORTEO,
        sorteo
    }
}

export function saveUpdateSorteo(idEvento) {
    return dispatch => {
        dispatch(requestUpdateSorteo());
        return sorteo.saveUpdate(idEvento)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    response.json().then(
                        response => {
                            dispatch(receiveUpdateSorteo(response.message));
                            return response;
                        }
                    )

                }
            })
            .then(() => {
                dispatch(resetUpdateSorteo());
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateSorteo(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error != "")
                                    dispatch(errorUpdateSorteo(error));
                                else
                                    dispatch(errorUpdateSorteo(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorUpdateSorteo(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//PARTICIPANTESEVENTO
export const REQUEST_PARTICIPANTESEVENTO = 'REQUEST_PARTICIPANTESEVENTO';
export const RECEIVE_PARTICIPANTESEVENTO = 'RECEIVE_PARTICIPANTESEVENTO';
export const INVALIDATE_PARTICIPANTESEVENTO = 'INVALIDATE_PARTICIPANTESEVENTO';
export const ERROR_PARTICIPANTESEVENTO = "ERROR_PARTICIPANTESEVENTO";


export function invalidateParticipantes(participantes) {
    return {
        type: INVALIDATE_PARTICIPANTESEVENTO,
        participantes
    }
}

function requestParticipantes() {
    return {
        type: REQUEST_PARTICIPANTESEVENTO,
    }
}

function receiveParticipantes(participantes) {
    return {
        type: RECEIVE_PARTICIPANTESEVENTO,
        receivedAt: Date.now(),
        participantes
    }
}

function errorParticipantes(error) {
    return {
        type: ERROR_PARTICIPANTESEVENTO,
        error: error,
    }
}

export function fetchParticipantes(idEvento) {
    return dispatch => {
        dispatch(requestParticipantes());
        return sorteo.getParticipantes(idEvento)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    console.log(response);
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                console.log(data);
                dispatch(receiveParticipantes(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorParticipantes(errorMessages.UNAUTHORIZED_TOKEN));
                        // dispatch(logout());
                        return;
                    default:
                        dispatch(errorParticipantes(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}



//SORTEAR
export const UPDATE_SORTEAR = 'UPDATE_SORTEAR';
export const REQUEST_UPDATE_SORTEAR = "REQUEST_UPDATE_SORTEAR";
export const SUCCESS_UPDATE_SORTEAR = "SUCCESS_UPDATE_SORTEAR";
export const ERROR_UPDATE_SORTEAR = "ERROR_UPDATE_SORTEAR";
export const RESET_UPDATE_SORTEAR = "RESET_UPDATE_SORTEAR";
function requestUpdateSortear() {
    return {
        type: REQUEST_UPDATE_SORTEAR,
    }
}

function receiveUpdateSortear(message) {
    return {
        type: SUCCESS_UPDATE_SORTEAR,
        receivedAt: Date.now(),
        message: message
    }
}

export function errorUpdateSortear(error) {
    return {
        type: ERROR_UPDATE_SORTEAR,
        error: error,
    }
}

export function resetUpdateSortear() {
    return {
        type: RESET_UPDATE_SORTEAR
    }
}

export function updateSortear(sortea) {
    return {
        type: UPDATE_SORTEAR,
        sortea
    }
}

export function saveUpdateSortear(idEvento) {
    return dispatch => {
        dispatch(requestUpdateSortear());
        return sorteo.sortear(idEvento)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    response.json().then(
                        response => {
                            dispatch(receiveUpdateSortear(response.message));
                            return response;
                        }
                    )

                }
            })
            .then(() => {
                history.push("/sorteo/startSorteo/" + idEvento);
                dispatch(resetUpdateSortear());
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateSortear(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error != "")
                                    dispatch(errorUpdateSortear(error));
                                else
                                    dispatch(errorUpdateSortear(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorUpdateSortear(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}


// function shouldFetchParticipantes(state) {
//     const sorteos = state.sorteos.byId;
//     if (!sorteos) {
//         return true
//     } else if (sorteos.isFetching) {
//         return false
//     } else {
//         return sorteos.didInvalidate;
//     }
// }

// export function fetchParticipantesIfNeeded(idEvento) {
//     return (dispatch, getState) => {
//         if (shouldFetchParticipantes(idEvento,getState())) {
//             return dispatch(fetchParticipantes(idEvento))
//         }
//     }
// }