import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import HeaderHerramienta from "../Layout/HeaderHerramienta";
import "./BotonPago.css";
//lodash
import LinkPagoCliente from "./LinkPagoCliente";
import PagoCuentaPropia from "./PagoCuentaPropia";
import ConfirmacionPago from "./ConfirmacionPago";
import {createPago, resetComprobante, resetCreatePago, resetUpdatePago} from "../../../../actions/PagoActions";
import isNil from "lodash/isNil";
import {resetMediosPago} from "../../../../actions/MediosPagoActions";
import {resetUsuarios} from "../../../../actions/UsuarioActions";
import {resetEstadosPago} from "../../../../actions/EstadosPagoActions";
import {resetPlanesCuotas} from "../../../../actions/PlanesCuotasActions";
import {resetProvincias} from "../../../../actions/ProvinciasActions";
import {resetCiudades} from "../../../../actions/CiudadActions";
import {resetFormasDePago} from "../../../../actions/FormaDePagoActions";
import {resetTarjeta} from "../../../../actions/TarjetaCreditoActions";

export default function BotonPago(props) {
    let {idPago, idTipoPago} = useParams();
    const dispatch = useDispatch();
    const mounted = useRef();
    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;
    const [tab, setTab] = useState(null);


    //Hooks
    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!idPago) {
            let cambio = {};
            cambio.idTipoPago = 1;
            dispatch(createPago(cambio));
        }
        if (!isNil(idTipoPago)) {
            setTab(parseInt(idTipoPago));
        } else {
            if (pago && pago.idTipoPago === 1)
                setTab(1);
            else if (pago && (pago.idTipoPago === 2 || pago.idTipoPago === 3))
                setTab(2);
            else
                setTab(1);
        }
        return function cleanup() {
            dispatch(resetMediosPago());
            dispatch(resetCreatePago());
            dispatch(resetUpdatePago());
            dispatch(resetUsuarios());
            dispatch(resetEstadosPago());
            dispatch(resetPlanesCuotas());
            dispatch(resetProvincias());
            dispatch(resetCiudades());
            dispatch(resetComprobante());
            dispatch(resetFormasDePago());
            dispatch(resetTarjeta());
        }
    }, []);

    //Cuando se recupera un pago derivo a la pestana que corresponde
    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!pagos.byId.isFetching && (pago.idTipoPago === 2 || pago.idTipoPago === 3) && tab === 1) {
                setTab(2);
            }
        }
    }, [pagos.byId.isFetching]);

    const handleChangeTab = (id) => {
        setTab(id);
        if (!idPago) {
            dispatch(resetCreatePago());
            let cambio = {};
            cambio.idTipoPago = id;
            dispatch(createPago(cambio));
        }

    };

    return (
        <div className="boton-pago container-fluid">
            <HeaderHerramienta titulo={"Pagos"}/>
            <div>
                <div className="container-presupuesto">
                    <div className="tags">
                        <ul>
                            {
                                (!idPago || pago.idTipoPago === 1) &&
                                <li className={tab === 1 ? 'active' : ""} onClick={(e) => {
                                    handleChangeTab(1)
                                }}>
                                    Link pago
                                </li>
                            }
                            <li className={tab === 2 ? 'active' : ""} onClick={(e) => {
                                handleChangeTab(2)
                            }}>{!idPago ? "Registrar pago" : "Pago"}
                            </li>
                            {
                                !!idPago &&
                                <li className={tab === 3 ? 'active' : ""} onClick={(e) => {
                                    handleChangeTab(3)
                                }}>
                                    Confirmación
                                </li>
                            }

                        </ul>
                    </div>
                    {
                        (tab === 1) &&
                        <LinkPagoCliente {...props}/>
                    }
                    {
                        (tab === 2) &&
                        <PagoCuentaPropia {...props}/>
                    }
                    {
                        (tab === 3) &&
                        <ConfirmacionPago {...props}/>
                    }

                </div>
            </div>
        </div>
    )
        ;
}
