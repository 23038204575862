//api
import pagos from "../api/pagos"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from "../actions/AuthenticationActions";
//normalizers
import { normalizeDato, normalizeDatos } from "../normalizers/normalizePagos";

import clone from "lodash/clone"

//PAGOS
export const REQUEST_PAGOS = 'REQUEST_PAGOS';
export const RECEIVE_PAGOS = 'RECEIVE_PAGOS';
export const INVALIDATE_PAGOS = 'INVALIDATE_PAGOS';
export const ERROR_PAGOS = "ERROR_PAGOS";
export const RESET_PAGOS = "RESET_PAGOS";


export function invalidatePagos() {
    return {
        type: INVALIDATE_PAGOS
    }
}

function requestPagos() {
    return {
        type: REQUEST_PAGOS,
    }
}

function receivePagos(json) {
    return {
        type: RECEIVE_PAGOS,
        pagos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPagos(error) {
    return {
        type: ERROR_PAGOS,
        error: error,
    }
}

export function resetPagos() {
    return {
        type: RESET_PAGOS
    }
}

export function fetchPagos(filtros) {
    return dispatch => {
        dispatch(requestPagos());
        return pagos.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePagos(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPagos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPagos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPagos(state) {
    const pagos = state.pagos.byId;
    if (!pagos) {
        return true
    } else if (pagos.isFetching) {
        return false
    } else {
        return pagos.didInvalidate;
    }
}

export function fetchPagosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPagos(getState())) {
            return dispatch(fetchPagos(filtros))
        }
    }
}


//MODEL
export const REQUEST_PAGO = 'REQUEST_PAGO';
export const RECEIVE_PAGO = 'RECEIVE_PAGO';
export const INVALIDATE_PAGO = 'INVALIDATE_PAGO';
export const ERROR_PAGO = "ERROR_PAGO";
export const RESET_PAGO = "RESET_PAGO";


export function invalidatePago() {
    return {
        type: INVALIDATE_PAGO
    }
}

function requestPago() {
    return {
        type: REQUEST_PAGO,
    }
}

function receivePago(json) {
    return {
        type: RECEIVE_PAGO,
        pago: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPago(error) {
    return {
        type: ERROR_PAGO,
        error: error,
    }
}

export function resetPago() {
    return {
        type: RESET_PAGO
    }
}

export function fetchPago(idPago) {
    return dispatch => {
        dispatch(requestPago());
        return pagos.getOne(idPago)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePago(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPago(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_PAGO = 'UPDATE_PAGO';
export const REQUEST_UPDATE_PAGO = "REQUEST_UPDATE_PAGO";
export const SUCCESS_UPDATE_PAGO = "SUCCESS_UPDATE_PAGO";
export const ERROR_UPDATE_PAGO = "ERROR_UPDATE_PAGO";
export const RESET_UPDATE_PAGO = "RESET_UPDATE_PAGO";

function requestUpdatePago() {
    return {
        type: REQUEST_UPDATE_PAGO,
    }
}

function receiveUpdatePago(pago) {
    return {
        type: SUCCESS_UPDATE_PAGO,
        receivedAt: Date.now(),
        pago: normalizeDato(pago)
    }
}

function errorUpdatePago(error) {
    return {
        type: ERROR_UPDATE_PAGO,
        error: error,
    }
}

export function resetUpdatePago() {
    return {
        type: RESET_UPDATE_PAGO,
    }
}

export function updatePago(pago) {
    return {
        type: UPDATE_PAGO,
        pago
    }
}

export function saveUpdatePago(files, fileLogo) {
    return (dispatch, getState) => {
        dispatch(requestUpdatePago());
        let pago = clone(getState().pagos.update.activo);
        pago.tarjetaCredito = getState().tarjetasCredito.create.nuevo;

        return pagos.saveUpdate(pago, files, fileLogo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdatePago(response));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                //console.log( error.status, error.json());
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorUpdatePago(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorUpdatePago(errorMessages.GENERAL_ERROR));
                            }
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorUpdatePago(JSON.parse(error.message)));
                                });
                            } catch (e) {
                                dispatch(errorUpdatePago(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//ALTA PAGO
export const CREATE_PAGO = 'CREATE_PAGO';
export const REQUEST_CREATE_PAGO = "REQUEST_CREATE_PAGO";
export const SUCCESS_CREATE_PAGO = "SUCCESS_CREATE_PAGO";
export const ERROR_CREATE_PAGO = "ERROR_CREATE_PAGO";
export const RESET_CREATE_PAGO = "RESET_CREATE_PAGO";


//ALTA PAGO
function requestCreatePago() {
    return {
        type: REQUEST_CREATE_PAGO,
    }
}

function receiveCreatePago(pago) {
    return {
        type: SUCCESS_CREATE_PAGO,
        receivedAt: Date.now(),
        pago: normalizeDato(pago)
    }
}

export function errorCreatePago(error) {
    return {
        type: ERROR_CREATE_PAGO,
        error: error,
    }
}

export function resetCreatePago() {
    return {
        type: RESET_CREATE_PAGO
    }
}

export function createPago(pago) {
    return {
        type: CREATE_PAGO,
        pago
    }
}

export function saveCreatePago(files, fileLogo) {
    return (dispatch, getState) => {
        dispatch(requestCreatePago());
        let pago = clone(getState().pagos.create.nuevo);
        pago.tarjetaCredito = getState().tarjetasCredito.create.nuevo;
        return pagos.saveCreate(pago, files, fileLogo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreatePago(response));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                //console.log( error.status, error.json());
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorCreatePago(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreatePago(errorMessages.GENERAL_ERROR));
                            }
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorCreatePago(JSON.parse(error.message)));
                                });
                            } catch (e) {
                                dispatch(errorCreatePago(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//DELETE PAGO
export const DELETE_PAGO = 'DELETE_PAGO';
export const REQUEST_DELETE_PAGO = "REQUEST_DELETE_PAGO";
export const SUCCESS_DELETE_PAGO = "SUCCESS_DELETE_PAGO";
export const ERROR_DELETE_PAGO = "ERROR_DELETE_PAGO";
export const RESET_DELETE_PAGO = "RESET_DELETE_PAGO";

function requestDeletePago() {
    return {
        type: REQUEST_DELETE_PAGO,
    }
}

function receiveDeletePago(pago) {
    return {
        type: SUCCESS_DELETE_PAGO,
        receivedAt: Date.now(),
        pago: normalizeDato(pago)
    }
}

function errorDeletePago(error) {
    return {
        type: ERROR_DELETE_PAGO,
        error: error,
    }
}

export function resetDeletePago(error) {
    return {
        type: RESET_DELETE_PAGO,
        error: error,
    }
}

export function deletePago(pago) {
    return {
        type: DELETE_PAGO,
        pago
    }
}

export function saveDeletePago(pago) {
    return dispatch => {
        dispatch(requestDeletePago());
        return pagos.saveDelete(pago)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeletePago());
                dispatch(receiveDeletePago(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeletePago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            try {
                                dispatch(errorDeletePago(JSON.parse(error.responseJSON.message)));
                            } catch (e) {
                                dispatch(errorCreatePago(errorMessages.GENERAL_ERROR));
                            }
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorDeletePago(JSON.parse(error.message)));
                                });
                            } catch (e) {
                                dispatch(errorDeletePago(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//REQUEST comprobantes pago
export const REQUEST_COMPROBANTE = 'REQUEST_COMPROBANTE';
export const RECEIVE_COMPROBANTE = 'RECEIVE_COMPROBANTE';
export const ERROR_COMPROBANTE = "ERROR_COMPROBANTE";
export const RESET_COMPROBANTE = "RESET_COMPROBANTE";

function requestComprobante() {
    return {
        type: REQUEST_COMPROBANTE,
    }
}

function receiveComprobante(file) {
    return {
        type: RECEIVE_COMPROBANTE,
        imagen: file,
        receivedAt: Date.now()
    }
}

export function resetComprobante() {
    return {
        type: RESET_COMPROBANTE,
    }
}

function errorComprobante(error) {
    return {
        type: ERROR_COMPROBANTE,
        error: error,
    }
}

export function fetchComprobante(idComprobante) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        dispatch(requestComprobante());
        return pagos.getComprobante(idComprobante)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    console.log("responsePago", response);
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                console.log("nombreArchivo", nombreArchivo);
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                dispatch(receiveComprobante(file));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorComprobante(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorComprobante(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//REQUEST comprobantes pago
export const REQUEST_LOGOPAGO = 'REQUEST_LOGOPAGO';
export const RECEIVE_LOGOPAGO = 'RECEIVE_LOGOPAGO';
export const ERROR_LOGOPAGO = "ERROR_LOGOPAGO";
export const RESET_LOGOPAGO = "RESET_LOGOPAGO";

function requestLogoPago() {
    return {
        type: REQUEST_LOGOPAGO,
    }
}

function receiveLogoPago(file) {
    return {
        type: RECEIVE_LOGOPAGO,
        imagen: file,
        receivedAt: Date.now()
    }
}

export function resetLogoPago() {
    return {
        type: RESET_LOGOPAGO,
    }
}

function errorLogoPago(error) {
    return {
        type: ERROR_LOGOPAGO,
        error: error,
    }
}

export function fetchLogoPago(idPago) {
    let nombreArchivo = "";
    let tipoArchivo = "";
    return dispatch => {
        dispatch(requestLogoPago());
        return pagos.getLogoPago(idPago)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    console.log("pago response", response)
                    response.headers.forEach(function (val, key) {
                        if (key === "content-disposition") {
                            // nombreArchivo = val.replace("attachment; filename=", "");}
                            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                            var matches = filenameRegex.exec(val);
                            if (matches != null && matches[1]) {
                                nombreArchivo = matches[1].replace(/['"]/g, '');
                            }
                        }
                        if (key === "content-type") {
                            tipoArchivo = val;
                        }
                    });
                    var data = response.blob();
                    return data;
                }
            })
            .then(function (data) {
                let file = new File([data], nombreArchivo, {type: tipoArchivo});
                dispatch(receiveLogoPago(file));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLogoPago(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLogoPago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}