import c from "../constants/constants";
// require('es6-promise').polyfill();
require('isomorphic-fetch');

var participantes = {

    getAll() {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/participantes/', defaultOptions);
    }
}

export default participantes;