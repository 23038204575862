import categorias from "../api/categorias"
import * as errorMessages from '../constants/MessageConstants';
import isEmpty from "lodash/isEmpty";

//CATEGORIAS
export const REQUEST_CATEGORIAS = 'REQUEST_CATEGORIAS';
export const RECEIVE_CATEGORIAS = 'RECEIVE_CATEGORIAS';
export const INVALIDATE_CATEGORIAS = 'INVALIDATE_CATEGORIAS';
export const ERROR_CATEGORIAS = "ERROR_CATEGORIAS";
export const RESET_CATEGORIAS = "RESET_CATEGORIAS";


export function invalidateCategorias() {
    return {
        type: INVALIDATE_CATEGORIAS,
    }
}

function requestCategorias() {
    return {
        type: REQUEST_CATEGORIAS,
    }
}

function receiveCategorias(json) {
    return {
        type: RECEIVE_CATEGORIAS,
        categorias: json,
        receivedAt: Date.now()
    }
}

function errorCategorias(error) {
    return {
        type: ERROR_CATEGORIAS,
        error: error,
    }
}

export function resetCategorias() {
    return {
        type: RESET_CATEGORIAS,
    }
}

function fetchCategorias() {
    return dispatch => {
        dispatch(requestCategorias());
        return categorias.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategorias(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCategorias(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorCategorias(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCategorias(state) {
    const categorias = state.categorias.byId;
    if (!categorias) {
        return true
    } else if (categorias.isFetching) {
        return false
    } else {
        return categorias.didInvalidate;
    }
}

export function fetchCategoriasIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchCategorias(getState())) {
            return dispatch(fetchCategorias())
        }
    }
}

//CATEGORIA
export const REQUEST_CATEGORIA = 'REQUEST_CATEGORIA';
export const RECEIVE_CATEGORIA = 'RECEIVE_CATEGORIA';
export const ERROR_CATEGORIA = "ERROR_CATEGORIA";


function requestCategoria() {
    return {
        type: REQUEST_CATEGORIA,
    }
}

function receiveCategoria(json) {
    return {
        type: RECEIVE_CATEGORIA,
        categoria: json,
        receivedAt: Date.now()
    }
}

function errorCategoria(error) {
    return {
        type: ERROR_CATEGORIA,
        error: error,
    }
}

export function fetchCategoria(idCategoria) {
    return dispatch => {
        dispatch(requestCategoria());
        return categorias.getOne(idCategoria)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategoria(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCategoria(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorCategoria(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

