import provincias from "../api/provincias";
import auth from "../api/authentication";
import * as errorMessages  from '../constants/MessageConstants';
import {logout} from "../actions/AuthenticationActions";


//PROVINCIAS
export const REQUEST_PROVINCIAS = 'REQUEST_PROVINCIAS';
export const RECEIVE_PROVINCIAS = 'RECEIVE_PROVINCIAS';
export const INVALIDATE_PROVINCIAS = 'INVALIDATE_PROVINCIAS';
export const ERROR_PROVINCIAS= "ERROR_PROVINCIAS";
export const RESET_PROVINCIAS = "RESET_PROVINCIAS";


export function invalidateProvincias(ciudad) {
    return {
        type: INVALIDATE_PROVINCIAS,
        ciudad
    }
}

export function resetProvincias() {
    return {
        type: RESET_PROVINCIAS,
    }
}

function requestProvincias() {
    return {
        type: REQUEST_PROVINCIAS,
    }
}

function receiveProvincias(json) {
    return {
        type: RECEIVE_PROVINCIAS,
        provincias: json,
        receivedAt: Date.now()
    }
}

function errorProvincias(error) {
    return {
        type: ERROR_PROVINCIAS,
        error: error,
    }
}

function fetchProvincias() {
    return dispatch => {
        dispatch(requestProvincias());
        return provincias.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProvincias(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProvincias(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProvincias(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProvincias(state) {
    const provincias = state.provincias.byId;
    if (!provincias) {
        return true
    } else if (provincias.isFetching) {
        return false
    } else {
        return provincias.didInvalidate;
    }
}

export function fetchProvinciasIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchProvincias(getState())) {
            return dispatch(fetchProvincias())
        }
    }
}

