import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {Button, Col, Label, Row, Table} from "react-bootstrap";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import sort from "lodash/sortBy";
import  $ from "jquery";

//CSS
import "../../../../assets/css/Landing.css";

//Actions
import {fetchCiudadesIfNeeded, invalidateCiudades} from "../../../../actions/CiudadActions";
import {fetchProvinciasIfNeeded, invalidateProvincias} from "../../../../actions/ProvinciasActions";
import {
    changeLanding,
    saveLanding,
    resetCreateLanding,
    resetUpdateLanding,
    fetchLogoLandingIfNeeded,
    updateLogoLanding,
    invalidateLogoLanding
} from "../../../../actions/LandingActions";
import {updateLogo} from "../../../../actions/ClienteActions";
import {fetchPreciosProductoTuPrecioIfNeeded} from "../../../../actions/PreciosProductoActions";

import {fetchCategoria, resetCategorias} from "../../../../actions/CategoriaActions";
import {resetProductos} from "../../../../actions/ProductoActions";
import {fetchDolarIfNeeded, invalidateDolar} from "../../../../actions/ListaPreciosActions";
import {fetchPlanesCuotasIfNeeded} from '../../../../actions/PlanesCuotasActions';
import {fetchUsuarioLogueadoIfNeeded, invalidateUsuarioLogueado} from '../../../../actions/UsuarioActions';

//Components
import MapaSearch from "../../../elementos/basicos/MapaSearch";
import HeaderPlataforma from "../MonitorPedidos/HeaderPlataforma";

//Constants
import * as listas from "../../../../constants/ListasPrecios";
import * as cuentas from "../../../../constants/Cuentas";
import * as herramientas from "../../../../constants/Herramientas";

//Images
import kitLogo from "../../../../assets/img/kitLogo.png";

const idCategoriaKit1 = 34;
const idCategoriaKit2 = 35;
const abono = 670;

class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kit1_selected: null,
            kit2_selected: null,
            abono: 0.8,
            disableCambioCotizacion: true
        }
    }

    componentDidMount() {
        // this.props.invalidateProvincias();
        // this.props.fetchProvinciasIfNeeded();
        this.props.resetProductos();
        this.props.resetCategorias();
        this.props.fetchCategoria(idCategoriaKit1);
        this.props.fetchCategoria(idCategoriaKit2);
        this.props.fetchCategoria(29);
        this.props.fetchCategoria(24);
        this.props.fetchCategoria(28);
        this.props.fetchCategoria(11);
        this.props.fetchDolarIfNeeded();
        this.props.fetchPlanesCuotasIfNeeded();
        this.props.invalidateUsuarioLogueado();
        this.props.fetchUsuarioLogueadoIfNeeded();
        this.props.invalidateLogoLanding();
        this.cargarLogoCliente();

        //Mover pantalla cuando hay un error en validacion
        var delay = 5;
        var offset = 150;
        document.addEventListener('invalid', function (e) {
            $(e.target).addClass("invalid");
            $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - offset}, delay);
        }.bind(this), true);

        document.querySelector("#myForm").addEventListener("submit", function (e) {
            console.log("holii")
            e.preventDefault();    //stop form from submitting
        });
    }

    componentWillUnmount() {
        this.props.resetProductos();
        this.props.resetCreateLanding();
        this.props.resetUpdateLanding();
        this.props.resetCategorias();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Precios
        if (this.props.accesosCuenta.allIds.length && isEmpty(this.props.preciosProducto.byId.preciosProductoTuPrecio)) {
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta && accesoCuenta.idUsuario == this.props.usuarios.byId.usuarioLogueado.id && accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID) {
                    accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                        var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                            this.props.fetchPreciosProductoTuPrecioIfNeeded(habilitacion.listaDefault, habilitacion.id);
                        }
                    });
                }
            });
        }
        if (!this.props.landing.byId.landing.id && this.props.usuarios.byId.usuarioLogueado.id && !this.props.landing.byId.landing.textoContacto) {
            let usuario = this.props.usuarios.byId.usuarioLogueado;
            let cliente = this.props.clientes.byId.clientes[usuario.idGremio];
            let cambio = {};
            cambio["nombreEmpresa"] = cliente.razonSocial;
            cambio["textoContacto"] = cliente.direccionFiscal;
            cambio["idUsuario"] = usuario.id;
            cambio["abono"] = abono;
            if (usuario.ciudad)
                cambio["textoContacto"] = cambio["textoContacto"] + " " + usuario.ciudad.nombreCiudad;
            if (usuario.ciudad && usuario.ciudad.provincia)
                cambio["textoContacto"] = `${cambio['textoContacto']} \n${usuario.ciudad.provincia.nombreProvincia}. Argentina.`;
            if (usuario.celular)
                cambio["textoContacto"] = `${cambio['textoContacto']} \nTe.: ${usuario.celular}.`;
            if (usuario.email)
                cambio["textoContacto"] = `${cambio['textoContacto']} \n${usuario.email}.`;
            this.props.changeLanding(cambio);
        }

        if (!this.props.landing.byId.landing.cotizacionDolar && this.props.landing.byId.landing && this.props.listaPrecio.dolar.dolar && this.props.landing.byId.landing.cotizacionDolar != this.props.listaPrecio.dolar.dolar.dolar) {
            let cotizacionDolar = parseFloat(this.props.listaPrecio.dolar.dolar.dolar.replace(",", ".")).toFixed(2);
            let cambio = {};
            cambio["cotizacionDolar"] = cotizacionDolar;
            this.props.changeLanding(cambio);
        }

        //Logo landing
        if (this.props.landing.byId.landing && this.props.landing.byId.landing.id && (!this.props.landing.logo.logo || this.props.landing.logo.didInvalidate )) {
            this.props.fetchLogoLandingIfNeeded();
        }
        if (!this.props.landing.logo.isFetching && prevProps.landing.logo.isFetching && this.props.landing.logo.logo) {
            if (this.props.landing.logo.logo.size > 0)
                this.cargarLogoLanding();
            else {
                if (this.props.usuarios.logo.logo && this.props.usuarios.logo.logo.size > 0)
                    this.props.updateLogoLanding(this.props.usuarios.logo.logo);
                this.cargarLogoCliente();
            }
        }
        if (!this.props.usuarios.logo.isFetching && prevProps.usuarios.logo.isFetching && (!this.props.landing.logo.logo || this.props.landing.logo.logo.size == 0) && !this.props.landing.logo.isFetching) {
            this.props.updateLogoLanding(this.props.usuarios.logo.logo);
            this.cargarLogoCliente();
        }
    }

    cargarLogoCliente() {
        console.log("cargarLogoCliente");
        var preview = this.refs.imagen;
        if (this.props.usuarios.logo.logo && this.props.usuarios.logo.logo.size > 0) {
            var file = this.props.usuarios.logo.logo;
            var fileReader = new FileReader();
            fileReader.onload = function () {
                preview.src = this.result;
            };
            fileReader.readAsDataURL(file);
        }
    }

    cargarLogoLanding() {

        console.log("cargarLogoLanding");
        var preview = this.refs.imagen;
        if (this.props.landing.logo.logo && this.props.landing.logo.logo.size > 0) {
            var file = this.props.landing.logo.logo;
            var fileReader = new FileReader();
            fileReader.onload = function () {
                preview.src = this.result;
            };
            fileReader.readAsDataURL(file);
        }
    }

    onChangeLanding(e) {
        let cambio = {};
        cambio[e.target.id] = e.target.value;

        if (e.target.id == "kit1") {
            cambio[e.target.id] = e.target.value;
            let producto = this.props.productos.byId.productos[e.target.value];
            let idProductoKit2 = null;
            let categoriaKit2 = this.props.categorias.byId.categorias[idCategoriaKit2];
            categoriaKit2.producto.some((idProducto) => {
                let productoCat2 = this.props.productos.byId.productos[idProducto];
                if (productoCat2.vd_idProducto == producto.codProducto) {
                    idProductoKit2 = productoCat2.id;
                    return true;
                }
                return false;
            })
            cambio.kit2 = idProductoKit2;
        }

        if (e.target.id == "incluyeInstalacion1" || e.target.id == "incluyeInstalacion2") {
            cambio[e.target.id] = e.target.checked;
        }

        this.props.changeLanding(cambio);
        if (e.target.id == "porcentajeGanancia1") {
            let cotizacionDolar = this.props.landing.byId.landing.cotizacionDolar ? this.props.landing.byId.landing.cotizacionDolar : 0;
            let idKit1 = this.props.landing.byId.landing.kit1;
            let precioKit1 = this.props.preciosProducto.byId.preciosProductoTuPrecio[idKit1];
            if (precioKit1) {
                precioKit1 = precioKit1 ? (parseFloat(precioKit1.precio) * parseFloat(cotizacionDolar)).toFixed(2) : 0;
                let ganancia1 = ((precioKit1 * e.target.value) / 100).toFixed(2);
                let cambio2 = {};
                cambio2.ganancia1 = ganancia1;
                this.props.changeLanding(cambio2);
            }
        }
        if (e.target.id == "porcentajeGanancia2") {
            let cotizacionDolar = this.props.landing.byId.landing.cotizacionDolar ? this.props.landing.byId.landing.cotizacionDolar : 0;
            let idKit2 = this.props.landing.byId.landing.kit2;
            let precioKit2 = this.props.preciosProducto.byId.preciosProductoTuPrecio[idKit2];
            if (precioKit2) {
                precioKit2 = precioKit2 ? (parseFloat(precioKit2.precio) * parseFloat(cotizacionDolar)).toFixed(2) : 0;
                let ganancia2 = ((precioKit2 * e.target.value) / 100).toFixed(2);
                let cambio2 = {};
                cambio2.ganancia2 = ganancia2;
                this.props.changeLanding(cambio2);
            }
        }
        //Habilitarlo como valido
        //let nombre = "#" + e.target.id;
        //let nombre = document.querySelectorAll('[id='+e.target.id+']');
        $('[id=' + e.target.id + ']').removeClass("invalid");
    }


    verImagen(e) {
        e.preventDefault();
        var preview = this.refs.imagen;


        var file = this.refs.file.files[0];
        var reader = new FileReader();

        reader.onloadend = function () {
            preview.src = reader.result;
        }

        if (file) {
            reader.readAsDataURL(file);
        } else {
            preview.src = "";
        }

        this.props.updateLogoLanding(file);
    }

    submitFormLanding(e) {
        e.preventDefault();
        console.log("e")
        // if (!e.target.checkValidity()) {
        //     alert('Por favor, complete todos los campos obligatorios.'); // error message
        // } else {
        if (!this.props.landing.byId.landing.direccionEmpresa || this.props.landing.byId.landing.direccionEmpresa == "" || this.props.landing.byId.landing.direccionHabilitada === false)
            document.getElementById("direccionEmpresa").scrollIntoView({block: "center", behavior: "smooth"});
        else
            this.props.saveLanding();
        // }
    }

    actualizarDolar(e) {
        e.preventDefault();
        if (this.props.landing.byId.landing && this.props.listaPrecio) {
            let cotizacionDolar = parseFloat(this.props.listaPrecio.dolar.dolar.dolar.replace(",", ".")).toFixed(2);
            let cambio = {};
            cambio["cotizacionDolar"] = cotizacionDolar;
            this.props.changeLanding(cambio);

            //Recalcular ganancias en base al dolar
            let landing = this.props.landing.byId.landing;
            let idKit1 = landing.kit1;
            let precioKit1 = this.props.preciosProducto.byId.preciosProductoTuPrecio[idKit1];
            if (precioKit1) {
                precioKit1 = precioKit1 ? (parseFloat(precioKit1.precio) * parseFloat(cotizacionDolar)).toFixed(2) : 0;
                let ganancia1 = (precioKit1 * landing.porcentajeGanancia1) / 100;
                let cambio2 = {};
                cambio2.ganancia1 = ganancia1;
                this.props.changeLanding(cambio2);
            }

            let idKit2 = landing.kit2;
            let precioKit2 = this.props.preciosProducto.byId.preciosProductoTuPrecio[idKit2];
            if (precioKit2) {
                precioKit2 = precioKit2 ? (parseFloat(precioKit2.precio) * parseFloat(cotizacionDolar)).toFixed(2) : 0;
                let ganancia2 = (precioKit2 * landing.porcentajeGanancia2) / 100;
                let cambio2 = {};
                cambio2.ganancia2 = ganancia2;
                this.props.changeLanding(cambio2);
            }

        }
    }

    render() {
        const {usuarioLogueado} = this.props.usuarios.byId;
        const {landing} = this.props.landing.byId;

        //PLAN
        let planCuotas = this.props.planesCuotas.byId.planesCuotas[landing.idPlanCuotas ? landing.idPlanCuotas : 1];
        let coefPlanCuotas = planCuotas ? planCuotas.coeficiente : null;


        //KITS
        var array = [1, 2, 3, 4];
        var KitPrincipales = [];
        var KitSecundarios = [];
        if (this.props.categorias.byId.isFetching) {
            array.map(function (array, index) {
                var arrayInvisible = <div key={index} className="item-invisible"/>;
                KitPrincipales.push(arrayInvisible);
                KitSecundarios.push(arrayInvisible);
            })
        }

        let categoriaKit1 = this.props.categorias.byId.categorias[idCategoriaKit1];
        if (categoriaKit1 && categoriaKit1.producto)
            sort(categoriaKit1.producto).map((idProducto) => {
                var producto = this.props.productos.byId.productos[idProducto];
                if (producto) {
                    var imagenProducto = "/img/productos/" + producto.img1;
                    var preciosProducto = this.props.preciosProducto.byId.preciosProductoTuPrecio[idProducto];
                    var precio = preciosProducto ? preciosProducto.precio * coefPlanCuotas : "";
                    let productosKit = [];
                    if (producto.esKit) {
                        producto.kit.map((idKit) => {
                            var kit = this.props.kits.byId.kits[idKit];
                            if (kit) {
                                var productoKit = this.props.productos.byId.productos[kit.idProducto];
                                if (productoKit && kit.idProductoPadre == producto.id) {
                                    productosKit.push(
                                        <tr key={productoKit.id} className="columnaProductosKit">
                                            <td>{productoKit.vd_idProducto}</td>
                                            <td className="tableColumnNombre">{productoKit.nomProducto}</td>
                                            <td className="tableColumnCantidad">{kit.cantidad}</td>
                                        </tr>
                                    );
                                }
                            }
                        });
                    }
                    KitPrincipales.push(
                        <div key={idProducto + "kit1"}
                             className={`item-kit ${landing.kit1 == producto.id ? "selected" : ""}`}
                             onClick={() => {
                                 let e = {};
                                 e.target = {};
                                 e.target.id = "kit1";
                                 e.target.value = producto.id;
                                 this.onChangeLanding(e)
                             }}
                        >
                            <div className="item-checkradio">
                                <label>
                                    <input type="radio" name="kit1"
                                           id="kit1"
                                           ref={"p-" + producto.id}
                                           value={producto.id}
                                           required={true}
                                           checked={landing.kit1 == producto.id ? true : false}
                                           onChange={(e) => this.onChangeLanding(e)}
                                    />
                                    <span/>
                                </label>
                            </div>
                            <div className="item-img">
                                <img className="imagenTabla" src={imagenProducto}
                                     onError={(e) => {
                                         e.target.src = kitLogo
                                     }}/>
                            </div>
                            <div className="item-cuerpo">
                                <p>{producto.vd_idProducto}</p>
                                <h5>{producto.nomProducto}</h5>
                            </div>
                            <div className="item-precio">
                                <span>u$s</span>{precio ? parseFloat(precio).toFixed(2) : 0}
                            </div>
                            <Table className="tablaResumen" responsive>
                                <thead className="tableHead">
                                <th>Cod</th>
                                <th className="tableColumnNombre">Nombre Producto</th>
                                <th>Cantidad</th>
                                </thead>
                                <tbody>
                                {productosKit}
                                </tbody>
                            </Table>
                        </div>
                    );
                }
            });
        let categoriaKit2 = this.props.categorias.byId.categorias[idCategoriaKit2];
        if (categoriaKit2 && categoriaKit2.producto)
            sort(categoriaKit2.producto).map((idProducto) => {
                var producto = this.props.productos.byId.productos[idProducto];
                if (producto) {
                    var imagenProducto = "/img/productos/" + producto.img1;
                    var preciosProducto = this.props.preciosProducto.byId.preciosProductoTuPrecio[idProducto];
                    var precio = preciosProducto ? preciosProducto.precio * coefPlanCuotas : "";
                    let productosKit = [];
                    if (producto.esKit) {
                        producto.kit.map((idKit) => {
                            var kit = this.props.kits.byId.kits[idKit];
                            if (kit) {
                                var productoKit = this.props.productos.byId.productos[kit.idProducto];
                                if (productoKit && kit.idProductoPadre == producto.id) {
                                    productosKit.push(
                                        <tr key={productoKit.id} className="columnaProductosKit">
                                            <td>{productoKit.vd_idProducto}</td>
                                            <td className="tableColumnNombre">{productoKit.nomProducto}</td>
                                            <td className="tableColumnCantidad">{kit.cantidad}</td>
                                        </tr>
                                    );
                                }
                            }
                        });
                    }
                    KitSecundarios.push(
                        <div key={idProducto + "kit2"}
                             className={`item-kit ${landing.kit2 == producto.id ? "selected" : ""}`}
                        >
                            <div className="item-checkradio">
                                <label>
                                    <input type="radio" name="kit2"
                                           id="kit2"
                                           ref={"s-" + producto.id}
                                           value={producto.id}
                                           required={true}
                                           checked={landing.kit2 == producto.id ? true : false}
                                           disabled
                                    />
                                    <span/>
                                </label>
                            </div>
                            <div className="item-img">
                                <img className="imagenTabla" src={imagenProducto}
                                     onError={(e) => {
                                         e.target.src = kitLogo
                                     }}/>
                            </div>
                            <div className="item-cuerpo">
                                <p>{producto.vd_idProducto}</p>
                                <h5>{producto.nomProducto}</h5>
                            </div>
                            <div className="item-precio">
                                <span>u$s</span>{precio ? parseFloat(precio).toFixed(2) : 0}
                            </div>
                            <Table className="tablaResumen" responsive>
                                <thead className="tableHead">
                                <th>Cod</th>
                                <th className="tableColumnNombre">Nombre Producto</th>
                                <th>Cantidad</th>
                                </thead>
                                <tbody>
                                {productosKit}
                                </tbody>
                            </Table>
                        </div>
                    );
                }
            });


        //PRECIOS
        let cotizacionDolar = this.props.landing.byId.landing.cotizacionDolar ? this.props.landing.byId.landing.cotizacionDolar : 0;

        let idKit1 = this.props.landing.byId.landing.kit1;
        let iva = 1.21;
        let productoKit1 = this.props.productos.byId.productos[idKit1];
        if (productoKit1 && productoKit1.iva)
            iva = (productoKit1.iva / 100) + 1;
        let precioKit1 = this.props.preciosProducto.byId.preciosProductoTuPrecio[idKit1];
        precioKit1 = precioKit1 ? (parseFloat(precioKit1.precio) * coefPlanCuotas * parseFloat(cotizacionDolar)).toFixed(2) : "";

        let idKit2 = this.props.landing.byId.landing.kit2;
        let precioKit2 = this.props.preciosProducto.byId.preciosProductoTuPrecio[idKit2];
        precioKit2 = precioKit2 ? (parseFloat(precioKit2.precio) * coefPlanCuotas * parseFloat(cotizacionDolar)).toFixed(2) : "";


        //Carga Formas de pago
        let planesCuotas = this.props.planesCuotas.allIds.map((id) => {
            let planCuotas = this.props.planesCuotas.byId.planesCuotas[id];
            return (
                <option key={id} value={planCuotas.id}>{planCuotas.desPlan}</option>
            )
        });

        let totalP1 = (precioKit1 * 1 * iva + ((landing.ganancia1 * 1 + (landing.incluyeInstalacion1 ? landing.instalacion1 * 1 : 0)) * coefPlanCuotas * iva)).toFixed(2);
        let totalP2 = (precioKit2 * 1 * iva + ((landing.ganancia2 * 1 + (landing.incluyeInstalacion1 ? landing.instalacion1 * 1 : 0)) * coefPlanCuotas * iva)).toFixed(2);

        return (
            <section className="landing">
                <HeaderPlataforma titulo={"Landing"}/>
                <Row>
                    <Col md={10} mdOffset={1} className="recuadro">
                        {(landing && landing.estadoLanding !== undefined) &&
                        <p className="subtitulo">
                            Mi Landing Page<Label
                            bsStyle={landing && landing.estadoLanding == 1 ? "success" : "warning"}>{landing && landing.estadoLanding == 1 ? "Activado" : "Esperando activación"}</Label>
                        </p>
                        }

                        <p style={{paddingTop: "10px", paddingBotton: "20px", maxWidth: "700px"}}>
                            Configurá los datos de tu página web, en donde podras brindarle información técnica
                            y comercial a tus clientes. Tambien podran ver tu información de contacto.
                        </p>

                        <div className="borderCeleste formLanding">
                            <Row>
                                <form id="myForm" onSubmit={ (e) => {
                                    this.submitFormLanding(e)
                                }}>

                                    <div className="input-row" style={{marginTop: "10px"}}>
                                        <label htmlFor="subDominio">Ingrese nombre de subdominio</label>
                                        <input id="subDominio" type="text" required
                                               name="subDominio"
                                               className="inputLanding"
                                               placeholder="Nombre de Subdominio"
                                               value={landing && landing.subDominio ? landing.subDominio : ""}
                                               disabled={landing && landing.id}
                                               onChange={(e) => this.onChangeLanding(e)}
                                               pattern={"^[a-z0-9-]*"}
                                               title="El Subdominio solo acepta letras minusculas, números enteros y guión medio (-)"
                                        />
                                    </div>
                                    <p style={{marginBottom: "25px"}}>Tu dominio
                                        sera: {usuarioLogueado && landing && landing.subDominio ? `https://${landing.subDominio}.mialarma.com.ar` : ""}</p>
                                    <div className="input-row">
                                        <legend>Selecciona tipo de landing</legend>
                                        <fieldset>
                                            <label>
                                                <input type="radio" name={"tipoLanding"}
                                                       id={"tipoLanding"}
                                                       value={1}
                                                       checked={usuarioLogueado && landing && landing.tipoLanding == 1}
                                                       required={true} onChange={(e) => this.onChangeLanding(e)}
                                                />
                                                <span/>

                                                <p>Comercial, Soporte y Contacto</p>
                                            </label>
                                            <label>
                                                <input type="radio" name={"tipoLanding"}
                                                       id={"tipoLanding"}
                                                       value={2}
                                                       checked={usuarioLogueado && landing && landing.tipoLanding == 2}
                                                       required={true} onChange={(e) => this.onChangeLanding(e)}
                                                />
                                                <span/>
                                                <p>Soporte y Contacto</p>
                                            </label>
                                        </fieldset>
                                    </div>
                                    {
                                        usuarioLogueado && landing && landing.tipoLanding == 1 &&
                                        <div
                                            style={{
                                                display: usuarioLogueado && landing && landing.tipoLanding == 2 ? "none" : "block",
                                                marginBottom: "40px"
                                            }}>
                                            <div className="input-row">
                                                <legend>Seleccione el KIT de Alarma a publicitar</legend>
                                                <fieldset>
                                                    <div className="kit-container">
                                                        <div className="items-kit-container">
                                                            {KitPrincipales}
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="input-row">
                                                <legend>El KIT seleccionado con comunicador GPRS 4G tiene un costo de:
                                                </legend>
                                                <fieldset>
                                                    <div className="kit-container">
                                                        <div className="items-kit-container">
                                                            {KitSecundarios}
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="input-row resumenPrecio">
                                                <legend>Resúmen de precios</legend>
                                                <fieldset>
                                                    <div>
                                                        <label htmlFor="idPlanCuotas">Seleccione forma de pago:</label>
                                                        <select
                                                            id="idPlanCuotas"
                                                            className="formaDePago" required
                                                            value={landing && landing.idPlanCuotas ? landing.idPlanCuotas : ""}
                                                            onChange={(e) => this.onChangeLanding(e)}>
                                                            <option value="">Seleccione Plan</option>
                                                            {planesCuotas}
                                                        </select>
                                                    </div>
                                                    <div style={{marginTop: "15px"}}>
                                                        <label htmlFor="cotizacionDolar">Cotización u$d 1 = $</label>
                                                        <input id="cotizacionDolar" type="text"
                                                               style={{maxWidth: "65px"}}
                                                               value={cotizacionDolar}
                                                               name="cotizacionDolar"
                                                               className={`inputResumen inputDolar`}
                                                               placeholder="cotizacion"
                                                               disabled={true}
                                                               readOnly/>
                                                        <button type="button" onClick={(e) => {
                                                            this.actualizarDolar(e)
                                                        }} className="actualizar-cotiz">
                                                            Actualizar
                                                        </button>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <label>Resumen kit 1</label>
                                                            <ul>
                                                                <li>
                                                                    Precio de
                                                                    costo:
                                                                    <span>{` $${precioKit1 ? precioKit1 : ""}`}</span>
                                                                </li>
                                                                <li>
                                                                    <div>Porcentaje de ganancia sobre el costo de los
                                                                        equipos:
                                                                        <span>
                                                                        <input id="porcentajeGanancia1" type="number"
                                                                               required
                                                                               name="porcentajeGanancia1"
                                                                               className="inputResumen"
                                                                               placeholder="xx"
                                                                               min={0}
                                                                               max={100}
                                                                               step={5}
                                                                               value={landing.porcentajeGanancia1 ? landing.porcentajeGanancia1 : ""}
                                                                               onChange={(e) => {
                                                                                   this.onChangeLanding(e)
                                                                               }}/>%
                                                                        <br/>
                                                                        Su ganancia en pesos por la venta de los equipos: $
                                                                    <input id="ganancia1" type="number"
                                                                           required
                                                                           name="ganancia1"
                                                                           className="inputResumen"
                                                                           placeholder="Ganancia"
                                                                           disabled={true}
                                                                           value={landing.ganancia1 ? landing.ganancia1 : ""}
                                                                           onChange={(e) => {
                                                                               this.onChangeLanding(e)
                                                                           }}/>
                                                                    </span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div>Precio Instalación: $<span>
                                                                    <input id="instalacion1"
                                                                           type="number"
                                                                           required
                                                                           name="instalacion1"
                                                                           className="inputResumen"
                                                                           placeholder="xxxx"
                                                                           value={landing.instalacion1 ? landing.instalacion1 : ""}
                                                                           onChange={(e) => {
                                                                               this.onChangeLanding(e)
                                                                           }}/>
                                                                </span>
                                                                        <input type="checkbox" id="incluyeInstalacion1"
                                                                               checked={landing.incluyeInstalacion1}
                                                                               onChange={(e) => {
                                                                                   this.onChangeLanding(e)
                                                                               }}/>
                                                                        <label className="instalacionChk"
                                                                               htmlFor="incluyeInstalacion1">Incluir
                                                                            instalación en la financiación</label>
                                                                    </div>
                                                                </li>
                                                                <li style={{listStyle: "none"}}>
                                                                    <hr/>
                                                                </li>
                                                                <li>
                                                                    Total: $<span>{totalP1 ? totalP1 : ""}</span>
                                                                    <small><i> (incluye iva)</i></small>
                                                                </li>
                                                                <li>
                                                                    Valor de cuota a publicar:
                                                                    $<span>{(planCuotas && totalP1) ? (totalP1 / planCuotas.cuotas).toFixed(2) : ""}</span>
                                                                    <small>
                                                                        <i> {planCuotas ? `(${planCuotas.cuotas} cuotas)` : ""}</i>
                                                                    </small>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                    </ul>

                                                    <ul>
                                                        <li>
                                                            <label>Resumen kit 2</label>
                                                            <ul>
                                                                <li>
                                                                    Precio de
                                                                    costo:
                                                                    <span>{` $${precioKit2 ? precioKit2 : ""}`}</span>
                                                                </li>
                                                                <li>
                                                                    <div>Porcentaje de ganancia sobre el costo de los
                                                                        equipos:
                                                                        <span>
                                                                        <input id="porcentajeGanancia2" type="number"
                                                                               required
                                                                               name="porcentajeGanancia2"
                                                                               className="inputResumen"
                                                                               placeholder="xx"
                                                                               min={0}
                                                                               max={100}
                                                                               step={5}
                                                                               value={landing.porcentajeGanancia2 ? landing.porcentajeGanancia2 : ""}
                                                                               onChange={(e) => {
                                                                                   this.onChangeLanding(e)
                                                                               }}/>%
                                                                        <br/>
                                                                        Su ganancia en pesos por la venta de los equipos: $
                                                                    <input id="ganancia2" type="number"
                                                                           required
                                                                           name="ganancia2"
                                                                           className="inputResumen"
                                                                           placeholder="Ganancia"
                                                                           disabled={true}
                                                                           value={landing.ganancia2 ? landing.ganancia2 : ""}
                                                                           onChange={(e) => {
                                                                               this.onChangeLanding(e)
                                                                           }}/>
                                                                    </span>
                                                                    </div>
                                                                </li>
                                                                <li>Precio Instalación: $<span><input id="instalacion1"
                                                                                                      type="text"
                                                                                                      required
                                                                                                      name="instalacion1"
                                                                                                      className="inputResumen"
                                                                                                      placeholder="xxxx"
                                                                                                      disabled={true}
                                                                                                      value={landing.instalacion1 ? landing.instalacion1 : ""}
                                                                                                      onChange={(e) => {
                                                                                                          this.onChangeLanding(e)
                                                                                                      }}/></span>
                                                                    <input type="checkbox" id="incluyeInstalacion1"
                                                                           checked={landing.incluyeInstalacion1}
                                                                           disabled={true}
                                                                           onChange={(e) => {
                                                                               this.onChangeLanding(e)
                                                                           }}/>
                                                                    <label className="instalacionChk"
                                                                           htmlFor="incluyeInstalacion1">Incluir
                                                                        instalación en la financiación</label>
                                                                </li>
                                                                <li style={{listStyle: "none"}}>
                                                                    <hr/>
                                                                </li>
                                                                <li>
                                                                    Total: $ <span>{totalP2 ? totalP2 : ""}</span>
                                                                    <small><i> (incluye iva)</i></small>
                                                                </li>
                                                                <li>
                                                                    Valor de cuota a publicar: $
                                                                    <span>{(planCuotas && totalP2) ? (totalP2 / planCuotas.cuotas).toFixed(2) : ""}</span>
                                                                    <small>
                                                                        <i> {planCuotas ? `(${planCuotas.cuotas} cuotas)` : ""}</i>
                                                                    </small>
                                                                </li>
                                                            </ul>
                                                        </li>

                                                    </ul>

                                                </fieldset>
                                            </div>
                                            <div className="input-row">
                                                <legend>{`Precio de abono por monitoreo (Sugerido $${abono})`}</legend>
                                                <input
                                                    id="abono"
                                                    type="number"
                                                    step=".01"
                                                    required
                                                    className="inputLanding"
                                                    min={670 * 0.8}
                                                    max={1800}
                                                    value={landing && landing.abono ? landing.abono : ""}
                                                    onChange={(e) => this.onChangeLanding(e)}/>
                                            </div>
                                            <div className="input-row">
                                                <legend>Desea que Kit Experto se encargue de la gestión de cobranza
                                                    mensual
                                                    del abono?
                                                </legend>
                                                <select
                                                    id="gestionAbonoMensualPorKit"
                                                    className="inputLanding"
                                                    required
                                                    value={landing ? landing.gestionAbonoMensualPorKit : ""}
                                                    onChange={(e) => this.onChangeLanding(e)}>
                                                    <option value="">Seleccione opción</option>
                                                    <option value={0}>No</option>
                                                    <option value={1}>Si</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="input-row">
                                        <legend>Nombre de empresa</legend>
                                        <input style={{borderTop: "1px solid #00ffeb"}}
                                               id="nombreEmpresa" type="text" required
                                               className="inputLanding"
                                               placeholder="Nombre de empresa"
                                               value={landing && landing.nombreEmpresa ? landing.nombreEmpresa : ""}
                                               onChange={(e) => this.onChangeLanding(e)}/>
                                    </div>
                                    <div className="input-row">
                                        <legend>Logo de empresa</legend>
                                        <fieldset>
                                            <div className="inputImagen">
                                                <input id="file" ref="file" type="file" name="file"
                                                       className="inputLanding"
                                                       required={(this.refs.imagen && !this.refs.imagen.src) || !this.props.usuarios.logo.logo}
                                                       accept="image/*" onChange={(e) => this.verImagen(e)}/>
                                                <img className="img-responsive" ref="imagen" src={null} alt=""/>
                                            </div>
                                        </fieldset>
                                    </div>


                                    <div className="input-row">
                                        <legend>Datos de contacto</legend>
                                        <fieldset>
                                            <p style={{paddingTop: "10px", paddingBotton: "10px"}}>
                                                Modifica libremente como queres que tu cliente te contacte.
                                            </p>
                                            <br/><br/>
                                            <textarea id="textoContacto" className="textoContacto" rows="5"
                                                      onChange={(e) => this.onChangeLanding(e)}
                                                      required
                                                      value={usuarioLogueado && landing && landing.textoContacto ? landing.textoContacto : ""}
                                                      placeholder="Texto de contacto"/>
                                        </fieldset>

                                    </div>
                                    <Button type="submit" className="registrate"
                                            disabled={this.props.landing.create.isCreating || this.props.landing.update.isUpdating}
                                            style={{display: "none"}}>
                                        Guardar cambios
                                    </Button>
                                </form>
                                <div className="input-row">
                                    <legend>Dirección de empresa</legend>
                                    <fieldset>
                                        <div style={{height: "350px", width: '100%'}}>
                                            <MapaSearch/>
                                        </div>

                                    </fieldset>
                                    <span style={{
                                        display: landing.direccionHabilitada == false ? "inline" : "none",
                                        color: "red"
                                    }}>Ingrese una dirección válida</span>
                                </div>


                                <Col md={12} className="botoneraIngreso" style={{paddingBottom: "23px"}}>
                                    <Button type="submit" className="registrate" form='myForm'
                                            disabled={this.props.landing.create.isCreating || this.props.landing.update.isUpdating}>
                                        Guardar cambios
                                    </Button>
                                    {
                                        landing.estadoLanding == 1 &&
                                        <Button type="submit" className="registrate vistaPrevia"
                                                onClick={() => window.open(usuarioLogueado && landing && landing.subDominio ? `http://${landing.subDominio}.mialarma.com.ar` : "")}
                                                disabled={this.props.landing.create.isCreating || this.props.landing.update.isUpdating}>Vista
                                            Previa
                                        </Button>
                                    }

                                </Col>


                            </Row>
                        </div>

                    </Col>
                </Row>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
        provincias: state.provincias,
        ciudades: state.ciudades,
        clientes: state.clientes,
        landing: state.landing,
        accesosCuenta: state.accesosCuenta,
        habilitaciones: state.habilitaciones,
        preciosProducto: state.preciosProducto,
        productos: state.productos,
        kits: state.kits,
        categorias: state.categorias,
        listaPrecio: state.listaPrecio,
        planesCuotas: state.planesCuotas,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanding: (landing) => {
            dispatch(changeLanding(landing))
        },
        saveLanding: () => {
            dispatch(saveLanding())
        },
        updateLogo: (file) => {
            dispatch(updateLogo(file))
        },
        fetchPreciosProductoTuPrecioIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoTuPrecioIfNeeded(idLista, idHabilitacion))
        },
        fetchCategoria: (idCategoria) => {
            dispatch(fetchCategoria(idCategoria))
        },
        resetProductos: () => {
            dispatch(resetProductos())
        },
        resetCategorias: () => {
            dispatch(resetCategorias())
        },
        fetchDolarIfNeeded: () => {
            dispatch(fetchDolarIfNeeded())
        },
        invalidateDolar: () => {
            dispatch(invalidateDolar())
        },
        fetchPlanesCuotasIfNeeded: () => {
            dispatch(fetchPlanesCuotasIfNeeded())
        },
        resetCreateLanding: () => {
            dispatch(resetCreateLanding())
        },
        resetUpdateLanding: () => {
            dispatch(resetUpdateLanding())
        },
        fetchUsuarioLogueadoIfNeeded: () => {
            dispatch(fetchUsuarioLogueadoIfNeeded())
        },
        invalidateUsuarioLogueado: () => {
            dispatch(invalidateUsuarioLogueado())
        },
        fetchLogoLandingIfNeeded: () => {
            dispatch(fetchLogoLandingIfNeeded())
        },
        invalidateLogoLanding: () => {
            dispatch(invalidateLogoLanding())
        },
        updateLogoLanding: (logo) => {
            dispatch(updateLogoLanding(logo))
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));