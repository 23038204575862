import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import "../../assets/css/Institucional.css";
import GoogleMapReact from 'google-map-react';


import smHr from '../../assets/img/smHr.png';


import alari from '../../assets/img/marcas/alari.png';
import alonso from '../../assets/img/marcas/alonso.png';
import avatec from '../../assets/img/marcas/avatec.png';
import cygnus from '../../assets/img/marcas/cygnus.png';
import dahua from '../../assets/img/marcas/dahua.png';
import lyonn from '../../assets/img/marcas/lyonn.png';
import nt from '../../assets/img/marcas/nt.png';
import siera from '../../assets/img/marcas/siera.png';
import smart from '../../assets/img/marcas/smart.png';
import takex from '../../assets/img/marcas/takex.png';
import ubiquiti from '../../assets/img/marcas/ubiquiti.png';
import wd from '../../assets/img/marcas/wd.png';
import zkteco from '../../assets/img/marcas/zkteco.png';
import kit from '../../assets/img/kitLogo.png';
import kitBig from '../../assets/img/kitLogoBig.png';

import Footer2 from '../layout/Footer2';

const Marcador = () => {
    return (
        <a href="https://goo.gl/maps/d6LwQru4oXu" target="_blank">
            <img src={kit}
                 alt="Kit Experto"
                 className="marker-map"/>
        </a>
    );
};

class Institucional extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    createMapOptions() {
        return {
            panControl: false,
            mapTypeControl: false,
            scrollwheel: this.props.scroll,
            styles: [
                {
                    "featureType": "all",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dcedf5"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -70
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "saturation": -60
                        }
                    ]
                }
            ],


        };
    }


    render() {

        var marcadores = [];
        marcadores.push(<Marcador lat={-32.907258} lng={-60.6819261}/>);
        return (
            <div className="topBorder">
                <section className="institucional-portada">
                    <Row>
                        <Col md={5} className="center-block recuadro">
                            <h1>
                                <sb>Kit Experto&nbsp;</sb>
                                es la empresa de servicios para Integradores o Instaladores en Seguridad Electrónica,
                                desarrollada por Quality Systems Group SRL para dar solución a las necesidades
                                específicas de un rubro de alta complejidad técnica y ejecutiva.
                            </h1>
                        </Col>
                    </Row>
                </section>
                <section className="mision-vision">
                    <div className="container-limit">
                        <Row>
                            <Col md={6} className="sin-padding">
                                <div className="mision">
                                    <h2>Misión</h2>
                                    <p>
                                        Queremos mantener una mirada desde adentro. Escuchar, entender, acompañar y
                                        trabajar
                                        adaptivamente. Lograr vínculos, alianzas y oportunidades mutuas.
                                        Mejorar la labor, los negocios y la profesión de nuestros clientes. Convertirnos
                                        en
                                        eje capitalizador de sus mejores proyectos.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="linea-bottom">

                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>

                        <Row>
                            <Col md={5}>
                                <div className="linea-bottom">

                                </div>
                            </Col>
                            <Col md={7} className="sin-padding">
                                <div className="vision">
                                    <h2>Visión</h2>
                                    <p>
                                        Nos interesa el trabajo por sobre todas las cosas. El generado y el bien
                                        logrado. Sabemos que del trabajo en equipo surgen los mejores resultados, y que
                                        es alli también donde surgen nuevas oportunidades. Creemos que el integrador en
                                        Seguridad Electrónica es el eslabón más importante de toda la cadena, por ser
                                        materializador de proyectos; pero asi mismo sabemos que su rol lo somete a
                                        responsabilidades adquiridas. Es por ello que desarrollamos Kit Experto, como un
                                        combinado de soluciones adjuntas a todos los proyectos ofrecidos, como una
                                        manera de allanar el camino a un mejor desarrollo profesional y comercial.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{
                            marginRight: "110px",
                            marginTop: "30px",
                            marginBottom: "50px",
                            position: "relative",
                            left: "86%"
                        }}/>

                    </div>
                </section>

                <section className="historia-responsive">
                    <div className="container-limit">
                        <Row>
                            <Col md={6} className="">
                                <div className="mision">
                                    <h2>Misión</h2>
                                    <p>
                                        Queremos mantener una mirada desde adentro. Escuchar, entender, acompañar y
                                        trabajar
                                        adaptivamente. Lograr vínculos, alianzas y oportunidades mutuas.
                                        Mejorar la labor, los negocios y la profesión de nuestros clientes. Convertirnos
                                        en
                                        eje capitalizador de sus mejores proyectos.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>

                        <Row>
                            <Col md={6} className="">
                                <div className="vision">
                                    <h2>Visión</h2>
                                    <p>
                                        Nos interesa el trabajo por sobre todas las cosas. El generado y el bien
                                        logrado. Sabemos que del trabajo en equipo surgen los mejores resultados, y que
                                        es alli también donde surgen nuevas oportunidades. Creemos que el integrador en
                                        Seguridad Electrónica es el eslabón más importante de toda la cadena, por ser
                                        materializador de proyectos; pero asi mismo sabemos que su rol lo somete a
                                        responsabilidades adquiridas. Es por ello que desarrollamos Kit Experto, como un
                                        combinado de soluciones adjuntas a todos los proyectos ofrecidos, como una
                                        manera de allanar el camino a un mejor desarrollo profesional y comercial.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>


                    </div>
                </section>

                <section className="especialistas">
                    <h2>Especialistas</h2>
                    <Col md={4} className="center-block clave">
                        <p>
                            La clave de Kit Experto está en quienes lo conformamos.
                            Somos profesionales y asistentes, técnicos y ejecutivos,
                            un grupo con
                            <sb> trayectoria, conocimiento y motivación.</sb>
                        </p>
                    </Col>
                    <p style={{marginTop: "20px"}}>Si querés formar parte de nuestro equipo, hacé click <a href="">
                        acá</a></p>
                </section>

                <section className="mision-vision">
                    <div className="container-limit">
                        <Row>
                            <Col md={6} className="sin-padding">
                                <div className="mision calidad">
                                    <h3>Política de Calidad</h3>
                                    <span>31/03/2023</span>
                                    <p>
                                        La Dirección de Quality Systems Group SRL establece y comunica a las partes
                                        interesadas su Política frente al Sistema Gestión de Calidad. Desde sus
                                        orígenes, nos comprometimos con un concepto de servicio Integral, donde nuestra
                                        trayectoria dentro de la seguridad electrónica y el canal IT nos permitió
                                        descubrir necesidades antes no comprendidas, logrando soluciones en
                                        infraestructura ágiles y ordenadas las cuales trasladamos al Integrador, siempre
                                        con la mira puesta en la Calidad de nuestros servicios ofrecidos.
                                    </p>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="linea-bottom">

                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>

                        <Row>
                            <Col md={5}>
                                <div className="linea-bottom">

                                </div>
                            </Col>
                            <Col md={7} className="sin-padding">
                                <div className="vision objetivos">
                                    <h2>Objetivos</h2>
                                    <ul>
                                        <li>
                                            Ser líderes en innovación de nuevas soluciones, involucrándose en la
                                            problemática de nuestros clientes, creando vínculos entre las diferentes
                                            tecnologías.
                                        </li>
                                        <li>
                                            Responder en tiempo y forma a las necesidades de los clientes, con la
                                            responsabilidad de mantener el rol de aliados.
                                        </li>
                                        <li>
                                            Asumir el compromiso de cumplir con los requisitos aplicables derivados de
                                            nuestro propio sistema de gestión, incluyendo los requisitos legales.
                                        </li>
                                        <li>
                                            Potenciar el crecimiento conjunto, participando activamente en la comunidad
                                            de Seguridad Electrónica en Argentina; publicando artículos, comunicando y
                                            compartiendo en las mayores exposiciones, y educando a través de nuestros
                                            propios eventos y seminarios.
                                        </li>
                                        <li>
                                            Asumir un compromiso de mejora continua del sistema de gestión de calidad,
                                            haciendo uso de los recursos humanos de nuestra organización.
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{
                            marginRight: "110px",
                            marginTop: "30px",
                            marginBottom: "50px",
                            position: "relative",
                            left: "86%"
                        }}/>
                    </div>
                </section>

                <section className="historia-responsive">
                    <div className="container-limit">
                        <Row>
                            <Col md={6} className="">
                                <div className="mision">
                                    <h2>Política de Calidad</h2>
                                    <p>31/03/2023</p>
                                    <p>
                                        La Dirección de Quality Systems Group SRL establece y comunica a las partes
                                        interesadas su Política frente al Sistema Gestión de Calidad. Desde sus
                                        orígenes, nos comprometimos con un concepto de servicio Integral, donde nuestra
                                        trayectoria dentro de la seguridad electrónica y el canal IT nos permitió
                                        descubrir necesidades antes no comprendidas, logrando soluciones en
                                        infraestructura ágiles y ordenadas las cuales trasladamos al Integrador, siempre
                                        con la mira puesta en la Calidad de nuestros servicios ofrecidos.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>

                        <Row>
                            <Col md={6} className="">
                                <div className="vision">
                                    <h2>Objetivos</h2>
                                    <ul>
                                        <li>
                                            Ser líderes en innovación de nuevas soluciones, involucrándose en la
                                            problemática de nuestros clientes, creando vínculos entre las diferentes
                                            tecnologías.
                                        </li>
                                        <li>
                                            Responder en tiempo y forma a las necesidades de los clientes, con la
                                            responsabilidad de mantener el rol de aliados.
                                        </li>
                                        <li>
                                            Asumir el compromiso de cumplir con los requisitos aplicables derivados de
                                            nuestro propio sistema de gestión, incluyendo los requisitos legales.
                                        </li>
                                        <li>
                                            Potenciar el crecimiento conjunto, participando activamente en la comunidad
                                            de Seguridad Electrónica en Argentina; publicando artículos, comunicando y
                                            compartiendo en las mayores exposiciones, y educando a través de nuestros
                                            propios eventos y seminarios.
                                        </li>
                                        <li>
                                            Asumir un compromiso de mejora continua del sistema de gestión de calidad,
                                            haciendo uso de los recursos humanos de nuestra organización.
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                        <img src={smHr} alt="" style={{marginLeft: "70px", marginTop: "70px", marginBottom: "50px"}}/>


                    </div>
                </section>

                <section className="nuestros-aliados">
                    <div className="container-limit">
                        <Row>
                            <h2>Nuestros Aliados</h2>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <img src={dahua} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={zkteco} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={takex} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={avatec} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={cygnus} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={alonso} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={ubiquiti} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={alari} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={nt} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={smart} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={lyonn} alt=""/>
                            </Col>
                            <Col md={4}>
                                <img src={siera} alt=""/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="mapa">
                    <Row>
                        <Col md={6} sm={6}>
                            <div style={{height: "550px", width: '100%'}}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{
                                        key: 'AIzaSyCkvwh0uiAf4V7eyViwaUp7ynGmVOJztdQ',
                                        language: 'es'
                                    }}
                                    center={{lat: -32.907258, lng: -60.6819261}}
                                    defaultCenter={{lat: -32.907258, lng: -60.6819261}}
                                    defaultZoom={17}
                                    gestureHandling="none"
                                    zoom={this.props.zoom}
                                    options={this.createMapOptions.bind(this)}
                                >
                                    {marcadores}
                                </GoogleMapReact>
                            </div>
                        </Col>
                        <Col md={6} sm={6}>
                            <div className="vcar">
                                <img src={kitBig} alt=""/>
                                <p>José Hernández 767 (2000) Rosario.</p>
                                <p>Santa Fe. Argentina.</p>
                                <p>Te.: 0810-3450724</p>
                                <p>kitexperto@kitexperto.com</p>
                            </div>
                        </Col>
                    </Row>
                </section>
                <Footer2/>
            </div>
        );
    }
}

export default Institucional;