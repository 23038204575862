import React, {Component} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import * as moment from 'moment';
import fechasFormat from "../../api/fechas";

import Footer2 from '../../components/layout/Footer2';

import "../../assets/css/Encuesta.css";

//Actions
import {changeUsuarioLogueado, errorCreateUsuarioLogueado, saveUsuarioLogueado, fetchUsuarioLogueadoIfNeeded} from "../../actions/UsuarioActions";
import {resetPassword, validarToken} from "../../actions/AuthenticationActions";

//Constants
import * as roles from "../../constants/Roles";

import Loading from "../../assets/img/loader.gif"

class CambiarPassword extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.authentication.token) {
            this.props.fetchUsuarioLogueadoIfNeeded();
        }
        if (this.props.match.params.token) {
            this.props.validarToken(this.props.match.params.token);
        } else if (!this.props.authentication.token && !this.props.usuarios.byId.usuarioLogueado.idRol) {
            this.props.history.push("/registrar");
        }
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.changeUsuarioLogueado(cambio);
        if (e.target.id == "password") {
            if (this.props.usuarios.byId.usuarioLogueado.confirmaPass != e.target.value) {
                this.refs.confirmaPass.setCustomValidity("Las contraseñas no coinciden");
            }
            else {
                console.log("bien");
                this.refs.confirmaPass.setCustomValidity("");
            }
        }
        if (e.target.id == "confirmaPass") {
            if (this.props.usuarios.byId.usuarioLogueado.password != e.target.value) {
                this.refs.confirmaPass.setCustomValidity("Las contraseñas no coinciden");
            }
            else {
                console.log("bien");
                this.refs.confirmaPass.setCustomValidity("");
            }
        }
    }

    submitForm(e) {
        e.preventDefault();
        if (this.props.usuarios.byId.usuarioLogueado.confirmaPass == this.props.usuarios.byId.usuarioLogueado.password) {
            if (!this.props.match.params.token)
                this.props.saveUsuarioLogueado();
            else {
                let usuario = this.props.usuarios.byId.usuarioLogueado;
                usuario.tokenReset = this.props.match.params.token;
                this.props.resetPassword(usuario);
            }
        }

    }

    render() {
        const {usuarioLogueado} = this.props.usuarios.byId;

        return (
            <div className="encuesta topBorder">
                <section className="login-ingreso">
                    <Row>
                        <Col md={10} mdOffset={1} className="recuadro">
                            <h1 className="titulo">
                                {!this.props.match.params.token ? "Cambiar contraseña" : "Restauración de contraseña"}
                            </h1>
                            <br/><br/>
                            <b style={{color: "red"}}>{this.props.authentication.errorMessage}</b>
                            <b style={{color: "red"}}>{this.props.usuarios.error}</b>
                            <form onSubmit={(e) => {this.submitForm(e)}} style={{display : this.props.authentication.errorMessage ? "none" : "inline"}}>
                                <Row >
                                    <input id="password" type="password" minLength="8"
                                           required={!this.props.authentication.token}
                                           placeholder="Contraseña"
                                           value={usuarioLogueado ? usuarioLogueado.password : ""}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                </Row>
                                <Row>
                                    <input id="confirmaPass" type="password" ref="confirmaPass" minLength="8"
                                           required={!this.props.authentication.token}
                                           placeholder="Confirmar Contraseña"
                                           value={usuarioLogueado ? usuarioLogueado.confirmaPass : ""}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                </Row>

                                <Row>
                                    <Col md={12} className="botoneraIngreso">
                                        <p style={{color: "red"}}>{this.props.usuarios.create.error}</p>
                                        <p style={{color: "red"}}>{this.props.usuarios.update.error}</p>
                                        <Button type="submit" className="registrate"
                                                disabled={this.props.usuarios.update.isUpdating || this.props.authentication.isFetching || this.props.authentication.errorMessage}>Aceptar</Button>
                                        <br/>
                                        <img src={Loading} style={{
                                            display: this.props.usuarios.create.isCreating ? "inline" : "none",
                                            width: "25px",
                                            marginTop: "21px"
                                        }}/>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Row>
                </section>

                <Footer2/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario))
        },
        errorCreate: (error) => {
            dispatch(errorCreateUsuarioLogueado(error))
        },
        saveUsuarioLogueado: () => {
            dispatch(saveUsuarioLogueado());
        },
        fetchUsuarioLogueadoIfNeeded: () => {
            dispatch(fetchUsuarioLogueadoIfNeeded());
        },
        resetPassword: (usuario) => {
            dispatch(resetPassword(usuario))
        },
        validarToken: (token) => {
            dispatch(validarToken(token))
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CambiarPassword));