import {
    SELECT_CIUDADES, INVALIDATE_CIUDADES,
    REQUEST_CIUDADES, RECEIVE_CIUDADES, ERROR_CIUDADES
} from '../actions/CiudadActions';
import { combineReducers } from 'redux';
import normalizeDatos from "../normalizers/normalizeCiudades";

function ciudadesById(state = {
    isFetching: false,
    didInvalidate: true,
    ciudades: []
}, action) {
    switch (action.type) {
        case INVALIDATE_CIUDADES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CIUDADES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_CIUDADES:
            let dato = normalizeDatos(action.ciudades).entities.ciudades;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                ciudades: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_CIUDADES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allCiudades(state = [], action) {
    switch (action.type) {
        case RECEIVE_CIUDADES:
            return normalizeDatos(action.ciudades).result ? normalizeDatos(action.ciudades).result : [];
        default:
            return state
    }
}


const ciudades = combineReducers({
    byId : ciudadesById,
    allIds : allCiudades
});

export default ciudades;