//api
import pedidos from "../api/pedidos"
import auth from "../api/authentication";

//Normalizers
import {normalizeDatos, normalizeDato} from "../normalizers/normalizePedidos";

//constants
import * as errorMessages from '../constants/MessageConstants';
import * as roles from "../constants/Roles";

//actions
import {logout} from "../actions/AuthenticationActions";

//clone
import clone from 'lodash/clone';

//Alta pedido
export const CREATE_PEDIDO = 'CREATE_PEDIDO';
export const REQUEST_CREATE_PEDIDO = "REQUEST_CREATE_PEDIDO";
export const SUCCESS_CREATE_PEDIDO = "SUCCESS_CREATE_PEDIDO";
export const ERROR_CREATE_PEDIDO = "ERROR_CREATE_PEDIDO";
export const RESET_CREATE_PEDIDO = "RESET_CREATE_PEDIDO";

function requestCreatePedido() {
    return {
        type: REQUEST_CREATE_PEDIDO,
    }
}

function receiveCreatePedido() {
    return {
        type: SUCCESS_CREATE_PEDIDO,
        receivedAt: Date.now()
    }
}

function errorCreatePedido(error) {
    return {
        type: ERROR_CREATE_PEDIDO,
        error: error,
    }
}

export function resetCreatePedido(error) {
    return {
        type: RESET_CREATE_PEDIDO,
        error: error,
    }
}

export function createPedido(pedido) {
    return {
        type: CREATE_PEDIDO,
        pedido
    }
}

export function saveCreatePedido(idLista, idUsuarioSeleccionado) {
    return (dispatch, getState) => {
        var pedido = {};
        pedido.idLista = idLista;
        pedido.detalle_pedido_mon = [];
        pedido.idUsuarioSeleccionado = idUsuarioSeleccionado;
        pedido.esPresupuesto = !!getState().pedidos.create.nuevo.esPresupuesto;
        if(getState().pedidos.create.nuevo.id)
            pedido.id = getState().pedidos.create.nuevo.id;

        //Recorro ids de detalles_pedido_mon del store de pedidos
        getState().pedidos.create.nuevo.detalle_pedido_mon.forEach((idDetalle) => {
            //Obtengo el objeto con los datos del detalle, clonando detalle para no modificarlo en el store
            var detalle = clone(getState().detallesPedidoMon.byId.detallesPedidoMon[idDetalle]);
            //Le agrego el precio del store de preciosProducto y precioProductoTuPrecio
            var precioProducto = getState().preciosProducto.byId.preciosProducto[detalle.idProducto];

            if (precioProducto)
                detalle.precioProducto = parseFloat(precioProducto.precio);
            else
                detalle.precioProducto = 0;

            if (getState().usuarios.byId.usuarioLogueado.idRol == roles.DISTRIBUIDOR_ID && getState().usuarios.update.activo.id) {
                var precioProductoTuPrecio = getState().preciosProducto.byId.preciosProductoTuPrecio[detalle.idProducto];
                if (precioProductoTuPrecio)
                    detalle.precioProductoTuPrecio = parseFloat(precioProductoTuPrecio.precio);
                else
                    detalle.precioProductoTuPrecio = 0;
            } else {
                delete detalle.precioProductoTuPrecio;
            }

            pedido.detalle_pedido_mon.push(detalle);
        });
        var nuevoPedido = getState().pedidos.create.nuevo;
        pedido.fechaEntrega = nuevoPedido.fechaEntrega;
        pedido.idFormaEntrega = nuevoPedido.idFormaEntrega;
        pedido.idLocalidadEntrega = nuevoPedido.idLocalidadEntrega;
        pedido.direccionEntrega = nuevoPedido.direccionEntrega;
        pedido.idTransporte = nuevoPedido.idTransporte;
        pedido.otroTransporte = nuevoPedido.otroTransporte;
        pedido.autorizadoARetirar = nuevoPedido.autorizadoARetirar;
        pedido.observacion = nuevoPedido.observacion;
        pedido.horaEntrega = nuevoPedido.horaEntrega;
        pedido.celularDomicilio = nuevoPedido.celularDomicilio;
        pedido.dniAutorizado = nuevoPedido.dniAutorizado;
        pedido.montoAAsegurar = nuevoPedido.montoAAsegurar;
        dispatch(requestCreatePedido());
        return pedidos.saveCreate(pedido)
            .then(function (response) {
                //Refresco token
                //auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    // return response.json();
                }
            })
            .then(function (data) {
                // dispatch(resetCreatePedido());
                dispatch(receiveCreatePedido());
                // dispatch(invalidatePedidos());
                // dispatch(fetchPedidosIfNeeded());
                //history.push("/configuracion/pedido/");
            })
            .catch(function (error) {
                    switch (error.status) {
                        case 401:
                            dispatch(errorCreatePedido(errorMessages.UNAUTHORIZED_TOKEN));
                            dispatch(logout());
                            return;
                        default:
                            error.json()
                                .then((error) => {
                                    if (error.message != "")
                                        dispatch(errorCreatePedido(error.message));
                                    else
                                        dispatch(errorCreatePedido(errorMessages.GENERAL_ERROR));
                                })
                                .catch((error) => {
                                    dispatch(errorCreatePedido(errorMessages.GENERAL_ERROR));
                                });
                            return;
                    }
                }
            );
    }
}

//PEDIDOS
export const REQUEST_PEDIDOS = 'REQUEST_PEDIDOS';
export const RECEIVE_PEDIDOS = 'RECEIVE_PEDIDOS';
export const INVALIDATE_PEDIDOS = 'INVALIDATE_PEDIDOS';
export const ERROR_PEDIDOS = "ERROR_PEDIDOS";
export const RESET_PEDIDOS = "RESET_PEDIDOS";

export function invalidatePedidos() {
    return {
        type: INVALIDATE_PEDIDOS
    }
}

function requestPedidos() {
    return {
        type: REQUEST_PEDIDOS,
    }
}

function receivePedidos(json) {
    return {
        type: RECEIVE_PEDIDOS,
        pedidos: json,
        receivedAt: Date.now()
    }
}

function errorPedidos(error) {
    return {
        type: ERROR_PEDIDOS,
        error: error,
    }
}

function fetchPedidos(filtros) {
    return dispatch => {
        dispatch(requestPedidos());
        return pedidos.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                data = normalizeDatos(data);
                dispatch(receivePedidos(data));
                //Si se busco por id, lo pongo en el update para CuentaYOrden.js
                if (filtros.id)
                    dispatch(updatePedido(data.entities.pedidos[Object.keys(data.entities.pedidos)]));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPedidos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorPedidos(error.message));
                                else
                                    dispatch(errorPedidos(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorPedidos(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

function shouldFetchPedidos(state, filtros) {
    const pedidos = state.pedidos.byId;

    if (!pedidos) {
        return true;
    } else if (filtros.id) {
        return true
    } else if (pedidos.isFetching) {
        return false;
    } else {
        return pedidos.didInvalidate;
    }
}

export function fetchPedidosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPedidos(getState(), filtros)) {
            return dispatch(fetchPedidos(filtros))
        }
    }
}

export function resetPedidos() {
    return {
        type: RESET_PEDIDOS
    }
}

//UPDATE PEDIDO
export const UPDATE_PEDIDO = 'UPDATE_PEDIDO';
export const REQUEST_UPDATE_PEDIDO = "REQUEST_UPDATE_PEDIDO";
export const SUCCESS_UPDATE_PEDIDO = "SUCCESS_UPDATE_PEDIDO";
export const ERROR_UPDATE_PEDIDO = "ERROR_UPDATE_PEDIDO";
export const RESET_UPDATE_PEDIDO = "RESET_UPDATE_PEDIDO";

function requestUpdatePedido() {
    return {
        type: REQUEST_UPDATE_PEDIDO,
    }
}

function receiveUpdatePedido() {
    return {
        type: SUCCESS_UPDATE_PEDIDO,
        receivedAt: Date.now()
    }
}

function errorUpdatePedido(error) {
    return {
        type: ERROR_UPDATE_PEDIDO,
        error: error,
    }
}

export function resetUpdatePedido() {
    return {
        type: RESET_UPDATE_PEDIDO,
    }
}

export function updatePedido(pedido, idPedido) {
    return {
        type: UPDATE_PEDIDO,
        pedido: pedido,
        idPedido
    }
}

export function saveUpdatePedido(pedido) {
    return (dispatch, getState) => {
        dispatch(requestUpdatePedido());
        dispatch(updatePedido(pedido, pedido.id));
        return pedidos.saveUpdate(pedido)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdatePedido());
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUpdatePedido(error.message));
                                else
                                    dispatch(errorUpdatePedido(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUpdatePedido(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//INICIAR PEDIDO
export const REQUEST_INICIAR_PEDIDO = "REQUEST_INICIAR_PEDIDO";
export const SUCCESS_INICIAR_PEDIDO = "SUCCESS_INICIAR_PEDIDO";
export const ERROR_INICIAR_PEDIDO = "ERROR_INICIAR_PEDIDO";

function requestIniciarPedido() {
    return {
        type: REQUEST_INICIAR_PEDIDO,
    }
}

function receiveIniciarPedido() {
    return {
        type: SUCCESS_INICIAR_PEDIDO,
        receivedAt: Date.now()
    }
}

function errorIniciarPedido(error) {
    return {
        type: ERROR_INICIAR_PEDIDO,
        error: error,
    }
}

export function iniciarPedido(pedido) {
    return (dispatch, getState) => {
        dispatch(updatePedido(pedido));
        dispatch(requestIniciarPedido());
        return pedidos.iniciarPedido(pedido)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveIniciarPedido());
                    dispatch(fetchPedidosIfNeeded({id: pedido.id}))
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorIniciarPedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorIniciarPedido(error.message));
                                else
                                    dispatch(errorIniciarPedido(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorIniciarPedido(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}


//ULTIMO PEDIDO DE ESE USUARIO
export const REQUEST_ULTIMO_PEDIDO = 'REQUEST_ULTIMO_PEDIDO';
export const RECEIVE_ULTIMO_PEDIDO = 'RECEIVE_ULTIMO_PEDIDO';
export const INVALIDATE_ULTIMO_PEDIDO = 'INVALIDATE_ULTIMO_PEDIDO';
export const ERROR_ULTIMO_PEDIDO = "ERROR_ULTIMO_PEDIDO";
export const RESET_ULTIMO_PEDIDO = "RESET_ULTIMO_PEDIDO";


export function invalidateUltimoPedido() {
    return {
        type: INVALIDATE_ULTIMO_PEDIDO,
    }
}

function requestUltimoPedido() {
    return {
        type: REQUEST_ULTIMO_PEDIDO,
    }
}

function receiveUltimoPedido(json) {
    return {
        type: RECEIVE_ULTIMO_PEDIDO,
        ultimoPedido: json,
        receivedAt: Date.now()
    }
}

function errorUltimoPedido(error) {
    return {
        type: ERROR_ULTIMO_PEDIDO,
        error: error,
    }
}

export function resetUltimoPedido() {
    return {
        type: RESET_ULTIMO_PEDIDO,
    }
}

function fetchUltimoPedido(idUsuario) {
    return dispatch => {
        dispatch(requestUltimoPedido());
        return pedidos.getByUltimoPedido(idUsuario)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUltimoPedido(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUltimoPedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then(error => {
                                if (error.message != "")
                                    dispatch(errorUltimoPedido(error.message));
                                else
                                    dispatch(errorUltimoPedido(errorMessages.GENERAL_ERROR));
                            })
                            .catch(error => {
                                dispatch(errorUltimoPedido(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

function shouldFetchUltimoPedido(state) {
    const ultimoPedido = state.pedidos.byId;
    console.log(ultimoPedido);
    if (ultimoPedido.isFetchingUltimo) {
        return false;
    } else {
        return ultimoPedido.didInvalidateUltimo;
    }
}


export function fetchUltimoPedidoIfNeeded(idUsuario) {
    return (dispatch, getState) => {
        if (idUsuario && shouldFetchUltimoPedido(getState())) {
            return dispatch(fetchUltimoPedido(idUsuario))
        } else
            return Promise.resolve();
    }
}