import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const entidadFinanciera = new schema.Entity('entidadesFinancieras', {}, {idAttribute: "id"});

    const mySchema = {entidadesFinancieras: [entidadFinanciera]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const entidadFinanciera = new schema.Entity('entidadesFinancieras', {}, {idAttribute: "id"});

    const mySchema = entidadFinanciera;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const entidadFinanciera = new schema.Entity('entidadesFinancieras', {}, {idAttribute: "id"});

    const mySchema = entidadFinanciera;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


