import {
    INVALIDATE_ENTIDADESFINANCIERAS,
    ERROR_ENTIDADESFINANCIERAS,
    RECEIVE_ENTIDADESFINANCIERAS,
    REQUEST_ENTIDADESFINANCIERAS,
    RESET_ENTIDADESFINANCIERAS,
    ERROR_ENTIDADFINANCIERA,
    RECEIVE_ENTIDADFINANCIERA,
    REQUEST_ENTIDADFINANCIERA,
    UPDATE_ENTIDADFINANCIERA,
    REQUEST_UPDATE_ENTIDADFINANCIERA,
    SUCCESS_UPDATE_ENTIDADFINANCIERA,
    ERROR_UPDATE_ENTIDADFINANCIERA,
    RESET_UPDATE_ENTIDADFINANCIERA,
    REQUEST_UPDATE_ENTIDADESFINANCIERAS,
    SUCCESS_UPDATE_ENTIDADESFINANCIERAS,
    ERROR_UPDATE_ENTIDADESFINANCIERAS,
    RESET_UPDATE_ENTIDADESFINANCIERAS,
    CREATE_ENTIDADFINANCIERA,
    ERROR_CREATE_ENTIDADFINANCIERA,
    REQUEST_CREATE_ENTIDADFINANCIERA,
    RESET_CREATE_ENTIDADFINANCIERA,
    SUCCESS_CREATE_ENTIDADFINANCIERA,
    REQUEST_CREATE_ENTIDADESFINANCIERAS,
    SUCCESS_CREATE_ENTIDADESFINANCIERAS,
    ERROR_CREATE_ENTIDADESFINANCIERAS,
    RESET_CREATE_ENTIDADESFINANCIERAS,
    DELETE_ENTIDADFINANCIERA,
    DELETE_CREATE_ENTIDADFINANCIERA,
    DELETE_UPDATE_ENTIDADFINANCIERA,
    REQUEST_DELETE_ENTIDADFINANCIERA,
    SUCCESS_DELETE_ENTIDADFINANCIERA,
    ERROR_DELETE_ENTIDADFINANCIERA,
    RESET_DELETE_ENTIDADFINANCIERA,
    REQUEST_PRINT_ENTIDADFINANCIERA,
    SUCCESS_PRINT_ENTIDADFINANCIERA,
    ERROR_PRINT_ENTIDADFINANCIERA,
    RESET_PRINT_ENTIDADFINANCIERA
} from '../actions/EntidadFinancieraActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        entidadesFinancieras: {},
    }
}

function entidadesFinancierasById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                entidadesFinancieras: {}
            });
        case RECEIVE_ENTIDADESFINANCIERAS:
            let dato = action.entidadesFinancieras.entities.entidadesFinancieras;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                entidadesFinancieras: merge({}, state.entidadesFinancieras, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ENTIDADFINANCIERA:
            let datoEntidadFinanciera = action.entidadFinanciera.entities.entidadesFinancieras;
            return Object.assign({}, state, {
                entidadesFinancieras: merge({}, state.entidadesFinancieras, datoEntidadFinanciera),
                isFetching: false,
            });
        
        case SUCCESS_DELETE_ENTIDADFINANCIERA:
            let datoEntidadFinancieraEliminado = action.entidadFinanciera.entities.entidadesFinancieras;
            return Object.assign({}, state, {
                entidadesFinancieras: mergeWith(clone(datoEntidadFinancieraEliminado), state.entidadesFinancieras, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ENTIDADFINANCIERA:
            let datoEntidadFinancieraCreado = action.entidadFinanciera.entities.entidadesFinancieras;
            return Object.assign({}, state, {
                entidadesFinancieras: mergeWith(clone(datoEntidadFinancieraCreado), state.entidadesFinancieras, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_ENTIDADESFINANCIERAS:
            let datosEntidadFinancieraCreado = action.entidadesFinancieras.entities.entidadesFinancieras;
                return Object.assign({}, state, {
                    entidadesFinancieras: mergeWith(clone(datosEntidadFinancieraCreado), state.entidadesFinancieras, (objValue, srcValue) => {return objValue;})
                });
        case SUCCESS_UPDATE_ENTIDADFINANCIERA:
            let datoEntidadFinancieraActualizado = action.entidadFinanciera.entities.entidadesFinancieras;
            return Object.assign({}, state, {
                entidadesFinancieras: mergeWith(clone(datoEntidadFinancieraActualizado), state.entidadesFinancieras, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_ENTIDADESFINANCIERAS:
            let datosEntidadFinancieraActualizado = action.entidadesFinancieras.entities.entidadesFinancieras;
                return Object.assign({}, state, {
                    entidadesFinancieras: mergeWith(clone(datosEntidadFinancieraActualizado), state.entidadesFinancieras, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                entidadesFinancieras: {}
            });
        default:
            return state
    }
}


function allEntidadesFinancieras(state = [], action) {
    switch (action.type) {
        case RECEIVE_ENTIDADESFINANCIERAS:
            return action.entidadesFinancieras.result && action.entidadesFinancieras.result.entidadesFinancieras ? union(action.entidadesFinancieras.result.entidadesFinancieras, state) : (action.entidadesFinancieras.result ? action.entidadesFinancieras.result : state) ;
        case RECEIVE_ENTIDADFINANCIERA:
                return action.entidadFinanciera.result ? union([action.entidadFinanciera.result], state) : state;
        
        case SUCCESS_CREATE_ENTIDADFINANCIERA:
                   let datoEntidadFinancieraSCreate = action.entidadFinanciera.entities.entidadesFinancieras;
                   let idNuevoSCreate = null;
                   if (Object.values(datoEntidadFinancieraSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoEntidadFinancieraSCreate)[0] && Object.values(datoEntidadFinancieraSCreate)[0].id ? Object.values(datoEntidadFinancieraSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
       case SUCCESS_CREATE_ENTIDADESFINANCIERAS:
                   let entidadesFinancierasCreate = action.entidadesFinancieras.entities && action.entidadesFinancieras.entities.entidadesFinancieras ? action.entidadesFinancieras.entities.entidadesFinancieras : null;
                   return entidadesFinancierasCreate ?
                       union(state, Object.values(entidadesFinancierasCreate).map((entidadesFinancieras) => {
                           return entidadesFinancieras.id
                       })) : state;
        case RESET_ENTIDADESFINANCIERAS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalEntidadesFinancieras(state = null, action) {
    switch (action.type) {
        case RECEIVE_ENTIDADESFINANCIERAS:
            return action.entidadesFinancieras && action.entidadesFinancieras.result.total ? action.entidadesFinancieras.result.total : 0;
        case RESET_ENTIDADESFINANCIERAS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_ENTIDADFINANCIERA:
            let dato = action.entidadFinanciera.entities.entidadesFinancieras;
            let entidadFinanciera = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: entidadFinanciera ? entidadFinanciera : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_ENTIDADFINANCIERA:
            let idsUpdate = [];
            Object.values(action.entidadFinanciera).map((entidadFinancieraUpdate) => {
                if (entidadFinancieraUpdate && entidadFinancieraUpdate.id)
                    idsUpdate.push(entidadFinancieraUpdate.id);
            });
            return merge({}, state, {
                activo: action.entidadFinanciera,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_ENTIDADFINANCIERA:
            let datoEntidadFinancieraActualizado = {};
            if (Object.values(action.entidadFinanciera.entities.entidadesFinancieras).length > 0)
                datoEntidadFinancieraActualizado = Object.values(action.entidadFinanciera.entities.entidadesFinancieras)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoEntidadFinancieraActualizado
            });
        case ERROR_UPDATE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_ENTIDADESFINANCIERAS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_ENTIDADESFINANCIERAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_ENTIDADESFINANCIERAS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });

           

           

        case DELETE_ENTIDADFINANCIERA:
            let datoEntidadFinancieraDelete = action.entidadFinanciera;
            let idsDelete = [];
           Object.values(action.entidadFinanciera).map((entidadFinancieraDelete) => {
               if (entidadFinancieraDelete && entidadFinancieraDelete.id)
                   idsDelete.push(entidadFinancieraDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoEntidadFinancieraDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
               return state;
           case DELETE_UPDATE_ENTIDADFINANCIERA:
                       let datoEntidadFinancieraDeleteUpdate = action.entidadFinanciera;
                       let idsDeleteUpdate = [];
                      Object.values(action.entidadFinanciera).map((entidadFinancieraDelete) => {
                          if (entidadFinancieraDelete && entidadFinancieraDelete.id)
                              idsDeleteUpdate.push(entidadFinancieraDelete.id);
                      });
                       if (idsDeleteUpdate.length > 0)
                           return Object.assign({}, state, {
                               activo: omit(clone(state.activo), Object.keys(datoEntidadFinancieraDeleteUpdate)),
                               activos: difference(clone(state.activos), idsDeleteUpdate)
                           });
                       else
                          return state;
        case SUCCESS_DELETE_ENTIDADFINANCIERA:
                    let datoEntidadFinancieraDeleted = {};
                    if (Object.values(action.entidadFinanciera.entities.entidadesFinancieras).length > 0)
                        datoEntidadFinancieraDeleted = Object.values(action.entidadFinanciera.entities.entidadesFinancieras)[0];
                    return Object.assign({}, state, {
                        isUpdating: false,
                        lastUpdated: action.receivedAt,
                        error: null,
                        activo: datoEntidadFinancieraDeleted
                    });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_ENTIDADFINANCIERA:
             let idsCreate = [];
             Object.values(action.entidadFinanciera).map((entidadFinancieraCreate) => {
                 if (entidadFinancieraCreate && entidadFinancieraCreate.id)
                     idsCreate.push(entidadFinancieraCreate.id);
             });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.entidadFinanciera,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_ENTIDADFINANCIERA:
            let datoEntidadFinancieraNuevo = {};
            if (Object.values(action.entidadFinanciera.entities.entidadesFinancieras).length > 0)
                datoEntidadFinancieraNuevo = Object.values(action.entidadFinanciera.entities.entidadesFinancieras)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoEntidadFinancieraNuevo,
                nuevos: []
            });
        case ERROR_CREATE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case REQUEST_CREATE_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: {},
                nuevos: []
            });
        case ERROR_CREATE_ENTIDADESFINANCIERAS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });

             

             
        case DELETE_ENTIDADFINANCIERA:
           let datoEntidadFinancieraDelete = action.entidadFinanciera;
           let idsDelete = [];
           Object.values(action.entidadFinanciera).map((entidadFinancieraDelete) => {
               if (entidadFinancieraDelete && entidadFinancieraDelete.id)
                   idsDelete.push(entidadFinancieraDelete.id);
           });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoEntidadFinancieraDelete)),
                    nuevos: difference(clone(state.nuevos), idsDelete)
                });
            else
               return state;
       case DELETE_CREATE_ENTIDADFINANCIERA:
                  let datoEntidadFinancieraDeleteCreate = action.entidadFinanciera;
                  let idsDeleteCreate = [];
                  Object.values(action.entidadFinanciera).map((entidadFinancieraDelete) => {
                      if (entidadFinancieraDelete && entidadFinancieraDelete.id)
                          idsDeleteCreate.push(entidadFinancieraDelete.id);
                  });
                   if (idsDeleteCreate.length > 0)
                       return Object.assign({}, state, {
                           nuevo: omit(clone(state.nuevo), Object.keys(datoEntidadFinancieraDeleteCreate)),
                           nuevos: difference(clone(state.nuevos), idsDeleteCreate)
                       });
                   else
                      return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_ENTIDADFINANCIERA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.entidadFinanciera,
                error: null,
            });
        case REQUEST_DELETE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

function print(state = {
    isPrinting: false,
    error: ""
}, action) {
    switch (action.type) {
        case REQUEST_PRINT_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isPrinting: true,
                error: null,
            });
        case SUCCESS_PRINT_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isPrinting: false,
                lastUpdated: action.receivedAt,
                error: null,
            });
        case ERROR_PRINT_ENTIDADFINANCIERA:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isPrinting: false,
                error: null,
            });
        default:
            return state
    }
}

const entidadesFinancieras = combineReducers({
    byId: entidadesFinancierasById,
    allIds: allEntidadesFinancieras,
    update: update,
    create: create,
    totalEntidadesFinancieras: totalEntidadesFinancieras,
    delete: deleter,
    print: print
});

export default entidadesFinancieras;