import {
    CREATE_PREGUNTA,
    REQUEST_CREATE_PREGUNTA,
    SUCCESS_CREATE_PREGUNTA,
    ERROR_CREATE_PREGUNTA,
    RESET_CREATE_PREGUNTA,
    JUGA_PREGUNTA,
    ERROR_JUGA_PREGUNTA,
    REQUEST_JUGA_PREGUNTA,
    RESET_JUGA_PREGUNTA,
    SUCCESS_JUGA_PREGUNTA,
    ERROR_PREGUNTAS,
    INVALIDATE_PREGUNTAS,
    RECEIVE_PREGUNTAS,
    REQUEST_PREGUNTAS
} from '../actions/PreguntasActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";

import normalizeDatos from '../normalizers/normalizePreguntas';

function preguntasById(state = {
    isFetching: false,
    didInvalidate: true,
    preguntas: []
}, action) {
    switch (action.type) {
        case INVALIDATE_PREGUNTAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PREGUNTAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PREGUNTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                preguntas: normalizeDatos(action.preguntas).entities.preguntas,
                lastUpdated: action.receivedAt
            });
        case ERROR_PREGUNTAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allPreguntas(state = [], action) {
    switch (action.type) {
        case RECEIVE_PREGUNTAS:
            return normalizeDatos(action.preguntas).result;
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    error: "",
    message:"",
    nuevo:{}
}, action) {
    switch (action.type) {
        case CREATE_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message:null,
                nuevo: merge({}, state.nuevo, action.pregunta)

            });
        case REQUEST_CREATE_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
                message:null,
            });
        case SUCCESS_CREATE_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message: action.message,
            });
        case ERROR_CREATE_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error,
                message: null,
            });
        case RESET_CREATE_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message: null,
                nuevo: {},
            });
        default:
            return state
    }
}


function juga(state = {
    isCreating: false,
    error: "",
    message:"",
}, action) {
    switch (action.type) {
        case JUGA_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message:null,
            });
        case REQUEST_JUGA_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
                message:null,
            });
        case SUCCESS_JUGA_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message: action.message,
            });
        case ERROR_JUGA_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error,
                message: null,
            });
        case RESET_JUGA_PREGUNTA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                message: null,
            });
        default:
            return state
    }
}

const preguntas = combineReducers({
    byId: preguntasById,
    allIds: allPreguntas,
    create: create,
    juga: juga
});

export default preguntas;