import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router'
import {Table, Modal, Button, FormGroup, InputGroup, FormControl, Glyphicon} from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import {fetchTransportesIfNeeded} from '../../../../actions/TransporteActions'
import history from "../../../../history";

const btnStyle = {
    background: "none",
    borderColor: "transparent",
    boxShadow: "none",
    backgroundColor: "#00aaeb",
    textShadow: "none",
    color: "#00ffeb",
    fontFamily: "Myriad Pro",
    fontWeight: "300",
    fontSize: "13px",
    borderRadius: "40px",
    padding: "5px 20px 5px 20px"
}

class TrackingPedidosModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.fetchTransportesIfNeeded();
    }

    closeModalBusSerie() {
        this.props.cerrar();
    }

    render() {
        let remitos = !isEmpty(this.props.pedido) ? this.props.pedido.remito.map((idRemito) => {
            console.info('remito: ', this.props.remitos.byId.remitos[idRemito]);
            let remito = this.props.remitos.byId.remitos[idRemito];
            if (remito) {
                let transporte = this.props.transportes.byId.transportes[remito.idTransporte];
                return (
                    <tr>
                        <td>{remito.vd_idRemito ? remito.vd_idRemito : ""}</td>
                        <td>{remito.direccionEntrega ? remito.direccionEntrega : ""}</td>
                        <td>{remito.nroSeguimiento ? remito.nroSeguimiento : ""}</td>
                        <td>{transporte.razonSocial ? transporte.razonSocial : ""}</td>
                        <td style={{textAlign: "center"}}>
                            <a target="_blank" href={transporte.paginaWeb ? transporte.paginaWeb : ""} style={{
                                pointerEvents: transporte.paginaWeb ? "inherit" : "none",
                                cursor: transporte.paginaWeb ? "pointer" : "default"
                            }}>
                                <Glyphicon
                                    style={{color: transporte.paginaWeb ? "#337ab7" : "grey"}}
                                           glyph="globe"/>
                            </a>
                        </td>

                    </tr>
                )
            }
        }) : null;

        let pedido = this.props.pedido;
        return (
            <Modal show={this.props.show}
                   onHide={() => this.closeModalBusSerie()}
                   aria-labelledby="contained-modal-title-lg"
            >
                <Modal.Header closeButton onClick={this.props.onHide}>
                    <Modal.Title id="contained-modal-title-lg">Seguimiento de pedido
                        Nº {pedido.vd_idPedido ? pedido.vd_idPedido : ""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nº Remito</th>
                            <th>Dir. entrega</th>
                            <th>Nº Seguimiento</th>
                            <th>Transporte</th>
                            <th>Web</th>
                        </tr>
                        </thead>
                        <tbody>
                        {remitos ? remitos : "No hay remitos"}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={btnStyle} onClick={() => history.push('/panel/monitorPedidos/' + this.props.match.params['idCuenta'] + '/bultos/' + pedido.id)}>Ver Detalles</Button>
                    <Button style={btnStyle} onClick={() => this.closeModalBusSerie()}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}


// Which props do we want to inject, given the global state?
function mapStateToProps(state) {
    return {
        remitos: state.remitos,
        transportes: state.transportes,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTransportesIfNeeded: () => {
            dispatch(fetchTransportesIfNeeded());
        }
    }
};


// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TrackingPedidosModal));