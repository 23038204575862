import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom'

import {Row, Button, FormControl} from "react-bootstrap";
import isEmpty from "lodash/isEmpty";

//Actions
import {
    updateCliente,
    createCliente,
    resetCreateCliente,
    invalidateCliente,
    fetchCliente,
    resetUpdateCliente,
    saveCreateCliente,
} from "../../../../actions/ClienteActions";

import {fetchPedidosIfNeeded, resetUpdatePedido} from "../../../../actions/PedidoActions";

//Components
import HeaderPlataforma from "./HeaderPlataforma";
import FieldGroupSubmit from "../../../camposTexto/FieldGroupSubmit";
import FieldGroup from "../../../camposTexto/FieldGroup";
import Loader from '../../../elementos/loader/Loader';

//CSS
import '../../../../assets/css/ModificarPedido.css';
import '../../../../assets/css/Loader.css';
//Constants
import * as CondicionIVA from "../../../../constants/CondicionIVA";
import history from "../../../../history";

class ModificarPedido extends Component {

    constructor(props) {
        super(props);

        this.state = {
            documento: '',
            tipoDocumento: "",
            buscarPedidosUsuario: true,
            volver: true,
        };
    }

    componentDidMount() {
        this.props.invalidateCliente();
        this.props.resetCreateCliente();
        this.props.resetUpdateCliente();

        window.scrollTo(0, 0);
        if (isEmpty(this.props.pedidos.update.activo)) {
            let state = this.state;
            this.props.fetchPedidosIfNeeded({
                id: this.props.match.params.idPedido,
                buscarPedidosUsuario: state.buscarPedidosUsuario,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.tipoDocumento == "dni" && this.props.clientes.create.nuevo.vd_codCondicionIVA != CondicionIVA.CONSUMIDOR_FINAL_ID) {
            let cliente = {};
            cliente.vd_codCondicionIVA = CondicionIVA.CONSUMIDOR_FINAL_ID;
            this.props.createCliente(cliente);
        }
    }

    componentWillUnmount() {
        this.props.resetUpdatePedido();
        // this.props.invalidateCliente();
        // this.props.resetUpdateCliente();
        this.props.resetCreateCliente();
    }

    changeCliente(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        if (!isEmpty(this.props.clientes.update.activo)) {
            this.props.updateCliente(cambio);
        } else {
            this.props.createCliente(cambio);
        }
    }

    changeDocumento(e) {
        var valor = e.target.value;
        this.setState({documento: valor});
        this.refs.datos.classList.remove("in");

        if (!isEmpty(this.props.clientes.update.activo))
            this.props.resetUpdateCliente();

        this.validarNroDocumento(this.state.tipoDocumento, valor);

    }

    changeTipoDocumento(e) {
        this.setState({tipoDocumento: e.target.value});
        this.refs.datos.classList.remove("in");
        this.validarNroDocumento(e.target.value, this.state.documento);
    }

    validarNroDocumento(tipoDocumento, documento) {
        var validacion = "";
        if (tipoDocumento == "dni") {
            if ((documento.length < 7 || documento.length > 8)) {
                validacion = "El DNI debe tener entre 7 y 8 dígitos";
            }
        } else if (tipoDocumento == "cuit") {
            if (documento.length != 11) {
                validacion = "El CUIT debe tener 11 dígitos";
            } else {
                validacion = "";
            }
        }
        document.getElementById("documento").setCustomValidity(validacion);
    }

    buscarDatosCliente(e) {
        e.preventDefault();
        this.props.invalidateCliente();
        this.props.resetUpdateCliente();
        this.props.resetCreateCliente();
        this.props.fetchCliente(this.state.tipoDocumento, this.state.documento);
        this.refs.datos.classList.add("in");
        this.setState({volver: false});
    }

    submitDatos(e) {
        e.preventDefault();
        var cambio = {
            tipoDocumento: this.state.tipoDocumento,
            documento: this.state.documento,
            idPedido: this.props.match.params['idPedido']
        };

        if (!isEmpty(this.props.clientes.update.activo)) {
            this.props.updateCliente(cambio);
        } else {
            this.props.createCliente(cambio);
        }
        this.props.saveCreateCliente();
    }

    render() {
        const {tipoDocumento} = this.state;
        var clienteNoEsta = false;
        let cliente = {};
        if (!isEmpty(this.props.clientes.update.activo)) {
            cliente = this.props.clientes.update.activo;
        } else if (!isEmpty(this.props.clientes.create.nuevo)) {
            cliente = this.props.clientes.create.nuevo;
        } else if (tipoDocumento == "dni") {
            clienteNoEsta = true;
        }

        let idPedido = this.props.match.params['idPedido'];
        let pedido = {};
        pedido = this.props.pedidos.byId.pedidos[idPedido];

        return (
            <div>
                <HeaderPlataforma titulo={"Monitor de Pedidos"}/>
                <Row className="modificarPedido">
                    <div className="titulo">
                        <h3>Cambiar datos de facturación.</h3>
                        <p onClick={() => history.push("/panel/monitorPedidos/1")}>[Volver]</p>
                    </div>
                    <form onSubmit={(e) => this.buscarDatosCliente(e)}>
                        <div>
                            <p className="comboCUITDNI">Tipo de Documento: </p>
                            <FormControl componentClass="select"
                                         placeholder="select"
                                         id="tipoDocumento"
                                         required
                                         value={tipoDocumento}
                                         onChange={(e) => this.changeTipoDocumento(e)}>
                                <option value={""}>Seleccione tipo de documento</option>
                                <option value="cuit">CUIT</option>
                                <option value="dni">DNI</option>
                            </FormControl>
                        </div>
                        <div style={{display: tipoDocumento == "" ? "none" : "block"}}>
                            <FieldGroupSubmit
                                id="documento"
                                type="number"
                                label={tipoDocumento == "cuit" ? "Ingrese CUIT" : "Ingrese DNI"}
                                placeholder=""
                                required={true}
                                help={this.props.clientes.byId.error ?
                                    this.props.clientes.byId.error
                                    :
                                    cliente.cuit ?
                                        "Ya estás en nuestro registro de datos " + cliente.razonSocial
                                        :
                                        ""
                                }
                                value={this.state.documento}
                                onChange={(e) => {
                                    this.changeDocumento(e)
                                }}
                            />
                        </div>
                    </form>

                    {
                        !this.props.clientes.byId.isFetching ? "" :
                            <div className="animated-background">
                                <div className="text-loader" style={{marginTop: "0px"}}></div>
                                <div className="text-loader"></div>
                                <div className="text-loader"></div>
                            </div>
                    }

                    <form
                        onSubmit={e => {
                            this.submitDatos(e)
                        }}
                        style={{display: !this.props.clientes.byId.isFetching ? "block" : "none"}}
                    >
                        <div className="datos collapse" ref="datos">
                            <FieldGroup
                                id="razonSocial"
                                type="text"
                                label="Razón Social/Nombre y Apellido"
                                required={true}
                                placeholder=""
                                value={cliente.razonSocial ? cliente.razonSocial : ""}
                                onChange={(e) => {
                                    this.changeCliente(e)
                                }}
                                disabled={this.state.tipoDocumento != "dni"}
                            />

                            <div className="condicionIva">
                                <p>*Condición de IVA: </p>
                                <FormControl componentClass="select"
                                             placeholder="select"
                                             id="vd_codCondicionIVA"
                                             required
                                             disabled={true}
                                             value={cliente.vd_codCondicionIVA ? cliente.vd_codCondicionIVA : ""}
                                             onChange={(e) => this.changeCliente(e)}>
                                    <option value={""}>Seleccione condición</option>
                                    <option
                                        value={CondicionIVA.CONSUMIDOR_FINAL_ID}>{CondicionIVA.CONSUMIDOR_FINAL}</option>
                                    <option
                                        value={CondicionIVA.RESPONSABLE_INSCRIPTO_ID}>{CondicionIVA.RESPONSABLE_INSCRIPTO}</option>
                                    <option
                                        value={CondicionIVA.RESPONSABLE_MONOTRIBUTO_ID}>{CondicionIVA.RESPONSABLE_MONOTRIBUTO}</option>
                                    <option value={CondicionIVA.EXENTO_ID}>{CondicionIVA.EXENTO}</option>
                                    <option
                                        value={CondicionIVA.RESPONSABLE_NO_INSCIPTO_ID}>{CondicionIVA.RESPONSABLE_NO_INSCIPTO}</option>
                                    <option
                                        value={CondicionIVA.NO_RESPONSABLE_ID}>{CondicionIVA.NO_RESPONSABLE}</option>
                                    <option
                                        value={CondicionIVA.EXPORTACION_ID}>{CondicionIVA.EXPORTACION}</option>
                                    <option
                                        value={CondicionIVA.IMPORTACION_ID}>{CondicionIVA.IMPORTACION}</option>
                                    <option value={0}>No se ha podido verificar la condición de IVA</option>
                                </FormControl>
                            </div>

                            <FieldGroup
                                id="direccionFiscal"
                                type="text"
                                label={clienteNoEsta ? "Direccion Fiscal/Particular" : "Direccion Fiscal"}
                                placeholder=""
                                required={true}
                                value={cliente.direccionFiscal ? cliente.direccionFiscal : ""}
                                onChange={(e) => {
                                    this.changeCliente(e)
                                }}
                                disabled={this.state.tipoDocumento != "dni"}
                            />

                            {
                                this.props.clientes.create.isCreating ?
                                    <div>
                                        <Loader/>
                                    </div>
                                    :

                                    <div className="botonesCuentaYOrden">
                                        <Button type="submit"
                                                className="center-block boton"
                                                disabled={(pedido ? pedido.validandoDatosFacturacion : false) || this.props.clientes.byId.error || (isEmpty(this.props.clientes.update.activo) && isEmpty(this.props.clientes.create.nuevo))}>
                                            Enviar
                                        </Button>
                                        <Button className="center-block boton"
                                                onClick={() => {history.push("/panel/monitorPedidos/1")}}
                                        >
                                            Volver
                                        </Button>
                                    </div>
                            }
                        </div>
                    </form>
                    <Button className="center-block boton"
                            style={{display: this.state.volver ? "block" : "none"}}
                            onClick={() => {history.push("/panel/monitorPedidos/1")}}
                    >
                        Volver
                    </Button>
                </Row>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        clientes: state.clientes,
        pedidos: state.pedidos,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCliente: (cliente) => {
            dispatch(updateCliente(cliente))
        },
        createCliente: (cliente) => {
            dispatch(createCliente(cliente))
        },
        invalidateCliente: () => {
            dispatch(invalidateCliente())
        },
        resetCreateCliente: () => {
            dispatch(resetCreateCliente())
        },
        resetUpdateCliente: () => {
            dispatch(resetUpdateCliente())
        },
        fetchCliente: (tipoDocumento, documento) => {
            dispatch(fetchCliente(tipoDocumento, documento))
        },
        saveCreateCliente: () => {
            dispatch(saveCreateCliente())
        },
        fetchPedidosIfNeeded: (filtros) => {
            dispatch(fetchPedidosIfNeeded(filtros))
        },
        resetUpdatePedido: () => {
            dispatch(resetUpdatePedido())
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModificarPedido));
