import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import {Button, Row, Table, Col} from "react-bootstrap";
import history from "../../../../history";

//Actions
import {fetchPedidosIfNeeded, resetUpdatePedido, resetPedidos} from "../../../../actions/PedidoActions";

//Components
import HeaderPlataforma from "./HeaderPlataforma";

//CSS
import '../../../../assets/css/Bultos.css';

//Images
import kitLogo from "../../../../assets/img/kitLogo.png";


class Bultos extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetPedidos();
        this.props.resetUpdatePedido();
        this.props.fetchPedidosIfNeeded({
            id: this.props.match.params.idPedido,
            buscarPedidosUsuario: true,
        });
    }

    componentWillUnmount() {
        this.props.resetPedidos();
    }

    render() {
        let idPedido = this.props.match.params['idPedido'];
        let pedido = {};
        pedido = this.props.pedidos.byId.pedidos[idPedido];

        var cuentaYOrden = pedido && pedido.idUsuario == this.props.usuarios.byId.usuarioLogueado.id && pedido.totalCuentaYOrden;

        //Obtengo moneda
        var simbolo = '$';
        if (pedido && pedido.moneda) {
            simbolo = pedido.moneda.simbolo;
        }

        var Bultos = [];
        this.props.bultos.allIds.map((idBulto) => {
            console.log(idBulto);
            var bulto = this.props.bultos.byId.bultos[idBulto];
            if (bulto) {
                //Armo detalles del bulto
                var DetallesBulto = [];
                this.props.packingList.allIds.some((id) => {
                    let packing = this.props.packingList.byId.packingList[id];
                    if (packing && packing.idBulto == idBulto) {
                        var detalleRemitido = this.props.detallesRemitidos.byId.detallesRemitidos[packing.idDetalleRemitido];
                        //DetallePedidoMon
                        if (detalleRemitido)
                            var detallePedidoMon = this.props.detallesPedidoMon.byId.detallesPedidoMon[detalleRemitido.idDetallePedidoMon];
                        //Producto
                        if (detallePedidoMon)
                            var producto = this.props.productos.byId.productos[detallePedidoMon.idProducto];
                        if (producto) {
                            var imagenProducto = "/img/productos/" + producto.img1;
                            var ProductosKit = [];
                            var detallesKit = new Map();
                            if (producto.esKit) {
                                this.props.kits.allIds.map((idKit) => {
                                    var kit = this.props.kits.byId.kits[idKit];
                                    if (kit && kit.idProductoPadre == producto.id) {
                                        let productoKit = this.props.productos.byId.productos[kit.idProducto];
                                        if (productoKit) {
                                            //Armo para cada detalle sus productos kits
                                            ProductosKit.push(productoKit);
                                            //Armo mapa de todos los detalles que son kits
                                            this.props.detallesPedidoMon.allIds.map((idDetalleKit) => {
                                                var detaKit = this.props.detallesPedidoMon.byId.detallesPedidoMon[idDetalleKit];
                                                if (detaKit && detaKit.idProducto == productoKit.id && detaKit.precioProducto == 0.01) {
                                                    detallesKit.set(detaKit.id, detaKit);
                                                }
                                            });
                                        }
                                    }
                                })
                            }
                            //Si los detalles no son detallesKit los muestro
                            if (!detallesKit.get(detallePedidoMon.id))
                                DetallesBulto.push(
                                    <tr key={producto.id}
                                        className={producto.esKit ? "columnaProductos kit primera" : "columnaProductos"}
                                    >
                                        <td style={{width: "150px"}}>
                                            <img className="imagenTabla" src={imagenProducto} onError={(e) => {e.target.src = kitLogo}}/>
                                        </td>
                                        <td style={{textAlign: "left", width: "500px"}}>
                                            <p>{producto.vd_idProducto}</p>
                                            <h5 className="nombreProducto"><b>{producto.nomProducto}</b></h5>
                                            <Link target="_blank" to={"/productoDetalle/" + producto.id}
                                                  className="verMas">Ver
                                                mas</Link>
                                        </td>
                                        <td style={{whiteSpace: "nowrap"}}>
                                            <p>{simbolo + " "}{detallePedidoMon.precioProducto}</p>
                                        </td>
                                        <td className="tdCantidad">{detallePedidoMon.cantidad}</td>
                                        <td className="tdCantidad">{detallePedidoMon.cantidadEntregada}</td>
                                        <td style={{display: pedido && pedido.vd_idPrioridad == 1 ? "table-cell" : "none"}}>{producto.iva}</td>
                                        <td style={{whiteSpace: "nowrap"}}>
                                            <p>{simbolo + " "}{(detallePedidoMon.precioProducto * detallePedidoMon.cantidad).toFixed(2)}</p>
                                        </td>
                                        <td style={{
                                            whiteSpace: "nowrap",
                                            display: cuentaYOrden ? "table-cell" : "none"
                                        }}>
                                            <p>{simbolo + " "}{(detallePedidoMon.precioCuentaYOrden * detallePedidoMon.cantidad).toFixed(2)}</p>
                                        </td>
                                    </tr>);
                            if (producto.esKit) {
                                ProductosKit.forEach((productoKit) => {
                                    imagenProducto = "/img/productos/" + productoKit.img1;
                                    let cantidadKit = 0;
                                    producto.kit.map((idKit) => {
                                        var kit = this.props.kits.byId.kits[idKit];
                                        if (kit && kit.idProducto == productoKit.id) {
                                            cantidadKit = kit.cantidad * detallePedidoMon.cantidad;
                                        }
                                    });
                                    DetallesBulto.push(
                                        <tr key={producto.id + " " + productoKit.id} className="columnaProductos kit">
                                            <td>
                                            </td>
                                            <td style={{textAlign: "left"}}>
                                                <h5 className="nombreProducto">
                                                    <b>{productoKit.vd_idProducto} - {productoKit.nomProducto}</b></h5>
                                                <Link target="_blank" to={"/productoDetalle/" + productoKit.id}
                                                      className="verMas">Ver mas</Link>
                                            </td>
                                            <td>-</td>
                                            <td>{cantidadKit}</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td style={{display: pedido && pedido.vd_idPrioridad == 1 ? "table-cell" : "none"}}>-</td>
                                            <td style={{display: cuentaYOrden ? "table-cell" : "none"}}>-</td>
                                        </tr>
                                    );
                                });
                            }
                        }

                    }
                });

                Bultos.push(
                    <div>
                        <h4>{"Bulto " + bulto.nroBulto}</h4>
                        <Table className="tablaResumen" id="tablaCarrito" responsive>
                            <thead className="tableHead">
                            <th style={{width: "150px"}}>Imagen</th>
                            <th style={{width: "500px"}}>Artículo y Descripción</th>
                            <th>Precio Unitario</th>
                            <th>Cantidad</th>
                            <th>Cantidad Entregada</th>
                            <th style={{display: pedido && pedido.vd_idPrioridad == 1 ? "table-cell" : "none"}}>IVA%</th>
                            <th>Total</th>
                            <th style={{display: cuentaYOrden ? "table-cell" : "none"}}>Total Modificado</th>
                            </thead>
                            <tbody>
                            {DetallesBulto}
                            </tbody>
                        </Table>
                    </div>
                );
            }
        });

        return (
            <div>
                <HeaderPlataforma titulo={"Monitor de Pedidos"}/>
                <Row className="bultos">
                    <div className="titulo">
                        <h3>Bultos.</h3>
                        <p onClick={() => history.push("/panel/monitorPedidos/1")}>[Volver]</p>
                    </div>
                    <Row>
                        {Bultos}
                    </Row>
                    <Button onClick={() => {
                        history.push("/panel/monitorPedidos/1")
                    }} className="center-block boton">Volver</Button>
                </Row>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        pedidos: state.pedidos,
        detallesPedidoMon: state.detallesPedidoMon,
        kits: state.kits,
        productos: state.productos,
        usuarios: state.usuarios,
        bultos: state.bultos,
        packingList: state.packingList,
        detallesRemitidos: state.detallesRemitidos,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPedidosIfNeeded: (filtros) => {
            dispatch(fetchPedidosIfNeeded(filtros))
        },
        resetUpdatePedido: () => {
            dispatch(resetUpdatePedido())
        },
        resetPedidos: () => {
            dispatch(resetPedidos())
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bultos));
