import React, {Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import {Col} from 'react-bootstrap';

//Images
import slide1 from '../../assets/img/slider/banners-web_productos-nuevos-13.jpg';
import slide2 from '../../assets/img/slider/banners-web_productos-nuevos-12.jpg';
import slide3 from '../../assets/img/slider/banners-web_productos-nuevos-11.jpg';
import slide4 from '../../assets/img/slider/banners-web_productos-nuevos-SIERA-Storage-01.jpg';

//CSS
import '../../assets/css/Slider.css';

class SliderProductos extends Component {
    render() {
        return (
            <Col className="slider">
                <OwlCarousel className="owl-theme" loop nav items={1} navText="" autoplay autoplayTimeout={7000}>
                    <div className="item">
                        <img src={slide1} alt=""/>
                    </div>
                    <div className="item">
                        <img src={slide2} alt=""/>
                    </div>
                    <div className="item">
                        <img src={slide3} alt=""/>
                    </div>
                    <div className="item">
                        <img src={slide4} alt=""/>
                    </div>
                </OwlCarousel>
            </Col>
        );
    }
}

export default SliderProductos;
