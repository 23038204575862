import c from "../constants/constants";
//require('es6-promise').polyfill();
import $ from "jquery";
require('isomorphic-fetch');

var listaPrecios = {

    imprimir() {

        let defaultOptions = {
            url: '',
            method: 'GET',
            //mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/vnd.ms-excel",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+'/listaPrecios', defaultOptions);
    },
    getUltimaFechaActualizacion() {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            // dataType: 'json',
        };
        return fetch(c.BASE_URL+'/listaPrecios/create', defaultOptions);
    },
    getDolar() {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            // dataType: 'json',
        };
        return fetch(c.BASE_URL+'/moneda/dolar', defaultOptions);
    },
    getCliente(cliente) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token,
            },
            // cache: false,
            // dataType: 'json',
        };
        return fetch(c.BASE_URL+'/clientes/' + cliente.id_usr, defaultOptions);
    },
    saveUpdate(cliente) {
        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Authorization": "Bearer " + localStorage.token,
                "enctype":"multipart/form-data",
            },
            // cache: false,
            // dataType: 'json',
            contentType: false,
            type: 'POST',
            processData: false,
            // body: JSON.stringify(cliente)
        };

        return fetch(c.BASE_URL+'/clientes/' + cliente.id_usr, defaultOptions);
    },
    getAll() {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+ localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL+"/listaPrecio", defaultOptions);
    },
    getById(idLista) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer "+localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/listaPrecio/'+idLista, defaultOptions);
    },
};

export default listaPrecios;