//api
import auth from "../api/authentication";
import preciosProducto from "../api/preciosProducto"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthenticationActions";


//PRECIOSPRODUCTO
export const REQUEST_PRECIOSPRODUCTO = 'REQUEST_PRECIOSPRODUCTO';
export const RECEIVE_PRECIOSPRODUCTO = 'RECEIVE_PRECIOSPRODUCTO';
export const INVALIDATE_PRECIOSPRODUCTO = 'INVALIDATE_PRECIOSPRODUCTO';
export const ERROR_PRECIOSPRODUCTO = "ERROR_PRECIOSPRODUCTO";
export const RESET_PRECIOSPRODUCTO = "RESET_PRECIOSPRODUCTO";


export function invalidatePreciosProducto(preciosProducto) {
    return {
        type: INVALIDATE_PRECIOSPRODUCTO,
        preciosProducto
    }
}

function requestPreciosProducto() {
    return {
        type: REQUEST_PRECIOSPRODUCTO,
    }
}

function receivePreciosProducto(json) {
    return {
        type: RECEIVE_PRECIOSPRODUCTO,
        preciosProducto: json,
        receivedAt: Date.now()
    }
}

function errorPreciosProducto(error) {
    return {
        type: ERROR_PRECIOSPRODUCTO,
        error: error,
    }
}

export function resetPreciosProducto(error) {
    return {
        type: RESET_PRECIOSPRODUCTO,
        error: error,
    }
}

function fetchPreciosProducto(idLista, idHabilitacion) {
    return dispatch => {
        dispatch(requestPreciosProducto());
        return preciosProducto.getLista(idLista, idHabilitacion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePreciosProducto(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPreciosProducto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPreciosProducto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPreciosProducto(state, idLista) {
    const preciosProducto = state.preciosProducto.byId;
    if (!preciosProducto) {
        return true
    } else if (preciosProducto.isFetching) {
        return false
    } else if (preciosProducto.didInvalidate) {
        return true;
    } else if (Object.keys(preciosProducto.preciosProducto).length == 0) {
        return true;
    }
}

export function fetchPreciosProductoIfNeeded(idLista, idHabilitacion) {
    return (dispatch, getState) => {
        if (shouldFetchPreciosProducto(getState(), idLista)) {
            return dispatch(fetchPreciosProducto(idLista, idHabilitacion))
        }
    }
}

//PRECIOSPRODUCTO_CONSUMIDOR
export const REQUEST_PRECIOSPRODUCTO_CONSUMIDOR = 'REQUEST_PRECIOSPRODUCTO_CONSUMIDOR';
export const RECEIVE_PRECIOSPRODUCTO_CONSUMIDOR = 'RECEIVE_PRECIOSPRODUCTO_CONSUMIDOR';
export const INVALIDATE_PRECIOSPRODUCTO_CONSUMIDOR = 'INVALIDATE_PRECIOSPRODUCTO_CONSUMIDOR';
export const ERROR_PRECIOSPRODUCTO_CONSUMIDOR = "ERROR_PRECIOSPRODUCTO_CONSUMIDOR";
export const RESET_PRECIOSPRODUCTO_CONSUMIDOR = "RESET_PRECIOSPRODUCTO_CONSUMIDOR";


export function invalidatePreciosProductoConsumidor(preciosProductoConsumidor) {
    return {
        type: INVALIDATE_PRECIOSPRODUCTO_CONSUMIDOR,
        preciosProductoConsumidor
    }
}

function requestPreciosProductoConsumidor() {
    return {
        type: REQUEST_PRECIOSPRODUCTO_CONSUMIDOR,
    }
}

function receivePreciosProductoConsumidor(json) {
    return {
        type: RECEIVE_PRECIOSPRODUCTO_CONSUMIDOR,
        preciosProductoConsumidor: json,
        receivedAt: Date.now()
    }
}

function errorPreciosProductoConsumidor(error) {
    return {
        type: ERROR_PRECIOSPRODUCTO_CONSUMIDOR,
        error: error,
    }
}

export function resetPreciosProductoConsumidor(error) {
    return {
        type: RESET_PRECIOSPRODUCTO_CONSUMIDOR,
        error: error,
    }
}

function fetchPreciosProductoConsumidor(idLista, idHabilitacion) {
    return dispatch => {
        dispatch(requestPreciosProductoConsumidor());
        return preciosProducto.getLista(idLista, idHabilitacion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePreciosProductoConsumidor(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPreciosProductoConsumidor(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPreciosProductoConsumidor(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPreciosProductoConsumidor(state, idLista) {
    const preciosProducto = state.preciosProducto.byId;
    if (!preciosProducto) {
        return true
    } else if (preciosProducto.isFetchingConsumidor) {
        return false
    } else if (preciosProducto.didInvalidateConsumidor) {
        return true;
    } else if (Object.keys(preciosProducto.preciosProductoConsumidor).length == 0) {
        return true;
    }
}

export function fetchPreciosProductoConsumidorIfNeeded(idLista, idHabilitacion) {
    return (dispatch, getState) => {
        if (shouldFetchPreciosProductoConsumidor(getState(), idLista)) {
            return dispatch(fetchPreciosProductoConsumidor(idLista, idHabilitacion))
        }
    }
}

//PRECIOSPRODUCTO_TUPRECIO
export const REQUEST_PRECIOSPRODUCTO_TUPRECIO = 'REQUEST_PRECIOSPRODUCTO_TUPRECIO';
export const RECEIVE_PRECIOSPRODUCTO_TUPRECIO = 'RECEIVE_PRECIOSPRODUCTO_TUPRECIO';
export const INVALIDATE_PRECIOSPRODUCTO_TUPRECIO = 'INVALIDATE_PRECIOSPRODUCTO_TUPRECIO';
export const ERROR_PRECIOSPRODUCTO_TUPRECIO = "ERROR_PRECIOSPRODUCTO_TUPRECIO";
export const RESET_PRECIOSPRODUCTO_TUPRECIO = "RESET_PRECIOSPRODUCTO_TUPRECIO";


export function invalidatePreciosProductoTuPrecio(preciosProductoTuPrecio) {
    return {
        type: INVALIDATE_PRECIOSPRODUCTO_TUPRECIO,
        preciosProductoTuPrecio
    }
}

function requestPreciosProductoTuPrecio() {
    return {
        type: REQUEST_PRECIOSPRODUCTO_TUPRECIO,
    }
}

function receivePreciosProductoTuPrecio(json) {
    return {
        type: RECEIVE_PRECIOSPRODUCTO_TUPRECIO,
        preciosProductoTuPrecio: json,
        receivedAt: Date.now()
    }
}

function errorPreciosProductoTuPrecio(error) {
    return {
        type: ERROR_PRECIOSPRODUCTO_TUPRECIO,
        error: error,
    }
}

export function resetPreciosProductoTuPrecio(error) {
    return {
        type: RESET_PRECIOSPRODUCTO_TUPRECIO,
        error: error,
    }
}

function fetchPreciosProductoTuPrecio(idLista, idHabilitacion) {
    return dispatch => {
        dispatch(requestPreciosProductoTuPrecio());
        return preciosProducto.getLista(idLista, idHabilitacion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePreciosProductoTuPrecio(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPreciosProductoTuPrecio(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPreciosProductoTuPrecio(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPreciosProductoTuPrecio(state, idLista) {
    const preciosProducto = state.preciosProducto.byId;
    if (!preciosProducto) {
        return true
    } else if (preciosProducto.isFetchingTuPrecio) {
        return false
    } else if (preciosProducto.didInvalidateTuPrecio) {
        return true;
    } else if (Object.keys(preciosProducto.preciosProductoTuPrecio).length == 0) {
        return true;
    }
}

export function fetchPreciosProductoTuPrecioIfNeeded(idLista, idHabilitacion) {
    return (dispatch, getState) => {
        if (shouldFetchPreciosProductoTuPrecio(getState(), idLista)) {
            return dispatch(fetchPreciosProductoTuPrecio(idLista, idHabilitacion))
        }
    }
}


//PRECIOSPRODUCTO_TUPRECIO_LISTA2
export const REQUEST_PRECIOSPRODUCTO_TUPRECIO_LISTA2 = 'REQUEST_PRECIOSPRODUCTO_TUPRECIO_LISTA2';
export const RECEIVE_PRECIOSPRODUCTO_TUPRECIO_LISTA2 = 'RECEIVE_PRECIOSPRODUCTO_TUPRECIO_LISTA2';
export const INVALIDATE_PRECIOSPRODUCTO_TUPRECIO_LISTA2 = 'INVALIDATE_PRECIOSPRODUCTO_TUPRECIO_LISTA2';
export const ERROR_PRECIOSPRODUCTO_TUPRECIO_LISTA2 = "ERROR_PRECIOSPRODUCTO_TUPRECIO_LISTA2";
export const RESET_PRECIOSPRODUCTO_TUPRECIO_LISTA2 = "RESET_PRECIOSPRODUCTO_TUPRECIO_LISTA2";


export function invalidatePreciosProductoTuPrecioLista2(preciosProductoTuPrecioLista2) {
    return {
        type: INVALIDATE_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
        preciosProductoTuPrecioLista2
    }
}

function requestPreciosProductoTuPrecioLista2() {
    return {
        type: REQUEST_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
    }
}

function receivePreciosProductoTuPrecioLista2(json) {
    return {
        type: RECEIVE_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
        preciosProductoTuPrecioLista2: json,
        receivedAt: Date.now()
    }
}

function errorPreciosProductoTuPrecioLista2(error) {
    return {
        type: ERROR_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
        error: error,
    }
}

export function resetPreciosProductoTuPrecioLista2(error) {
    return {
        type: RESET_PRECIOSPRODUCTO_TUPRECIO_LISTA2,
        error: error,
    }
}

function fetchPreciosProductoTuPrecioLista2(idLista, idHabilitacion) {
    return dispatch => {
        dispatch(requestPreciosProductoTuPrecioLista2());
        return preciosProducto.getLista(idLista, idHabilitacion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePreciosProductoTuPrecioLista2(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPreciosProductoTuPrecioLista2(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPreciosProductoTuPrecioLista2(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPreciosProductoTuPrecioLista2(state, idLista) {
    const preciosProducto = state.preciosProducto.byId;
    if (!preciosProducto) {
        return true
    } else if (preciosProducto.isFetchingTuPrecioLista2) {
        return false
    } else if (preciosProducto.didInvalidateTuPrecioLista2) {
        return true;
    } else if (Object.keys(preciosProducto.preciosProductoTuPrecioLista2).length == 0) {
        return true;
    }
}

export function fetchPreciosProductoTuPrecioLista2IfNeeded(idLista, idHabilitacion) {
    console.log("holasd");
    return (dispatch, getState) => {
        if (shouldFetchPreciosProductoTuPrecioLista2(getState(), idLista)) {
            return dispatch(fetchPreciosProductoTuPrecioLista2(idLista, idHabilitacion))
        }
    }
}