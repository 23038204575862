import {connect} from "react-redux";
import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
//Images
import kitLogo from '../../../assets/img/kitLogo.png';
//Actions
import {
    fetchPreciosProductoConsumidorIfNeeded,
    fetchPreciosProductoIfNeeded
} from "../../../actions/PreciosProductoActions";
import {fetchListaPreciosIfNeeded} from "../../../actions/ListaPreciosActions";
//Constants
import * as listas from "../../../constants/ListasPrecios";
import * as herramientas from "../../../constants/Herramientas";
import * as roles from "../../../constants/Roles";
import * as cuentas from "../../../constants/Cuentas";
//CSS
import "../../../assets/css/Producto.css";
import auth from "../../../api/authentication";

//Components


class Producto extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.authentication.token)
            this.props.fetchPreciosProductoConsumidorIfNeeded(listas.LISTA_DEFAULT, null);
        this.props.fetchListaPreciosIfNeeded();

        if (Object.keys(this.props.accesosCuenta.byId.accesosCuenta).length > 0) {
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID && accesoCuenta.idUsuario == auth.idUsuario()) {
                    accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                        var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                            if (habilitacion.listaDefault) {
                                this.props.fetchPreciosProductoIfNeeded(habilitacion.listaDefault, habilitacion.id);
                            } else if (this.props.listaPrecio.allIds.length) {
                                this.props.fetchPreciosProductoIfNeeded(this.props.listaPrecio.allIds[0], habilitacion.id);
                            }
                        }
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.listaPrecio.byId.listaPrecio != this.props.listaPrecio.byId.listaPrecio || prevProps.accesosCuenta.byId.accesosCuenta != this.props.accesosCuenta.byId.accesosCuenta) && this.props.listaPrecio.allIds.length) {
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID && accesoCuenta.idUsuario == auth.idUsuario()) {
                    accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                        var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                            var listaDef = null;
                            if (habilitacion.listaDefault) {
                                listaDef = habilitacion.listaDefault;
                            } else {
                                listaDef = this.props.listaPrecio.allIds[0];
                            }
                            this.props.fetchPreciosProductoIfNeeded(listaDef, habilitacion.id);
                        }
                    });
                }
            });
        }
    }

    render() {
        const {producto, history} = this.props;
        var path = kitLogo;
        if (producto && producto.img1)
            try {
                path = process.env.PUBLIC_URL + "/img/productos/" + producto.img1;
            } catch (e) {
            }

        //Precio
        var precioVigente = 0;
        var precioViejo = null;
        var precioProducto = null;
        if (!this.props.authentication.token || this.props.usuarios.byId.usuarioLogueado.idRol != roles.INSTALADOR_INTEGRADOR_ID) {
            precioProducto = this.props.preciosProducto.byId.preciosProductoConsumidor[producto.id.toString()];
            if (precioProducto && precioProducto.precio) {
                precioVigente = precioProducto.precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }
        } else if (this.props.usuarios.byId.usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID) {
            precioProducto = this.props.preciosProducto.byId.preciosProducto[producto.id.toString()];
            if (precioProducto && precioProducto.precio) {
                precioVigente = precioProducto.precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }
            var precioProductoViejo = this.props.preciosProducto.byId.preciosProductoConsumidor[producto.id.toString()];
            if (precioProductoViejo && precioProductoViejo.precio) {
                precioViejo = precioProductoViejo.precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }
        }

        //Obtengo moneda
        var lista = this.props.listaPrecio.byId.listaPrecio[listas.LISTA_DEFAULT];
        var simbolo = '$';
        if (lista) {
            simbolo = lista.moneda.simbolo;
        }

        var descuento = null;
        if (precioViejo && precioVigente) {
            descuento = ((precioViejo / precioVigente) - 1) * 100;
            descuento = descuento.toFixed(0);
        }

        var esInstaladorIntegrador = roles.INSTALADOR_INTEGRADOR_ID == this.props.usuarios.byId.usuarioLogueado.idRol;


        return (
            <Link className="sin-decoration" to={'/productoDetalle/' + producto.id}>
                <div className="container-producto">
                    <div className="productoComun">
                        <div className="tituloYDescuento">
                            <h2>{producto ? producto.nomProducto : ""}</h2>
                        </div>
                        <div className="contenedorDetalles">
                            <img src={path} onError={(e) => {
                                e.target.src = kitLogo;
                                e.target.style.marginTop = "35px"
                            }}/>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

function mapStateToProps(state) {
    return {
        preciosProducto: state.preciosProducto,
        listaPrecio: state.listaPrecio,
        accesosCuenta: state.accesosCuenta,
        habilitaciones: state.habilitaciones,
        authentication: state.authentication,
        usuarios: state.usuarios,

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPreciosProductoIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoIfNeeded(idLista, idHabilitacion))
        },
        fetchListaPreciosIfNeeded: (idLista) => {
            dispatch(fetchListaPreciosIfNeeded(idLista))
        },
        fetchPreciosProductoConsumidorIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoConsumidorIfNeeded(idLista, idHabilitacion))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Producto));


