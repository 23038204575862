import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router";
import "./TransferenciaBancaria.css";
import Loader from "../../../../../components/elementos/loader/Loader";
//lodash
import {Glyphicon, Row} from "react-bootstrap";
import {createPago, updatePago} from "../../../../../actions/PagoActions";
import clone from "lodash/clone"
import union from "lodash/union"
import guardarAhora from "../../../../../assets/img/pago/guardarAhora.png";
import moment from "moment";

export default function ValorPagoDiferido(props) {
    let {idPago} = useParams();
    const dispatch = useDispatch();
    let file = React.createRef();

    //Store
    const pagos = useSelector(state => state.pagos);
    const pago = idPago ? pagos.update.activo : pagos.create.nuevo;

    // Props
    let files = props.files;
    const setFiles = props.setFiles.bind(this);

    let previews = props.previews;
    const setPreviews = props.setPreviews.bind(this);

    //Effects
    useEffect(() => {
        if (!idPago) {
            let cambio = {};
            cambio.fecha = moment().format("YYYY-MM-DD");
            dispatch(createPago(cambio));
        }
    }, []);

    const onChangePago = (e) => {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (idPago)
            dispatch(updatePago(cambio));
        else
            dispatch(createPago(cambio));
    };

    let handleEliminarFoto = (index) => {
        if (!props.disabled) {
            let arrayFiles = clone(files);
            arrayFiles.splice(index, 1);
            setFiles(arrayFiles);
            let arrayPreviews = clone(previews);
            arrayPreviews.splice(index, 1);
            setPreviews(arrayPreviews);
        }
    };

    const verImagen = (e) => {
        e.preventDefault();
        //Preview
        let previewsImg = clone(previews);
        let cant = clone(previewsImg.length);
        let fotos = [];
        for (let i = 0; i < file.current.files.length; i++) {
            fotos.push(file.current.files[i]);
            let vuelta = clone(i);
            previewsImg[cant + vuelta] = "loading";
        }
        setPreviews(previewsImg);

        let fotosClone = clone(files);
        setFiles(union(fotosClone, fotos));

        //Cargar foto
        previewsImg = clone(previews);
        for (let i = 0; i < file.current.files.length; i++) {
            let vuelta = clone(i);
            let fileSubido = file.current.files[i];
            let reader = new FileReader();
            reader.onloadend = function () {
                if (fileSubido.type === "application/pdf" || fileSubido.type === "application/doc" || fileSubido.type === "application/docx")
                    previewsImg[cant + vuelta] = guardarAhora;
                else
                    previewsImg[cant + vuelta] = reader.result;
                setTimeout(() => setPreviews(previewsImg), 40);
            };
            if (fileSubido) {
                reader.readAsDataURL(fileSubido);
            }
        }
    };

    const descargarImagen = (file) => {
        let fileSubido = file;
        let reader = new FileReader();
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        reader.onloadend = function () {
            a.href = reader.result;
            a.download = file.name;
            a.click();
        };
        if (fileSubido) {
            reader.readAsDataURL(fileSubido);
        }
    };
    return (
        <div className="transferencia-bancaria container-fluid">
            <Row className="container-formulario">
                <div className="input-row">
                    <label htmlFor="fecha">Fecha pago</label>
                    <input id="fecha" type="date" required
                           name="fecha"
                           value={pago && pago.fecha ? pago.fecha : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
                <div className="input-row">
                    <label htmlFor="importe">Importe total de los valores</label>
                    <input id="importe" type="number" required
                           name="importe"
                           placeholder="Importe total de los valores"
                           value={pago && pago.importe ? pago.importe : ""}
                           disabled={props.disabled}
                           onChange={(e) => onChangePago(e)}
                    />
                </div>
                <br/>
                <div>
                    <label>Comprobantes <small>(Podés sumar más de una foto de los valores a entregar)</small></label>
                    <div className="fotos">

                        <div className="cuadroAgregarFoto" onClick={() => file.current.click()}>
                            <Glyphicon glyph="camera" bsSize="large"/>
                            <p>Agregar comprobantes</p>
                        </div>
                        {
                            previews.map((src, i) => {
                                if (src === "loading")
                                    return (<div key={i + "loader"} className="cuadroFotoLoading">
                                        <Loader/>
                                    </div>)
                                else
                                    return <div key={i + "foto"} className="cuadroFoto">
                                        <div className="herramientas">
                                            <div className="cruz" onClick={() => descargarImagen(files[i])}>
                                                <Glyphicon glyph="cloud-download" bsSize="large"/>
                                            </div>
                                            {
                                                !props.disabled &&
                                                <div className="cruz" onClick={() => handleEliminarFoto(i)}>
                                                    <Glyphicon glyph="trash" bsSize="large"/>
                                                </div>
                                            }
                                        </div>
                                        <img src={src}/>
                                    </div>
                            })
                        }
                        <input type="file" ref={file} accept="image/png, image/jpeg, application/pdf,.doc, .docx"
                               multiple={true} disabled={props.disabled}
                               className="agregarFoto" onChange={(e) => verImagen(e)}/>
                    </div>
                </div>
            </Row>
        </div>
    )
        ;
}
