export class FormDecidir {
    constructor(nroTarjeta, titular, mesVencimiento, anoVencimiento, codigoSeguridad, dni) {
        this.formTemplate = `
            <form action="" method="post" id="card_data_form" name="token-form" >
		    <fieldset>
				<ul>
	                <li>
	                    <label for="card_number">Credit card number:</label>
	                    <input type="text" data-decidir="card_number" name="card_number" placeholder="XXXXXXXXXXXXXXXX" value="${nroTarjeta}"/>
	                </li>
	                <li>
	                    <label for="security_code">Security code:</label>
	                    <input type="text"  data-decidir="security_code" placeholder="XXX" value="${codigoSeguridad}" />
	                </li>
	                <li>
	                    <label for="card_expiration_month">Expiration month:</label>
	                    <input type="text"  data-decidir="card_expiration_month" placeholder="MM" value="${mesVencimiento}"/>
	                </li>
	                <li>
	                    <label for="card_expiration_year">Expiration year:</label>
	                    <input type="text"  data-decidir="card_expiration_year" placeholder="YY" value="${anoVencimiento}"/>
	                </li>
	                <li>
	                    <label for="card_holder_name">Card holder name:</label>
	                    <input type="text" data-decidir="card_holder_name" name="card_holder_name" placeholder="APRO" value="EA"/>
	                </li>
	                <li>
                <label for="card_holder_birthday">Card holder birthday:</label>
                <input type="text" id="card_holder_birthday" data-decidir="card_holder_birthday" placeholder="ddMMYYYY"
                       value="04091994"/>
            </li>
            <li>
                <label for="card_holder_door_number">Card holder door number:</label>
                <input type="text" id="card_holder_door_number" data-decidir="card_holder_door_number" placeholder="NUM"
                       value="1"/>
            </li>
            <li>
            <label for="issued">Issued:</label>
            <input type="text" id="issued" data-decidir="issued" name="issued" disabled="true"/>
            </li>
            <li>
                <label for="card_holder_doc_type">Document type:</label>
                <select data-decidir="card_holder_doc_type">
                    <option value="dni">DNI</option>
                </select>
            </li>
	                <li>
                    <label for="card_holder_doc_type">Document number:</label>
                    <input type="text" data-decidir="card_holder_doc_number" placeholder="XXXXXXXXXX" value="27859328"/>
	                </li>
		        </ul>
		        <input type="submit" value="Generate Token!" id="btnGenerateToken" />
		    </fieldset>
		    </form>
  `;
    }
}