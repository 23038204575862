import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';

import Header from "../layout/HeaderLogo"

import '../../assets/css/NuevoLocal.css';


import GoogleMapReact from 'google-map-react';
import kit from '../../assets/img/kitLogo.png';
import info from '../../assets/img/nuevoLocal/info.png';

const Marcador = () => {
    return (
        <a href="https://goo.gl/maps/NvGNtRhqM2n" target="_blank">
            <img src={kit}
                 alt="Kit Experto"
                 className="marker-map"/>
        </a>
    );
}

class NuevoLocal extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    createMapOptions() {
        return {
            panControl: false,
            mapTypeControl: false,
            scrollwheel: this.props.scroll,
            styles: [
                {
                    "featureType": "all",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dcedf5"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -70
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        },
                        {
                            "saturation": -60
                        }
                    ]
                }
            ],


        };
    }

    render() {
        var marcadores = [];
        marcadores.push(<Marcador lat={-32.907258} lng={-60.6819261}/>);

        return (
            <div className="container-fluid nuevo-local">
                <Header/>
                <Row>
                    <div className="container-limit">
                        <Col md={12}>
                            <h1>
                                Conocé nuestro <b>nuevo local, por dentro</b>
                            </h1>
                        </Col>
                    </div>
                </Row>
                <Row className="seccion-blanca">
                    <div className="container-limit">
                        <Col md={10}>
                            <p>Hace dos años pensamos una propuesta de trabajo diferente, <b>en equipo.</b></p>
                            <p>A partir de este 18 de diciembre vas a poder aprovecharla en nuestro <b>nuevo espacio
                                tecnológico.</b></p>
                        </Col>
                    </div>
                </Row>
                <Row className="direccion">
                    <div className="container-limit">
                        <Col md={6} sm={6} style={{height: "340px", display: "inline-table"}}>
                            <div className="center-vertical">
                                <p>Ubicado en:</p>
                                <h3>José Hernández 767.
                                    Rosario</h3>
                            </div>
                        </Col>
                    </div>
                    <Col md={6} sm={6} style={{paddingRight: "0px", paddingLeft: "0px"}}>
                        <div style={{height: "340px", width: '100%'}}>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: 'AIzaSyCkvwh0uiAf4V7eyViwaUp7ynGmVOJztdQ',
                                    language: 'es'
                                }}
                                center={{lat: -32.907258, lng: -60.6819261}}
                                defaultCenter={{lat: -32.907258, lng: -60.6819261}}
                                defaultZoom={17}
                                zoomControl={false}
                                gestureHandling="none"
                                zoom={this.props.zoom}
                                options={this.createMapOptions.bind(this)}
                            >
                                {marcadores}
                            </GoogleMapReact>
                        </div>
                    </Col>
                </Row>
                <div className="barra-divisora"></div>
                <Row className="creamos">
                    <Col md={6} className="center-block">
                        <h2>Creamos un local de alta tecnología y
                            utilidad para que sigamos trabajando juntos
                            pero mucho más cómodos</h2>

                    </Col>

                    <br/><br/><br/>
                    <img className="img-responsive" src={info} alt=""/>

                </Row>

                <div className="barra-mas"></div>
                <Row className="footer">
                    <a href="http://www.kitexperto.com">www.kitexperto.com</a>
                </Row>
            </div>
        );
    }
}

export default NuevoLocal;
