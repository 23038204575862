import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){
    console.log(myData);
    const producto = new schema.Entity('producto',{}, {idAttribute: "id"});
    const detallesPedidoMon = new schema.Entity('detallesPedidoMon',{producto: producto});
    const mySchema = [ detallesPedidoMon ] ;
    const normalizedData = normalize(myData, mySchema);
    console.log(normalizedData);
    return normalizedData;
}

export default normalizeDatos;