import React, {Component} from 'react';
import {connect} from "react-redux";

//Boostrap
import {Row} from 'react-bootstrap';

//Components
import CatalogoMarca from "../elementos/productos/CatalogoMarca";
import BannerIntegrador from "../secciones/BannerIntegrador";
import SliderMarca from "../secciones/SliderMarca"
import Catalogo from "../secciones/Catalogo";
import SliderProductos from "../secciones/SliderProductos";
import Footer2 from "../layout/Footer2";
import SubBarra from '../layout/SubBarra';
import SliderHome from "../secciones/SliderHome";

class ProductosInicio extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="container-fluid">
                <SubBarra logueado={this.props.authentication.token}/>
                <Row>
                    {/*<SliderProductos/>*/}
                    <SliderHome/>
                </Row>
                <Row>
                    <Catalogo/>
                </Row>
                <Row>
                    <BannerIntegrador/>
                </Row>
                <Row>
                    <CatalogoMarca/>
                </Row>
                <Row>
                    <SliderMarca/>
                </Row>
                <Footer2/>
            </div>

        )
            ;
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductosInicio);
