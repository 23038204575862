import { normalize, schema } from 'normalizr';

function normalizeDatos(myData){

    const destino = new schema.Entity('destinos',{}, {idAttribute:"id"});
    const transporte = new schema.Entity('transportes',{Destino:[destino]}, {idAttribute:"id"});
    const mySchema = [ transporte ] ;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;