import {combineReducers} from 'redux';
import {RECEIVE_PAGO, RECEIVE_PAGOS, SUCCESS_CREATE_PAGO, SUCCESS_UPDATE_PAGO} from "../actions/PagoActions";
import merge from "lodash/merge";
import {RESET_ESTADOSPAGO} from "../actions/EstadosPagoActions";

function estadosPagoById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosPago: []
}, action) {
    switch (action.type) {

        case RESET_ESTADOSPAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                estadosPago: {}
            });

        //PAGOS
        case RECEIVE_PAGOS:
            return Object.assign({}, state, {
                estadosPago: merge({}, state.estadosPago, action.pagos.entities && action.pagos.entities.estadosPago ? action.pagos.entities.estadosPago : {}),
                error: null
            });
        case RECEIVE_PAGO:
            return Object.assign({}, state, {
                estadosPago: merge({}, state.estadosPago, action.pago.entities && action.pago.entities.estadosPago ? action.pago.entities.estadosPago : {}),
                error: null
            });
        case SUCCESS_CREATE_PAGO:
            return Object.assign({}, state, {
                estadosPago: merge({}, state.estadosPago, action.pago.entities && action.pago.entities.estadosPago ? action.pago.entities.estadosPago : {}),
                error: null
            });
        case SUCCESS_UPDATE_PAGO:
            return Object.assign({}, state, {
                estadosPago: merge({}, state.estadosPago, action.pago.entities && action.pago.entities.estadosPago ? action.pago.entities.estadosPago : {}),
                error: null
            });
        default:
            return state
    }
}


function allMediosPago(state = [], action) {
    switch (action.type) {
        case RESET_ESTADOSPAGO:
            return [];
        default:
            return state
    }
}


const estadosPago = combineReducers({
    byId: estadosPagoById,
    allIds: allMediosPago
});

export default estadosPago;