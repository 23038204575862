import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

//api
import preguntas from "../api/preguntas";

//PREGUNTAS
export const REQUEST_PREGUNTAS = 'REQUEST_PREGUNTAS';
export const RECEIVE_PREGUNTAS = 'RECEIVE_PREGUNTAS';
export const INVALIDATE_PREGUNTAS = 'INVALIDATE_PREGUNTAS';
export const ERROR_PREGUNTAS= "ERROR_PREGUNTAS";


export function invalidatePreguntas(pregunta) {
    return {
        type: INVALIDATE_PREGUNTAS,
        pregunta
    }
}

function requestPreguntas() {
    return {
        type: REQUEST_PREGUNTAS,
    }
}

function receivePreguntas(json) {
    return {
        type: RECEIVE_PREGUNTAS,
        preguntas: json,
        receivedAt: Date.now()
    }
}

function errorPreguntas(error) {
    return {
        type: ERROR_PREGUNTAS,
        error: error,
    }
}

function fetchPreguntas(idEvento) {
    return dispatch => {
        dispatch(requestPreguntas());
        return preguntas.getAll(idEvento)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePreguntas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPreguntas(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        dispatch(errorPreguntas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPreguntas(state) {
    const preguntas = state.preguntas.byId;
    if (!preguntas) {
        return true
    } else if (preguntas.isFetching) {
        return false
    } else {
        return preguntas.didInvalidate;
    }
}

export function fetchPreguntasIfNeeded(idEvento) {
    return (dispatch, getState) => {
        if (shouldFetchPreguntas(getState())) {
            return dispatch(fetchPreguntas(idEvento))
        }
    }
}


//PREGUNTA CREATE
export const CREATE_PREGUNTA = 'CREATE_PREGUNTA';
export const REQUEST_CREATE_PREGUNTA = "REQUEST_CREATE_PREGUNTA";
export const SUCCESS_CREATE_PREGUNTA = "SUCCESS_CREATE_PREGUNTA";
export const ERROR_CREATE_PREGUNTA = "ERROR_CREATE_PREGUNTA";
export const RESET_CREATE_PREGUNTA = "RESET_CREATE_PREGUNTA";


//CREATE
function requestCreatePregunta() {
    return {
        type: REQUEST_CREATE_PREGUNTA,
    }
}

function receiveCreatePregunta(message) {
    return {
        type: SUCCESS_CREATE_PREGUNTA,
        receivedAt: Date.now(),
        message: message
    }
}

export function errorCreatePregunta(error) {
    return {
        type: ERROR_CREATE_PREGUNTA,
        error: error,
    }
}

export function resetCreatePregunta() {
    return {
        type: RESET_CREATE_PREGUNTA
    }
}

export function createPregunta(pregunta) {
    return {
        type: CREATE_PREGUNTA,
        pregunta
    }
}

export function saveCreatePregunta(idEvento) {
    return (dispatch, getState) => {
        dispatch(requestCreatePregunta());
        return preguntas.saveCreate(idEvento,getState().preguntas.create.nuevo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    dispatch(receiveCreatePregunta());
                    dispatch(resetCreatePregunta());
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePregunta(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error != "")
                                    dispatch(errorCreatePregunta(error));
                                else
                                    dispatch(errorCreatePregunta(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorCreatePregunta(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//PREGUNTA JUGA
export const JUGA_PREGUNTA = 'JUGA_PREGUNTA';
export const REQUEST_JUGA_PREGUNTA = "REQUEST_JUGA_PREGUNTA";
export const SUCCESS_JUGA_PREGUNTA = "SUCCESS_JUGA_PREGUNTA";
export const ERROR_JUGA_PREGUNTA = "ERROR_JUGA_PREGUNTA";
export const RESET_JUGA_PREGUNTA = "RESET_JUGA_PREGUNTA";


//JUGA
function requestJugaPregunta() {
    return {
        type: REQUEST_JUGA_PREGUNTA,
    }
}

function receiveJugaPregunta(message) {
    return {
        type: SUCCESS_JUGA_PREGUNTA,
        receivedAt: Date.now(),
        message: message
    }
}

export function errorJugaPregunta(error) {
    return {
        type: ERROR_JUGA_PREGUNTA,
        error: error,
    }
}

export function resetJugaPregunta() {
    return {
        type: RESET_JUGA_PREGUNTA
    }
}

export function jugaPregunta(juga) {
    return {
        type: JUGA_PREGUNTA,
        juga
    }
}

export function saveJugaPregunta(idEvento, dni) {
    return (dispatch, getState) => {
        dispatch(requestJugaPregunta());
        return preguntas.juga(idEvento, dni)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    response.json().then(
                        response => {
                            dispatch(receiveJugaPregunta(response.message));
                            return response;
                        }
                    )

                }
            })
            .then((pregunta) => {
                // setTimeout(() => {
                //     dispatch(resetJugaPregunta())
                // }, 10000)
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorJugaPregunta(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error != "")
                                    dispatch(errorJugaPregunta(error));
                                else
                                    dispatch(errorJugaPregunta(errorMessages.GENERAL_ERROR));
                            })
                            .catch((error) => {
                                dispatch(errorJugaPregunta(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}
