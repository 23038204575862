import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom'
import {Col, OverlayTrigger, Row, Tooltip, Button} from 'react-bootstrap';
import history from '../../history';

//CSS
import '../../assets/css/Cuentas.css';

//Images
import help from '../../assets/img/iconLista/help2.png';
import spinner from '../../assets/img/spinner.gif';
import emptyLogo from '../../assets/img/iconLista/empty-logo.png';
import iconoListaPrecio from "../../assets/img/cuentas/iconoListaPrecios2.png";
import flechitaIzquierda from "../../assets/img/cuentas/flechitaIzquierda.png";
import lineaVertical from "../../assets/img/cuentas/lineaConPuntito.png";
import lineaVerticalDer from "../../assets/img/cuentas/linea.png";
import logoKit from "../../assets/img/cuentas/logoKit.png";

//Actions
import {
    imprimirListaPrecios,
    fetchFechaListaPrecioIfNeeded
} from "../../actions/ListaPreciosActions";

//Components
import DatosUsuario from "../../components/elementos/basicos/DatosUsuario";

//Constants
import c from '../../constants/constants';

class Cuentas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false,
        };
    }

    render() {
        let cuenta = null;
        let accesoCuenta = null;
        let toolsAndAcounts = this.props.cuentas.allIds.map((idCuenta) => {
            cuenta = this.props.cuentas.byId.cuentas[idCuenta];
            let herramienta = [];
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta.idUsuario == this.props.usuarios.byId.usuarioLogueado.id && accesoCuenta.idCuenta == idCuenta) {
                    let habilitacion = null;
                    let herramientaData = null;
                    accesoCuenta.habilitacion.map((idHabilitacion) => {
                        habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        herramientaData = this.props.herramientas.byId.herramientas[habilitacion.idHerramienta];
                        if (habilitacion.estado == 1)
                            if (herramientaData.externo === 0)
                                herramienta.push(
                                    <Link to={cuenta ? herramientaData.toHerramienta + cuenta.id : ""}>
                                        <img className="imagenHerramienta"
                                             src={process.env.PUBLIC_URL + "/img/tools/" + herramientaData.imgHerramienta}
                                             alt={herramientaData.desHerramienta}/>
                                    </Link>
                                );
                            else
                                herramienta.push(
                                    <a
                                        href={`${c.EXTERNAL_URL}${herramientaData.toHerramienta}${idCuenta}`}
                                        target={"_blank"}
                                    >
                                        <img className="imagenHerramienta"
                                             src={process.env.PUBLIC_URL + "/img/tools/" + herramientaData.imgHerramienta}
                                             alt={herramientaData.desHerramienta}/>
                                    </a>
                                );

                    });
                }
            });
            return (
                <Row>
                    <img className="imgLineaVertical" src={lineaVertical}/>
                    <img className="flechitaIzquierda" src={flechitaIzquierda}/>
                    <h3 className="cuentaAliasEIdentificacion">{cuenta ? cuenta.alias + ", " + cuenta.identificacion : ""}</h3>
                    <div className="iconosHerramientas">
                        {herramienta}
                    </div>
                </Row>
            )
        });

        return (
            <div className="container-fluid cuentas">
                <section id="cuentas">
                    <div className="cointainer-fluid">
                        <div className="maxdiv">
                            <Row className="mleft9">

                                <img className="logoKit" src={logoKit} onClick={() => {
                                    history.push("/")
                                }}/>
                                <Row>
                                    <Col md={8} sm={8} xs={12}>
                                        <h1 className="titleCuentas">Cuentas</h1>
                                        {toolsAndAcounts ? toolsAndAcounts : "No hay cuentas asociadas."}
                                    </Col>
                                    <Col md={4} sm={4} xsHidden className="opciones"
                                         style={{textAlign: "right", paddingRight: "5%"}}>
                                        <img className="imgLineaVerticalDer" src={lineaVerticalDer}/>
                                        <DatosUsuario mostrarLogo={true}/>
                                    </Col>
                                </Row>
                            </Row>
                        </div>
                    </div>
                </section>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        accesosCuenta: state.accesosCuenta,
        listaPrecio: state.listaPrecio,
        usuarios: state.usuarios,
        cuentas: state.cuentas,
        herramientas: state.herramientas,
        habilitaciones: state.habilitaciones,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        imprimirListaPrecios: () => {
            dispatch(imprimirListaPrecios())
        },
        fetchFechaListaPrecioIfNeeded: () => {
            dispatch(fetchFechaListaPrecioIfNeeded());
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cuentas));
