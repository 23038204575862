import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, Redirect,Switch} from 'react-router-dom'
import {NavLink, Link} from 'react-router-dom';
import {Navbar, Nav, NavItem, NavDropdown, MenuItem} from "react-bootstrap";

//CSS
import '../../assets/css/Cuentas.css';

//Actions
import {fetchUsuarioLogueadoIfNeeded} from "../../actions/UsuarioActions";
import {invalidateCategorias} from "../../actions/CategoriaActions";
import {fetchAccesosCuentaIfNeeded} from "../../actions/AccesosCuentaActions";
import {logout} from "../../actions/AuthenticationActions";

//Components
import ListaPrecios from "../plataforma/herramientas/ListaPrecios/ListaPrecios";
import Cuentas from "../paginas/Cuentas";
import OpenSafe from "./herramientas/OpenSafe/OpenSafe";
import MonitorPedidos from "./herramientas/MonitorPedidos/MonitorPedidos";
import ModificarPedido from "./herramientas/MonitorPedidos/ModificarPedido";
import CuentaYOrden from "./herramientas/MonitorPedidos/CuentaYOrden";
import {PrivateRoute} from "../../components/layout/PrivateRoute";
import DetallesPedido from "./herramientas/MonitorPedidos/DetallesPedido";
import Bultos from "./herramientas/MonitorPedidos/Bultos";
import Landing from "./herramientas/Landing/Landing";
import BotonPago from "./herramientas/BotonPago/BotonPago";
import MonitorPagos from "./herramientas/BotonPago/MonitorPagos";
import ListasMonitoresPago from "./herramientas/BotonPago/ListasMonitoresPagos";
import LinkPagoRecurrenteCliente from "./herramientas/BotonPago/LinkPagoRecurrenteCliente";


class Plataforma extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            dejoDeBuscarPedidos: false,
        };
    }

    onToggle() {
        this.setState({open: !this.state.open});
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.authentication.token) {
            this.props.fetchUsuarioLogueadoIfNeeded();
        }
        this.props.invalidateCategorias();
        this.props.fetchAccesosCuentaIfNeeded();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pedidos.byId.isFetching != this.props.pedidos.byId.isFetching && !this.props.pedidos.byId.isFetching) {
            this.setState({dejoDeBuscarPedidos: true});
        }
    }

    render() {
        return (
            <div className="plataforma">
                <div className="page-main container-fluid">
                    <Navbar onToggle={(e) => this.onToggle()}
                            expanded={this.state.open}
                            collapseOnSelect>
                        <Navbar.Header>
                            <Navbar.Toggle/>
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <Nav>
                                <li className="hidden-sm hidden-md hidden-lg"
                                    style={{display: this.props.authentication.token ? "block" : "none"}}>
                                    <NavLink activeClassName="active" to="/cambiarPassword">CAMBIAR CONTRASEÑA</NavLink>
                                </li>
                                <li className="hidden-sm hidden-md hidden-lg"
                                    style={{display: this.props.authentication.token ? "block" : "none"}}>
                                    <a onClick={this.props.logout}>CERRAR SESION</a>
                                </li>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <Switch>
                        <Route path="/panel/cuentas" exact component={Cuentas}/>
                        <Route path="/panel/pagos/nuevo/:idTipoPago" component={BotonPago}/>
                        <Route path="/panel/pagos/:idPago/:idTipoPago?" component={BotonPago}/>
                        <Route path="/panel/linksPagos/nuevo" component={LinkPagoRecurrenteCliente}/>
                        <Route path="/panel/linksPagos/:idLinkPago" component={LinkPagoRecurrenteCliente}/>
                        {/*<Route path="/panel/monitorPagos/:idCuenta" component={MonitorPagos}/>*/}
                        <Route path="/panel/listaMonitoresPagos/:idCuenta" component={ListasMonitoresPago}/>
                        <Route path="/panel/listaPrecios" component={ListaPrecios}/>
                        <Route path="/panel/openSafe/:idCuenta"component={OpenSafe}/>
                        <PrivateRoute path="/panel/monitorPedidos/:idCuenta/modificarPedido/:idPedido"
                                      pedidos={this.props.pedidos}
                                      exact component={ModificarPedido}/>
                        <PrivateRoute path="/panel/monitorPedidos/:idCuenta/modificarPrecioPedido/:idPedido"
                                      pedidos={this.props.pedidos}
                                      dejoDeBuscarPedidos={this.state.dejoDeBuscarPedidos}
                                      exact component={CuentaYOrden}/>
                        <PrivateRoute path="/panel/monitorPedidos/:idCuenta/verPedido/:idPedido"
                                      pedidos={this.props.pedidos}
                                      exact component={DetallesPedido}/>
                        <PrivateRoute path="/panel/monitorPedidos/:idCuenta/bultos/:idPedido"
                                      pedidos={this.props.pedidos}
                                      exact component={Bultos}/>
                        <Route path="/panel/monitorPedidos/:idCuenta" exact component={MonitorPedidos}/>
                        <Route path="/panel/landing/:idCuenta" exact component={Landing}/>
                    </Switch>
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
        pedidos: state.pedidos,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout())
        },
        fetchUsuarioLogueadoIfNeeded: () => {
            dispatch(fetchUsuarioLogueadoIfNeeded());
        },
        fetchAccesosCuentaIfNeeded: (idCuenta, idUsuario) => {
            dispatch(fetchAccesosCuentaIfNeeded(idCuenta, idUsuario))
        },
        invalidateCategorias: () => {
            dispatch(invalidateCategorias())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plataforma));
