import React from 'react';
import {Table, Row, Col, Button, FormControl} from 'react-bootstrap';
import {withRouter, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import history from "../../../../history";

//CSS
import '../../../../assets/css/Checkout.css';

//Components
import ProgesoCompra from "./ProgesoCompra";
import ResumenCarrito from "./ResumenCarrito";
import DatosEntrega from "./DatosEntrega";
// import Pago from "./Compra/Pago";
// import CompraExitosa from "./Compra/CompraExitosa";

//Images
import logoKit from "../../../../assets/img/cuentas/logoKit.png";
import inicio from "../../../../assets/img/monitorPedidos/inicio.png";

class ModalPago extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            numero: 1,
        }
    }

    componentDidMount() {
        if (!this.props.pedidos.create.nuevo.detalle_pedido_mon || this.props.pedidos.create.nuevo.detalle_pedido_mon.length == 0)
            this.props.history.push("/");
    }

    render() {
        return (
            <div className="checkout">
                <Row>
                    <Col xs={10} sm={10} md={10} lg={10} xsOffset={1}>
                        <div className="inicioKit">
                            <img className="logoKit" src={logoKit} onClick={() => {
                                history.push("/");
                            }}/>
                            <img src={inicio} className="inicio" onClick={() => {
                                history.push('/panel/cuentas');
                            }}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <ProgesoCompra numero={this.props.match.params.paso}/>
                    <Col xs={12}>
                        <div className="datosPaso">
                            <Switch>
                                <Route path="/panel/listaPrecios/checkout/1" component={ResumenCarrito}/>
                                <Route path="/panel/listaPrecios/checkout/2" component={DatosEntrega}/>
                                {/*<Route path="/comprar/2" exact component={DatosFacturacion}/>*/}
                                {/*<Route path="/comprar/3" exact component={Pago}/>*/}
                                {/*<Route path="/comprar/4" exact component={CompraExitosa}/>*/}
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        pedidos: state.pedidos,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {};
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalPago));
