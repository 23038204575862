import c from "../constants/constants";

require('es6-promise').polyfill();
require('isomorphic-fetch');

var preciosProductos = {

    getLista(idLista, idHabilitacion) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + "/preciosProducto/" + idLista + "/" + idHabilitacion, defaultOptions);
    }
};

export default preciosProductos;