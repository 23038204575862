import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter, Route} from "react-router-dom";
import {Switch} from 'react-router';

import siera from '../../../assets/img/evento/siera.png';
import kit from '../../../assets/img/evento/kit.png';

import '../../../assets/css/CheckInSiera.css';
import FormCheckIn from "./FormCheckIn";
import ErrorCheckIn from './ErrorCheckIn';
import OkCheckIn from './OkCheckIn';

class CheckInSiera extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="checkin">
                <div className="container-limit">
                    <div className="header">
                        <img src={siera} style={{paddingTop: "10px"}} alt=""/>
                        <img src={kit} className="pull-right" alt=""/>
                    </div>
                    <Row>
                        <Col md={12} className="center-block">

                            <Switch>
                                <Route path="/checkin" exact component={FormCheckIn}/>
                                <Route path="/checkin/ok"  component={OkCheckIn}/>
                                <Route path="/checkin/error"  component={ErrorCheckIn}/>
                            </Switch>

                        </Col>
                    </Row>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}


const mapDispatchToProps = (dispatch) => {
    return {

    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckInSiera));
