import React, {Component} from 'react';
import {Row, Col, Breadcrumb, Table} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import history from "../../history";

//CSS
import '../../assets/css/ProductoDetalle.css';
import 'react-image-gallery/styles/css/image-gallery.css';

//Componentes
import Footer2 from '../layout/Footer2';
import OtrosProductos from "../elementos/productos/OtrosProductos";
import SubBarra from '../layout/SubBarra';
import ModalRegistro from "../../components/elementos/basicos/ModalRegistro";

//Imagenes
import bannerCompraNoLogueado from '../../assets/img/conTarjeta.png';
import bannerCompraLogueado from "../../assets/img/bannerCompraProfesionalLogueado.jpg";
import mail from '../../assets/img/mailBig.png';
import face from '../../assets/img/faceBig.png';
import entrega from "../../assets/img/entregasPais.png";
import kitLogo from '../../assets/img/kitLogo.png';
import Loader from "../../components/elementos/loader/Loader";
import pacman from "../../assets/img/productos/pacman.png";
import imgEsInstaladorIntegrador from "../../assets/img/productos/esIntegrador.png";
import wsp from '../../assets/img/whatsapp.png';
import tool from '../../assets/img/logoHerramienta.png';
import verPrecio from "../../assets/img/verPrecio.png";


//Actions
import {fetchCategoriasIfNeeded} from "../../actions/CategoriaActions";
import {fetchListaPreciosIfNeeded} from "../../actions/ListaPreciosActions";
import {
    fetchPreciosProductoIfNeeded,
    fetchPreciosProductoConsumidorIfNeeded
} from "../../actions/PreciosProductoActions";
import * as listas from "../../constants/ListasPrecios";
import * as herramientas from "../../constants/Herramientas";
import auth from "../../api/authentication";
import * as roles from "../../constants/Roles";
import * as rendimiento from "../../constants/Rendimiento";

//Constants
import * as cuentas from "../../constants/Cuentas";

class ProductoDetalle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showTrackingModal: false,
        }
    }

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
        window.scrollTo(0, 0);

        if (this.props.authentication.token)
            this.props.fetchPreciosProductoConsumidorIfNeeded(listas.LISTA_DEFAULT, null);
        this.props.fetchListaPreciosIfNeeded();

        if (Object.keys(this.props.accesosCuenta.byId.accesosCuenta).length > 0) {
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta.idCuenta == cuentas.LISTA_PRECIOS_ID && accesoCuenta.idUsuario == auth.idUsuario()) {
                    accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                        var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                            if (habilitacion.listaDefault) {
                                this.props.fetchPreciosProductoIfNeeded(habilitacion.listaDefault, habilitacion.id);
                            } else if (this.props.listaPrecio.allIds.length) {
                                this.props.fetchPreciosProductoIfNeeded(this.props.listaPrecio.allIds[0], habilitacion.id);
                            }
                        }
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params["idProducto"] != prevProps.match.params["idProducto"])
            window.scrollTo(0, 0);

        if ((prevProps.listaPrecio.byId.listaPrecio != this.props.listaPrecio.byId.listaPrecio || prevProps.accesosCuenta.byId.accesosCuenta != this.props.accesosCuenta.byId.accesosCuenta) && Object.keys(this.props.listaPrecio.byId.listaPrecio).length) {
            this.props.accesosCuenta.allIds.map((idAccesoCuenta) => {
                var accesoCuenta = this.props.accesosCuenta.byId.accesosCuenta[idAccesoCuenta];
                if (accesoCuenta.idUsuario == auth.idUsuario()) {
                    accesoCuenta.habilitacion.forEach((idHabilitacion) => {
                        var habilitacion = this.props.habilitaciones.byId.habilitaciones[idHabilitacion];
                        if (habilitacion && habilitacion.idHerramienta == herramientas.LISTA_PRECIOS_ID) {
                            var listaDef = null;
                            if (habilitacion.listaDefault) {
                                listaDef = habilitacion.listaDefault;
                            } else if (this.props.listaPrecio.allIds.length) {
                                listaDef = this.props.listaPrecio.allIds[0];
                            }
                            this.props.fetchPreciosProductoIfNeeded(listaDef, habilitacion.id);
                        }
                    });
                }
            });
        }
    }

    openCloseTrackingModal() {
        this.setState({showTrackingModal: !this.state.showTrackingModal})
    }

    verPrecio() {
        if (this.props.authentication.token) {
            let idProducto = this.props.match.params["idProducto"];
            if (idProducto)
                var producto = this.props.productos.byId.productos[idProducto];
            history.push("/panel/listaPrecios/1/producto/" + producto.nomProducto.replace(/\//ig, ""));
        } else {
            this.openCloseTrackingModal();
        }
    }

    render() {
        const {history} = this.props;
        var path = kitLogo;
        var path2 = kitLogo;
        var path3 = kitLogo;
        var path4 = kitLogo;
        var images = [];

        var esInstaladorIntegrador = roles.INSTALADOR_INTEGRADOR_ID == this.props.usuarios.byId.usuarioLogueado.idRol;

        //producto
        let idProducto = this.props.match.params["idProducto"];
        if (idProducto)
            var producto = this.props.productos.byId.productos[idProducto];

        var hayMasImagenes = false;
        if (producto) {
            var categoria = this.props.categorias.byId.categorias[producto.idCategoria];
            var subCategoria = this.props.subCategorias.byId.subCategorias[producto.idSubCategoria];
            //Imagenes
            try {
                if (producto) {
                    if (producto.img2) {
                        console.log("entre a ESTE IF TENGO IMAGEN 2!");
                        path2 = process.env.PUBLIC_URL + "/img/productos/" + producto.img2;
                        images.push({original: path2, thumbnail: path2});
                        hayMasImagenes = true;

                    }
                    if (producto.img3) {
                        path3 = process.env.PUBLIC_URL + "/img/productos/" + producto.img3;
                        images.push({original: path3, thumbnail: path3});
                        hayMasImagenes = true;
                    }
                    if (producto.img4) {
                        path4 = process.env.PUBLIC_URL + "/img/productos/" + producto.img4;
                        images.push({original: path4, thumbnail: path4});
                        hayMasImagenes = true;
                    }
                    if (producto.img1) {
                        console.log("entre a ESTE IF TENGO IMAGEN 1!");
                        path = process.env.PUBLIC_URL + "/img/productos/" + producto.img1;
                        images.push({original: path, thumbnail: path});
                    }
                }
            } catch (e) {
            }
            //Precio
            var precioVigente = 0;
            var precioViejo = null;
            var precioProducto = null;
            if (!this.props.authentication.token || this.props.usuarios.byId.usuarioLogueado.idRol != roles.INSTALADOR_INTEGRADOR_ID) {
                precioProducto = this.props.preciosProducto.byId.preciosProductoConsumidor[producto.id.toString()];
                if (precioProducto && precioProducto.precio) {
                    precioVigente = precioProducto.precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                }
            } else if (this.props.usuarios.byId.usuarioLogueado.idRol == roles.INSTALADOR_INTEGRADOR_ID) {
                precioProducto = this.props.preciosProducto.byId.preciosProducto[producto.id.toString()];
                if (precioProducto && precioProducto.precio) {
                    precioVigente = precioProducto.precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                }
                var precioProductoViejo = this.props.preciosProducto.byId.preciosProductoConsumidor[producto.id.toString()];
                if (precioProductoViejo && precioProductoViejo.precio) {
                    precioViejo = precioProductoViejo.precio.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
                }
            }

            //Marca
            var marca = this.props.marcas.byId.marcas[producto.idMarca];
            var pathmarca = null;
            if (marca && marca.pathImagen)
                try {
                    const svg = require("!raw-loader!../../assets/img/marcas/" + marca.pathImagen);
                    if (svg)
                        pathmarca = <span className="marca" dangerouslySetInnerHTML={{__html: svg}}/>;
                } catch (e) {
                }

            var tablaKits = [];
            var productosKit = [];
            tablaKits.push(
                <Table className="tablaResumen" responsive>
                    <thead className="tableHead">
                    <th>Cod</th>
                    <th className="tableColumnNombre">Nombre Producto</th>
                    <th>Cantidad</th>
                    </thead>
                    <tbody>
                    {productosKit}
                    </tbody>
                </Table>
            );
            if (producto.esKit) {
                this.props.kits.allIds.map((idKit) => {
                    var kit = this.props.kits.byId.kits[idKit];
                    if (kit) {
                        var productoKit = this.props.productos.byId.productos[kit.idProducto];
                        if (productoKit && kit.idProductoPadre == producto.id) {
                            productosKit.push(
                                <tr key={productoKit.id} className="columnaProductosKit">
                                    <td>{productoKit.vd_idProducto}</td>
                                    <td className="tableColumnNombre">{productoKit.nomProducto}</td>
                                    <td className="tableColumnCantidad">{kit.cantidad}</td>
                                </tr>
                            );
                        }
                    }
                });
            }
        }

        //Obtengo moneda
        var lista = this.props.listaPrecio.byId.listaPrecio[listas.LISTA_DEFAULT];
        var simbolo = '$';
        if (lista) {
            simbolo = lista.moneda.simbolo;
        }

        var descuento = null;
        if (precioViejo && precioVigente) {
            descuento = ((precioViejo / precioVigente) - 1) * 100;
            descuento = descuento.toFixed(0);

        }

        return (
            <div className="contenedorProductoDetalle">
                <SubBarra logueado={this.props.authentication.token}/>
                <section className="container-limit producto-detalle">
                    <div style={{
                        display: this.props.categorias.byId.isFetching ? "inline" : "none",
                        color: "white",
                        textAlign: "center",
                        padding: "100px"
                    }}>
                        <Loader/>
                    </div>
                    <div style={{
                        display: !this.props.categorias.byId.isFetching && !producto ? "block" : "none",
                        color: "white",
                        textAlign: "center",
                        padding: "20px"
                    }}>
                        No existe producto
                    </div>
                    <div className="container-limit"
                         style={{display: !this.props.categorias.byId.isFetching && producto ? "inline" : "none"}}>
                        <br/>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => {
                                history.push('/')
                            }}>
                                Inicio
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => {
                                history.push('/productos/')
                            }}>
                                productos
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => {
                                history.push("/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase())
                            }}>
                                {categoria ? categoria.desCategoria : ""}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => {
                                history.push("/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase() + "/" + subCategoria.desSubCategoria.replace(/\s/g, "").toLowerCase())
                            }}>
                                {subCategoria ? subCategoria.desSubCategoria : ""}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {producto ? producto.nomProducto : ""}
                            </Breadcrumb.Item>
                        </Breadcrumb>

                        <Row className="datosProducto">
                            <Col xs={12} sm={6} className="columnaInfoProductos">
                                <Row className="itemTitulo">
                                    <h1>{producto ? producto.nomProducto : ""}</h1>
                                    <p className="codigo">CÓDIGO <span>{producto ? producto.vd_idProducto : ""}</span>
                                    </p>
                                </Row>
                                <Row className="itemMarca">
                                    <Col xs={4} className="marca sin-padding">
                                        {pathmarca}
                                        {/*<img className="img-responsive" src={pathmarca} alt=""/>*/}
                                        <h2 className="sinMarca"
                                            style={{display: !pathmarca ? "inline" : "none"}}>{marca ? marca.desMarca : ""}</h2>
                                    </Col>
                                    <Col xs={8} className="verPrecio" onClick={() => this.verPrecio()}>
                                        <img src={verPrecio} alt=""/>
                                    </Col>
                                </Row>
                                <Row className="esIntegrador" onClick={() => {
                                    history.push("/registrar")
                                }}>
                                    <img src={imgEsInstaladorIntegrador}
                                         style={{display: this.props.authentication.token ? "none" : "block"}}/>
                                </Row>
                            </Col>
                            <Col xs={12} sm={6} className="columnaFoto"
                                 style={{marginBottom: hayMasImagenes ? "0" : "135px"}}>
                                <div className="img-galeria">
                                    {/*<div className="contenedorDescuento"*/}
                                    {/*     style={{display: esInstaladorIntegrador && descuento >= rendimiento.RENDIMIENTO ? (this.props.preciosProducto.byId.isFetchingConsumidor || this.props.preciosProducto.byId.isFetching ? "none" : "block") : "none"}}>*/}
                                    {/*    <div className="descuento" style={{zIndex: "2"}}>*/}
                                    {/*        <img src={pacman}/>*/}
                                    {/*        <p>{descuento ? descuento : 0} %</p>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <ImageGallery
                                        style={{zIndex: "1"}}
                                        items={images}
                                        slideInterval={2000}
                                        onImageLoad={this.handleImageLoad}
                                        showNav={false}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        showBullets={false}
                                        defaultImage={kitLogo}
                                        showThumbnails={hayMasImagenes}
                                    />
                                    <Row className="itemMarcaXS">
                                        <Col xs={6} className="marca sin-padding">
                                            {pathmarca}
                                            {/*<img className="img-responsive" src={pathmarca} alt=""/>*/}
                                            <h2 className="sinMarca"
                                                style={{display: !pathmarca ? "inline" : "none"}}>{marca ? marca.desMarca : ""}</h2>
                                        </Col>
                                        <Col xs={6} className="verPrecioXS" onClick={() => this.verPrecio()}>
                                            <img src={verPrecio} alt=""/>
                                        </Col>
                                    </Row>
                                    <Row className="esIntegradorXS" onClick={() => {
                                        history.push("/registrar")
                                    }}>
                                        <img src={imgEsInstaladorIntegrador}
                                             style={{display: this.props.authentication.token ? "none" : "block"}}/>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="descripcion">
                                    <p>
                                        {producto ? (producto.esKit ? tablaKits : producto.desProducto) : ""}
                                    </p>
                                </div>

                            </Col>
                            <Col md={6} className="banner">
                                <img className="img-responsive"
                                     src={this.props.authentication.token ? bannerCompraLogueado : bannerCompraNoLogueado}
                                     alt=""/>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="dudas">
                    <div className="container-limit contenedorDudas">
                        <div className="item">
                            <h3><b style={{fontWeight: 300}}>¿Tenés dudas?</b> Consultá a uno de nuestros asesores.
                            </h3>
                        </div>
                        <div className="item mediaLinks">
                            <a href="mailto: kitexperto@kitexperto.com" target="_blank">
                                <img src={mail} alt="" style={{marginRight: "20px"}}/>
                            </a>
                            <a href="http://www.facebook.com/kitexperto" target="_blank">
                                <img src={face} alt="" style={{marginRight: "20px"}}/>
                            </a>
                            <a href={"https://wa.me/5493416524727"} target="_blank">
                                <img src={wsp} alt="" style={{marginRight: "20px"}}/>
                            </a>
                        </div>
                    </div>
                </section>

                <OtrosProductos categoria={categoria} subcategoria={subCategoria}/>

                <section className="entrega">
                    <img src={entrega} className="img-responsive" alt="" style={{borderBottom: "4px solid #00ffea"}}/>
                </section>

                <Footer2/>

                <ModalRegistro show={this.state.showTrackingModal}
                               cerrar={this.openCloseTrackingModal.bind(this)}
                               className="modal-tracking"/>

            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias,
        subCategorias: state.subCategorias,
        kits: state.kits,
        authentication: state.authentication,
        preciosProducto: state.preciosProducto,
        accesosCuenta: state.accesosCuenta,
        listaPrecio: state.listaPrecio,
        habilitaciones: state.habilitaciones,
        usuarios: state.usuarios,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        },
        fetchPreciosProductoIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoIfNeeded(idLista, idHabilitacion))
        },
        fetchListaPreciosIfNeeded: (idLista) => {
            dispatch(fetchListaPreciosIfNeeded(idLista))
        },
        fetchPreciosProductoConsumidorIfNeeded: (idLista, idHabilitacion) => {
            dispatch(fetchPreciosProductoConsumidorIfNeeded(idLista, idHabilitacion))
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoDetalle));