import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import "./MonitorPagos.css";
//lodash
import {resetLogoPago} from "../../../../actions/PagoActions";
import {Glyphicon, Row, Table} from "react-bootstrap";
//Constants
import * as EstadoPago from "../../../../constants/EstadoPago"
import moment from "moment";
import max from "lodash/max";

import LinkGenerado from "../../../../assets/img/pago/linkPago.png"
import * as moneda from "../../../../constants/Moneda";
import agregar from "../../../../assets/img/pago/agregar.png";
import {resetUsuarios} from "../../../../actions/UsuarioActions";
import {
    fetchLinksPagosIfNeeded,
    invalidateLinksPagos,
    resetCreateLinkPago,
    resetLinksPagos,
    resetUpdateLinkPago
} from "../../../../actions/LinkPagoActions";
import c from "../../../../constants/constants";


export default function MonitorLinksPagos(props) {
    const dispatch = useDispatch();
    const mounted = useRef();
    const timer = useRef();
    //Store
    const usuarios = useSelector(state => state.usuarios);
    const estadosPago = useSelector(state => state.estadosPago);
    const clientes = useSelector(state => state.clientes);
    const linksPagos = useSelector(state => state.linksPagos);

    let totalLinksPagos = linksPagos.totalLinksPagos;
    const totalPaginas = Math.ceil(totalLinksPagos ? totalLinksPagos / 50 : 0);

    //State
    const [search, setSearch] = useState("");

    const params = new URLSearchParams(props.location.search);
    const order = params.get("order") ? params.get("order") : "fechaCreacion";
    const direction = params.get("direction") ? params.get("direction") : "desc";
    const page = params.get("page") ? params.get("page") : "1";


    //Hooks
    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        let filtros = {
            searchPhase: params.get("sp") ? params.get("sp") : "",
            order: params.get("order") ? params.get("order") : "fechaCreacion",
            direction: params.get("direction") ? params.get("direction") : "desc",
            page: params.get("page") ? params.get("page") : "1",
            acreditados: params.get("acreditados") ? params.get("acreditados") : "",
        };
        if (filtros.searchPhase != search) {
            setSearch(filtros.searchPhase);
        }
        dispatch(fetchLinksPagosIfNeeded(filtros));

        return function cleanup() {
            dispatch(resetLinksPagos());
            dispatch(resetCreateLinkPago());
            dispatch(resetUpdateLinkPago());
            dispatch(resetUsuarios());
            dispatch(resetLogoPago());
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            let filtros = {
                searchPhase: params.get("sp") ? params.get("sp") : "",
                order: params.get("order") ? params.get("order") : "fechaCreacion",
                direction: params.get("direction") ? params.get("direction") : "desc",
                page: params.get("page") ? params.get("page") : "1",
                acreditados: params.get("acreditados") ? params.get("acreditados") : "",
            };

            dispatch(resetLinksPagos());
            dispatch(invalidateLinksPagos());
            dispatch(fetchLinksPagosIfNeeded(filtros));
        }
    }, [props.location.search]);

    const onClickBoton = (idTipoPago) => {
        props.history.push("/panel/pagos/nuevo/" + idTipoPago);
    };

    const changeOrder = (or) => {
        params.set("order", or ? or : "");
        let direction = params.get("direction");
        params.set("direction", direction === "asc" ? "desc" : "asc");
        props.history.push("/panel/listaMonitoresPagos/1/monitorLinksPagos?" + params.toString());
    };

    const changeAcreditados = (acred) => {
        params.delete("page");
        params.set("acreditados", acred ? acred : "");
        props.history.push("/panel/listaMonitoresPagos/1/monitorLinksPagos?" + params.toString());
    };

    const WAIT_INTERVAL = 500;

    const changeSearch = (e) => {

        clearTimeout(timer.current);
        let valor = e.target.value;
        setSearch(e.target.value);

        timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            clearTimeout(timer.current);
            triggerChange();
        }
    };

    const triggerChange = (searchPhase) => {
        if ((!searchPhase && !search) || (searchPhase === "")) {
            params.delete("sp");
            props.history.push("/panel/listaMonitoresPagos/1/monitorLinksPagos?" + params.toString());
        } else {
            params.delete("page");
            params.set("sp", searchPhase ? searchPhase : search);
            props.history.push("/panel/listaMonitoresPagos/1/monitorLinksPagos?" + params.toString());
        }
    };

    //Filas tabla pedidos
    var array = [1, 2, 3, 4];
    var Filas =
        linksPagos.byId.isFetching && linksPagos.allIds.length === 0 ?
            (array.map(function (array, index) {
                    return (<tr key={index}>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                        <th><p className="parrafoInvisible"></p></th>
                    </tr>)
                })

            )
            :
            linksPagos.allIds.map(function (idLinkPago) {
                let linkPago = linksPagos.byId.linksPagos[idLinkPago];
                let usuario = usuarios.byId.usuarios[linkPago.idUsuario];
                let cliente = clientes.byId.clientes[linkPago.idCliente];
                // let medioPago = mediosPago.byId.mediosPago[linkPago.idMedioPago];
                const ultimoEstadoPago = estadosPago.byId.estadosPago[max(linkPago.estados_pago)];
                const calcEstadoActivo = () => {
                    if (ultimoEstadoPago)
                        switch (ultimoEstadoPago.desEstadoPago) {
                            case EstadoPago.LINK_GENERADO:
                                return 0;
                            case EstadoPago.PENDIENTE:
                                return 1;
                            case EstadoPago.CONFIRMADO:
                                return 2;
                            case EstadoPago.CONFIRMADO_DIFERENCIAS:
                                return 3;
                            case EstadoPago.RECHAZADO:
                                return 4;
                            default:
                                return null;

                        }
                };
                return (
                    <tr key={"pago-" + linkPago.id}>
                        <td>{linkPago && linkPago.pathProducto ? <img className={"imgProducto"} src={c.IMG_URL + linkPago.pathProducto}/> : ""}</td>
                        <td>{linkPago && linkPago.referencia ? linkPago.referencia : ""}</td>
                        <td>{linkPago && linkPago.id ? linkPago.id : ""}</td>
                        <td>{linkPago && linkPago.fechaCreacion ? moment(linkPago.fechaCreacion).format("DD-MM-YYYY HH:mm") : ""}</td>
                        <td>{usuario ? usuario.nombre + " " + usuario.apellido : ""}</td>
                        <td>{cliente ? `${cliente.razonSocial} (${cliente.vd_codCliente})` : "-"}</td>
                        <td>{linkPago && linkPago.idMonedaPago ? (linkPago.idMonedaPago === moneda.DOLAR_ID ? "Dólar" : "Peso") : ""}</td>
                        <td>{linkPago && linkPago.importe ? linkPago.importe : ""}</td>
                        <td>{linkPago && linkPago.estado===1 ? "Activo" : "Inactivo"}</td>
                        <td className="verLinkPago">
                            <Link to={`/panel/linksPagos/${linkPago.id}`}>
                                Ver
                            </Link>
                        </td>
                    </tr>
                )

            });

    if (Filas.length === 0)
        Filas.push(
            <tr key="r0">
                <td align="center" colSpan={9}>No se encontraron pagos</td>
            </tr>);

    return (
        <div className="monitor-pagos">
            <div className="container-monitor-pagos">
                <div className="container">
                    <Row>
                        <div className="herramientas" style={{justifyContent: "end"}}>

                            <input id="search" type="text" required
                                   name="search"
                                   placeholder="Buscar..."
                                   value={search ? search : ""}
                                   disabled={props.disabled}
                                   style={{}}
                                   onChange={(e) => changeSearch(e)}
                                   onKeyDown={(e) => handleKeyDown(e)}
                            />
                        </div>
                    </Row>
                    <Row>

                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Imagen</th>
                                <th>Referencia</th>
                                <th onClick={() => changeOrder("id")}>
                                    <b>Número</b>
                                    {order === "id" &&
                                    (direction === "asc" ? <Glyphicon glyph="menu-up" bsSize="large"/> :
                                        <Glyphicon glyph="menu-down" bsSize="large"/>)}
                                </th>
                                <th onClick={() => changeOrder("fechaCreacion")}>
                                    <b>Fecha</b>
                                    {order === "fechaCreacion" &&
                                    (direction === "asc" ? <Glyphicon glyph="menu-up" bsSize="large"/> :
                                        <Glyphicon glyph="menu-down" bsSize="large"/>)}
                                </th>
                                <th>Usuario</th>
                                <th>Cliente</th>
                                <th>Moneda</th>
                                <th>Importe</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {Filas}
                            </tbody>
                        </Table>
                    </Row>
                    {
                        totalPaginas > 0 &&
                        <div className="paginas">
                            {
                                totalPaginas && totalPaginas > 1 &&
                                [...Array(totalPaginas).keys()].map((numero, i) => {
                                    let pagina = numero + 1;
                                    let paramsPagina = new URLSearchParams(props.location.search);
                                    paramsPagina.set("page", pagina ? pagina : "1");
                                    return <Link key={"pag-" + i} to={{
                                        pathname: "/panel/listaMonitoresPagos/1/monitorLinksPagos",
                                        search: "?" + paramsPagina.toString()
                                    }}
                                                 className={"numeroPagina" + ((pagina.toString()) === page || (!page && pagina === 1) ? " " + "active" : "")}
                                                 onClick={() => window.scrollTo(0, 0)}>
                                        {numero + 1}
                                    </Link>
                                })
                            }
                        </div>
                    }
                </div>
                <div className="botonera">
                    <div className="botonera-der">
                        <button className="boton-default outline" onClick={() => onClickBoton(1)}>
                            <img src={LinkGenerado}/>
                            Generar link de pago
                        </button>
                        <button className="boton-default outline" onClick={() => onClickBoton(2)}>
                            <img src={agregar}/>
                            Nuevo pago
                        </button>
                    </div>

                </div>

            </div>
        </div>
    )
        ;
}
