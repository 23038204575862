import {
    RECEIVE_USUARIO_LOGUEADO, RESET_UPDATE_USUARIO_LOGUEADO, RESET_CREATE_USUARIO_LOGUEADO
} from '../actions/UsuarioActions';
import {UPDATE_ENCUESTARESPUESTAS,REQUEST_UPDATE_ENCUESTARESPUESTAS,SUCCESS_UPDATE_ENCUESTARESPUESTAS,ERROR_UPDATE_ENCUESTARESPUESTAS,
CREATE_ENCUESTARESPUESTAS, ERROR_CREATE_ENCUESTARESPUESTAS, REQUEST_CREATE_ENCUESTARESPUESTAS, SUCCESS_CREATE_ENCUESTARESPUESTAS} from '../actions/EncuestaRespuestaActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";
import {LOGOUT_SUCCESS} from "../actions/AuthenticationActions";

function encuestaRespuestasById(state = {
    isFetching: false,
    didInvalidate: true,
    encuestaRespuestas: {}
}, action) {
    switch (action.type) {
        case RECEIVE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                encuestaRespuestas: action.usuario ? action.usuario.entities.encuestaRespuestas : {},
                lastUpdated: action.receivedAt
            });
        case UPDATE_ENCUESTARESPUESTAS:
            var estado = {};
            estado[action.encuestaRespuestas.id] = action.encuestaRespuestas;
            return Object.assign({}, state, {
                isFetching: false,
                encuestaRespuestas: merge({}, state.encuestaRespuestas, estado)
            });
        case CREATE_ENCUESTARESPUESTAS:
            var estado = {};
            estado[action.encuestaRespuestas.id] = action.encuestaRespuestas;
            return Object.assign({}, state, {
                isFetching: false,
                encuestaRespuestas: merge({}, state.encuestaRespuestas, estado)
            });
        case RESET_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                encuestaRespuestas: {},
            });
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                encuestaRespuestas: {},
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                encuestaRespuestas: {},
            });
        default:
            return state
    }
}


function allEncuestaRespuestas(state = [], action) {
    switch (action.type) {
        case RECEIVE_USUARIO_LOGUEADO:
            var encuestaRespuestas = action.usuario ? action.usuario.entities.encuestaRespuestas : {};
            return encuestaRespuestas ? Object.keys(encuestaRespuestas) : [];
        case UPDATE_ENCUESTARESPUESTAS:
            return union(state, [action.encuestaRespuestas.id]);
        case CREATE_ENCUESTARESPUESTAS:
            return union(state, [action.encuestaRespuestas.id]);
        case RESET_CREATE_USUARIO_LOGUEADO:
            return [];
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return [];
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function update(state = {isUpdating: false, encuestaRespuestas: [], error:"" }, action) {
    switch (action.type) {
        case UPDATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isUpdating: false,
                encuestaRespuestas: union(state.encuestaRespuestas, [action.encuestaRespuestas.id])
            });
        case REQUEST_UPDATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isUpdating: false,
                encuestaRespuestas:  []
            });
        case ERROR_UPDATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: false,
                encuestaRespuestas:  [],
                error: null
            });
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isUpdating: false,
                encuestaRespuestas:  [],
                error: null
            });
        default:
            return state
    }
}


function create(state = {isCreating: false, encuestaRespuestas: [], error:"" }, action) {
    switch (action.type) {
        case CREATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isCreating: false,
                encuestaRespuestas: union(state.encuestaRespuestas, [action.encuestaRespuestas.id])
            });
        case REQUEST_CREATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isCreating: true
            });
        case SUCCESS_CREATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isCreating: false,
                encuestaRespuestas:  []
            });
        case ERROR_CREATE_ENCUESTARESPUESTAS:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: false,
                encuestaRespuestas:  [],
                error: null
            });
        case RESET_UPDATE_USUARIO_LOGUEADO:
            return Object.assign({}, state, {
                isCreating: false,
                encuestaRespuestas:  [],
                error: null
            });
        default:
            return state
    }
}

const encuestaRespuestas = combineReducers({
    byId: encuestaRespuestasById,
    allIds: allEncuestaRespuestas,
    update: update,
    create: create
});

export default encuestaRespuestas;