//CREATE MODEL
import clone from "lodash/clone";
import * as errorMessages from "../constants/MessageConstants";
import obtenerTokenDecidir from "../utils/decidir/decidirApi";
import {createPago} from "./PagoActions";

export const CREATE_PAGO = 'CREATE_PAGO';
export const REQUEST_CREATE_PAGO = "REQUEST_CREATE_PAGO";
export const SUCCESS_CREATE_PAGO = "SUCCESS_CREATE_PAGO";
export const ERROR_CREATE_PAGO = "ERROR_CREATE_PAGO";
export const RESET_CREATE_PAGO = "RESET_CREATE_PAGO";

function requestCreatePago() {
    return {
        type: REQUEST_CREATE_PAGO,
    }
}


function errorCreatePago(error) {
    return {
        type: ERROR_CREATE_PAGO,
        error: error,
    }
}

export function saveUpdatePagoDecidir() {
    return (dispatch, getState) => {
        console.log(window)
        dispatch(requestCreatePago());
        let pago = clone(getState().pagos.create.nuevo);
        pago.tarjetaCredito = getState().tarjetasCredito.create.nuevo;
        return obtenerTokenDecidir(pago.tarjetaCredito)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(createPago({idPagoDecidir: response.id, pagarOnline: true}));
                    // dispatch(receiveUpdatePago({}));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePago(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.error) {
                            let errorMsj = {};
                            errorMsj["Pago rechazado"] = [];
                            error.error.forEach((er) => {
                                switch (er.error.type) {
                                    case "invalid_expiry_date":
                                        er.mensaje = "Fecha de vencimiento inválida.";
                                        break;
                                    default:
                                        er.mensaje = "Revise los datos de la tarjeta.";
                                        break;
                                }
                                if (er.mensaje && errorMsj["Pago rechazado"].indexOf(er.mensaje) === -1)
                                    errorMsj["Pago rechazado"].push(er.mensaje);
                            })
                            dispatch(errorCreatePago(errorMsj));
                        } else
                            dispatch(errorCreatePago(errorMessages.GENERAL_ERROR));

                        return Promise.reject(error);
                }
            });
    }
}