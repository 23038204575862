import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom'
import history from "../../../../history";
import $ from "jquery";

import {Table, Tooltip, OverlayTrigger, Button, FormControl, FormGroup, InputGroup, Glyphicon} from "react-bootstrap";

//Components
import HeaderPlataforma from "./HeaderPlataforma";
import Loader from "../../../elementos/loader/Loader";

//CSS
import '../../../../assets/css/MonitorPedidos.css';

//Images
import flechita from "../../../../assets/img/monitorPedidos/flechita.png";
import pedidoNoRecibido from "../../../../assets/img/monitorPedidos/icon-pedidonorecibido.png";
import pedidoNoProcesado from "../../../../assets/img/monitorPedidos/icon-pedidonoprocesado.png";
import pedidoNoEnviado from "../../../../assets/img/monitorPedidos/icon-pedidonoenviado.png";
import pedidoNoRecibidoYConfirmado from "../../../../assets/img/monitorPedidos/icon-pedidonoentregado.png";
import pedidoRecibido from "../../../../assets/img/monitorPedidos/icon-pedidorecibido.png";
import pedidoProcesado from "../../../../assets/img/monitorPedidos/icon-pedidoprocesado.png";
import pedidoParcialmenteProcesado from "../../../../assets/img/monitorPedidos/icon-pedidoparcialmenteprocesado.png";
import pedidoEnviado from "../../../../assets/img/monitorPedidos/icon-pedidoenviado.png";
import pedidoParcialmenteEnviado from "../../../../assets/img/monitorPedidos/icon-pedidoparcialmenteenviado.png";
import pedidoRecibidoYConfirmado from "../../../../assets/img/monitorPedidos/icon-pedidoentregado.png";
import pedidoParcialmenteEntregado from "../../../../assets/img/monitorPedidos/icon-pedidoparcialmenteentregado.png";

//Actions
import {
    invalidatePedidos,
    fetchPedidosIfNeeded,
    updatePedido,
    iniciarPedido,
    resetPedidos
} from "../../../../actions/PedidoActions";
import {fetchFormasEntregaIfNeeded} from "../../../../actions/FormaEntregaActions";

//Api
import fechasFormat from "../../../../api/fechas";
import estadosPedidoApi from "../../../../api/estadosPedido";

//Constants
import * as EstadoPedido from "../../../../constants/EstadoPedido";
import * as EstadoEntrega from "../../../../constants/EstadoEntrega";
import * as EstadoRemito from "../../../../constants/EstadoRemito";
import TrackingPedidosModal from "./TrackingPedidosModal";
import * as moneda from "../../../../constants/Moneda";
import * as roles from "../../../../constants/Roles";

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class MonitorPedidos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            order: 'fechaPedido',
            direction: 'DESC',
            registros: 0,
            buscarPedidosUsuario: true,
            searchPhase: "",
            showTrackingModal: false,
            pedidoSeleccionado: {},
            intervalId: null,
            hayMasPedidos: true,
        };

        this.getMorePedidos = this.getMorePedidos.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let state = this.state;
        this.props.resetPedidos();
        this.props.fetchPedidosIfNeeded({
            order: state.order,
            direction: state.direction,
            registros: 0,
            buscarPedidosUsuario: state.buscarPedidosUsuario,
        });

        this.props.fetchFormasEntregaIfNeeded();

        //Agrego evento scroll que busca pedidos del usuario
        window.addEventListener('scroll', this.getMorePedidos);

        //Refresco cada 2 minutos la lista de pedidos
        let intervalId = setInterval(
            () => {
                this.props.invalidatePedidos();
                this.props.fetchPedidosIfNeeded({
                    order: state.order,
                    direction: state.direction,
                    registros: 0,
                    buscarPedidosUsuario: state.buscarPedidosUsuario,
                });
            }, 120000
        );
        //se guarda el id del setInterval para poder pararlo cuando se desmonta
        this.setState({intervalId: intervalId});
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pedidos.update.isUpdating != this.props.pedidos.update.isUpdating && !this.props.pedidos.update.isUpdating) {
            let state = this.state;
            this.props.resetPedidos();
            this.props.fetchPedidosIfNeeded({
                order: state.order,
                direction: state.direction,
                registros: 0,
                buscarPedidosUsuario: state.buscarPedidosUsuario,
            });
        }

        if (prevProps.pedidos.byId.isFetching && !this.props.pedidos.byId.isFetching && prevProps.pedidos.allIds.length == this.props.pedidos.allIds.length) {
            this.setState({hayMasPedidos: false});
        }
    }

    componentWillUnmount() {
        //Paro el setInterval que refresca la lista de pedidos
        clearInterval(this.state.intervalId);
        this.timer = null;

        //Quito evento scroll que busca pedidos del usuario
        window.removeEventListener('scroll', this.getMorePedidos);
    }

    getMorePedidos() {
        if ($(window).scrollTop() + $(window).height() == $(document).height()) {
            let state = this.state;
            this.props.invalidatePedidos();
            this.props.fetchPedidosIfNeeded({
                order: state.order,
                direction: state.direction,
                registros: this.props.pedidos.allIds.length,
                buscarPedidosUsuario: state.buscarPedidosUsuario,
                searchPhase: state.searchPhase,
            });
        }
    }

    openTrackingModal(pedido) {
        this.setState({showTrackingModal: true, pedidoSeleccionado: pedido})
    }

    closeTrackingModal() {
        this.setState({showTrackingModal: false})

    }

    changeSearch(e) {
        clearTimeout(this.timer);

        this.setState({searchPhase: e.target.value});

        this.timer = setTimeout(() => this.triggerChange(), WAIT_INTERVAL);
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            clearTimeout(this.timer);
            this.triggerChange();
        }
    }

    triggerChange() {
        let state = this.state;
        this.props.resetPedidos();
        this.props.invalidatePedidos();

        this.props.fetchPedidosIfNeeded({
            order: state.order,
            searchPhase: state.searchPhase,
            direction: state.direction,
            registros: 0,
            buscarPedidosUsuario: true,
        });
    }

    render() {
        var usuarioLogueado = this.props.usuarios.byId.usuarioLogueado;
        var screenWidth = $(window).width();

        var esEmpleadoKit = usuarioLogueado && (
            usuarioLogueado.idRol == roles.ADMINISTRACION_ID || usuarioLogueado.idRol == roles.ADMINISTRADOR_ID || usuarioLogueado.idRol == roles.COMMUNITY_MANAGER_ID
            || usuarioLogueado.idRol == roles.VENDEDOR_ID || usuarioLogueado.idRol == roles.DESPACHO_ID);
        const tooltipNoRecibido = (
            <Tooltip id="tooltipNoRecibido" className="tooltip">
                <p>Pedido aún no recibido.</p>
            </Tooltip>
        );
        const tooltipRecibido = (
            <Tooltip id="tooltipRecibido" className="tooltip">
                <p>Pedido ya recibido.</p>
            </Tooltip>
        );
        const tooltipNoProcesado = (
            <Tooltip id="tooltipNoProcesado" className="tooltip">
                <p>Pedido aún no procesado.</p>
            </Tooltip>
        );
        const tooltipProcesado = (
            <Tooltip id="tooltipProcesado" className="tooltip">
                <p>Pedido ya procesado.</p>
            </Tooltip>
        );
        const tooltipNoEnviado = (
            <Tooltip id="tooltipNoEnviado" className="tooltip">
                <p>Pedido aún no enviado.</p>
            </Tooltip>
        );
        const tooltipEnviado = (
            <Tooltip id="tooltipEnviado" className="tooltip">
                <p>Pedido ya enviado. <br/> presione aqui para ver el número de seguimiento</p>
            </Tooltip>
        );
        const tooltipRecibidoYConfirmado = (
            <Tooltip id="tooltipRecibidoYConfirmado" className="tooltip">
                <p>Pedido recibido y confirmado.</p>
            </Tooltip>
        );
        const tooltipNoRecibidoYConfirmado = (
            <Tooltip id="tooltipNoRecibidoYConfirmado" className="tooltip">
                <p>Pedido aún no recibido ni confirmado.</p>
            </Tooltip>
        );

        const tooltipParcialmenteEntregado = (
            <Tooltip id="tooltipParcialmenteEntregado" className="tooltip">
                <p>Pedido recibido parcialmente.</p>
            </Tooltip>
        );

        //Filas tabla pedidos
        var array = [1, 2, 3, 4];
        var Filas =
            this.props.pedidos.byId.isFetching && this.props.pedidos.allIds.length === 0 ?
                (array.map(function (array, index) {
                        return (<tr key={index}>
                            <th><p className="parrafoInvisible">Estado</p></th>
                            <th><p className="parrafoInvisible">Número</p></th>
                            <th><p className="parrafoInvisible">Razon Social</p></th>
                            <th><p className="parrafoInvisible">Tipo de Pedido</p></th>
                            <th><p className="parrafoInvisible">Fecha de pedido</p></th>
                            <th><p className="parrafoInvisible">Tu Precio</p></th>
                            <th><p className="parrafoInvisible">Precio Cliente</p></th>
                            <th><p className="parrafoInvisible">----</p></th>
                            <th><p className="parrafoInvisible">Proceso Facturación</p></th>
                            <th><p className="parrafoInvisible">Ver</p></th>
                            <th><p className="parrafoInvisible LG">Seguimiento</p></th>
                            <th><p className="parrafoInvisible XS">Seguimiento</p></th>
                        </tr>)
                    })

                )
                :
                this.props.pedidos.allIds.map(function (key) {
                    var pedido = this.props.pedidos.byId.pedidos[key];
                    if (pedido) {
                        var formaEntrega = this.props.formasEntrega.byId.formasEntrega[pedido.idFormaEntrega];
                        var cliente = this.props.clientes.byId.clientes[pedido.cliente];
                        var estadoPedido = estadosPedidoApi.buscarUltimoEstadoPedido(pedido.estado_pedido, this.props.estadosPedido.byId.estadosPedido);

                        //no muestra los pedidos cancelados
                        if (estadoPedido.desEstadoPedido == EstadoPedido.CANCELADO)
                            return true;

                        if (pedido.idPedidoRelacionado && (pedido.idUsuario != usuarioLogueado.id && !esEmpleadoKit)) {
                            return true;
                        }

                        var estadoEntrega = null;
                        var maxFechaEstadoEntrega = null;
                        pedido.estado_entrega.map((idEstadoEntrega) => {
                            var estado = this.props.estadosEntrega.byId.estadosEntrega[idEstadoEntrega];
                            if (estado.idPedidoMon == pedido.id && (!maxFechaEstadoEntrega || estado.fechaEstado > maxFechaEstadoEntrega)) {
                                maxFechaEstadoEntrega = estado.fechaEstado;
                                estadoEntrega = estado;
                            }
                        });

                        var Seguimiento = [];
                        //Pedido Recibido/Aprobado
                        if (estadoPedido.desEstadoPedido != EstadoPedido.PENDIENTE || estadoPedido.desEstadoPedido != EstadoPedido.CANCELADO) {
                            Seguimiento.push(
                                <span key="tooltipRecibido" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipRecibido}>
                                        <img src={pedidoRecibido}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else {
                            Seguimiento.push(
                                <span key="tooltipNoRecibido" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipNoRecibido}>
                                        <img src={pedidoNoRecibido}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        }

                        //Pedido Procesado
                        if (estadoEntrega && estadoEntrega.desEstadoEntrega == EstadoEntrega.PARCIAL) {
                            Seguimiento.push(
                                <span key="tooltipParcialmenteProcesado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipProcesado}>
                                        <img src={pedidoParcialmenteProcesado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else if (estadoEntrega && estadoEntrega.desEstadoEntrega == EstadoEntrega.COMPLETO) {
                            Seguimiento.push(
                                <span key="tooltipProcesado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipProcesado}>
                                        <img src={pedidoProcesado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else {
                            Seguimiento.push(
                                <span key="tooltipNoProcesado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipNoProcesado}>
                                        <img src={pedidoNoProcesado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        }

                        //Pedido Enviado
                        var enviado = false;
                        var recibidoYConfirmado = false;
                        pedido.remito.map((idRemito) => {
                            var remito = this.props.remitos.byId.remitos[idRemito];
                            remito.estado_remito.map((idEstadoRemito) => {
                                var estadoRemito = this.props.estadosRemito.byId.estadosRemito[idEstadoRemito];
                                if (estadoRemito.desEstadoRemito == EstadoRemito.EN_TRANSITO || estadoRemito.desEstadoRemito == EstadoRemito.RECIBIDO || estadoRemito.desEstadoRemito == EstadoRemito.RECIBIDO_CON_INCONVENIENTES) {
                                    enviado = true;
                                }
                                if (estadoRemito.desEstadoRemito == EstadoRemito.RECIBIDO)
                                    recibidoYConfirmado = true;
                            });
                        });
                        if (enviado && estadoEntrega && estadoEntrega.desEstadoEntrega == EstadoEntrega.COMPLETO) {
                            Seguimiento.push(
                                <span key="tooltipEnviado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipEnviado}>
                                        <img src={pedidoEnviado} style={{cursor: "pointer"}}
                                             onClick={() => this.openTrackingModal(pedido)}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else if (enviado && estadoEntrega && estadoEntrega.desEstadoEntrega == EstadoEntrega.PARCIAL) {
                            Seguimiento.push(
                                <span key="tooltipParcialmenteEnviado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipEnviado}>
                                        <img src={pedidoParcialmenteEnviado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else if (!enviado) {
                            Seguimiento.push(
                                <span key="tooltipNoEnviado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipNoEnviado}>
                                        <img src={pedidoNoEnviado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        }

                        //Pedido Recibido y Confirmado
                        if (recibidoYConfirmado && estadoEntrega && estadoEntrega.desEstadoEntrega == EstadoEntrega.COMPLETO) {
                            Seguimiento.push(
                                <span key="tooltipRecibidoYConfirmado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipRecibidoYConfirmado}>
                                        <img src={pedidoRecibidoYConfirmado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else if (recibidoYConfirmado && estadoEntrega && estadoEntrega.desEstadoEntrega == EstadoEntrega.PARCIAL) {
                            Seguimiento.push(
                                <span key="tooltipParcialmenteEntregado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipParcialmenteEntregado}>
                                        <img src={pedidoParcialmenteEntregado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        } else if (!recibidoYConfirmado) {
                            Seguimiento.push(
                                <span key="tooltipNoRecibidoYConfirmado" className="custom-tooltip">
                                    <OverlayTrigger placement={screenWidth > 767 ? "left" : "right"}
                                                    overlay={tooltipNoRecibidoYConfirmado}>
                                        <img src={pedidoNoRecibidoYConfirmado}/>
                                    </OverlayTrigger>
                                </span>
                            );
                        }

                        var simbolo = pedido.moneda ? pedido.moneda.simbolo : moneda.DOLAR;

                        return (
                            <tr key={pedido.id}>
                                <td className="seguimiento XS">
                                    {(!pedido.esPresupuesto || pedido.esPresupuesto == 0) && Seguimiento}
                                    {(!!pedido.esPresupuesto && pedido.esPresupuesto == 1) &&
                                    <Button
                                        style={{display: usuarioLogueado.id == pedido.idUsuario || esEmpleadoKit ? "table-cell" : "none"}}
                                        onClick={() => {
                                            history.push('/panel/listaPrecios/' + this.props.match.params['idCuenta'] + '/continuarPedido/' + pedido.id);
                                        }}
                                        disabled={pedido.facturado || estadoPedido.desEstadoPedido != EstadoPedido.GUARDADO || pedido.validandoDatosFacturacion || pedido.idPedidoRelacionado}
                                    > Continuar pedido
                                    </Button>}
                                </td>
                                <td>
                                    <label>
                                        <input type="radio"
                                               required
                                               checked={estadoPedido.desEstadoPedido == EstadoPedido.CERRADO || estadoPedido.desEstadoPedido == EstadoPedido.CERRADO_RESUELTO ? true : false}
                                        />
                                        <span/>
                                    </label>
                                </td>
                                <td>{pedido.vd_idPedido}</td>
                                <td className="razon-social">
                                    <b>{cliente ? cliente.razonSocial : ""}</b>
                                    {(!pedido.esPresupuesto || pedido.esPresupuesto == 0) &&
                                    <Button
                                        style={{display: usuarioLogueado.id == pedido.idUsuario || esEmpleadoKit ? "table-cell" : "none"}}
                                        onClick={() => {
                                            history.push('/panel/monitorPedidos/' + this.props.match.params['idCuenta'] + '/modificarPedido/' + pedido.id);
                                            this.props.updatePedido(pedido, null);
                                        }}
                                        disabled={!!(pedido.facturado || estadoPedido.desEstadoPedido != EstadoPedido.PENDIENTE || pedido.validandoDatosFacturacion || pedido.idPedidoRelacionado)}
                                    > Modificar</Button>
                                    }
                                </td>
                                <td>{formaEntrega ? formaEntrega.desFormaEntrega : ""}</td>
                                <td>{fechasFormat.formatFecha(pedido.fechaPedido)}</td>
                                <td className="precio">{pedido ? (pedido.moneda ? pedido.moneda.simbolo + " " + pedido.total : "$ " + pedido.total) : 0}</td>
                                <td></td>
                                <td className="precio clientePrecio">
                                    {(!pedido.esPresupuesto || pedido.esPresupuesto == 0) &&
                                    <div
                                        style={{display: usuarioLogueado.id == pedido.idUsuario || esEmpleadoKit ? "block" : "none"}}>
                                        {pedido && pedido.totalCuentaYOrden && !pedido.idPedidoRelacionado ? (simbolo + " " + pedido.totalCuentaYOrden) : (simbolo + " " + 0)}
                                        <Button
                                            onClick={() => {
                                                history.push('/panel/monitorPedidos/' + this.props.match.params['idCuenta'] + '/modificarPrecioPedido/' + pedido.id);
                                                this.props.updatePedido(pedido, null);
                                            }}
                                            disabled={!!(pedido.facturado || estadoPedido.desEstadoPedido != EstadoPedido.PENDIENTE || pedido.idPedidoRelacionado)}
                                        > Modificar</Button>
                                    </div>
                                    }
                                </td>
                                <td className="procesoFacturacion"
                                    style={{color: pedido.facturado ? "#00ffeb" : "#00aaeb", fontWeight: "bold"}}>
                                    <div
                                        style={{display: this.props.pedidos.update.isUpdating && this.props.pedidos.update.activo.id == pedido.id ? "none" : (usuarioLogueado.id == pedido.idUsuario || esEmpleadoKit ? "block" : "none")}}>
                                        <Button
                                            style={{display: pedido.cambioDatosFacturacion && !pedido.validandoDatosFacturacion && pedido.totalCuentaYOrden && pedido.totalCuentaYOrden != "0" && !pedido.idPedidoRelacionado ? "block" : "none"}}
                                            disabled={estadoPedido.desEstadoPedido != EstadoPedido.PENDIENTE}
                                            onClick={() => this.props.iniciarPedido(pedido)}>Iniciar Pedido</Button>
                                        {pedido.facturado ? "Facturado" : (pedido.validandoDatosFacturacion ? "Validando" : pedido.cambioDatosFacturacion && pedido.totalCuentaYOrden && pedido.totalCuentaYOrden != "0" && !pedido.idPedidoRelacionado ? "" : "---")}
                                    </div>
                                    <div
                                        style={{display: this.props.pedidos.update.isUpdating && this.props.pedidos.update.activo.id == pedido.id ? "block" : "none"}}>
                                        <Loader/>
                                    </div>
                                </td>
                                <td className="verPedido">
                                    <Button
                                        onClick={() => history.push("/panel/monitorPedidos/" + this.props.match.params['idCuenta'] + "/verPedido/" + pedido.id)}>Ver</Button>
                                </td>
                                <td className="seguimiento LG">
                                    {(!pedido.esPresupuesto || pedido.esPresupuesto == 0) && Seguimiento}
                                    {(!!pedido.esPresupuesto && pedido.esPresupuesto == 1 ) &&
                                    <Button
                                        style={{display: usuarioLogueado.id == pedido.idUsuario || esEmpleadoKit ? "table-cell" : "none"}}
                                        onClick={() => {
                                            history.push('/panel/listaPrecios/' + this.props.match.params['idCuenta'] + '/continuarPedido/' + pedido.id);
                                        }}
                                        disabled={!!(pedido.facturado || estadoPedido.desEstadoPedido != EstadoPedido.GUARDADO || pedido.validandoDatosFacturacion || pedido.idPedidoRelacionado)}
                                    > Continuar pedido
                                    </Button>}
                                </td>
                            </tr>
                        )
                    }
                }.bind(this));
        return (
            <div className="monitorPedidos">
                <HeaderPlataforma titulo={"Monitor de Pedidos"}/>
                <section className="sectionMonitor">
                    <FormGroup>
                        <InputGroup>
                            <InputGroup.Addon><Glyphicon glyph="search"/></InputGroup.Addon>
                            <FormControl type="text" value={this.state.searchPhase}
                                         onChange={(e) => this.changeSearch(e)}
                                         onKeyDown={(e) => this.handleKeyDown(e)}
                                         placeholder="Buscar..."/>
                        </InputGroup>
                    </FormGroup>
                    <Table className="tablaMonitor" responsive>
                        <thead className="tableHead">
                        <th className="seguimiento XS">Seguimiento</th>
                        <th className="estado">
                            <p>Estado</p>
                            <img src={flechita} alt="flechita"/>
                        </th>
                        <th>Número</th>
                        <th>Razon Social</th>
                        <th>Tipo de Pedido</th>
                        <th>Fecha<br/>de Pedido</th>
                        <th>Tu Precio</th>
                        <th>
                            <div className="lineaCeleste"></div>
                        </th>
                        <th className="precioCliente">Precio Cliente</th>
                        <th>Proceso<br/>Facturación</th>
                        <th></th>
                        <th className="seguimiento LG">Seguimiento</th>
                        </thead>
                        <tbody>
                        {Filas.length > 0 ? Filas :
                            <td colSpan={11} style={{textAlign: "center"}}>No hay registros de pedidos</td>}
                        <tr className="filaVacia">
                            <td className="seguimiento XS">
                                <div
                                    style={{display: this.props.pedidos.byId.isFetching && this.state.hayMasPedidos ? "block" : "none"}}>
                                    <Loader/>
                                </div>
                            </td>
                            <td colSpan={10}>
                                <div
                                    style={{display: this.props.pedidos.byId.isFetching && this.state.hayMasPedidos ? "block" : "none"}}>
                                    <Loader/>
                                </div>
                            </td>
                            <td className="seguimiento LG">
                                <div
                                    style={{display: this.props.pedidos.byId.isFetching && this.state.hayMasPedidos ? "block" : "none"}}>
                                    <Loader/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    <br/><br/>
                    <div className="contacto">
                        <h5>Consultanos tus dudas enviandonos un mensaje a <b>contacto@kitexperto.com</b>
                        </h5>
                    </div>
                    <br/>
                </section>
                <TrackingPedidosModal show={this.state.showTrackingModal}
                                      cerrar={this.closeTrackingModal.bind(this)}
                                      pedido={this.state.pedidoSeleccionado}
                                      className="modal-tracking"
                />
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
        usuarios: state.usuarios,
        pedidos: state.pedidos,
        formasEntrega: state.formasEntrega,
        clientes: state.clientes,
        estadosPedido: state.estadosPedido,
        estadosEntrega: state.estadosEntrega,
        remitos: state.remitos,
        estadosRemito: state.estadosRemito,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPedidosIfNeeded: (filtros) => {
            dispatch(fetchPedidosIfNeeded(filtros))
        },
        invalidatePedidos: () => {
            dispatch(invalidatePedidos())
        },
        fetchFormasEntregaIfNeeded: () => {
            dispatch(fetchFormasEntregaIfNeeded())
        },
        updatePedido: (pedido, idPedido) => {
            dispatch(updatePedido(pedido, idPedido))
        },
        iniciarPedido: (pedido) => {
            dispatch(iniciarPedido(pedido))
        },
        resetPedidos: () => {
            dispatch(resetPedidos())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MonitorPedidos));
