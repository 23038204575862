import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import siera from '../../../assets/img/evento/siera.png';
import kit from '../../../assets/img/evento/kit.png';
import banner from '../../../assets/img/evento/header.png'

import '../../../assets/css/RegistroEvento.css';
import FieldGroup from "../../camposTexto/FieldGroup";

import {
    changeUsuarioLogueado,
    fetchUsuarioIfNeeded, invalidateUsuario, resetCreateUsuarioLogueado,
    resetUpdateUsuarioLogueado
} from "../../../actions/UsuarioActions";
import FieldGroupSubmit from "../../camposTexto/FieldGroupSubmit";
import {saveCreateRegistro} from "../../../actions/RegistroActions";

import isObject from "lodash/isObject";
import isString from "lodash/isString";


import Loader from '../../elementos/loader/Loader';
import FieldTelefono from "../../camposTexto/FieldTelefono";

class RegistroEvento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dni: ""
        };

        this.submitForm = this.submitForm.bind(this);
        this.submitDatos = this.submitDatos.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    submitForm(e) {
        e.preventDefault();
        this.props.invalidateUsuario();
        this.props.resetCreateUsuarioLogueado();
        this.props.resetUpdateUsuarioLogueado();
        this.props.fetchUsuarioIfNeeded(this.state.dni);
        this.refs.datos.classList.add("in");
    }

    submitDatos(e) {
        e.preventDefault();
        if (!this.props.usuarios.byId.usuarioLogueado.dni) {
            this.props.changeUsuarioLogueado({dni: this.state.dni});
        }
        this.props.saveCreateRegistro();
    }

    changeDni(e) {
        this.setState({dni: e.target.value});
        console.log(this.state);
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.changeUsuarioLogueado(cambio);
    }


    render() {
        const usuario = this.props.usuarios.byId.usuarioLogueado;
        console.log(usuario);

        const error = this.props.registros.create.error;

        var msgError = null;

        if (error && isObject(error.message) && !isString(error.message)) {
            msgError = Object.keys(error.message).map(function(key, index) {
                return (<li>{error.message[key]}</li>);
            });
        }else{
            msgError = <p>{error ? error.message : ""}</p>;
        }

        return (
            <div className="container-limit evento">
                <div className="header">
                    <img src={siera} style={{paddingTop: "10px"}} alt=""/>
                    <Link to="/"><img src={kit} className="pull-right" alt=""/></Link>
                </div>
                <div className="banner">
                    <img className="img-responsive" src={banner} alt=""/>
                </div>
                <Row>
                    <Col md={12}>
                        <form ref="asd" onSubmit={(e) => {
                            this.submitForm(e)
                        }}>
                            <FieldGroupSubmit
                                id="dni"
                                type="number"
                                label="Ingresar DNI"
                                placeholder=""
                                required={true}
                                help={this.props.usuarios.byId.error ? this.props.usuarios.byId.error
                                    : usuario.dni ? "Ya estás en nuestro registro de datos " + usuario.nombre + " " + usuario.apellido :
                                        ""
                                }
                                value={this.state.dni}
                                onChange={(e) => {
                                    this.changeDni(e)
                                }}
                            />
                        </form>
                        <form onSubmit={e => {
                            this.submitDatos(e)
                        }}>
                            <div className="datos collapse" ref="datos">
                                <FieldGroup
                                    id="nombre"
                                    type="text"
                                    label="Nombre"
                                    placeholder=""
                                    required={true}
                                    value={usuario.nombre ? usuario.nombre : ""}
                                    onChange={(e) => {
                                        this.onChangeUsuario(e)
                                    }}
                                />

                                <FieldGroup
                                    id="apellido"
                                    type="text"
                                    label="Apellido"
                                    placeholder=""
                                    required={true}
                                    value={usuario.apellido ? usuario.apellido : ""}
                                    onChange={(e) => {
                                        this.onChangeUsuario(e)
                                    }}
                                />

                                <FieldGroup
                                    id="empresa"
                                    type="text"
                                    label="Empresa o Institución"
                                    placeholder=""
                                    required={true}
                                    value={usuario.empresa ? usuario.empresa : ""}
                                    onChange={(e) => {
                                        this.onChangeUsuario(e)
                                    }}
                                />

                                <FieldGroup
                                    id="cargo"
                                    type="text"
                                    label="Cargo o Función"
                                    placeholder=""
                                    required={true}
                                    value={usuario.cargo ? usuario.cargo : ""}
                                    onChange={(e) => {
                                        this.onChangeUsuario(e)
                                    }}
                                />
                                <FieldTelefono
                                    id="telefono"
                                    type="text"
                                    label="Teléfono"
                                    required={true}
                                    placeholder="(Cod. Area sin 0 - Num. Tel. sin 15 ej: 341 511111)"
                                    disabled={false}
                                    value={usuario.telefono ? (usuario.telefono == null ? "" : usuario.telefono) : ""}
                                    options={{phone: true, phoneRegionCode: 'AR', delimiter:"-"}}
                                    onChange={(e) => {
                                        this.onChangeUsuario(e)
                                    }}
                                />

                                <FieldGroup
                                    id="email"
                                    type="email"
                                    label="E-mail"
                                    require={true}
                                    placeholder=""
                                    value={usuario.email ? usuario.email : ""}
                                    onChange={(e) => {
                                        this.onChangeUsuario(e)
                                    }}
                                />
                                <FieldGroup id="vd_codIntegrador"
                                            label="¿Quién te invitó al evento?"
                                            componentClass="select"
                                            required={true}
                                            placeholder="Seleccione una empresa"
                                            value={usuario.vd_codIntegrador ? usuario.vd_codIntegrador : ""}
                                            onChange={(e) => {
                                                this.onChangeUsuario(e)
                                            }}>
                                    <option value="" selected>Seleccione una empresa</option>
                                    <option value="100">Kit Experto</option>
                                    <option value="1734">Propiedad Protegida</option>
                                    <option value="2178">Bartez Informática</option>
                                    <option value="1450">Arroyo Alarmas</option>
                                    <option value="1823">Bernabe</option>
                                    <option value="1623">Macrotecno</option>
                                </FieldGroup>
                                {
                                    this.props.registros.create.isCreating ?
                                        <Loader/>
                                        :
                                        <Button type="submit" className="center-block">Enviar</Button>
                                }


                                <loader/>
                                <p className="registro-error">{msgError ? msgError  : "" }</p>

                                <p className="registro-error">{this.props.registros.create.message ? this.props.registros.create.message : ""}</p>
                            </div>
                            <br/>

                        </form>
                    </Col>
                </Row>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        registros: state.registros
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsuarioIfNeeded: (dni) => {
            dispatch(fetchUsuarioIfNeeded(dni));
        },
        resetCreateUsuarioLogueado: () => {
            dispatch(resetCreateUsuarioLogueado())
        },
        resetUpdateUsuarioLogueado: () => {
            dispatch(resetUpdateUsuarioLogueado())
        },
        invalidateUsuario: () => {
            dispatch(invalidateUsuario());
        },
        changeUsuarioLogueado: (usuario) => {
            dispatch(changeUsuarioLogueado(usuario));
        },
        saveCreateRegistro: () => {
            dispatch(saveCreateRegistro());
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegistroEvento));
