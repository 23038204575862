import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

//Images
import tool from '../../assets/img/bigTool.png'

//CSS
import '../../assets/css/BannerIntegrador.css';


class BannerIntegrador extends Component {
    render() {
        return (
            <Row className="hidden-sm hidden-xs">
                <div className="integrador" style={{cursor:"pointer"}} onClick={()=>this.props.history.push("/soluciones")}>
                    <h4>¿Sos Integrador? Conocé cómo podemos complementar tu&nbsp;<span>trabajo.</span></h4>
                    <img src={tool} alt=""/>
                </div>
            </Row>
        );
    }
}

export default withRouter(connect()(BannerIntegrador));