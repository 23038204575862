import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link, NavLink} from "react-router-dom"

import '../../assets/css/Header.css';

import logo from '../../assets/img/kitLogo.png';




class HeaderLogo extends Component {
    render() {
        return (
            <div className="container-fluid header">
                <div className="container-limit">
                    <Row>
                        <Col xs={1} sm={1} style={{paddingLeft:"0px"}}>
                            <img className="logo" src={logo} alt="" style={{width: "120px"}}/>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default HeaderLogo;
