import React from 'react';
import "./MonitorPagos.css";
//lodash
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import LinkGenerado from "../../../../assets/img/pago/linkPago.png"
import Pendiente from "../../../../assets/img/pago/pendiente.png"
import Confirmado from "../../../../assets/img/pago/confirmado.png"
import Rechazado from "../../../../assets/img/pago/rechazado.png"
import $ from "jquery";
//Constants


export default function EstadosPago(props) {
    const onClickBoton = (idTipoPago) => {
        if (idTipoPago && props.idPago)
            props.history.push("/panel/pagos/" + props.idPago + "/" + idTipoPago);
    };

    return (
        <div className="lineaTiempo">
            {
                props.idTipoPago === 1 &&
                <div className={props.ultimoEstado >= 0 ? "active" : ""} onClick={() => onClickBoton(1)}>
                    <OverlayTrigger placement={$(window).width() > 767 ? "left" : "right"}
                                    overlay={
                                        <Tooltip id="tooltipParcialmenteEntregado" className="tooltip">
                                            <p>Link de pago generado</p>
                                        </Tooltip>
                                    }
                    >
                        <img src={LinkGenerado}/>
                    </OverlayTrigger>
                </div>
            }
            <div className={props.ultimoEstado >= 1 ? "active" : ""} onClick={() => onClickBoton(2)}>
                <OverlayTrigger placement={$(window).width() > 767 ? "left" : "right"}
                                overlay={
                                    <Tooltip id="tooltipParcialmenteEntregado" className="tooltip">
                                        <p>Pago pendiente de confirmación</p>
                                    </Tooltip>
                                }
                >
                    <img src={Pendiente}/>
                </OverlayTrigger>
            </div>
            {
                props.ultimoEstado <= 2 &&
                <div className={props.ultimoEstado === 2 ? "active" : ""} onClick={() => onClickBoton(3)}>
                    <OverlayTrigger placement={$(window).width() > 767 ? "left" : "right"}
                                    overlay={
                                        <Tooltip id="tooltipParcialmenteEntregado" className="tooltip">
                                            <p>Pago confirmado y acreditado</p>
                                        </Tooltip>
                                    }
                    >
                        <img src={Confirmado}/>
                    </OverlayTrigger>
                </div>
            }
            {
                props.ultimoEstado === 3 &&
                <div className={props.ultimoEstado === 3 ? "active" : ""} onClick={() => onClickBoton(3)}>
                    <OverlayTrigger placement={$(window).width() > 767 ? "left" : "right"}
                                    overlay={
                                        <Tooltip id="tooltipParcialmenteEntregado" className="tooltip">
                                            <p>Pago confirmado con diferencias y acreditado</p>
                                        </Tooltip>
                                    }
                    >
                        <div style={{position: "relative", margin: 0}}>
                            <div className="semi-circulo2"/>
                            <img style={{zIndex: 2}} src={Confirmado}/>
                        </div>

                    </OverlayTrigger>
                </div>
            }
            {
                props.ultimoEstado === 4 &&
                <div className={props.ultimoEstado === 4 ? "reject" : ""} onClick={() => onClickBoton(3)}>
                    <OverlayTrigger placement={$(window).width() > 767 ? "left" : "right"}
                                    overlay={
                                        <Tooltip id="tooltipParcialmenteEntregado" className="tooltip">
                                            <p>El pago fue rechazado</p>
                                        </Tooltip>
                                    }
                    >
                        <img src={Rechazado}/>
                    </OverlayTrigger>
                </div>
            }
        </div>
    )

}
