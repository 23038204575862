import fetch from 'isomorphic-fetch'
import formasEntrega from "../api/formasEntrega"
import auth from "../api/authentication";
import * as errorMessages  from '../constants/MessageConstants';
import {logout} from "../actions/AuthenticationActions";


//FORMASENTREGA
export const REQUEST_FORMASENTREGA = 'REQUEST_FORMASENTREGA';
export const RECEIVE_FORMASENTREGA = 'RECEIVE_FORMASENTREGA';
export const INVALIDATE_FORMASENTREGA = 'INVALIDATE_FORMASENTREGA';
export const ERROR_FORMASENTREGA= "ERROR_FORMASENTREGA";


export function invalidateFormasEntrega(formaEntrega) {
    return {
        type: INVALIDATE_FORMASENTREGA,
        formaEntrega
    }
}

function requestFormasEntrega() {
    return {
        type: REQUEST_FORMASENTREGA,
    }
}

function receiveFormasEntrega(json) {
    return {
        type: RECEIVE_FORMASENTREGA,
        formasEntrega: json,
        receivedAt: Date.now()
    }
}

function errorFormasEntrega(error) {
    return {
        type: ERROR_FORMASENTREGA,
        error: error,
    }
}

function fetchFormasEntrega() {
    return dispatch => {
        dispatch(requestFormasEntrega());
        return formasEntrega.getAll()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveFormasEntrega(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorFormasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorFormasEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchFormasEntrega(state) {
    const formasEntrega = state.formasEntrega.byId;
    if (!formasEntrega) {
        return true
    } else if (formasEntrega.isFetching) {
        return false
    } else {
        return formasEntrega.didInvalidate;
    }
}

export function fetchFormasEntregaIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchFormasEntrega(getState())) {
            return dispatch(fetchFormasEntrega())
        }
    }
}