import ciudades from "../api/ciudades";
import auth from "../api/authentication";
import * as errorMessages  from '../constants/MessageConstants';
import {logout} from "../actions/AuthenticationActions";


//CIUDADES
export const REQUEST_CIUDADES = 'REQUEST_CIUDADES';
export const RECEIVE_CIUDADES = 'RECEIVE_CIUDADES';
export const INVALIDATE_CIUDADES = 'INVALIDATE_CIUDADES';
export const ERROR_CIUDADES= "ERROR_CIUDADES";
export const RESET_CIUDADES= "RESET_CIUDADES";


export function invalidateCiudades(ciudad) {
    return {
        type: INVALIDATE_CIUDADES,
        ciudad
    }
}

export function resetCiudades() {
    return {
        type: RESET_CIUDADES,
    }
}

function requestCiudades() {
    return {
        type: REQUEST_CIUDADES,
    }
}

function receiveCiudades(json) {
    return {
        type: RECEIVE_CIUDADES,
        ciudades: json,
        receivedAt: Date.now()
    }
}

function errorCiudades(error) {
    return {
        type: ERROR_CIUDADES,
        error: error,
    }
}

function fetchCiudades(idProvincia) {
    return dispatch => {
        dispatch(requestCiudades());
        return ciudades.getAll(idProvincia)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCiudades(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorCiudades(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCiudades(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCiudades(state) {
    const ciudades = state.ciudades.byId;
    if (!ciudades) {
        return true
    } else if (ciudades.isFetching) {
        return false
    } else {
        return ciudades.didInvalidate;
    }
}

export function fetchCiudadesIfNeeded(idProvincia) {
    return (dispatch, getState) => {
        if (shouldFetchCiudades(getState(),idProvincia)) {
            return dispatch(fetchCiudades(idProvincia))
        }
    }
}

