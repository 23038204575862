import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export const PrivateRoute = ({component: Component, pedidos, dejoDeBuscarPedidos, ...rest}) => ( // eslint-disable-line
    <Route
        {...rest} render={props => {
        var encuentraPedido = false;
        pedidos.allIds.forEach(idPedido => {
            if (idPedido == props.match.params.idPedido) {
                encuentraPedido = true;
            }
        });
        if (!dejoDeBuscarPedidos) {
            encuentraPedido = true;
        }
        if(pedidos.byId.error) {
            encuentraPedido = false;
        }

        return (
            (!encuentraPedido) ?
                <Redirect
                    to={{
                        pathname: '/panel/monitorPedidos/' + props.match.params.idCuenta,
                        state: {from: props.location},
                    }}
                />
                :
                <Component {...props}/>
        )
    }}
    />
);