import {
    RECEIVE_KITS
} from '../actions/KitActions';
import { combineReducers } from 'redux';
import normalizeDatos from "../normalizers/normalizeKits";

function productosKitsById(state = {
    isFetching: false,
    didInvalidate: true,
    productosKits: []
}, action) {
    switch (action.type) {
        case RECEIVE_KITS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productosKits: normalizeDatos(action.kits).entities.productosKits,
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allProductosKits(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const productosKits = combineReducers({
    byId : productosKitsById,
    allIds : allProductosKits
});

export default productosKits;