import React, {Component} from 'react';
import {Col, Row, Panel, PanelGroup} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';

import {connect} from 'react-redux';

//Components
import ProductoDos from '../elementos/producto/Producto';
import BannerIntegrador from '../secciones/BannerIntegrador';
import SliderMarca from '../secciones/SliderMarca';
import Footer2 from '../layout/Footer2';
import PromocionDos from "../elementos/promocion/PromocionDos";
import SubBarra from '../layout/SubBarra';
import BarraCategoriasResponsive from "../layout/BarraCategoriasResponsive";


//CSS
import '../../assets/css/Productos2.css';

//Images
import entrega from "../../assets/img/entregasPais.png";

//Actions
import {fetchCategoriasIfNeeded} from "../../actions/CategoriaActions";
import {fetchAccesosCuentaUsuarioIfNeeded} from "../../actions/AccesosCuentaActions";

//Utils
import pickBy from "lodash/pickBy";
import Scrollchor from "react-scrollchor";

class Productos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeKey: 0,
        }

    }

    componentDidMount() {
        this.props.fetchCategoriasIfNeeded();
        window.scrollTo(0, 0);
        // if(auth.idUsuario())
        //     this.props.fetchAccesosCuentaUsuarioIfNeeded(1, auth.idUsuario());
    }

    changePanelActivoCategoria(activeKey) {
        this.setState({
            activeKey: activeKey,
        })
    }

    render() {
        var categoriaSeleccionada = null;
        var subCategoriaSeleccionada = null;
        var Categorias = this.props.categorias.allIds.map((idCategoria) => {
            var categoria = this.props.categorias.byId.categorias[idCategoria];

            //Seleccion de categoria
            if (this.props.match.params["desCategoria"] == categoria.desCategoria.replace(/\s/g, "").toLowerCase())
                categoriaSeleccionada = categoria;

            //Seleccion de subcategoria
            if (this.props.match.params["desSubCategoria"] == "kits")
                subCategoriaSeleccionada = "kits";
            else if (this.props.match.params["desSubCategoria"] == "otros")
                subCategoriaSeleccionada = "otros";

            //Subcategorias
            var subCategorias = [];
            categoria.sub_categoria.map((idSubCategoria) => {
                var subCategoria = this.props.subCategorias.byId.subCategorias[idSubCategoria];
                if (this.props.match.params["desSubCategoria"] == subCategoria.desSubCategoria.replace(/\s/g, "").toLowerCase()
                    && this.props.categorias.byId.categorias[subCategoria.idCategoria].desCategoria.replace(/\s/g, "").toLowerCase() == this.props.match.params["desCategoria"])
                    subCategoriaSeleccionada = subCategoria;
                if (subCategoria) {
                    subCategorias.push(
                        <Scrollchor animate={{duration: 500}}
                                    disableHistory={true}
                                    to="#productos">
                            <li key={"li_subCat_" + subCategoria.id} className="subCategoria">
                                <NavLink
                                    key={"navLink_subCat_" + subCategoria.id}
                                    to={"/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase() + "/" + subCategoria.desSubCategoria.replace(/\s/g, "").toLowerCase()}
                                    activeClassName="active" exact>
                                    {subCategoria.desSubCategoria}
                                </NavLink>
                            </li>
                        </Scrollchor>);
                }
            });
            //Categorias
            return (
                <Panel eventKey={categoria.id} id={categoria.id} className="panelCategoria">
                    <Panel.Heading>
                        <Panel.Toggle toggle>
                            <Scrollchor animate={{duration: 500}}
                                        disableHistory={true}
                                        to="#productos">
                                <NavLink
                                    to={"/categorias/" + categoria.desCategoria.replace(/\s/g, "").toLowerCase()}
                                    activeClassName="active" key={"navLink_categoria_" + categoria.id}>
                                    {categoria.desCategoria.toUpperCase()}
                                </NavLink>
                            </Scrollchor>
                        </Panel.Toggle>
                    </Panel.Heading>
                    <Panel.Collapse>
                        <Panel.Body style={{display: categoria.sub_categoria.length == 0 ? "none" : "block"}}>
                            {subCategorias}
                        </Panel.Body>
                    </Panel.Collapse>
                </Panel>
            );
        });

        //Marcas
        var marcaSeleccionada = null;
        var Marcas = this.props.marcas.allIds.map((idMarca) => {
            let marca = this.props.marcas.byId.marcas[idMarca];
            if (marca) {
                if (this.props.match.params["desMarca"] == marca.desMarca.replace(/\s/g, "").toLowerCase())
                    marcaSeleccionada = marca;
                return (
                    <Scrollchor animate={{duration: 500}}
                                disableHistory={true}
                                to="#productos">
                        <li className="marcas" key={idMarca}>
                            <NavLink activeClassName="active" key={"navLink_" + idMarca}
                                     to={"/marcas/" + marca.desMarca.replace(/\s/g, "").toLowerCase()}>{marca.desMarca}
                            </NavLink>
                        </li>
                    </Scrollchor>
                );
            }
        });

        //Productos
        var seleccionProductos = [];
        seleccionProductos = pickBy(this.props.productos.byId.productos, (producto) => {
            if (marcaSeleccionada)
                return producto.idMarca == marcaSeleccionada.id;
            else if (subCategoriaSeleccionada && subCategoriaSeleccionada == "kits" && categoriaSeleccionada) {
                return producto.idCategoria == categoriaSeleccionada.id && !producto.idSubCategoria && producto.esKit;
            } else if (subCategoriaSeleccionada && subCategoriaSeleccionada == "otros" && categoriaSeleccionada) {
                return producto.idCategoria == categoriaSeleccionada.id && !producto.idSubCategoria && !producto.esKit;
            } else if (subCategoriaSeleccionada && categoriaSeleccionada) {
                return producto.idCategoria == categoriaSeleccionada.id && producto.idSubCategoria == subCategoriaSeleccionada.id;
            } else if (categoriaSeleccionada) {
                return producto.idCategoria == categoriaSeleccionada.id;
            }
        });

        var cantidadPorPagina = 0;
        var productosPorPagina = [];
        var paginas = [];
        Object.values(seleccionProductos).some((producto, index) => {
            if (!producto.isPromo)
                productosPorPagina.push(<ProductoDos key={producto.id * 100} producto={producto}/>);
            else {
                //Todo
                productosPorPagina.push(<PromocionDos key={producto.id_promo} kit={producto}/>);
            }
            cantidadPorPagina++;
            if (cantidadPorPagina == 6 || index == Object.keys(seleccionProductos).length - 1) {
                cantidadPorPagina = 0;
                paginas.push(
                    <div className="item" key={producto.id * 10}>
                        <Row className="filaProductos" key={producto.id}>
                            {productosPorPagina}
                        </Row>
                    </div>
                );
                productosPorPagina = [];
            }
        });
        return (
            <div>
                <SubBarra logueado={this.props.authentication.token}/>
                <section className="productos2">
                    <BarraCategoriasResponsive/>
                    <div className="container-limit">
                        <Row>
                            <Col sm={2} xsHidden>
                                <div className="categorias">
                                    <h2>CATEGORIAS DE PRODUCTOS</h2>
                                    <div className="categoriasNuevas" key="categorias">
                                        <PanelGroup
                                            accordion
                                            id="accordion-controlled-example"
                                            activeKey={this.state.activeKey}
                                            onSelect={(e) => this.changePanelActivoCategoria(e)}
                                        >
                                            {Categorias}
                                            <Panel eventKey="marcas" className="panelCategoria">
                                                <Panel.Heading>
                                                    <Panel.Toggle toggle>
                                                        MARCAS
                                                    </Panel.Toggle>
                                                </Panel.Heading>
                                                <Panel.Collapse>
                                                    <Panel.Body>
                                                        {Marcas}
                                                    </Panel.Body>
                                                </Panel.Collapse>
                                            </Panel>
                                        </PanelGroup>
                                    </div>
                                </div>
                            </Col>
                            <Col md={10} sm={10}>
                                {paginas.length > 0 ?
                                    <OwlCarousel navs className="owl-theme" items={1} id="productos"
                                                 key={this.props.match.params["desCategoria"] + "_" + this.props.match.params["desSubCategoria"] + "_" + this.props.match.params["desMarca"]}>
                                        {paginas}
                                    </OwlCarousel> : ""
                                }
                            </Col>
                        </Row>
                    </div>
                </section>
                <section className="container-limit entrega">
                    <Row>
                        <img className="img-responsive" src={entrega} alt=""/>
                    </Row>
                </section>
                <BannerIntegrador/>
                <SliderMarca/>
                <Footer2/>

            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias,
        subCategorias: state.subCategorias,
        kits: state.kits,
        authentication: state.authentication,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCategoriasIfNeeded: () => {
            dispatch(fetchCategoriasIfNeeded())
        },
        fetchAccesosCuentaUsuarioIfNeeded: (idCuenta, idUsuario) => {
            dispatch(fetchAccesosCuentaUsuarioIfNeeded(idCuenta, idUsuario))
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Productos);