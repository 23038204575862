import {combineReducers} from 'redux';
import {normalizeDatos, normalizeDato} from "../normalizers/normalizeCategorias";
import {RECEIVE_CATEGORIA, RECEIVE_CATEGORIAS} from "../actions/CategoriaActions";
import {RECEIVE_PEDIDOS} from "../actions/PedidoActions";
import merge from "lodash/merge";
import union from "lodash/union";


function kitsById(state = {
    isFetching: false,
    didInvalidate: true,
    kits: []
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                kits: normalizeDatos(action.categorias).entities.kits,
                lastUpdated: action.receivedAt
            });
        case RECEIVE_CATEGORIA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                kits: merge({}, state.kits, normalizeDato(action.categoria).entities.kits),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                kits: merge({}, state.kits, action.pedidos.entities.kits),
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allKits(state = [], action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            let kits1 = normalizeDatos(action.categorias).entities.kits;
            return kits1 ? Object.keys(kits1) : [];
        case RECEIVE_CATEGORIA:
            let kits2 = normalizeDato(action.categoria).entities.kits;
            return kits2 ? Object.keys(kits2) : [];
        case RECEIVE_PEDIDOS:
            return action.pedidos.entities.kits ? union(state, Object.keys(action.pedidos.entities.kits)) : state;
        default:
            return state
    }
}


const kits = combineReducers({
    byId: kitsById,
    allIds: allKits
});

export default kits;