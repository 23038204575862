export const RETIRA_COMISIONISTA_ID = 1;
export const PAGO_EN_DOMICILIO_ID = 2;
export const PAGO_EN_SUCURSAL_ID = 3;
export const RETIRA_CLIENTE_ID = 4;
export const EXPERTO_A_DOMICILIO_ID = 5;
export const EXPERTO_A_TRANPORTE_ID = 6;
export const FACTURA_DE_ENVIO_ID = 7;
export const COMUNIDAD_KIT_ID = 8;
export const RETIRA_COMISIONISTA = "Retira comisionista";
export const PAGO_EN_DOMICILIO = "Pago en domicilio";
export const PAGO_EN_SUCURSAL = "Pago en sucursal";
export const RETIRA_CLIENTE = "Retira cliente";
export const EXPERTO_A_DOMICILIO = "Experto a domicilio";
export const EXPERTO_A_TRANPORTE = "Experto a Transporte";
export const FACTURA_DE_ENVIO = "Factura de Envío";
export const COMUNIDAD_KIT = "Comunidad Kit";

