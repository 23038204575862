//utils
import transportes from "../api/transportes"
import auth from "../api/authentication";
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthenticationActions";


//TRANSPORTES
export const REQUEST_TRANSPORTES = 'REQUEST_TRANSPORTES';
export const RECEIVE_TRANSPORTES = 'RECEIVE_TRANSPORTES';
export const INVALIDATE_TRANSPORTES = 'INVALIDATE_TRANSPORTES';
export const ERROR_TRANSPORTES = "ERROR_TRANSPORTES";
export const RESET_TRANSPORTES = "RESET_TRANSPORTES";

//Recuperar transporte
export function invalidateTransportes() {
    return {
        type: INVALIDATE_TRANSPORTES
    }
}

function requestTransportes() {
    return {
        type: REQUEST_TRANSPORTES,
    }
}

function receiveTransportes(json) {
    return {
        type: RECEIVE_TRANSPORTES,
        transportes: json,
        receivedAt: Date.now()
    }
}

function errorTransportes(error) {
    return {
        type: ERROR_TRANSPORTES,
        error: error,
    }
}

function fetchTransportes(filtros) {
    return dispatch => {
        dispatch(requestTransportes());
        return transportes.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                }
                else {
                    var data = response.json();
                    //Refresco token
                    auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveTransportes(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorTransportes(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorTransportes(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchTransportes(state, filtros) {
    const transportes = state.transportes.byId;
    if (!transportes) {
        return true
    } else if (filtros && filtros.id) {
        return true
    } else if (transportes.isFetching && (filtros && (!filtros.searchPhase || filtros.searchPhase != ""))) {
        return false
    } else {
        return transportes.didInvalidate;
    }
}

export function fetchTransportesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchTransportes(getState(), filtros)) {
            return dispatch(fetchTransportes(filtros))
        }
        else
            return Promise.resolve();
    }
}

export function resetTransportes() {
    return {
        type: RESET_TRANSPORTES
    }
}