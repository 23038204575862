import {combineReducers} from 'redux';
import merge from "lodash/merge";
import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from "../actions/PedidoActions";
import union from "lodash/union";

function detallesRemitidosById(state = {
    isFetching: false,
    didInvalidate: true,
    detallesRemitidos: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                detallesRemitidos: merge({}, state.detallesRemitidos, action.pedidos.entities.detallesRemitidos),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                detallesRemitidos: [],
                error: null
            });
        default:
            return state
    }
}


function allDetallesRemitidos(state = [], action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return action.pedidos.entities.detallesRemitidos ? union(state, Object.keys(action.pedidos.entities.detallesRemitidos)) : state;
        case RESET_PEDIDOS:
            return [];
        default:
            return state
    }
}

const detallesRemitidos = combineReducers({
    byId: detallesRemitidosById,
    allIds: allDetallesRemitidos,
});

export default detallesRemitidos;